import React, { useEffect, useState } from 'react';
import Icon, { SortASC, SortDESC } from '@base/assets/icons/svg-icons';
import SelectDropdown from '@base/components/form/select-dropdown';
import { ASC, DESC } from '@base/config/constant';
import { ChevronDown } from 'react-feather';
import { generateUUID } from '@base/utils/helpers';
import classNames from 'classnames';

interface ListHeaderSort {
  isCollapse?: boolean;
  sortColumns: any[];
  sortData: any;
  onSortDataChanged: (sortData: any) => void;
}

const ListHeaderSort = (props: ListHeaderSort) => {
  const { isCollapse, sortColumns, sortData, onSortDataChanged } = props;

  const [sortDirection, setSortDirection] = useState<number>(sortData.orderBy || DESC);
  const [sortField, setSortField] = useState<string | null>(sortData.field || null);

  useEffect(() => {
    if (sortData.field && sortField != sortData.field) {
      setSortField(sortData.field);
    }
  }, [sortData.field]);

  useEffect(() => {
    if (sortData.orderBy && sortData.orderBy != sortDirection) {
      setSortDirection(sortData.orderBy);
    }
  }, [sortData.orderBy]);

  useEffect(() => {
    const newSortData = { field: sortField, orderBy: sortDirection };
    if (JSON.stringify(newSortData) != JSON.stringify(sortData)) {
      onSortDataChanged(newSortData);
    }
  }, [sortField, sortDirection, sortData]);

  const componentId = generateUUID();

  return isCollapse ? (
    <>
      <label className="form-item-title">
        <button
          type="button"
          className="btn btn-sort pd-0 current-sorting"
          onClick={() => setSortDirection(sortDirection === ASC ? DESC : ASC)}
        >
          {/* Add 'current-sorting' class when sorting is applied */}
          {sortDirection && sortDirection === ASC ? Icon('sort_asc') : Icon('sort_desc')}
          {/*{Icon('sort_asc')} /!* Icon : sort / sort_desc / sort_asc *!/*/}
          <span className="sr-only">오름차순</span> {/* sort_desc  : 내림차순 */}
        </button>
        Sort by:
        <button
          type="button"
          className="btn btn-link link-03 pd-0 float-right"
          data-toggle="collapse"
          data-target={`#sortBy_${componentId}`}
        >
          <ChevronDown />
        </button>
      </label>
      <div id={`sortBy_${componentId}`} className="collapse">
        {sortColumns.map((col, i) => (
          <button
            key={i}
            type="button"
            className={classNames('dropdown-item', {
              active: sortField && sortField === col?.value,
            })}
            onClick={() => setSortField(col?.value || null)}
          >
            {col.label}
          </button>
        ))}
      </div>
    </>
  ) : (
    <SelectDropdown
      options={sortColumns || []}
      placeholder={<span className="text-secondary">None</span>}
      onChange={(column: any) => setSortField(column?.value || null)}
      selected={sortField}
      buttonStyles={{ paddingLeft: 0, paddingRight: 0 }}
      prefix={
        <>
          <button
            onClick={() => setSortDirection(sortDirection === ASC ? DESC : ASC)}
            className="btn btn-sort pd-r-0 current-sorting"
          >
            {sortDirection && sortDirection === ASC ? <SortASC /> : <SortDESC />}
          </button>
          <span>Sort by:</span>
        </>
      }
    />
  );
};

export default ListHeaderSort;
