import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { User, Users } from 'react-feather';
import { components } from 'react-select';
import { Select } from '@base/components/form';
import { Avatar } from '@base/components';
import { UserPopover } from '@base/containers';
import { useUsersAutoComplete } from '@base/services/user-service';

interface UserProps {
  className?: string;
  styles?: any;
  placeholder?: string;
  placement?: string | undefined;
  single?: boolean;
  showAvatar?: boolean;
  showPopover?: boolean;
  visible?: boolean;
  value?: any;
  onChange?: any;
  onAssignToMe?: any;
}

/**
 *
 * @param {*} props
 * @returns
 */
const UserAutoComplete: React.FC<UserProps> = (props) => {
  const {
    className,
    styles,
    placeholder = 'Type or click to select a user',
    placement = 'left',
    single = false, //
    showAvatar = false,
    showPopover = true,
    visible = true, //hide or display selected items
    value, //[], initial value
    onChange,
    onAssignToMe = null,
  } = props;

  //state
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');
  const setSearchTextDebounced = useRef(
    _.debounce((searchText) => setSearchText(searchText), 1000),
  ).current;
  const [options, setOptions] = useState<any>([]); //for default options
  const [showList, setShowList] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const popoverRef = useRef(null);
  const [target, setTarget] = useState(null);

  //initial selected
  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          //2 cases for value: string[] or object[]
          let isArrayString = _.isString(value[0]);
          if (isArrayString) {
            const selectedIds = selectedValue?.map((_ele: any) => _ele.value);
            if (JSON.stringify(value) !== JSON.stringify(selectedIds)) {
              const newValue: any = [];
              value.map((_item: string) => {
                //find in options
                const fOption = options.find((_ele: any) => _ele.value === _item);
                if (fOption) {
                  newValue.push(fOption);
                }
              });
              setSelectedValue(newValue);
            }
          } else {
            //array object
            if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
              setSelectedValue(value);
            }
          }
        } else {
          setSelectedValue([]);
        }
      } else {
        //single object
        if (_.isString(value)) {
          if (value !== selectedValue?.value) {
            //find in options
            const fOption = options.find((_ele: any) => _ele.value === value);
            if (fOption) {
              setSelectedValue(fOption);
            }
          }
        } else {
          if (value?.value !== selectedValue?.value) {
            setSelectedValue(value);
          }
        }
      }
    } else {
      setSelectedValue(null);
    }
  }, [value, options]);

  //build params
  const getSearchParams = () => {
    let params: any = {
      groupId: '',
      keyword: searchText,
    };
    return params;
  };

  //convert to select options
  const formatSelectOptions = (results: any) => {
    let tmpOptions = results?.data?.map((_item: any) => {
      return {
        ..._item,
        label: _item?.fullName,
        value: _item?.id,
      };
    });
    return tmpOptions;
  };

  // const { data: results, isLoading } = useUsersAutoComplete(getSearchParams());
  const results = [{
    id: "msr",
    name: "MSR",
    fullName: "MSR"
  }]
  //// console.log('postResult', results);
  //let newOptions = formatSelectOptions(results);

  //init states list
  useEffect(() => {
    if (results) {
      let newOptions = formatSelectOptions(results);
      setOptions(newOptions);
    }
  }, [results]);

  //input text change
  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };

  //value change
  const handleSelectChange = (newValue: any) => {
    if (visible) {
      setSelectedValue(newValue);
    }
    //callback
    onChange && onChange(newValue);
  };

  //selected items from popover
  const handleSelectUsers = (items: any) => {
    if (single) {
      //items is []
      if (items && items.length > 0) {
        const newSelect = {
          ...items[0],
          label: items[0]?.fullName,
          value: items[0]?.id,
        };
        setSelectedValue(newSelect);
        //callback
        onChange && onChange(newSelect);
      }
    } else {
      //items is []
      if (items && items.length > 0) {
        const newSelect: any = selectedValue ? [...selectedValue] : [];
        items.map((_item: any) => {
          const idx = newSelect.findIndex((_ele: any) => _ele.id === _item.id);
          if (idx === -1) {
            newSelect.push({
              ..._item,
              label: _item?.fullName,
              value: _item?.id,
            });
          }
        });
        setSelectedValue(newSelect);
        //callback
        onChange && onChange(newSelect);
      }
    }

    //close popover
    setShowList(false);
  };

  //selected options
  const CustomMultiValueLabel = ({ children, ...props }: any) => {
    const { data } = props;
    return (
      <components.MultiValueLabel {...props}>
        <div className="d-flex align-items-center css-user-autocomplete">
          {showAvatar && <Avatar height={20} width={20} url={data?.photo} alt={data?.fullName} />}
          <div className="d-flex mg-l-10 css-flex">{children}</div>
        </div>
      </components.MultiValueLabel>
    );
  };

  //single custom option
  const CustomSingleValueLabel = ({ children, ...props }: any) => {
    const { data } = props;
    return (
      <components.SingleValue {...props}>
        <div className="d-flex align-items-center css-customer-autocomplete">
          {showAvatar && (
            <div className="avatar avatar-xs">
              <Avatar url={data?.photo} alt={data?.fullName} height={26} width={26} />
            </div>
          )}
          <div className="d-flex mg-l-10 css-flex">{children}</div>
        </div>
      </components.SingleValue>
    );
  };

  //select custom option
  const CustomOption = ({ children, ...props }: any) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div className="d-flex align-items-center css-user-autocomplete">
          {showAvatar && (
            <div className="avatar avatar-xs">
              <Avatar height={26} width={26} url={data?.photo} alt={data?.fullName} />
            </div>
          )}
          <div className="d-flex mg-l-10 css-flex">{children}</div>
        </div>
      </components.Option>
    );
  };

  //render
  return (
    <div className={classNames('pos-relative', className)} style={styles} ref={popoverRef}>
      <Select
        outSide={true}
        isMulti={!single}
        noOptionsMessage={() => 'No users found.'}
        placeholder={<div className="react-select-placeholder">{placeholder}</div>}
        iconIndicator={single ? <User size={16} /> : <Users size={16} />}
        isClearable
        components={{
          Option: CustomOption,
          MultiValueLabel: CustomMultiValueLabel,
          SingleValue: CustomSingleValueLabel,
        }}
        // isLoading={isLoading}
        inputValue={inputText}
        onInputChange={handleInputChange}
        value={selectedValue}
        options={options}
        getOptionLabel={(opt: any) => opt.name}
        getOptionValue={(opt: any) => opt.id}
        onChange={handleSelectChange}
        addLabel="Assign to me"
        onAdd={onAssignToMe}
        onClickIndicator={(e: any) => {
          setShowList(!showList);
          setTarget(e.target);
        }}
        menuPlacement={'auto'}
      />
      {showPopover && (
        <UserPopover
          placement={placement}
          isOpen={showList}
          onClose={() => setShowList(false)}
          single={single}
          data={Array.isArray(selectedValue) ? selectedValue : selectedValue ? [selectedValue] : []}
          onApply={handleSelectUsers}
          containerRef={document.body} //popoverRef
          target={popoverRef}
        />
      )}
    </div>
  );
};

export default UserAutoComplete;
