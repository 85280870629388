import React, { useState } from 'react';
import classNames from 'classnames';
import Collapse from 'react-bootstrap/Collapse';
import { ChevronDown, ChevronRight } from 'react-feather';
import { FormIcon } from '@base/components/form/icon';
import { SpanLang } from '@base/components/form';

const CardCollapse: React.FC<any> = (props: any) => {
  const { className, defaultOpen, headerTitle, children } = props;
  //state
  const [open, setOpen] = useState(defaultOpen || false);

  return (
    <div className={classNames('card', className)}>
      <div
        className="card-header"
        aria-controls="card-collapse"
        aria-expanded={open}
        onClick={() => setOpen(!open)}
      >
        <span className="card-header-title">
          {headerTitle ? (
            <>
              <FormIcon icon={headerTitle?.icon} iconType={headerTitle?.iconType} size={24} />
              {/* <SpanLang keyLang={headerTitle?.title} className={'mg-l-10'} /> */}
              <span className={'mg-l-10'}>{headerTitle?.title}</span>
            </>
          ) : (
            'No Name'
          )}
        </span>
        <div className="card-header-actions">
          <a className={'tx-black'}>
            {open ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
          </a>
        </div>
      </div>
      <div className="card-body pd-0">
        <Collapse in={open}>{children}</Collapse>
      </div>
    </div>
  );
};

export default CardCollapse;
