import React from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Edit2 } from 'react-feather';
import { Timeline } from '@base/types/interfaces/timeline';
import { useTranslation } from 'react-i18next';
import { removeTags } from '@base/utils/helpers/string-utils';

interface ItemProps {
  data: Timeline;
}

const Item = ({ data }: ItemProps) => {
  const { t } = useTranslation();
  const createTpl = _.template('<label class="tx-gray-500"><%= to%></label> Created.');
  const updateTpl = _.template(
    '<label class="tx-indigo"><%= lang%></label> was updated from <label class="tx-gray-500"><%= from%></label> to <label class="tx-gray-500"><%= to%></label>',
  );
  const deleteTpl = _.template('<%= to%> deleted');

  return (
    <>
      <div className="new-timeline-item">
        <div className="new-timeline-icon">
          <Edit2 />
        </div>
        <div className="new-timeline-body">
          <div className="card pd-15">
            <div className="new-timeline-body-header">
              {data.content?.map((item, index) => {
                const [from, to] = item.value.split('|->|');
                const [fromId, fromName] = from.split('|<->|');
                const [toId, toName] = to.split('|<->|');
                const tplData = {
                  field: item.field,
                  lang: t(item.lang),
                  from: fromName ? fromName : removeTags(from),
                  to: toName ? toName : removeTags(to),
                };
                let returnTpl;
                if (data.action == 'created') {
                  returnTpl = createTpl(tplData);
                } else if (data.action == 'updated') {
                  returnTpl = updateTpl(tplData);
                } else {
                  returnTpl = deleteTpl(tplData);
                }
                return (
                  <div key={`content-${index}`} dangerouslySetInnerHTML={{ __html: returnTpl }} />
                );
              })}
            </div>
            <div className="mg-l-auto tx-color-03">
              {data.createdBy?.name}, {dayjs(data.createdAt).format('HH:mm')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
