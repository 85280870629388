import React, { useCallback, useState, useRef, useEffect, ChangeEvent } from 'react';
import classNames from 'classnames';
import { FormIcon } from '@base/components/form';
import { Plus, X } from 'react-feather';
import dayjs from 'dayjs';
import { DatePicker } from '@base/components/date-picker/default';
import ActivityWrite from '@activity/pages/write';
import { IFilter } from './activity';
import { useForm } from 'react-hook-form';
import { ActivityTypesOptions } from '@activity/config/constants';
import { AssignedTo, DateFilter } from '@base/config/constant';

interface IForm {
  dateFilter: string;
  assignedTo: string;
  category: string[];
  keyword?: string;
}

interface IFilterProps {
  onChangeFilter: (val: IFilter | null) => void;
}

const ActivityFilter = (props: IFilterProps) => {
  const { onChangeFilter } = props;

  const [startDate, setStartDate] = useState<Date>(dayjs().startOf('month').toDate());
  const [endDate, setEndDate] = useState<Date>(dayjs().endOf('month').toDate());
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showWrite, setShowWrite] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const filterRef = useRef<any>(null);

  const {
    register,
    handleSubmit,
    formState: error,
    watch,
  } = useForm<IForm>({
    defaultValues: {
      dateFilter: 'month',
      assignedTo: 'me',
      keyword: '',
      category: ActivityTypesOptions.map((category) => category.value as string),
    },
  });
  const onSubmit = (data: IForm) => {
    // console.log(data);
    onChangeFilter({
      createdAt: {
        from: startDate,
        to: endDate,
      },
      assignedTo: data.assignedTo,
      category: data.category,
      keyword: data.keyword,
    });
    setShowFilter(false);
  };

  //click out side of filter dropdown
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      // console.log(event.target.classList);
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        !(event.target.classList.length === 0)
      ) {
        setShowFilter(false);
      }
    }

    // Bind the event listener
    filterRef.current.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      filterRef.current?.removeEventListener('click', handleClickOutside);
    };
  }, [filterRef]);

  const onChangeStartDate = useCallback((date: Date) => {
    setStartDate(date);
  }, []);

  const onChangeEndDate = useCallback((date: Date) => {
    setEndDate(date);
  }, []);

  const onChangeDate = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let rangeDate: { from: Date; to: Date };
    const {
      currentTarget: { value },
    } = e;
    if (value == 'week') {
      rangeDate = {
        from: dayjs().startOf('week').toDate(),
        to: dayjs().endOf('week').toDate(),
      };
    } else if (value == 'month') {
      rangeDate = {
        from: dayjs().startOf('month').toDate(),
        to: dayjs().endOf('month').toDate(),
      };
    } else if (value == 'year') {
      rangeDate = {
        from: dayjs().startOf('year').toDate(),
        to: dayjs().endOf('year').toDate(),
      };
    } else {
      rangeDate = {
        from: dayjs().startOf('day').toDate(),
        to: dayjs().endOf('day').toDate(),
      };
    }
    setStartDate(rangeDate.from);
    setEndDate(rangeDate.to);
  }, []);

  const resetData = useCallback(() => {
    onChangeFilter(null);
    setShowFilter(false);
  }, []);

  const dateFilterWatch = watch('dateFilter');

  return (
    <div className="d-flex justify-content-end">
      <button
        type="button"
        className="btn btn-primary rounded-30 mg-r-5"
        onClick={() => setShowWrite(true)}
      >
        <Plus className="mg-r-5" />
        Activity
      </button>
      <div
        className={classNames('dropleft ', {
          show: showFilter,
        })}
        ref={filterRef}
      >
        <button
          type="button"
          className="btn btn-white rounded-30"
          // data-toggle="dropdown"
          onClick={() => setShowFilter((showFilter) => !showFilter)}
        >
          <span className="mg-r-5">
            <FormIcon icon="filter" iconType="icon" />
          </span>
          Filters
        </button>
        <form
          className={classNames('dropdown-menu wd-400 pd-x-15 bg-white shadow-sm', {
            show: showFilter,
          })}
          onSubmit={handleSubmit(onSubmit)}
        >
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => setShowFilter(false)}
          >
            <span className="small" aria-hidden="true">
              &times;
            </span>
          </button>
          <div className="form-group">
            <label className="d-block form-item-title">Date</label>
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
              {DateFilter.map((item) => {
                return (
                  <label
                    key={item.value}
                    className={classNames('btn btn-white', {
                      active: item.value == dateFilterWatch,
                    })}
                  >
                    <input
                      {...register('dateFilter')}
                      type="radio"
                      value={item.value}
                      onChange={onChangeDate}
                    />
                    {item.label}
                  </label>
                );
              })}
            </div>
            <div className="d-flex align-items-center mg-t-10">
              <DatePicker
                placement={'bottom-end'}
                selected={startDate}
                onChange={onChangeStartDate}
                selectsStart={true}
                startDate={startDate}
                endDate={endDate}
              />
              <span className="mg-x-10">~</span>
              <DatePicker
                placement={'bottom-end'}
                selected={endDate}
                onChange={onChangeEndDate}
                selectsEnd={true}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Keyword</label>
            <input type="search" className="form-control" {...register('keyword')} />
          </div>
          <div className="form-group">
            <label className="form-item-title">Assigned to</label>
            <div className="form-check">
              {AssignedTo.map((item) => {
                return (
                  <label
                    key={item.value}
                    className="custom-control custom-radio custom-control-inline"
                  >
                    <input
                      type="radio"
                      className="custom-control-input"
                      {...register('assignedTo')}
                      value={item.value}
                      id={`assignedTo-${item.value}`}
                    />
                    <label className="custom-control-label" htmlFor={`assignedTo-${item.value}`}>
                      {item.label}
                    </label>
                  </label>
                );
              })}
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Activity</label>
            <div className="d-flex flex-row flex-wrap">
              {ActivityTypesOptions.map((item: any) => (
                <div key={item.value} className="custom-control custom-checkbox mg-r-5">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    {...register('category')}
                    value={item.value}
                    id={`category-${item.value}`}
                  />
                  <label className="custom-control-label" htmlFor={`category-${item.value}`}>
                    {item.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="d-flex">
            <button type="button" className="btn btn-light flex-grow-1 mg-r-5" onClick={resetData}>
              Reset
            </button>
            <button type="submit" className="btn btn-primary flex-grow-1">
              Apply
            </button>
          </div>
        </form>
      </div>

      {showWrite && (
        <ActivityWrite
          title={'New Activity Task'}
          size="lg"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={showWrite}
          onClose={() => setShowWrite(false)}
          onReload={() => null}
          menuApi={'activity_task'}
        />
      )}
    </div>
  );
};

export default React.memo(ActivityFilter);
