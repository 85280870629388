import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ChevronLeft, ChevronRight, Search, Users, X, RefreshCcw } from 'react-feather';
import { NoData, Avatar } from '@base/components';
import _ from 'lodash';

import './style.scss';

//loading placeholder
const LoadingArrayUsers = () => (
  <div className={'pd-x-20'}>
    {[...Array(6)].map((_e, index) => (
      <div key={index} className="media align-items-center">
        <div className="avatar wd-40 ht-40 rounded-circle navata-placeholder" />
        <div className="media-body pd-l-10">
          <div className="mg-b-2 ht-10 wd-200 navata-placeholder mg-b-5" />
          <div className="d-block ht-10 wd-100  navata-placeholder" />
        </div>
      </div>
    ))}
  </div>
);

/**
 * author: trungtm
 * main object
 */
const ListContainer: React.FC<any> = ({
  group = null,
  fetchList,
  listData = { items: [], keyword: '', attrs: {} },
  isListLoading,
  onSelectItem = null,
  selectedItems = [],
}: any) => {
  //lang
  const { t } = useTranslation();
  //state
  const [keyword, setKeyword] = useState('');
  const [attrs, setAttrs] = useState<any>({});

  //set list
  useEffect(() => {
    if (listData) {
      if (listData?.keyword && listData?.keyword != keyword) setKeyword(listData.keyword || '');
      if (listData?.attrs) setAttrs(listData.attrs);
    }
  }, [listData]);

  //refresh list based on group changed
  useEffect(() => {
    const params: any = {
      page: 1,
      keyword,
    };
    if (group && group.id) {
      params.groupIDs = [group.id];
      params.group = group; //for cacheOptions
    }
    fetchList && fetchList(params);
  }, [group]);

  //search item
  const handleSearch = (e: any) => {
    if (e.key === 'Enter') {
      const params: any = {
        page: 1,
        keyword,
      };
      if (group && group.id) {
        params.groupIDs = [group.id];
        params.group = group; //for cacheOptions
      }
      fetchList && fetchList(params);
    }
  };

  //clear keyword
  const handleClearSearch = () => {
    setKeyword('');
    const params: any = {
      page: 1,
      //keyword
    };
    if (group && group.id) {
      params.groupIDs = [group.id];
      params.group = group; //for cacheOptions
    }
    fetchList && fetchList(params);
  };

  //refresh data
  const handleRefreshList = () => {
    setKeyword('');
    const params: any = {
      page: 1,
      //keyword
    };
    fetchList && fetchList(params);
  };

  //previous page
  const handlePrePage = () => {
    if (attrs) {
      if (attrs?.page > 1) {
        const params: any = {
          page: attrs?.page - 1,
          keyword,
        };
        if (group && group.id) {
          params.groupIDs = [group.id];
          params.group = group; //for cacheOptions
        }
        fetchList && fetchList(params);
      }
    }
  };

  //nextx page
  const handleNextPage = () => {
    if (attrs) {
      if (attrs?.maxpage > attrs?.page) {
        const params: any = {
          page: attrs.page + 1,
          keyword,
        };
        if (group && group.id) {
          params.groupIDs = [group.id];
          params.group = group; //for cacheOptions
        }
        fetchList && fetchList(params);
      }
    }
  };

  //check selected item
  const isSelected = (item: any) => {
    return _.map(selectedItems, 'id').indexOf(item.id) !== -1;
  };

  //// console.log('listdata', listData);
  //main render
  return (
    <div className="list-container">
      {/* search */}
      <div className="mg-b-10 pd-10">
        <div className="input-group input-search">
          <div className="input-group-prepend">
            <span className="input-group-text bg-white">
              <Search className="tx-gray-300" />
            </span>
          </div>
          <input
            type="text"
            name="keyword"
            value={keyword}
            placeholder="Search..."
            className="form-control"
            autoComplete="off"
            onChange={(e) => setKeyword(e.target.value)}
            onKeyPress={handleSearch}
          />
          {keyword && (
            <div
              className="pos-absolute t-5 r-100 z-index-100 cursor-pointer"
              onClick={handleClearSearch}
            >
              <X className="wd-15 tx-gray-400" />
            </div>
          )}
          <div className="input-group-append">
            <button
              className={classNames('btn', {
                'btn-light': true,
                'btn-warning': false,
              })}
              type="button"
              onClick={handleRefreshList}
            >
              <RefreshCcw />
            </button>
          </div>
          <div className="input-group-append">
            <button
              className={classNames('btn', {
                'btn-light': true,
                'btn-warning': false,
              })}
              type="button"
            >
              <Users />
              {<span className="badge badge-danger position-absolute">{selectedItems.length}</span>}
            </button>
          </div>
        </div>
      </div>

      {/* list */}
      <div className="list-users scroll-box">
        {!isListLoading &&
          listData &&
          listData?.items?.length > 0 &&
          listData?.items?.map((_item: any) => {
            //get group name
            let itemGroup = _item?.properties?.crmBaseGroup?.name || t(_item?.type); //group for user; type for customer
            if (itemGroup.length === 0) {
              if (_item?.properties?.crmGroups && _item?.properties?.crmGroups?.length > 0) {
                itemGroup = _item?.properties?.crmGroups.map((_ele: any) => _ele?.name).join(', ');
              }
            }

            return (
              <div
                key={_item.id}
                className={classNames('media align-items-center', { selected: isSelected(_item) })}
                onClick={() => onSelectItem && onSelectItem(_item)}
              >
                {/* <div className="wd-40 ht-40 bg-gray-300 tx-white d-flex align-items-center justify-content-center rounded"> */}
                <div className="avatar avatar-sm">
                  <Avatar
                    height={32}
                    width={32}
                    //userno={item?.no}
                    url={_item?.photo}
                    //className="rounded-circle"
                    alt={_item?.name}
                  />
                </div>
                <div className="media-body pd-l-15">
                  <div className="mg-b-2">{_item?.name}</div>
                  <span className="tx-13 tx-color-03">{itemGroup}</span>
                </div>
              </div>
            );
          })}
        {!isListLoading && listData && listData?.items?.length === 0 && <NoData />}
        {isListLoading && <LoadingArrayUsers />}
      </div>

      {/* TODO: pagination */}
      <div className="d-flex pd-x-5 pd-y-10">
        <div className={'flex-fill'}>
          <span>
            <>{!listData?.group ? 'Total' : t(listData?.group?.title)}: </>
            <strong>{listData?.attrs?.total}</strong>
          </span>
        </div>
        <div>
          <span className={'mg-r-10'}>
            Page: {listData?.attrs?.page}/{listData?.attrs?.maxpage}
          </span>
          <button
            disabled={listData?.attrs?.page === 1}
            type="button"
            className="btn btn-xs btn-white btn-icon mg-r-5"
            onClick={handlePrePage}
          >
            <ChevronLeft />
          </button>
          <button
            disabled={listData?.attrs?.page === listData?.attrs?.total}
            type="button"
            className="btn btn-xs btn-white btn-icon"
            onClick={handleNextPage}
          >
            <ChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListContainer;
