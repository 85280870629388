import React, { Suspense } from 'react';
import * as Icons from 'react-feather';
//import extendIcons from "./extend.icons";

export type IconName = keyof typeof Icons;

export type FeatureIconProps = {
  name: IconName;
};

const FeatureIcon = (props: FeatureIconProps) => {
  const { name, ...rest } = props;
  //const Component = Icon[name] || extendIcons[name];
  const IconTag = Icons[name];

  if (!IconTag) {
    return null;
  }

  return <IconTag {...rest} />;
};

export default FeatureIcon;
