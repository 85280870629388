import React, { useEffect, useState, VFC } from 'react';
import { RefreshCw, Trash } from 'react-feather';
import {
  IToolbarActions,
  ToolbarActions,
} from '@base/components/list/list-toolbar/list-toolbar-action-more';
import { generateUUID } from '@base/utils/helpers';
import { isEmpty } from 'lodash';

export interface IListHeader {
  isSimple?: boolean; // true: for split mode
  filterComponent?: React.ReactNode;
  searchComponent?: React.ReactNode;
  columnSettingComponent?: React.ReactNode;
  onSelectedAll?: () => void;
  onDeselectedAll?: () => void;
  onRefresh?: () => void;
  onEmpty?: () => void;
  actionsProps?: IToolbarActions; // more buttons
  showColumnSettingComponent?: boolean;
  selectedIds?: string[];
}

const ListHeader: React.FC<IListHeader> = (props) => {
  const {
    isSimple,
    filterComponent,
    searchComponent,
    columnSettingComponent,
    onSelectedAll,
    onDeselectedAll,
    onRefresh,
    onEmpty,
    actionsProps,
    showColumnSettingComponent = true,
    selectedIds = [],
  } = props;

  const componentId = generateUUID();

  const [checkedAll, setCheckedAll] = useState<boolean>(false);

  // reset checked all
  useEffect(() => {
    if (isEmpty(selectedIds) && checkedAll) {
      setCheckedAll(false);
    }
  }, [selectedIds]);

  return (
    <div className="list-filter-bar">
      {/* checkbox all */}
      {isSimple && (
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id={`headerCheckAll_${componentId}`}
            // defaultChecked={false}
            checked={checkedAll}
            onChange={(e: any) => {
              if (e.target.checked) {
                // console.log('...onSelectedAll...', e?.target?.checked);
                onSelectedAll && onSelectedAll();
              } else {
                // console.log('...onDeselectedAll...', e?.target?.checked);
                onDeselectedAll && onDeselectedAll();
              }
              setCheckedAll(e.target.checked);
            }}
          />
          <label className="custom-control-label" htmlFor={`headerCheckAll_${componentId}`}>
            All
          </label>
        </div>
      )}

      {/* filters */}
      {filterComponent}

      {!isSimple && (
        <>
          {onEmpty && (
            <div className='d-flex flex-grow-1 justify-content-end mg-r-5'>
              <button type="button" className="btn btn-danger" aria-label="empty" onClick={onEmpty}>
                <Trash /> Empty
              </button>
            </div>
          )}

          {/* search */}
          {searchComponent}

          {/* Columns Settings */}
          {showColumnSettingComponent && columnSettingComponent}
        </>
      )}

      {isSimple && (
        <>
          {/* actions more dropdown */}
          {actionsProps && <ToolbarActions color={null} {...actionsProps} />}

          {/* refresh button */}
          {onRefresh && (
            <button type="button" className="btn btn-icon" aria-label="reload" onClick={onRefresh}>
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
          )}

          {/* search */}
          {searchComponent}
        </>
      )}
    </div>
  );
};

ListHeader.defaultProps = {
  isSimple: false,
};

export default ListHeader;
