import React, { useState } from 'react';
import classNames from 'classnames';
import HeaderMultiTheme from '@base/containers/header-multi-theme';
import { RightCollapse, LeftCollapse } from '@base/assets/icons/svg-icons';
import ViewTabsHeader from '@base/components/view/tabs-header';
import ViewTabsBody from '@base/components/view/tabs-body/indext';
import Loading from '@base/components/loading';

export interface IDestopLayout1ViewProps {
  className?: string;
  isLoading?: boolean;
  headerProps: any;
  componentTop?: any;
  topProps?: any;
  componentLeft: any;
  leftProps?: any;
  componentCenter?: any;
  centerProps?: any;
  componentRight: any;
  rightProps?: any;
  contentStyle?: any;
  tabViewComponent?: any;
  tabView?: boolean;
}

const DesktopLayout1View = (props: IDestopLayout1ViewProps) => {
  let {
    className = '',
    isLoading,
    headerProps,
    topProps,
    componentLeft,
    componentCenter,
    componentRight,
    centerProps,
    componentTop,
    contentStyle,
    tabViewComponent,
    tabView,
  } = props;
  const [hideAsideLeft, setHideAsideLeft] = useState(false);
  const [hideAsideRight, setHideAsideRight] = useState(false);

  // const [popoverOpen, setPopoverOpen] = useState(false);
  // const toggle = () => setPopoverOpen(!popoverOpen);
  return (
    <div className={classNames("view-wrap", className)}>
      {isLoading && <Loading />}
      {headerProps && <HeaderMultiTheme {...headerProps} />}
      {componentTop}
      <div className="row no-gutters flex-nowrap flex-grow-1" style={contentStyle}>
        <div
          className={classNames('col-3 view-aside', {
            'hidden-aside': hideAsideLeft,
          })}
          style={{ transformOrigin: 'left' }}
        >
          {!hideAsideLeft ? (
            <button
              type="button"
              className="btn btn-show-aside-right"
              onClick={() => setHideAsideLeft(!hideAsideLeft)}
            >
              <LeftCollapse />
            </button>
          ) : (
            ''
          )}
          {componentLeft}
        </div>
        {/** View Content */}
        <div
          className={classNames('view-content', {
            'col-9': hideAsideLeft || hideAsideRight || !componentRight,
            'col-12': (hideAsideLeft && hideAsideRight) || (!componentRight && hideAsideLeft),
            'col-6': Boolean(componentRight),
          })}
        >
          {!componentCenter && tabView && (
            <div className="d-flex align-items-start bg-white bd-b pd-t-8">
              {/* collapse button */}
              {/**TODO TabView */}
              {tabViewComponent ? tabViewComponent : <ViewTabsHeader {...centerProps} />}
              {/* collapse button */}
            </div>
          )}
          <div className="view-body">
            {hideAsideLeft && (
              <button
                type="button"
                className="btn btn-show-aside-left z-index-50"
                onClick={() => setHideAsideLeft(false)}
              >
                <RightCollapse />
                <span className="sr-only">좌측 사이드 패널 열기</span>
              </button>
            )}
            {hideAsideRight ? (
              <button
                type="button"
                className={classNames('btn btn-show-aside-right z-index-50', {
                  hide: !hideAsideRight,
                })}
                onClick={() => setHideAsideRight(!hideAsideRight)}
              >
                <LeftCollapse />
              </button>
            ) : (
              ''
            )}
            {componentCenter ? componentCenter : <ViewTabsBody {...centerProps} />}
          </div>
        </div>
        {componentRight && (
          <div
            className={classNames('col-3 view-aside', {
              'hidden-aside': hideAsideRight,
            })}
            style={{ transformOrigin: 'left' }}
          >
            {!hideAsideRight ? (
              <button
                type="button"
                className={classNames('btn btn-show-aside-left', {
                  hide: hideAsideRight,
                })}
                onClick={() => setHideAsideRight(!hideAsideRight)}
              >
                <RightCollapse />
              </button>
            ) : (
              ''
            )}
            {componentRight}
          </div>
        )}
      </div>
    </div>
  );
};

DesktopLayout1View.defaultProps = {
  isLoading: false,
  topProps: null,
  componentLeft: null,
  componentCenter: null,
  componentRight: null,
  centerProps: {
    tab: {},
    tabs: [],
    onChangeTab: () => null,
  },
  componentTop: null,
  contentStyle: {}, //{ height: 'calc(100% - 50px)' }
  tabViewComponent: null,
  tabView: true,
};

export default DesktopLayout1View;
