import React from 'react';
import classNames from 'classnames';

/**
 * author: trungtm
 * @param {*} param0
 */
const Footer: React.FC<any> = (props: any) => {
  const { children, className } = props;

  //render
  return <div className={classNames('panel-footer', className)}>{children}</div>;
};

export default Footer;
