import _ from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BaseMutationResponse } from '../types/interfaces/response';
import { useUploadMutation } from './useFileUploadMutation';

function useUploadFile(files: any | any[]) {
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [curFileIndex, setCurFileIndex] = useState<number>(-1);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState<number>(-1);
  const [isError, setIsError] = useState(false);
  if (_.isUndefined(files) || _.isNull(files)) {
    return { curFileIndex, uploadedFiles };
  }
  const selectedFiles = _.isArray(files) ? files : [files];

  // upload
  const mUpload: any = useUploadMutation<BaseMutationResponse>(
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //toast.success('Uploaded successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // console.log('mutation error', error);
        toast.error('There is error during uploading: ' + JSON.parse(error).message);
        setIsError(true);
      },
    },
    // (pEvent: ProgressEvent, partsNumber: number, partIndex: number, uploadId?: string) =>
    //   uploadProgressHandler(pEvent, partsNumber, partIndex, uploadId),
  );

  //upload success
  useEffect(() => {
    if (!mUpload.isSuccess) return;
    const newUploadedFiles = [...uploadedFiles];
    const newUpload = {
      objectId: mUpload.data.id, //upload id
      objectUrl: mUpload.data.url, //download url
      name: selectedFiles[curFileIndex].name,
      size: selectedFiles[curFileIndex].size,
      contentType: selectedFiles[curFileIndex].type,
    };
    newUploadedFiles.push(newUpload);
    setUploadedFiles(newUploadedFiles);
    //next file uploading
    setLastUploadedFileIndex(curFileIndex);
  }, [selectedFiles, curFileIndex]);

  /** =============================== UPLOAD HANDLER =========================== */
  useEffect(() => {
    if (selectedFiles.length > 0 && curFileIndex === -1) {
      setCurFileIndex(lastUploadedFileIndex === -1 ? 0 : lastUploadedFileIndex + 1);
    }
  }, [selectedFiles, curFileIndex, lastUploadedFileIndex]);

  //upload current file
  useEffect(() => {
    if (isError) return;
    // console.log('curFileIndex', curFileIndex);
    // console.log('selectedFiles', selectedFiles);
    if (curFileIndex !== -1) {
      // console.log('upload file');
      // mUpload.mutate(selectedFiles[curFileIndex]);
    }
  }, [isError, curFileIndex, selectedFiles]);

  //next file - last file
  useEffect(() => {
    if (lastUploadedFileIndex === -1) {
      return;
    }
    const isLastFile = lastUploadedFileIndex === selectedFiles.length - 1;
    if (isLastFile) {
      setCurFileIndex(-1);
    } else {
      //next uploaded
      const nextFileIndex = curFileIndex + 1;
      setCurFileIndex(nextFileIndex);
    }
  }, [lastUploadedFileIndex, curFileIndex, selectedFiles]);

  return { curFileIndex, uploadedFiles };
}

export default useUploadFile;
