import React from 'react';

const wav = () => {
  return (
    <svg id="file-type-wav" xmlns="http://www.w3.org/2000/svg" width="42px" height="51px" viewBox="0 0 42 51">
      <g>
        <path fill="#819CDB" d="M42,49c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h32l8,8V49z" />
        <polygon fill="#6C88C9" points="42.003,14.828 34.615,7.44 42,8 	" />
        <g>
          <path fill="#ADBEE8" d="M42,8h-6c-1.104,0-2-0.896-2-2V0L42,8z" />
        </g>
      </g>
      <g>
        <path
          fill="#819CDB"
          d="M40.471,29.383c-0.325-0.175-0.72-0.155-1.026,0.049L32,34.396h-6c-0.553,0-1,0.447-1,1v7
                c0,0.553,0.447,1,1,1l13.444,4.964c0.307,0.204,0.701,0.224,1.026,0.049c0.325-0.174,0.528-0.513,0.528-0.881V30.264
                C40.999,29.896,40.796,29.557,40.471,29.383z"
        />
        <path
          fill="#809BDA"
          d="M39.925,28.928c-0.325-0.174-0.72-0.154-1.026,0.049l-7.444,4.965h-6c-0.553,0-1,0.447-1,1v7
                c0,0.553,0.447,1,1,1l13.444,4.963c0.307,0.205,0.701,0.225,1.026,0.049c0.325-0.174,0.528-0.512,0.528-0.881V29.809
                C40.453,29.441,40.25,29.102,39.925,28.928z"
        />
        <path
          fill="#7F9AD9"
          d="M39.38,28.474c-0.325-0.175-0.72-0.155-1.026,0.049l-7.444,4.964h-6c-0.553,0-1,0.447-1,1v7
                c0,0.553,0.447,1,1,1l13.444,4.964c0.307,0.204,0.701,0.224,1.026,0.049c0.325-0.174,0.528-0.513,0.528-0.881V29.354
                C39.908,28.986,39.705,28.647,39.38,28.474z"
        />
        <path
          fill="#7E99D9"
          d="M38.834,28.02c-0.325-0.176-0.72-0.156-1.026,0.049l-7.444,4.963h-6c-0.553,0-1,0.447-1,1v7
                c0,0.553,0.447,1,1,1l13.444,4.965c0.307,0.203,0.701,0.223,1.026,0.049s0.528-0.514,0.528-0.881V28.9
                C39.362,28.531,39.159,28.193,38.834,28.02z"
        />
        <path
          fill="#7D98D8"
          d="M38.289,27.564c-0.325-0.175-0.72-0.155-1.026,0.049l-7.444,4.964h-6c-0.553,0-1,0.447-1,1v7
                c0,0.553,0.447,1,1,1l13.444,4.964c0.307,0.204,0.701,0.224,1.026,0.049c0.325-0.174,0.528-0.513,0.528-0.881V28.445
                C38.817,28.077,38.614,27.738,38.289,27.564z"
        />
        <path
          fill="#7C97D7"
          d="M37.743,27.109c-0.325-0.174-0.72-0.154-1.026,0.049l-7.444,4.965h-6c-0.553,0-1,0.447-1,1v7
                c0,0.553,0.447,1,1,1l13.444,4.963c0.307,0.205,0.701,0.225,1.026,0.049c0.325-0.174,0.528-0.512,0.528-0.881V27.99
                C38.271,27.623,38.068,27.283,37.743,27.109z"
        />
        <path
          fill="#7B97D6"
          d="M37.198,26.656c-0.325-0.176-0.72-0.156-1.026,0.049l-7.444,4.963h-6c-0.553,0-1,0.447-1,1v7
                c0,0.553,0.447,1,1,1l13.444,4.965c0.307,0.203,0.701,0.223,1.026,0.049s0.528-0.514,0.528-0.881V27.537
                C37.727,27.168,37.523,26.83,37.198,26.656z"
        />
        <path
          fill="#7A96D5"
          d="M36.652,26.201c-0.325-0.175-0.72-0.155-1.026,0.049l-7.444,4.964h-6c-0.553,0-1,0.447-1,1v7
                c0,0.553,0.447,1,1,1l13.444,4.964c0.307,0.204,0.701,0.224,1.026,0.049c0.325-0.174,0.528-0.513,0.528-0.881V27.082
                C37.181,26.714,36.978,26.375,36.652,26.201z"
        />
        <path
          fill="#7995D4"
          d="M36.107,25.746c-0.325-0.174-0.72-0.154-1.026,0.049l-7.444,4.965h-6.001c-0.552,0-1,0.447-1,1v7
                c0,0.553,0.448,1,1,1l13.445,4.963c0.307,0.205,0.701,0.225,1.026,0.049c0.325-0.174,0.528-0.512,0.528-0.881V26.627
                C36.636,26.26,36.433,25.92,36.107,25.746z"
        />
        <path
          fill="#7894D4"
          d="M35.562,25.292c-0.325-0.175-0.72-0.155-1.026,0.049l-7.444,4.964h-6c-0.553,0-1,0.447-1,1v7
                c0,0.553,0.447,1,1,1l13.444,4.964c0.307,0.204,0.701,0.224,1.026,0.049c0.325-0.174,0.528-0.513,0.528-0.881V26.173
                C36.09,25.805,35.887,25.466,35.562,25.292z"
        />
        <path
          fill="#7793D3"
          d="M35.017,24.838c-0.325-0.176-0.72-0.156-1.026,0.049l-7.444,4.963h-6.001c-0.552,0-1,0.447-1,1v7
                c0,0.553,0.448,1,1,1l13.445,4.965c0.307,0.203,0.701,0.223,1.026,0.049s0.528-0.514,0.528-0.881V25.719
                C35.545,25.35,35.342,25.012,35.017,24.838z"
        />
        <path
          fill="#7692D2"
          d="M34.471,24.383c-0.325-0.175-0.72-0.155-1.026,0.049L26,29.396h-6c-0.553,0-1,0.447-1,1v7
                c0,0.553,0.447,1,1,1l13.444,4.964c0.307,0.204,0.701,0.224,1.026,0.049c0.325-0.174,0.528-0.513,0.528-0.881V25.264
                C34.999,24.896,34.796,24.557,34.471,24.383z"
        />
        <path
          fill="#7691D1"
          d="M33.925,23.928c-0.325-0.174-0.72-0.154-1.026,0.049l-7.444,4.965h-6c-0.552,0-1,0.447-1,1v7
                c0,0.553,0.448,1,1,1l13.444,4.963c0.307,0.205,0.701,0.225,1.026,0.049c0.325-0.174,0.528-0.512,0.528-0.881V24.809
                C34.453,24.441,34.25,24.102,33.925,23.928z"
        />
        <path
          fill="#7590D0"
          d="M33.38,23.474c-0.325-0.175-0.72-0.155-1.026,0.049l-7.444,4.964h-6c-0.552,0-1,0.447-1,1v7
                c0,0.553,0.448,1,1,1l13.445,4.964c0.307,0.204,0.701,0.224,1.026,0.049c0.325-0.174,0.528-0.513,0.528-0.881V24.354
                C33.908,23.986,33.705,23.647,33.38,23.474z"
        />
        <path
          fill="#748FD0"
          d="M32.834,23.02c-0.325-0.176-0.72-0.156-1.026,0.049l-7.444,4.963h-6c-0.552,0-1,0.447-1,1v7
                c0,0.553,0.448,1,1,1l13.444,4.965c0.307,0.203,0.701,0.223,1.026,0.049s0.528-0.514,0.528-0.881V23.9
                C33.362,23.531,33.159,23.193,32.834,23.02z"
        />
        <path
          fill="#738ECF"
          d="M32.289,22.564c-0.325-0.175-0.72-0.155-1.026,0.049l-7.444,4.964h-6c-0.552,0-1,0.447-1,1v7
                c0,0.553,0.448,1,1,1l13.445,4.964c0.307,0.204,0.701,0.224,1.026,0.049c0.325-0.174,0.528-0.513,0.528-0.881V23.445
                C32.817,23.077,32.614,22.738,32.289,22.564z"
        />
        <path
          fill="#728DCE"
          d="M31.743,22.109c-0.325-0.174-0.72-0.154-1.026,0.049l-7.444,4.965h-6c-0.552,0-1,0.447-1,1v7
                c0,0.553,0.448,1,1,1l13.444,4.963c0.307,0.205,0.701,0.225,1.026,0.049c0.325-0.174,0.528-0.512,0.528-0.881V22.99
                C32.271,22.623,32.068,22.283,31.743,22.109z"
        />
        <path
          fill="#718DCD"
          d="M31.198,21.656c-0.325-0.176-0.72-0.156-1.026,0.049l-7.444,4.963h-6c-0.553,0-1,0.447-1,1v7
                c0,0.553,0.447,1,1,1l13.445,4.965c0.307,0.203,0.701,0.223,1.026,0.049s0.528-0.514,0.528-0.881V22.537
                C31.727,22.168,31.523,21.83,31.198,21.656z"
        />
        <path
          fill="#708CCC"
          d="M30.652,21.201c-0.325-0.175-0.72-0.155-1.026,0.049l-7.444,4.964h-6c-0.553,0-1,0.447-1,1v7
                c0,0.553,0.447,1,1,1l13.444,4.964c0.307,0.204,0.701,0.224,1.026,0.049c0.325-0.174,0.528-0.513,0.528-0.881V22.082
                C31.181,21.714,30.978,21.375,30.652,21.201z"
        />
        <path
          fill="#6F8BCB"
          d="M30.107,20.747c-0.325-0.175-0.72-0.155-1.026,0.049l-7.444,4.964h-6.001c-0.552,0-1,0.447-1,1v7
                c0,0.553,0.448,1,1,1l13.445,4.963c0.307,0.205,0.701,0.225,1.026,0.049c0.325-0.174,0.528-0.512,0.528-0.881V21.627
                C30.636,21.26,30.433,20.92,30.107,20.747z"
        />
        <path
          fill="#6E8ACB"
          d="M29.562,20.292c-0.325-0.175-0.72-0.155-1.026,0.049l-7.444,4.964h-6c-0.552,0-1,0.447-1,1v7
                c0,0.553,0.448,1,1,1l13.445,4.964c0.307,0.204,0.701,0.224,1.026,0.049c0.325-0.174,0.528-0.513,0.528-0.881V21.173
                C30.09,20.805,29.887,20.466,29.562,20.292z"
        />
        <path
          fill="#6D89CA"
          d="M29.017,19.837c-0.325-0.175-0.72-0.155-1.026,0.049l-7.444,4.963h-6.001c-0.552,0-1,0.447-1,1v7
                c0,0.553,0.448,1,1,1l13.445,4.965c0.307,0.203,0.701,0.223,1.026,0.049s0.528-0.514,0.528-0.881V20.718
                C29.545,20.35,29.342,20.011,29.017,19.837z"
        />
        <path
          fill="#6C88C9"
          d="M28.471,19.383c-0.325-0.175-0.72-0.155-1.026,0.049L20,24.396h-6c-0.552,0-1,0.447-1,1v7
                c0,0.553,0.448,1,1,1l13.445,4.964c0.307,0.204,0.701,0.224,1.026,0.049c0.325-0.174,0.528-0.513,0.528-0.881V20.264
                C28.999,19.896,28.796,19.557,28.471,19.383z"
        />
      </g>
      <path
        fill="#6781BF"
        d="M28.471,19.383c-0.325-0.175-0.72-0.155-1.026,0.049L20,24.396h-6c-0.552,0-1,0.447-1,1v7
            c0,0.553,0.448,1,1,1h6l7.444,4.964c0.307,0.204,0.701,0.224,1.026,0.049c0.325-0.174,0.528-0.513,0.528-0.881V20.264
            C28.999,19.896,28.796,19.557,28.471,19.383z"
      />
      <path
        fill="#FFFFFF"
        d="M26.471,18.119c-0.324-0.175-0.719-0.155-1.025,0.049L18,23.132h-6c-0.552,0-1,0.447-1,1v7
            c0,0.553,0.448,1,1,1h6l7.445,4.964c0.307,0.204,0.701,0.224,1.025,0.049C26.797,36.971,27,36.632,27,36.264V19
            C27,18.632,26.797,18.293,26.471,18.119z"
      />
      <path fill="#FFFFFF" d="M29,25c1.656,0,3,1.343,3,3s-1.344,3-3,3V25z" />
      <g>
        <path
          fill="#FFFFFF"
          d="M16.974,10.85h-0.938l-1.106-3.208l-1.106,3.208h-0.939L11.21,5.912h1.156l1.016,3.319l1.1-3.333h0.924
                l1.1,3.333l1.016-3.319h1.127L16.974,10.85z"
        />
        <path
          fill="#FFFFFF"
          d="M23.162,10.814l-0.449-1.1h-2.072l-0.449,1.1h-1.099l2.101-4.938h0.994l2.102,4.938H23.162z M21.677,7.172
                l-0.651,1.59h1.303L21.677,7.172z"
        />
        <path fill="#FFFFFF" d="M27.557,10.85h-0.953l-1.982-4.938h1.191l1.281,3.453l1.281-3.453h1.164L27.557,10.85z" />
      </g>
    </svg>
  );
};

export default wav;
