import React from 'react';
import classnames from 'classnames';
import CurrencyInput from '@base/containers/currency-input';

const Edit: React.FC = (props: any) => {
  const { errors = {} } = props;

  return (
    <div>
      <div className={classnames({ 'is-required': errors?.isRequired })}>
        <CurrencyInput currencyDisabled={true} {...props} />
      </div>
      {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
    </div>
  );
};

export default Edit;
