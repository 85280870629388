import React from 'react';
import TextView from '@base/containers/view-field/text/view';
import { useRecoilValue } from 'recoil';
import { defaultCurrencySelector } from '@base/recoil/selectors';
import { moneyFormat } from '@base/utils/helpers';

const View: React.FC = (props: any) => {
  const { value } = props; // {currency: '', value: ''}
  //recoil
  const defaultCurrency: any = useRecoilValue(defaultCurrencySelector);

  const text = `${value?.currency || defaultCurrency?.code || ''} ${moneyFormat(value?.value || 0)}`;

  return <TextView value={text ?? ''} />;
};

export default View;
