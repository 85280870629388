export default {
  // default options
  defaultHTagName: 'h1',
  labelHN1: 'One (largest)',
  labelHN2: 'Two',
  labelHN3: 'Three',
  labelHN4: 'Four',
  labelHN5: 'Five',
  labelHN6: 'Six (smallest)',
  labelHBlock: 'Heading',
  labelHTrait: 'Size',
  //placeholder: 'Insert your heading text here',
};
