import { MiModal, SpanLang } from '@base/components';
import { Button, Input } from '@base/components/form';
import TuiEditor from '@base/components/form/tui-editor';
import { t } from 'i18next';
import React, { useState } from 'react';
import EmailPhoneAutoComplete from '../email-phone-auto-complete';

function SendSmsModal(props: any) {
  const { isOpen, handleClose, handleSend } = props;

  const [smsTo, setSmsTo] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const renderFields = () => {
    return (
      <div className="pd-t-15 scroll-box" style={{ height: 'calc(100vh - 190px)' }}>
        <div className="form-group">
          <label className="form-item-title wd-150">
            Subject<span className="tx-danger">*</span>
          </label>
          <div className="flex-fill">
            <Input value={''} onChange={(newValue: string) => {}} />
          </div>
        </div>

        <div className="form-group">
          <label className="form-item-title wd-150">
            From<span className="tx-danger">*</span>
          </label>
          <div className="flex-fill">
            <EmailPhoneAutoComplete
              single={true}
              showAvatar={true}
              showEmail={false}
              showPhone={true}
              isDisabled={true}
              value={''}
              onChange={() => {}}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="form-item-title wd-150">
            To<span className="tx-danger">*</span>
          </label>
          <div className="flex-fill">
            <EmailPhoneAutoComplete
              single={true}
              showAvatar={true}
              showEmail={false}
              showPhone={true}
              // isDisabled={true}
              value={''}
              onChange={() => {}}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="form-item-title wd-150">
            Message<span className="tx-danger">*</span>
          </label>
          <div className="flex-fill">
            <TuiEditor value={''} onChange={() => {}} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <MiModal title={t('Send SMS')} size="lg" isOpen={isOpen} onClose={handleClose}>
      <MiModal.Body>
        <div className="pos-relative">
          <div className="bd-b pd-y-5">
            <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
              <span>
                <span className="tx-danger">*</span>
                <span>is required field</span>
              </span>
            </div>
          </div>
          <form className="form">{renderFields()}</form>
        </div>
      </MiModal.Body>
      <MiModal.Footer>
        <div className="d-flex justify-content-end">
          <button type="button" className="btn btn-outline-secondary mg-r-5" onClick={handleClose}>
            <SpanLang keyLang="Cancel" />
          </button>
          <button type="button" className="btn btn-primary" onClick={handleSend}>
            <SpanLang keyLang="Send" />
          </button>
        </div>
      </MiModal.Footer>
    </MiModal>
  );
}

export default SendSmsModal;
