import React, { FC } from 'react';
import { Button, FormIcon } from '@base/components/form';
import classNames from 'classnames';
import { ListType } from '@base/types/enums';

const AVAILABLE_LIST_TYPES: {
  [key: string]: any;
} = {
  [ListType.LIST]: {
    id: 'list',
    label: 'List',
    icon: 'List',
  },
  [ListType.GRID]: {
    id: 'grid',
    label: 'Grid',
    icon: 'Grid',
  },
  [ListType.SPLIT]: {
    id: 'split',
    label: 'Split',
    icon: 'Sidebar',
  },
  [ListType.CALENDAR]: {
    id: 'calendar',
    label: 'Calendar',
    icon: 'Calendar',
  },
  [ListType.KANBAN]: {
    id: 'kanban',
    label: 'Kanban',
    icon: 'Trello',
  },
};

export interface IToolbarListTypeButton {
  listTypes: ListType[];
  listTypeStr: ListType;
  onChangeListType: (listType: ListType) => void;
}

export const ToolbarListTypeButton: React.FC<IToolbarListTypeButton> = (
  props: IToolbarListTypeButton,
) => {
  const { listTypes, listTypeStr = ListType.LIST, onChangeListType } = props;

  const onChanged = (typeStr: ListType) => {
    onChangeListType && onChangeListType(typeStr);
  };

  return (
    <div className="dropdown">
      <Button
        icon={AVAILABLE_LIST_TYPES?.[listTypeStr]?.icon}
        color="white"
        className="btn btn-white btn-icon"
        data-han-tooltip="List type"
        data-toggle="dropdown"
      />
      <div className="dropdown-menu">
        {listTypes.map((str: ListType, i: number) => {
          const listTypeData = AVAILABLE_LIST_TYPES?.[str];
          return (
            <a
              key={i}
              role="button"
              className={classNames('dropdown-item', {
                active: str === listTypeStr,
              })}
              onClick={() => onChanged(str)}
            >
              <i className="mg-r-5">
                <FormIcon icon={listTypeData.icon} size={16} />
              </i>
              {listTypeData.label}
            </a>
          );
        })}
      </div>
    </div>
  );
};
