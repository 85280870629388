import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Icon from '@base/assets/icons/svg-icons';
import UploadDropArea from '@base/components/form/upload/drop-area';
import UploadFile from '@base/components/form/upload/file';
import NoData from '@base/components/no-data';
import { useAttachmentsByMenu } from '@base/services/attachment-service';
import { SpanLang } from '@base/components';
import useMutationPost from '@base/hooks/useMutationPost';
import { ATTACHMENT_ADD_ITEM } from '@base/services/graphql/attachment';
import { BaseMutationResponse, BaseResponse } from '@base/types/interfaces/response';
import { useDownloadObjectMutation, useUploadMutation } from '@base/hooks/useFileUploadMutation';
import ProgressBar from '@base/components/form/progress-bar';
import Loading from '@base/components/loading';
import AttachmentCard from './attachment-card';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { nanoid } from '@base/utils/helpers';

interface TimeAttachmentsProps {
  listType?: string;
  menuSource: string;
  menuSourceId: string;
}

const zip = new JSZip();
const LIMIT = 100;

const TEST_DATA = [
  {
    contentType: 'text/plain',
    createdAt: '2022-08-08',
    id: '242a08ba-340a-49e8-9936-dabb5b5158b6',
    name: 'test3.txt',
    objectId: 'JCoIt0YnT9SMkPb5OI2L4Q==',
    objectUrl: 'https://api.habin.io/v1/core/storage/object/roiytp4gr787jdro65hutdcmhr/test3.txt',
    size: 835,
  },
  {
    contentType: 'application/pdf',
    createdAt: '2022-08-08',
    id: '242a1069-9955-46d7-a8ce-56696bed112b',
    name: 'CTA.pdf',
    objectId: 'JCoQZqgHRSC6QBF/sZHu+Q==',
    objectUrl: 'https://api.habin.io/v1/core/storage/object/roiby3iey7n1bq1ynf95drxq9r/CTA.pdf',
    size: 1004237,
  },
  {
    contentType: 'image/png',
    createdAt: '2022-08-08',
    id: '242a3454-573b-42b3-85d7-c1a6eb755a4f',
    name: '1_s3_cors.png',
    objectId: 'JCo0T/SNQiWrl6fX/+VTaA==',
    objectUrl:
      'https://api.habin.io/v1/core/storage/object/roideu9wtibnmkhzw9m993kupy/1_s3_cors.png',
    size: 12812,
  },
];

/**
 *
 * @param props
 * @returns
 */
const TimeAttachment = (props: TimeAttachmentsProps) => {
  let { listType = 'list', menuSource, menuSourceId } = props;

  // state
  const [attachments, setAttachments] = useState<any>([]);
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);
  const [uploadFiles, setUploadFiles] = useState<any>([]);
  const [curFileIndex, setCurFileIndex] = useState<number>(-1);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState<number>(-1);
  const [curDownloadIndex, setCurDownloadIndex] = useState<number>(-1);
  const [lastDownloadedIndex, setLastDownloadedIndex] = useState<number>(-1);
  const [progressParts, setProgressParts] = useState<any>([]);

  /** ================================= HOOK ====================================== */
  const { data, isLoading, refetch } = useAttachmentsByMenu(menuSource, menuSourceId, LIMIT);

  //set list state
  useEffect(() => {
    if (data?.results) {
      //// console.log('data hook', data?.results);
      setAttachments(data.results);
    } else {
      setAttachments([]); //TEST_DATA
    }
  }, [data]);

  /* ================================== mutations ====================================== */
  // upload
  const mUpload: any = useUploadMutation<BaseMutationResponse>(
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //toast.success('Uploaded successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        //// console.log('mutation error', error);
        toast.error('Uploaded failed: ' + JSON.parse(error).message);
      },
    },
    (pEvent: ProgressEvent, partsNumber: number, partIndex: number, uploadId?: string) =>
      uploadProgressHandler(pEvent, partsNumber, partIndex, uploadId),
  );

  //save to db
  const mAddAttachment: any = useMutationPost<BaseResponse<string>>(
    ATTACHMENT_ADD_ITEM,
    'builtin_createAttachment',
    {
      onSuccess: (res: any) => {
        //toast.success('Attachment added!');
      },
      onError: (error: any, variables: any, context: any) => {
        //// console.log('mutation error', error);
        toast.error('Updated attachment failed: ' + JSON.parse(error).message);
      },
    },
  );

  //download mutation
  const mDownload: any = useDownloadObjectMutation<BaseMutationResponse>({
    onSuccess: (data: any, variables: any, context: any) => {
      //toast.success('Uploaded successfully!');
    },
    onError: (error: any, variables: any, context: any) => {
      //// console.log('mutation error', error);
      toast.error('Downloaded failed: ' + JSON.parse(error).message);
    },
  });

  //upload success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mUpload);
    if (mUpload.isSuccess) {
      //set progress to 100
      const newUploadFiles = [...uploadFiles];
      newUploadFiles[curFileIndex].percentCompleted = 100;
      setUploadFiles(newUploadFiles);
      //save to DB
      const params = {
        source: {
          menu: menuSource,
          id: menuSourceId,
        },
        objectId: mUpload.data.id, //upload id
        objectUrl: mUpload.data.url, //download url
        name: newUploadFiles[curFileIndex].file.name,
        size: newUploadFiles[curFileIndex].file.size,
        contentType: newUploadFiles[curFileIndex].file.type,
      };
      mAddAttachment.mutate({ attachment: params });
    }
  }, [mUpload.isSuccess]);

  //update db success
  useEffect(() => {
    if (mAddAttachment.isSuccess) {
      //refetch(); //load list
      //insert new to state
      const newAttachments = [...attachments];
      newAttachments.push({
        id: mAddAttachment.data.id,
        name: uploadFiles[curFileIndex].file.name,
        size: uploadFiles[curFileIndex].file.size,
        contentType: uploadFiles[curFileIndex].file.type,
        objectId: mUpload.data.id, //upload id
        objectUrl: mUpload.data.url, //download url
        createdAt: new Date().toISOString(), //'2022-08-08'
      });
      setAttachments(newAttachments);

      //next file uploading
      setLastUploadedFileIndex(curFileIndex);
    }
  }, [mAddAttachment.isSuccess]);

  /* ================================== END mutations ====================================== */

  /* ================================== Download ALL ====================================== */
  useEffect(() => {
    if (curDownloadIndex !== -1) {
      const params = {
        //id: attachments[curDownloadIndex].objectId,
        url: attachments[curDownloadIndex].objectUrl,
      };
      mDownload.mutate(params);
    }
  }, [curDownloadIndex]);

  //next file - last file
  useEffect(() => {
    if (lastDownloadedIndex === -1) {
      return;
    }
    const isLastFile = lastDownloadedIndex === attachments.length - 1;
    const nextFileIndex = isLastFile ? -1 : curDownloadIndex + 1;
    setCurDownloadIndex(nextFileIndex);
    //if last, generate zip
    if (isLastFile) {
      zip.generateAsync({ type: 'blob' }).then(function (blob) {
        saveAs(blob, `attachment_${nanoid()}.zip`);
        setIsDownloadingAll(false);
      });
      //reset
      setCurDownloadIndex(-1);
      setLastDownloadedIndex(-1);
    }
  }, [lastDownloadedIndex]);

  //download success
  useEffect(() => {
    //// console.log('<<< download completed useEffect >>>', mDownload);
    //reference: https://base64.guru/converter/encode/image
    if (mDownload.isSuccess) {
      //zip
      const base64Data = mDownload.data.substring(mDownload.data.indexOf(',') + 1);
      zip.file(attachments[curDownloadIndex].name, base64Data, { base64: true });
      //next download
      setLastDownloadedIndex(curDownloadIndex);
    }
  }, [mDownload.isSuccess]);

  //download all
  const handleDownloadAll = async () => {
    setIsDownloadingAll(true);
    if (attachments.length > 0) {
      if (curDownloadIndex === -1) {
        setCurDownloadIndex(lastDownloadedIndex === -1 ? 0 : lastDownloadedIndex + 1);
      }
    }
  };
  /* ================================== End ALL ====================================== */

  /* ================================== HANDLER ====================================== */
  //calculate progress for parts
  const uploadProgressHandler = async (
    progressEvent: ProgressEvent,
    numberParts: number,
    partIndex: number,
    uploadId?: string,
  ) => {
    if (progressEvent.loaded >= progressEvent.total) return;
    const currentProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //set parts upload progress
    setProgressParts((progressParts: any[]) => {
      progressParts[partIndex] = currentProgress;
      const sum = progressParts.reduce((acc: any, curr: any) => acc + curr);

      //set file progress
      const newUploadFiles = [...uploadFiles];
      newUploadFiles[curFileIndex].percentCompleted = Math.round(sum / numberParts);
      newUploadFiles[curFileIndex].uploadId = uploadId || '';
      setUploadFiles(newUploadFiles);

      return progressParts;
    });
  };

  //upload files change, start upload files
  useEffect(() => {
    if (uploadFiles.length > 0) {
      if (curFileIndex === -1) {
        setCurFileIndex(lastUploadedFileIndex === -1 ? 0 : lastUploadedFileIndex + 1);
      }
    }
  }, [uploadFiles]);

  //upload current file
  useEffect(() => {
    //// console.log('curFileIndex', curFileIndex);
    if (curFileIndex !== -1) {
      mUpload.mutate(uploadFiles[curFileIndex].file);
    }
  }, [curFileIndex]);

  //next file - last file
  useEffect(() => {
    if (lastUploadedFileIndex === -1) {
      return;
    }
    const isLastFile = lastUploadedFileIndex === uploadFiles.length - 1;
    const nextFileIndex = isLastFile ? -1 : curFileIndex + 1;
    setCurFileIndex(nextFileIndex);
  }, [lastUploadedFileIndex]);

  //files select
  const handleFileChange = (files: any) => {
    const newFiles = files.map((_file: any) => ({ file: _file, percentCompleted: 0 }));
    setUploadFiles(newFiles);
  };

  //delete in state
  const handleRemoveItem = (id: string) => {
    const newAttachments = [...attachments];
    const fIndex = newAttachments.findIndex((_ele: any) => _ele.id === id);
    if (fIndex > -1) {
      newAttachments.splice(fIndex, 1);
    }
    setAttachments(newAttachments);
  };

  //// console.log('attachments state', attachments);
  //render
  return (
    <div className="pos-relative">
      {(attachments.length === 0 || isLoading) && <NoData icon={'FileText'} iconType={'feather'} />}
      {/* FOR tablet */}
      {listType === 'grid' && (
        <div className="card-file-columns pd-20">
          {!isLoading &&
            attachments.length > 0 &&
            attachments.map((_item: any, index: number) => {
              return (
                <AttachmentCard
                  key={index}
                  listType={listType}
                  menuSource={menuSource}
                  menuSourceId={menuSourceId}
                  item={_item}
                  //getList={refetch}
                  onRemoveItem={handleRemoveItem}
                />
              );
            })}
          <div className="card card-file file-upload">
            <UploadDropArea
              showBrowse={true}
              files={[]}
              onDrop={handleFileChange}
              saving={mUpload.isLoading}
            />
          </div>
        </div>
      )}
      {/* FOR desktop */}
      {listType === 'list' && (
        <>
          {attachments.length > 0 &&
            attachments.map((_item: any, index: number) => {
              return (
                <AttachmentCard
                  key={index}
                  listType={listType}
                  menuSource={menuSource}
                  menuSourceId={menuSourceId}
                  item={_item}
                  //getList={refetch}
                  onRemoveItem={handleRemoveItem}
                />
              );
            })}
          <div className="mg-t-10">
            <UploadFile
              multiple={true}
              canDrop={true}
              autoUpload={false}
              //imageUpload={true}
              onChange={handleFileChange}
              isSaving={mUpload.isLoading}
            />
          </div>
          {uploadFiles.map((_upload: any, index: number) => (
            <ProgressBar
              key={index}
              fileName={_upload.file.name}
              fileSize={_upload.file.size}
              percentCompleted={_upload.percentCompleted}
              uploadId={_upload.uploadId}
            />
          ))}
          {!isLoading && attachments.length > 0 && (
            <button type="button" className="btn btn-link" onClick={handleDownloadAll}>
              <span className="mg-r-5">{Icon('file_zip')}</span>
              <SpanLang keyLang={'common_section_attachment_download_all'} />
              {isDownloadingAll && (
                <span
                  className="spinner-border spinner-border-sm mg-l-10"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          )}
        </>
      )}
      {isLoading && <Loading />}
    </div>
  );
};

export default React.memo(TimeAttachment);
