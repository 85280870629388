import { Canvas, Portal, SpanLang } from '@base/components';
import { Select } from '@base/components/form';
import { useMenuTemplates } from '@base/services/setting-service';
import { ETemplateGroupNum } from '@base/types/enums';
import { ITemplate } from '@base/types/interfaces/setting';
import GrapesTSViewFieldView from '@base/containers/view-field/grapes-ts/view';
import React, { useState } from 'react';

interface PreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  templateGroup: string;
  previewTemplate?: ITemplate;
  onInsert: (item: ITemplate) => void;
}
const PreviewModal: React.FC<PreviewModalProps> = (props: PreviewModalProps) => {
  const { isOpen, onClose, templateGroup, onInsert, previewTemplate } = props;
  const { data: templatesReps, isLoading: isLoadingList } = useMenuTemplates({
    templateGroup: ETemplateGroupNum[templateGroup] ?? 0,
  });
  const [itemSelected, setSelected] = useState<ITemplate>(
    previewTemplate ?? { id: '', name: 'No Selected' },
  );
  const [htmlJson, setHtmlJson] = useState(
    previewTemplate ? JSON.parse(previewTemplate.html ?? '{}') : { html: '', css: '' },
  );
  const onSelect = (opt: ITemplate) => {
    setSelected(opt);
    setHtmlJson(JSON.parse(opt.html ?? '{}'));
  };
  const handleSave = () => {
    if (itemSelected?.id !== '') {
      onInsert && onInsert(itemSelected);
      onClose();
    }
  };
  return (
    <>
      <Portal>
        <Canvas
          isVisible={isOpen}
          onCloseSideBar={() => onClose()}
          customStyles={{ width: 'auto' }}
          className={'wd-400'}
        >
          <Canvas.Header title={<SpanLang keyLang="Select Template" />} />
          <Canvas.Body style={{ height: 'calc(100vh - 130px)' }}>
            <>
              {/* Preview */}
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Template</label>
                <div className="col-sm-8">
                  <Select
                    outSide={true}
                    options={templatesReps?.results}
                    onChange={(opt: ITemplate) => onSelect(opt)}
                    selected={itemSelected}
                    value={itemSelected}
                    getOptionValue={(opt: ITemplate) => {
                      return opt.id;
                    }}
                    getOptionLabel={(opt: ITemplate) => {
                      return opt.name;
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">Preview</label>
              </div>
              <div className="pd-15 scroll-box " style={{ height: 'calc(100vh - 250px)' }}>
                <div className="form-group row">
                  {/* <div
                    className="col-sm-12"
                    dangerouslySetInnerHTML={{ __html: itemSelected?.html ?? '' }}
                  ></div> */}
                  {itemSelected?.id != '' && (
                    <GrapesTSViewFieldView showTools={false} value={htmlJson} />
                  )}
                </div>
              </div>
              {/* Select template */}
            </>
          </Canvas.Body>
          <Canvas.Footer
            onClose={() => onClose()}
            onSave={() => handleSave()}
            saveLabel={'Insert'}
          />
        </Canvas>
      </Portal>
    </>
  );
};

export default PreviewModal;
