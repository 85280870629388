import React, { useState, useEffect } from "react";
import { Input, Select } from "@base/components/form";
import { useRecoilValue } from 'recoil';
import { currenciesAtom } from '@base/recoil/atoms';
import { defaultCurrencySelector } from '@base/recoil/selectors';
import classNames from "classnames";

/**
 * 
 * @param {*} props 
 * @returns 
 */
const CurrencyInput: React.FC<any> = (props) => {
  const {
    value, //={currency: 'VND', value: '0'}
    onChange,
    showSymbol = false,
    showInput = true,
    currencyDisabled = false,
    optionValues = [], // [{label, value}] --> amount
    //...rest
  } = props;
  //recoil
  const currencies: any = useRecoilValue(currenciesAtom);
  const defaultCurrency: any = useRecoilValue(defaultCurrencySelector);
  //state
  const [currencyOptions, setCurrencyOptions] = useState<any[]>([]);
  const [selectedValue, setSelectedValue] = useState<any>(null); //{currency: 'VND', value: '0'}

  //init options
  useEffect(() => {
    if (currencies && currencies.length > 0) {
      const newOptions: any[] = [];
      currencies.map((_item: any) => {
        newOptions.push({
          ..._item,
          label: showSymbol ? _item.currencySymbol : _item.currencyName,
          value: _item.code,
        });
      });
      setCurrencyOptions(newOptions);
    }
  }, [currencies]);

  //default change
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
        setSelectedValue({
          ...value,
          currency: value?.currency ? value.currency : defaultCurrency?.code
          // {
          //   ...value.currency,
          //   label: showSymbol ? value.currency.currencySymbol : value.currency.currencyName,
          //   value: value.currency.code,
          // }
        });
      }
    } else {
      // const defaultOption = {
      //   ...defaultCurrency,
      //   label: showSymbol ? defaultCurrency.currencySymbol : defaultCurrency.currencyName,
      //   value: defaultCurrency.code,
      // }
      setSelectedValue({ currency: defaultCurrency?.code, value: 0 });
    }
  }, [value]);

  //amount change
  const handleValueChange = (keyName: string, keyValue: any) => {
    const newValue = { ...selectedValue };
    newValue[keyName] = keyValue;
    setSelectedValue(newValue);
    //callback
    onChange && onChange(newValue);
  }

  //console.log('selectedValue', selectedValue);
  //render
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <span className={classNames("input-group-text", { 'pd-0': !currencyDisabled })}>
          {currencyDisabled ?
            selectedValue?.currency
            :
            <Select
              isSearchable={false}
              outSide={true}
              isDisabled={currencyDisabled}
              ctrlStyles={{
                border: 'none',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                marginRight: 2,
                maxHeight: 39,
                minWidth: 72
              }}
              components={{
                IndicatorSeparator: () => null
              }}
              options={currencyOptions}
              value={currencyOptions.find((_ele: any) => _ele.code === selectedValue?.currency)}
              onChange={(newOption: any) => handleValueChange('currency', newOption.code)}
            />
          }
        </span>
      </div>
      {showInput ?
        <Input
          type='money'
          className='ht-41'
          value={selectedValue?.value}
          onChange={(newValue: string) => handleValueChange('value', newValue)}
        />
        :
        <Select
          isSearchable={false}
          outSide={true}
          options={optionValues}
          value={optionValues.find((_ele: any) => _ele.value === selectedValue?.value)}
          onChange={(newOptionValue: any) => handleValueChange('value', newOptionValue.value)}
        />
      }
    </div>
  );
}

export default CurrencyInput;