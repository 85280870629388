import { Button, Input, Select } from '@base/components/form';
import React, { useEffect, useState } from 'react';
import Icon from '@base/assets/icons/svg-icons';

import { ChevronRight, ChevronLeft } from 'react-feather';
import classNames from 'classnames';

interface ListPaginationProps {
  isSplitMode?: boolean;
  type: 'simple' | 'full';
  currentPage: number;
  itemPerPage: number;
  totalPage: number;
  totalItems: number;
  isPreviousData?: boolean;
  onChange: (page: number, itemPerPage: number) => void;
  enablePerPageOptions?: boolean;
}

const perPageOptions = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 20, label: '20' },
];

const ListPagination = (props: ListPaginationProps) => {
  const {
    type = 'full',
    isSplitMode,
    currentPage,
    totalPage,
    enablePerPageOptions = true,
    itemPerPage,
    totalItems,
    isPreviousData = false,
    onChange,
  } = props;

  const [perPage, setPerPage] = useState<number>(itemPerPage || 10);
  const [page, setPage] = useState<number>(currentPage || 1);

  //init
  useEffect(() => {
    if (itemPerPage != perPage) {
      setPerPage(itemPerPage);
    }
  }, [itemPerPage]);

  //init
  useEffect(() => {
    if (currentPage != page) {
      setPage(currentPage);
    }
  }, [currentPage]);

  const hasMore = page < totalPage;

  //page change next, prev
  const pageHandler = (newPage: number) => {
    const isMoveFirstLast = Math.abs(newPage - page) > 1;

    if (isMoveFirstLast) {
      setPage(newPage);
      //callback
      onChange && onChange(newPage, perPage);
    } else {
      if (newPage > page) {
        // next page
        if (!isPreviousData && hasMore) {
          let nextPg = page + 1;
          setPage(nextPg);
          //callback
          onChange && onChange(nextPg, perPage);
        }
      } else {
        // previous page
        let nextPg = Math.max(page - 1, 0);
        setPage(nextPg);
        //callback
        onChange && onChange(nextPg, perPage);
      }
    }
  };

  //manually page change
  const handlePageChange = (e: any) => {
    setPage(e.target.value);
    //callback
    onChange && onChange(e.target.value, perPage);
  };

  //per page change
  const handlePerPageChange = (newPerPage: number) => {
    setPerPage(newPerPage);
    //callback
    const newPage = newPerPage >= totalItems ? 1 : page;
    onChange && onChange(newPage, newPerPage);
  }

  //render full type
  const renderFullPagination = () => {
    return (
      <div className="list-pagination-wrap">
        <div className="list-pagination">
          <button
            type="button"
            className={classNames('btn', {
              'btn-link link-02 btn-icon': isSplitMode,
              'btn-dark': !isSplitMode,
            })}
            aria-label="move to first"
            disabled={page === 1}
            onClick={() => pageHandler(1)}
          >
            {Icon('move_first')}
            <span className="sr-only">처음으로</span>
          </button>
          <button
            type="button"
            className={classNames('btn', {
              'btn-link link-02 btn-icon': isSplitMode,
              'btn-dark': !isSplitMode,
            })}
            aria-label="previous"
            disabled={page === 1}
            onClick={() => pageHandler(page - 1)}
          >
            <ChevronLeft />
            <span className="sr-only">이전</span>
          </button>
          {isSplitMode && (
            <strong className="mg-x-10">
              {page}/{totalPage}
            </strong>
          )}
          <button
            type="button"
            className={classNames('btn', {
              'btn-link link-02 btn-icon': isSplitMode,
              'btn-dark': !isSplitMode,
            })}
            aria-label="next"
            disabled={isPreviousData || !hasMore}
            onClick={() => pageHandler(page + 1)}
          >
            <ChevronRight />
            <span className="sr-only">다음</span>
          </button>
          <button
            type="button"
            className={classNames('btn', {
              'btn-link link-02 btn-icon': isSplitMode,
              'btn-dark': !isSplitMode,
            })}
            aria-label="move to last"
            disabled={currentPage >= totalPage}
            onClick={() => pageHandler(totalPage)}
          >
            {Icon('move_last')}
            <span className="sr-only">마지막으로</span>
          </button>

          {!isSplitMode && (
            <>
              <input
                type="number"
                className="form-control"
                max={totalPage}
                min={1}
                value={page}
                onChange={handlePageChange}
              />
              / {totalPage}
            </>
          )}
        </div>
        <div className="wd-80">
          {enablePerPageOptions && (
            <Select
              options={perPageOptions}
              //selected={perPage}
              menuPlacement="top"
              value={perPageOptions.find((_ele) => _ele.value == perPage)}
              onChange={(option: any) => handlePerPageChange(option.value)}
            />
          )}
        </div>
      </div>
    );
  }

  //simple type
  const renderSimplePagination = () => {
    return (
      <div className="d-flex justify-content-end align-items-center pagination mg-t-10">
        <Button
          color="link link-02"
          className="btn-icon"
          icon="move_first"
          iconType="custom"
          data-han-tooltip="move to first"
          disabled={page === 1}
          onClick={() => pageHandler(1)}
        />
        <Button
          color="link link-02"
          className="btn-icon"
          icon="ChevronLeft"
          data-han-tooltip="previous"
          disabled={page === 1}
          onClick={() => pageHandler(page - 1)}
        />
        <Button
          color="link link-02"
          className="btn-icon"
          icon="ChevronRight"
          data-han-tooltip="next"
          disabled={isPreviousData || !hasMore}
          onClick={() => pageHandler(page + 1)}
        />
        <Button
          color="link link-02"
          className="btn-icon"
          icon="move_last"
          iconType="custom"
          data-han-tooltip="move to last"
          disabled={currentPage >= totalPage}
          onClick={() => pageHandler(totalPage)}
        />
        <Input
          type="number"
          className="paging-input"
          max={totalPage}
          min={1}
          value={page}
          onChange={handlePageChange}
        />/ {totalPage}
        <div className="wd-80 mg-l-10">
          {enablePerPageOptions && (
            <Select
              options={perPageOptions}
              //selected={perPage}
              menuPlacement="top"
              value={perPageOptions.find((_ele) => _ele.value == perPage)}
              onChange={(option: any) => handlePerPageChange(option.value)}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      {type === 'full' && renderFullPagination()}
      {type === 'simple' && renderSimplePagination()}
    </>
  );
};

export default ListPagination;
