import { SpanLang } from '@base/components';
import ListReactTable8 from '@base/components/list/list-react-table-v8';
import ListTableCellDroplist from '@base/components/list/list-table-cell-droplist';
import { usePublishProductsByGroup } from '@product/product/services/product-service';
import { ColumnDef } from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';
import withTextAndPreviewModal, {
  QuickViewComponentProps,
} from '@base/hooks/hocs/withTextAndPreviewModal';
import { ProductItemTextView, ProductTextView } from '@base/containers/quick-view';

const ProductGroupQuickView = (props: QuickViewComponentProps) => {
  const { id, setLoading } = props;

  const { data, isLoading } = usePublishProductsByGroup({ groupId: id });

  useEffect(() => {
    setLoading && setLoading(isLoading);
  }, [isLoading]);

  const columnsV8 = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'name',
        header: () => <SpanLang keyLang={'Product Name'} />,
        accessorKey: 'name',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <ProductTextView value={{ id: row?.original?.id, name: row?.original?.name }} />
          );
        },
        // width: 'auto',
      },
      {
        id: 'unit',
        header: () => <SpanLang keyLang={'Base Unit'} />,
        accessorKey: 'unit',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          return row?.original?.unit?.name ?? '';
        },
        // width: 'auto',
      },
      {
        id: 'attributes',
        header: () => <SpanLang keyLang={'Attributes'} />,
        // accessorKey: 'attrValues',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          const items: any[] = row?.original?.attributes ?? [];
          return items.length > 0 ? (
            <ListTableCellDroplist
              className="no-padding cursor-default"
              showAvatar={false}
              values={items}
            />
          ) : (
            ''
          );
        },
        // width: 'auto',
      },
      {
        id: 'items',
        header: () => <SpanLang keyLang={'Items'} />,
        // accessorKey: 'items',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          let items: any[] = row?.original?.items ?? [];
          return items.length > 0 ? (
            <ListTableCellDroplist
              className="no-padding cursor-default"
              showAvatar={false}
              values={items}
              cellComponent={(item: any) => (
                <ProductItemTextView value={{ id: item?.id, name: item?.name }} />
              )}
            />
          ) : (
            ''
          );
        },
        // width: 'auto',
      },
    ],
    [data?.data],
  );

  // React-table v8
  const listTable8Props = {
    className: 'product-detail-tb',
    tableClassName: '',
    style: { maxHeight: 'calc(100vh - 290px)' },
    columns: columnsV8,
    data: data?.data ?? [],
    initialState: {
      // pageCount: paging?.totalItems || 0,
      pageSize: data?.paging?.itemPerPage || 15,
      // pageIndex: paging?.currentPage || 1,
      // sorts: [sort],
    },
    // onRowSelect: handleCheckItem,
    onSortBy: (clName: any, isSorted: any) => {
      // isSorted from react-table-v8 that is false, 'asc' or 'desc'
      // // console.log('isSorted', isSorted);
      // if (isSorted !== false) {
      //   let orderBy = isSorted === 'desc' ? DESC : ASC;
      //   setSort({ field: clName, orderBy: orderBy });
      // }
    },
    rowSelected: [],
  };

  return (
    <>
      <div className="pd-20 scroll-box">
        <ListReactTable8 {...listTable8Props} />
      </div>
    </>
  );
};
export default withTextAndPreviewModal(ProductGroupQuickView);
