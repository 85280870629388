import React from 'react';
import { X } from 'react-feather';
import { NoData, Avatar } from '@base/components';
import './style.scss';

/**
 *
 */
const ItemContainer: React.FC<any> = ({
  selectedItems = [],
  onRemoveSelectedItem = null,
  optionLabel = null,
}: any) => {
  return (
    <div className="item-container">
      {selectedItems &&
        selectedItems?.length > 0 &&
        selectedItems.map((item: any, index: number) => {
          return (
            <span key={index} className="item-label rounded-pill">
              <span className="avatar d-inline-block">
                <Avatar
                  url={item?.photo}
                  alt={item?.name}
                  width={25}
                  height={25}
                />
              </span>
              <span className="item-name">{optionLabel ? optionLabel(item) : item?.name}</span>
              <button
                type="button"
                className="btn btn-link btn-icon"
                onClick={() => onRemoveSelectedItem && onRemoveSelectedItem(item)}
              >
                <X />
              </button>
            </span>
          );
        })}
      {selectedItems && selectedItems?.length === 0 && (
        <NoData label="No item(s) selected." icon="Users" />
      )}
    </div>
  );
};

export default ItemContainer;
