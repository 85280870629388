import React, { useCallback, useEffect, useState, FC } from 'react';
import { ChevronDown, RotateCcw, Search, X } from 'react-feather';
import { Input, Select, Switch } from '@base/components/form';
import styled from '@emotion/styled';
import { DateRangeSelection } from '@base/components/form/calendar';

interface ListSearchSplitMode {
  onSubmit: (fieldData: any) => void;
  placeholder?: string;
}

const FormGroup = styled.div`
  .form-control {
    border: 1px solid var(--input-border-color) !important;
  }
`;

export const ListSearchSplitMode = ({ onSubmit, placeholder }: ListSearchSplitMode) => {
  const [isShow, setIsShow] = useState(true);

  const onSubmitHandler = (value: string | null) => {
    onSubmit && onSubmit({ keyword: value });
  };

  useEffect(() => {
    if (!isShow) {
      onSubmitHandler(null);
    }
  }, [isShow]);

  return (
    <>
      <button
        type="button"
        className="btn btn-icon"
        aria-label="open search input"
        onClick={() => setIsShow(!isShow)}
      >
        <Search />
        <span className="sr-only">검색창 열기</span>
      </button>

      {isShow && (
        <div className="input-group split-search">
          <input
            type="search"
            className="form-control"
            onKeyDown={(e: any) => {
              //// console.log('Search ...........=====>', e);
              if (e.key === 'Enter') {
                onSubmitHandler(e?.target?.value);
              }
            }}
            placeholder={placeholder ?? ''}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <Search />
            </span>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              onClick={() => setIsShow(false)}
            >
              <X />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const SearchField = React.memo(({ type, searchKey, data, onChange }: any) => {
  const handleOnChange = (value: any) => {
    onChange && onChange(searchKey, value);
  };

  switch (type) {
    case 'text':
    case 'email':
    case 'search':
      return <Input {...data} onChange={handleOnChange} />;
    case 'switch':
      return <Switch {...data} noLabel={true} />;
    case 'select':
      return (
        <Select outSide {...data} onChange={(selected: any) => handleOnChange(selected?.value)} />
      );
    case 'range':
      return (
        <DateRangeSelection
          onChange={(date: any) => {
            handleOnChange([date?.startDate?.toISOString(), date?.endDate?.toISOString()]);
          }}
          isInline={false}
        />
      );
    case 'custom':
      const Component = data.component;
      return (
        <Component
          {...data.componentProps}
          onChange={(newValue: any) => handleOnChange(data.getParam(newValue))}
        />
      );
    default:
      return null;
  }
});
SearchField.displayName = 'SearchField';

interface ListSearch {
  isSimple?: boolean;
  searchFields?: any[];
  onSubmit?: (fieldData: any) => void;
  placeholder?: string;
  searchValue?: string;
}

const ListSearch = ({
  isSimple,
  searchFields,
  placeholder,
  onSubmit,
  searchValue = '',
}: ListSearch) => {
  const defaultFormData =
    searchFields?.reduce((final, field) => {
      final[field.name] = field.defaultValue ?? '';
      return final;
    }, {}) || {};

  const [isShow, setIsShow] = useState(true);
  const [isAdvanceSearch, setIsAdvanceSearch] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);
  const [keyword, setKeyword] = useState('');

  //init value
  useEffect(() => {
    if (searchValue != keyword) {
      setKeyword(searchValue);
    }
  }, [searchValue]);

  const onInputSubmitHandler = (value: string) => {
    setKeyword(value);
    onSubmit && onSubmit({ ...formData, keyword: value });
  };

  const onFormSubmitHandler = () => {
    onSubmit && onSubmit({ ...formData, ...(keyword ? { keyword } : {}) });
  }; //, [keyword, formData])

  const onResetForm = () => {
    setFormData(defaultFormData);
  }; //, [defaultFormData]);

  useEffect(() => {
    if (!isShow) {
      onInputSubmitHandler('');
    }
  }, [isShow]);

  return isSimple ? (
    <>
      <button
        type="button"
        className="btn btn-icon"
        aria-label="open search input"
        onClick={() => setIsShow(!isShow)}
      >
        <Search />
        <span className="sr-only">검색창 열기</span>
      </button>
      {isShow && (
        <div className="input-group split-search">
          <input
            type="search"
            className="form-control"
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                onInputSubmitHandler(e?.target?.value);
              }
            }}
            placeholder={placeholder ?? ''}
            defaultValue={searchValue}
          />
          <div className="input-group-append">
            <span className="input-group-text tx-gray-400">
              <Search />
            </span>
            <button
              type="button"
              className="btn btn-link link-02 btn-icon"
              onClick={() => {
                setIsShow(false);
                // onInputSubmitHandler("");
              }}
            >
              <X />
            </button>
          </div>
        </div>
      )}
    </>
  ) : (
    <div className="input-group input-group-flush list-search">
      <div className="input-group-prepend">
        <span className="input-group-text tx-gray-400">
          <Search />
        </span>
      </div>
      <div className="d-flex flex-grow-1 pd-0">
        <input
          type="search"
          className="form-control wd-100p bd-0"
          placeholder={placeholder ?? ''}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              onInputSubmitHandler(keyword);
            }
          }}
          onChange={(ev: any) => {
            setKeyword(ev.target.value);
          }}
          value={keyword}
        />
        {searchFields && searchFields?.length > 0 && (
          <button
            type="button"
            className="btn btn-link link-03 btn-icon han-tooltip--left"
            data-han-tooltip="Advanced Search"
            onClick={() => setIsAdvanceSearch(!isAdvanceSearch)}
          >
            {isAdvanceSearch ? <X /> : <ChevronDown />}
          </button>
        )}
      </div>
      {isAdvanceSearch && searchFields && (
        <div className="dropdown-menu show wd-100p pd-0">
          <div className="pd-10 bg-light bd-b">Advanced Search</div>
          <div className="pd-10">
            {searchFields.map((field, i) => (
              <FormGroup className="form-group" key={i}>
                <label className="form-item-title">{field?.label}</label>
                <SearchField
                  type={field?.type}
                  searchKey={field?.name}
                  data={field}
                  onChange={(key: string, value: any) => setFormData({ ...formData, [key]: value })}
                />
              </FormGroup>
            ))}
          </div>
          <div className="pd-10  bd-t text-center">
            <button type="button" className="btn btn-secondary mg-r-10" onClick={onResetForm}>
              <RotateCcw className="mg-r-5" />
              Reset
            </button>
            <button type="button" className="btn btn-primary" onClick={onFormSubmitHandler}>
              <Search className="mg-r-5" />
              Search
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListSearch;
