import React, { Fragment, useState, useEffect } from 'react';
import LanguageModal from '../language-translation-modal';
import { Menu, Item, useContextMenu } from 'react-contexify';
import $ from 'jquery';

import 'react-contexify/dist/ReactContexify.css';
import './style.scss';

const MENU_ID = 'translation-context-menu';

const LanguageTranslation: React.FC<any> = ({ children }) => {
  //const [turnOffTranslate, setTurnOff] = useState(false);
  const [langKey, setLangKey] = useState('');
  const [langWords, setLangWords] = useState('');
  const [languageText, setLanguageText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isKey, setIsKey] = useState(false);
  const translator = true;

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  //
  const handleMouseDownGetTranslateWorkAndKeyLang = (e: any) => {
    if (e.button === 2 && translator) {
      let keyLang = $(e.target).attr('data-lang-id') as string;
      const langText = $(e.target).text();

      setLangKey(keyLang);
      setLangWords(langText);

      // check config user translator
      // if (langText) {
      //   setTurnOff(false);
      // }
    }
  };

  //
  const showModalLanguage = () => {
    setShowModal(true);
  };

  //
  const closeModalLanguage = () => {
    setShowModal(false);
  };

  //
  const onTranslate = (type: string) => {
    // if (langKey == "" && langWords == "") {
    //     alert("please select words");
    //     return;
    // }
    if (type == 'words') {
      setIsKey(false);
      setLanguageText(langWords);
    } else {
      setLanguageText(langKey);
      setIsKey(true);
    }

    showModalLanguage();
  };

  //
  useEffect(() => {
    $(document)
      .off('mousedown.trans')
      .on('mousedown.trans', handleMouseDownGetTranslateWorkAndKeyLang);
  }, []);

  //main render
  return (
    <Fragment>
      <div onContextMenu={show}>{children}</div>
      <Menu id={MENU_ID}>
        <Item onClick={() => onTranslate('words')}>
          Translate Words: <b>{langWords}</b>
        </Item>
        <Item onClick={() => onTranslate('key')}>
          Translate Key: <b>{langKey}</b>
        </Item>
      </Menu>
      {showModal &&
        <LanguageModal
          isKey={isKey}
          showModal={showModal}
          closeModalLanguage={closeModalLanguage}
          keyTranslateWords={languageText}
          translateWords={languageText}
        />
      }
    </Fragment>
  );
};

export default LanguageTranslation;
