// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".crm-tag-name{background-color:var(--background-tag);color:var(--text-color-tag);padding:2px 4px;display:flex;align-items:center;margin:3px;border-radius:3px}.crm-tag-name:hover{color:var(--text-color-tag);opacity:1}.crm-tag-name-remove{color:var(--text-color-tag);opacity:.5}.crm-tag-name-remove:hover{color:var(--text-color-tag);opacity:1}", "",{"version":3,"sources":["webpack://./src/base/components/form/tag-name/styles.scss"],"names":[],"mappings":"AAAA,cACC,sCAAA,CACA,2BAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,UAAA,CACA,iBAAA,CACA,oBACC,2BAAA,CACA,SAAA,CAIF,qBACC,2BAAA,CACA,UAAA,CACA,2BACC,2BAAA,CACA,SAAA","sourcesContent":[".crm-tag-name {\n\tbackground-color: var(--background-tag);\n\tcolor: var(--text-color-tag);\n\tpadding: 2px 4px;\n\tdisplay: flex;\n\talign-items: center;\n\tmargin: 3px;\n\tborder-radius: 3px;\n\t&:hover {\n\t\tcolor: var(--text-color-tag);\n\t\topacity: 1\n\t};\n}\n\n.crm-tag-name-remove {\n\tcolor: var(--text-color-tag);\n\topacity: 0.5;\n\t&:hover {\n\t\tcolor: var(--text-color-tag);\n\t\topacity: 1\n\t};\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crm-tag-name": "crm-tag-name",
	"crm-tag-name-remove": "crm-tag-name-remove"
};
export default ___CSS_LOADER_EXPORT___;
