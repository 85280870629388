import { COLORS } from '@base/config/constant';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import { Button, FormIcon } from '@base/components/form';
import Filter from '@base/components/han-header/filter';
import { IconType } from '@base/types/types/app';
import { confirmAlert } from '@base/components/confirm-alert';
import { ChevronDown, Plus } from 'react-feather';
import { slideToObject } from '@base/utils/helpers/array-utils';
import { keys } from 'lodash';
import { useRecoilState } from 'recoil';
import { deviceAtom } from '@base/recoil/atoms';

interface IButtonHeaderProps {
  icon?: string;
  iconType?: IconType;
  onClick: () => void;
  label: string;
}

export const ButtonHeader: React.FC<IButtonHeaderProps> = ({
  icon = '',
  iconType = 'feather',
  onClick,
  label = '',
}: IButtonHeaderProps) => {
  return (
    <button type="button" className="dropdown-item" onClick={onClick}>
      <FormIcon icon={icon} iconType={iconType} />
      <span className="mg-l-5 pd-t-5 tx-15">{label}</span>
    </button>
  );
};
export interface IHeaderProps {
  splitViewMode?: any;
  titleField?: any;
  menus: any;
  menu: any;
  tabPath: any;
  onMenuChange?: any;
  title: any;
  componentRight?: any;
  componentLeft?: any;
  onExportToPDF?: any;
  onPrint?: any;
  onDelete?: any;
  onSendEmail?: any;
  onMore?: any;
  onNew?: any;
  addOptions?: any;
  addOptionType?: string;
  onClone?: any;
  isDeleting?: any;
  moreActions?: any;
  newActions?: any;
  newActionDefault?: () => {};
  stages?: any;
  onStageChange?: any;
  useNewFilter?: boolean;
  moreButtons?: any;
  onBeforeGoList?: () => void;
}

const HeaderMultiTheme: React.FC<IHeaderProps> = (props: IHeaderProps) => {
  let {
    splitViewMode,
    titleField,
    menus,
    menu,
    tabPath,
    onMenuChange,
    title,
    componentRight,
    componentLeft,
    onExportToPDF,
    onPrint,
    onDelete,
    onSendEmail,
    onMore,
    onNew,
    addOptions,
    addOptionType = 'category',
    onClone,
    isDeleting,
    moreActions,
    newActions,
    newActionDefault,
    stages,
    onStageChange,
    useNewFilter,
    moreButtons,
    onBeforeGoList,
  } = props;

  let categoryOptions = slideToObject(menus, 'value', 'label');

  // const [headerColor, setHeaderColor] = useState(COLORS[0]);
  const [device, setDevice] = useRecoilState(deviceAtom);
  const { headerColor } = device;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  //current path
  let { label, path } = useMemo(
    () => menus.find((item: any) => item.value == menu) || { path: '/' },
    [menus, menu],
  );
  if (tabPath) path = tabPath;

  const confirmDelete = () => {
    // confirmAlert({
    //   title: t('crm_new_common_delete'),
    //   message: t('crm_new_common_delete_msg'),
    //   buttons: [
    //     {
    //       label: 'No',
    //       className: 'btn-secondary',
    //     },
    //     {
    //       label: 'Yes',
    //       className: 'btn-primary',
    //       onClick: onDelete,
    //     },
    //   ],
    // });
    onDelete && onDelete();
  };

  const confirmClone = () => {
    // confirmAlert({
    //   title: t('crm_new_common_clone'),
    //   message: t('crm_new_common_clone_msg'),
    //   buttons: [
    //     {
    //       label: 'No',
    //       className: 'btn-secondary',
    //     },
    //     {
    //       label: 'Yes',
    //       className: 'btn-primary',
    //       onClick: onClone,
    //     },
    //   ],
    // });
    onClone && onClone();
  };

  const newButton = useMemo(() => {
    return (
      onNew && (
        <div className="btn-group dropdown">
          <button type="button" className="btn btn-primary pd-x-10" onClick={() => onNew()}>
            <Plus /> {'New'}
            <span className="sr-only">추가</span>
          </button>
          {addOptions && keys(addOptions).length > 0 && (
            <>
              <button
                type="button"
                className="btn btn-primary dropdown-toggle-split pd-x-5"
                data-toggle="dropdown"
              >
                <ChevronDown />
                <span className="sr-only">추가 옵션 열기</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                {addOptionType === 'category' && (
                  <>
                    {keys(addOptions).map((key, i) => {
                      return (
                        key != menu && (
                          <button
                            key={i}
                            type="button"
                            className="dropdown-item"
                            onClick={() => onNew(key)}
                          >
                            {addOptions[key].icon ? addOptions[key].icon : ''}{' '}
                            {addOptions[key].name}
                          </button>
                        )
                      );
                    })}
                  </>
                )}
                {addOptionType === 'tab' && (
                  <>
                    {keys(addOptions).map((key, i) => (
                      <button
                        key={i}
                        type="button"
                        className="dropdown-item"
                        onClick={() => onNew(key)}
                      >
                        {addOptions[key].icon ? addOptions[key].icon : ''} {addOptions[key].label}
                      </button>
                    ))}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )
    );
  }, [onNew, addOptions, addOptionType]);

  //category change
  const handeFilterChange = (newCategory: string) => {
    if (onMenuChange) {
      onMenuChange(newCategory);
    } else {
      let { path } = menus.find((item: any) => item.value == newCategory);
      navigate(path);
    }
  };

  // render
  return (
    <div className={`view-header bg-${headerColor} new-ui-header-view`}>
      {!splitViewMode && (
        <button
          className="btn btn-link pd-l-0 pd-y-0 btn-back"
          onClick={() => {
            onBeforeGoList && onBeforeGoList();
            navigate(path);
          }}
        >
          <FormIcon icon="ArrowLeft" attrs={{ className: 'mg-r-5' }} />
          {/* <span className="tx-16 tx-bold">{label}</span> */}
        </button>
      )}
      {/* move to another submenu */}
      {!splitViewMode && (
        <Filter
          listFilter={categoryOptions}
          value={menu}
          onChange={handeFilterChange}
          useNewFilter={useNewFilter}
          label={label}
        />
      )}
      {title && <h1 className="view-title mg-l-10 lh-1">{title}</h1>}
      {/* {titleField && (
        <Field
          componentViewProps={{
            className: 'view-title',
          }}
          isLabel={false}
          isHorizontal={true}
          item={titleField}
          isCanEdit={true}
        />
      )} */}
      {componentLeft}

      <div className="button-group flex-shrink-0 mg-l-auto d-flex">
        {componentRight}
        {stages && stages.length > 0 && (
          <div className="process-buttons mg-r-5">
            <button
              type="button"
              //data-han-tooltip="Change stage"
              className="btn btn-success mg-l-5"
              data-toggle="dropdown"
            >
              Change Stage
              <span className="span-icon">
                <ChevronDown />
              </span>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              {stages.map((_stage: any, _index: number) => (
                <ButtonHeader
                  key={_index}
                  label={_stage.label}
                  onClick={() => onStageChange(_stage.value)}
                />
              ))}
            </div>
          </div>
        )}
        {moreButtons?.length > 0 &&
          moreButtons.map((_button: any, _bIndex: number) => (
            <button
              key={_bIndex}
              type="button"
              className="btn btn-info mg-l-5"
              onClick={_button.onClick}
            >
              {_button.name}
            </button>
          ))}
        <div className="process-buttons mg-r-5">
          <button type="button" className="btn btn-info mg-l-5" data-toggle="dropdown">
            More
            <span className="span-icon">
              <ChevronDown />
            </span>
            {isDeleting && (
              <span
                className="spinner-border spinner-border-sm mg-l-5"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            {onExportToPDF && (
              <ButtonHeader
                icon="export_pdf"
                iconType="custom"
                label="Export to PDF"
                onClick={onExportToPDF}
              />
            )}
            {onPrint && <ButtonHeader icon="Printer" label="Print" onClick={onPrint} />}

            {onSendEmail && <ButtonHeader icon="Mail" label="Send mail" onClick={onSendEmail} />}
            {moreActions.map(({ label, ...attrs }: any, index: number) => (
              <ButtonHeader
                key={index}
                label={label}
                icon={attrs?.icon || 'ArrowRightCircle'}
                {...attrs}
              />
            ))}
            {onClone && <ButtonHeader icon="Copy" label="Clone" onClick={confirmClone} />}
            {onDelete && <ButtonHeader icon="Trash" label="Delete" onClick={confirmDelete} />}
          </div>
          {Boolean(newActions.length) && (
            <div className="btn-group dropdown mg-l-5">
              <a
                href="#"
                className="btn btn-primary pd-x-10"
                data-toggle="modal"
                onClick={(e) => {
                  e.preventDefault();
                  newActionDefault && newActionDefault();
                }}
              >
                <FormIcon icon="Plus" />
              </a>
              <button
                type="button"
                className="btn btn-primary dropdown-toggle-split pd-x-5"
                data-toggle="dropdown"
              >
                <FormIcon icon="ChevronDown" />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                {newActions.map((item: any, index: number) => (
                  <a
                    href="#"
                    className="dropdown-item"
                    data-toggle="modal"
                    onClick={item.onClick}
                    key={index}
                  >
                    {item.label}
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
        {newButton}
      </div>
    </div>
  );
};

HeaderMultiTheme.defaultProps = {
  splitViewMode: false,
  titleField: null,
  menus: [],
  menu: 'account',
  title: '',
  componentRight: null,
  componentLeft: null,
  onExportToPDF: null,
  onPrint: null,
  onDelete: null,
  onMore: null,
  onNew: null,
  onClone: null,
  isDeleting: false,
  moreActions: [],
  newActions: [],
  stages: [],
  onStageChange: null,
  useNewFilter: false,
};
export default HeaderMultiTheme;
