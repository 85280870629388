interface internalServerProps {
  resetErrorBoundary: () => void;
}

function InternalServer(props: internalServerProps) {
  const { resetErrorBoundary } = props;
  return (
    <div className="container ht-100p tx-center">
      <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
        <span className="tx-24 lh-1" style={{ textTransform: 'uppercase' }}>
          Error
        </span>
        <div className="tx-72 lh-1">500</div>
        <div className="wd-70p wd-sm-250 wd-lg-300 mg-b-15">
          <img src="/errors/500-error.gif" className="img-fluid" alt="Error 500" />
        </div>
        <h1 className="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5">Internal Server Error</h1>
        <h5 className="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20">
          Oopps. There was an error, please try again later.
        </h5>
        <p className="tx-color-03 mg-b-30">
          The server encountered an internal server error and was unable to complete your request.
        </p>
        <div className="mg-b-40">
          <button className="btn btn-brand-02 bd-0 mg-l-5 pd-sm-x-25">Back to Home</button>
          <button className="btn btn-brand-02 bd-0 mg-l-5 pd-sm-x-25" onClick={resetErrorBoundary}>
            Try again
          </button>
        </div>
      </div>
    </div>
  );
}

export default InternalServer;
