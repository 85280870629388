import { gql } from 'graphql-request';

export const GET_NEXT_ID = gql`
  mutation q($menu: String!) {
    setting_nextId(menu: $menu) {
      code
      setting {
        prefixString
        autoGenerate
        nextNumber
      }
    }
  }
`;

export const GET_PUBLIC_NEXT_ID = gql`
  mutation q($menu: String!, $token: String) {
    tracking_nextId(menu: $menu, token: $token) {
      code
      setting {
        prefixString
        autoGenerate
        nextNumber
      }
    }
  }
`;

export const SETTING_NEXT_ID_UPDATE = gql`
  mutation q($menu: String!, $value: SettingInput) {
    setting_updateNextIdSetting(menu: $menu, value: $value) {
      id
    }
  }
`;
