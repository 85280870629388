export default {
  labelShortText: 'Short Text',
  labelHeading: 'Heading',
  labelTextLine: 'Text',
  labelDivider: 'Divider',
  labelLine: 'Line',
  labelSpace: 'Space',
  labelLink: 'Link',
  labelImage: 'Image',
  labelIcon: 'Icon',
  labelVideo: 'Video',
  labelMap: 'Map',
  labelLinkBLock: 'Link Block',
  labelQuote: 'Quote',
  labelParagraph: 'Paragraph',
  labelTextSection: 'Text Section',
  LabelButtonBlk: 'Link Button',
  labelListItemsBlk: 'List',
  labelGridItemsBlk: 'Grid',
  labelMarketingCta: 'CTA',
  labelMarketingPersonalize: 'Personalize',
  labelCalendar: 'Calendar',
  labelMarketingEvent: 'Event',
  labelMarketingSurvey: 'Survey Form',
  labelMarketingOptionSet: 'Option Set',
  labelMarketingSocial: 'Social Share',
  labelDownloadLink: 'Download link',
  labelAddress: 'Address',
  labelPackage: 'Package',
  labelCoupon: 'Coupon',
  labelPersonalize: 'Personalize',
  labelForm: 'Form',
};
