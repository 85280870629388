import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useDownloadObjectMutation } from '@base/hooks/useFileUploadMutation';
import { toast } from 'react-toastify';
import imageNoPic from '@base/assets/img/no-pic-m.png';
import LazyLoadImage from '@base/components/lazy-load-image';

const Container = styled.div`
  width: 225px;
  height: 225px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 3px;
`;

const View: React.FC<any> = (props: any) => {
  const { value } = props; //value is S3 url
  const loadedRef = useRef(true);
  const [imageData, setImageData] = useState<any>(null);
  const [curValue, setCurValue] = useState('');

  //test image S3
  //id: "JCvIx+XLTZiRfxznEV88HA=="
  //url: "https://api.habin.io/v1/core/storage/object/roihtt9f3pg3trm9duutnz3hdo/image.png"

  //download mutation
  const mDownload: any = useDownloadObjectMutation<any>({
    onSuccess: (data: any, variables: any, context: any) => {
      //toast.success('Uploaded successfully!');
      //// console.log('onSuccess data >>>', data);
      setImageData(data);
    },
    onError: (error: any, variables: any, context: any) => {
      //// console.log('mutation error', error);
      toast.error('There is an error in downloading image: ' + JSON.parse(error).message);
    },
  });

  //re-init
  useEffect(() => {
    //// console.log('value data >>>', value);
    if (value !== curValue) {
      setCurValue(value);
      loadedRef.current = true;
    }
  }, [value]);

  //init load image
  useEffect(() => {
    if (curValue) {
      if (loadedRef.current) {
        const params = {
          id: '',
          url: curValue,
        };
        mDownload.mutate(params);
        return () => {
          loadedRef.current = false;
        };
      }
    }
  }, [curValue]);

  //// console.log('mDownload data >>>', mDownload);
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Container className="photo-view">
        <LazyLoadImage
          url={value ? imageData : imageNoPic}
          thumb={imageNoPic}
          height={225}
          width={225}
        />
      </Container>
    </div>
  );
};

export default View;
