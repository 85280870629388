import React, { useEffect } from 'react';
import classNames from 'classnames';
import { DESC } from '@base/config/constant';
import {
  //Column,
  //ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel, Table,
  //Table,
  useReactTable,
} from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { TableNothing } from '@base/components/list/list-loading';
import Loading from '@base/components/loading';

interface ITableProps {
  className?: string;
  tableClassName?: string;
  style?: any;
  columns: any;
  data: any;
  fetchData?: any;
  isFetching?: boolean;
  fetchingComponent?: React.ReactNode;
  initialState?: any;
  onRowSelect?: (params: any) => void;
  onSortBy?: (clName: string, isSortedDesc: boolean) => void;
  rowSelected?: string[];
  renderBody?: (table: Table<any>) => React.ReactElement;
  isLoading?: boolean;
}

const ListReactTable8: React.FC<ITableProps> = (props) => {
  const {
    className = '',
    tableClassName = '',
    style,
    columns,
    data,
    isFetching = false,
    // fetchingComponent,
    initialState = {
      pageIndex: 0,
      pageSize: 15,
      // sorts: [{ field: 'createdAt', orderBy: DESC }],
    },
    onRowSelect,
    onSortBy,
    rowSelected,
    renderBody: customRenderBody,
    isLoading = false,
  } = props;

  // state
  const [rowSelection, setRowSelection] = React.useState<any>({});

  //reset selection when data changes
  // useEffect(() => {
  //   setRowSelection({});
  // }, [data]);

  // callback selected rows
  useEffect(() => {
    if (Object.keys(rowSelection).length > 0) {
      onRowSelect && onRowSelect(Object.keys(rowSelection));
    } else {
      onRowSelect && onRowSelect([]);
    }
  }, [rowSelection]);

  // listen row selected
  useEffect(() => {
    if (isEmpty(rowSelected) && Object.keys(rowSelection).length > 0) {
      setRowSelection({});
    }
  }, [rowSelected]);

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      // pagination: {
      //   pageIndex: initialState?.pageIndex || 0,
      //   pageSize: initialState?.pageSize || 15,
      // }
    },
    onRowSelectionChange: setRowSelection,
    //option
    getRowId(originalRow: any, index: number, parent?: any) {
      //return row id in select row
      return parent ? [parent.id, originalRow.id].join('.') : originalRow.id;
    },
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    //
    manualSorting: true, //server-side sorting
    debugTable: true,
  });

  // // console.log('rowSelection', rowSelection);
  // // console.log('table data', data);

  // initial state
  useEffect(() => {
    // sorting state
    if (initialState?.sorts?.length > 0) {
      let sortingState: any[] = [];
      initialState.sorts.map((_sort: any) => {
        if (_sort.field) {
          sortingState.push({ id: _sort.field, desc: _sort?.orderBy === DESC ? true : false });
        }
      });
      table.setSorting(sortingState);
    }
    // console.log('pageSize', initialState);
    // paging state
    if (initialState?.pageSize) {
      table.setPageSize(initialState.pageSize || 15);
    } else {
      table.setPageSize(15); //default
    }
  }, []);

  // table header
  const renderHeader = () => (
    <thead>
      {table.getHeaderGroups().map((headerGroup: any, groupIdx: number) => (
        <tr key={groupIdx}>
          {headerGroup.headers.map((header: any, cIdx: number) => {
            // // console.log('table header', header);
            // // console.log('getCanSort', header.column.getCanSort());
            // // console.log('getIsSorted', header.column.getIsSorted());
            return (
              <th
                key={`${groupIdx}-${cIdx}`}
                colSpan={header.colSpan}
                style={{ width: header?.column?.columnDef?.width ?? header?.column?.getSize() }}
                className={classNames('align-middle tb-sel-cell', {
                  sorting: header.column.getCanSort(),
                  sorting_asc: header.column.getIsSorted() === 'asc',
                  sorting_desc: header.column.getIsSorted() === 'desc',
                })}
                onClick={() => {
                  if (header.column.getCanSort()) {
                    header.column.toggleSorting();
                    // sort on server
                    onSortBy && onSortBy(header.column.id, header.column.getNextSortingOrder());
                  }
                }}
              >
                {header.isPlaceholder ? null : (
                  <>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {/* {header.column.getCanFilter() ? <div>Filter Component</div> : null} */}
                  </>
                )}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );

  // table body
  const renderBody = () => {
    if (!!customRenderBody) {
      return customRenderBody(table);
    }

    return (
      <tbody>
        {table.getRowModel().rows.map((row: any, rIdx: number) => {
          return (
            <tr key={rIdx}>
              {row.getVisibleCells().map((cell: any, ceIdx: number) => {
                return (
                  <td
                    {...{
                      key: ceIdx,
                      style: {
                        // width: cell?.column?.getSize(),
                        // minWidth: cell?.column?.getSize(),
                        // maxWidth: cell?.column?.getSize(),
                        // whiteSpace: 'nowrap',
                      },
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        })}
        {!isFetching && data.length === 0 && (
          <tr>
            <td colSpan={columns.length}>
              <TableNothing />
            </td>
          </tr>
        )}
        {isLoading && (
          <tr>
            <td colSpan={columns.length}>
              <Loading />
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  // render
  return (
    <div
      className={classNames('table-responsive bg-white bd rounded scroll-box', className)}
      style={style ?? { maxHeight: 'calc(100vh - 240px)' }}
    >
      <table
        className={classNames('table table-bordered dataTable bd-0 mg-b-0', tableClassName)}
        role="table"
      >
        {renderHeader()}
        {renderBody()}
      </table>
    </div>
  );
};

export default ListReactTable8;
