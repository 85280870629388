import React, { FormEvent, useCallback } from 'react';
import { Select } from '@base/components/form';
import { ReminderState } from '.';

export const typeOptions = [
  {
    value: 'NOTIFY_EMAIL',
    label: 'Email',
  },
  {
    value: 'NOTIFY_POPUP',
    label: 'Pop up',
  },
  {
    value: 'NOTIFY_SMS',
    label: 'SMS',
  },
  {
    value: 'NOTIFY_TEAMCHANNEL',
    label: 'Team Channel',
  },
];

export const timeOptions = [
  {
    value: 15,
    label: '15 minutes ago',
  },
  {
    value: 30,
    label: '30 minutes ago',
  },
  {
    value: 45,
    label: '45 minutes ago',
  },
  {
    value: 60,
    label: '1 hour ago',
  },
  {
    value: 120,
    label: '2 hours ago',
  },
  {
    value: 360,
    label: '6 hours ago',
  },
  {
    value: 720,
    label: '12 hours ago',
  },
  {
    value: 1440,
    label: '1 day ago',
  },
  {
    value: 2880,
    label: '2 days ago',
  },
  {
    value: 10080,
    label: '7 days ago',
  },
];

interface EditProps {
  value: ReminderState;
  onChange: (val: ReminderState) => void;
}

const Edit = (props: EditProps) => {
  const { value, onChange } = props;

  //change flag
  const handleReminderChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      onChange({ ...value, use: e.currentTarget.checked });
    },
    [value],
  );

  //type
  const handleTypeChange = useCallback(
    (type: { label: string; value: string }) => {
      onChange({ ...value, notify: type.value });
    },
    [value],
  );

  //type
  const handleTimeChange = useCallback(
    (time: { label: string; value: number }) => {
      onChange({ ...value, end: time.value });
    },
    [value],
  );
  //render
  return (
    <div className="pos-relative">
      <div className="form-group">
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="reminderTaskSwitch"
            checked={value.use}
            onChange={handleReminderChange}
          />
          <label className="custom-control-label" htmlFor="reminderTaskSwitch">
            Use Reminder
          </label>
        </div>
      </div>
      {value.use && (
        <div className="d-flex mg-x-20">
          <div className="wd-50p mg-r-10">
            <Select
              outSide={true}
              options={typeOptions}
              value={typeOptions.find((el) => el.value === value.notify)}
              onChange={handleTypeChange}
            />
          </div>
          <div className="wd-50p">
            <Select
              outSide={true}
              options={timeOptions}
              value={timeOptions.find((el) => el.value === value.end)}
              onChange={handleTimeChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Edit;
