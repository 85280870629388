import React from 'react';
import _ from 'lodash';
import { SpanLang } from '@base/components';
import { defaultColumnsRender } from '@base/components/list/list-react-table/columns-render';
import { HeaderProps, Row } from 'react-table';
import { ColumnDef } from '@tanstack/react-table';

interface ISelectHeaderCheckboxProps {
  /** react-table's header props */
  headerProps: React.PropsWithChildren<HeaderProps<any>>;
  /** A predicate - based on your business logic - to determine whether a given row should be selectable */
  checkIfRowIsSelectable: (row: Row<any>) => boolean;
  /** Whether to allow page selection. Default: true */
  shouldSelectPage?: boolean;
  callbackFn?: (params: any) => void;
}

/**
 * A convenience method for react-table headers for allowing conditional select
 * @param headerProps react-table's header props
 * @param checkIfRowIsSelectable A predicate - based on your business logic - to determine whether a given row should be selectable
 * @param shouldSelectPage Whether to allow page selection. Default: true
 * @returns Modified `checkboxProps` to enforce the conditional select
 */
export const getSelectHeaderCheckboxProps = ({
  headerProps,
  checkIfRowIsSelectable,
  shouldSelectPage = false,
  callbackFn,
}: ISelectHeaderCheckboxProps) => {
  // Note that in my comments I differentiate between the standard logic and the logic for the conditional select
  const checkIfAllSelectableRowsSelected = (rows: Row<any>[]) =>
    rows.filter(checkIfRowIsSelectable).every((row) => {
      return row.isSelected;
    });
  // Standard: Here we define the selection type for the next click: Select Page / Select All
  const isSelectPage =
    shouldSelectPage &&
    headerProps.page &&
    headerProps.page
      // For conditional select: Filter the rows based on your business logic
      .filter(checkIfRowIsSelectable)
      // Standard: `isSelectPage === true` if some of the rows are not yet selected
      // This (standard) logic might be confusing to understand at first, but - as a side note - the idea is as follows:
      // This is the variable that defines whether the header props that will be received FOR THE NEXT CLICK will be for Select Page or for Select All
      // Try to play this out in your head:
      //  - Initially, none of the rows are selected, so when we clicking the button initially, we will select only the (selectable) rows on the page (i.e. Select Page), hence the next click will be for Select All, hence `isSelectPage` will be `false`
      //  - When clicking again, we will select the rest of the (selectable) rows (i.e. Select All). The next click will again be Select All (for de-selecting all), hence `isSelectPage` will be `false`
      //  - Finally, when clicking again, we will de-select all rows. The next click will be for Select Page, hence `isSelectPage` will `true`
      .some((row) => !row.isSelected);

  // Standard: Get the props based on Select Page / Select All
  const checkboxProps = isSelectPage
    ? headerProps.getToggleAllPageRowsSelectedProps()
    : headerProps.getToggleAllRowsSelectedProps();

  // For conditional select: The header checkbox should be:
  //   - checked if all selectable rows are selected
  //   - indeterminate if only some selectable rows are selected (but not all)
  const disabled = headerProps.rows.filter(checkIfRowIsSelectable).length === 0;
  const checked = !disabled && checkIfAllSelectableRowsSelected(headerProps.rows);
  const indeterminate = !checked && headerProps.rows.some((row) => row.isSelected);

  // For conditional select: This is where the magic happens
  const onChange = () => {
    // If we're in Select All and all selectable rows are already selected: deselect all rows
    let isSelectedAll = checkIfAllSelectableRowsSelected(headerProps.rows);
    if (!isSelectPage && isSelectedAll) {
      headerProps.rows.forEach((row) => {
        headerProps.toggleRowSelected(row.id, false);
      });
    } else {
      // Otherwise:
      // First, define the rows to work with: if we're in Select Page, use `headerProps.page`, otherwise (Select All) use headerProps.rows
      const rows = isSelectPage ? headerProps.page : headerProps.rows;
      // Then select every selectable row
      rows.forEach((row) => {
        const checked = checkIfRowIsSelectable(row);
        headerProps.toggleRowSelected(row.id, checked);
      });
    }
    callbackFn && callbackFn(!isSelectedAll ? 'all' : 'notAll');
  };

  // For conditional select: override checked, indeterminate and onChange - to enforce conditional select based on our business logic
  return {
    ...checkboxProps,
    checked,
    indeterminate,
    onChange,
    disabled,
  };
};

// const cellRenderFnDefault = (col: string, data: any, extraParams: any) => {
//   // // console.log('cellRenderFnDefault');
//   let dataType = typeof data[col];
//   let renderData =
//     dataType != 'undefined'
//       ? dataType == 'string' || dataType == 'number'
//         ? data[col]
//         : JSON.stringify(data[col])
//       : '';
//   // let renderData = 'cellRenderFnDefault';

//   // == 'string' ? data[col] : (data[col] as string)
//   return <>{renderData}</>;
// };

export const makeTableColumns = (
  fields: any[],
  columnRenderRemap: any,
  extraParams: any,
  hiddenColumns: string[],
): any[] => {
  //react-table columns
  let RColumns: any[] = [];
  // loadDefaultColumns
  columnRenderRemap = {
    ...columnRenderRemap,
    ...defaultColumnsRender,
  };

  // // console.log('columnRenderRemap', columnRenderRemap);
  // // console.log('fields', fields);

  //@Trung - render by column map
  // Object.keys(columnRenderRemap).map((_column: any) => {
  //   if (hiddenColumns.indexOf(_column) !== -1) {
  //     return;
  //   }
  //   //find in pagelayout
  //   const fIdx = fields.findIndex((_field: any) => _field.keyName === _column);
  //   if (fIdx > -1) {
  //     let column: any = {
  //       // ...field,
  //       Header: <SpanLang keyLang={fields[fIdx]?.languageKey} />,
  //       accessor: _column,
  //       width: fields[fIdx]?.width ?? 'auto',
  //       disableSortBy: fields[fIdx]?.disableSortBy || false,
  //     };
  //     // defaultRender
  //     let cellRenderFn = defaultColumnsRender._defaultRender;
  //     if (typeof columnRenderRemap[_column] != 'undefined') {
  //       cellRenderFn = columnRenderRemap[_column];
  //     } else {
  //       // // console.log('columnRenderRemap empty', field?.keyName);
  //     }
  //     //create cell
  //     column.Cell = (props: any) => {
  //       if (!props) {
  //         return null;
  //       }
  //       let col: string = _column;
  //       let data: any = props.row.original;
  //       return cellRenderFn(col, data, extraParams);
  //     };
  //     RColumns.push(column);
  //   }
  // });

  // render column order by setting
  fields &&
    fields?.length > 0 &&
    fields?.map((field: any) => {
      // const found = RColumns.find((col) => col.accessor == field.keyName);
      // if (found) {
      //   return;
      // }

      if (hiddenColumns.indexOf(field.keyName) !== -1) {
        return;
      }

      let column: any = {
        // ...field,
        Header: <SpanLang keyLang={field?.languageKey} />,
        accessor: field.keyName,
        width: field?.width ?? 'auto',
        disableSortBy: field?.disableSortBy || false,
      };

      // defaultRender
      let cellRenderFn = defaultColumnsRender._defaultRender;
      if (typeof columnRenderRemap[field.keyName] != 'undefined') {
        cellRenderFn = columnRenderRemap[field.keyName];
      } else {
        // // console.log('columnRenderRemap empty', field?.keyName);
      }

      column.Cell = (props: any) => {
        if (!props) {
          return null;
        }
        let col: string = field.keyName;
        let data: any = props.row.original;
        return cellRenderFn(col, data, extraParams);
      };

      RColumns.push(column);
    });

  return RColumns;
};

export const makeTable8Columns = (
  fields: any[],
  columnRenderRemap: any,
  extraParams: any,
  hiddenColumns: string[],
) => {
  //react-table columns
  let newColumns: any[] = [];

  // loadDefaultColumns
  let columnRender = {
    ...columnRenderRemap,
    ...defaultColumnsRender,
  };

  //// console.log('columnRender', columnRender);
  //// console.log('fields', fields);

  // render column order by setting
  fields.length > 0 &&
    fields.map((field: any) => {
      if (hiddenColumns.indexOf(field.keyName) !== -1) {
        return;
      }
      let column: any = {
        header: () => <SpanLang keyLang={field.languageKey || field.name} />,
        accessorKey: field.keyName,
        enableColumnFilter: false,
        enableSorting: field?.enableSorting ? true : false,
        width: field?.width ?? 'auto',
      };

      // defaultRender
      let cellRenderFn = defaultColumnsRender._defaultRender;
      if (typeof columnRender[field.keyName] != 'undefined') {
        cellRenderFn = columnRender[field.keyName];
      }

      column.cell = (props: any) => {
        if (!props) {
          return null;
        }
        let col: string = field.keyName;
        let data: any = props.row.original;
        return cellRenderFn(col, data, extraParams);
      };

      newColumns.push(column);
    });

  return newColumns;
};

export const makeReactTableColumns = (columns: ColumnDef<any>[]) => {
  return columns?.map((column: any) => {
    return { ...column, width: column?.size ?? 'auto' };
  });
};
