import React from 'react';

interface ISelectTemplateViewProps {
  value: any;
}
const SelectTemplateView: React.FC<ISelectTemplateViewProps> = (
  props: ISelectTemplateViewProps,
) => {
  const { value } = props;
  return <>{value.templateName}</>;
};

export default SelectTemplateView;
