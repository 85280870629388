import React from 'react';
import TextView from '@base/containers/view-field/text/view';
import { convertDateTimeServerToClient } from '@base/utils/helpers/general.utils';

const View: React.FC = (props: any) => {
  const { value } = props;
  return (
    <TextView
      value={
        value.isUnlimit
          ? 'No end date'
          : convertDateTimeServerToClient({
              date: value?.dataTime,
              isTime: false,
              humanize: true,
            }) ?? ''
      }
    />
  );
};

export default View;
