import React, { useState } from 'react';
import classnames from 'classnames';
import Canvas from '@base/components/canvas';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { FormIcon } from '@base/components/form/icon';
import { useRecoilState } from 'recoil';
import { deviceAtom } from '@base/recoil/atoms/app';
import { Select } from '@base/components/form';
import SkinSettings from './skin';
import LanguageSetting from './language';
import { PREFERENCE_MENUS } from '@base/config/preference-menu';
import { ChevronDown, ChevronUp } from 'react-feather';
import classNames from 'classnames';
import HeaderColor from '@base/containers/header-color';
import { Portal } from '@base/components';
import { deleteDB } from 'idb';
import LocalStorage from '@base/utils/storages/ls';
import SessionStorage from '@base/utils/storages/ss';
import { cloneDeep } from 'lodash';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_USER_SETTING } from '@base/services/graphql/setting';

const NavUl = styled.ul`
  margin: 0;
  padding: 0;

  li {
    list-style: none;

    a {
      padding: 5px 0;
      color: var(--color-inverse-01);
      display: block;
      transition: all 0.3s;

      svg {
        margin-right: 5px;
        color: var(--text-color);
      }

      &:hover {
        text-decoration: none;
        color: var(--color-inverse-02);
      }
    }
  }
`;

interface LabelProps {
  menu: any;
  setIsOpen: (isOpen: boolean) => void;
}

const Label = (props: LabelProps) => {
  const { menu, setIsOpen } = props;

  return (
    <div className="cursor-pointer" onClick={() => setIsOpen(menu)}>
      <label
        className={classNames(
          'cursor-pointer tx-sans tx-10 tx-uppercase tx-bold tx-spacing-1 tx-color-02',
          {
            // 'mg-b-15': menu.isOpen,
          },
        )}
      >
        {menu.title}
      </label>
      {menu.isOpen ? (
        <ChevronDown className="mg-l-5 icon-right" size={14} />
      ) : (
        <ChevronUp className="mg-l-5 icon-right" size={14} />
      )}
    </div>
  );
};

interface PreferenceMenuProps {
  isShow: boolean;
  onClose: () => void;
  //theme?: any;
}

const LAYOUT_OPTIONS: any[] = [
  { label: 'Layout 1', value: 'layout1' },
  { label: 'Layout 2', value: 'layout2' },
  { label: 'Layout 3', value: 'layout3' },
];

const PreferenceMenu: React.FC<PreferenceMenuProps> = (props: PreferenceMenuProps) => {
  const { isShow, onClose } = props;

  const [deviceState, setDeviceState] = useRecoilState(deviceAtom);
  const [menus, setMenus] = useState<any[]>(PREFERENCE_MENUS);

  const mChangeUserSetting: any = useMutationPost<BaseMutationResponse>(
    UPDATE_USER_SETTING,
    'setting_updateUserSetting',
    {},
  );

  // device state change
  const handleDeviceChange = (keyAttribute: string, newValue: string) => {
    const cloneData = cloneDeep(deviceState) as any;
    if (cloneData?.[keyAttribute] != newValue) {
      const newDeviceState = {
        ...deviceState,
        [keyAttribute]: newValue,
      };
      setDeviceState(newDeviceState);

      // save setting to db
      const param = {
        userSetting: {
          menu: 'common',
          key: 'general',
          value: JSON.stringify(newDeviceState),
        },
      };
      mChangeUserSetting.mutate(param);
    }
  };

  const setIsOpen = (menu: any) => {
    let nMenus = menus;
    if (menu.isOpen) {
      // just open
      nMenus = menus.map((item: any) => {
        if (menu.id === item.id) {
          item.isOpen = false;
        }
        return item;
      });
    } else {
      nMenus = menus.map((item: any) => {
        if (menu.id === item.id) {
          item.isOpen = true;
        } else {
          item.isOpen = false;
        }
        return item;
      });
    }
    setMenus(nMenus);
  };

  const onUpdateLatestCode = () => {
    // clean index db
    const dbName = 'ncrm';
    deleteDB(dbName);
    // clean in LocalStorage
    // const Ls = new LocalStorage();
    // Ls.clear();
    const Ss = new SessionStorage();
    Ss.clear();
    // hard reload
    const win = window as any;
    win?.location?.reload(true);
    return false;
  };

  return (
    <Portal>
      <Canvas
        //customStyles={{ zIndex: '1052' }}
        //backdropStyles={{ zIndex: '1051' }}
        isVisible={isShow}
        onCloseSideBar={onClose}
        className={'wd-250'}
      >
        <Canvas.Header>
          <h3 className="tx-bold tx-spacing--2 tx-brand-02 mg-b-0">
            Hanbiro
            <span className="tx-normal tx-primary">CRM</span>
          </h3>
        </Canvas.Header>
        <Canvas.Body className="h-100">
          <div
            className="scroll-box pos-relative"
            style={{ height: 'calc(100vh - 70px)', overflowX: 'hidden' }}
          >
            <div className="pd-y-20">
              <div className="d-flex">
                <div className="d-flex w-50 text-center pr-1">
                  <button
                    // onClick={() => handleDeviceChange('device', 'desktop')}
                    className={classnames(
                      `btn d-flex flex-column w-100 align-items-center 
                  btn-${deviceState.device === 'desktop' ? 'primary' : 'secondary'}`,
                    )}
                  >
                    <FormIcon icon="Monitor" iconType="feather" size={20} />
                    Desktop
                  </button>
                </div>
                <div className="d-flex w-50 text-center pl-1">
                  <button
                    // onClick={() => handleDeviceChange('device', 'tablet')}
                    className={classnames(
                      `btn d-flex flex-column w-100 align-items-center 
                  btn-${deviceState.device === 'tablet' ? 'primary' : 'secondary'}`,
                    )}
                  >
                    <FormIcon icon="Smartphone" iconType="feather" size={20} />
                    Tablet
                  </button>
                </div>
              </div>
            </div>
            {menus.length > 0
              ? menus.map((menu: any, index: number) => {
                  return (
                    <div
                      className={classNames('bd-t pd-y-10', {
                        // 'pd-y-20': menu.isOpen,
                        // 'pd-y-5': !menu.isOpen,
                      })}
                      key={index}
                    >
                      <Label menu={menu} setIsOpen={(menu) => setIsOpen(menu)} />
                      <NavUl className={classNames({ hide: !menu.isOpen })}>
                        {menu.children
                          ? menu.children.map((child: any, cIdx: number) => {
                              return (
                                <li key={cIdx}>
                                  <Link to={child.link}>
                                    <FormIcon
                                      icon={child.icon}
                                      iconType={child.iconType}
                                      size={20}
                                      color="gray"
                                    />
                                    {child.title}
                                  </Link>
                                </li>
                              );
                            })
                          : ''}
                      </NavUl>
                    </div>
                  );
                })
              : ''}

            <div className="pd-y-20 bd-t">
              <label className="tx-sans tx-10 tx-uppercase tx-bold tx-spacing-1 tx-color-02 mg-b-15">
                Display Layout
              </label>
              <Select
                options={LAYOUT_OPTIONS}
                value={LAYOUT_OPTIONS?.find((v: any) => v?.value === deviceState?.layout) ?? null}
                onChange={(option: any) => handleDeviceChange('layout', option.value)}
              />
            </div>

            <div className="pd-y-20 bd-t">
              <label className="tx-sans tx-10 tx-uppercase tx-bold tx-spacing-1 tx-color-02 mg-b-15">
                Skins
              </label>
              <SkinSettings
                skin={deviceState?.skin}
                onChange={(newSkin: string) => handleDeviceChange('skin', newSkin)}
              />
            </div>
            <div className="pd-y-20 bd-t">
              <label className="tx-sans tx-10 tx-uppercase tx-bold tx-spacing-1 tx-color-02 mg-b-15">
                Color in the header of all Documents
              </label>
              <HeaderColor
                value={deviceState?.headerColor}
                onChange={(newColor: string) => handleDeviceChange('headerColor', newColor)}
              />
            </div>

            {/* Languages */}
            <div className="pd-y-20 bd-t">
              <label className="tx-sans tx-10 tx-uppercase tx-bold tx-spacing-1 tx-color-02 mg-b-15">
                Languages
              </label>
              <div className="row row-xs">
                <div className="w-100 mg-l-10">
                  <LanguageSetting
                    value={deviceState?.language}
                    onChange={(newLanguage: string) => handleDeviceChange('language', newLanguage)}
                  />
                </div>
              </div>
            </div>

            <div className="pd-y-20 bd-t">
              <label className="tx-sans tx-10 tx-uppercase tx-bold tx-spacing-1 tx-color-02 mg-b-15">
                Translation Editor
              </label>
              <div className="row row-xs">
                <div className="w-100 mg-l-10">
                  <div className="custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="transSwitch"
                      checked={deviceState.enableTrans}
                      onChange={(e: any) => handleDeviceChange('enableTrans', e.target.checked)}
                    />
                    <label className="custom-control-label" htmlFor="transSwitch"></label>
                  </div>
                </div>
              </div>
            </div>
            <div className="pd-y-20 bd-t">
              <label className="tx-sans tx-10 tx-uppercase tx-bold tx-spacing-1 tx-color-02 mg-b-15">
                For Development
              </label>
              <div className="row row-xs">
                <div className="w-100 mg-l-10">
                  <button type="button" className="btn btn-primary" onClick={onUpdateLatestCode}>
                    Update Latest Code
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Canvas.Body>
      </Canvas>
    </Portal>
  );
};

export default PreferenceMenu;
