import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Mail, X, MessageCircle } from 'react-feather';
import { confirmAlert, SpanLang, NoData, Avatar } from '@base/components';
import { UserAutoComplete } from '@base/containers';
import Loading from '@base/components/loading';
import { generateUUID } from '@base/utils/helpers';
import { random } from 'lodash';

interface IAssignRepContainerProps {
  placement?: string;
  items: any;
  isLoading: boolean;
  role?: 'user' | 'sales';
  onDelete?: any;
  onAssign?: any;
}

/**
 *
 * @param {*} props
 * @returns
 */
const AssignRepContainer: React.FC<IAssignRepContainerProps> = (
  props: IAssignRepContainerProps,
) => {
  const {
    role = 'user', //TODO - check menu Opportunity
    placement,
    items,
    isLoading,
    onDelete,
    onAssign,
  } = props;

  //lang
  const { t } = useTranslation();
  // state
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deletedItem, setDeletedItem] = useState<any>(null);

  const loginUser = { id: generateUUID(), name: `Hanbiro User ${random()}` };
  // TODO - get from config

  //delete confirm
  const handleDelete = (item: any) => {
    // confirmAlert({
    //   title: t('crm_new_common_delete'),
    //   message: t('crm_new_common_delete_msg'),
    //   buttons: [
    //     {
    //       label: 'No',
    //       className: 'btn-secondary',
    //     },
    //     {
    //       label: 'Yes',
    //       className: 'btn-primary',
    //       onClick: () => {
    //         setIsDeleting(true);
    //         setDeletedItem(item);
    //         onDelete(item, () => {
    //           setDeletedItem(null);
    //           setIsDeleting(false);
    //         });
    //       },
    //     },
    //   ],
    // });
    setIsDeleting(true);
    setDeletedItem(item);
    onDelete(item, () => {
      setDeletedItem(null);
      setIsDeleting(false);
    });
  };

  //assign to me
  const handleAssignToMe = () => {
    let newUser: any = {
      ...loginUser,
      properties: {
        crmGroups: {
          id: '',
          name: '',
        },
      },
    };
    onAssign(newUser);
  };

  //render items
  const renderItemInfo = (item: any, index: number) => {
    const isAssignedToMe = item?.id === loginUser?.id ? true : false;

    return (
      <div
        key={index}
        className={classNames(
          'd-flex align-items-center mg-b-3 pd-x-10 pd-y-3 justify-content-between',
          {
            'bg-warning rounded-30': isAssignedToMe,
          },
        )}
      >
        <div
          className="media align-items-center flex-grow-1"
          style={{
            maxWidth: isAssignedToMe ? 'calc(100% - 36px)' : 'calc(100% - 108px)',
          }}
        >
          <div className="avatar avatar-xs">
            <Avatar userno={item?.id ?? ''} alt={item?.name} width={26} height={26} />
          </div>
          <div className="media-body mg-l-5 text-truncate">{item?.name || 'Han Test 1'}</div>
        </div>
        <div className="flex-shrink-0">
          {/* {!isAssignedToMe && (
            <>
              <button
                type="button"
                className="btn btn-link btn-icon han-tooltip--top"
                data-han-tooltip="Send Email"
              >
                <Mail className="tx-teal" />
                <span className="sr-only">이메일 보내기</span>
              </button>
              <button
                type="button"
                className="btn btn-link btn-icon han-tooltip--top"
                data-han-tooltip="Chat"
              >
                <MessageCircle className="tx-indigo" /> <span className="sr-only">채팅하기</span>
              </button>
            </>
          )} */}
          <button
            type="button"
            className="btn btn-link btn-icon han-tooltip--top"
            data-han-tooltip="Delete"
            onClick={() => handleDelete(item)}
          >
            {isDeleting && deletedItem?.id === item.id ? (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            ) : (
              <X className="tx-danger" />
            )}
          </button>
        </div>
      </div>
    );
  };

  //render
  return (
    <>
      <div className="pos-relative mg-b-10">
        {(items.length == 0 || isLoading) && <NoData icon={'Users'} iconType={'feather'} />}
        {!isLoading && items.map((_item: any, _index: number) => renderItemInfo(_item, _index))}
        {isLoading && <Loading />}
      </div>
      <UserAutoComplete
        placement={placement}
        single={true}
        showAvatar={true}
        visible={false}
        value={[]}
        onChange={onAssign}
        onAssignToMe={handleAssignToMe}
      />
    </>
  );
};

AssignRepContainer.defaultProps = {
  placement: 'right',
  //menuSource: '',
  //menuSourceId: '',
  //menuTab: '', //on url
  items: [],
  isLoading: false,
  onDelete: () => null,
  onAssign: () => null,
};

export default AssignRepContainer;
