import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Edit2 } from 'react-feather';
import ClickOutside from '@base/components/han-click-outside';
import HanGroupButton from '@base/components/han-group-button';
import { HoverEditContainer, ViewContainer } from '../common/style';
import { useStorageUploadMutation, useUploadMutation } from '@base/hooks/useFileUploadMutation';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import useMutationPost from '@base/hooks/useMutationPost';
import { UPDATE_CUSTOMER } from '@customer/customer/services/graphql';
import { KEY_NAME_CUSTOMER_ID, KEY_NAME_CUSTOMER_PHOTO } from '@customer/customer/config/key-names';
import { MENU_CUSTOMER } from '@base/config/menus';
import { useRecoilState } from 'recoil';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import View from './view';
import Edit from './edit';

const PhotoViewField: React.FC<any> = (props: any) => {
  //// console.log('photo props', props);
  const { menuSource, menuSourceId, value } = props;
  //state
  const [isEdit, setIsEdit] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const [viewData, setViewData] = useRecoilState(viewDataByMenuAtom(menuSource));

  //S3 upload
  const mS3Upload: any = useUploadMutation<BaseMutationResponse>(
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //toast.success('Uploaded successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        //// console.log('mutation error', error);
        toast.success('Uploaded failed: ' + JSON.parse(error).message);
      },
    },
    (pEvent: ProgressEvent, partsNumber: number, partIndex: number) => {
      // console.log('uploading...');
    },
  );

  //Block Storage
  // const mStorageUpload: any = useStorageUploadMutation<BaseMutationResponse>(
  //   {
  //     onError: (error: any, variables: any, context: any) => {
  //       //// console.log('mutation error', error);
  //       toast.success('There is an error during uploading: ' + JSON.parse(error).message);
  //     }
  //   }
  // );

  //update to DB
  const mUpdateCustomer: any = useMutationPost<BaseMutationResponse>(
    UPDATE_CUSTOMER,
    'customer_updateCustomer',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //toast.success('Updated photo successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        //// console.log('mutation error', error);
        toast.success('Updated photo failed: ' + JSON.parse(error).message);
      },
    },
  );

  //upload success
  useEffect(() => {
    if (mS3Upload.isSuccess) {
      if (menuSource === MENU_CUSTOMER) {
        //then update to db
        const params = {
          [KEY_NAME_CUSTOMER_ID]: menuSourceId,
          [KEY_NAME_CUSTOMER_PHOTO]: mS3Upload.data.url, //or mUpload.data.id
        };
        mUpdateCustomer.mutate({ customer: params });
      }
    }
  }, [mS3Upload.isSuccess]);

  //update success
  useEffect(() => {
    if (mUpdateCustomer.isSuccess) {
      if (menuSource === MENU_CUSTOMER) {
        //refresh view - update view data recoil
        const newViewData = _.cloneDeep(viewData);
        const keyIndex = newViewData.layout.data[0].children.findIndex(
          (_ele: any) => _ele.keyName === KEY_NAME_CUSTOMER_PHOTO,
        );
        if (keyIndex > -1) {
          newViewData.layout.data[0].children[keyIndex].data = mS3Upload.data.url;
          setViewData(newViewData);
        }
      }
      //off edit
      setIsEdit(false);
    }
  }, [mUpdateCustomer.isSuccess]);

  //event
  const handleFileChange = (files: any[]) => {
    setFiles(files);
  };

  //upload and save
  const handleUploadAndSave = () => {
    //// console.log('save....', files);
    if (files.length > 0) {
      //upload to file server
      mS3Upload.mutate(files[0]);

      //FOR TEST: removed
      // const formData = new FormData();
      // formData.append('files[0]', files[0]);
      // formData.append('module', 'landingpage');
      // mStorageUpload.mutate(formData);
    }
  };

  return (
    <HoverEditContainer>
      {isEdit ? (
        <ClickOutside onClickOutsite={() => setIsEdit(!isEdit)} onClickInsite={() => null}>
          <div className="d-flex flex-column align-items-center">
            <Edit value={files} onChange={handleFileChange} />
            <HanGroupButton
              className="mg-t-5"
              onClose={() => setIsEdit(false)}
              onSave={handleUploadAndSave}
              isSaving={mS3Upload.isLoading || mUpdateCustomer.isLoading}
            />
          </div>
        </ClickOutside>
      ) : (
        <ViewContainer
          className="wd-100p"
          onClick={() => {
            setIsEdit(true);
          }}
        >
          <View key={menuSourceId} value={value} />
          <Edit2
            className="mg-l-5 icon-edit"
            size={14}
            color="#8392a5"
            onClick={() => {
              setIsEdit(true);
            }}
          />
        </ViewContainer>
      )}
    </HoverEditContainer>
  );
};

export default PhotoViewField;
