import { lazy } from 'react';

export const TextView = lazy(() => import('@base/containers/view-field/text'));
export const DateTimeView = lazy(() => import('@base/containers/view-field/datetime'));
export const TextAreaView = lazy(() => import('@base/containers/view-field/text-area'));
export const DataSourceView = lazy(() => import('@base/containers/view-field/data-source'));
export const MenuSourceSelectView = lazy(
  () => import('@base/containers/view-field/menu-source-select'),
);
export const AddressView = lazy(() => import('@base/containers/view-field/address'));
export const EmailView = lazy(() => import('@base/containers/view-field/email'));
export const PhoneView = lazy(() => import('@base/containers/view-field/phone'));
export const MobileView = lazy(() => import('@base/containers/view-field/mobile'));
export const FaxView = lazy(() => import('@base/containers/view-field/fax'));
export const WebsiteView = lazy(() => import('@base/containers/view-field/website'));
export const IpAddressView = lazy(() => import('@base/containers/view-field/ip-address'));
export const CurrencyView = lazy(() => import('@base/containers/view-field/currency'));
export const CurrencyInputView = lazy(() => import('@base/containers/view-field/currency-input'));
export const AssignRepView = lazy(() => import('@base/containers/view-field/assign-rep'));
export const RankingView = lazy(() => import('@base/containers/view-field/ranking'));
export const Switch = lazy(() => import('@base/containers/view-field/switch'));
export const CountryView = lazy(() => import('@base/containers/view-field/country'));
export const EditorView = lazy(() => import('@base/containers/view-field/editor'));
export const EditorTemplateView = lazy(() => import('@base/containers/view-field/editor-template'));
export const SelectTemplateView = lazy(() => import('@base/containers/view-field/select-template'));
export const PirorityView = lazy(() => import('@base/containers/view-field/priority'));
export const Lookup = lazy(() => import('@base/containers/view-field/lookup'));
export const Radio = lazy(() => import('@base/containers/view-field/radio-group'));
export const Selectbox = lazy(() => import('@base/containers/view-field/selectbox'));
export const Checkbox = lazy(() => import('@base/containers/view-field/checkbox-group'));
export const CheckboxSingle = lazy(() => import('@base/containers/view-field/checkbox'));
// export const Date = lazy(() => import('@base/containers/view-field/date'));
export const Tags = lazy(() => import('@base/containers/view-field/tags'));
export const DurationView = lazy(() => import('@base/components/form/duration'));
export const ReminderView = lazy(() => import('@base/components/form/reminder'));
export const RepeatView = lazy(() => import('@base/components/form/repeat'));
export const RepeatTimeView = lazy(() => import('@base/components/form/repeat-time'));
export const LookupView = lazy(() => import('@base/containers/view-field/lookup'));
export const RadioGroupView = lazy(() => import('@base/containers/view-field/radio-group'));

export const ProductSelect = lazy(() => import('@base/containers/view-field/product-select'));
export const UnLimitDateTimeView = lazy(
  () => import('@base/containers/view-field/unlimit-datetime'),
);
export const DateView = lazy(() => import('@base/containers/view-field/date'));
export const LinkView = lazy(() => import('@base/containers/view-field/link'));
