import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListContainer from './ListContainer';
import ItemContainer from './ItemContainer';
import CoreTree from '@base/components/form/tree/core/tree';
import { Bookmark } from 'react-feather';

import './style.scss';
import Loading from '@base/components/loading';
import classNames from 'classnames';

/**
 * tree data defines: {key: '', title: '', children: []}
 * list data defines: {id, name, label, group}
 * @param {*} props
 */
const TreeListSelect: React.FC<any> = (props: any) => {
  const {
    single = false,
    value, //selected items = [{id, name, label, group}]
    onChange,
    onTreeNodeClick, //For TREE
    fetchTree, // For TREE
    formatTreeNode, // For TREE
    category, //''
    categoryItems = [], //{id, value}
    isCategoryLoading = false,
    listData, //{items, attrs}
    listFilter,
    onFilterChange,
    isListLoading,
  } = props;
  const { t } = useTranslation();
  //state
  //const [node, setNode] = useState(listData?.group); //node id
  const [selectedItems, setSelectedItems] = useState<any>(value || []);

  // init data
  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          if (JSON.stringify(value) !== JSON.stringify(selectedItems)) {
            setSelectedItems(value);
          }
        }
      }
    } else {
      setSelectedItems([]);
    }
  }, [value]);

  //click on node
  // const handleSelectNode = (node: any) => {
  //   setNode(node);
  //   //callback
  //   onTreeNodeClick && onTreeNodeClick(node);
  // };

  //select a item
  const handleSelectItem = (item: any) => {
    if (single) {
      setSelectedItems([item]);
      //callback
      onChange && onChange([item]);
    } else {
      //check exist before push
      const idx = selectedItems.findIndex((ele: any) => ele.id === item.id);
      if (idx === -1) {
        const newItems = [...selectedItems];
        newItems.push(item);
        setSelectedItems(newItems);
        //callback
        onChange && onChange(newItems);
      }
    }
  };

  //remove an item
  const handleRemoveSelectedItem = (item: any) => {
    const newItems = [...selectedItems];
    const idx = newItems.findIndex((el) => el.id === item.id);
    newItems.splice(idx, 1);
    setSelectedItems(newItems);
    //callback
    onChange && onChange(newItems);
  };

  //filter change
  const handleCategoryChange = (categoryKey: string) => {
    const newFilter: any = { ...listFilter };
    if (category === 'rating') {
      newFilter.filter.filters.rating = categoryKey;
    } else {
      newFilter.filter.filters.type = categoryKey;
    }
    onFilterChange(newFilter);
  };

  //render categories list
  const CategoryItemsMemo = useMemo(() => {
    //// console.log('categoryItems', categoryItems);
    return (
      <div className="list-container bd-0">
        <div className="list-users scroll-box">
          {categoryItems.map((_ele: any, index: number) => (
            <div
              key={index}
              className={classNames('media align-items-center', {
                selected:
                  _ele.keyName === listFilter?.filter?.filters?.type ||
                  _ele.keyName === listFilter?.filter?.filters?.rating,
              })}
              onClick={() => handleCategoryChange(_ele.keyName)}
            >
              <Bookmark size="16" className="tx-color-03" />
              <div className="media-body pd-l-15">{t(_ele.languageKey).toString()}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }, [categoryItems, listFilter]);

  //main render
  return (
    <div className="pos-relative tree-list-select">
      {/* selected items */}
      <ItemContainer
        selectedItems={selectedItems}
        onRemoveSelectedItem={handleRemoveSelectedItem}
      />

      {/* filter + list */}
      <div className="d-flex tree-list-container">
        <div className="tree-container">
          <div className="pd-10">
            {fetchTree && (
              <CoreTree
                parsedNode={formatTreeNode}
                source={fetchTree}
                //onClickTitle={handleSelectNode}
              />
            )}
            {categoryItems.length > 0 && CategoryItemsMemo}
            {isCategoryLoading && <Loading />}
          </div>
        </div>

        {/* list */}
        <ListContainer
          //group={node} //current node
          //fetchList={fetchList}
          listData={listData}
          isListLoading={isListLoading}
          selectedItems={selectedItems}
          onSelectItem={handleSelectItem}
        />
      </div>
    </div>
  );
};

export default TreeListSelect;
