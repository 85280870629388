import {EDateRangeType} from "@base/types/enums";

export const dateRangeOptions: any = {
  [EDateRangeType.DATE_RANGE_TODAY]: 'Today',
  [EDateRangeType.DATE_RANGE_YESTERDAY]: 'Yesterday',
  [EDateRangeType.DATE_RANGE_THIS_WEEK]: 'This Week',
  [EDateRangeType.DATE_RANGE_PREVIOUS_WEEK]: 'Previous Week',
  [EDateRangeType.DATE_RANGE_LAST_7_DAYS]: 'Last 7 Days',
  [EDateRangeType.DATE_RANGE_LAST_30_DAYS]: 'Last 30 Days',
  [EDateRangeType.DATE_RANGE_THIS_MONTH]: 'This Month',
  [EDateRangeType.DATE_RANGE_PREVIOUS_MONTH]: 'Previous Month',
  [EDateRangeType.DATE_RANGE_LAST_3_MONTHS]: 'Last 3 Months',
  [EDateRangeType.DATE_RANGE_THIS_QUARTER]: 'This Quarter',
  [EDateRangeType.DATE_RANGE_PREVIOUS_QUARTER]: 'Previous Quarter',
  [EDateRangeType.DATE_RANGE_LAST_6_MONTHS]: 'Last 6 Months',
  [EDateRangeType.DATE_RANGE_THIS_YEAR]: 'This Year',
  [EDateRangeType.DATE_RANGE_PREVIOUS_YEAR]: 'Previous Year',
  [EDateRangeType.DATE_RANGE_LAST_360_DAYS]: 'Last 360 days'
};

export const dateRangeIncludeCustomOptions = {
  ...dateRangeOptions,
  [EDateRangeType.DATE_RANGE_CUSTOM]: 'Customize'
}