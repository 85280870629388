import React from "react";
import classnames from "classnames";
import { FormIcon } from "@base/components/form/icon";
import Avatar from "@base/components/avatar";

import "./styles.scss";

interface TagNameProps {
	className?: string;
	id?: string;
	name: string;
	onDelete?: any;
}

const TagName = ({ id = "", name = "", onDelete = null, className = "" } : TagNameProps) => {
	return (
		<div
			className={classnames(
				"crm-tag-name d-flex align-items-center justify-content-center",
				className
			)}
		>
			{id &&
				<Avatar
					height={18}
					width={18}
					userno={id}
					className="mg-r-5"
					alt={name}
				/>
			}
			<span className="tx-normal" style={{ whiteSpace: "nowrap" }}>
				{name}
			</span>
			{onDelete && (
				<span
					role="button"
					className="crm-tag-name-remove cursor-pointer pd-l-5"
					onClick={() => onDelete()}
				>
					<FormIcon icon="X" iconType='feather' size={14} />
				</span>
			)}
		</div>
	);
}

export default TagName;
