import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinymceEditor: React.FC<any> = (props: any) => {
  const { options = {}, onChange, initialValue, ...attrs } = props;

  const handleEditorChange = (e: any) => {
    onChange && onChange(e.target.getContent());
  };

  const isDark = $('body').attr('data-skin') === 'dark';
  return (
    <Editor
      initialValue={initialValue}
      init={{
        height: 500,
        table_tab_navigation: true, // Enable tab button to focus prev / next cell
        table_responsive_width: true,
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true,
        branding: false,
        statusbar: true,
        toolbar_drawer: 'floating',
        //images_upload_timeout: 10,
        //images_upload_url: '/ngw/app/lib/tinymce/plugins/jbimages/ci/index.php?upload/dropupload', //TODO
        //automatic_uploads: true,
        menubar: false,
        nowrap: false,
        skin: isDark ? 'oxide-dark' : 'oxide',
        content_css: isDark ? 'dark' : 'default',
        plugins: [
          'advlist autolink lists link image anchor',
          'searchreplace visualblocks fullscreen',
          'insertdatetime table nonbreaking hr advcode',
          'imagetools noneditable codesample emoticons hanbiroclip',
          'autoresize',
        ],
        toolbar:
          'fontselect fontsizeselect | align lineheightselect checklist  | bold italic underline | forecolor backcolor | code bullist numlist | link image insertfile table | codesample | insertdatetime emoticons | removeformat | restoredraft | fullscreen',
        ...options,
      }}
      onChange={handleEditorChange}
      //tinymceScriptSrc={`${process.env.PUBLIC_URL}/assets/tinymce/js/tinymce.min.js`}
      {...attrs}
    />
  );
};

export default TinymceEditor;
