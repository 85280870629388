import React, { useEffect } from 'react';
import classnames from 'classnames';
import UnLimitDatePicker from '@base/components/form/unlimit-date-picker';

const Edit: React.FC = (props: any) => {
  const { value = '', onChange = () => {}, errors = {}, attrsValue } = props;
  return (
    <div className={classnames('wd-100p')}>
      <div
        className={classnames({
          'is-required': errors?.isRequired,
        })}
      >
        <UnLimitDatePicker {...props} value={value} />
      </div>
      {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
    </div>
  );
};

export default Edit;
