import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes, Link, useParams, useSearchParams } from 'react-router-dom';
import { ITab, ITabs } from '@base/types/interfaces/app';
import classNames from 'classnames';
import { FormIcon } from '@base/components/form';
import { AlertTriangle, Plus, Repeat } from 'react-feather';

interface ITabsBodyProps {
  tabs: ITab[];
  showTabs?: number;
  currentTab?: string;
  onToggleTabGroup?: () => void;
  toggleLabel?: string;
  onConfigure?: () => void;
}

const ViewTabsHeader = (props: ITabsBodyProps) => {
  const { tabs, showTabs, currentTab, onToggleTabGroup, toggleLabel, onConfigure } = props;
  let defaultTab: ITab | any = undefined;

  if (tabs.length > 0) {
    tabs.forEach((tab) => {
      if (tab.default) {
        defaultTab = tab;
      }
      if (currentTab && currentTab != '' && tab.path == currentTab) {
        defaultTab = tab;
      }
    });
  }

  const [tab, setTab] = useState(defaultTab);

  useEffect(() => {
    if (defaultTab != undefined) {
      setTab(defaultTab);
    }
    //return () => { };
  }, [defaultTab]);

  const toggleTab = useCallback((tab: any) => {
    setTab(tab);
  }, []);

  const showTabsReal = showTabs == tabs.length - 1 ? tabs.length : showTabs;

  return (
    <div className="d-flex flex-column flex-grow-1">
      {onConfigure && (
        <div className="pd-15 mg-b-10 bg-white bd-b text-center">
          <p>
            <AlertTriangle size={16} className="mg-r-5 tx-danger" />
            The configuration is not completed.
          </p>
          <button type="button" className="btn btn-lg btn-primary" onClick={onConfigure}>
            Continue Configuration
          </button>
        </div>
      )}

      <div className="view-tabs flex-grow-1">
        <ul className="nav nav-tabs flex-grow-1 bd-0">
          {showTabsReal &&
            tabs.map((item, index) => {
              // if ('show' in item && !item.show) {
              //   return;
              // }
              return (
                index + 1 < showTabsReal + 1 && (
                  <li
                    key={index}
                    className={classNames('nav-item', {
                      active: tab && item.order == tab.order,
                    })}
                  >
                    <button
                      type="button"
                      className="btn btn-xs btn-move-tab"
                      aria-label="Drag-and-drop to reorder"
                    >
                      <FormIcon icon="move" iconType="icon" />
                      <span className="sr-only">드래그앤드롭으로 이동</span>
                    </button>
                    <Link
                      to={item.path}
                      className="btn nav-link"
                      onClick={(e: any) => {
                        toggleTab(item);
                      }}
                    >
                      {item.label}
                    </Link>
                  </li>
                )
              );
            })}
          {showTabsReal && tabs && tabs?.length > showTabsReal && (
            <li
              className={classNames('nav-item dropdown pd-0-f', {
                active: tab && tab.order > showTabsReal,
              })}
            >
              <button type="button" className="btn nav-link pd-x-20-f" data-toggle="dropdown">
                <Plus className="mg-r-5" />
                {tab && tab.order > showTabsReal ? tab.label : 'More'}
              </button>
              <div
                className={classNames('dropdown-menu dropdown-menu-right bg-white', {
                  f5: tab && tab.order > showTabsReal,
                })}
              >
                {tabs.map((item, index) => {
                  // if ('show' in item && !item.show) {
                  //   return;
                  // }

                  return (
                    index + 1 > showTabsReal && (
                      <div className="dropdown-item" key={index}>
                        <button
                          type="button"
                          className="btn btn-xs btn-move-tab"
                          aria-label="Drag-and-drop to reorder"
                        >
                          <FormIcon icon="move" iconType="icon" />
                          <span className="sr-only">드래그앤드롭으로 이동</span>
                        </button>
                        <Link
                          to={item.path}
                          onClick={(e) => {
                            toggleTab(item);
                          }}
                        >
                          <button
                            type="button"
                            className={classNames('btn', {
                              active: tab && item.order == tab.order,
                            })}
                          >
                            {item.label}
                          </button>
                        </Link>
                      </div>
                    )
                  );
                })}
              </div>
            </li>
          )}
          {onToggleTabGroup && (
            <li className="mg-l-auto mg-r-10">
              <button type="button" className="btn btn-white" onClick={() => onToggleTabGroup()}>
                <Repeat className="mg-r-5 " />
                {toggleLabel}
              </button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

ViewTabsHeader.defaultProps = {
  showTabs: 3,
};

export default ViewTabsHeader;
