import React, { useMemo } from 'react';
import cn from 'classnames';
import { Trash2 } from 'react-feather';
import DropArea from '@base/components/form/upload/drop-area';
import { isString } from 'lodash';

import '../styles.scss';

interface UploadSingleImageProps {
  as?: any;
  //title: string;
  className?: any;
  file: any;
  setFile: any;
  accept: any;
  preview: any;
}

const UploadSingleImage: React.FC<UploadSingleImageProps> = (props: UploadSingleImageProps) => {
  const { as: As, className, file = [], setFile = null, accept = '', preview = true } = props;

  const srcImg = useMemo(() => {
    if (file === null) return null;
    if (file.length === 0 || file === null) {
      return null;
    } else {
      if (isString(file)) {
        return `${file}`;
      } else {
        return window.URL.createObjectURL(file[0]);
      }
    }
  }, [file]);

  return (
    <As className={cn('prod_upload_image', className)}>
      <div className="prod_upload_image_list w-100">
        {preview && file?.length > 0 && (
          <div className="prod_upload_image_item">
            <div className="remove-cover" onClick={() => setFile([])}>
              <Trash2 />
            </div>
            <img src={srcImg || undefined} className={'img-preview'} />
          </div>
        )}

        {file?.length === 0 && (
          <DropArea
            //className="btn prod_upload_dropzone w-100"
            multiple={false}
            viewListFiles={false}
            onDrop={(files: any) => setFile && setFile(files)}
            files={file}
            accept={accept}
          />
        )}
      </div>
    </As>
  );
};

UploadSingleImage.defaultProps = {
  as: 'div',
  preview: true,
};
export default UploadSingleImage;
