import { Checkbox } from '@base/components/form';
import { getSelectHeaderCheckboxProps } from '@base/components/utils/helpers/react-table';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import BTable from 'react-bootstrap/Table';
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table';
import { TableNothing } from '@base/components/list/list-loading';
import Loading from '@base/components/loading';
import styled from '@emotion/styled';
import { DESC } from '@base/config/constant';

const Styles = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;
`;

interface IReactTableProps {
  containerClassName?: string;
  footerClassName?: string;
  className?: string;
  columns: any;
  data: any;
  fetchData?: any;
  isFetching?: boolean;
  fetching?: React.ReactNode;
  controlledPageCount?: any;
  isShowPagination?: boolean;
  renderLimit?: any;
  initialState?: any;
  componentBottom?: any;
  isCheckboxTable?: boolean; // @Todo show checkbox or not
  onCheckedRow?: (params: any) => void;
  onRowClick?: (data: any, ev: any) => void;
  selectedRows?: any[];
  onSortBy?: (clName: string, isSortedDesc: boolean) => void;
}

const ListReactTable: React.FC<IReactTableProps> = (props: IReactTableProps) => {
  const {
    // containerClassName = '',
    // footerClassName = '',
    className = '',
    columns,
    data,
    // fetchData,
    isFetching = false,
    fetching,
    // controlledPageCount,
    // isShowPagination = true,
    // renderLimit = null,
    initialState = {
      pageIndex: 0,
      pageSize: 15,
      selectedIds: [],
      sort: { field: 'createdAt', orderBy: DESC },
    },
    // componentBottom = null,
    onCheckedRow,
    onRowClick,
    onSortBy,
    isCheckboxTable = true,
  } = props;

  //initial selected rows
  let rtSelectedRowIds: any = {};
  if (initialState.selectedIds?.length > 0) {
    initialState.selectedIds.forEach((id: any) => {
      rtSelectedRowIds[id] = true;
    });
  }

  //// console.log('table rtSelectedRowIds', rtSelectedRowIds);
  //// console.log('table columns', columns);

  const {
    getTableProps,
    // getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    // page, // Instead of using 'rows', we'll use page,
    // // which has only the rows for the active page
    // // The rest of these things are super handy, too ;)
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,
    // selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: initialState.pageIndex,
        pageSize: initialState.pageSize,
        selectedRowIds: rtSelectedRowIds,
        sortBy: [
          {
            id: initialState?.sort?.field || 'createdAt',
            desc: initialState?.sort?.orderBy === DESC,
          },
        ],
      },
      manualSortBy: true, //  sort by outside
      getRowId: (originalRow: any): string => {
        return originalRow.id;
      },
      autoResetSelectedRows: true,
    },
    //useFilters,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: any) =>
        isCheckboxTable
          ? [
              // Let's make a column for selection
              {
                id: 'selection',
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: (props: any) => {
                  // reset selectedRowsId base selectedIds
                  props.rows?.map((row: any) => {
                    const rowId = row.original.id;
                    //check row has selected or not
                    const isSelected =
                      initialState.selectedIds.length > 0 &&
                      initialState.selectedIds.includes(rowId);
                    // check and remove selectedRowIds
                    if (!isSelected && selectedRowIds) {
                      delete selectedRowIds[rowId];
                    }
                    row.isSelected = isSelected;
                  });
                  //get props
                  const checkboxProps = getSelectHeaderCheckboxProps({
                    headerProps: props,
                    // Your business logic, e.g.
                    checkIfRowIsSelectable: (row: any) => {
                      return true;
                    },
                    callbackFn: onCheckedRow,
                  });
                  return <Checkbox {...checkboxProps} />;
                },
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: (props: any) => {
                  //set row selected
                  // // console.log('initialState.selectedIds', initialState.selectedIds);

                  // const isSelected = initialState.selectedIds.includes(props.row.original.id);
                  //get props
                  // props.row.toggleRowSelected(isSelected);
                  const toggleRowSelectedProps = props.row.getToggleRowSelectedProps();
                  const { onChange, ...restProps } = toggleRowSelectedProps;
                  return (
                    <Checkbox
                      onChange={(ev: any) => {
                        onChange(ev);
                        onCheckedRow && onCheckedRow(props.row.original.id);
                      }}
                      {...restProps}
                    />
                  );
                },
                // Cell: (props: any) => <Checkbox {...props.row?.getToggleRowSelectedProps()} />,
                width: 25, // custom width
                disableSortBy: true, //disabled sort, filter
              },
              ...columns,
            ]
          : [...columns],
      );
    },
  );

  return (
    <div className={classNames('table-responsive bg-white bd rounded', className)}>
      <BTable {...getTableProps()} className="table-bordered dataTable mg-b-0 bd-0">
        <thead>
          {headerGroups.map((headerGroup: any, groupIdx: number) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={groupIdx}>
              {headerGroup.headers.map((column: any, cIdx: number) => {
                //// console.log('header column', column);
                if (column?.headers?.length > 0) {
                  //merge rows on header
                  const rColumn = column?.depth === 0 ? column : column.headers[0];
                  return (
                    <th
                      {...rColumn.getHeaderProps({
                        ...rColumn.getSortByToggleProps(),
                        style: {
                          width: rColumn?.width,
                          minWidth: rColumn?.width,
                          // maxWidth: column?.width,
                        },
                      })}
                      key={`${groupIdx}-${cIdx}`}
                      className={classNames('align-middle', {
                        sorting: rColumn.canSort && !rColumn.isSorted,
                        sorting_asc: rColumn.isSorted && !rColumn.isSortedDesc,
                        sorting_desc: rColumn.isSorted && rColumn.isSortedDesc,
                        'tb-sel-cell': !rColumn.canSort,
                        'pd-y-5-f text-center': column?.depth === 0,
                      })}
                      onClick={() => {
                        if (!rColumn?.disableSortBy) {
                          onSortBy && onSortBy(rColumn.id, !rColumn.isSortedDesc);
                          rColumn?.toggleSortBy && rColumn?.toggleSortBy(!rColumn.isSortedDesc);
                        }
                      }}
                      rowSpan={column?.depth === 0 ? 1 : headerGroups.length}
                    >
                      {rColumn.render('Header')}
                    </th>
                  );
                } else {
                  //only one row header
                  //or 2 rows header
                  return headerGroups.length === 1 || column.depth === 1 ? (
                    <th
                      {...column.getHeaderProps({
                        ...column.getSortByToggleProps(),
                        style: {
                          width: column?.width,
                          minWidth: column?.width,
                          // maxWidth: column?.width,
                        },
                      })}
                      key={`${groupIdx}-${cIdx}`}
                      className={classNames({
                        sorting: column.canSort && !column.isSorted,
                        sorting_asc: column.isSorted && !column.isSortedDesc,
                        sorting_desc: column.isSorted && column.isSortedDesc,
                        'tb-sel-cell': !column.canSort,
                      })}
                      onClick={() => {
                        if (!column?.disableSortBy) {
                          onSortBy && onSortBy(column.id, !column.isSortedDesc);
                          column?.toggleSortBy && column?.toggleSortBy(!column.isSortedDesc);
                        }
                      }}
                    >
                      {column.render('Header')}
                    </th>
                  ) : null;
                }
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {isFetching ? (
            <tr>
              <td colSpan={columns.length + 1}>{fetching}</td>
            </tr>
          ) : (
            ''
          )}
          {!isFetching &&
            rows.length > 0 &&
            rows.map((row: any, index) => {
              prepareRow(row);
              return (
                <tr
                  // onClick={(e: any) => {
                  //   let foundCheckbox = $(e.target).parent().find('input[type=checkbox]');
                  //   // console.log('checkbox');
                  //   if (foundCheckbox && foundCheckbox.length == 1) {
                  //     return;
                  //   }
                  //   onRowClick && onRowClick(row.original, e);
                  // }}
                  {...row.getRowProps()}
                  key={row.id ?? index}
                >
                  {row.cells.map((cell: any, cIdx: number) => {
                    let isSorted = false;
                    headerGroups[0].headers.map((column: any, colIdx: number) => {
                      if (colIdx === cIdx) {
                        isSorted = column.isSorted;
                      }
                    });
                    return (
                      <td
                        {...cell.getCellProps({
                          style: {
                            width: cell.column.width,
                            minWidth: cell.column.width,
                            // maxWidth: cell.column.width,
                          },
                        })}
                        key={cIdx}
                        className={isSorted ? 'sorting-cell' : 'tb-sel-cell'}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          {!isFetching && rows.length === 0 && (
            <tr>
              <td colSpan={columns.length + 1}>
                <TableNothing />
              </td>
            </tr>
          )}
        </tbody>
      </BTable>
    </div>
  );
};

export default ListReactTable;
