import React from 'react';
import classNames from 'classnames';

/**
 *
 * @param {*} props
 * @returns
 */
const Body = (props: any) => {
  const { className, children, style = {} } = props;

  return (
    <div className={classNames('mail-compose-body ht-100p', className)} style={style}>
      {children}
    </div>
  );
};

export default Body;
