import React from 'react';
import classNames from 'classnames';
import Flag from 'react-world-flags';

//import { Maximize2, Minimize2 } from "react-feather";
//import { useTranslation } from "react-i18next";

/**
 * author: trungtm
 * @param {*} param0
 */
const Header: React.FC<any> = (props: any) => {
  const { data, className, activeKey, activeLabel, onChange } = props;

  //render
  return (
    <div className={classNames('panel-heading', className)}>
      <ul className="nav nav-tabs" role="tablist">
        {/* main items */}
        {data?.map((item: any, index: number) => {
          return (
            !item.other && (
              <li key={index} className="nav-item">
                <a
                  href={`#${item?.key}`}
                  className={classNames('nav-link show', {
                    active: item.key == activeKey,
                  })}
                  role="tab"
                  data-toggle="tab"
                  onClick={() => onChange && onChange(item.key)}
                >
                  {item.icon && <Flag code={item.icon} className="wd-20 mg-r-3" />}
                  <span>{item?.label}</span>
                </a>
              </li>
            )
          );
        })}
        {/* other items */}
        <li className="nav-item">
          <a
            href={activeKey ? activeKey : 'other'}
            className={classNames('nav-link show dropdown')}
            role="tab"
            data-toggle="tab"
          >
            <span
              className="dropdown-toggle"
              id="ddmOther"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {activeLabel ? activeLabel : 'Others'}
            </span>
            <div className="dropdown-menu" aria-labelledby="ddmOther">
              {data?.map((item: any, index: number) => {
                return (
                  item.other && (
                    <a
                      key={index}
                      className="dropdown-item"
                      onClick={() => onChange && onChange(item.key)}
                    >
                      {item.icon && <Flag code={item.icon} className="wd-20 mg-r-3" />}
                      <span>{item?.label}</span>
                    </a>
                  )
                );
              })}
            </div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Header;
