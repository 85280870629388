/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { X, ChevronDown, Plus, Check } from 'react-feather';
import TagName from '../tag-name';

/**
 *
 * @param {*} props
 * @returns
 */
const TagSelect: React.FC<any> = (props: any) => {
  const { single = false, value, onChange, isLoading, options, onSave } = props;
  //state
  const [showDropdown, setShowDropdown] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [tagValue, setTagValue] = useState<any>(null); //[] or {}
  const [tagOptions, setTagOptions] = useState<any>([]);
  //memorize state
  const [tagName, setTagName] = useState('');
  //refs
  const dropdownRef = useRef<any>(null);

  //one-time init
  useEffect(() => {
    if (options) {
      setTagOptions(options);
    }
  }, [options]);

  //outside click dropdown
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef && !dropdownRef?.current?.contains(event?.target)) {
        setShowDropdown(false);
        setShowInput(false);
      }
    };

    window?.addEventListener('mousedown', handleClickOutside);
    return () => {
      window?.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  //init data
  useEffect(() => {
    //// console.log('init value', value);
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          if (JSON.stringify(value) !== JSON.stringify(tagValue)) {
            setTagValue(value);
          }
        } else {
          setTagValue([]);
        }
      } else {
        if (JSON.stringify(value) !== JSON.stringify(tagValue)) {
          setTagValue(value);
        }
      }
    } else {
      setTagValue(null);
    }
  }, [value]);

  //get tag options
  // const fetchOptions = async (inputValue) => {
  //   let data;
  //   let newOptions;
  //   setIsLoading(true);
  //   data = await request(DESK_GET_LIST_TAG);
  //   newOptions = data?.deskTagsGetting?.rows.map((_item) => {
  //     return _item;
  //   });
  //   setTagOptions(newOptions);
  //   setIsLoading(false);
  // };

  //add new tag
  const handleAddNew = () => {
    if (tagName.length > 0) {
      setIsSaving(true);
      const res = onSave(tagName);
      if (res) {
        //reset input
        setTagName('');
        const newTag = res;
        //add to option
        const newOptions = [...tagOptions];
        newOptions.push(newTag);
        setTagOptions(newOptions);
        //add to selected
        handleValueChange(newTag);
      }
      setIsSaving(false);
    }
  };

  //state update
  const handleValueChange = (newValue: any) => {
    if (single) {
      setTagValue(newValue);
      //callback
      onChange && onChange(newValue);
    } else {
      const newTags = [...tagValue];
      //check exist
      const foundIdx = newTags.findIndex((_ele) => _ele.id === newValue.id);
      if (foundIdx === -1) {
        newTags.push(newValue);
      }
      setTagValue(newTags);
      //callback
      onChange && onChange(newTags);
    }
    setShowDropdown(false);
    setShowInput(false);
  };

  //remove selected
  const handleRemoveTag = (index: number) => {
    const newTags = [...tagValue];
    newTags.splice(index, 1);
    setTagValue(newTags);
    //callback
    onChange && onChange(newTags);
  };

  //render
  return (
    <div className={classNames('dropdown', { show: showDropdown })} ref={dropdownRef}>
      {/* <div className="input-group" data-toggle="dropdown"> */}
      <div className="input-group">
        {single && (
          <input
            type="text"
            className="form-control"
            placeholder="Select a tag"
            value={tagValue.name}
            onFocus={() => setShowDropdown(true)}
          />
        )}
        {!single && (
          <div
            className={'form-control d-flex flex-wrap form-control-tags pd-2'}
            style={{ minHeight: 38 }}
            onClick={() => setShowDropdown(true)}
          >
            {tagValue?.map((_item: any, _index: number) => (
              <TagName
                key={_item.id}
                //id={type == "tag" ? null : _item.id}
                name={_item.name}
                onDelete={() => handleRemoveTag(_index)}
              />
            ))}
          </div>
        )}
        <div className="input-group-append">
          <button type="button" className="btn">
            <ChevronDown />
          </button>
        </div>
      </div>
      <div className={classNames('dropdown-menu wd-100p', { show: showDropdown })}>
        {!showInput && (
          <button type="button" className="dropdown-item" onClick={() => setShowInput(true)}>
            <Plus className="mg-r-5" />
            Add new tag
          </button>
        )}
        {showInput && (
          <div className="d-flex align-items-center pd-l-20 pd-r-10">
            <input
              type="text"
              className="form-control"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
            />
            <button type="button" className="btn btn-sm btn-icon" onClick={handleAddNew}>
              {isSaving ? (
                <span
                  className="spinner-border spinner-border-sm mg-r-10"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <Check className="tx-success" />
              )}
            </button>
            <button
              type="button"
              className="btn btn-sm btn-link link-03 btn-icon"
              onClick={() => setShowInput(false)}
            >
              <X />
            </button>
          </div>
        )}
        <div className="dropdown-divider"></div>
        <div style={{ maxHeight: 200, overflowY: 'auto' }}>
          {isLoading && (
            <div className="wrap-hanloading">
              <div className="han-loading" />
            </div>
          )}
          {!isLoading &&
            tagOptions.map((_option: any, index: number) => (
              <button
                key={index}
                className="dropdown-item"
                onClick={() => handleValueChange(_option)}
              >
                {_option.name}
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TagSelect;
