import React, { useState } from 'react';
import { Empty, NoData } from '@base/components';
//import { Tooltip } from '@base/components/form';
import { Edit, Copy } from 'react-feather';
import Loading from '@base/components/loading';
/**
 */
const LanguageListSearch: React.FC<any> = (props: any) => {
  const { keyword, dataListSearch, isLoading, setModifedLangValue, setCopiedLangValue } = props;

  //// console.log('dataListSearch', dataListSearch);

  //main render
  return (
    <div className="pos-relative">
      <div className="table-responsive scroll-box mg-b-10" style={{ maxHeight: '260px' }}>
        <table className="table table-bordered mg-0">
          <thead>
            <tr className="bg-gray-100">
              <th scope="col" className="tx-center">
                Type
              </th>
              <th scope="col" className="tx-center">
                Menu
              </th>
              <th scope="col" className="tx-center">
                Lang Key
              </th>
              <th scope="col" className="tx-center">
                English
              </th>
              <th scope="col" className="tx-center">
                Korea
              </th>
              <th scope="col" className="tx-center">
                Vietnamese
              </th>
              <th scope="col" className="tx-center">
                Japan
              </th>
              <th scope="col" className="tx-center">
                China(ch)
              </th>
              <th scope="col" className="tx-center">
                China(zh)
              </th>
              <th scope="col" className="tx-center">
                Indonesia
              </th>
              {dataListSearch?.length > 0 && (
                <th scope="col" className="tx-center">
                  Actions
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {dataListSearch?.length > 0 &&
              dataListSearch?.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>{item.system || 'All'}</td>
                    <td>{item.menu}</td>
                    <td>{item.langKey}</td>
                    <td>{item.en}</td>
                    <td>{item.ko}</td>
                    <td>{item.vi}</td>
                    <td>{item.jp}</td>
                    <td>{item.ch}</td>
                    <td>{item.zh || ''}</td>
                    <td>{item.ido}</td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <button
                          className="btn btn-link"
                          data-han-tooltip="Edit"
                          onClick={() => setModifedLangValue(item)}
                        >
                          <Edit className="tx-gray-300" />
                        </button>
                        <button
                          className="btn btn-link"
                          data-han-tooltip="Copy"
                          onClick={() => setCopiedLangValue(item)}
                        >
                          <Copy className="tx-gray-300" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            {dataListSearch?.length === 0 && (
              <tr>
                <td colSpan={11}>
                  <NoData />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default LanguageListSearch;
