import dayjs from 'dayjs';
import React, { forwardRef, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { Clock } from 'react-feather';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
interface IProps {
  onChange: (params: any) => void;
  value: string;
}

const TimePicker: React.FC<IProps> = ({ onChange = () => {}, value = '', ...attrs }: IProps) => {
  const valueCustom = useMemo(() => {
    try {
      if (dayjs(value, 'HH:mm', true).isValid()) {
        const date = dayjs(value, 'HH:mm', true);
        return date.toDate();
      }
      return new Date();
    } catch (error) {
      return new Date();
    }
  }, [value]);

  const CustomInput = forwardRef<HTMLInputElement>((props, ref) => (
    <div className="input-group input-date-group dropdown">
      <input disabled type="text" className="form-control" ref={ref} {...props} />
      <div className="input-group-append">
        <span className="input-group-text bg-white tx-gray-300 bd-0">
          <Clock />
        </span>
      </div>
    </div>
  ));
  CustomInput.displayName = 'ReactTimePickerInput';

  return (
    <DatePicker
      customInput={<CustomInput />}
      selected={valueCustom}
      onChange={(date) => {
        let nDate = dayjs(date);
        if (nDate.isValid()) {
          let nValue = nDate.format('HH:mm');
          onChange(nValue);
        }
      }}
      showTimeSelectOnly
      showTimeSelect
      timeFormat="HH:mm"
      dateFormat="HH:mm"
      className="form-control"
      //wrapperClassName="input-time-picker-custom"
      {...attrs}
    />
  );
};

export default TimePicker;
