import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Menu, ChevronRight } from 'react-feather';
import { Link, NavLink } from 'react-router-dom';
import { FormIcon } from '@base/components/form/icon';
import MenuItem from './menu-item/nav.item';
import { nanoid } from '@base/utils/helpers/general.utils';
import { IconType } from '@base/types/types/app';

import './styles.scss';
import { useRecoilState, useRecoilValue } from 'recoil';
import { listFavoriteAtom, pinSubMenuSettingsAtom } from '@base/recoil/atoms';
import { SpanLang } from '@base/components';

interface MenuAsideProps {
  isExpand: boolean;
  setIsExpand: (isExpand: boolean) => void;
  stylesPosition?: any;
}

const MenuAside = (props: MenuAsideProps) => {
  const {
    isExpand,
    setIsExpand,
    stylesPosition,
    //modules,
  } = props;

  //state
  const [isHover, setIsHover] = useState(false);

  const pinSubMenuSettings = useRecoilValue(pinSubMenuSettingsAtom);

  const favoriteList = useRecoilValue(listFavoriteAtom);

  //type IconType = 'feather' | 'main';
  interface Menu {
    id: string;
    label: string;
    name: string;
    icon: {
      icon: string;
      iconType: IconType;
    };
    children: Menu[];
    path: string;
    key: string;
    color: string;
    expand: boolean;
    active: boolean;
  }
  const mainMenu: Array<Menu> = [
    {
      id: nanoid(),
      label: 'Dashboard',
      name: 'dashboard',
      icon: {
        icon: 'dashboard',
        iconType: 'main',
      },
      children: [],
      path: '/analytic',
      key: 'crm_dashboard',
      color: 'rgb(63, 135, 213)',
      expand: false,
      active: false,
    },
    {
      id: nanoid(),
      label: 'My Work',
      name: 'mywork',
      icon: {
        icon: 'activity',
        iconType: 'main',
      },
      children: [],
      path: '/activity',
      key: 'crm_mywork',
      color: 'rgb(210, 65, 67)',
      expand: false,
      active: false,
    },
    {
      id: nanoid(),
      label: 'Desk',
      name: 'desk',
      icon: {
        icon: 'helpdesk',
        iconType: 'main',
      },
      children: [],
      path: '/desk',
      key: 'crm_desk',
      color: 'rgb(255, 136, 0)',
      expand: false,
      active: false,
    },
    {
      id: nanoid(),
      label: 'Customer',
      name: 'customer',
      icon: {
        icon: 'customer',
        iconType: 'main',
      },
      children: [],
      path: '/customer',
      key: 'crm_customer',
      color: 'rgb(63, 135, 213)',
      expand: false,
      active: false,
    },
    {
      id: nanoid(),
      label: 'Product',
      name: 'product',
      icon: {
        icon: 'product',
        iconType: 'main',
      },
      children: [],
      path: '/product',
      key: 'crm_product',
      color: 'rgb(139, 195, 74)',
      expand: false,
      active: false,
    },
    // {
    //   id: nanoid(),
    //   label: 'Order',
    //   name: 'order',
    //   icon: {
    //     icon: 'order',
    //     iconType: 'main',
    //   },
    //   children: [],
    //   path: '/order',
    //   key: 'crm_order',
    //   color: 'rgb(228, 95, 169)',
    //   expand: false,
    //   active: false,
    // },
    // {
    //   id: nanoid(),
    //   label: 'Purchase',
    //   name: 'purchase',
    //   icon: {
    //     icon: 'purchase',
    //     iconType: 'main',
    //   },
    //   children: [],
    //   path: '/purchase',
    //   key: 'crm_purchase',
    //   color: 'rgb(102, 201, 201)',
    //   expand: false,
    //   active: false,
    // },
    // {
    //   id: nanoid(),
    //   label: 'Invoice',
    //   name: 'invoice',
    //   icon: {
    //     icon: 'invoice',
    //     iconType: 'main',
    //   },
    //   children: [],
    //   path: '/invoice',
    //   key: 'crm_invoice',
    //   color: 'rgb(129, 81, 219)',
    //   expand: false,
    //   active: false,
    // },
    // {
    //   id: nanoid(),
    //   label: 'Marketing',
    //   name: 'marketing',
    //   icon: {
    //     icon: 'marketing',
    //     iconType: 'main',
    //   },
    //   children: [],
    //   path: '/marketing',
    //   key: 'crm_marketing',
    //   color: 'rgb(255, 193, 7)',
    //   expand: false,
    //   active: false,
    // },
    // {
    //   id: nanoid(),
    //   label: 'Inventory',
    //   name: 'inventory',
    //   icon: {
    //     icon: 'inventory',
    //     iconType: 'main',
    //   },
    //   children: [],
    //   path: '/inventory',
    //   key: 'crm_inventory',
    //   color: 'green',
    //   expand: false,
    //   active: false,
    // },
    // {
    //   id: nanoid(),
    //   label: 'Sales',
    //   name: 'sales',
    //   icon: {
    //     icon: 'sales',
    //     iconType: 'main',
    //   },
    //   children: [],
    //   path: '/sales',
    //   key: 'crm_sales',
    //   color: 'rgb(45, 175, 191)',
    //   expand: false,
    //   active: false,
    // },
    {
      id: nanoid(),
      label: 'Demo Page',
      name: 'demo-page',
      icon: {
        icon: 'Box',
        iconType: 'feather',
      },
      children: [],
      path: '/demo-page',
      key: 'crm_demo_page',
      color: 'rgb(255, 39, 28)',
      expand: false,
      active: false,
    },
  ];

  //get class
  const getClassMenu = () => {
    if (isExpand) {
      return 'maximize';
    } else {
      if (isHover) {
        return 'minimize maximize';
      } else {
        return 'minimize';
      }
    }
  };

  //main render
  return (
    <aside className={classNames('aside aside-fixed', getClassMenu())} style={stylesPosition}>
      {/* header */}
      <div className="aside-header">
        <a className="aside-logo">
          Hanbiro<span>CRM</span>
        </a>
        <a
          className="aside-menu-link"
          onClick={(e) => {
            e.preventDefault();
            document.body.classList.remove('aside-hover');
            setIsExpand(!isExpand);
          }}
        >
          <Menu />
        </a>
      </div>
      {/* body */}
      <div className="aside-body">
        <div className="nav nav-aside">
          {mainMenu.map((_menu: any, _index: number) => {
            //// console.log('menu', _menu);
            const MenuIcon = _menu.icon;
            const hasSubMenu = false; // hard code by harry for Boss's request
            const TagLink = hasSubMenu ? 'div' : Link;
            const collapseClass = {
              'data-toggle': 'collapse',
              'data-target': `#menu-collapse-${_index}`,
              'data-expanded': 'false',
            };

            // go to pin sub menu
            let menuPath = _menu.path;
            let menuName = _menu.name;
            if (pinSubMenuSettings && pinSubMenuSettings?.[menuName]) {
              menuPath += '/' + pinSubMenuSettings?.[menuName];
            }

            return (
              <MenuItem key={_index} active={_menu.active} show={true} withSub={hasSubMenu}>
                <TagLink
                  to={menuPath}
                  className="nav-link d-flex align-items-center"
                  {...(hasSubMenu ? collapseClass : {})}
                >
                  <>
                    {MenuIcon && (
                      <span className={`main-icon ${_menu.name}`}>
                        <FormIcon icon={MenuIcon.icon} iconType={MenuIcon.iconType} />
                      </span>
                    )}

                    <span className="title">{_menu.label}</span>
                  </>
                </TagLink>

                {hasSubMenu && (
                  <>
                    <span className="arrow-toggle">
                      <ChevronRight />
                    </span>
                    <div
                      className="panel-collapse child_menu_wrapper collapse"
                      id={`menu-collapse-${_index}`}
                    >
                      <ul className="nav nav-aside child_menu">
                        {_menu.children.map((_item: any) => (
                          <li key={_item.id} className="nav-item children_item">
                            <Link to={_item.path} className="nav-link">
                              {_item.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
              </MenuItem>
            );
          })}
          {/* favorite menu */}
          <MenuItem key={101} active={false} show={true} withSub={false}>
            <Link to={''} className="nav-link">
              <span className="main-icon favorite">
                <FormIcon icon="Star" />
              </span>
              <span className="title">Favorites</span>
            </Link>
            <ul>
              {favoriteList?.map((_item: any, index: any) => (
                <Link key={index} to={_item.menuPath} className="nav-link">
                  <span className="title">
                    <SpanLang keyLang={_item.menuTitle} className="pd-0" />
                  </span>
                </Link>
              ))}
            </ul>
          </MenuItem>
        </div>
      </div>
    </aside>
  );
};

export default MenuAside;
