import React, { useCallback, useState, useRef, ChangeEvent } from 'react';
import classNames from 'classnames';
import { FormIcon } from '@base/components/form';
import dayjs from 'dayjs';
import { DatePicker } from '@base/components/date-picker/default';
import { useForm } from 'react-hook-form';
import { Filter } from './timeline';
import { AssignedTo, DateFilter } from '@base/config/constant';
import { useOnClickOutside } from 'usehooks-ts';

interface Form {
  dateFilter: string;
  assignedTo: string;
  action: string[];
}

interface FilterProps {
  onChangeFilter: (val: Filter | null) => void;
}

const actions = [
  {
    label: 'Created',
    value: 'created',
  },
  {
    label: 'Updated',
    value: 'updated',
  },
  {
    label: 'Deleted',
    value: 'deleted',
  },
];

const Filter = (props: FilterProps) => {
  const { onChangeFilter } = props;
  const { register, handleSubmit, watch } = useForm<Form>({
    defaultValues: {
      dateFilter: 'month',
      assignedTo: 'me',
      action: ['created', 'updated', 'deleted'],
    },
  });
  const onSubmit = (data: Form) => {
    onChangeFilter({
      createdAt: {
        from: startDate,
        to: endDate,
      },
      createdBy: data.assignedTo,
      action: data.action,
    });
    setShowFilter(false);
  };

  const [startDate, setStartDate] = useState<Date>(dayjs().startOf('month').toDate());
  const [endDate, setEndDate] = useState<Date>(dayjs().endOf('month').toDate());

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const filterRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   function handleClickOutside(event: any) {
  //     // console.log(event.target.classList);
  //     if (
  //       filterRef.current &&
  //       !filterRef.current.contains(event.target) &&
  //       // !event.target.classList.contains('rdrDayNumber') &&
  //       // !event.target.classList.contains('rdrDay') &&
  //       // !event.target.classList.contains('rdrDays') &&
  //       // !event.target.classList.contains('rdrMonth') &&
  //       // !event.target.classList.contains('rdrMonths') &&
  //       // !event.target.classList.contains('rdrMonthAndYearWrapper') &&
  //       // !event.target.classList.contains('rdrCalendarWrapper') &&
  //       // !event.target.classList.contains('popper') &&
  //       // !event.target.classList.contains('rdrNextPrevButton') &&
  //       !(event.target.classList.length === 0)
  //     ) {
  //       setShowFilter(false);
  //     }
  //   }

  //   // Bind the event listener
  //   filterRef.current?.addEventListener('click', handleClickOutside);
  //   return () => {
  //     // Unbind the event listener on clean up
  //     filterRef.current?.removeEventListener('click', handleClickOutside);
  //   };
  // }, [filterRef]);

  const onChangeStartDate = useCallback((date: Date) => {
    setStartDate(date);
  }, []);

  const onChangeEndDate = useCallback((date: Date) => {
    setEndDate(date);
  }, []);

  const onChangeDate = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let rangeDate: { from: Date; to: Date };
    const {
      currentTarget: { value },
    } = e;
    if (value == 'week') {
      rangeDate = {
        from: dayjs().startOf('week').toDate(),
        to: dayjs().endOf('week').toDate(),
      };
    } else if (value == 'month') {
      rangeDate = {
        from: dayjs().startOf('month').toDate(),
        to: dayjs().endOf('month').toDate(),
      };
    } else if (value == 'year') {
      rangeDate = {
        from: dayjs().startOf('year').toDate(),
        to: dayjs().endOf('year').toDate(),
      };
    } else {
      rangeDate = {
        from: dayjs().startOf('day').toDate(),
        to: dayjs().endOf('day').toDate(),
      };
    }
    setStartDate(rangeDate.from);
    setEndDate(rangeDate.to);
  }, []);

  const resetData = useCallback(() => {
    onChangeFilter(null);
    // setShowFilter(false);
  }, []);

  const dateFilterWatch = watch('dateFilter');

  const handleClickOutside = () => {
    // Your custom logic here
    console.log('clicked outside');
    setShowFilter(false);
  };

  const handleClickInside = () => {
    // Your custom logic here
    console.log('clicked inside');
  };

  useOnClickOutside(filterRef, handleClickOutside);

  return (
    <div className="d-flex justify-content-end">
      <div
        className={classNames('dropleft ', {
          show: showFilter,
        })}
        ref={filterRef}
      >
        <button
          type="button"
          className="btn btn-white rounded-30"
          // data-toggle="dropdown"
          onClick={() => setShowFilter((showFilter) => !showFilter)}
        >
          <span className="mg-r-5">
            <FormIcon icon="filter" iconType="icon" />
          </span>
          Filters
        </button>
        <form
          className={classNames('dropdown-menu wd-400 pd-x-15 bg-white shadow-sm', {
            show: showFilter,
          })}
          onSubmit={handleSubmit(onSubmit)}
        >
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => setShowFilter(false)}
          >
            <span className="small" aria-hidden="true">
              &times;
            </span>
          </button>
          <div className="form-group">
            <label className="d-block form-item-title">Date</label>
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
              {DateFilter.map((item) => {
                return (
                  <label
                    key={item.value}
                    className={classNames('btn btn-white', {
                      active: item.value == dateFilterWatch,
                    })}
                  >
                    <input
                      type="radio"
                      value={item.value}
                      {...register('dateFilter')}
                      onChange={onChangeDate}
                    />
                    {item.label}
                  </label>
                );
              })}
            </div>
            <div className="d-flex align-items-center mg-t-10">
              <DatePicker
                placement={'bottom-end'}
                selected={startDate}
                onChange={onChangeStartDate}
                selectsStart={true}
                startDate={startDate}
                endDate={endDate}
                dateFormat={'yyyy/MM/dd'}
              />
              <span className="mg-x-10">~</span>
              <DatePicker
                placement={'bottom-end'}
                selected={endDate}
                onChange={onChangeEndDate}
                selectsEnd={true}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat={'yyyy/MM/dd'}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Assigned to</label>
            <div className="form-check">
              {AssignedTo.map((item) => {
                return (
                  <label
                    key={item.value}
                    className="custom-control custom-radio custom-control-inline"
                  >
                    <input
                      type="radio"
                      className="custom-control-input"
                      {...register('assignedTo')}
                      value={item.value}
                      id={`assignedTo-${item.value}`}
                    />
                    <label className="custom-control-label" htmlFor={`assignedTo-${item.value}`}>
                      {item.label}
                    </label>
                  </label>
                );
              })}
            </div>
          </div>
          <div className="form-group">
            <label className="form-item-title">Type</label>
            <div className="form-check">
              {actions.map((item) => {
                return (
                  <label
                    key={item.value}
                    className="custom-control custom-checkbox custom-control-inline"
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      {...register('action')}
                      value={item.value}
                      id={`type-${item.value}`}
                    />
                    <label className="custom-control-label" htmlFor={`type-${item.value}`}>
                      {item.label}
                    </label>
                  </label>
                );
              })}
            </div>
          </div>
          <div className="d-flex">
            <button type="button" className="btn btn-light flex-grow-1 mg-r-5" onClick={resetData}>
              Reset
            </button>
            <button type="submit" className="btn btn-primary flex-grow-1">
              Apply
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Filter;
