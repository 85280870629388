import React from 'react';
import TextView from '@base/containers/view-field/text/view';
import { CheckCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';

const View: React.FC<any> = (props: any) => {
  const { t } = useTranslation();
  const { value } = props;

  return (
    <div>
      {value ?
        value?.map((item: any, index: number) => {
          const optionId = item?.label?.label ?? '';
          return (
            <div key={index} className="" style={{ wordBreak: 'break-all' }}>
              {optionId && (
                <span className="badge badge-dark mg-r-5 pd-b-5">
                  {optionId == '1' ? item?.labelValue : t(item?.label?.languageKey)}
                </span>
              )}
              {item?.zipcode && <span className="mg-r-5">{item?.zipcode}</span>}
              {item?.street && <span className="mg-r-5">{item?.street}</span>}
              {item?.country?.country && <span className="mg-r-5">{item?.country?.country}</span>}
              {item?.state && <span className="mg-r-5">{item?.state}</span>}
              {item?.city && <span className="mg-r-5">{item?.city}</span>}
              {Boolean(item?.primary) && <CheckCircle size={16} className="tx-info mg-l-5" />}
            </div>
          );
        })
        :
        <TextView value={''} />
      }
    </div>
  );
};

export default View;
