export enum ECustomerCategory {
  CATEGORY_NONE = 'CATEGORY_NONE',
  CATEGORY_ACCOUNT = 'CATEGORY_ACCOUNT',
  CATEGORY_CONTACT = 'CATEGORY_CONTACT',
}

export enum ECustomerContactType {
  CONTACT_TYPE_NONE = 'CONTACT_TYPE_NONE',
  CONTACT_TYPE_EMPLOYEE = 'CONTACT_TYPE_EMPLOYEE',
  CONTACT_TYPE_INFLUENCER = 'CONTACT_TYPE_INFLUENCER',
  CONTACT_TYPE_CHAMPION = 'CONTACT_TYPE_CHAMPION',
  CONTACT_TYPE_BUDGET_HOLDER = 'CONTACT_TYPE_BUDGET_HOLDER',
  CONTACT_TYPE_DECISION_MAKER = 'CONTACT_TYPE_DECISION_MAKER',
  CONTACT_TYPE_END_USER = 'CONTACT_TYPE_END_USER',
}

export enum ECustomerType {
  TYPE_NONE = 'TYPE_NONE',
  TYPE_CUSTOMER = 'TYPE_CUSTOMER',
  TYPE_POTENTIAL = 'TYPE_POTENTIAL',
  TYPE_SALES_AGENT = 'TYPE_SALES_AGENT',
  TYPE_VENDOR = 'TYPE_VENDOR',
  TYPE_PARTNER = 'TYPE_PARTNER',
}