import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Image as ImageIcon } from 'react-feather';
import classNames from 'classnames';
import { IMAGE_SIZE_S } from '@base/config/image';
import { apiUrl } from '@base/utils/helpers/http';
import { useDownloadObjectMutation } from '@base/hooks/useFileUploadMutation';

interface AvatarProps {
  userno?: string;
  url?: string;
  file?: any;
  alt?: string;
  width?: number | string;
  height?: number | string;
  className?: string; // Classname set size for image, if it have value, width and height prop will not working.
  size?: string;
  isCircle?: boolean;
  isUrl?: boolean;
  //imgClass?: string;
}

const NoImage = ({
  className = '',
  isCircle = true,
  width = 40,
  height = 40,
  alt = '',
}: AvatarProps) => {
  return (
    <div
      className={classNames(
        'd-flex align-items-center justify-content-center bg-gray-400',
        className,
        {
          'rounded-circle': isCircle,
        },
      )}
      style={{
        width: width,
        height: height,
        minWidth: 14,
        minHeight: 14,
        //backgroundColor: '#8392a5', // 'var(--background-gray)',
      }}
    >
      {alt && alt.length > 0 ? (
        <span className="tx-13 tx-white tx-bold tx-capitalize">{alt[0]}</span>
      ) : (
        <ImageIcon
          color="#d2d7de"
          style={{
            height: '50%',
            width: '50%',
          }}
        />
      )}
    </div>
  );
};

/**
 *
 * @param {*} props
 * @returns
 */
const Avatar: React.FC<AvatarProps> = (props: AvatarProps) => {
  const {
    url,
    file,
    alt,
    width = 40,
    height = 40,
    className, // Classname set size for image, if it have value, width and height prop will not working.
    size = IMAGE_SIZE_S,
    isCircle = true,
    isUrl = false,
    //imgClass = 'img-fluid', // classname for image
  } = props;

  //state
  //const [isLoading, setIsLoading] = useState(false);
  //const [isError, setIsError] = useState(false);
  const [imageData, setImageData] = useState<any>(null);

  //download mutation
  const mDownload: any = useDownloadObjectMutation<any>({
    onSuccess: (data: any, variables: any, context: any) => {
      //toast.success('Uploaded successfully!');
      //// console.log('onSuccess data >>>', data);
      setImageData(data);
    },
    onError: (error: any, variables: any, context: any) => {
      //// console.log('mutation error', error);
      //toast.error('There is an error in downloading image: ' + JSON.parse(error).message);
    },
  });

  //get image url
  // const imageUrl = useMemo(() => {
  //   if (file || url) {
  //     if (file) return window.URL.createObjectURL(file);

  //     return `${url}&size=${size}`;
  //   }
  //   return null;
  // }, [url, file]);

  //set new image
  useEffect(() => {
    // if (imageUrl) {
    //   setIsLoading(true);
    //   setIsError(false);
    //   const NewImage = new Image();
    //   NewImage.onload = function () {
    //     setIsLoading(false);
    //   };

    //   NewImage.onerror = function () {
    //     setIsError(true);
    //   };

    //   NewImage.src = imageUrl;
    // }
    if (file) {
      setImageData(window.URL.createObjectURL(file));
    } else if (url) {
      try {
        const params = {
          id: '',
          url,
        };
        mDownload.mutate(params);
      } catch {
        setImageData(null);
      }
    } else {
      setImageData(null);
    }
  }, [url, file]);

  const renderLoadingOrError = () => {
    if (mDownload.isError) {
      return (
        <NoImage
          isCircle={isCircle}
          width={width}
          height={height}
          className={className}
          alt={alt}
        />
      );
    } else {
      return (
        mDownload.isLoading && (
          <div
            className={classNames('navata-placeholder', { 'rounded-circle': isCircle })}
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        )
      );
    }
  };

  const getStyle = () => {
    if (className) {
      return {};
    }
    return {
      width: width,
      height: height,
    };
  };

  //render image by url
  if (url || isUrl) {
    return (
      <div className={className} style={getStyle()}>
        {!mDownload.isLoading && imageData === null && (
          <NoImage
            isCircle={isCircle}
            width={width}
            height={height}
            className={className}
            alt={alt}
          />
        )}
        {!mDownload.isLoading && imageData !== null && (
          <img
            src={imageData}
            alt={alt}
            className={classNames(className, { 'rounded-circle': isCircle })}
            style={{
              width: width,
              height: height,
              minWidth: 26,
              minHeight: 26,
            }}
          />
        )}
        {/* {renderLoadingOrError()} */}
        {mDownload.isLoading && (
          <img
            className={classNames('navata-placeholder', { 'rounded-circle': isCircle })}
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        )}
      </div>
    );
  }

  //render image by name
  return (
    <NoImage isCircle={isCircle} width={width} height={height} className={className} alt={alt} />
  );
};

export default React.memo(Avatar);
