import ListTableCellDroplist from '@base/components/list/list-table-cell-droplist';
import {
  PRODUCT_ITEM_INVENTORY_TYPE_OPTIONS,
  PRODUCT_ITEM_TYPE_OPTIONS,
} from '@product/main/config/constants';
import { useEffect } from 'react';
import withTextAndPreviewModal, {
  QuickViewComponentProps,
} from '@base/hooks/hocs/withTextAndPreviewModal';
import { usePublishItemView } from '@product/item/services/item-service';
import { Avatar } from '@base/components';
import ItemBaseInfo from '@product/item/containers/item-base-info';
import { numberFormat } from '@base/utils/helpers';
import { Link } from 'react-router-dom';

export const ProductItemQuickView = (props: QuickViewComponentProps) => {
  const { id, setLoading } = props;

  const { data, isLoading } = usePublishItemView(id);

  useEffect(() => {
    setLoading && setLoading(isLoading);
  }, [isLoading]);

  return (
    <div className="pd-20 scroll-box">
      <div className="form-group row">
        <div className="col-sm-4">
          <Avatar
            key={data?.images?.[0]?.id}
            url={data?.images?.[0]?.name}
            alt={''}
            height={150}
            width={150}
            isCircle={false}
          />
        </div>
        <div className="col-sm-8 d-flex align-items-center">
          <Link to={`/product/item/${data?.id}`}>{data?.name}</Link>
        </div>
      </div>
      <div className="form-group1 row">
        <div className="w-100">
          <ItemBaseInfo data={data} />
        </div>
      </div>
      <div className="form-group1 row">
        <label className="col-sm-4 col-form-label">Item Type</label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {PRODUCT_ITEM_TYPE_OPTIONS?.find((v: any) => v.value === data?.['itemType'])?.label ??
              ''}
          </label>
        </div>
      </div>
      <div className="form-group1 row">
        <label className="col-sm-4 col-form-label">Inventory Type</label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {PRODUCT_ITEM_INVENTORY_TYPE_OPTIONS?.find(
              (v: any) => v.value === data?.['inventoryType'],
            )?.label ?? ''}
          </label>
        </div>
      </div>
      {data?.inventoryType === 'INVENTORY_TYPE_INVENTORY' && (
        <div className="form-group1 row">
          <label className="col-sm-4 col-form-label">SKU</label>
          <div className="col-sm-8 col-form-label">
            <ListTableCellDroplist
              className="no-padding cursor-default"
              showAvatar={false}
              values={data?.sku ?? []}
            />
          </div>
        </div>
      )}
      {data?.inventoryType === 'INVENTORY_TYPE_INVENTORY' && (
        <div className="form-group1 row">
          <label className="col-sm-4 col-form-label">Opening Stock</label>
          <div className="col-sm-8">
            <label className="col-form-label">
              {numberFormat(data?.openStock ?? 0, { noOfDecimal: 0 })}
            </label>
          </div>
        </div>
      )}
      {data?.inventoryType === 'INVENTORY_TYPE_INVENTORY' && (
        <div className="form-group1 row">
          <label className="col-sm-4 col-form-label">Stock on Hand </label>
          <div className="col-sm-8">
            <label className="col-form-label">
              {numberFormat(data?.stockOnHand ?? 0, { noOfDecimal: 0 })}
            </label>
          </div>
        </div>
      )}
      {data?.inventoryType === 'INVENTORY_TYPE_INVENTORY' && (
        <div className="form-group1 row">
          <label className="col-sm-4 col-form-label">Replenishment Point</label>
          <div className="col-sm-8">
            <label className="col-form-label">
              {numberFormat(data?.replenishmentPoint ?? 0, { noOfDecimal: 0 })}
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default withTextAndPreviewModal(ProductItemQuickView);
