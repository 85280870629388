import React, { useEffect, useState } from 'react';
import ListHeaderSort from '@base/components/list/list-header/list-header-sort';
import dayjs from 'dayjs';
import ListHeaderFilterNormalMode from './normal-mode';
import ListHeaderFilterSplitMode from './split-mode';

interface ListHeaderFilters {
  category: string;
  groupByOptions?: any[];
  dateByOptions?: any[];
  filterByOptions?: any[];
  filterData: { [key: string]: any };
  onFilterDataChanged: (value: any) => void;
  isSplitMode?: boolean;
  sortColumns: any[];
  sortData: any;
  onSortDataChanged: (sortData: any) => void;
}

const ListHeaderFilters = (props: ListHeaderFilters) => {
  const {
    category,
    isSplitMode,
    sortColumns,
    sortData,
    onSortDataChanged,
    filterData,
    onFilterDataChanged,
    groupByOptions,
    dateByOptions,
    filterByOptions,
  } = props;

  const [date, setDate] = useState<any>({});
  const [filter, setFilter] = useState<any>({});
  const [groupBy, setGroupBy] = useState<any>({ groupBy: filterData?.groupBy });

  useEffect(() => {
    const newFilter = { ...date, ...filter, ...groupBy };
    if (JSON.stringify(filterData) != JSON.stringify(newFilter)) {
      onFilterDataChanged(newFilter);
    }
  }, [date, filter, groupBy, filterData]);

  const filterProps = {
    filterData,
    onFilterDataChanged: (data: any, type: string) => {
      if (type === 'date') {
        for (const k in data) {
          data[k][0] = dayjs(data[k][0]).toISOString();
          data[k][1] = dayjs(data[k][1]).toISOString();
        }
        setDate(data);
      } else if (type === 'filter') {
        setFilter(data);
      } else {
        setGroupBy(data ? { groupBy: data } : {});
      }
    },
    groupByOptions,
    filterByOptions,
    dateByOptions,
  };

  const sortProps = { onSortDataChanged, sortData, sortColumns };

  // // console.log('...filterProps...', filterProps);
  return (
    <>
      {isSplitMode ? (
        <ListHeaderFilterSplitMode
          {...filterProps}
          sort={sortColumns && !!sortColumns.length && <ListHeaderSort {...sortProps} isCollapse />}
        />
      ) : (
        <ListHeaderFilterNormalMode
          {...filterProps}
          sort={sortColumns && !!sortColumns.length && <ListHeaderSort {...sortProps} />}
        />
      )}
    </>
  );
};

export default ListHeaderFilters;
