import React, { useEffect, useState } from 'react';
import TinymceEditor from '../tinymce-editor';
import { useTranslation } from 'react-i18next';

const Editor: React.FC<any> = (props: any) => {
  const {
    readOnly = false,
    onChange = null,
    options = {},
    value,
    maxNumberValue = false,
    name,
    placeholder = '',
    type = 'text',
    disabled = false,
    className = '',
    hookOnChange = null,
    onBlur = null,
    ...rest
  } = props;

  const { t } = useTranslation();
  const [curValue, setCurValue] = useState(Array.isArray(value) ? value[0] : value);

  useEffect(() => {
    if (value !== curValue) {
      setCurValue(value);
    }
  }, [value]);

  const handleValueChange = (content: any) => {
    setCurValue(content);
    //callback
    onChange && onChange(content);
  };

  const toolBar = readOnly
    ? ''
    : 'undo redo styleselect bold italic alignleft aligncenter alignright alignjustify | bullist numlist outdent indent';
  return (
    <TinymceEditor
      key={'TinymceEditor Editor'}
      //initialValue={curValue}
      //disabled={readOnly}
      options={{
        statusbar: false,
        height: 'auto',
        toolbar: '',
        placeholder: t('common.board_content_input_msg'),
        ...options,
      }}
      onChange={handleValueChange}
      toolbar={toolBar}
      {...rest}
    />
  );
};
export default Editor;
