import React, { Fragment, useEffect, useState } from 'react';
import { Button, Select } from '@base/components/form';

/**
 */
const LanguageForm: React.FC<any> = (props: any) => {
  const {
    type,
    setType,
    configMenu,
    menu,
    setMenu,
    langKey,
    setLangKey,
    langValues,
    setLangValues,
    modifiedLangValue,
    resetNewLang, //reset to create new
    //error
    langKeyError,
    langValueError,
  } = props;

  //change menu, set default key
  const handleChangeMenu = (option: any) => {
    setMenu(option);
    if (option.value) {
      setLangKey('ncrm_' + option.value + '_');
    }
  };

  //lang values change
  const handleLangValueChange = (e: any, idx: number) => {
    const newLangValues = [...langValues];
    newLangValues[idx].value = e.target.value;
    setLangValues(newLangValues);
  };

  //main render
  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="mg-b-20" style={{ width: '90%' }}>
        <div className="pos-relative">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <div>
                <span>Type</span>
              </div>
              <div className="mg-l-20">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="allRadio"
                    name="typeRadio"
                    className="custom-control-input"
                    disabled={modifiedLangValue?.uuid}
                    checked={type === 'all'}
                    onChange={() => setType('all')}
                  />
                  <label className="custom-control-label" htmlFor="allRadio">
                    All
                  </label>
                </div>
              </div>
              <div className="mg-l-20">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="serverRadio"
                    name="typeRadio"
                    className="custom-control-input"
                    disabled={modifiedLangValue?.uuid}
                    checked={type === 'server'}
                    onChange={() => setType('server')}
                  />
                  <label className="custom-control-label" htmlFor="serverRadio">
                    Sever
                  </label>
                </div>
              </div>
              <div className="mg-l-20">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="clientRadio"
                    name="typeRadio"
                    className="custom-control-input"
                    disabled={modifiedLangValue?.uuid}
                    checked={type === 'client'}
                    onChange={() => setType('client')}
                  />
                  <label className="custom-control-label" htmlFor="clientRadio">
                    Client
                  </label>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <Button color="info" className="btn btn-info" onClick={resetNewLang}>
                Reset New
              </Button>
            </div>
          </div>

          <div className="pd-t-10">
            <span>Menu</span>
          </div>
          <Select
            isDisabled={modifiedLangValue?.uuid}
            options={configMenu}
            value={menu} //configMenu[0]
            onChange={handleChangeMenu}
          />

          <div className="pd-t-10">
            <span>Lang Key<span className='tx-danger'>*</span></span>
          </div>
          <input
            type="text"
            className="form-control"
            disabled={modifiedLangValue?.uuid}
            value={langKey}
            onChange={(e) => setLangKey(e.target.value)}
          />
          {langKeyError && <p className="tx-danger">Language key is required.</p>}

          {langValues.map((item: any, idx: number) => {
            return (
              <Fragment key={idx}>
                <div className="pd-t-10">
                  <span>{item.label}</span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={item.value}
                  onChange={(e) => handleLangValueChange(e, idx)}
                />
              </Fragment>
            );
          })}
          {langValueError && (
            <p className="tx-danger">Language values are required at least ONE.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LanguageForm;
