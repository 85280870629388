import { ICountry } from '@base/types/interfaces/setting';
import { parseJSONTo } from '@base/utils/helpers';
import {
  IBusinessHours,
  ICalendarSetting,
  ICurrencySetting,
  IDateSetting,
  IFiscalPeriodSetting,
  INumberSetting,
  ITimeSetting,
  ITimezoneSetting,
} from '@settings/general-setting/types/interfaces/format';
import { selector, selectorFamily } from 'recoil';
import { countriesAtom, currenciesAtom, formatSettingsAtom, statesAtom } from '../atoms';

export const countriesSelector = selector<any>({
  key: 'countriesSelector',
  get: ({ get }) => {
    return get(countriesAtom);
  },
});

export const statesSelector = selectorFamily({
  key: 'countriesSelector',
  get:
    (countryCode: string) =>
    ({ get }) => {
      const _states = get(statesAtom);
      return _states[countryCode];
      // return [];
    },
});

export const phonesSelector = selector<any>({
  key: 'phonesSelector',
  get: ({ get }) => {
    let phones: object[] = [];
    const countries = get(countriesSelector);

    countries?.map((item: any) => {
      phones.push({ value: item?.phoneCode, label: item?.phoneCode, isDefault: item?.isDefault });
    });

    return phones;
  },
});

export const defaultCountrySelector = selector<any>({
  key: 'defaultCountrySelector',
  get: async ({ get }) => {
    const countries = await get(countriesSelector);

    return countries?.find((country: any) => country?.isDefault);
  },
});

export const defaultPhoneSelector = selector<any>({
  key: 'defaultPhoneSelector',
  get: ({ get }) => {
    const phones = get(phonesSelector);
    return phones?.find((phone: any) => phone?.isDefault);
  },
});

export const defaultCurrencySelector = selector<any>({
  key: 'defaultCurrencySelector',
  get: ({ get }) => {
    const currencies = get(currenciesAtom);
    return currencies?.find((currency) => currency?.isDefault);
  },
});

export const numberSettingSelector = selector<any>({
  key: 'numberSettingSelector',
  get: ({ get }) => {
    const formatSettings = get(formatSettingsAtom);
    let result = formatSettings?.find((item: any) => item.key == 'number');
    return result ? parseJSONTo<INumberSetting>(result.value) : undefined;
  },
});

export const currencySettingSelector = selector<any>({
  key: 'currencySettingSelector',
  get: ({ get }) => {
    const formatSettings = get(formatSettingsAtom);
    let result = formatSettings?.find((item: any) => item.key == 'currency');
    return result ? parseJSONTo<ICurrencySetting>(result.value) : undefined;
  },
});

export const timeSettingSelector = selector<any>({
  key: 'timeSettingSelector',
  get: ({ get }) => {
    const formatSettings = get(formatSettingsAtom);
    let result = formatSettings?.find((item: any) => item.key == 'time');
    return result ? parseJSONTo<ITimeSetting>(result.value) : undefined;
  },
});

export const timezoneSettingSelector = selector<any>({
  key: 'timezoneSettingSelector',
  get: ({ get }) => {
    const formatSettings = get(formatSettingsAtom);
    let result = formatSettings?.find((item: any) => item.key == 'timezone');
    return result ? parseJSONTo<ITimezoneSetting[]>(result.value) : undefined;
  },
});

export const countrySettingSelector = selector<any>({
  key: 'countrySettingSelector',
  get: ({ get }) => {
    const formatSettings = get(formatSettingsAtom);
    let result = formatSettings?.find((item: any) => item.key == 'country');
    return result ? parseJSONTo<ICountry[]>(result.value) : undefined;
  },
});

export const dateSettingSelector = selector<any>({
  key: 'dateSettingSelector',
  get: ({ get }) => {
    const formatSettings = get(formatSettingsAtom);
    let result = formatSettings?.find((item: any) => item.key == 'date');
    return result ? parseJSONTo<IDateSetting>(result.value) : undefined;
  },
});

export const calendarSettingSelector = selector<any>({
  key: 'calendarSettingSelector',
  get: ({ get }) => {
    const formatSettings = get(formatSettingsAtom);
    let result = formatSettings?.find((item: any) => item.key == 'calendar');
    return result ? parseJSONTo<ICalendarSetting>(result.value) : undefined;
  },
});

export const businessHoursSettingSelector = selector<any>({
  key: 'businessHoursSettingSelector',
  get: ({ get }) => {
    const formatSettings = get(formatSettingsAtom);
    let result = formatSettings?.find((item: any) => item.key == 'business_hours');
    return result ? parseJSONTo<IBusinessHours>(result.value) : undefined;
  },
});

export const fiscalPeriodSettingSelector = selector<any>({
  key: 'fiscalPeriodSettingSelector',
  get: ({ get }) => {
    const formatSettings = get(formatSettingsAtom);
    let result = formatSettings?.find((item: any) => item.key == 'fiscal_period');
    return result ? parseJSONTo<IFiscalPeriodSetting>(result.value) : undefined;
  },
});
