import React, { useState, Fragment, useEffect, useRef } from 'react';
import { humanFileSize } from '@base/utils/helpers/general.utils';
import { Accept, useDropzone } from 'react-dropzone';
import { FileText, Trash } from 'react-feather';

const dragOver: React.CSSProperties = {
  borderColor: '#007bff',
};
const uploadProgressStyles: React.CSSProperties = {
  position: 'absolute',
  height: 'calc(100% - 10px)',
  backgroundColor: '#79797926',
  top: 5,
  left: 0,
  width: '100%',
};

interface Uploadconfig {
  maxUpload?: number;
  maxCount?: number;
}
const defaultUploadConfig: Uploadconfig = {
  maxUpload: 9999999,
};

declare global {
  interface Window {
    userDataGlobal: any;
  }
}
/**
 *
 * @param file
 * @param saving
 * @param remove
 * @returns {*}
 * @constructor
 */
const FileItem = ({ file, saving, remove }: any) => {
  return (
    <li className="list-group-item d-flex align-items-center">
      <div
        className={`wd-40 ht-40 tx-white d-flex align-items-center justify-content-center rounded 
        ${file.id ? 'bg-orange' : 'bg-gray-300'}`}
      >
        <FileText />
      </div>
      <div className={'mg-l-15 wd-70p'}>
        <h6 className={'text-overflow'}>{file.name}</h6>
        <span className="d-block tx-color-03 tx-uppercase tx-11 tx-medium">
          {humanFileSize(file.size)}
        </span>
      </div>
      <div className="mg-l-auto text-right">
        <a role="button" onClick={remove} className="btn btn-xs btn-link btn-icon" tabIndex={0}>
          <Trash />
        </a>
      </div>

      <div
        className="progress-bar progress-bar-striped progress-bar-animated"
        style={saving ? uploadProgressStyles : {}}
        role="progressbar"
        aria-valuenow={0}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </li>
  );
};

// const fileLimit = {
//     max_upload: 3,
//     max_count: 100,
//     max_expired: 100
// };

interface IUploadProps {
  showBrowse?: boolean;
  onDrop?: any;
  saving?: boolean;
  files: any;
  languages?: any;
  multiple?: boolean;
  accept?: Accept;
  viewListFiles?: boolean;
}

/**
 *
 * @param onDrop {function} callback when user selected file
 * @param saving {boolean}
 * @param files {array}
 * @param name {string}
 * @returns {*}
 */
const UploadDropArea: React.FC<IUploadProps> = (props: IUploadProps) => {
  const {
    showBrowse = false,
    onDrop = null,
    saving,
    files,
    languages = {},
    multiple = true,
    accept,
    viewListFiles = true,
  } = props;

  // init react states
  const [dragging, setDragging] = useState<boolean>(false);
  const [filesState, setFile] = useState<any>(files || []);
  const [totalFileSize, setTotalFileSize] = useState<number>(0);
  const [removedFiles, removeUploadedFile] = useState<any[]>([]);
  const [fileLimitConfig, setConfig] = useState<Uploadconfig>(defaultUploadConfig);
  const isMounted = useRef(true);

  // init file drop area
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFile) => {
      let maxSizeBytes = fileLimitConfig.maxUpload || 0;
      let count = 0;
      let uploadedTotal = 0;
      let newFiles = acceptedFile;

      if (multiple) {
        newFiles = acceptedFile.concat(filesState);
      }

      newFiles.forEach((file) => {
        uploadedTotal += parseInt(file.size + '');
        count++;
      });

      if (typeof maxSizeBytes != 'undefined' && uploadedTotal > maxSizeBytes) {
        alert(
          `Total script upload file size does not exceed ${humanFileSize(
            fileLimitConfig.maxUpload || 0,
          )}, current your total: ${humanFileSize(uploadedTotal)}`,
        );
        return;
      }
      if (typeof fileLimitConfig.maxCount != 'undefined' && count > fileLimitConfig.maxCount) {
        alert(`The total number of files does not exceed ${fileLimitConfig.maxCount}`);
        return;
      }

      setTotalFileSize(uploadedTotal);

      onDrop && onDrop(newFiles);
      setFile && setFile(newFiles);
      setDragging && setDragging(false);
    },
    onDragOver: () => setDragging(true),
    onDragLeave: () => setDragging(false),
    multiple,
    accept,
  });

  const getTotalFileSize = (files: any[]) => {
    let totalFileSize = 0;

    files.forEach((file) => {
      totalFileSize += parseInt(file.size);
    });

    return totalFileSize;
  };

  const onRemove = (index: number) => {
    if (filesState[index].id) {
      filesState[index];
      removeUploadedFile([...removedFiles, filesState[index]]);
    }

    filesState.splice(index, 1);
    setFile([...filesState]);

    let totalSizeAfterDelete = getTotalFileSize(filesState);

    if (totalSizeAfterDelete === 0) {
      setTotalFileSize(0); //default 0
    } else {
      setTotalFileSize(totalSizeAfterDelete);
    }
  };

  const getLanguage = (key: string, defaultText = '') => {
    return languages[key] || defaultText;
  };

  // just init files
  useEffect(() => {
    if (isMounted.current) {
      if (files) {
        setFile(files);
        setTotalFileSize(getTotalFileSize(files));
      }
    }
    return () => {
      isMounted.current = false;
      setFile([]);
    }; // cleanup toggles value, if unmounted
  }, [files]);

  // useEffect(() => {
  //   let { userDataGlobal } = window;
  //   let uploadConfigObj = userDataGlobal.uploadSetting;
  //   let uploadConfigCop = { ...uploadConfigObj };

  //   uploadConfigCop.maxUpload = uploadConfigObj.maxUpload * 1024 * 1024;

  //   setConfig(uploadConfigCop);
  // }, []);

  return (
    <Fragment>
      <div className="modal-uploader">
        <div {...getRootProps()} className="file-drag-drop-area" style={dragging ? dragOver : {}}>
          <div className="file-drag-drop-area-init">
            {!saving && (
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="css-i6dzq1"
              >
                <polyline points="16 16 12 12 8 16"></polyline>
                <line x1="12" y1="12" x2="12" y2="21"></line>
                <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
                <polyline points="16 16 12 12 8 16"></polyline>
              </svg>
            )}
            {saving && <span className="spinner-border" role="status" aria-hidden="true" />}
            <div>{getLanguage('dropAndDrag', 'Drag & Drop Files')}</div>
            {fileLimitConfig.maxUpload && (
              <div className="file-size-infor">
                <strong>{totalFileSize > 0 ? humanFileSize(totalFileSize) : '0 B'}</strong> /{' '}
                <span>{humanFileSize(fileLimitConfig.maxUpload)}</span>
              </div>
            )}
          </div>

          <input {...getInputProps()} />
        </div>
        {showBrowse && (
          <>
            <div className="divider-text wd-100p">or</div>
            <button type="button" className="btn btn-block btn-primary" onClick={open}>
              Browse
              {saving && (
                <span
                  className="spinner-border spinner-border-sm mg-l-5"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </>
        )}
        {viewListFiles && (
          <ul className="list-group list-group-flush mg-t-15">
            {filesState.map((file: any, index: number) => (
              <FileItem key={index} remove={() => onRemove(index)} file={file} saving={saving} />
            ))}
          </ul>
        )}

        {removedFiles.map((file, index) => (
          <input key={index} type="hidden" name={'file_removed[]'} value={JSON.stringify(file)} />
        ))}
      </div>
    </Fragment>
  );
};

// UploadDropArea.defaultProps = {
//   showBrowse: false,
//   onDrop: null,
//   saving: false,
//   files: [],
//   languages: {},
//   multiple: true,
//   accept: '',
//   viewListFiles: true,
// };

export default UploadDropArea;
