import Button from '@base/components/form/button';
import { ChevronUp } from 'react-feather';

export type FooterContainerProps = {
  isSaving: boolean;
  isValid: boolean;
  isReset: boolean;
  onSubmit: () => void;
  onNewSubmit: () => void;
};

function FooterContainer(props: FooterContainerProps) {
  const { isSaving, isValid, isReset, onSubmit, onNewSubmit } = props;

  return (
    <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
      <div className="d-flex pd-15 bd-t justify-content-end">
        <div className="btn-group dropup">
          <Button
            color="success"
            onClick={onSubmit}
            type="button"
            disabled={isSaving || !isValid}
            loading={isSaving && !isReset}
            name="Save"
          />
          <button
            type="button"
            className="btn btn-success dropdown-toggle-split"
            data-toggle="dropdown"
          >
            <ChevronUp />
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <Button
              className="dropdown-item"
              color="primary"
              type="button"
              onClick={onNewSubmit}
              disabled={isSaving || !isValid}
              loading={isSaving && isReset}
              name="Save and Create New"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterContainer;
