import ClickOutside from '@base/components/han-click-outside';
import HanGroupButton from '@base/components/han-group-button';
import { COMMON_VIEW_FIELD_READ_ONLY } from '@base/config/constant';
import VIEW_FIELD_API_CONFIG from '@base/config/view-fied-api';
import useMutationPost from '@base/hooks/useMutationPost';
import { listDataByMenuAtom, viewDataByMenuAtom } from '@base/recoil/atoms';
import { getUpdateCommonViewField } from '@base/services/graphql/pagelayout';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import _, { isDate, isObject } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Edit2 } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { IViewFieldConfig } from '@base/types/interfaces/common';
import { FieldUserPermission } from '@base/types/interfaces/pagelayout';

import { HoverEditContainer, ViewContainer } from './style';

type CommonViewField = {
  /** Component for view mode */
  componentView: any;
  /** Component for Edit mode */
  componentEdit: any;
  /** permission for component */
  userPermission?: FieldUserPermission;
  /** callback save action*/
  onSave?: (params: any) => void; //NOT USE
  onSubmitHandler?: (params: any) => void; // external form submit
  /** callback for close  action*/
  onClose?: () => void;
  /** callback for refetch action*/
  onRefetch?: () => void;
  /** callback for delete action */
  onDelete?: (params: any) => void;
  /** default value for component */
  value: any;
  //display under link format
  link?: string;
  /** event onClik to link */
  onClickLink?: any;
  /** field config */
  config?: IViewFieldConfig;
  /** field keyName */
  keyName: string;
  /** layout style */
  isHorizontal?: boolean;
  // id: string;
  /** id is viewing */
  menuSourceId: string;
  // menu: string;
  // category: string;
  /** Menu Source  */
  menuSource: string;
  /** Only show edit component when click Edit icon */
  clickIconToEdit?: boolean;
};

const CommonViewField = (props: CommonViewField) => {
  const {
    componentView: ComponentView,
    componentEdit: ComponentEdit,
    userPermission,
    onSave,
    onSubmitHandler,
    onRefetch, //refetch data after update success
    onClose = () => null,
    onDelete = null,
    value: initialValue,
    config,
    keyName,
    isHorizontal = false,
    menuSourceId,
    // menu,
    menuSource,
    clickIconToEdit = false,
  } = props;
  // console.log('config >>>>>>>', config);
  const viewFieldAPI = VIEW_FIELD_API_CONFIG[menuSource];
  const {
    handleSubmit,
    //reset,
    setValue,
    getValues,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      [keyName]: config?.getValueEdit ? config.getValueEdit(initialValue) : initialValue,
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });
  //state
  const defaultShowEdit = config?.viewProps?.defaultShowEdit ?? false;
  const [isEdit, setIsEdit] = useState(defaultShowEdit);
  const [isSaving, setIsSaving] = useState(false);
  const [changedValue, setChangedValue] = useState(initialValue);
  const [viewData, setViewData] = useRecoilState(viewDataByMenuAtom(menuSource));
  const [listData, setListData] = useRecoilState(listDataByMenuAtom(menuSource));

  // console.log('Common view', 'menu:' + menu, 'category:' + category, viewData);

  const handleOnClose = (flag = false) => {
    setIsEdit(!isEdit);
    if (flag) {
      // rollback value for react-hook-form
      setValue(keyName, config?.getValueEdit ? config?.getValueEdit(changedValue) : changedValue);
    }
    onClose && onClose();
  };

  const queryClient = useQueryClient();
  const mutationKey = viewFieldAPI?.mutationKey;
  const variableKey = viewFieldAPI?.variableKey;
  const mutationString = getUpdateCommonViewField(mutationKey, variableKey, config?.sectionId);
  const mUpdate: any = useMutationPost<BaseMutationResponse>(mutationString, mutationKey, {
    onMutate: () => {
      setIsSaving(true);
    },
    onSuccess: (data: BaseMutationResponse, variables: any, context: any) => {
      const currentValue = getValues(keyName);
      // for updating changed value in view
      // console.log('currentValue', currentValue);

      // set value for view component
      const changedFormValue = config?.getChangedValue
        ? config.getChangedValue(currentValue)
        : currentValue;
      // : config?.getMutationValue
      // ? config?.getMutationValue(currentValue)
      // console.log('onSuccess changedFormValue', changedFormValue);
      setChangedValue(changedFormValue);

      // set value for react-hook-form
      setValue(
        keyName,
        config?.getValueEdit ? config.getValueEdit(changedFormValue) : changedFormValue,
      );

      //set recoil state
      if (config?.getRecoilStateValue) {
        const newStateValue = config.getRecoilStateValue(currentValue); //format value by field
        //update to view
        if (viewData?.layout) {
          const newViewData = _.cloneDeep(viewData);
          const keyIndex = newViewData.layout.data[0].children.findIndex(
            (_ele: any) => _ele.keyName === keyName,
          );
          if (keyIndex > -1) {
            newViewData.layout.data[0].children[keyIndex].data = newStateValue;
            setViewData(newViewData);
          }
        }
        //update to list
        if (listData) {
          const newListData = _.cloneDeep(listData);
          const itemIndex = newListData.findIndex((_item: any) => _item.id === menuSourceId);
          if (itemIndex > -1) {
            newListData[itemIndex][keyName] = newStateValue;
            setListData(newListData);
          }
        }
      }

      setIsSaving(false);
      // If there is invalidateQueries, run it.
      if (config?.refetchQueryKey) {
        const refetchQuery = config.refetchQueryKey;
        setTimeout(() => {
          if (refetchQuery.length > 0) {
            // multiple query
            for (const queryKey of refetchQuery) {
              queryClient.invalidateQueries(queryKey as any);
            }
          } else {
            queryClient.invalidateQueries(config.refetchQueryKey);
          }
        }, 500);
      }
      // TODO: implement optimistic update
      if (config?.optimisticQueryKey) {
        const optimistic = variables[variableKey];
        console.log('optimistic', optimistic);
        queryClient.setQueryData(config?.optimisticQueryKey, (old: any) => {
          return { ...old, ...optimistic };
        });
      }

      toast.success('Updated successfully!');
      onSave && onSave(currentValue);
      handleOnClose();
    },
    onError: (error: any) => {
      // console.log('Updated failed error: ', error);
      toast.error('Updated failed');
      setIsSaving(false);
      handleOnClose(true);
    },
  });

  //check success
  useEffect(() => {
    if (mUpdate.isSuccess) {
      onRefetch && onRefetch();
    }
  }, [mUpdate.isSuccess]);

  // console.log('changed value =>', keyName, changedValue);

  const onSubmit = (formData: any) => {
    // console.log('Edit FormData', formData);
    if (onSubmitHandler) {
      onSubmitHandler(formData);
      handleOnClose();
      return;
    }
    const currentValue = getValues(keyName);
    // console.log(currentValue);
    const oldValue = config?.getValueEdit ? config.getValueEdit(changedValue) : changedValue;

    // console.log('onSubmit >>> currentValue', currentValue);
    // console.log('onSubmit >>> oldValue', oldValue, changedValue);

    // if two object same, return
    if (JSON.stringify(currentValue) == JSON.stringify(oldValue)) {
      handleOnClose();
      return;
    }

    // Checking: it has custom updated value for modified api or not.
    let mutationValue = config?.getMutationValue
      ? config.getMutationValue(currentValue, viewData)
      : { [keyName]: currentValue };

    let updateData: any = {};
    // add extra field : because some field have to update with another field together.
    let extraMutationParams = config?.getExtraMutationParam
      ? config.getExtraMutationParam(config.viewData, currentValue)
      : {};
    if (isObject(mutationValue) && !isDate(mutationValue)) {
      updateData[variableKey] = {
        ...mutationValue,
        ...extraMutationParams,
      };
    } else {
      updateData[variableKey] = {
        [keyName]: mutationValue,
        ...extraMutationParams,
      };
    }

    // section id that included in menu.
    if (config?.sectionId) {
      updateData[variableKey].id = config.sectionId;
      updateData.id = menuSourceId;
    } else {
      updateData[variableKey].id = menuSourceId;
    }

    console.log('Common EDIT >>> updateData', updateData, mutationValue);
    // Custom for filed call save api to other menu
    if (config?.onSave) {
      config.onSave(updateData?.[variableKey]);
    } else {
      mUpdate.mutate(updateData);
    }
  };

  // watch initialValue and set defaultValue to useFrom
  // listening the initialValue and update new value for changeValue
  useEffect(() => {
    if (initialValue && changedValue != initialValue) {
      setChangedValue(initialValue);
      const newValue = config?.getValueEdit ? config?.getValueEdit(initialValue) : initialValue;
      setValue(keyName, newValue);
    }
  }, [initialValue]);

  //======================== Debug ========================//
  // console.log('[Common EDIT]form values', watch()); //get form values when inputing
  // console.log(
  //   'CommonViewField >>> keyName >>> value',
  //   props?.keyName,
  //   initialValue,
  //   props?.userPermission,
  // );
  //======================== End Debug ========================//

  const canEdit = () => {
    return userPermission?.isEdit && COMMON_VIEW_FIELD_READ_ONLY?.indexOf(keyName) === -1;
  };

  return (
    <HoverEditContainer>
      {isEdit && ComponentEdit && COMMON_VIEW_FIELD_READ_ONLY?.indexOf(keyName) === -1 ? (
        <ClickOutside
          // onClickOutsite={() => handleOnClose && handleOnClose(true)}
          onClickInsite={() => {
            // canEdit() && setIsEdit(true);
          }}
        >
          <form
            //key={`${keyName}-${uniqueId()}`}
            key={keyName}
            onSubmit={handleSubmit(onSubmit)}
            className={classNames('form wd-100p component-edit align-items-center', {
              'd-flex': isHorizontal,
            })}
          >
            <Controller
              name={keyName}
              control={control}
              rules={{
                validate: config?.validate,
              }}
              render={({ field: { value, onChange } }: any) => {
                let additionalProps = config?.getPropsEdit ? config?.getPropsEdit(viewData) : {};
                return (
                  <React.Suspense fallback={<></>}>
                    <ComponentEdit
                      {...props} //TODO: DELETE, should only get props for Edit
                      componentProps={{ ...config?.componentProps, ...additionalProps }}
                      errors={errors} //form submit error
                      value={value} //hook form
                      onChange={onChange} //hook form
                    />
                  </React.Suspense>
                );
              }}
            />
            {Object.keys(errors).length > 0 && errors?.[keyName] && (
              <p className="tx-danger tx-12">{errors?.[keyName]?.message}</p>
            )}
            <HanGroupButton
              className={classNames({
                'mg-l-5': isHorizontal,
                'mg-t-5': !isHorizontal,
              })}
              onClose={() => handleOnClose && handleOnClose(true)}
              onSave={() => {
                handleSubmit((data) =>
                  setTimeout(function () {
                    onSubmit(data);
                  }, 50),
                )();
              }}
              onDelete={onDelete}
              isValid={!isValid}
              isSaving={isSaving}
            />
          </form>
        </ClickOutside>
      ) : (
        <ViewContainer
          className={classNames('wd-100p', {
            'component-view-lock': !canEdit(),
            pointer: canEdit(),
          })}
          onClick={() => {
            !clickIconToEdit && canEdit() && setIsEdit(true);
          }}
        >
          <ComponentView
            {...props}
            value={config?.getValueView ? config?.getValueView(changedValue) : changedValue}
          />
          {canEdit() && (
            <Edit2
              className="mg-l-5 icon-edit"
              size={14}
              color="#8392a5"
              onClick={() => {
                canEdit() && setIsEdit(true);
              }}
            />
          )}
        </ViewContainer>
      )}
    </HoverEditContainer>
  );
};

export default CommonViewField;
