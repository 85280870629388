import grapesjs from 'grapesjs';

import loadBlocks from './blocks';
import loadComponents from './components/index';
import loadCommands from './commands/index';
import loadListeners from './listeners';
import loadTraits from './traits';

import { hNavbarType, tabsType, customCodeType } from './constants';

import tabOptions from './configs/tab-options';
import basicOptions from './configs/basic-options';
import navOptions from './configs/nav-options';
import codeOptions from './configs/code-options';
import tooltipOptions from './configs/tooltip-options';
import headingOptions from './configs/heading-options';
import dividerOptions from './configs/divider-options';
import tableOptions from './configs/table-options';

export default grapesjs.plugins.add('gts-blocks-basic', (editor: any, opts: any) => {
  const config: any = {
    blocks: [
      //'short-text',
      'heading',
      'text-line',
      'paragraph',
      'text-section',
      'divider',
      'shape-divider',
      'link-button',
      'link',
      'image',
      'video',
      'map',
      'link-block',
      'quote',
      'tooltip',
      hNavbarType,
      tabsType,
      customCodeType,
      'table',
      'list-items',
      'grid-items',
      'personalize',
      'form',
      //'calendar',
      'event',
      'survey-form',
      'option-set',
      'social-share',
      'download-link',
      'address',
      'package',
      'coupon',
      'button',
      'space',
      'html',
      'icon',
    ],
    templateBlocks: [],
    category: 'Element',
    //basic options
    ...basicOptions,
    //navbar options
    ...navOptions,
    //tab options
    ...tabOptions,
    //custom code options
    ...codeOptions,
    //tooltip options
    ...tooltipOptions,
    //heading options
    ...headingOptions,
    //shape divider options
    ...dividerOptions,
    //table options
    ...tableOptions,
    //other options
    ...opts,
  };
  //// console.log('block config', opts);

  //custom blocks by template type
  if (opts.templateType === 'full') {
    config.templateBlocks = [
      'heading',
      'text-line',
      'line',
      //'image',
      'n-image',
      'link-button',
      'divider',
      'space',
      'list-items',
      'table',
      'personalize',
      'form',
      customCodeType, //html
      'icon',
    ];
  }
  if (opts.templateType === 'basic') {
    config.templateBlocks = ['text-line', 'personalize'];
  }
  if (opts.templateType === 'simple') {
    config.templateBlocks = ['text-line', 'personalize', 'n-image'];
  }
  if (opts.templateType === 'medium') {
    config.templateBlocks = [
      'text-line',
      'n-image',
      'link-button',
      'divider',
      'table',
      'personalize',
      customCodeType,
      'icon',
    ];
  }

  //add listeners
  loadListeners(editor, config);
  //Add blocks
  loadBlocks(editor, config);
  //add components
  loadComponents(editor, config);
  //add commands
  loadCommands(editor, config);
  //add traits
  loadTraits(editor);
});
