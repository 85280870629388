import React, { useCallback, useRef, useState } from 'react';
import useInfinitePosts from '@base/hooks/useInfinitePosts';
import { Scrollbars } from 'react-custom-scrollbars-2';

import Filter from './filter';
import Item from './item';
import { Timeline } from '@base/types/interfaces/timeline';
import { TIMELINE_PAGE_SIZE } from '@base/config/constant';
import { GET_TIMELINE_BY_MENU } from '@base/services/graphql/timeline';
import { makeSection } from '@base/utils/helpers/makeSection';
import { Section, StickyHeader } from './styles';
import { Button } from '@base/components/form';
// import { useQueryClient } from 'react-query';
import { useQueryClient } from '@tanstack/react-query'; //v4
import Loading from '@base/components/loading';
import { MENU_SOURCE } from '@base/config/menus';
import { RefreshCcw } from 'react-feather';
import classNames from 'classnames';

interface TimlineProps {
  className?: string;
  menuSource?: string;
  menuSourceId?: string;
}

export interface Filter {
  createdAt: {
    from: Date;
    to: Date;
  };
  createdBy: string;
  action: string[];
}

const Timeline = (props: TimlineProps) => {
  // console.log('timeline', props);
  const { className = '', menuSource, menuSourceId } = props;

  const [isAutoScroll, setAutoScroll] = useState(false);
  const [filter, setFilter] = useState<Filter | null>(null);
  const queryClient = useQueryClient();

  let queries: string[] = [];
  if (filter != null) {
    if (filter.createdAt) {
      queries.push(['createdAt', '>=', '"' + filter.createdAt.from.toISOString() + '"'].join(''));
      queries.push(['createdAt', '<=', '"' + filter.createdAt.to.toISOString() + '"'].join(''));
    }
    if (filter.createdBy != '') {
      queries.push(['createdBy', '=', filter.createdBy].join(''));
    }

    if (filter.action.length > 0) {
      queries.push(['action', '=', filter.action.join(',')].join(''));
    }
  }

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    error,
    isError,
  } = useInfinitePosts<Timeline[]>(
    ['builtin_timelines', menuSourceId as string],
    GET_TIMELINE_BY_MENU,
    {
      source: {
        menu: menuSource && MENU_SOURCE[menuSource],
        id: menuSourceId,
      },
      filter: {
        // filters: filter,
        sort: {
          field: 'createdAt',
          orderBy: 'DESC',
        },
        paging: {
          size: TIMELINE_PAGE_SIZE,
        },
        query: queries.length ? '(' + queries.join(' ') + ')' : null,
      },
    },
  );

  const onChangeFilter = useCallback((f: Filter | null) => {
    queryClient.removeQueries(['builtin_timelines']);
    setFilter(f);
  }, []);

  const scrollbarRef = useRef<Scrollbars>(null);
  const onScroll = useCallback(
    (values: any) => {
      if (isAutoScroll && values.top === 1 && hasNextPage) {
        fetchNextPage();
        scrollbarRef.current?.scrollToBottom();
      }
    },
    [isAutoScroll, fetchNextPage, scrollbarRef, hasNextPage],
  );

  const getMore = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
      scrollbarRef.current?.scrollToBottom();
    }
  }, [fetchNextPage, scrollbarRef, hasNextPage]);

  const sections = makeSection(data);

  const onChangeAutoScroll = useCallback(() => {
    setAutoScroll((auto) => !auto);
  }, []);

  return (
    <div className={classNames("pd-20", className)}>
      <div className="new-timeline-group">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-baseline">
            <div className="mb-2 float-right custom-control custom-switch mg-l-5">
              <input
                type="checkbox"
                className="custom-control-input"
                id="noteAutoScroll"
                onClick={onChangeAutoScroll}
              />
              <label className="custom-control-label" htmlFor="noteAutoScroll">
                AutoScroll
              </label>
            </div>
            <button type="button" className="btn" onClick={() => refetch()}>
              <RefreshCcw className="mg-r-5" /> Refresh
            </button>
          </div>
          <Filter onChangeFilter={onChangeFilter} />
        </div>
        {!isError && (
          <Scrollbars
            ref={scrollbarRef}
            autoHeight
            autoHeightMin={400}
            autoHeightMax={document.body.scrollHeight - 260}
            autoHide
            onScrollFrame={onScroll}
          >
            {Object.entries(sections).map(([date, notes]) => {
              return (
                <Section key={date}>
                  <StickyHeader>
                    <button>{date}</button>
                  </StickyHeader>
                  {notes.map((note) => (
                    <Item key={note.id} data={note} />
                  ))}
                </Section>
              );
            })}
          </Scrollbars>
        )}

        {(isLoading || isFetchingNextPage) && <Loading />}

        {!isAutoScroll && hasNextPage && (
          <Button
            block={true}
            color={'light'}
            className="bd-0 tx-uppercase tx-10 tx-spacing-1 tx-medium mg-t-10"
            name="Load More"
            onClick={() => getMore()}
          />
        )}
      </div>
    </div>
  );
};

export default Timeline;
