import React, { useEffect } from 'react';
import classnames from 'classnames';
import { TextArea } from '@base/components/form';

const Edit: React.FC = ({ value = '', onChange = () => {}, errors = {}, attrsValue }: any) => {
  return (
    <div className={classnames('wd-100p')}>
      <div
        className={classnames({
          'is-required': errors?.isRequired,
        })}
      >
        <TextArea value={value} onChange={(data: any) => onChange(data)} />
      </div>
      {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
    </div>
  );
};

export default Edit;
