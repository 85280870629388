import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
// import {
//   convertDateTimeServerToClient,
//   formatParamDateTime,
// } from '@base/utils/helpers/general.utils';
import DatePicker from '@base/components/date-picker/default';
import Switch from '../switch';
import SpanLang from '../span-lang';

const DEFAULT_UNLIMIT = false;

const UnLimitDatePicker: React.FC<any> = (props: any) => {
  const {
    time = false,
    value = null, // {dataTime: "2020-01-12", isUnlimit: false}
    onChange = null,
    unlimitLabel = 'No End Date',
    minDate = null,
    maxDate = null,
    tooltip = '판매 종료일 지정 시 해당 날짜에 자동으로 판매 중단됩니다.',
    //...rest
  } = props;

  //state
  const [currValue, setCurrValue] = useState<any>({
    dataTime: dayjs(new Date()),
    isUnlimit: DEFAULT_UNLIMIT,
  });

  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(currValue)) {
        setCurrValue(value);
      }
    } else {
      setCurrValue({ dataTime: new Date(), isUnlimit: DEFAULT_UNLIMIT });
    }
  }, [value]);

  // const { dataTime, isUnlimit } = useMemo(() => {
  //   //const momentParseDate = (date) => moment(date).format(formatParamDateTime({ isTime: time }));
  //   const parseDate = (_value: any) => (dayjs(_value) !== undefined ? _value : dayjs(new Date()));
  //   const crrUnlimit = value?.isUnlimit || DEFAULT_UNLIMIT;
  //   if (value && value.dataTime) {
  //     return {
  //       dataTime: parseDate(value.dataTime),
  //       isUnlimit: crrUnlimit,
  //     };
  //   } else {
  //     const defaultValue = {
  //       dataTime: dayjs(new Date()),
  //       isUnlimit: crrUnlimit,
  //     };
  //     onChange &&
  //       onChange({
  //         dataTime: defaultValue.dataTime,
  //         isUnlimit: defaultValue.isUnlimit,
  //       });
  //     return defaultValue;
  //   }
  // }, [value?.dataTime, value?.isUnlimit]);

  // useEffect(() => {
  //   setCurrValue({
  //     dataTime,
  //     isUnlimit,
  //   });
  // }, [dataTime, isUnlimit]);

  // useEffect(() => {
  //   let flag = true;
  //   if (isEqual(value, currValue)) flag = false;
  //   if (currValue === null) flag = false;
  //   flag &&
  //     onChange &&
  //     onChange({
  //       dataTime: currValue?.dataTime,
  //       isUnlimit: currValue?.isUnlimit,
  //     });
  // }, [currValue]);

  function handleChangeValue(type: any, value: any) {
    const newValue = currValue;
    newValue[type] = value;
    setCurrValue(newValue);
    //callback
    onChange && onChange(newValue);
  }

  const onChangeSwitch = async (e: any) => {
    const newIsUnlimit = !currValue.isUnlimit;
    handleChangeValue('isUnlimit', newIsUnlimit);
    if (newIsUnlimit === false) {
      if (minDate !== null) {
        if (dayjs(minDate).diff(currValue.dataTime, 'day') > 0) {
          handleChangeValue('dataTime', minDate);
        }
      }
    }
  };

  const cusProps: any = {};
  if (minDate !== null) cusProps.minDate = minDate;
  if (maxDate !== null) cusProps.maxDate = maxDate;

  return (
    <>
      <div className={'wd-100p'}>
        <div className={'d-flex align-items-center'}>
          <div className={'pos-relative flex-grow-1'}>
            {currValue?.isUnlimit ? (
              <input
                className={'form-control'}
                readOnly={true}
                disabled={true}
                style={{ height: 40 }}
              />
            ) : (
              <DatePicker
                selected={currValue?.dataTime ? new Date(currValue?.dataTime) : new Date()}
                onChange={(value) => handleChangeValue('dataTime', value)}
                {...cusProps}
              />
            )}
          </div>
          <div className="custom-control">
            {/* <SpanLang keyLang={'Sales End Date'} /> */}
            <Switch
              className={'d-flex'}
              label={unlimitLabel}
              value={currValue.isUnlimit}
              onChange={onChangeSwitch}
            />
          </div>
        </div>
        <div className="mg-t-5">
          <SpanLang className="tx-orange" keyLang={tooltip} />
        </div>
      </div>
    </>
  );
};

export default UnLimitDatePicker;
