export const colors = {
  /*
   * multiValue(remove)/color:hover
   */
  danger: 'var(--danger)',

  /*
   * multiValue(remove)/backgroundColor(focused)
   * multiValue(remove)/backgroundColor:hover
   */
  dangerLight: 'var(--danger-light)',

  /*
   * control/backgroundColor
   * menu/backgroundColor
   * option/color(selected)
   */
  neutral0: 'var(--dropdown-color)',

  /*
   * control/backgroundColor(disabled)colo
   */
  neutral5: 'var(--button-hover-color)',

  /*
   * control/borderColor(disabled)
   * multiValue/backgroundColor
   * indicators(separator)/backgroundColor(disabled)
   */
  neutral10: 'var(--border-color-2)',

  /*
   * control/borderColor
   * option/color(disabled)
   * indicators/color
   * indicators(separator)/backgroundColor
   * indicators(loading)/color
   */
  neutral20: 'var(--border-color-2)',

  /*
   * control/borderColor(focused)
   * control/borderColor:hover
   */
  neutral30: 'var(--neutral-30)',

  /*
   * menu(notice)/color
   * singleValue/color(disabled)
   * indicators/color:hover
   */
  neutral40: 'var(--text-color-disabled)', // TODO

  /*
   * placeholder/color
   */
  neutral50: 'var(--text-color-hover)',

  /*
   * indicators/color(focused)
   * indicators(loading)/color(focused)
   */
  neutral60: 'var(--neutral-60)',

  neutral70: 'var(--neutral-70)',

  /*
   * input/color
   * multiValue(label)/color
   * singleValue/color
   * indicators/color(focused)
   * indicators/color:hover(focused)
   */
  neutral80: 'var(--text-color)',

  neutral90: 'var(--color-inverse-01)',

  /*
   * control/boxShadow(focused)
   * control/borderColor(focused)
   * control/borderColor:hover(focused)
   * option/backgroundColor(selected)
   * option/backgroundColor:active(selected)
   */
  primary: 'var(--dropdown-selected-color)',

  /*
   * option/backgroundColor(focused)
   */
  primary25: 'var(--dropdown-hover-color)',

  /*
   * option/backgroundColor:active
   */
  primary50: 'var(--primary-50)',

  primary75: 'var(--primary-75)',
};
