import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Select } from '@base/components/form';
import { useTranslation } from 'react-i18next';
import { useMenuSettings } from '@settings/general-setting/services/format-service';

interface MenuSourceSelectProps {
  outSide?: boolean;
  className: string;
  single: boolean;
  isDisabled: boolean;
  placeholder?: string;
  optionLabel?: string;
  optionValue?: string;
  sourceKey: string;
  sourceMenu: string;
  value?: any;
  onChange?: (value: any) => void;
}
/**
 * This component applies for get setting options by menu
 */
const MenuSourceSelect = (props: MenuSourceSelectProps) => {
  const {
    outSide = true,
    className,
    single,
    isDisabled,
    placeholder = 'Select...',
    sourceKey, //ex: 'sla'
    sourceMenu, //ex: 'desk'
    optionLabel = 'label',
    optionValue = 'value',
    value, //={}
    onChange,
  } = props;
  const { t } = useTranslation();

  //state
  const [options, setOptions] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>(null);

  //init rows
  useEffect(() => {
    if (single) {
      if (value) {
        if (_.isString(value)) {
          if (value !== selectedOption?.[optionValue]) {
            //find in options
            const fOption = options.find((_ele: any) => _ele?.[optionValue] === value);
            if (fOption) {
              setSelectedOption(fOption);
            }
          }
        } else {
          if (value?.[optionValue] !== selectedOption?.[optionValue]) {
            setSelectedOption(value);
          }
        }
      } else {
        setSelectedOption(null);
      }
    } else {
      if (value) {
        if (Array.isArray(value) && value.length > 0) {
          //2 cases: value=string[] or object[] + selectedOption=object[]
          let isArrayString = _.isString(value[0]);
          if (isArrayString) {
            const selectedIds = selectedOption?.map((_ele: any) => _ele?.[optionValue]);
            if (JSON.stringify(value) !== JSON.stringify(selectedIds)) {
              const newValue: any = [];
              value.map((_item: string) => {
                //find in options
                const fOption = options.find((_ele: any) => _ele?.[optionValue] === _item);
                if (fOption) {
                  newValue.push(fOption);
                }
              });
              setSelectedOption(newValue);
            }
          } else {
            //array object
            if (JSON.stringify(value) !== JSON.stringify(selectedOption)) {
              setSelectedOption(value);
            }
          }
        } else {
          setSelectedOption([]);
        }
      } else {
        setSelectedOption(null);
      }
    }
  }, [value, options]);

  // ============= HOOK get field options ======================
  //get
  const params = {
    keys: [sourceKey],
    menus: [sourceMenu],
  };
  const { data: postResult, isLoading } = useMenuSettings(params);

  //init states list
  useEffect(() => {
    if (postResult?.data) {
      const newKeyValue = postResult.data.find((_ele: any) => _ele.key === sourceKey);
      if (newKeyValue) {
        try {
          let newOptions = JSON.parse(newKeyValue.value);
          //console.log('newOptions', newOptions);
          //if optionLabel is 'languageKey' --> display label by lang
          newOptions.map((_ele: any) => ({ ..._ele, [optionLabel]: t(_ele?.[optionLabel]) }));
          setOptions(newOptions);
        } catch {
          setOptions([]);
        }
      }
    } else {
      setOptions([]);
    }
  }, [postResult]);

  //option change
  const handleValueChange = (newValue: any) => {
    if (newValue) {
      setSelectedOption(newValue);
      //callback
      onChange && onChange(newValue);
    }
  };

  //render
  return (
    <div className={classNames('pos-relative', className)}>
      <Select
        isClearable
        outSide={outSide}
        isSearchable={false}
        isMulti={!single}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isLoading={isLoading}
        value={selectedOption}
        options={options}
        onChange={handleValueChange}
        getOptionLabel={(opt: any) => opt[optionLabel] || 'label'}
        getOptionValue={(opt: any) => opt[optionValue] || 'value'}
      />
    </div>
  );
};

export default MenuSourceSelect;
