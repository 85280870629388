import React from 'react';
import Icon, { mainIcons, icons } from '@base/assets/icons/svg-icons';
import FeatherIcon from '../feather';
import styled from '@emotion/styled';
import { IconType } from '@base/types/types/app';

interface FormIconProps {
  icon: string; //name
  iconType: IconType;
  color?: string;
  size?: number;
  attrs?: any;
  className?: string;
}

const SpanIcon = styled.span<FormIconProps>`
  padding: 0px;
  margin: 0px;
  svg {
    width: ${(props: FormIconProps) => props.size + 'px !important'};
    height: ${(props: FormIconProps) => props.size + 'px !important'};
    stroke: ${(props: FormIconProps) => props.color};
  }
`;

const FormIcon = (props: FormIconProps) => {
  const { icon, iconType, color, size, className, attrs } = props;

  if (iconType === 'feather') {
    return <FeatherIcon name={icon} size={size} color={color} className={className} {...attrs} />;
  } else if (iconType === 'main' && mainIcons[icon]) {
    return mainIcons[icon];
  } else {
    return (
      <SpanIcon size={size} color={color} className={className} {...attrs}>
        {icons[icon] ? icons[icon] : ''}
      </SpanIcon>
    );
  }
};

FormIcon.defaultProps = {
  icon: 'Users', //default
  iconType: 'feather',
  color: 'currentColor',
  size: 18,
  attrs: {},
};

export default FormIcon;
