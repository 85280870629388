// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".react-ip-input{display:inline-block;border:1px solid #c2c3c9;border-radius:4px;padding:5px 8px}.react-ip-input .react-ip-input__item{display:inline-block}.react-ip-input.has-error{border-color:#a94442;box-shadow:inset 0 1px 1px rgba(0,0,0,.075)}.react-ip-input input{width:30px;border:none;outline:none;text-align:center;background-color:var(--input-color);color:var(--text-color-2)}", "",{"version":3,"sources":["webpack://./src/base/components/form/ip-input/style.scss"],"names":[],"mappings":"AAAA,gBACE,oBAAA,CACA,wBAAA,CACA,iBAAA,CACA,eAAA,CAGF,sCACE,oBAAA,CAGF,0BACE,oBAAA,CACA,2CAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA,CACA,mCAAA,CACA,yBAAA","sourcesContent":[".react-ip-input {\n  display: inline-block;\n  border: 1px solid #c2c3c9;\n  border-radius: 4px;\n  padding: 5px 8px;\n}\n\n.react-ip-input .react-ip-input__item {\n  display: inline-block;\n}\n\n.react-ip-input.has-error {\n  border-color: #a94442;\n  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);\n}\n\n.react-ip-input input {\n  width: 30px;\n  border: none;\n  outline: none;\n  text-align: center;\n  background-color: var(--input-color);\n  color: var(--text-color-2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"react-ip-input": "react-ip-input",
	"react-ip-input__item": "react-ip-input__item",
	"has-error": "has-error"
};
export default ___CSS_LOADER_EXPORT___;
