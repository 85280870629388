import React from 'react';
import classNames from 'classnames';
import { Theme } from '@base/types/interfaces/app';

const ViewHeaderLeftContentRight: React.FC<any> = (props: any) => {
  const { skin, onChange } = props;

  const handleChangeTheme = (theme: Theme) => {
    onChange && onChange(theme);
  };

  return (
    <div className="row row-xs">
      <div className="col-4 cursor-pointer">
        <div
          className={classNames('df-mode df-mode-light', { active: skin === 'light' })}
          onClick={() => handleChangeTheme('light')}
        >
          <span className="bg-ui-01" />
          <span className="bg-ui-02" />
        </div>
        <span className="df-skin-name">Light</span>
      </div>
      <div className="col-4 cursor-pointer">
        <div
          className={classNames('df-mode df-mode-dark', { active: skin === 'dark' })}
          onClick={() => handleChangeTheme('dark')}
        >
          <span className="bg-gray-700" />
          <span className="bg-gray-900" />
        </div>
        <span className="df-skin-name tx-nowrap">Dark</span>
      </div>
    </div>
  );
};

export default ViewHeaderLeftContentRight;
