// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".image-container{background-color:#ccc;overflow:hidden;position:relative;max-width:800px;margin:20px auto}.image{position:absolute;width:100%;height:100%;opacity:0}.image.thumb{opacity:1;filter:blur(10px);transition:opacity 1s ease-out;position:absolute}.image.thumb.is-loaded{opacity:0}.image.is-loaded{transition:opacity 1s ease-out;opacity:1}", "",{"version":3,"sources":["webpack://./src/base/components/lazy-load-image/styles.scss"],"names":[],"mappings":"AAAA,iBACE,qBAAA,CACA,eAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEF,OACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CAEA,aACE,SAAA,CACA,iBAAA,CACA,8BAAA,CACA,iBAAA,CACA,uBACE,SAAA,CAIJ,iBACE,8BAAA,CACA,SAAA","sourcesContent":[".image-container {\n  background-color: #ccc;\n  overflow: hidden;\n  position: relative;\n  max-width: 800px;\n  margin: 20px auto;\n}\n.image {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  opacity: 0;\n\n  &.thumb {\n    opacity: 1;\n    filter: blur(10px);\n    transition: opacity 1s ease-out;\n    position: absolute;\n    &.is-loaded {\n      opacity: 0;\n    }\n  }\n\n  &.is-loaded {\n    transition: opacity 1s ease-out;\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image-container": "image-container",
	"image": "image",
	"thumb": "thumb",
	"is-loaded": "is-loaded"
};
export default ___CSS_LOADER_EXPORT___;
