import React from 'react';
import { Plus } from 'react-feather';
import classNames from 'classnames';
import { FormIcon } from '../form/icon';
import Button from '@base/components/form/button';
import { IconType } from '@base/types/types/app';

interface props {
  icon?: string;
  iconType?: IconType;
  iconSize?: number;
  label?: string;
  labelButton?: string;
  onClick?: () => void;
  ComponentButton?: any;
  className?: string;
}

const NoData: React.FC<props> = ({
  icon,
  iconType,
  iconSize,
  label,
  labelButton,
  onClick,
  ComponentButton,
  className,
}: props) => {
  return (
    <div
      className={classNames(
        'd-flex flex-column justify-content-center align-items-center tx-color-04 pd-10',
        className,
      )}
    >
      <FormIcon icon={icon} iconType={iconType} size={iconSize} />
      <p className="mg-y-8 tx-nowrap">
        {/* <HanSpanLang keyLang={"common.common_no_data"} /> */}
        {label || 'No data available.'}
      </p>
      <div className="d-flex">
        {ComponentButton}
        {labelButton && (
          <Button color="primary" outline onClick={onClick}>
            <Plus /> {labelButton || 'Add'}
          </Button>
        )}
      </div>
    </div>
  );
};

NoData.defaultProps = {
  icon: 'Database',
  iconType: 'feather',
  iconSize: 40,
};

export default NoData;
