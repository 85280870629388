import { Theme } from '@emotion/react';

export interface ThemeGroup {
  light: Theme;
  dark: Theme;
}

/**
 * @light theme
 */
export const light: Theme = {
  fontColor: {
    body: '#000',
    container: '#000',
    section: '#000',
  },
  bgColor: {
    body: '#fff',
    container: '#fff',
    section: '#fff',
  },
};

/**
 * @dark theme
 */
export const dark: Theme = {
  fontColor: {
    body: '#fff',
    container: '#fff',
    section: '#fff',
  },
  bgColor: {
    body: '#000',
    container: '#eee',
    section: '#eee',
  },
};

const mode: ThemeGroup = {
  light,
  dark,
};

export default mode;
