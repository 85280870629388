import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { nl2br } from '@base/utils/helpers';
import { moneyFormat } from '@base/utils/helpers/general.utils';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

interface Props {
  value: string | null;
  isBadge?: boolean;
  className?: string;
}

const Container = styled.div`
  .crm-customer-view-text-no-data {
    width: 50px;
    border-bottom: 1px dashed #cccccc;
  }
  .is-badge {
    background-color: #f3f5f7;
    padding: 5px 10px;
    border-radius: 4px;
    display: inline;
  }
`;

const View = ({ value = '', isBadge = false, className = '' }: Props) => {
  if (typeof value != 'number' && typeof value != 'string') {
    return null;
  }
  //// console.log(value);

  const { t } = useTranslation();

  // const valueNew = useMemo(() => (value?.includes?.('crm_new') ? t(value) : value), [value, t]);

  const [valueNew, setValueNew] = useState(value ?? '');
  useEffect(() => {
    setValueNew(typeof value === 'string' ? t(value ?? '') : moneyFormat(value));
  }, [value]);

  const children =
    typeof valueNew === 'string'
      ? { dangerouslySetInnerHTML: { __html: nl2br(valueNew) } }
      : { children: valueNew };

  return (
    <Container className="crm-view-detail">
      <div
        className={classnames(className, {
          'crm-customer-view-text-no-data': valueNew == '' || valueNew == null,
          ['is-badge']: isBadge,
        })}
        style={{ wordBreak: 'break-all' }}
        //{...children}
      >
        {valueNew}
      </div>
    </Container>
  );
};

View.defaultProps = {
  value: '',
  isBadge: false,
  className: '',
};

export default View;
