import React, { useState, useRef, useEffect, Fragment, VFC } from 'react';
import { Clock, Calendar as CalendarIcon } from 'react-feather';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { clickOutSide } from '@base/utils/helpers';
import Portal from '@base/components/portal';
import dayjs from 'dayjs';

const CalendarWrapper = styled.div`
  background-color: var(--background-box);
  border-radius: 5px;
  input {
    background-color: var(--input-color) !important;
    border: 1px solid var(--input-border-color);
  }
  .input-group-text {
    border: 1px solid var(--input-border-color);
    border-left: 0;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    > svg {
      opacity: 0.5;
    }
  }
`;

interface IDate {
  name: string;
  onSet: () => void;
  onChange: (date: Date) => void;
  date: any;
  time: any;
  isInline: boolean;
  readOnly: boolean;
  userFormat?: string | null;
  inputRender?: (props: any) => any;
}

const Date: VFC<IDate> = (props: IDate) => {
  const {
    name,
    onSet,
    onChange = null,
    date,
    time,
    isInline = false,
    readOnly = true,
    userFormat = null, //format date by user
    inputRender,
  } = props;

  //state
  const [dialogShown, showDialog] = useState<boolean>(false);
  const [dateState, setDate] = useState<Date>(date);
  const buttonRef = useRef<any>(null);
  let popper;

  //set data
  useEffect(() => {
    if (date) {
      setDate(date);
    }
  }, [date]);

  useEffect(() => {
    if (dialogShown) {
      const offOutside = clickOutSide('showCustomerSelect', () => {
        showDialog(false);
      });

      if (offOutside) offOutside();
    }
  }, [dialogShown]);

  const getReferentObject = () => {
    const el = buttonRef.current;

    return {
      getBoundingClientRect: () => {
        return el.getBoundingClientRect();
      },
      clientWidth: el.clientWidth,
      clientHeight: el.clientHeight,
    };
  };

  const onSetDate = (date: any) => {
    /*// setDate(date);
    //convert to ISO string
    onSet && onSet(date);
    onChange && onChange(date); //custom
    //showDialog(false);*/
  };

  //trungtm - get local date time
  const toJSONLocal = (date: any, isTime: boolean) => {
    /*let local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return isTime
      ? local.toJSON().slice(0, 10) + ' ' + date.getHours() + ':' + date.getMinutes()
      : local.toJSON().slice(0, 10);*/
  };

  //get date time by default or user
  const getDate = (): string => {
    return '';
    /*if (userFormat) {
      return '';
      //by user format
      //return userFormat({ date: dateState, isTime: time });
    } else {
      //return by ISO format
      return toJSONLocal(dateState, time); //'MM-DD-YYYY HH:mm' : 'MM-DD-YYYY'
    }*/
  };

  return (
    <CalendarWrapper>
      {!inputRender ? (
        <span className={classNames('input-group', { 'wd-50p d-inline-flex': isInline })}>
          <input
            onFocus={() => showDialog(true)}
            ref={buttonRef}
            readOnly={readOnly}
            type="datetime"
            value={getDate()}
            className="form-control ht-38"
          />
          <span className="input-group-append ht-38">
            <span className="input-group-text">{time ? <Clock /> : <CalendarIcon />}</span>
          </span>
        </span>
      ) : (
        inputRender({
          onClick: () => showDialog(true),
          value: getDate(),
          onRef: (el: any) => (buttonRef.current = el),
        })
      )}

      {dialogShown && (
        <Portal id={document.body}>
          <div>test</div>
          {/*<Popper
            id="showCustomerSelect"
            el={getReferentObject()}
            ref={(ref: any) => (popper = ref)}
            placement={'top'}
            className={'algolia-places'}
          >
            <div>test</div>
            <DatePicker onChange={() => {}} selected={new Date()} />
          </Popper>*/}
        </Portal>
      )}
      <input type={'hidden'} name={name} value={dayjs(date).format()} />
    </CalendarWrapper>
  );
};

export default Date;
