import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import validator from 'validator';
import { ActionMeta, components, OnChangeValue } from 'react-select';
import CreatableSelect from '@base/components/form/select/creatable';
import { Avatar } from '@base/components';
import { FormIcon } from '@base/components/form/icon';
import { useCustomersAutoComplete } from '@customer/customer/services/list-service'; //TODO
import { useTranslation } from 'react-i18next';

interface IEmailAutoCompleteProps {
  className?: string;
  ctrlStyles?: any;
  placeholder?: string;
  single?: boolean;
  visible?: boolean;
  showAvatar?: boolean;
  showEmail?: boolean;
  showPhone?: boolean;
  showAllOption?: boolean;
  isDisabled?: boolean;
  exceptItems?: any;
  value: any;
  onChange: any;
}

/**
 *
 * @param {*} props
 * @returns
 */
const EmailPhoneAutoComplete: React.FC<IEmailAutoCompleteProps> = (props) => {
  const {
    className,
    ctrlStyles,
    placeholder = '',
    single = false, //
    visible = true, //hide or display selected items
    showAvatar = false,
    showEmail = false,
    showPhone = false,
    showAllOption = false,
    isDisabled = false,
    value, //[], initial value
    onChange,
  } = props;
  const { t } = useTranslation();
  //state
  const [isLoading, setIsLoading] = useState(false);
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');
  const setSearchTextDebounced = useRef(
    _.debounce((searchText) => setSearchText(searchText), 1000),
  ).current;
  const [options, setOptions] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState<any>(null);

  //initial selected
  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          //2 cases for value: string[] or object[]
          let isArrayString = _.isString(value[0]);
          if (isArrayString) {
            const selectedIds = selectedValue?.map((_ele: any) => _ele.value);
            if (JSON.stringify(value) !== JSON.stringify(selectedIds)) {
              const newValue: any = [];
              value.map((_item: string) => {
                //find in options
                const fOption = options.find((_ele: any) => _ele.value === _item);
                if (fOption) {
                  newValue.push(fOption);
                }
              });
              setSelectedValue(newValue);
            }
          } else {
            //array object
            if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
              setSelectedValue(value);
            }
          }
        } else {
          setSelectedValue([]);
        }
      } else {
        //single object
        if (_.isString(value)) {
          if (value !== selectedValue?.value) {
            //find in options
            const fOption = options.find((_ele: any) => _ele.value === value);
            if (fOption) {
              setSelectedValue(fOption);
            }
          }
        } else {
          if (value?.value !== selectedValue?.value) {
            setSelectedValue(value);
          }
          //if value is not in options
          const fIndex = options.findIndex((_ele: any) => _ele.value === value?.value);
          if (fIndex === -1) {
            const newOptions = [...options];
            newOptions.unshift(value);
            setOptions(newOptions);
          }
        }
      }
    } else {
      setSelectedValue(null);
    }
  }, [value, options]);

  //build params
  const getSearchParams = () => {
    let params: any = {
      filter: {
        query: '',
        paging: {
          page: 1,
          size: 50,
        },
      },
    };

    if (searchText) {
      params.filter.query += `keyword=${searchText}`; //type=3,4
    }

    return params;
  };

  //convert to select options
  const formatSelectOptions = (results: any) => {
    let tmpOptions = results?.data?.map((_item: any) => {
      return {
        ..._item,
        label: _item.name,
        value: _item.id,
      };
    });
    //TODO: filter by execptIds
    if (showAllOption) {
      tmpOptions?.unshift({
        label: t('All Customers'),
        value: 'all',
      });
    }
    return tmpOptions;
  };

  //TODO: search all emails, phones for user, customer, ...
  const {
    data: results,
    status: searchStatus,
    refetch,
  } = useCustomersAutoComplete(getSearchParams());
  //// console.log('postResult', results);

  //init states list
  useEffect(() => {
    if (results) {
      let newOptions = formatSelectOptions(results);
      setOptions(newOptions);
    } else {
      setOptions([]);
    }
  }, [results]);

  //input text change
  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };

  //value change
  const handleValueChange = (newValue: OnChangeValue<any, false>, actionMeta: ActionMeta<any>) => {
    let newItem = newValue;
    if (visible) {
      if (!single) {
        //just apply for multi
        const allIdx = newItem?.findIndex((ele: any) => ele.value === 'all');
        if (allIdx > -1) {
          newItem = [newItem[allIdx]];
        }
      }
      setSelectedValue(newItem);
    }
    //callback
    onChange && onChange(newItem);
  };

  //create new option - selected value
  const handleValueCreate = (inputValue: string) => {
    //TODO 1: check this value that not existed in current options
    setIsLoading(true);
    setTimeout(() => {
      //add option
      const newOptions = [...options];
      const newOption: any = {
        value: inputValue,
        label: inputValue,
      };
      if (showEmail) {
        newOption.emails = [
          {
            email: inputValue,
            label: { languageKey: 'crm_new_common_label_primary', label: 'LABEL_PRIMARY' },
          },
        ];
        newOption.phones = [
          {
            phoneNumber: 'none',
            label: { languageKey: 'crm_new_common_label_primary', label: 'LABEL_PRIMARY' },
          },
        ];
      }
      if (showPhone) {
        newOption.phones = [
          {
            phoneNumber: inputValue,
            label: { languageKey: 'crm_new_common_label_primary', label: 'LABEL_PRIMARY' },
          },
        ];
        newOption.emails = [
          {
            email: 'none',
            label: { languageKey: 'crm_new_common_label_primary', label: 'LABEL_PRIMARY' },
          },
        ];
      }
      newOptions.push(newOption);
      setOptions(newOptions);
      //set selected value
      if (visible) {
        if (single) {
          setSelectedValue(newOption);
          //callback
          onChange && onChange(newOption);
        } else {
          const newValues = selectedValue ? [...selectedValue] : [];
          newValues.push(newOption);
          setSelectedValue(newValues);
          //callback
          onChange && onChange(newValues);
        }
      }
      setIsLoading(false);
    }, 200);
  };

  //selected options
  const CustomMultiValueLabel = ({ children, ...props }: any) => {
    const { data } = props;
    let isValid = true;
    const primaryEmail = data?.emails?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');
    const primaryPhone = data?.phones?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');
    if (showEmail) {
      if (!validator.isEmail(primaryEmail?.email || '')) {
        isValid = false;
      }
    }
    if (showPhone) {
      if (!validator.isNumeric(primaryPhone?.phoneNumber || '')) {
        isValid = false;
      }
    }

    const getLabel = () => {
      if (showEmail) {
        return `${children} <${primaryEmail?.email || 'none'}>`;
      } else if (showPhone) {
        return `${children} <${primaryPhone?.phoneNumber || 'none'}>`;
      } else {
        return children || 'none';
      }
    };

    return (
      <components.MultiValueLabel {...props}>
        <div className="d-flex align-items-center css-customer-autocomplete">
          {showAvatar && (
            <div className="avatar avatar-xs">
              {/* TODO: data?.photo */}
              <Avatar url={''} alt={data?.name} height={26} width={26} />
            </div>
          )}
          <div className={classNames('d-flex mg-l-10 css-flex', { 'tx-danger': !isValid })}>
            {getLabel()}
          </div>
        </div>
      </components.MultiValueLabel>
    );
  };

  //single selected option
  const CustomSingleValueLabel = ({ children, ...props }: any) => {
    const { data } = props;
    let isValid = true;
    const primaryEmail = data?.emails?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');
    const primaryPhone = data?.phones?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');
    if (showEmail) {
      if (!validator.isEmail(primaryEmail?.email || '')) {
        isValid = false;
      }
    }
    if (showPhone) {
      if (!validator.isNumeric(primaryPhone?.phoneNumber || '')) {
        isValid = false;
      }
    }

    const getLabel = () => {
      if (showEmail) {
        return `${children} <${primaryEmail?.email || 'none'}>`;
      } else if (showPhone) {
        return `${children} <${primaryPhone?.phoneNumber || 'none'}>`;
      } else {
        return children || 'none';
      }
    };

    return (
      <components.SingleValue {...props}>
        <div className="d-flex align-items-center css-customer-autocomplete">
          {showAvatar && (
            <div className="avatar avatar-xs">
              {/* TODO: data?.photo */}
              <Avatar url={''} alt={data?.name} height={26} width={26} />
            </div>
          )}
          <div className={classNames('d-flex mg-l-10 css-flex', { 'tx-danger': !isValid })}>
            {getLabel()}
          </div>
        </div>
      </components.SingleValue>
    );
  };

  //custom options
  const CustomOption = ({ children, ...props }: any) => {
    const { data } = props;
    const primaryEmail = data?.emails?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');
    const primaryPhone = data?.phones?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');

    const getLabel = () => {
      if (showEmail) {
        return `${children} <${primaryEmail?.email || 'none'}>`;
      } else if (showPhone) {
        return `${children} <${primaryPhone?.phoneNumber || 'none'}>`;
      } else {
        return children || 'none';
      }
    };

    return (
      <components.Option {...props}>
        <div className="d-flex align-items-center css-customer-autocomplete">
          {showAvatar && (
            <div className="avatar avatar-xs">
              {/* TODO: data?.photo */}
              <Avatar url={''} alt={data?.name} height={26} width={26} />
            </div>
          )}
          <div className="d-flex mg-l-10 css-flex">{getLabel()}</div>
        </div>
        {data.value === 'all' && <hr className="mg-y-10" />}
      </components.Option>
    );
  };

  const newPlaceholder = `Type to select or enter to create a new ${showEmail ? 'email' : 'phone'}`;
  const noOptMsg = `No ${showEmail ? 'emails' : 'phones'} found. `;
  //render
  return (
    <div className={classNames('pos-relative', className)} style={{ minWidth: '250px' }}>
      <CreatableSelect
        isMulti={!single}
        ctrlStyles={ctrlStyles}
        noOptionsMessage={() => noOptMsg}
        placeholder={placeholder ? placeholder : newPlaceholder}
        iconIndicator={
          <div className="icons-size-1">{<FormIcon icon={showEmail ? 'Mail' : 'Phone'} />}</div>
        }
        isClearable
        isDisabled={isDisabled}
        components={{
          Option: CustomOption,
          MultiValueLabel: CustomMultiValueLabel,
          SingleValue: CustomSingleValueLabel,
        }}
        isLoading={searchStatus === 'loading' || isLoading}
        inputValue={inputText}
        onInputChange={handleInputChange}
        value={selectedValue}
        options={options}
        onChange={handleValueChange}
        onCreateOption={handleValueCreate}
      />
    </div>
  );
};

export default EmailPhoneAutoComplete;
