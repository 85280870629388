import React from 'react';
import { Link } from 'react-router-dom';

export interface ItemHeaderProps {
  title?: string;
  url?: string;
  headerRender?: any;
}

const ItemHeader = (props: ItemHeaderProps) => {
  const { title = '', url = '', headerRender } = props;

  //render
  return (
    <div className="d-flex card-grid-header align-items-center">
      <div className="grid-list-item-detail-header wd-100p">
        {headerRender ? (
          headerRender
        ) : (
          <Link to={url} className="grid-list-item-title link-02">
            <span className="tx-bold">{title}</span>
          </Link>
        )}
        {/* <TitleGrid
          keyName={keyName as string}
          value={title}
          link={titleLink}
          onClickLink={onClickTitleLink}
          userPermission={{ isEdit: false }}
          menuSourceId={id}
          menuSource={menuSource as string}
          viewConfig={viewConfig}
        /> */}
      </div>
    </div>
  );
};

export default ItemHeader;
