export default (editor: any, config: any) => {
  const domc = editor.DomComponents;
  const defaultType = domc.getType('link');
  const defaultModel = defaultType.model;
  const defaultView = defaultType.view;

  const btnStyle = {
    display: 'inline-block',
    'text-decoration': 'none',
    cursor: 'pointer',
    'font-weight': '400',
    width: '160px',
    color: 'white',
    'text-align': 'center',
    'vertical-align': 'middle',
    'user-select': 'none',
    'background-color': '#ffa200', //'transparent'
    border: '1px solid transparent',
    padding: '0.2rem 0.6rem',
    'font-size': '1rem',
    'line-height': '1.5',
    'border-radius': '0.25rem',
    transition: `color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out`,
  };

  const linkStyle = {
    color: '#0168fa',
    'text-decoration': 'none',
    'background-color': 'transparent',
  };

  domc.addType('survey-url', {
    model: defaultModel.extend(
      {
        defaults: {
          ...defaultModel.prototype.defaults,
          'custom-name': 'survey-url',
          content: 'Go Survey',
          attributes: { target: '_blank' },
          style: btnStyle,
          traits: [
            {
              type: 'select',
              name: 'surveyType',
              label: 'Type',
              options: [
                { value: 'button', name: 'Button' },
                { value: 'link', name: 'Link' },
              ],
              changeProp: 1,
            },
            {
              type: 'text',
              name: 'surveyName',
              label: 'Name',
              changeProp: 1,
            },
            {
              type: 'text',
              placeholder: 'www.vora.net',
              name: 'surveyLink',
              label: 'Link Url',
              changeProp: 1,
            },
          ],
          surveyType: 'button', //default value for trait 'surveyType'
        },

        async init() {
          //call api to get data
          this.on('change:surveyType', this.handleTypeChange);
          this.on('change:surveyName', this.handleNameChange);
          this.on('change:surveyLink', this.handleLinkChange);
        },

        handleTypeChange(el: any) {
          //// console.log('a type value > ', this.get('surveyType'));
          const curType = this.get('surveyType');
          switch (curType) {
            case 'button':
              el.setStyle(btnStyle);
              break;
            case 'link':
              el.setStyle(linkStyle);
              break;
          }
        },

        handleNameChange(el: any) {
          //// console.log('a name value > ', this.get('surveyName'));
          const newName = this.get('surveyName');
          el.setAttributes({ name: newName });
        },

        handleLinkChange(el: any) {
          //// console.log('a name value > ', this.get('surveyLink'));
          const newLink = this.get('surveyLink');
          el.setAttributes({ href: newLink });
        },

        // toHTML() {
        //   const tagName = this.get('tagName');
        //   const price = this.get('defaultPrice');
        //   return `<${tagName}>${price}</{tagName}>`;
        // },
      },
      {
        isComponent(el: any) {
          // if (el.tagName == 'A' && el.hasAttribute('class="cta-button"')) {
          //   return { type: 'cta-button' };
          // }
          return false;
        },
      },
    ),
    //extendFnView: ['render'],
    view: defaultView.extend({
      // init() {
      //   this.listenTo(this.model, 'change:defaultPrice', this.render);
      // },
      // onRender() {
      //   this.el.innerHTML = this.model.get('defaultPrice');
      // },
      // events: {
      //   click: function () {
      //     if (selecionado && selecionado == this.model) {
      //       selecionado = false;
      //       selecionadoB = false;
      //     } else {
      //       editor.select(this.model);
      //       selecionado = this.model;
      //       selecionadoB = 'link';
      //       let selected = editor.getSelected();
      //       let openSmBtn = editor.Panels.getButton('views', 'opcoesBloco');
      //       openSmBtn.set('active', 1);
      //     }
      //   },
      //   mouseenter: function () {
      //     if (!selecionado) editor.select(this.model);
      //   },
      //   mouseleave: function () {
      //     if (!selecionado) {
      //       editor.select(null);
      //     } else {
      //       editor.select(selecionado);
      //     }
      //   },
      // },
    }),
  });
};
