import React from 'react';
import classNames from 'classnames';
import Avatar from '../avatar';

interface ILogoRankingProps {
  value: any;
  className?: any;
}

const LogoRanking: React.FC<ILogoRankingProps> = ({
  value = {},
  className = '',
}: ILogoRankingProps) => {
  const label = value?.label || '';
  const characterFirst = label?.charAt?.(0) || '';

  return (
    <div className='avatar avatar-sm mg-l-10'>
      <Avatar
        url=''
        alt={characterFirst}
        height={30}
        width={30}
      />
    </div>
    // <div
    //   className={classNames(
    //     'wd-30 ht-30 mg-r-5 pd-0 bd-0 rounded-circle text-center lh-12',
    //     className,
    //   )}
    //   style={{ backgroundColor: '#bc9546' }}
    // >
    //   <span className="tx-white tx-semibold">{characterFirst}</span>
    // </div>
  );
};

export default LogoRanking;
