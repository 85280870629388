import React, {useEffect} from "react";
import {useCustomerPreview} from "@customer/customer/services/view-service";
import {MENU_CUSTOMER} from "@base/config/menus";
import {Link} from "react-router-dom";
import ListTableCellDroplist from "@base/components/list/list-table-cell-droplist";
import PhotoView from "@base/containers/view-field/photo/view";
import {
  ECustomerCategory,
  ECustomerContactType,
  ECustomerType
} from "@base/types/enums/customer";
import * as keyNames from "@customer/customer/config/key-names";
import {Switch} from "@base/components/form";
import {isFunction} from "lodash";
import withTextAndPreviewModal, {QuickViewComponentProps} from "@base/hooks/hocs/withTextAndPreviewModal";
import styled from "@emotion/styled";

const fieldsConfig: any = {
  [keyNames.KEY_NAME_CUSTOMER_INDUSTRIES]: {
    label: "Industry",
    value: (data: any) => {
      return data?.industries?.[0]?.languageKey ?? '-';
    }
  },
  [keyNames.KEY_NAME_CUSTOMER_TYPE]: {
    label: "Customer Type",
    value: (data: any) => {
      return data?.type?.languageKey ?? '-';
    }
  },
  [keyNames.KEY_NAME_CUSTOMER_RATING]: {
    label: "Rating",
    value: (data: any) => {
      return data?.rating?.languageKey ?? '-';
    }
  },
  [keyNames.KEY_NAME_CUSTOMER_PHONES]: {
    label: "Phone",
    value: (data: any) => {
      return data?.phones?.[0].phoneNumber ?? '-';
    }
  },
  [keyNames.KEY_NAME_CUSTOMER_MOBILE]: {
    label: "Mobile",
    value: (data: any) => {
      return data?.mobiles?.[0].phoneNumber ?? '-';
    }
  },
  [keyNames.KEY_NAME_CUSTOMER_EMAIL]: {
    label: "Email",
    value: (data: any) => {
      return data?.emails?.[0].email ?? '-';
    }
  },
  [keyNames.KEY_NAME_CUSTOMER_MAIN_PRODUCT]: {
    label: (data: any) => {
      return data?.typeEnum === ECustomerType.TYPE_VENDOR ? "Vendor Product" : "Main Product";
    },
    value: (data: any) => {
      return data?.flagshipProduct ?? '-';
    }
  },
  [keyNames.KEY_NAME_CUSTOMER_ANNUAL_REVENUE]: {
    label: "Annual Revenue",
    value: (data: any) => {
      return data?.annualRevenue ?? '0';
    }
  },
  [keyNames.KEY_NAME_CUSTOMER_ASSIGN_TO]: {
    label: "Assigned Rep",
    value: (data: any) => {
      return data?.staffs ? <ListTableCellDroplist
        className="no-padding cursor-default"
        showAvatar={false}
        values={data.staffs}
      /> : '-';
    }
  },
  [keyNames.KEY_NAME_CUSTOMER_PREFERRED]: {
    label: "Preferred",
    value: (data: any) => {
      return <Switch value={data?.preferred ?? false}/>;
    }
  },
  [keyNames.KEY_NAME_CUSTOMER_SALES_COMMISSION]: {
    label: "Sales Commission",
    value: (data: any) => {
      return data?.salesCommission ?? '-';
    }
  },
  [keyNames.KEY_NAME_CUSTOMER_SALES_PRODUCT]: {
    label: "Sales Product",
    value: (data: any) => {
      return data?.saleProducts?.[0]?.name ?? '-';
    }
  },
  [keyNames.KEY_NAME_CUSTOMER_RELATED_PRODUCT]: {
    label: "Related Product",
    value: (data: any) => {
      return data?.relatedProducts?.[0]?.name ?? '-';
    }
  },
  [keyNames.KEY_NAME_CUSTOMER_ACCOUNT]: {
    label: "Related Account",
    value: (data: any) => {
      if (data?.account) {
        const url = `/${MENU_CUSTOMER}/account/${data?.account?.id}`;
        return <Link to={url}>{data?.account?.name ?? '-'}</Link>;
      }
      return '-';
    }
  },
  [keyNames.KEY_NAME_CUSTOMER_DEPARTMENT]: {
    label: "Department",
    value: (data: any) => {
      return !!data?.department ? data.department : '-';
    }
  },
  [keyNames.KEY_NAME_CUSTOMER_POSITION]: {
    label: "Work Position",
    value: (data: any) => {
      return !!data?.position ? data.position : '-';
    }
  },
  [keyNames.KEY_NAME_CUSTOMER_EMPLOYEE_ROLE]: {
    label: "Role",
    value: (data: any) => {
      return !!data?.role ? data.role : '-';
    }
  }
};

const accountFieldKeysByType: any = {
  [ECustomerType.TYPE_CUSTOMER]: [
    keyNames.KEY_NAME_CUSTOMER_INDUSTRIES,
    keyNames.KEY_NAME_CUSTOMER_TYPE,
    keyNames.KEY_NAME_CUSTOMER_RATING,
    keyNames.KEY_NAME_CUSTOMER_PHONES,
    keyNames.KEY_NAME_CUSTOMER_EMAIL,
    keyNames.KEY_NAME_CUSTOMER_MAIN_PRODUCT,
    keyNames.KEY_NAME_CUSTOMER_ANNUAL_REVENUE,
    keyNames.KEY_NAME_CUSTOMER_ASSIGN_TO
  ],
  [ECustomerType.TYPE_VENDOR]: [
    keyNames.KEY_NAME_CUSTOMER_INDUSTRIES,
    keyNames.KEY_NAME_CUSTOMER_TYPE,
    keyNames.KEY_NAME_CUSTOMER_PREFERRED,
    keyNames.KEY_NAME_CUSTOMER_MAIN_PRODUCT,
    keyNames.KEY_NAME_CUSTOMER_PHONES,
    keyNames.KEY_NAME_CUSTOMER_EMAIL,
    keyNames.KEY_NAME_CUSTOMER_ASSIGN_TO
  ],
  [ECustomerType.TYPE_SALES_AGENT]: [
    keyNames.KEY_NAME_CUSTOMER_INDUSTRIES,
    keyNames.KEY_NAME_CUSTOMER_TYPE,
    keyNames.KEY_NAME_CUSTOMER_SALES_COMMISSION,
    keyNames.KEY_NAME_CUSTOMER_SALES_PRODUCT,
    keyNames.KEY_NAME_CUSTOMER_PHONES,
    keyNames.KEY_NAME_CUSTOMER_EMAIL,
    keyNames.KEY_NAME_CUSTOMER_ASSIGN_TO
  ],
  [ECustomerType.TYPE_PARTNER]: [
    keyNames.KEY_NAME_CUSTOMER_INDUSTRIES,
    keyNames.KEY_NAME_CUSTOMER_TYPE,
    keyNames.KEY_NAME_CUSTOMER_RELATED_PRODUCT,
    keyNames.KEY_NAME_CUSTOMER_PHONES,
    keyNames.KEY_NAME_CUSTOMER_EMAIL,
    keyNames.KEY_NAME_CUSTOMER_ASSIGN_TO
  ]
};

const contactFieldKeysByType: any = {
  [ECustomerType.TYPE_CUSTOMER]: [
    keyNames.KEY_NAME_CUSTOMER_INDUSTRIES,
    keyNames.KEY_NAME_CUSTOMER_TYPE,
    keyNames.KEY_NAME_CUSTOMER_RATING,
    keyNames.KEY_NAME_CUSTOMER_PHONES,
    keyNames.KEY_NAME_CUSTOMER_MOBILE,
    keyNames.KEY_NAME_CUSTOMER_EMAIL,
    keyNames.KEY_NAME_CUSTOMER_MAIN_PRODUCT,
    keyNames.KEY_NAME_CUSTOMER_ANNUAL_REVENUE,
    keyNames.KEY_NAME_CUSTOMER_ASSIGN_TO
  ],
  [ECustomerType.TYPE_VENDOR]: [
    keyNames.KEY_NAME_CUSTOMER_INDUSTRIES,
    keyNames.KEY_NAME_CUSTOMER_TYPE,
    keyNames.KEY_NAME_CUSTOMER_PREFERRED,
    keyNames.KEY_NAME_CUSTOMER_MAIN_PRODUCT,
    keyNames.KEY_NAME_CUSTOMER_PHONES,
    keyNames.KEY_NAME_CUSTOMER_MOBILE,
    keyNames.KEY_NAME_CUSTOMER_EMAIL,
    keyNames.KEY_NAME_CUSTOMER_ASSIGN_TO
  ],
  [ECustomerType.TYPE_SALES_AGENT]: [
    keyNames.KEY_NAME_CUSTOMER_INDUSTRIES,
    keyNames.KEY_NAME_CUSTOMER_TYPE,
    keyNames.KEY_NAME_CUSTOMER_SALES_COMMISSION,
    keyNames.KEY_NAME_CUSTOMER_SALES_PRODUCT,
    keyNames.KEY_NAME_CUSTOMER_PHONES,
    keyNames.KEY_NAME_CUSTOMER_MOBILE,
    keyNames.KEY_NAME_CUSTOMER_EMAIL,
    keyNames.KEY_NAME_CUSTOMER_ASSIGN_TO
  ],
  [ECustomerType.TYPE_PARTNER]: [
    keyNames.KEY_NAME_CUSTOMER_INDUSTRIES,
    keyNames.KEY_NAME_CUSTOMER_TYPE,
    keyNames.KEY_NAME_CUSTOMER_RELATED_PRODUCT,
    keyNames.KEY_NAME_CUSTOMER_PHONES,
    keyNames.KEY_NAME_CUSTOMER_MOBILE,
    keyNames.KEY_NAME_CUSTOMER_EMAIL,
    keyNames.KEY_NAME_CUSTOMER_ASSIGN_TO
  ]
};

const employeeFieldKeys: any = [
  keyNames.KEY_NAME_CUSTOMER_ACCOUNT,
  keyNames.KEY_NAME_CUSTOMER_DEPARTMENT,
  keyNames.KEY_NAME_CUSTOMER_POSITION,
  keyNames.KEY_NAME_CUSTOMER_EMPLOYEE_ROLE,
  keyNames.KEY_NAME_CUSTOMER_PHONES,
  keyNames.KEY_NAME_CUSTOMER_MOBILE,
  keyNames.KEY_NAME_CUSTOMER_EMAIL,
  keyNames.KEY_NAME_CUSTOMER_ASSIGN_TO
];

const PhotoViewWrapper = styled.div`
  div.photo-view {
    height: auto!important;
    
    div.image-container{
      margin: unset!important;
    }
  }
`;

export const CustomerQuickView = (props: QuickViewComponentProps) => {
  const {id, setLoading} = props;

  const {data, isLoading} = useCustomerPreview(id, []);

  const isAccount = !!data?.category && data.category === ECustomerCategory.CATEGORY_ACCOUNT;
  const isContact = !!data?.category && data.category === ECustomerCategory.CATEGORY_CONTACT;
  const isEmployee = !!data?.contactTypeEnum && data.contactTypeEnum === ECustomerContactType.CONTACT_TYPE_EMPLOYEE;

  const url = `/${MENU_CUSTOMER}/${(isAccount ? 'account' : 'contact')}/${id}`;

  useEffect(() => {
    setLoading && setLoading(isLoading);
  }, [isLoading]);

  let fieldKeys: any = [];

  if (isAccount) {
    fieldKeys = accountFieldKeysByType?.[data?.typeEnum] ?? [];
  } else if (isContact) {
    if (isEmployee) {
      fieldKeys = employeeFieldKeys;
    } else {
      fieldKeys = contactFieldKeysByType?.[data?.typeEnum] ?? [];
    }
  }

  return (
    <div className="pd-20">
      <div className="form-group row">
        <div className="col-sm-4 pd-r-0">
          <PhotoViewWrapper>
            <PhotoView value={data?.photo ?? null} />
          </PhotoViewWrapper>
        </div>
        <div className="col-sm-8 d-flex align-items-center">
          <Link to={url} className="tx-18 font-weight-bold">{data?.name}</Link>
        </div>
      </div>
      {
        fieldKeys?.map((k: any, i: number) => {
          const field = fieldsConfig[k];

          if(!field){
            return null;
          }

          return <div className="form-group row" key={i}>
            <label
              className="col-sm-4 col-form-label">{field?.label && (isFunction(field.label) ? field.label(data) : field.label)}:</label>
            <div className="col-sm-8">
              <label className="col-form-label text-overflow wd-100p">
                {field?.value && (isFunction(field.value) ? field.value(data) : field.value)}
              </label>
            </div>
          </div>
        })
      }
    </div>
  );
};

export default withTextAndPreviewModal(CustomerQuickView, {title: 'Customer Information'});