import { components } from 'react-select';
import { lazy } from 'react';

export const RadioGroup = lazy(() => import('@base/components/form/radio-group'));
export const Select = lazy(() => import('@base/components/form/select'));
export const SelectBox = lazy(() => import('@base/containers/view-field/selectbox/edit'));
export const CheckBox = lazy(() => import('@base/components/form/checkbox'));
export const Switch = lazy(() => import('@base/components/form/switch'));
export const Input = lazy(() => import('@base/components/form/input'));
export const TextArea = lazy(() => import('@base/components/form/text-area'));
export const DateSelector = lazy(() => import('@base/components/form/date-selector'));
export const Percent = lazy(() => import('@base/components/form/percent'));
export const DurationRange = lazy(() => import('@base/components/form/duration-range'));

export const IpAddress = lazy(() => import('@customer/customer/components/ip-address'));
export const Email = lazy(() => import('@customer/customer/components/email'));
export const Address = lazy(() => import('@customer/customer/components/address'));
export const Phone = lazy(() => import('@customer/customer/components/phone'));
export const Website = lazy(() => import('@customer/customer/components/website'));
export const DataSourceSelect = lazy(() => import('@base/containers/data-source-select'));
export const CurrencySelect = lazy(() => import('@base/containers/currency-select'));
export const DatePicker = lazy(() => import('@base/components/date-picker/default'));

//TODO: move to MENU using it
export const Date = lazy(() => import('@base/containers/view-field/date/edit'));
export const InputCodeGenerator = lazy(() => import('@base/containers/code-generator'));
export const UserAutoComplete = lazy(() => import('@base/containers/user-auto-complete'));
export const Lookup = lazy(() => import('@base/containers/view-field/lookup/edit'));
export const CheckboxGroup = lazy(() => import('@base/containers/view-field/checkbox-group/edit'));
export const Tags = lazy(() => import('@base/containers/view-field/tags/edit'));
export const TagSelect = lazy(() => import('@base/components/form/tag-select'));
export const TagInput = lazy(() => import('@base/components/form/tag-input'));
export const Editor = lazy(() => import('@base/components/form/tui-editor'));
export const EditorTemplate = lazy(
  () => import('@base/containers/view-field/editor-template/edit'),
);
export const Duration = lazy(() => import('@base/components/form/duration/edit'));
export const ProductAutoComplete = lazy(
  () => import('@product/product/containers/product-auto-complete'),
);
export const CustomerAutoComplete = lazy(
  () => import('@customer/customer/containers/customer-auto-complete'),
);
export const Reminder = lazy(() => import('@base/components/form/reminder/edit'));
export const Repeat = lazy(() => import('@base/components/form/repeat/edit'));
export const RepeatTime = lazy(() => import('@base/components/form/repeat-time/edit'));

// export const TeamNameSelect = lazy(
//   () => import('@settings/sales-team/components/team-name-select'),
// );
// export const ProcessSelect = lazy(() => import('@settings/sales-team/components/process-select'));
export const UploadFile = lazy(() => import('@base/components/form/upload/file'));
