import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Select } from '@base/components/form';
import { languagesAtom } from '@base/recoil/atoms';
import { useRecoilValue } from 'recoil';

/**
 *
 * @param {*} props
 * @returns
 */
const LanguageSelect: React.FC<any> = (props: any) => {
  const {
    className,
    value, //= "kr"
    onChange,
  } = props;
  const avaiLanguages: any = useRecoilValue(languagesAtom);

  //state
  const [curValue, setCurValue] = useState<any>(null);
  const [options, setOptions] = useState<any>([]);

  //re-initial data for table
  useEffect(() => {
    //set select options
    if (avaiLanguages && avaiLanguages.length > 0) {
      const newOptions = [...options];
      avaiLanguages.map((_item: any) => {
        newOptions.push({
          ..._item,
          value: _item.code,
          label: _item.title,
        });
      });
      setOptions(newOptions);
    }
  }, [avaiLanguages]);

  //set selected item
  useEffect(() => {
    if (value) {
      if (value !== curValue?.value) {
        const _selected = avaiLanguages?.find((_ele: any) => {
          return _ele?.value == value;
        });
        setCurValue(_selected);
      }
    } else {
      //get default item in list
      setCurValue(null);
    }
  }, [value]);

  //option
  const handleValueChange = (newOption: any) => {
    setCurValue(newOption);
    //callback
    onChange && onChange(newOption.value);
  };

  //render
  return (
    <div className={classNames('pos-relative', className)}>
      <Select
        placeholder='Select language...'
        isSearchable={false}
        outSide={true}
        value={curValue}
        options={options}
        onChange={handleValueChange}
      />
    </div>
  );
};

export default LanguageSelect;
