import React, { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import CodeGeneratorSetting from './code-generator-setting';
import { Overlay, Popover } from 'react-bootstrap';
import { Button } from '@base/components/form';
import { X } from 'react-feather';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { SETTING_NEXT_ID_UPDATE } from './query';
interface CodeGeneratorProps {
  containerRef: any;
  target: any;
  show: boolean;
  onHide: any;
  saveCb: any;
  onSettingChange: any;
  menu: string;
  defaultProps: any;
}

const CodeGeneratorPopover: React.FC<CodeGeneratorProps> = (props: CodeGeneratorProps) => {
  const { containerRef, target, show, onHide, saveCb, onSettingChange, menu, defaultProps } = props;
  const [idPop] = useState(uniqueId('code-generator-popover-'));

  //state
  const [showErr, setShowErr] = useState(false);
  const [errorData, setErrorData] = useState<string>('');
  const [autoGenerate, setAutoGenerate] = useState(false); //true/false
  const [nextNumber, setNextNumber] = useState('');
  const [prefixString, setPrefixString] = useState('');

  //create mutation
  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    SETTING_NEXT_ID_UPDATE,
    'setting_updateNextIdSetting',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        //toast.success('Created ticket comment successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('There is an error during creating: ' + JSON.parse(error).message);
      },
    },
  );

  //init data
  useEffect(() => {
    if (defaultProps) {
      setAutoGenerate(defaultProps?.autoGenerate);
      setNextNumber(defaultProps?.nextNumber);
      setPrefixString(defaultProps?.prefixString);
    }
  }, [defaultProps]);

  //success update
  useEffect(() => {
    if (mUpdate.isSuccess) {
      saveCb && saveCb(); //get new next ID
      //onSettingChange && onSettingChange({ autoGenerate, prefixString, nextNumber }); //WHAT FOR?
      onHide();
    }
  }, [mUpdate.isSuccess]);

  //check is number input
  const isNumeric = (value: string) => {
    return /^-?\d+$/.test(value);
  };

  //save setting
  const handleSave = () => {
    //validate
    if (autoGenerate && !isNumeric(nextNumber)) {
      setErrorData('Please enter a valid next number');
      return setShowErr(true);
    }
    if (showErr) setShowErr(false);
    //start saving
    const params = {
      menu,
      value: JSON.stringify({ autoGenerate, nextNumber, prefixString })
    }
    mUpdate.mutate(params);
  };

  return (
    <Overlay
      show={show}
      target={target}
      placement="bottom"
      container={containerRef}
      containerPadding={0}
    >
      <Popover id={idPop}>
        <Popover.Header as="h3">
          <div className='d-flex align-items-center'>
            <div>ID Generator Setting</div>
            <div className='mg-l-auto'>
              <Button color='link' size='sm' className="link-03" onClick={onHide}><X /></Button>
            </div>
          </div>
        </Popover.Header>
        <Popover.Body>
          <div>
            <CodeGeneratorSetting
              autoGenerate={autoGenerate}
              setAutoGenerate={setAutoGenerate}
              nextNumber={nextNumber}
              setNextNumber={setNextNumber}
              prefixString={prefixString}
              setPrefixString={setPrefixString}
            />
            {showErr && <p className="tx-danger pd-t-10">* {errorData}</p>}
          </div>
        </Popover.Body>
        <div className={'ht-60 d-flex justify-content-end align-items-center pd-r-10'}>
          <Button color="secondary" size='sm' className="mg-r-5" onClick={onHide}>
            Close
          </Button>
          <Button color="primary" size='sm' disabled={mUpdate.isLoading} loading={mUpdate.isLoading} onClick={handleSave}>
            Save
          </Button>
        </div>
      </Popover>
    </Overlay>
  );
};

export default CodeGeneratorPopover;
