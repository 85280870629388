import React, { useRef, useCallback, useState } from 'react';
import { NOTE_GET_LIST_BY_MENU } from '@base/services/graphql/note';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Note } from '@base/types/interfaces/note';
import NoteItem from './item';
import { Section, StickyHeader } from './styles';
import { Button } from '@base/components/form';
import { NOTE_PAGE_SIZE } from '@base/config/constant';
import useInfinitePosts from '@base/hooks/useInfinitePosts';
import { makeSection } from '@base/utils/helpers/makeSection';
import NoteWrite from './write';
import Loading from '@base/components/loading';
import { convertDateTimeServerToClient } from '@base/utils/helpers';
import { useParams } from 'react-router-dom';
import { MENU_SOURCE } from '@base/config/menus';
import classNames from 'classnames';

interface NoteProps {
  className?: string;
  menuSource: string;
  menuSourceId: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

function Note(props: NoteProps) {
  const { className = '', menuSource, menuSourceId } = props;
  const params = useParams();
  const id = menuSourceId != '' ? menuSourceId : params?.id ?? '';
  const [isAutoScroll, setAutoScroll] = useState(false);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError } =
    useInfinitePosts<Note[]>(['builtin_notes'], NOTE_GET_LIST_BY_MENU, {
      source: {
        menu: MENU_SOURCE[menuSource] ?? 'MENU_NONE',
        id: id,
      },
      filter: {
        sort: {
          field: 'createdAt',
          orderBy: 'DESC',
        },
        paging: {
          size: NOTE_PAGE_SIZE,
        },
      },
    });

  const scrollbarRef = useRef<Scrollbars>(null);
  const onScroll = useCallback(
    (values: any) => {
      if (isAutoScroll && values.top === 1 && hasNextPage) {
        fetchNextPage();
        scrollbarRef.current?.scrollToBottom();
      }
    },
    [isAutoScroll, fetchNextPage, scrollbarRef, hasNextPage],
  );

  const getMore = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
      scrollbarRef.current?.scrollToBottom();
    }
  }, [fetchNextPage, scrollbarRef, hasNextPage]);

  const onChangeAutoScroll = useCallback(() => {
    setAutoScroll((auto) => !auto);
  }, []);

  const sections = makeSection(data);

  //v2.0
  return (
    <div className={classNames("detail-view scroll-box pd-15", className)}>
      {/* <div className="mb-2 float-right custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id="noteAutoScroll"
          onClick={onChangeAutoScroll}
        />
        <label className="custom-control-label" htmlFor="noteAutoScroll">
          AutoScroll
        </label>
      </div> */}
      <NoteWrite source={menuSource} sourceId={id} />

      {!isError && (
        <Scrollbars
          ref={scrollbarRef}
          autoHeight
          autoHeightMin={400}
          autoHeightMax={document.body.scrollHeight - 300}
          autoHide
          onScrollFrame={onScroll}
        >
          {Object.entries(sections).map(([date, notes]) => {
            return (
              <Section key={date}>
                <StickyHeader>
                  <button>{convertDateTimeServerToClient({ date: date })}</button>
                </StickyHeader>
                {notes.map((note) => (
                  <NoteItem key={note.id} data={note} source={menuSource} sourceId={id} />
                ))}
              </Section>
            );
          })}
        </Scrollbars>
      )}

      {(isLoading || isFetchingNextPage) && <Loading />}

      {!isAutoScroll && hasNextPage && (
        <Button
          block={true}
          color={'light'}
          className="bd-0 tx-uppercase tx-10 tx-spacing-1 tx-medium mg-t-10"
          name="Load More"
          onClick={() => getMore()}
        />
      )}
    </div>
  );
}

export default Note;
