import React from 'react';

const xls = () => {
  return (
    <svg id="file-type-xls" xmlns="http://www.w3.org/2000/svg" width="42px" height="51px" viewBox="0 0 42 51">
      <path fill="#95C94C" d="M42,49c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h32l8,8V49z" />
      <path fill="#83B73B" d="M42,14.83l-7.38-7.39L42,8V14.83z" />
      <path fill="#C8F092" d="M42,8h-6c-1.104,0-2-0.896-2-2V0L42,8z" />
      <path fill="#95C94C" d="M42,46c0,0.553-0.447,1-1,1H17c-0.552,0-1-0.447-1-1V28h25c0.553,0,1,0.447,1,1V46z" />
      <path
        fill="#94C84B"
        d="M15.724,27.759l1.472-0.034h23.529c0.552,0,1,0.448,1,1v17c0,0.553-0.448,1-1,1h-24c-0.552,0-1-0.447-1-1
            V27.759z"
      />
      <path
        fill="#93C74B"
        d="M15.448,27.518l1.455-0.068h23.544c0.553,0,1,0.447,1,1v17c0,0.553-0.447,1-1,1h-24c-0.552,0-1-0.447-1-1
            V27.518z"
      />
      <path
        fill="#92C64A"
        d="M15.172,27.275l1.438-0.104h23.562c0.553,0,1,0.447,1,1v17c0,0.553-0.447,1-1,1h-24c-0.552,0-1-0.447-1-1
            V27.275z"
      />
      <path
        fill="#92C549"
        d="M14.896,27.034l1.421-0.138h23.58c0.552,0,1,0.447,1,1v17c0,0.552-0.448,1-1,1h-24c-0.552,0-1-0.448-1-1
            V27.034z"
      />
      <path
        fill="#91C449"
        d="M14.621,26.793c0-0.095,1.31-0.172,1.405-0.172h23.596c0.552,0,1,0.447,1,1v17c0,0.553-0.448,1-1,1h-24
            c-0.552,0-1-0.447-1-1L14.621,26.793L14.621,26.793z"
      />
      <path
        fill="#90C348"
        d="M14.345,26.552c0-0.114,1.273-0.206,1.388-0.206h23.613c0.552,0,1,0.447,1,1v17c0,0.552-0.448,1-1,1h-24
            c-0.553,0-1-0.448-1-1V26.552z"
      />
      <path
        fill="#8FC247"
        d="M14.069,26.311c0-0.134,1.238-0.24,1.371-0.24h23.628c0.553,0,1,0.447,1,1v17c0,0.552-0.447,1-1,1h-24
            c-0.552,0-1-0.448-1-1V26.311z"
      />
      <path
        fill="#8EC146"
        d="M13.793,26.068c0-0.152,1.202-0.275,1.354-0.275h23.646c0.553,0,1,0.447,1,1v17c0,0.553-0.447,1-1,1h-24
            c-0.552,0-1-0.447-1-1V26.068z"
      />
      <path
        fill="#8DC046"
        d="M13.517,25.828c0-0.171,1.166-0.311,1.337-0.311h23.664c0.552,0,1,0.448,1,1v17c0,0.552-0.448,1-1,1h-24
            c-0.552,0-1-0.448-1-1V25.828z"
      />
      <path
        fill="#8CBF45"
        d="M13.241,25.586c0-0.191,1.13-0.345,1.32-0.345h23.68c0.552,0,1,0.447,1,1v17c0,0.552-0.448,1-1,1h-24
            c-0.552,0-1-0.448-1-1V25.586z"
      />
      <path
        fill="#8CBE44"
        d="M12.965,25.345c0-0.209,1.094-0.379,1.304-0.379h23.697c0.552,0,1,0.448,1,1v17c0,0.552-0.448,1-1,1h-24
            c-0.552,0-1-0.448-1-1L12.965,25.345L12.965,25.345z"
      />
      <path
        fill="#8BBD44"
        d="M12.689,25.104c0-0.229,1.058-0.414,1.287-0.414h23.713c0.552,0,1,0.448,1,1v17c0,0.553-0.448,1-1,1h-24
            c-0.552,0-1-0.447-1-1V25.104z"
      />
      <path
        fill="#8ABC43"
        d="M12.414,24.862c0-0.248,1.022-0.449,1.27-0.449h23.73c0.553,0,1,0.448,1,1v17c0,0.552-0.447,1-1,1h-24
            c-0.552,0-1-0.448-1-1V24.862z"
      />
      <path
        fill="#89BB42"
        d="M12.138,24.621c0-0.267,0.986-0.483,1.253-0.483h23.748c0.552,0,1,0.448,1,1v17c0,0.552-0.448,1-1,1h-24
            c-0.552,0-1-0.448-1-1V24.621z"
      />
      <path
        fill="#88BB42"
        d="M11.862,24.379c0-0.286,0.95-0.517,1.236-0.517h23.763c0.553,0,1,0.448,1,1v17c0,0.553-0.447,1-1,1h-24
            c-0.552,0-1-0.447-1-1V24.379z"
      />
      <path
        fill="#87BA41"
        d="M11.586,24.138c0-0.305,0.914-0.551,1.219-0.551h23.781c0.553,0,1,0.448,1,1v17c0,0.552-0.447,1-1,1h-24
            c-0.552,0-1-0.448-1-1V24.138z"
      />
      <path
        fill="#86B940"
        d="M11.311,23.896c0-0.324,0.878-0.586,1.202-0.586h23.797c0.553,0,1,0.448,1,1v17c0,0.552-0.447,1-1,1h-24
            c-0.552,0-1-0.448-1-1V23.896z"
      />
      <path
        fill="#85B840"
        d="M11.035,23.655c0-0.343,0.842-0.621,1.185-0.621h23.814c0.552,0,1,0.448,1,1v17c0,0.552-0.448,1-1,1h-24
            c-0.552,0-1-0.448-1-1L11.035,23.655L11.035,23.655z"
      />
      <path
        fill="#85B73F"
        d="M10.759,23.414c0-0.362,0.807-0.655,1.168-0.655h23.832c0.552,0,1,0.448,1,1v17c0,0.553-0.448,1-1,1h-24
            c-0.552,0-1-0.447-1-1V23.414z"
      />
      <path
        fill="#84B63E"
        d="M10.483,23.172c0-0.381,0.771-0.689,1.151-0.689h23.849c0.552,0,1,0.448,1,1v17c0,0.553-0.448,1-1,1h-24
            c-0.552,0-1-0.447-1-1L10.483,23.172L10.483,23.172z"
      />
      <path
        fill="#83B53E"
        d="M10.207,22.931c0-0.4,0.735-0.724,1.135-0.724h23.865c0.553,0,1,0.448,1,1v17c0,0.553-0.447,1-1,1h-24
            c-0.552,0-1-0.447-1-1V22.931z"
      />
      <path
        fill="#82B43D"
        d="M9.931,22.689c0-0.419,0.699-0.758,1.118-0.758h23.883c0.552,0,1,0.448,1,1v17c0,0.552-0.448,1-1,1h-24
            c-0.552,0-1-0.448-1-1V22.689z"
      />
      <path
        fill="#81B33C"
        d="M9.655,22.448c0-0.438,0.663-0.793,1.101-0.793h23.898c0.553,0,1,0.448,1,1v17c0,0.553-0.447,1-1,1h-24
            c-0.552,0-1-0.447-1-1V22.448z"
      />
      <path
        fill="#80B23B"
        d="M9.379,22.207c0-0.458,0.627-0.828,1.084-0.828h23.916c0.553,0,1,0.448,1,1v17c0,0.553-0.447,1-1,1h-24
            c-0.552,0-1-0.447-1-1V22.207z"
      />
      <path
        fill="#7FB13B"
        d="M9.104,21.965c0-0.476,0.591-0.862,1.067-0.862h23.933c0.553,0,1,0.448,1,1v17c0,0.552-0.447,1-1,1h-24
            c-0.552,0-1-0.448-1-1V21.965z"
      />
      <path
        fill="#7FB03A"
        d="M8.828,21.724c0-0.496,0.555-0.896,1.05-0.896h23.949c0.552,0,1,0.448,1,1v17c0,0.553-0.448,1-1,1h-24
            c-0.552,0-1-0.447-1-1L8.828,21.724L8.828,21.724z"
      />
      <path
        fill="#7EAF39"
        d="M8.552,21.483c0-0.515,0.52-0.931,1.034-0.931h23.967c0.552,0,1,0.448,1,1v17c0,0.553-0.448,1-1,1h-24
            c-0.553,0-1-0.447-1-1V21.483z"
      />
      <path
        fill="#7DAE39"
        d="M8.276,21.241c0-0.534,0.483-0.965,1.017-0.965h23.982c0.553,0,1,0.448,1,1v17c0,0.553-0.447,1-1,1h-24
            c-0.552,0-1-0.447-1-1V21.241z"
      />
      <path
        fill="#7CAD38"
        d="M34,38c0,0.553-0.447,1-1,1H9c-0.552,0-1-0.447-1-1V21c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V38z"
      />
      <path
        fill="#7AAB37"
        d="M35,39c0,0.553-0.447,1-1,1H10c-0.552,0-1-0.447-1-1V22c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V39z"
      />
      <path fill="#95C94C" d="M10,25h22v12H10V25z" />
      <path
        fill="#FFFFFF"
        d="M34,38c0,0.553-0.447,1-1,1H9c-0.552,0-1-0.447-1-1V21c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V38z
            M32,26H10v11h22V26z"
      />
      <path fill="#FFFFFF" d="M10,29h22v1H10V29z M10,33h22v1H10V33z" />
      <path
        fill="#FFFFFF"
        d="M21,24h1v13h-1V24z M26,24h1v13h-1V24z M16.65,10.72l-1.1-1.68l-1.11,1.68h-1.22l1.71-2.49l-1.64-2.41h1.26
            l1.03,1.59l1.03-1.59h1.23L16.2,8.21l1.71,2.51H16.65z M19.41,10.72v-4.9h1.08v3.92h2.45v0.98H19.41z M26.189,10.791
            c-0.742,0-1.492-0.26-2.08-0.785l0.637-0.764c0.441,0.365,0.904,0.596,1.465,0.596c0.441,0,0.707-0.175,0.707-0.462V9.361
            c0-0.272-0.168-0.412-0.988-0.623c-0.986-0.252-1.625-0.525-1.625-1.498V7.226c0-0.89,0.715-1.478,1.717-1.478
            c0.715,0,1.324,0.225,1.82,0.623l-0.561,0.813c-0.434-0.301-0.861-0.482-1.273-0.482c-0.414,0-0.631,0.188-0.631,0.427v0.014
            c0,0.322,0.211,0.427,1.057,0.645c0.996,0.259,1.558,0.616,1.558,1.471v0.015C27.99,10.244,27.246,10.791,26.189,10.791z"
      />
    </svg>
  );
};

export default xls;
