import React, { memo, useEffect, useState } from 'react';
import MiModal from '@base/components/mi-modal';
import CodeGeneratorSetting from './code-generator-setting';

interface CodeGeneratorProps {
  show: boolean;
  onHide: any;
  saveCb: any;
  onSettingChange: any;
  menu: string;
  defaultProps: any;
}

const CodeGeneratorModal: React.FC<CodeGeneratorProps> = (props: CodeGeneratorProps) => {
  const { show, onHide, saveCb, onSettingChange, menu, defaultProps } = props;

  //state
  const [showErr, setShowErr] = useState(false);
  const [errorData, setErrorData] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  //const [isInitLoading, setIsInitLoading] = useState(false);
  const [autoGenerate, setAutoGenerate] = useState(defaultProps?.autoGenerate);
  const [nextNumber, setNextNumber] = useState(defaultProps?.nextNumber || '');
  const [prefixString, setPrefixString] = useState(defaultProps?.prefixString || '');

  //check is number input
  const isNumeric = (value: string) => {
    return /^-?\d+$/.test(value);
  };

  //save success
  const handleSuccess = () => {
    // noty({
    //   title: 'Success',
    //   content: 'Code generator setting changed successfully.',
    //   type: 'success',
    // })();
    onHide();
    saveCb && saveCb();
  };

  //NEW: save setting
  const handleSave = async () => {
    //validate
    if (autoGenerate && !isNumeric(nextNumber)) {
      setErrorData('Please enter a valid next number');
      return setShowErr(true);
    }
    if (isLoading) return;
    if (showErr) setShowErr(false);

    //save setting
    // setIsLoading(true);
    // const newParams = {
    //   id: defaultProps?.id,
    //   value: JSON.stringify({
    //     autoGenerate,
    //     nextNumber,
    //     prefixString,
    //   }),
    // };
    // const res = await GraphQLv2(GO_SET_MENU_SETTING, { input: newParams });
    // if (res && res.id) {
    //   handleSuccess();
    //   onSettingChange &&
    //     onSettingChange({
    //       autoGenerate,
    //       prefixString,
    //       nextNumber,
    //     });
    // } else {
    //   noty({
    //     title: 'Error',
    //     content: 'There is an error during saving!',
    //     type: 'danger',
    //   })();
    // }
    // setIsLoading(false);
  };

  return (
    <MiModal title={'Code Generator Setting'} size="md" isOpen={show} onClose={onHide}>
      <MiModal.Body>
        <div className={'ht-100p wd-100p d-flex flex-column'}>
          <CodeGeneratorSetting
            //isInitLoading={isInitLoading}
            autoGenerate={autoGenerate}
            setAutoGenerate={setAutoGenerate}
            nextNumber={nextNumber}
            setNextNumber={setNextNumber}
            prefixString={prefixString}
            setPrefixString={setPrefixString}
          />
          {showErr && <p className="tx-danger pd-t-10">* {errorData}</p>}
        </div>
      </MiModal.Body>
      <MiModal.Footer onClose={onHide} onSave={handleSave} saveLoading={isLoading} />
    </MiModal>
  );
};

export default CodeGeneratorModal;
