import Avatar from '@base/components/avatar';
import { convertDateTimeServerToClient } from '@base/utils/helpers';

export const defaultColumnsRender = {
  createdAt(col: string, data: any) {
    let createdAt = data[col] ? data[col] : '';
    return convertDateTimeServerToClient({
      date: createdAt,
      humanize: false,
      formatOutput: 'YYYY-MM-DD HH:mm:ss',
    });
  },
  updatedAt(col: string, data: any) {
    let updatedAt = data[col] ? data[col] : '';
    return convertDateTimeServerToClient({ date: updatedAt, humanize: true });
  },
  createdBy(col: string, data: any) {
    let createdBy = data[col] ? data[col] : null;
    //return createdBy?.name;
    return (
      <div className="d-flex align-items-center">
        <Avatar url={createdBy?.photo || ''} alt={createdBy?.name} height={26} width={26} />
        <span className="mg-l-5">{createdBy?.name}</span>
      </div>
    );
  },
  updatedBy(col: string, data: any) {
    let updatedBy = data[col] ? data[col] : null;
    //return updatedBy?.name;
    return (
      <div className="d-flex align-items-center">
        <Avatar url={updatedBy?.photo || ''} alt={updatedBy?.name} height={26} width={26} />
        <span className="mg-l-5">{updatedBy?.name}</span>
      </div>
    );
  },
  _defaultRender(col: string, data: any, extraParams: any = undefined) {
    // // console.log('cellRenderFnDefault');
    let dataType = typeof data[col];
    let renderData =
      dataType != 'undefined'
        ? dataType == 'string' || dataType == 'number'
          ? data[col]
          : JSON.stringify(data[col])
        : '';
    return <>{renderData}</>;
  },
};
