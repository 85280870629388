import SidebarMenu from '@base/components/sidebar-menu';
import { ISideMenuItem } from '@base/types/interfaces/app';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ChevronLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';

type IBasicProps = {
  children: any;
  loading: boolean;
  isFetching?: boolean;
  match: any;
  activeMenu: string;
  menus: any[];
  pageTitle?: string;
  showSidebar?: boolean;
};

const DesktopLayout1Basic = (props: IBasicProps) => {
  const {
    menus,
    match,
    activeMenu,
    loading,
    isFetching = false,
    pageTitle,
    showSidebar = false,
    ...remainProps
  } = props;

  //// console.log('menus', menus);
  //state
  const [hideSidebar, setHideSideBar] = useState(showSidebar);
  const [title, setTitle] = useState(pageTitle);
  const { t } = useTranslation();

  // const headerTitle = `${pageTitle} > ${activeMenu}`;
  useEffect(() => {
    if (match && match.params?.menu != '' && menus.length > 0) {
      let arrTitles: string[] = [];
      menus.some((menu: ISideMenuItem) => {
        if (menu.keyName == match.params.menu) {
          arrTitles.push(t(menu.languageKey));
          if (match.params.id != '' && menu.layouts && menu.layouts.length) {
            menu.layouts.some((layout: ISideMenuItem) => {
              if (match.params.id === layout.id) {
                arrTitles.push(t(layout.languageKey));
              }
            });
          }
          return true;
        }
      });
      let nTitle = arrTitles.length ? pageTitle + ' > ' + arrTitles.join(' > ') : pageTitle;
      setTitle(nTitle);
    }
  }, [match]);

  return (
    <div className={classNames('page-container-wrap', { 'hide-sidebar': hideSidebar })}>
      {/** Page left menu */}
      <SidebarMenu menus={menus} activeMenu={activeMenu} loading={loading} match={match} />
      {/** Page content */}
      <div className="page-container-content">
        <div className="page-container-content-header">
          <div className="d-flex flex-1 align-items-center">
            <button
              type="button"
              className="btn btn-toggle-sidebar tx-gray-400"
              style={{ marginRight: 0 }}
              onClick={() => setHideSideBar(!hideSidebar)}
            >
              <ChevronLeft />
            </button>
            <h1 className="mg-b-0 tx-16 tx-uppercase tx-normal mg-l-10 mg-r-10">
              {/* <HanSpanLang keyLang="crm_new_generalsetting_title" /> */}
              {title}
            </h1>
          </div>
          {isFetching && (
            <div className="d-flex flex-1">
              <span className="tx-italic">Saving...</span>
            </div>
          )}
        </div>
        <div
          className="ht-100v"
          // style={{ height: 'calc(100% - 55px)' }}
          // style={{ height: '700px' }}
        >
          <Routes>
            {menus.length
              ? menus.map((menu: ISideMenuItem) => {
                  const MenuComponent = menu.component;
                  const ViewComponent = menu.componentView;
                  const menuPath = menu.path ? menu.path : menu.keyName;

                  return (
                    <React.Fragment key={menu.keyName}>
                      {menu.default ? (
                        <Route index element={<Navigate to={`${menu.keyName}`} />} />
                      ) : (
                        ''
                      )}
                      {
                        <>
                          <Route path={menuPath} element={<MenuComponent {...remainProps} />} />
                          {ViewComponent && (
                            <Route
                              path={`${menu.keyName}/:id`}
                              element={<ViewComponent {...remainProps} />}
                            />
                          )}
                          {menu.layouts?.length
                            ? menu.layouts.map((layout: ISideMenuItem) => {
                                const LayoutComponent = layout.component;
                                const LayoutViewComponent = layout.componentView;
                                return (
                                  <React.Fragment key={layout.id}>
                                    <Route
                                      path={`${menu.keyName}/${layout.id}`}
                                      element={<LayoutComponent {...remainProps} />}
                                    />
                                    {LayoutViewComponent && (
                                      <Route
                                        path={`${menu.keyName}/${layout.id}/:id`}
                                        element={<LayoutViewComponent {...remainProps} />}
                                      />
                                    )}
                                  </React.Fragment>
                                );
                              })
                            : ''}
                        </>
                      }
                    </React.Fragment>
                  );
                })
              : ''}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default DesktopLayout1Basic;
