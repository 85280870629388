import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface IListTableProp {
  listData: any[];
  isFetching: boolean;
  fetching: React.ReactNode;
  nodata?: React.ReactNode;
}

const ListTable: React.FC<IListTableProp> = ({
  listData,
  isFetching,
  fetching,
  nodata,
}: IListTableProp) => {
  return (
    <div className="table-responsive">
      {isFetching && fetching}
      {!isFetching && (
        <table className="table dataTable">
          <thead>
            <tr>
              <th scope="col" className="tb-sel-cell">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="accountCheckAll" />
                  <label className="custom-control-label" htmlFor="accountCheckAll" />
                </div>
              </th>
              <th scope="col" className="sorting_asc">
                Name
              </th>
              <th scope="col" className="sorting">
                Industry
              </th>
              <th scope="col" className="sorting">
                Customer Type
              </th>
              <th scope="col" className="sorting">
                Email
              </th>
              <th scope="col" className="sorting">
                Telephone
              </th>
            </tr>
          </thead>
          <tbody>
            {listData.length > 0 &&
              listData.map((data) => {
                return (
                  <tr key={data.id}>
                    <td className="tb-sel-cell">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="accountCheck1"
                        />
                        <label className="custom-control-label" htmlFor="accountCheck1" />
                      </div>
                    </td>
                    <td className="sorting-cell">
                      <Link to={['/customer/view/', data.id].join('')}>
                        <span className="d-inline-block avatar avatar-sm mg-r-5">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;usqp=CAU"
                            className="rounded-circle"
                            alt=""
                          />
                        </span>
                        {data.name}
                      </Link>
                    </td>
                    <td>&nbsp;</td>
                    <td>Customer</td>
                    <td>
                      <a href="mailto:adventure@aw.com">adventureworks@domain.com</a>
                    </td>
                    <td>
                      <a href="tel:+1 518-445-5696">+1 518-445-5696</a>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      {!isFetching && !listData?.length && nodata}
    </div>
  );
};

export default ListTable;
