import Checkbox from '@base/components/form/checkbox';
import classNames from 'classnames';
import React from 'react';

interface ICheckboxEditProps {
  label: string;
  value: boolean;
  onChange: (params: any) => void;
  errors: any;
}

const CheckboxEdit: React.FC<ICheckboxEditProps> = (props: ICheckboxEditProps) => {
  const { value, onChange, errors, label } = props;

  // console.log('checbox edit', value);
  return (
    <div className="crm-common-radio-button">
      <div
        className={classNames({
          'is-required': errors?.isRequired,
        })}
      >
        <Checkbox
          // value={value}
          onChange={(data: any) => onChange(data)}
          label={label}
          checked={value}
        />
      </div>
      {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
    </div>
  );
};

export default CheckboxEdit;
