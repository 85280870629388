/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

/**
 *
 * @param {*} props
 */
const Input: React.FC<any> = (props: any) => {
  const {
    value = '',
    onChange,
    disabled,
    type, //'text', 'number', 'money', 'currency', 'decimal'
    currencySymbol = '$',
    currencyFormat = '###,##0.00',
    format = '###,##0.00',
    digits = 2,
    currencyPrecision = 2,
    min,
    max, //maximum value for number
    isLoading,
    rightIcon,
    onRightIconClick,
    className = '',
    placeholder,
    onBlur,
    onEnter,
    ...letProps
  } = props;

  //state
  const [text, setText] = useState(value);
  const [maxValue, setMaxValue] = useState<number>(max);
  const [otherProps, setOtherProps] = useState<any>({});

  useEffect(() => {
    if (type && type === 'number') {
      setMaxValue(max);
      setOtherProps({ ...letProps, min, max });
    }
  }, [type, max]);

  //init input data
  useEffect(() => {
    handleChange(null, value);
  }, [value]);

  //change
  const handleChange = (e: any, inputValue: string | number | null = null) => {
    if (type === 'money') {
      const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]{0,2})?|[.][0-9]+)$'); //number with '.'
      let value = e
        ? e.target.value.replace(/\,/g, '')
        : typeof inputValue == 'string' || typeof inputValue == 'number'
          ? inputValue.toString().replace(/\,/g, '')
          : ''; //for money

      //just accept Number
      if (value === '' || floatRegExp.test(value)) {
        setText(value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')); //with ','
        if (e !== null) {
          onChange && onChange(value);
        }
      }
    } else if (type === 'currency') {
      let floatRegExp: RegExp;
      let value = '';

      switch (currencyFormat) {
        case '###.##0,00':
          floatRegExp = new RegExp(`^[+-]?([0-9]+([,][0-9]{0,${currencyPrecision}})?|[,][0-9]+)$`); //number with ','
          value = e
            ? e.target.value.replace(/\./g, '')
            : typeof inputValue == 'string' || typeof inputValue == 'number'
              ? inputValue.toString().replace(/\./g, '')
              : '';
          break;
        case '### ##0,00':
          floatRegExp = new RegExp(`^[+-]?([0-9]+([,][0-9]{0,${currencyPrecision}})?|[,][0-9]+)$`); //number with ','
          value = e
            ? e.target.value.replace(/\s/g, '')
            : typeof inputValue == 'string' || typeof inputValue == 'number'
              ? inputValue.toString().replace(/\s/g, '')
              : '';
          break;
        default:
          //###,##0.00
          floatRegExp = new RegExp(`^[+-]?([0-9]+([.][0-9]{0,${currencyPrecision}})?|[.][0-9]+)$`); //number with '.'
          value = e
            ? e.target.value.replace(/\,/g, '')
            : typeof inputValue == 'string' || typeof inputValue == 'number'
              ? inputValue.toString().replace(/\,/g, '')
              : '';
          break;
      }
      //just accept reg
      if (value === '' || floatRegExp.test(value)) {
        let newText = '';

        switch (currencyFormat) {
          case '###.##0,00':
            newText = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            break;
          case '### ##0,00':
            newText = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            break;
          default:
            //###,##0.00
            newText = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            break;
        }
        setText(newText);
        if (e !== null) {
          onChange && onChange(value);
        }
      }
    } else if (type === 'decimal') {
      let floatRegExp: RegExp;
      let value = '';

      switch (format) {
        case '###.##0,00':
          floatRegExp = new RegExp(`^[+-]?([0-9]+([,][0-9]{0,${digits}})?|[,][0-9]+)$`); //number with ','
          value = e
            ? e.target.value.replace(/\./g, '')
            : typeof inputValue == 'string' || typeof inputValue == 'number'
              ? inputValue.toString().replace(/\./g, '')
              : '';
          break;
        case '### ##0,00':
          floatRegExp = new RegExp(`^[+-]?([0-9]+([,][0-9]{0,${digits}})?|[,][0-9]+)$`); //number with ','
          value = e
            ? e.target.value.replace(/\s/g, '')
            : typeof inputValue == 'string' || typeof inputValue == 'number'
              ? inputValue.toString().replace(/\s/g, '')
              : '';
          break;
        default:
          //###,##0.00
          floatRegExp = new RegExp(`^[+-]?([0-9]+([.][0-9]{0,${digits}})?|[.][0-9]+)$`); //number with '.'
          value = e
            ? e.target.value.replace(/\,/g, '')
            : typeof inputValue == 'string' || typeof inputValue == 'number'
              ? inputValue.toString().replace(/\,/g, '')
              : '';
          break;
      }
      //just accept reg
      if (value === '' || floatRegExp.test(value)) {
        //check max value
        if (parseFloat(value) > max) {
          value = max;
        }
        let newText = '';
        switch (format) {
          case '###.##0,00':
            newText = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            break;
          case '### ##0,00':
            newText = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            break;
          default:
            //###,##0.00
            newText = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            break;
        }
        setText(newText); //for display: string with commas
        if (e !== null) {
          onChange && onChange(value); //for callback: number
        }
      }
    } else {
      //normal text
      let newText = e ? e.target.value : inputValue;
      if (type === 'number') {
        if (typeof min === 'number') {
          if (parseInt(newText || 0) < min) {
            newText = min;
          }
        }
        if (parseInt(newText || 0) > maxValue) {
          newText = maxValue;
        }
      }
      setText(newText);
      if (e !== null) {
        onChange && onChange(newText);
      }
    }
  };

  const handleBlur = (e: any) => {
    //normal text
    const value = e ? e.target.value : text;
    if (e !== null) {
      onBlur && onBlur(value);
    }
  };
  const handleKeyUp = (e: any) => {
    //normal text
    if (e !== null && e.keyCode === 13) {
      const value = e ? e.target.value : text;
      onEnter && onEnter(value);
    }
  };

  //main render
  return (
    <>
      {type === 'currency' ? (
        <div className="pos-relative">
          <div className={classNames('input-group', className)}>
            <div className="input-group-prepend">
              <span className="input-group-text">{currencySymbol}</span>
            </div>
            <input
              // {...props}
              type="text"
              className="form-control ht-38 tx-right"
              onFocus={(e) => e.target.select()}
              //defaultValue={text}
              value={text} //has ','
              onChange={handleChange}
              disabled={disabled}
              placeholder={placeholder}
            />
          </div>
        </div>
      ) : (
        <>
          {rightIcon ? (
            <div className={classNames('input-group', className)}>
              <input
                {...otherProps}
                type={type === 'money' || type === 'decimal' ? 'text' : type}
                className={classNames('form-control ht-38', {
                  'tx-right': !rightIcon && type === 'money',
                })}
                //defaultValue={text}
                value={text} //has ',' for money
                onFocus={(e) => e.target.select()}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyUp={handleKeyUp}
                disabled={disabled}
                placeholder={placeholder}
              />
              <div className="input-group-append" onClick={onRightIconClick}>
                <span className="input-group-text lh-1">{rightIcon}</span>
              </div>
            </div>
          ) : (
            <input
              {...otherProps}
              type={type === 'money' || type === 'decimal' ? 'text' : type}
              className={classNames(
                'form-control ht-38',
                { 'tx-right': !rightIcon && type === 'money' },
                className,
              )}
              //defaultValue={text}
              value={text} //has ',' for money
              onFocus={(e) => e.target.select()}
              onChange={handleChange}
              disabled={disabled}
              placeholder={placeholder}
              onBlur={handleBlur}
              onKeyUp={handleKeyUp}
            />
          )}
          {isLoading && (
            <span className="pos-absolute t-5 r-40 mg-t-2">
              <span
                className="spinner-border spinner-border-sm tx-gray-300 mg-r-10"
                role="status"
                aria-hidden="true"
              />
            </span>
          )}
        </>
      )}
    </>
  );
};

export default Input;
