import * as menuKeys from './menus';
import { BasicConfig, BasicDataTypeConfig } from '@base/config/write-field';
import AccountConfig from '@customer/customer/config/write-field/account';
import ContactConfig from '@customer/customer/config/write-field/contact';
import ActivityConfig from '@activity/config/write-field';
import { SequenceWriteField } from '@activity/config/write-field/sequence';
import ProductConfig from '@product/product/config/write-field';
import TicketConfig from '@desk/ticket/config/write-field';
import KnowledgeBaseConfig from '@desk/knowledge-base/config/write-field';
import LandingPageConfig from '@settings/online-digital-content/landing-page/config/write-field';
import MarketingCtaConfig from '@settings/online-digital-content/cta/config/write-field';
import SurveyConfig from '@settings/online-digital-content/survey/config/write-field';
import SatisfactionSurveyConfig from '@settings/online-digital-content/satisfaction-survey/config/write-field';
import TicketFormConfig from '@settings/online-digital-content/ticket-form/config/write-field';
import AnalyticReportConfig from '@dashboard/report/configs/write-field';

export const ModuleConfig: any = {
  [menuKeys.MENU_CUSTOMER_ACCOUNT]: AccountConfig,
  [menuKeys.MENU_CUSTOMER_CONTACT]: ContactConfig,
  [menuKeys.MENU_ACTIVITY]: ActivityConfig,
  [menuKeys.MENU_ACTIVITY_TASK]: ActivityConfig,
  [menuKeys.MENU_ACTIVITY_TASK_SEQUENCE]: SequenceWriteField,
  [menuKeys.MENU_ACTIVITY_CALL]: ActivityConfig,
  [menuKeys.MENU_ACTIVITY_EMAIL]: ActivityConfig,
  [menuKeys.MENU_ACTIVITY_SMS]: ActivityConfig,
  [menuKeys.MENU_PRODUCT_PRODUCT]: ProductConfig,
  [menuKeys.MENU_DESK_TICKET]: TicketConfig,
  [menuKeys.MENU_DESK_KNOWLEDGE]: KnowledgeBaseConfig,
  [menuKeys.MENU_SETTING_LANDINGPAGE]: LandingPageConfig,
  [menuKeys.MENU_SETTING_CTA]: MarketingCtaConfig,
  [menuKeys.MENU_SETTING_SURVEY]: SurveyConfig,
  [menuKeys.MENU_SETTING_SATISFACTION_SURVEY]: SatisfactionSurveyConfig,
  [menuKeys.MENU_SETTING_TICKET_FORM]: TicketFormConfig,
  [menuKeys.MENU_ANALYTIC_REPORT]: AnalyticReportConfig,
};

export const getConfigForMenu = (menu = '') => {
  return {
    ...BasicConfig,
    ...BasicDataTypeConfig,
    //...CommonConfig,
    ...(ModuleConfig?.[menu] || {}),
  };
};

const getAllConfig = () => {
  let all: any = {
    ...BasicConfig,
    //...CommonConfig,
  };
  const allConfig = Object.keys(ModuleConfig).reduce((oldValue, menu) => {
    all = {
      ...all,
      ...ModuleConfig[menu],
    };
    return {
      ...oldValue,
      [menu]: getConfigForMenu(menu),
    };
  }, {});

  return {
    all: all,
    ...allConfig,
  };
};

export const pagelayoutWriteConfig = getAllConfig();

export const TOOLTIP_TYPE_ICON = '1';
export const TOOLTIP_TYPE_TEXT = '2';

export const CUSTOM_LAYOUT_TYPES = [
  {
    value: 'horizontal',
    type: 'horizontal',
    label: 'Horizontal',
    icon: 'MoreHorizontal',
  },
  {
    value: 'vertical',
    type: 'vertical',
    label: 'Vertical',
    icon: 'MoreVertical',
  },
];
