import { DurationValue } from '@base/types/interfaces/common';
import { DATE_SEPARATORS } from '@settings/general-setting/config/constant';
import dayjs, { QUnitType } from 'dayjs';
import { LabelValue } from '@base/types/types/app';

export enum DateUnit {
  'UNIT_SECOND' = 1,
  'UNIT_MINUTE' = 60,
  'UNIT_HOUR' = 60 * 60,
  'UNIT_DAY' = 60 * 60 * 24,
  'UNIT_WEEK' = 60 * 60 * 24 * 7,
  'UNIT_MONTH' = 60 * 60 * 24 * 7 * 30,
}

export const DurationOptions: LabelValue[] = [
  {
    value: 'UNIT_SECOND',
    label: 'secs',
  },
  {
    value: 'UNIT_MINUTE',
    label: 'mins',
  },
  {
    value: 'UNIT_HOUR',
    label: 'hrs',
  },
  {
    value: 'UNIT_DAY',
    label: 'days',
  },
  {
    value: 'UNIT_WEEK',
    label: 'weeks',
  },
  {
    value: 'UNIT_MONTH',
    label: 'months',
  },
];

// const formatDateTime = 'YYYY-MM-DD HH:mm';
// const formatDate = 'YYYY-MM-DD';

export function plusDate(date: any, number = 0, unit: QUnitType) {
  let newDate = dayjs(date);
  return newDate.add(number, unit);
}

export function diffDate(date1: any, date2: any, unit: any) {
  let newDate1 = dayjs(date1);
  return newDate1.diff(date2, unit);
}

export function convertDateFormat(dateFormat: string): string {
  let data = dateFormat.replaceAll('d', 'D').replaceAll('y', 'Y');
  return data;
}
export function replaceSeparator(dateString = '', separator = ''): string {
  let data = dateString || '';
  DATE_SEPARATORS.forEach((element) => {
    data = data.replaceAll(element.value, separator);
  });
  return data;
}

export function parseDurationValueToString(
  input: DurationValue,
  showLabel: boolean = true,
): string {
  let result = '';
  let value = 1;
  let unitValue = DateUnit['UNIT_DAY'];
  let unitLabel = DurationOptions.find((item) => {
    return item.value == 'UNIT_DAY';
  });

  if (input && input.duration > 0) {
    unitLabel = DurationOptions.find((item) => {
      return item.value == input.durationUnit;
    });
    unitValue = DateUnit[input.durationUnit as keyof typeof DateUnit];
    value = unitValue ? Math.ceil(input.duration / unitValue) : 0;
  }
  result = value + '';
  if (showLabel) {
    result = value + ' ' + unitLabel?.label;
  }

  return result;
}

export function parseDurationValueToSecond(input: DurationValue): number {
  let result = 1;
  if (input && input.duration > 0) {
    let unitValue = DateUnit[input.durationUnit as keyof typeof DateUnit];
    result = unitValue * input.duration;
  }

  return result;
}

export function getDateTime(value: string) {
  if (value == '0001-01-01T00:00:00Z' || value == '1970-01-01T00:00:00Z' || value == '') {
    return null;
  } else {
    return new Date(value);
  }
}

export const createArrayDayInMonth = (maxDay: number) => {
  return Array.from({ length: maxDay }, (_, i) => ({
    value: i + 1,
    label: i + 1,
  }));
};

export const daysInMonth = (month: any) => {
  if (month) {
    const maxDay = new Date(new Date().getFullYear(), month, 0).getDate();
    return createArrayDayInMonth(maxDay);
  }
  return null;
};
