import { DatasPromise, graphQLGetsApi } from '@base/utils/axios/graphql';
import { IResponseError } from '@base/utils/axios/helper';
import Storages from '@base/utils/storages/ls';
import { authAtom } from '@sign-in/recoil/atoms/auth';
import { useQuery } from '@tanstack/react-query';
import { isArray, isString } from 'lodash';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

//import { useQuery } from 'react-query';
export function usePosts<T>(queryKey: any[], query: string, variables: any, options?: any) {
  const Ls = new Storages();
  const setAuth = useSetRecoilState(authAtom);

  let key: string;
  if (isArray(queryKey)) {
    key = queryKey[0];
  } else {
    key = queryKey;
  }

  const response = useQuery<DatasPromise<T>>(
    queryKey,
    () => graphQLGetsApi<T>(key, query, variables),
    options,
  );

  // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
  useEffect(() => {
    if (isString(response.error)) {
      const err = JSON.parse(response.error) as IResponseError;

      if (['no_authentication', 'server_error'].includes(err.message)) {
        // Ls.remove('token');
        // setAuth(null);
        // // console.log('logout');
      }
    }
  }, [response.error]);
  return response;
}

export default usePosts;
