import { Switch } from '@base/components/form';
import ListTableCellDroplist from '@base/components/list/list-table-cell-droplist';
import { PRODUCT_TYPE_OPTIONS } from '@product/main/config/constants';
import { usePublishProductView } from '@product/product/services/product-service';
import { useEffect } from 'react';
import withTextAndPreviewModal, {
  QuickViewComponentProps,
} from '@base/hooks/hocs/withTextAndPreviewModal';
import { Link } from 'react-router-dom';

export const ProductQuickView = (props: QuickViewComponentProps) => {
  const { id, setLoading } = props;

  const { data, isLoading } = usePublishProductView(id);

  useEffect(() => {
    setLoading && setLoading(isLoading);
  }, [isLoading]);

  return (
    <div className="pd-20">
      <div className="form-group row">
        <label className="col-sm-4 col-form-label">Product Name</label>
        <div className="col-sm-8 col-form-label">
          <Link to={`/product/product/${data?.id}`}>{data?.name}</Link>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label">Can be Sold</label>
        <div className="col-sm-8 col-form-label">
          <Switch className="" value={data?.canBeSold} />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label">Product Group</label>
        <div className="col-sm-8">
          <label className="col-form-label">{data?.group?.name}</label>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label">Product Id</label>
        <div className="col-sm-8">
          <label className="col-form-label">{data?.code}</label>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label">Supply Method</label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {PRODUCT_TYPE_OPTIONS?.find((v: any) => v.value === data?.['type'])?.label}
          </label>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label">Base Unit</label>
        <div className="col-sm-8">
          <label className="col-form-label">{data?.unit?.name}</label>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label">Attributes</label>
        <div className="col-sm-8">
          <label className="col-form-label">
            {data?.attributes?.map((item: any) => item?.name ?? '').join(',') ?? ''}
          </label>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label">Active</label>
        <div className="col-sm-8 col-form-label">
          <Switch className="" value={data?.active} />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label">Assigned Rep</label>
        <div className="col-sm-8 col-form-label">
          <ListTableCellDroplist
            className="no-padding cursor-default"
            showAvatar={false}
            values={data?.assignTo ?? []}
          />
        </div>
      </div>
    </div>
  );
};

export default withTextAndPreviewModal(ProductQuickView);
