// Basic and share data type
export const BASIC_DATA_TYPE_TEXT = 'text';
export const BASIC_DATA_TYPE_SINGLE_CHOICE = 'radio';
export const BASIC_DATA_TYPE_SELECT_BOX = 'selectbox';
export const BASIC_DATA_TYPE_MULTI_CHOICES = 'checkbox';
export const BASIC_DATA_TYPE_TEXTAREA = 'textarea';
export const BASIC_DATA_TYPE_DATE = 'date';
export const BASIC_DATA_TYPE_DATE_TIME = 'datetime';
export const BASIC_DATA_TYPE_NUMBER = 'number';
export const BASIC_DATA_TYPE_DECIMAL = 'decimal';
export const BASIC_DATA_TYPE_PERCENT = 'percent';
export const BASIC_DATA_TYPE_SWITCH = 'switch'; //NOT have in basic fields

export const BASIC_DATA_TYPE_ADDRESS = 'address';
export const BASIC_DATA_TYPE_EMAIL = 'email';
export const BASIC_DATA_TYPE_PHONE = 'phone';
export const BASIC_DATA_TYPE_WEBSITE = 'url';
export const BASIC_DATA_TYPE_IP = 'ip';
export const BASIC_DATA_TYPE_LOOKUP = 'lookup';
export const BASIC_DATA_TYPE_CURRENCY = 'currency';

export const BASIC_DATA_TYPE_MULTI_LOOKUP = 'multi_select_lookup';

//field attribute
export const DATA_TYPE_ATTR_NAME = 'name';
export const DATA_TYPE_ATTR_MAXLENGTH = 'maxlength';
export const DATA_TYPE_ATTR_MULTIPLE = 'multiple';
export const DATA_TYPE_ATTR_REQUIRED = 'required';
export const DATA_TYPE_ATTR_DUPLICATE = 'allow_duplicate';
export const DATA_TYPE_ATTR_LIST_DEFAULT_VIEW = 'default_view_in_list';
export const DATA_TYPE_ATTR_TOOLTIP_SHOW = 'tooltip_show';
export const DATA_TYPE_ATTR_TOOLTIP_MSG = 'tooltip_text';
export const DATA_TYPE_ATTR_TOOLTIP_TYPE = 'tooltip_type';
export const DATA_TYPE_ATTR_EMPLOYEE_SHOW = 'show_in_employee_page';
export const DATA_TYPE_ATTR_LAYOUT = 'layout';
export const DATA_TYPE_ATTR_LOOKUP_DATA_SOURCE = 'data_source_key';
export const DATA_TYPE_ATTR_NUMBER_DIGITS = 'number_digits';
export const DATA_TYPE_ATTR_NUMBER_DECIMAL = 'number_decimal';
