import React from 'react';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormIcon } from '@base/components/form/icon';
import SpanLang from '@base/components/form/span-lang';
import Tooltip from '@base/components/form/tool-tip';

interface FieldProps {
  className?: string;
  item: any;
  control: any;
  errors: any;
  isHidden?: boolean;
  invisible?: boolean;
}

const Field = (props: FieldProps) => {
  const { className, item, control, errors, isHidden, invisible } = props;

  const { t } = useTranslation();
  const {
    languageKey,
    hideTitle,
    keyName,
    // dataType,
    value: itemValue,
    validate,
    columns,
    tooltipShow,
    tooltipText,
    Component,
    componentProps,
  } = item;

  //do nothing
  if (invisible) return <></>;
  // // console.log('Field', item, Component, componentProps);
  //render
  return (
    <div
      className={classnames(`form-group ${className}`, {
        'col-12': columns == 1,
        'col-6': columns == 2,
        'col-4': columns == 3,
        'col-3': columns == 4,
        'd-none': isHidden,
      })}
    >
      {!hideTitle && (
        <label className="form-item-title d-flex">
          <SpanLang keyLang={languageKey} />
          {validate?.required && <span className="tx-danger mg-l-5">*</span>}
          {tooltipShow && (
            <Tooltip overlay={t(tooltipText)}>
              <FormIcon icon="AlertCircle" iconType="feather" size={12} />
            </Tooltip>
          )}
        </label>
      )}
      {componentProps?.replacetitle && (
        <div className="d-flex mg-b-10">
          <span className="mg-r-10 pd-x-12 pd-y-5 bg-primary tx-white">
            {componentProps?.replacetitle.step}
          </span>
          <span className="flex-grow-1 pd-t-5">{componentProps?.replacetitle.text}</span>
        </div>
      )}
      {Component ? (
        <Controller
          name={keyName}
          control={control}
          rules={{
            validate: validate,
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <Component control={control} {...componentProps} value={value} onChange={onChange} />
            );
          }}
          defaultValue={itemValue}
        />
      ) : (
        'Empty'
      )}
      {errors?.[keyName] && <div className="tx-danger tx-12">{errors?.[keyName]?.message}</div>}
    </div>
  );
};

Field.defaultProps = {
  className: '',
  item: {},
  control: null,
  errors: {},
  isHidden: false,
  invisible: false,
};

export default Field;
