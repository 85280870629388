import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ITab } from '@base/types/interfaces/app';
interface TabsBodyProps {
  tabs: ITab[];
}

const ViewTabsBody = (props: TabsBodyProps) => {
  const { tabs, ...remainProps } = props;
  return (
    <Routes>
      {tabs?.map((tab: ITab) => {
        // if ('show' in tab && !tab.show) {
        //   return;
        // }
        const TabComponent = tab.tabComponent;
        const tabComponentProps = tab?.tabComponentProps;

        return (
          <React.Fragment key={tab.path}>
            {tab.default ? <Route index element={<Navigate to={tab.path} />} /> : ''}
            {
              <Route
                path={tab.path}
                element={<TabComponent {...remainProps} {...tabComponentProps} />}
              />
            }
          </React.Fragment>
        );
      })}
    </Routes>
  );
};

export default ViewTabsBody;
