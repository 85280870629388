import React, { useState, FC } from 'react';
// import { Link } from 'react-router-dom';
// import { Button } from '@base/components/form';
// import { Edit2 } from 'react-feather';
// import classNames from 'classnames';
// import { HoverEditContainer, ViewContainer } from '@base/containers/view-field/common/style';

import Title from '@base/containers/view-field/title';
import TitleGrid from '@base/containers/view-field/title-grid';

export interface IEditComponent {
  title: string;
  id: string;
  onAfterSaved: () => void;
  onClose: () => void;
}
export interface IListGridItemHeader {
  title: string;
  titleLink: string;
  id: string;
  keyName?: string;
  viewConfig?: any;
  menuSource?: string;
  onView?: any;
  onClickTitleLink?: any;
  renderLeftComponent?: any;
  /** THIS props not use anymore */
  beforeTitleRender?: () => any;
  //badge?: any;
  //category?: string;
  isSplitMode?: boolean;
  onTitleEdited?: (title: string) => void;
  EditComponent?: any;
}

const ListGridItemHeader: FC<IListGridItemHeader> = (props: IListGridItemHeader) => {
  const {
    keyName,
    title,
    titleLink,
    onClickTitleLink = null,
    id,
    menuSource,
    viewConfig,
    onView,
    renderLeftComponent,
    //onTitleEdited, //OLD
    //beforeTitleRender, //OLD
    //EditComponent, //OLD
  } = props;

  //render
  return (
    <div
      className="d-flex card-grid-header align-items-center"
      onClick={(e) => e.stopPropagation()}
    >
      {/* {beforeTitleRender && beforeTitleRender()} */}
      <div
        className="grid-list-item-detail-header wd-100p"
        onClick={(e: any) => onView && onView(id)}
      >
        {renderLeftComponent && renderLeftComponent()}
        <TitleGrid
          keyName={keyName as string}
          value={title}
          link={titleLink}
          onClickLink={onClickTitleLink}
          userPermission={{ isEdit: false }}
          menuSourceId={id}
          menuSource={menuSource as string}
          viewConfig={viewConfig}
        />
      </div>
    </div>
  );
};

export default ListGridItemHeader;
