import React from 'react';

const mp3 = () => {
  return (
    <svg id="file-type-mp3" xmlns="http://www.w3.org/2000/svg" width="42px" height="51px" viewBox="0 0 42 51">
      <g>
        <path fill="#C694D6" d="M42,49c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h32l8,8V49z" />
        <polygon fill="#B47CC4" points="42.003,14.828 34.615,7.44 42,8 	" />
        <g>
          <path fill="#DCCFE1" d="M42,8h-6c-1.104,0-2-0.896-2-2V0L42,8z" />
        </g>
      </g>
      <g>
        <path
          fill="#C694D6"
          d="M27.688,29.264c-0.493,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V34.242l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V32.531v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L27.688,29.264z"
        />
        <path
          fill="#C593D5"
          d="M27.333,28.964c-0.493,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V33.942l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V32.231v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L27.333,28.964z"
        />
        <path
          fill="#C492D4"
          d="M26.977,28.664c-0.493,0.068-0.86,0.49-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V33.643l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V31.932v-3.996c0-0.289-0.126-0.564-0.344-0.754c-0.219-0.191-0.509-0.277-0.796-0.236
                L26.977,28.664z"
        />
        <path
          fill="#C391D3"
          d="M26.621,28.363c-0.493,0.07-0.86,0.492-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V33.342l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V31.631v-3.994c0-0.291-0.126-0.566-0.344-0.756c-0.219-0.189-0.509-0.275-0.796-0.234
                L26.621,28.363z"
        />
        <path
          fill="#C390D3"
          d="M26.266,28.063c-0.493,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V33.042L37.53,31.62v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V31.331v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L26.266,28.063z"
        />
        <path
          fill="#C28FD2"
          d="M25.909,27.764c-0.493,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V32.742l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V31.031v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L25.909,27.764z"
        />
        <path
          fill="#C18ED1"
          d="M25.554,27.464c-0.493,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V32.442l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V30.731v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L25.554,27.464z"
        />
        <path
          fill="#C08DD0"
          d="M25.198,27.164c-0.493,0.068-0.86,0.49-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.613,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V32.143l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V30.432v-3.996c0-0.289-0.126-0.565-0.344-0.754c-0.219-0.19-0.509-0.276-0.796-0.235
                L25.198,27.164z"
        />
        <path
          fill="#BF8CCF"
          d="M24.842,26.863c-0.493,0.07-0.86,0.492-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.601,1.326-3.601,2.963c0,1.635,1.612,2.963,3.601,2.963c1.988,0,3.6-1.328,3.6-2.963V31.842l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V30.131v-3.994c0-0.291-0.126-0.566-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L24.842,26.863z"
        />
        <path
          fill="#BE8BCE"
          d="M24.486,26.563c-0.493,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V31.542l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V29.831v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L24.486,26.563z"
        />
        <path
          fill="#BD8ACD"
          d="M24.131,26.264c-0.494,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.613,2.963,3.602,2.963c1.987,0,3.6-1.328,3.6-2.963V31.242l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V29.531v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L24.131,26.264z"
        />
        <path
          fill="#BC89CC"
          d="M23.774,25.964c-0.493,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.601,1.326-3.601,2.963c0,1.635,1.612,2.963,3.601,2.963c1.988,0,3.601-1.328,3.601-2.963V30.942l10.077-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V29.231v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L23.774,25.964z"
        />
        <path
          fill="#BC88CC"
          d="M23.419,25.664c-0.493,0.069-0.86,0.491-0.86,0.991v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V30.643l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V28.932v-3.996c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L23.419,25.664z"
        />
        <path
          fill="#BB87CB"
          d="M23.063,25.364c-0.494,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.99,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.987,0,3.6-1.328,3.6-2.963V30.342l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V28.631v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L23.063,25.364z"
        />
        <path
          fill="#BA86CA"
          d="M22.707,25.063c-0.493,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.601,1.326-3.601,2.963c0,1.635,1.612,2.963,3.601,2.963c1.988,0,3.601-1.328,3.601-2.963V30.042l10.077-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V28.331v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L22.707,25.063z"
        />
        <path
          fill="#B986C9"
          d="M22.352,24.764c-0.493,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V29.742l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V28.031v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L22.352,24.764z"
        />
        <path
          fill="#B885C8"
          d="M21.996,24.464c-0.494,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.99,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.987,0,3.6-1.328,3.6-2.963V29.442l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V27.731v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L21.996,24.464z"
        />
        <path
          fill="#B784C7"
          d="M21.64,24.164c-0.493,0.069-0.86,0.491-0.86,0.99v4.278v8.412c-0.472-0.188-0.994-0.301-1.552-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V29.143l10.077-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V27.432v-3.996c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235L21.64,24.164
                z"
        />
        <path
          fill="#B683C6"
          d="M21.284,23.864c-0.493,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V28.842l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V27.131v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L21.284,23.864z"
        />
        <path
          fill="#B682C6"
          d="M20.929,23.563c-0.494,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.995-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V28.542l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V26.831v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L20.929,23.563z"
        />
        <path
          fill="#B581C5"
          d="M20.572,23.264c-0.493,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.471-0.188-0.994-0.301-1.552-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V28.242l10.077-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V26.531v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L20.572,23.264z"
        />
        <path
          fill="#B480C4"
          d="M20.217,22.964c-0.493,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V27.942l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V26.231v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L20.217,22.964z"
        />
        <path
          fill="#B37FC3"
          d="M19.861,22.664C19.368,22.733,19,23.155,19,23.654v4.278v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V27.643l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V25.931v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L19.861,22.664z"
        />
        <path
          fill="#B27EC2"
          d="M19.505,22.364c-0.493,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V27.342L30.77,25.92v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V25.631v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L19.505,22.364z"
        />
        <path
          fill="#B17DC1"
          d="M19.149,22.063c-0.494,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V27.042l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V25.331v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L19.149,22.063z"
        />
        <path
          fill="#B07CC0"
          d="M18.793,21.764c-0.494,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V26.742l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V25.031v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L18.793,21.764z"
        />
        <path
          fill="#AF7BBF"
          d="M18.438,21.464c-0.494,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V26.442l10.077-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V24.731v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L18.438,21.464z"
        />
        <path
          fill="#AF7ABF"
          d="M18.082,21.164c-0.494,0.069-0.86,0.491-0.86,0.99v4.278v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V26.143l10.078-1.422v8.124
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V24.431v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L18.082,21.164z"
        />
        <path
          fill="#AE79BE"
          d="M17.726,20.864c-0.494,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V25.842l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V24.131v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L17.726,20.864z"
        />
        <path
          fill="#AD78BD"
          d="M17.371,20.563c-0.494,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V25.542l10.077-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V23.831v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L17.371,20.563z"
        />
        <path
          fill="#AC77BC"
          d="M17.015,20.264c-0.494,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
                c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V25.242l10.078-1.422v8.123
                c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
                c1.988,0,3.601-1.328,3.601-2.963V23.531v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235
                L17.015,20.264z"
        />
      </g>
      <path
        fill="#9F6FAD"
        d="M17.015,20.264c-0.494,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
            c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V25.242l10.078-1.422v8.123
            c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
            c1.988,0,3.601-1.328,3.601-2.963V23.531v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235L17.015,20.264
            z"
      />
      <path
        fill="#FFFFFF"
        d="M16.015,19.264c-0.494,0.069-0.86,0.491-0.86,0.99v4.277v8.412c-0.472-0.188-0.994-0.301-1.553-0.301
            c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963c1.988,0,3.6-1.328,3.6-2.963V24.242l10.078-1.422v8.123
            c-0.472-0.188-0.995-0.301-1.554-0.301c-1.989,0-3.602,1.326-3.602,2.963c0,1.635,1.612,2.963,3.602,2.963
            c1.988,0,3.601-1.328,3.601-2.963V22.531v-3.995c0-0.29-0.126-0.565-0.344-0.755c-0.219-0.19-0.509-0.276-0.796-0.235L16.015,19.264
            z"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M16.07,10.783V7.582l-1.38,2.095h-0.028l-1.366-2.073v3.18h-1.058V5.881h1.163l1.289,2.072l1.289-2.072
                h1.163v4.902H16.07z"
        />
        <path
          fill="#FFFFFF"
          d="M20.914,9.313h-0.82v1.471h-1.078V5.881h2.003c1.17,0,1.877,0.693,1.877,1.695V7.59
                C22.896,8.725,22.014,9.313,20.914,9.313z M21.803,7.597c0-0.483-0.336-0.743-0.875-0.743h-0.834v1.5h0.855
                c0.539,0,0.854-0.322,0.854-0.743V7.597z"
        />
        <path
          fill="#FFFFFF"
          d="M24.355,6.805V5.881h3.313v0.813L26.43,7.877c0.672,0.111,1.316,0.462,1.316,1.379
                c0,0.932-0.672,1.611-1.766,1.611c-0.882,0-1.491-0.35-1.912-0.848l0.743-0.707C25.147,9.69,25.498,9.9,25.995,9.9
                c0.406,0,0.693-0.23,0.693-0.602c0-0.406-0.343-0.631-0.96-0.631h-0.447l-0.169-0.687l1.17-1.177H24.355z"
        />
      </g>
    </svg>
  );
};

export default mp3;
