import React, { useState } from 'react';
import classnames from 'classnames';
import { ChevronDown } from 'react-feather';
import { Link } from 'react-router-dom';
import Icon from '@base/assets/icons/svg-icons';
import { Button, FormIcon } from '@base/components/form';
import classNames from 'classnames';

interface IBasicFilter {
  value: string;
  listFilter: {
    [key: string]: any;
  };
  label?: string;
  onChange?: (selected: string) => void;
  activeValue?: string;
  onActive?: (nVal: string) => void;
}

const BasicFilter: React.FC<IBasicFilter> = ({
  value,
  listFilter = {},
  onChange = null,
  label = null,
  activeValue = '',
  onActive = () => {},
}) => {
  let classes = 'dropdown mg-l-5 to-body';
  if (label) {
    classes = 'dropdown bd-x show tx-nowrap';
  }
  return (
    <div className={classes}>
      {label && (
        <Button
          isIconsEnd
          data-toggle="dropdown"
          color="link"
          className="link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
          icon="ChevronDown"
          name={label}
        />
      )}
      {!label && (
        <button
          className="btn btn-xs btn-white btn-icon"
          color="white"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          id="filter-list"
        >
          <ChevronDown />
        </button>
      )}

      <div className="dropdown-menu nav-sub" aria-labelledby="filter-list">
        {Object.keys(listFilter).map((mode, index) => {
          const modeLabel = listFilter[mode];
          const isObject = typeof modeLabel === 'object';
          const Tag: any = isObject ? Link : 'a';
          const attrs = !isObject
            ? {
                onClick: () => onChange && onChange(mode),
              }
            : {
                to: modeLabel.path,
              };

          return (
            <React.Fragment key={index}>
              {mode.match(/title-/i) ? (
                <div className="dropdown-header tx-uppercase tx-bold tx-inverse">
                  {modeLabel}
                  <button
                    type="button"
                    className="btn btn-xs btn-link link-03 btn-icon"
                    aria-label="move this to main menu"
                  >
                    {Icon('main_menu')}
                    <span className="sr-only">이 메뉴를 메인 메뉴로 이동</span>
                  </button>
                </div>
              ) : (
                <Tag
                  className={classnames('dropdown-item d-flex align-items-center cursor-pointer', {
                    disabled: isObject && modeLabel.disabled,
                  })}
                  {...attrs}
                >
                  <span style={{ flex: 1 }}>{isObject ? modeLabel.title : modeLabel}</span>
                  {value === mode && mode != activeValue && (
                    <button
                      // className="btn btn-xs btn-set-default btn-icon mg-l-5"
                      className="btn btn-xs btn-set-default btn-icon mg-l-5"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="sticky"
                      onClick={(ev: any) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        onActive(mode);
                      }}
                    >
                      <FormIcon icon="set_default" iconType="custom" size={16} />
                    </button>
                  )}
                  {mode == activeValue && (
                    <button
                      // className="btn btn-xs btn-set-default btn-icon mg-l-5"
                      className="btn btn-xs btn-set-default btn-icon mg-l-5  active"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="sticky"
                      onClick={(ev: any) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        onActive(mode);
                      }}
                    >
                      <FormIcon icon="set_default" iconType="custom" size={16} />
                    </button>
                  )}
                </Tag>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

BasicFilter.defaultProps = {
  value: 'account',
};

export default BasicFilter;
