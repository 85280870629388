import React from 'react';

const NoteAndError: React.FC<any> = ({ errors = {} }: any) => {
  return (
    <>
      <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
        <span>
          <span className="tx-danger">*</span>
          <span>{'You cannot except a required field'}</span>
        </span>
      </div>
      {Object.keys(errors).length > 0 && (
        <div className="alert alert-danger mg-x-15">
          There are errors on the form. Please fix them before continuing.
        </div>
      )}
    </>
  );
};

export default NoteAndError;
