/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react';
import UploadFile from '../file';
import { getFileIcon, humanFileSize } from '@base/utils/helpers/general.utils';
import { X } from 'react-feather';

/**
 *
 * @param {*} props
 * @returns
 */
const FileViewUpload: React.FC<any> = (props: any) => {
  const { imageUpload = false, value, onChange } = props;
  //state
  const [files, setFiles] = useState([]);

  //init data
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(files)) {
        setFiles(value);
      }
    } else {
      setFiles([]);
    }
  }, [value]);

  //state update
  const handleValueChange = (newValue: any) => {
    const newFiles: any = files;
    //check exist before push
    newValue.map((_value: any) => {
      const fIdx = newFiles.findIndex((_ele: any) => _ele.name === _value.name);
      if (fIdx === -1) {
        newFiles.push(_value);
      }
    });
    setFiles(newFiles);
    //callback
    onChange && onChange(newFiles);
  };

  //remove selected files
  const handleRemoveFile = (index: number) => {
    const newFiles = files;
    newFiles.splice(index, 1);
    setFiles(newFiles);
    //callback
    onChange && onChange(newFiles);
  };

  //render list view
  const renderFileView = (item: any, index: number) => {
    return (
      <div className="d-flex align-items-center mg-b-3 pd-x-10 pd-y-5 bg-light rounded">
        <div
          className="media align-items-center flex-grow-1"
          style={{ maxWidth: 'calc(100% - 72px' }}
        >
          <span className="file-type-icon">{getFileIcon(item.name)}</span>
          <div className="media-body mg-l-10 text-truncate">
            {item.name}
            <small className="d-block text-muted text-truncate">({humanFileSize(item.size)})</small>
          </div>
        </div>
        <div className="flex-shrink-0 mg-l-auto">
          <button
            type="button"
            className="btn btn-link btn-icon"
            onClick={() => handleRemoveFile(index)}
          >
            <X className="tx-danger" />
          </button>
        </div>
      </div>
    );
  };

  //render
  return (
    <div className="pos-relative">
      <div className="mg-t-10">
        <UploadFile
          multiple={true}
          canDrop={true}
          autoUpload={false}
          imageUpload={imageUpload}
          onChange={(files) => handleValueChange(files)}
        />
        {files.map((_file, _index) => renderFileView(_file, _index))}
      </div>
    </div>
  );
};

export default FileViewUpload;
