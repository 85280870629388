import React, { FC } from 'react';
export { default as EmptySplitViewFC } from './split-view-empty';

//import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
//import 'react-reflex/styles.css';

import { Allotment } from 'allotment';
import 'allotment/dist/style.css';

interface ISplitView {
  styles?: React.CSSProperties;
  leftPane: React.ReactNode;
  rightPane: React.ReactNode;
}

const SPLIT_DEFAULT_SIZE = 320;
const SPLIT_MIN_SIZE = 320;
const SPLIT_MAX_SIZE = 420;

const SplitView: FC<ISplitView> = (props: ISplitView) => {
  const { styles, leftPane, rightPane } = props;

  return (
    <Allotment defaultSizes={[100, 500]}>
      <Allotment.Pane minSize={SPLIT_MIN_SIZE} maxSize={SPLIT_MAX_SIZE}>
        {leftPane}
      </Allotment.Pane>
      <Allotment.Pane>{rightPane}</Allotment.Pane>
    </Allotment>

    // <ReflexContainer orientation="vertical" style={styles !== undefined ? styles : {}}>
    //   <ReflexElement
    //     className="left-pane scroll-box"
    //     minSize={SPLIT_MIN_SIZE}
    //     maxSize={SPLIT_MAX_SIZE}
    //     size={SPLIT_DEFAULT_SIZE}
    //   >
    //     {leftPane}
    //   </ReflexElement>
    //   <ReflexSplitter />
    //   <ReflexElement className="right-pane d-flex scroll-box">{rightPane}</ReflexElement>
    // </ReflexContainer>
  );
};

export default SplitView;
