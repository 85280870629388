export enum ListType {
  GRID = 'GRID',
  LIST = 'LIST',
  SPLIT = 'SPLIT',
  CALENDAR = 'CALENDAR',
  KANBAN = 'KANBAN',
}

export enum ITemplateType {
  NONE = 'NONE',
  MENU = 'template',
  EMAIL = 'email_template',
}

export enum EPriority {
  NONE = 'PRIORITY_NONE',
  URGENT = 'PRIORITY_URGENT',
  HIGH = 'PRIORITY_HIGH',
  MEDIUM = 'PRIORITY_MEDIUM',
  LOW = 'PRIORITY_LOW',
}

export enum ETemplateGroup {
  NONE = 'GROUP_NONE',
  KNOWLEDGE = 'GROUP_KNOWLEDGE',
  EMAIL = 'GROUP_EMAIL',
  SMS = 'GROUP_SMS',
  TASK = 'GROUP_TASK',
  CALL = 'GROUP_CALL',
}

export const ETemplateGroupNum: { [index: string]: number } = {
  [ETemplateGroup.KNOWLEDGE]: 1,
  [ETemplateGroup.EMAIL]: 2,
  [ETemplateGroup.SMS]: 3,
  [ETemplateGroup.TASK]: 4,
  [ETemplateGroup.CALL]: 5,
};

export enum EDateRangeType {
  DATE_RANGE_NONE = 'DATE_RANGE_NONE',
  DATE_RANGE_TODAY = 'DATE_RANGE_TODAY',
  DATE_RANGE_YESTERDAY = 'DATE_RANGE_YESTERDAY',
  DATE_RANGE_THIS_WEEK = 'DATE_RANGE_THIS_WEEK',
  DATE_RANGE_PREVIOUS_WEEK = 'DATE_RANGE_PREVIOUS_WEEK',
  DATE_RANGE_LAST_7_DAYS = 'DATE_RANGE_LAST_7_DAYS',
  DATE_RANGE_LAST_30_DAYS = 'DATE_RANGE_LAST_30_DAYS',
  DATE_RANGE_THIS_MONTH = 'DATE_RANGE_THIS_MONTH',
  DATE_RANGE_PREVIOUS_MONTH = 'DATE_RANGE_PREVIOUS_MONTH',
  DATE_RANGE_LAST_3_MONTHS = 'DATE_RANGE_LAST_3_MONTHS',
  DATE_RANGE_THIS_QUARTER = 'DATE_RANGE_THIS_QUARTER',
  DATE_RANGE_PREVIOUS_QUARTER = 'DATE_RANGE_PREVIOUS_QUARTER',
  DATE_RANGE_LAST_6_MONTHS = 'DATE_RANGE_LAST_6_MONTHS',
  DATE_RANGE_THIS_YEAR = 'DATE_RANGE_THIS_YEAR',
  DATE_RANGE_PREVIOUS_YEAR = 'DATE_RANGE_PREVIOUS_YEAR',
  DATE_RANGE_LAST_360_DAYS = 'DATE_RANGE_LAST_360_DAYS',
  DATE_RANGE_CUSTOM = 'DATE_RANGE_CUSTOM',
}