import React, { useEffect, useState } from 'react';
import CommonViewField from '../common';

import View from './view';
import Edit from './edit';

const TextViewField: React.FC = (props: any) => {
  const [value, setValue] = useState(props?.value);

  useEffect(() => {
    if (props?.value != value) {
      setValue(props?.value);
    }
  }, [props?.value]);

  return <CommonViewField {...props} componentView={View} componentEdit={Edit} value={value} />;
};

export default TextViewField;
