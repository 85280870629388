import { PRIORITIES, PRIORITIES_CLASS } from '@base/config/constant';
import { EPriority } from '@base/types/enums';
import { string } from 'prop-types';
import React from 'react';
import SpanLang from '../span-lang';

interface IPrioriyProps {
  value: string;
}

const Prioriy: React.FC<IPrioriyProps> = (props: IPrioriyProps) => {
  const { value } = props;

  const className = `badge badge-priority priority-${PRIORITIES_CLASS[value]}`;
  const priority = PRIORITIES.find((val) => {
    return val.keyName === value;
  });
  return (
    <>
      <span className={className}>
        <SpanLang keyLang={priority?.languageKey} />
      </span>
    </>
  );
};

export default Prioriy;
