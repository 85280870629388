import React, { useMemo } from 'react';
import classnames from 'classnames';
import { ChevronDown, ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button, FormIcon } from '@base/components/form';

const Li = styled.li`
  padding: 0;

  > a {
    padding: 0.25rem 1.5rem;
  }

  &.hasChild {
    position: relative;

    > ul {
      position: absolute;
      min-width: 200px;
      top: 0;
      right: -200px;
      padding: 0;
      background-color: #fff;
      display: none;
      border: 1px solid #ccc;
    }

    &:hover {
      > ul {
        display: block;
      }
    }

    > svg {
      margin-left: 10px;
    }
  }
`;

interface ISubMenuFilter {
  value: string;
  listFilter: {
    [key: string]: any;
  };
  label?: string;
  onChange?: (selected: string) => void;
}

const SubMenuFilter: React.FC<ISubMenuFilter> = ({
  value = 'account',
  listFilter,
  onChange = null,
  label = null,
}) => {
  const groupMenus = () => {
    let menus: any[] = [];

    let isInParent = false;
    Object.keys(listFilter).forEach(function (mode) {
      const modeLabel = listFilter[mode];
      // // console.log('modeLabel', mode, modeLabel);
      const isObject = typeof modeLabel === 'object';
      const Tag = isObject ? Link : 'a';
      const attrs = !isObject
        ? {
            onClick: () => onChange && onChange(mode),
          }
        : {
            to: modeLabel.path,
          };

      if (mode.match(/title-/i)) {
        isInParent = true;

        menus.push({
          modeLabel,
          isObject,
          Tag,
          attrs: {},
          children: [],
          mode,
        });
      } else if (isInParent) {
        menus[menus.length - 1].children.push({
          modeLabel,
          isObject,
          Tag,
          attrs,
          mode,
        });
      } else {
        menus.push({
          modeLabel,
          isObject,
          Tag,
          attrs,
          mode,
        });
      }
    });

    return menus;
  };

  const menus = useMemo(() => groupMenus(), [listFilter]);

  let classes = 'dropdown mg-l-5 to-body';
  if (label) {
    classes = 'dropdown bd-x show tx-nowrap';
  }
  return (
    <div className={classes}>
      {label && (
        <Button
          isIconsEnd
          data-toggle="dropdown"
          color="link"
          className="link-02 pd-x-15 pd-y-0 tx-16 tx-bold"
          icon="ChevronDown"
          name={label}
        />
      )}
      {!label && (
        <button
          className="btn btn-xs btn-white btn-icon"
          color="white"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          id="filter-list"
        >
          <ChevronDown />
        </button>
      )}

      <div className="dropdown-menu nav-sub" aria-labelledby="filter-list">
        {menus.map(({ modeLabel, isObject, Tag, attrs, mode, children }, index) => (
          <Li
            key={index}
            className={classnames(
              'dropdown-item justify-content-between d-flex align-items-center cursor-pointer',
              {
                disabled: isObject && modeLabel.disabled,
                active: value === mode,
                hasChild: children,
              },
            )}
          >
            <Tag {...attrs}>
              <span style={{ flex: 1 }}>{isObject ? modeLabel.title : modeLabel}</span>
              {/* { && <button
                                className="btn btn-xs btn-set-default btn-icon"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                id="sticky"
                            >
                                <HanIconCommon icon="set_default" iconType="custom" size={16}/>
                            </button>} */}
            </Tag>

            {children && (
              <>
                <ChevronRight width={16} />

                <ul>
                  {children.map(({ modeLabel, isObject, Tag, attrs, mode }: any, index: any) => (
                    <Li
                      key={index}
                      className={classnames(
                        'dropdown-item justify-content-between d-flex align-items-center cursor-pointer',
                        {
                          disabled: isObject && modeLabel.disabled,
                          active: value === mode,
                          hasChild: children,
                        },
                      )}
                    >
                      <Tag {...attrs}>
                        <span style={{ flex: 1 }}>{isObject ? modeLabel.title : modeLabel}</span>
                      </Tag>
                    </Li>
                  ))}
                </ul>
              </>
            )}
          </Li>
        ))}
      </div>
    </div>
  );
};

export default SubMenuFilter;
