import { DESC, ASC } from '@base/config/constant';
import { graphQLApi } from '@base/utils/axios/graphql';
import {
  IAddAttachmentInput,
  IAttachment,
  IAttachmentsResponse,
  IDeleteAttachmentInput,
} from '@base/types/interfaces/attachment';
import { FilterInput, ISourceInput } from '@base/types/interfaces/common';
import {
  ATTACHMENT_ADD_ITEM,
  ATTACHMENT_GET_LIST_BY_MENU,
  ATTACHMENT_DELETE_ITEM,
} from './graphql/attachment';
import usePost from '@base/hooks/usePost';
import { BaseMutationResponse } from '@base/types/interfaces/response';

export const createAttachmentApi = (attachment: IAttachment): Promise<BaseMutationResponse> => {
  let variables: IAddAttachmentInput = {
    attachment: attachment,
  };
  let key = 'builtin_createAttachment';
  return graphQLApi<BaseMutationResponse>(key, ATTACHMENT_ADD_ITEM, variables);
};
export const deleteAttachmentApi = (id: string): Promise<BaseMutationResponse> => {
  let params: IDeleteAttachmentInput = {
    id: id,
  };
  let key = 'builtin_deleteAttachment';
  return graphQLApi<BaseMutationResponse>(key, ATTACHMENT_DELETE_ITEM, params);
};
export const getAttachmentsByMenuAPI = (
  menuSource: string,
  menuSourceId: string,
  size = 15,
  page = 1,
) => {
  let filter: FilterInput = {
    sort: {
      field: 'createdAt',
      orderBy: DESC,
    },
    paging: {
      size: size,
      page: page,
    },
  };
  let source: ISourceInput = {
    menu: menuSource,
    id: menuSourceId,
  };
  let params = {
    filter: filter,
    source: source,
  };
  let key = 'builtin_attachments';
  return graphQLApi<IAttachmentsResponse>(key, ATTACHMENT_GET_LIST_BY_MENU, params);
};

export const useAttachmentsByMenu = (
  menuSource: string,
  menuSourceId: string,
  size = 100,
  page = 1,
) => {
  let filter: FilterInput = {
    sort: {
      field: 'createdAt',
      orderBy: ASC,
    },
    paging: {
      size: size,
      page: page,
    },
  };
  let source: ISourceInput = {
    menu: menuSource,
    id: menuSourceId,
  };
  let params = {
    filter: filter,
    source: source,
  };
  let keys = ['builtin_attachments', menuSource, menuSourceId];
  const response = usePost<IAttachmentsResponse>(keys, ATTACHMENT_GET_LIST_BY_MENU, params);
  return response;
};
