import React from 'react';

const avi = () => {
  return (
    <svg
      id="file-type-avi"
      xmlns="http://www.w3.org/2000/svg"
      width="42px"
      height="51px"
      viewBox="0 0 42 51"
    >
      <g>
        <path
          fill="#9D89E3"
          d="M42,49c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h32l8,8V49z"
        />
        <polygon fill="#8873D1" points="42.003,14.828 34.615,7.44 42,8 	" />
        <g>
          <path fill="#D2C8F0" d="M42,8h-6c-1.104,0-2-0.896-2-2V0L42,8z" />
        </g>
      </g>
      <g>
        <path
          fill="#9D89E3"
          d="M41,32.722c0,0-4.383,1.418-4.936,1.418l-0.872-1.603L19,32c-0.553,0-1,0.447-1,1v11c0,0.553,0.447,1,1,1
                    h22V32.722z"
        />
        <path
          fill="#9C88E2"
          d="M40.708,32.347c0,0-4.383,1.418-4.936,1.418L34.9,32.162l-16.192-0.537c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V32.347z"
        />
        <path
          fill="#9B87E1"
          d="M40.417,31.972c0,0-4.383,1.418-4.936,1.418l-0.872-1.603L18.417,31.25c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V31.972z"
        />
        <path
          fill="#9A86E1"
          d="M40.125,31.597c0,0-4.383,1.418-4.936,1.418l-0.872-1.603l-16.192-0.537c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V31.597z"
        />
        <path
          fill="#9985E0"
          d="M39.833,31.222c0,0-4.383,1.418-4.936,1.418l-0.872-1.603L17.833,30.5c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V31.222z"
        />
        <path
          fill="#9884DF"
          d="M39.542,30.847c0,0-4.383,1.418-4.936,1.418l-0.872-1.603l-16.193-0.537c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V30.847z"
        />
        <path
          fill="#9783DE"
          d="M39.25,30.472c0,0-4.383,1.418-4.936,1.418l-0.872-1.603L17.25,29.75c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V30.472z"
        />
        <path
          fill="#9682DD"
          d="M38.958,30.097c0,0-4.383,1.418-4.936,1.418l-0.872-1.603l-16.192-0.537c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V30.097z"
        />
        <path
          fill="#9581DC"
          d="M38.667,29.722c0,0-4.383,1.418-4.936,1.418l-0.872-1.603L16.667,29c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V29.722z"
        />
        <path
          fill="#9480DC"
          d="M38.375,29.347c0,0-4.383,1.418-4.936,1.418l-0.872-1.603l-16.192-0.537c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V29.347z"
        />
        <path
          fill="#937FDB"
          d="M38.083,28.972c0,0-4.383,1.418-4.936,1.418l-0.872-1.603L16.083,28.25c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V28.972z"
        />
        <path
          fill="#927EDA"
          d="M37.792,28.597c0,0-4.383,1.418-4.936,1.418l-0.872-1.603l-16.193-0.537c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V28.597z"
        />
        <path
          fill="#917ED9"
          d="M37.5,28.222c0,0-4.383,1.418-4.936,1.418l-0.872-1.603L15.5,27.5c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V28.222z"
        />
        <path
          fill="#917DD8"
          d="M37.208,27.847c0,0-4.383,1.418-4.936,1.418L31.4,27.662l-16.192-0.537c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V27.847z"
        />
        <path
          fill="#907CD7"
          d="M36.917,27.472c0,0-4.383,1.418-4.936,1.418l-0.872-1.603L14.917,26.75c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V27.472z"
        />
        <path
          fill="#8F7BD7"
          d="M36.625,27.097c0,0-4.383,1.418-4.936,1.418l-0.872-1.603l-16.192-0.537c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V27.097z"
        />
        <path
          fill="#8E7AD6"
          d="M36.333,26.722c0,0-4.383,1.418-4.936,1.418l-0.872-1.603L14.333,26c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V26.722z"
        />
        <path
          fill="#8D79D5"
          d="M36.042,26.347c0,0-4.383,1.418-4.936,1.418l-0.872-1.603l-16.193-0.537c-0.553,0-1,0.447-1,1v11
                    c0,0.553,0.447,1,1,1h22V26.347z"
        />
        <path
          fill="#8C78D4"
          d="M35.75,25.972c0,0-4.383,1.418-4.936,1.418l-0.872-1.603L13.75,25.25c-0.553,0-1,0.448-1,1v11
                    c0,0.553,0.447,1,1,1h22V25.972z"
        />
        <path
          fill="#8B77D3"
          d="M35.458,25.597c0,0-4.383,1.418-4.936,1.418l-0.872-1.603l-16.192-0.537c-0.553,0-1,0.448-1,1v11
                    c0,0.553,0.447,1,1,1h22V25.597z"
        />
        <path
          fill="#8A76D2"
          d="M35.167,25.222c0,0-4.383,1.418-4.936,1.418l-0.872-1.603L13.167,24.5c-0.553,0-1,0.448-1,1v11
                    c0,0.553,0.447,1,1,1h22V25.222z"
        />
        <path
          fill="#8975D2"
          d="M34.875,24.847c0,0-4.383,1.418-4.936,1.418l-0.872-1.603l-16.192-0.537c-0.553,0-1,0.448-1,1v11
                    c0,0.553,0.447,1,1,1h22V24.847z"
        />
        <path
          fill="#8874D1"
          d="M34.583,24.472c0,0-4.383,1.418-4.936,1.418l-0.872-1.603L12.583,23.75c-0.553,0-1,0.448-1,1v11
                    c0,0.553,0.447,1,1,1h22V24.472z"
        />
        <path
          fill="#8773D0"
          d="M34.292,24.097c0,0-4.383,1.418-4.936,1.418l-0.872-1.603l-16.193-0.537c-0.553,0-1,0.448-1,1v11
                    c0,0.553,0.447,1,1,1h22V24.097z"
        />
        <path
          fill="#8672CF"
          d="M34,23.722c0,0-4.383,1.418-4.936,1.418l-0.872-1.603L12,23c-0.553,0-1,0.448-1,1v11c0,0.553,0.447,1,1,1
                    h22V23.722z"
        />
      </g>
      <path
        fill="#7B68BD"
        d="M34,23.722l-5.691,3.497V24c0-0.552-0.447-1-1-1H12c-0.553,0-1,0.448-1,1v11c0,0.553,0.447,1,1,1h15.309
                c0.553,0,1-0.447,1-1v-3.219L34,35.278V23.722z"
      />
      <path
        fill="#FFFFFF"
        d="M33,22.722l-5.691,3.497V23c0-0.552-0.447-1-1-1H11c-0.553,0-1,0.448-1,1v11c0,0.553,0.447,1,1,1h15.309
                c0.553,0,1-0.447,1-1v-3.219L33,34.278V22.722z"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M17.236,10.752l-0.447-1.1h-2.074l-0.447,1.1h-1.1l2.101-4.938h0.995l2.101,4.938H17.236z M15.752,7.11
                    L15.101,8.7h1.303L15.752,7.11z"
        />
        <path
          fill="#FFFFFF"
          d="M21.633,10.787H20.68l-1.982-4.938h1.191l1.281,3.453l1.282-3.453h1.163L21.633,10.787z"
        />
        <path fill="#FFFFFF" d="M25.117,10.752V5.849h1.078v4.903H25.117z" />
      </g>
    </svg>
  );
};

export default avi;
