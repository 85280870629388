import React, { useCallback, useEffect, useState } from 'react';
import { Upload, UploadCloud, X } from 'react-feather';
import { useDropzone } from 'react-dropzone';
// import { uploadFile } from 'HanBase/services/Http';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import classNames from 'classnames';
import { humanFileSize } from '@base/utils/helpers';
import _ from 'lodash';

interface IDropFileProps {
  isOver: boolean;
  isSaving: boolean;
}
const FileItem = styled.div`
  background-color: var(--background-box-second);
  padding: 10px;
  border-radius: 10px;

  > div {
    max-width: 100%;
  }

  .file-name {
    overflow: hidden;
    max-width: 93%;
    display: inline-block;
    text-overflow: ellipsis;
  }
`;
const dropFileOverCss = (props: IDropFileProps) =>
  props?.isOver &&
  css`
    border-color: #0d47a1;
    background-color: rgb(222 238 255);
  `;

const DropFileElement = styled.div`
  color: #bdc5d4;
  padding: 12px;
  border: 1px dashed #bdc5d4;
  border-radius: 3px;
  background-color: #fafafa;
  cursor: pointer;

  &:hover {
    border-color: #888;
  }
  ${dropFileOverCss}
`;

interface IUploadProps {
  onChange?: (params: any) => void;
  Element?: any;
  canDrop?: boolean;
  autoUpload?: boolean;
  imageUpload?: boolean;
  name?: string;
  value?: any;
  showInfoUpdated?: boolean;
  className?: string;
  isSaving?: boolean;
  multiple?: boolean;
  maxSize?: number;
}

const UploadFile: React.FC<IUploadProps> = (props: IUploadProps) => {
  const {
    onChange = (params: any) => null,
    Element,
    canDrop = false,
    autoUpload = true,
    imageUpload = false,
    name = 'file',
    value,
    showInfoUpdated = true,
    className,
    isSaving = false,
    multiple = false,
    maxSize = 100000000,
  } = props;

  //state
  const [file, setFile] = useState<any>(null);

  useEffect(() => {
    if (!_.isEmpty(value)) {
      if (multiple) {
        if (Array.isArray(value)) {
          if (JSON.stringify(value) !== JSON.stringify(file)) {
            setFile(value);
          }
        } else {
          setFile([]);
        }
      } else {
        if (value.name !== file.name) {
          setFile(value);
        }
      }
    } else {
      if (multiple) {
        setFile([]);
      } else {
        setFile(null);
      }
    }
  }, [value]);

  //file change
  const loadFile = (files: any) => {
    if (!multiple) {
      setFile(files[0]);
      onChange && onChange(files[0]);
    } else {
      let newFiles: any[] = file ? [...file] : [];
      files.map((_item: any) => {
        const foundIdx = newFiles.findIndex((_ele: any) => _ele.name === _item.name);
        if (foundIdx === -1) {
          newFiles.push(_item);
        }
      });
      setFile(newFiles);
      onChange && onChange(newFiles);
    }
  };

  //remove item
  const handleRemoveItem = (index: number) => {
    if (multiple) {
      const newFiles = [...file];
      newFiles.splice(index, 1);
      setFile(newFiles);
      //callback
      onChange && onChange(newFiles);
    } else {
      setFile(null);
      //callback
      onChange && onChange(null);
    }
  };

  //select file
  const onDrop = (acceptedFiles: any) => {
    // // console.log('acceptedFiles', acceptedFiles)
    // Do something with the files
    loadFile(acceptedFiles);
  };

  //config
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: imageUpload
      ? { 'image/*': [] } //'.png', '.gif', '.jpeg', '.jpg'
      : {}, //.pdf,.docx,.doc,.xls,.xlsx,.txt,.patch,.mp4,.mp3,.wav,.rar,.mov,.zip
    maxSize: maxSize, // 100 mb
    multiple,
    onDrop,
  });

  //an item
  const renderItem = (item: any, index: number) => {
    return (
      <FileItem key={index} className="d-flex align-items-center mg-b-5">
        <div className="d-flex malign-items-center">
          <a target="_blank" className="file-name mg-r-10">
            {item.name}
          </a>
          <span className="file-size d-block text-muted">{humanFileSize(item.size)}</span>
        </div>
        <button
          type="button"
          className="mg-l-auto btn btn-link link-03"
          onClick={() => handleRemoveItem(index)}
        >
          <X className="tx-danger" />
        </button>
      </FileItem>
    );
  };

  return (
    <div className={classNames('form-group', className)}>
      {!Element ? (
        !canDrop ? (
          <button {...getRootProps()} type="button" className="btn btn-outline-primary">
            <Upload className="mg-r-5" />
            Upload file
          </button>
        ) : (
          <DropFileElement
            {...getRootProps()}
            className="d-flex align-items-center justify-content-center"
            isOver={isDragActive}
            isSaving={isSaving}
          >
            <input {...getInputProps()} />
            <UploadCloud className="mr-3" /> {isSaving ? 'Uploading...' : 'Drag & Drop files'}
          </DropFileElement>
        )
      ) : (
        <Element {...getRootProps()} isSaving={isSaving}>
          <input {...getInputProps()} />
        </Element>
      )}

      <div className="mg-t-10">
        {file &&
          showInfoUpdated &&
          (multiple
            ? file.map((_ele: any, index: number) => _ele && renderItem(_ele, index))
            : renderItem(file, 0))}
      </div>
      <div className="d-flex justify-content-end">
        {file
          ? humanFileSize(
              multiple
                ? _.sumBy(file, function (o: any) {
                    return o.size;
                  })
                : file?.size || 0,
            )
          : '0 B'}{' '}
        / {humanFileSize(maxSize)}
      </div>
    </div>
  );
};

export default UploadFile;
