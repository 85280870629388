import Canvas from '@base/components/canvas';
import { Select, SpanLang } from '@base/components/form';
import Portal from '@base/components/portal';
import SelectTemplateList from './select-template.style';
import React, { useEffect, useState } from 'react';
import { Check } from 'react-feather';
import { ITemplate } from '@base/types/interfaces/setting';
import { useMenuTemplate, useMenuTemplates } from '@base/services/setting-service';
import { parserTemplateProperties } from '@base/utils/helpers/templates-utils';
import { ETemplateGroup, ETemplateGroupNum } from '@base/types/enums';
import PreviewModal from './preview-modal';

interface ISelectTemplateProps {
  templateGroup: ETemplateGroup;
  useTemplateId?: boolean;
  useItemTable?: boolean;
  useSelectBox?: boolean;
  value?: ITemplate;
  options?: ITemplate[];
  onChange: (params: any) => void;
  canChange?: boolean;
}
const SUPPORTED_TEMPLATE_GROUPS = [
  ETemplateGroup.KNOWLEDGE,
  ETemplateGroup.EMAIL,
  ETemplateGroup.SMS,
  ETemplateGroup.TASK,
  ETemplateGroup.CALL,
];
const SelectTemplate: React.FC<ISelectTemplateProps> = (props: ISelectTemplateProps) => {
  const {
    templateGroup = ETemplateGroup.KNOWLEDGE,
    useTemplateId = false,
    useItemTable = false,
    useSelectBox = false,
    value,
    options = [],
    onChange,
    canChange = true,
  } = props;

  const [templateId, setTemplateId] = useState('');
  const { data: templatesReps, isLoading: isLoadingList } = useMenuTemplates({
    templateGroup: ETemplateGroupNum[templateGroup] ?? 0,
  });
  const [itemSelected, setSelected] = useState<any>(value || null);
  const { data: templateReps, isLoading: isLoadingView } = useMenuTemplate(
    templateId,
    templateGroup,
  );
  // const [show, setShown] = useState(false);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isGetting, setIsGetting] = useState(false);

  //init when value changes
  useEffect(() => {
    if (value) {
      if (value?.id !== itemSelected?.id) {
        setSelected(value);
      }
    } else {
      setSelected(null); //{ id: '', name: 'No Selected' }
    }
  }, [value]);

  const onClose = () => {
    setIsOpenPreview(false);
  };
  const handleSave = () => {
    onChange && onChange(itemSelected);
    onClose();
  };
  const onSelect = (template: ITemplate) => {
    if (useTemplateId) {
      setSelected(template);
      onChange && onChange(template.id);
      onClose();
      return;
    }
    onChange && onChange(template);
    setSelected(template);
    setIsGetting(true);
    setTemplateId(template.id);
  };
  useEffect(() => {
    if (templateReps && templateReps.options != '') {
      // setSelected(templateReps);
      setIsGetting(false);
      let nTplData = parserTemplateProperties(itemSelected, templateGroup);
      onChange && onChange(nTplData);
      onClose();
    }
  }, [templateReps]);

  if (SUPPORTED_TEMPLATE_GROUPS.indexOf(templateGroup) === -1) {
    return <span className="text-danger">Group is not available</span>;
  }

  return (
    <div className="template-select">
      {!useSelectBox ? (
        <span>
          Template: <span className="text-muted">&quot;{itemSelected.name}&quot;</span>
          {canChange && (
            <a className="text-primary mg-l-5" onClick={() => setIsOpenPreview(false)}>
              Change
            </a>
          )}
        </span>
      ) : (
        <div className="d-flex align-items-center" style={{ minWidth: "350px" }}>
          <Select
            isClearable
            isSearchable={false}
            outSide={true}
            isLoading={isLoadingList}
            options={templatesReps?.results}
            onChange={(opt: ITemplate) => onSelect(opt)}
            selected={itemSelected}
            value={itemSelected}
            getOptionValue={(opt: ITemplate) => {
              return opt.id;
            }}
            getOptionLabel={(opt: ITemplate) => {
              return opt.name;
            }}
          />
          {canChange && (
            <a
              className="text-primary mg-l-10 cursor-pointer"
              onClick={() => {
                setIsOpenPreview(true);
              }}
            >
              Preview
            </a>
          )}
        </div>
      )}

      {isOpenPreview && (
        <PreviewModal
          isOpen={isOpenPreview}
          onClose={onClose}
          templateGroup={templateGroup}
          previewTemplate={itemSelected}
          onInsert={(insertedItem: ITemplate) => {
            onSelect(insertedItem);
          }}
        />
      )}
    </div>
  );
};

export default SelectTemplate;
