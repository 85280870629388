import React, { useEffect, Fragment, useState } from 'react';
import _uniqueId from 'lodash/uniqueId';
import classNames from 'classnames';
import './styles.scss';
import Body from './body';
import Header from './header';
import Footer from './footer';

const defaultProps = {
  isAbsolute: false,
  isVisible: false,
  onCloseSideBar: () => {},
  content: {},
  className: '',
  backgroundColorCanvas: 'var(--white-color)',
  width: '',
  backdrop: true, // Click backDrop to close
  autoClose: true,
  customStyles: null,
  backdropStyles: null,
};

const Canvas = (props: any) => {
  const {
    isAbsolute,
    isVisible,
    onCloseSideBar,
    className,
    backgroundColorCanvas,
    width,
    backdrop,
    backdropStyles,
    children,
    customStyles,
  } = props;

  // state
  const [id] = useState(_uniqueId('right-sidebar-'));
  const [nStyles, setNStyles] = useState<any>({ width: 'auto' });

  useEffect(() => {
    let _nStyles: any = {};
    if (customStyles) {
      _nStyles = { ...nStyles, ...customStyles };
    }
    if (width) {
      _nStyles.width = `${width}%`;
    }
    if (JSON.stringify(_nStyles) != JSON.stringify(nStyles)) {
      setNStyles(_nStyles);
    }
  }, [customStyles, width]);

  // main render
  return (
    <div id={id} className={classNames('')}>
      <div
        className={classNames(
          'off-canvas off-canvas-overlay off-canvas-right content-canvas d-flex justify-content-start',
          isVisible ? 'show' : '',
          isAbsolute ? 'off-canvas-absolute' : '',
        )}
        style={nStyles}
      >
        <div
          className={classNames('canvas-content-wrapper', className ? className : 'wd-100p')}
          style={{ backgroundColor: backgroundColorCanvas }}
        >
          {children}
        </div>
      </div>
      <div
        className={`backdrop ${isVisible ? 'show' : ''}`}
        onClick={() => {
          backdrop ? onCloseSideBar() : null;
        }}
        style={backdropStyles ? backdropStyles : { zIndex: 200 }}
      />
    </div>
  );
};

Canvas.defaultProps = defaultProps;

export default Object.assign(Canvas, {
  Body,
  Header,
  Footer,
});
