import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Select, TreeDropdown } from '@base/components/form';
import { useTranslation } from 'react-i18next';
import {
  GET_SELECTION_FIELD_ITEMS_BY_SINGLE_KEY,
  GET_SELECTION_GROUPS_BY_MULTI_KEY,
} from '@base/services/graphql/setting';
import usePosts from '@base/hooks/usePosts';
import { GET_MENU_SETTINGS } from '@settings/general-setting/services/graphql/format';
import _ from 'lodash';
interface IFieldOption {
  id: string;
  keyName: string;
  keyRoot: string;
  languageKey: string;
  parentId: string;
  children: any;
}

interface TreeGroupProps {
  idFolder: number;
  keyword: string;
  node: any;
}

interface DataSourceSelectProps {
  className: string;
  single: boolean;
  isDisabled: boolean;
  sourceKey: string; //ex: 'priority'
  sourceType: string; // ex: 'field' or 'group' or 'setting'
  sourceMenu: string;
  value: any;
  onChange: (value: any) => void;
  addLabel?: any;
  onAdd?: (() => void) | null;
  onClose?(): void;
  placeholder?: string;
}

const DataSourceSelect: React.FC<any> = (props: DataSourceSelectProps) => {
  const {
    className,
    single,
    isDisabled,
    sourceKey, //ex: 'priority'
    sourceType, // ex: 'field' or 'group', 'setting'
    sourceMenu, //ex: 'price_list'
    value, //={}
    onChange,
    addLabel,
    onAdd,
    onClose,
    placeholder = 'Select...',
  } = props;
  const { t } = useTranslation();

  //state
  const [keyOptions, setKeyOptions] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>(null);

  //init rows
  useEffect(() => {
    if (single) {
      if (value) {
        if (_.isString(value)) {
          if (value !== selectedOption?.value) {
            //find in options
            const fOption = keyOptions.find((_ele: any) => _ele.value === value);
            if (fOption) {
              setSelectedOption(fOption);
            }
          }
        } else {
          if (value?.value !== selectedOption?.value) {
            setSelectedOption(value);
          }
        }
      } else {
        setSelectedOption(null);
      }
    } else {
      if (value) {
        if (Array.isArray(value) && value.length > 0) {
          //2 cases: value=string[] or object[] + selectedOption=object[]
          let isArrayString = _.isString(value[0]);
          if (isArrayString) {
            const selectedIds = selectedOption?.map((_ele: any) => _ele.value);
            if (JSON.stringify(value) !== JSON.stringify(selectedIds)) {
              const newValue: any = [];
              value.map((_item: string) => {
                //find in options
                const fOption = keyOptions.find((_ele: any) => _ele.value === _item);
                if (fOption) {
                  newValue.push(fOption);
                }
              });
              setSelectedOption(newValue);
            }
          } else {
            //array object
            if (JSON.stringify(value) !== JSON.stringify(selectedOption)) {
              setSelectedOption(value);
            }
          }
        } else {
          setSelectedOption([]);
        }
      } else {
        setSelectedOption(null);
      }
    }
  }, [value, keyOptions]);

  //config key
  const FieldKeys: any = {
    contact_type: 'keyName',
    priority: 'keyName',
    purpose_of_activities: 'keyName',
    customer_type: 'keyName',
    customer_category: 'keyName',
    ticket_status: 'keyName',
  };
  const keyData = FieldKeys[sourceKey] || 'id';

  // ============= HOOK get field options ======================
  const fieldParams = {
    keyName: sourceKey,
    // filter: {
    //   query: `keyRoot:${sourceKey}`
    // }
  };
  const { data: optionsData, isFetching: fieldLoading } = usePosts<IFieldOption[]>(
    ['setting_selectionFieldItems', sourceType, sourceKey],
    GET_SELECTION_FIELD_ITEMS_BY_SINGLE_KEY,
    fieldParams,
    {
      //keepPreviousData: true,
      enabled: sourceType === 'field' && sourceKey.length > 0,
    },
  );
  //init states list
  useEffect(() => {
    if (optionsData?.data) {
      initOptions(optionsData.data);
    }
  }, [optionsData]);
  // ============= END get field options ======================

  // ============= HOOK get tax options ======================
  const settingParams = {
    menus: [sourceMenu],
    keys: [sourceKey],
  };
  const { data: settingData, isFetching: settingLoading } = usePosts<any>(
    ['setting_menusSettings', sourceType, sourceMenu, sourceKey],
    GET_MENU_SETTINGS,
    settingParams,
    {
      keepPreviousData: true,
      enabled: sourceType === 'setting',
    },
  );

  //init states list
  useEffect(() => {
    //// console.log('settingData', settingData);
    if (settingData?.data?.length > 0) {
      try {
        let jsonValues = JSON.parse(settingData?.data[0].value);
        //// console.log('parse jsonValues', jsonValues);
        let newOptions: any[] = [];
        switch (sourceKey) {
          case 'tax':
            newOptions = jsonValues.map((_ele: any) => ({
              ..._ele,
              id: _ele.id,
              languageKey: _ele.name,
            }));
            if (value?.sourceId) {
              //newOptions = newOptions
              // .filter((_val: any) => {
              //   const foundCustomers = _val?.customers ? _val.customers?.filter((_cust: any) => _cust.id === value.sourceId) : [];
              //   if (foundCustomers?.length > 0) return true;
              // })
            }
            break;
          case 'timezone':
            newOptions = jsonValues.map((_ele: any) => ({
              ..._ele,
              id: _ele.id,
              languageKey: `[GMT ${_ele.sdutc}] ${_ele.tzone}`,
            }));
            break;
        }
        initOptions(newOptions);
      } catch (e: any) {
        // console.log('parse error', e);
      }
    }
  }, [settingData, value]);

  // ============= END get setting options ======================

  //set options
  const initOptions = (dsOptions: any) => {
    let newOptions: any = [];
    if (dsOptions && dsOptions.length > 0) {
      dsOptions.map((_item: any) => {
        const newItem = {
          ..._item,
          label: _item.languageKey ? t(_item.languageKey) : _item.keyName,
          value: _item?.[keyData],
        };
        newOptions.push(newItem);
      });
      setKeyOptions(newOptions);
    }
  };

  // HOOK get group options
  // with search query keyRoots=["a", "b"]
  // =>
  // keyRoot:("a" "b") => where keyRoot=a and keyRoot=b
  // keyRoot:{"a" "b"} => where keyRoot=a or keyRoot=b
  const keyRoots = [sourceKey.toString()].join(' ');
  const groupParams = {
    filter: {
      query: `keyRoot:{${keyRoots}}`,
    },
    format: '',
  };
  const { data: groupsData, isLoading: groupLoading } = usePosts<IFieldOption[]>(
    ['setting_selectionGroups', sourceType, sourceKey],
    GET_SELECTION_GROUPS_BY_MULTI_KEY,
    groupParams,
    {
      keepPreviousData: true,
      enabled: sourceType === 'group' && sourceKey.length > 0,
    },
  );
  //get groups
  const getGroups = async ({ idFolder = 0, keyword, node }: TreeGroupProps) => {
    if (idFolder == 0) {
      return new Promise((resolve) => {
        resolve(groupsData?.data || []);
      });
    } else {
      //get children of node
      return new Promise((resolve) => {
        resolve(node.children || []);
      });
    }
  };

  //option change
  const handleValueChange = (newValue: any) => {
    if (newValue) {
      setSelectedOption(newValue);
      //callback
      onChange && onChange(newValue);
    }
  };

  //bind data nodes
  const formatDataNode = (node: any) => {
    const hasChildren = node.children && node.children.length > 0;
    return {
      title: t(node.languageKey),
      id: node.id,
      hasChildren,
      isExpand: false,
      isFolder: true,
    };
  };

  const handleTreeValueChange = (newValue: any) => {
    onChange &&
      onChange(
        single
          ? { ...newValue?.[0], value: newValue?.[0]?.id, label: t(newValue?.[0]?.languageKey) }
          : newValue?.map((v: any) => ({ ...v, value: v?.id, label: t(v?.languageKey) })),
      );
  };

  //render
  return (
    <div className={classNames('pos-relative', className)}>
      {sourceType === 'group' ? (
        <TreeDropdown
          fetchTree={getGroups}
          formatTreeNode={formatDataNode}
          isLoading={groupLoading}
          value={single ? [selectedOption] : selectedOption || []}
          single={single}
          onChange={handleTreeValueChange}
          addLabel={addLabel}
          onAdd={onAdd}
          onClose={onClose}
          placeholder={placeholder}
        />
      ) : (
        <Select
          isClearable
          outSide={true}
          isSearchable={false}
          isMulti={!single}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isLoading={fieldLoading || settingLoading}
          value={selectedOption}
          options={keyOptions}
          onChange={handleValueChange}
        />
      )}
    </div>
  );
};

export default DataSourceSelect;
