import React, { useState, forwardRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Clock } from 'react-feather';
//import { setMinutes, setHours, setSeconds } from 'date-fns';
import { parseStrTimeToDate, getHour } from './utils';
//import { Portal } from 'react-overlays';
//import { Overlay } from 'react-bootstrap';

// import './style.scss';

//TODO: using popover, overlay in react-boostrap
// const CalendarContainer = ({ children }: any) => {
//   const el = document.getElementById('calendar-portal');

//   return <Overlay target={el}>{children}</Overlay>;
// };

interface CustomInputProps {
  value?: string;
  onClick?(): void;
  inputName: string;
}

// eslint-disable-next-line react/display-name
const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>((props, ref) => {
  const { value, onClick, inputName } = props;
  return (
    <span className="input-group">
      <input
        readOnly={true}
        type="datetime"
        className="form-control ht-38"
        onClick={onClick}
        name={inputName}
        ref={ref}
        value={value}
      />
      <span className="input-group-prepend ht-38">
        <span className="input-group-text">
          <Clock />
        </span>
      </span>
    </span>
  );
});

const TimePicker: React.FC<any> = (props: any) => {
  const {
    minTime = null,
    maxTime = null,
    step = 15,
    name,
    defaultValue,
    popperPlacement = 'top-end',
    value = '',
    onChange = null,
  } = props;

  //state
  const [startDate, setStartDate] = useState(parseStrTimeToDate(defaultValue) || new Date());

  useEffect(() => {
    if (value != '') {
      setStartDate(parseStrTimeToDate(value));
    } else {
      if (defaultValue) {
        setStartDate(parseStrTimeToDate(defaultValue));
      } else {
        setStartDate(new Date());
      }
    }
  }, [value]);

  return (
    <DatePicker
      customInput={<CustomInput inputName={name} />}
      selected={startDate}
      onChange={(date: Date) => {
        setStartDate(date);
        onChange && onChange(date);
      }}
      popperPlacement={popperPlacement}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={step}
      //popperContainer={CalendarContainer}
      minTime={
        minTime
          ? getHour(minTime?.sec || 0, minTime?.min || 0, minTime?.hour || 0)
          : getHour(0, 0, 0)
      }
      maxTime={
        maxTime
          ? getHour(maxTime?.sec || 0, maxTime?.min || 0, maxTime?.hour || 0)
          : getHour(0, 59, 23)
      }
      timeCaption="Time"
      dateFormat="hh:mm aa"
    />
  );
};

export default TimePicker;
