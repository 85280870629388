import React from 'react';
import { SpanLang } from '@base/components';
import TextView from '@base/containers/view-field/text/view';
import { OptionValue } from '@base/types/interfaces/common';

interface ICheckboxViewProps {
  value: OptionValue[];
}
const CheckboxView: React.FC<ICheckboxViewProps> = (props: ICheckboxViewProps) => {
  const { value } = props;
  return (
    <>
      {value.length > 0 ? (
        value.map((v, idx) => {
          return (
            <div key={idx}>
              {idx > 0 ? ',' : ''}
              <SpanLang keyLang={v.languageKey} />
            </div>
          );
        })
      ) : (
        <TextView value={''} />
      )}
    </>
  );
};

export default CheckboxView;
