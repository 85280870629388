import CheckboxGroup from '@base/components/form/checkbox-group';
import { OptionValue } from '@base/types/interfaces/common';
import classNames from 'classnames';
import React from 'react';

interface ICheckboxEditProps {
  value: OptionValue[];
  onChange: (params: any) => {};
  errors: any;
  isHorizontal: boolean;
  options: OptionValue[];
  fieldValue?: string;
  fieldLabel?: string;
}
const CheckboxEdit: React.FC<ICheckboxEditProps> = (props: ICheckboxEditProps) => {
  const {
    value,
    onChange,
    errors,
    isHorizontal = true,
    options,
    fieldValue = 'keyName',
    fieldLabel = 'languageKey',
  } = props;
  return (
    <div className={classNames('crm-common-radio-button')}>
      <div
        className={classNames({
          'is-required': errors?.isRequired,
        })}
      >
        <CheckboxGroup
          isVertical={!isHorizontal}
          options={options}
          value={value || []}
          onChange={(data: any) => onChange(data)}
          fieldValue={fieldValue}
          fieldLabel={fieldLabel}
        />
      </div>
      {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
    </div>
  );
};

export default CheckboxEdit;
