import React, { Fragment, useState, useRef, useEffect } from 'react';
//import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue, components } from 'react-select';
import { colors } from './colors';
import { Plus, Users } from 'react-feather';

interface ICreatableSelectProps {
  width?: string;
  ctrlStyles?: object;
  outSide?: boolean;
  options?: any;
  styles?: object;
  defaultValue?: object | any[] | undefined;
  value?: object | any[] | undefined;
  onChange?: any;
  menuPortalTarget?: any;
  required?: boolean;
  selectProps?: any;
  isLoading?: boolean;
  children?: any;
}

//custom dropdown indicator
const DropdownIndicator = (props: { selectProps?: any; getStyles?: any; innerProps?: any }) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
      <div
        style={{ padding: '0px 5px' }}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <a
          className={'tx-gray-500'}
          //href="javascript:void(0)"
          onClick={(e) => {
            props?.selectProps?.onClickIndicator && props?.selectProps?.onClickIndicator(e);
          }}
        >
          {props?.selectProps?.iconIndicator || <Users size={14} />}
        </a>
      </div>
    </div>
  );
};

//add a custom option - add
const CustomMenuOption: React.FC<any> = (propsOption: any) => {
  const { children, ...props } = propsOption;

  //state
  const [isShowAdd, setIsShowAdd] = useState(false);
  //show add canvas or modal
  const handleShowAdd = () => {
    props?.selectProps?.onAdd();
    setIsShowAdd(true);
  };

  //render
  return (
    <>
      {!isShowAdd && (
        <components.Menu {...props}>
          <div>
            {!props.isLoading &&
              props.selectProps?.onAdd &&
              props.selectProps?.addLabel.length > 0 && (
                <div className="d-flex justify-content-center align-items-center bd-b pd-y-5">
                  <button type="button" className="btn btn-link link-03" onClick={handleShowAdd}>
                    <Plus /> {props.selectProps?.addLabel}
                  </button>
                </div>
              )}
            <div>{children}</div>
          </div>
        </components.Menu>
      )}
    </>
  );
};

//custom loading spinner
const LoadingIndicator = (props: any) => {
  return (
    <div className="spinner-border spinner-border-sm mg-r-10" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
};

//add a custom option - add
const CustomEmptyOption = ({ children, ...props }: any) => {
  //render
  return (
    <>
      {!props.selectProps?.showList && (
        <components.Menu {...props}>
          <div>{children}</div>
        </components.Menu>
      )}
    </>
  );
};

//custom react-select
const CreatableSelectHook = (props: any) => {
  const {
    width = '100%',
    ctrlStyles = {},
    styles = {},
    components = {},
    menuPlacement = 'auto',
    ...opts
  } = props;

  //state
  //const [selected, setSelected] = useState<any>(opts.defaultValue);
  //const el = useRef(null);

  //handle value change
  // const handleValueChange = (newValue: OnChangeValue<any, false>, actionMeta: ActionMeta<any>) => {
  //   setSelected(newValue);
  //   opts.onChange && opts.onChange(newValue);
  // };

  //custom styles
  const newStyles = {
    container: (provided: any) => ({ ...provided, width: width }),
    ...(styles || {}),
    //trungtm
    control: (provided: any, state: any) => ({
      ...provided,
      border: '1px solid var(--input-border-color)',
      //transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      '&:hover': {
        // Overwrittes the different states of border
        color: 'var(--text-color-2)',
        backgroundColor: 'var(--input-focus-color)',
        borderColor: 'var(--input-border-color)',
        outline: '0',
        boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
      },
      ...ctrlStyles,
    }),
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      color: 'var(--text-color-tag)',
      fontSize: '0.875rem',
      padding: '1px',
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      color: 'var(--text-color-tag)',
      opacity: '0.5',
      '> svg': {
        width: '16px',
        height: '16px',
      },
      '&:hover': {
        color: 'var(--text-color-tag)',
        opacity: '1',
      },
    }),
    multiValue: (styles: any, { data }: any) => ({
      ...styles,
      backgroundColor: 'var(--background-tag)',
      padding: '2px 4px',
      borderRadius: '3px',
    }),
  };

  //set portal
  if (opts?.outSide) {
    opts.menuPortalTarget = document.body;
    newStyles.menuPortal = (base: any) => ({ ...base, zIndex: 9999 });
  }

  //custom components
  let customComponents = components;

  //indicator
  if (opts?.iconIndicator) {
    customComponents.DropdownIndicator = DropdownIndicator;
  }

  //custom option
  if (opts?.onAdd && opts?.addLabel?.length > 0) {
    customComponents.Menu = CustomMenuOption;
  }

  //custom loading
  // if (!customComponents.LoadingIndicator) {
  //   customComponents.LoadingIndicator = LoadingIndicator;
  // }

  //hide options menu
  if (opts?.showList) {
    customComponents.Menu = CustomEmptyOption;
  }

  // useEffect(() => {
  //   setSelected(opts.defaultValue);
  // }, [opts.defaultValue]);

  return (
    <Fragment>
      <CreatableSelect
        components={customComponents}
        menuPlacement={menuPlacement}
        styles={{ ...newStyles, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
        theme={(theme) => ({
          ...theme,
          colors,
        })}
        //options={options}
        //value={selected}
        //onChange={handleValueChange}
        {...opts}
      />
    </Fragment>
  );
};

export default CreatableSelectHook;
