import { SvgIcons } from '@base/assets/icons/svg-icons';
import { useAbortUploadMutation } from '@base/hooks/useFileUploadMutation';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { getFileIcon, humanFileSize } from '@base/utils/helpers';
import { Pause } from 'react-feather';
import { toast } from 'react-toastify';

interface IProgressBarProps {
  fileName: string;
  fileSize: number;
  percentCompleted: number;
  uploadId?: string;
}
const ProgressBar: React.FC<IProgressBarProps> = (props) => {
  const { fileName, fileSize, percentCompleted, uploadId } = props;

  //mutation
  const mAbortUpload: any = useAbortUploadMutation<BaseMutationResponse>({
    onSuccess: (data: any, variables: any, context: any) => {
      //toast.success('Uploaded successfully!');
    },
    onError: (error: any, variables: any, context: any) => {
      //// console.log('mutation error', error);
      toast.error('Abort Upload failed: ' + JSON.parse(error).message);
    },
  });
  //click
  const handleAbortUpload = () => {
    if (uploadId) {
      mAbortUpload.mutate(uploadId);
    }
  };

  let icon = getFileIcon(fileName);
  return (
    <div className="d-flex align-items-center mg-b-3 pd-x-10 pd-y-5 bg-light rounded">
      <div className="d-flex flex-grow-1 media align-items-center">
        <span className="file-type-icon">
          <SvgIcons type={icon} />
        </span>
        <div
          className="d-flex flex-grow-1 flex-column mg-l-10"
          //style={{ maxWidth: 'calc(100% - 72px)' }}
        >
          <div className="media-body text-truncate">{fileName}</div>
          <div className="d-flex progress bg-gray-300 ht-10 mg-y-2">
            <div
              className="progress-bar bg-df-2"
              role="progressbar"
              aria-valuenow={percentCompleted}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{ width: percentCompleted + '%' }}
            />
          </div>
          <div className="d-flex justify-content-between">
            <small className="d-block text-muted text-truncate">{humanFileSize(fileSize)}</small>
            <small className="d-block text-muted text-truncate">{percentCompleted + '%'}</small>
          </div>
        </div>
        {uploadId && (
          <button
            type="button"
            className="btn btn-link link-03 btn-icon"
            data-han-tooltip="Cancel"
            onClick={handleAbortUpload}
          >
            <Pause className="tx-danger" />
          </button>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
