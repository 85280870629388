import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

interface Props {
  value: any;
  to: string;
  target?: string;
  className?: string;
}

const Container = styled.div``;

const View: React.FC<Props> = (props: Props) => {
  const { className, value, to, target } = props;

  const [link, setLink] = useState('');

  useEffect(() => {
    setLink([to, value?.id ?? ''].join('/'));
  }, [value, to]);

  return (
    <Container className="crm-view-detail">
      <div className={classnames(className, {})}>
        <Link to={link} target={target}>
          {value?.name ?? ''}
        </Link>
      </div>
    </Container>
  );
};

View.defaultProps = {
  className: '',
  to: '',
  target: '',
};

export default View;
