import React, { useState } from 'react';
import classNames from 'classnames';
import { Image } from 'react-feather';
import { addParameterToURL } from '@base/utils/helpers/hashParams';
import styled from '@emotion/styled';
import { Avatar } from '@base/components';

const NO_PHOTO = null;

const FirstLetterSpan = styled.span`
  text-transform: capitalize;
`;

const ListGridItemPhotoContainer: React.FC<any> = ({ children, empty }) =>
  empty ? children : <span className="avatar-initial rounded">{children}</span>;

interface IListGridItemPhoto {
  src: string;
  alt?: string;
  className?: string;
  imageClass?: string;
  isSplitMode?: boolean;
}

const getFirstCharacter = (label: string): string => {
  return label?.charAt?.(0) || '';
};

const ListGridItemPhoto = ({
  src,
  alt,
  className,
  imageClass,
  isSplitMode,
}: IListGridItemPhoto) => {
  const [imageSrc, setSrc] = useState<string | null>(src || NO_PHOTO);

  return (
    <div className={className}>
      {imageSrc ? (
        <Avatar
          key={imageSrc}
          url={imageSrc}
          alt={''}
          height={isSplitMode ? 38 : 100}
          width={isSplitMode ? 38 : 100}
          isCircle={false}
          className={classNames('rounded', imageClass)}
        />
      ) : (
        <ListGridItemPhotoContainer empty={className === 'grid-list-item-pic'}>
          {alt ? <FirstLetterSpan>{getFirstCharacter(alt)}</FirstLetterSpan> : <Image />}
        </ListGridItemPhotoContainer>
      )}
    </div>
  );
};

ListGridItemPhoto.defaultProps = {
  alt: 'Grid Photo',
  //className: 'grid-list-item-pic',
};

export default ListGridItemPhoto;
