import React from 'react';
import { IMAGE_SIZE_L } from '@base/config/image';
//import classNames from 'classnames';
import Avatar from '@base/components/avatar';

interface IImageProp {
  size?: string;
  className?: string;
  url?: string;
  imgClass?: string;
}
/**
 *
 * @param {*} props
 */
const Image: React.FC<IImageProp> = (props: IImageProp) => {
  let { size = IMAGE_SIZE_L, className, url, imgClass } = props;
  return (
    <Avatar
      isCircle={false}
      size={size}
      height="100%"
      width="100%"
      className={className}
      isUrl={true}
      url={url}
    //imgClass={imgClass}
    />
  );
};

export default Image;
