import React, { useState } from 'react';

import CommonViewField from '../common';

import View from './view';
import Edit from './edit';

interface IProps {
  value: any;
  onSave: (params: any) => void;
  keyName: string;
  menuSource: string;
  menuSourceId: string;
}

const Date = (props: IProps) => {
  const { value, keyName, menuSource, menuSourceId, onSave, ...remainProps } = props;
  // console.log('date', props);
  return (
    <CommonViewField
      keyName={keyName}
      menuSourceId={menuSourceId}
      menuSource={menuSource}
      onSave={onSave}
      componentView={View}
      componentEdit={Edit}
      value={value}
      {...remainProps}
    />
  );
};

export default Date;
