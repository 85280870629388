import React from 'react';
import classnames from 'classnames';
import LanguageSelect from '@base/containers/language-select';

const Edit: React.FC = (props: any) => {
  const { value, errors, componentProps, onChange } = props;

  return (
    <div>
      <div className={classnames({ 'is-required': errors.isRequired })}>
        <LanguageSelect {...componentProps} value={value} onChange={onChange} />
      </div>
      {errors.isRequired && <div className="tx-danger tx-12">{errors.isRequired}</div>}
    </div>
  );
};

export default Edit;
