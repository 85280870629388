import React, { useState } from 'react';
import UploaderImage from '@base/components/form/image-uploader';
import styled from '@emotion/styled';

const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 3px;
`;

const Edit: React.FC<any> = (props: any) => {
  const { value, onChange } = props;

  return (
    <Container>
      <UploaderImage
        className='wd-250'
        isSingle={true}
        type={'img'}
        preview={true}
        value={value}
        onChange={onChange}
      />
    </Container>
  );
};

export default Edit;
