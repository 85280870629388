import { LabelValue } from '@base/types/types/app';
import { OptionValue } from '@base/types/interfaces/common';
import * as keyNames from './key-names';

export const COLORS = [
  {
    value: 'white',
  },
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
  {
    value: 'success',
  },
  {
    value: 'warning',
  },
  {
    value: 'danger',
  },
  {
    value: 'info',
  },
  {
    value: 'indigo',
  },
  {
    value: 'purple',
  },
  {
    value: 'pink',
  },
  {
    value: 'teal',
  },
  {
    value: 'orange',
  },
  {
    value: 'gradient-1',
  },
  {
    value: 'gradient-2',
  },
  {
    value: 'gradient-3',
  },
];

export const ASC = 1;
export const DESC = 2;
export const LIST_TABLE_PAGE_SIZE = 15;
export const LIST_GRID_PAGE_SIZE = 20;
export const NOTE_PAGE_SIZE = 15;
export const TIMELINE_PAGE_SIZE = 15;
export const ACTIVITY_VIEW_PAGE_SIZE = 15;
export const SET_TIMEOUT = 500;
export const LIST_STALE_TIME = 60 * 1000;

export const COMMON_VIEW_FIELD_READ_ONLY = [
  keyNames.KEY_NAME_CREATED_AT,
  keyNames.KEY_NAME_CREATED_BY,
  keyNames.KEY_NAME_DELETED_AT,
  keyNames.KEY_NAME_DELETED_BY,
  keyNames.KEY_NAME_UPDATED_AT,
  keyNames.KEY_NAME_UPDATED_BY,
];
export const DEVICE_DESKTOP = 'desktop';
export const DEVICE_TABLET = 'tablet';
export const DEVICE_PHONE = 'mobile';

export const PRIORITY_NONE = 'PRIORITY_NONE';
export const PRIORITY_VERY_LOW = 'PRIORITY_VERY_LOW';
export const PRIORITY_LOW = 'PRIORITY_LOW';
export const PRIORITY_MEDIUM = 'PRIORITY_MEDIUM';
export const PRIORITY_HIGH = 'PRIORITY_HIGH';
export const PRIORITY_URGENT = 'PRIORITY_URGENT';

export const PRIORITIES: OptionValue[] = [
  // {
  //   keyName: PRIORITY_VERY_LOW,
  //   languageKey: 'Very Low',
  // },
  {
    keyName: PRIORITY_URGENT,
    languageKey: 'Urgent',
  },

  {
    keyName: PRIORITY_HIGH,
    languageKey: 'High',
  },

  {
    keyName: PRIORITY_MEDIUM,
    languageKey: 'Medium',
  },
  {
    keyName: PRIORITY_LOW,
    languageKey: 'Low',
  },
];

export const SRC_NO_IMAGE = 'https://www.freeiconspng.com/uploads/no-image-icon-6.png';

export const MONTHS = [
  {
    value: 1,
    label: 'January',
  },
  {
    value: 2,
    label: 'February',
  },
  {
    value: 3,
    label: 'March',
  },
  {
    value: 4,
    label: 'April',
  },
  {
    value: 5,
    label: 'May',
  },
  {
    value: 6,
    label: 'June',
  },
  {
    value: 7,
    label: 'July',
  },
  {
    value: 8,
    label: 'August',
  },
  {
    value: 9,
    label: 'September',
  },
  {
    value: 10,
    label: 'October',
  },
  {
    value: 11,
    label: 'November',
  },
  {
    value: 12,
    label: 'December',
  },
];

export const WEEK_DAYS = [
  {
    value: 0,
    label: 'Mon',
  },
  {
    value: 1,
    label: 'Tue',
  },
  {
    value: 2,
    label: 'Wed',
  },
  {
    value: 3,
    label: 'Thu',
  },
  {
    value: 4,
    label: 'Fri',
  },
  {
    value: 5,
    label: 'Sat',
  },
  {
    value: 6,
    label: 'Sun',
  },
];

export const MODULE: { [index: string]: string } = {
  MODULE_ACTIVITY: 'Activity',
  MODULE_DESK: 'Desk',
  MODULE_PRODUCT: 'Product',
  MODULE_ACCOUNT: 'Account',
  MODULE_CONTACT: 'Contact',
};

export const MODULE_OPTIONS: OptionValue[] = [
  {
    keyName: 'MODULE_ACTIVITY',
    languageKey: MODULE['MODULE_ACTIVITY'],
  },
  {
    keyName: 'MODULE_DESK',
    languageKey: MODULE['MODULE_DESK'],
  },
  {
    keyName: 'MODULE_ACCOUNT',
    languageKey: MODULE['MODULE_ACCOUNT'],
  },
  {
    keyName: 'MODULE_CONTACT',
    languageKey: MODULE['MODULE_CONTACT'],
  },
  {
    keyName: 'MODULE_PRODUCT',
    languageKey: MODULE['MODULE_PRODUCT'],
  },
];

export const DateFilter: LabelValue[] = [
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'This week',
    value: 'week',
  },
  {
    label: 'This month',
    value: 'month',
  },
  {
    label: 'This year',
    value: 'year',
  },
];

export const AssignedTo: LabelValue[] = [
  {
    label: 'Me',
    value: 'me',
  },
  {
    label: 'Team',
    value: 'team',
  },
  {
    label: 'Specific User',
    value: 'user',
  },
];

export const TermOptions: OptionValue[] = [
  {
    keyName: 'TERM_MONTH',
    languageKey: 'Month',
  },

  {
    keyName: 'TERM_WEEK',
    languageKey: 'Week',
  },

  {
    keyName: 'TERM_DAY',
    languageKey: 'Day',
  },
  {
    keyName: 'TERM_HOUR',
    languageKey: 'Hour',
  },
];

interface IPriorityClass {
  [key: string]: string;
}

export const PRIORITIES_CLASS: IPriorityClass = {
  PRIORITY_NONE: 'none',
  PRIORITY_URGENT: 'urgent',
  PRIORITY_HIGH: 'high',
  PRIORITY_MEDIUM: 'medium',
  PRIORITY_LOW: 'low',
};

export const WHEN_OPTIONS: OptionValue[] = [
  { keyName: 'WHEN_BEFORE', languageKey: 'Before' },
  { keyName: 'WHEN_AFTER', languageKey: 'After' },
];
