import React from 'react';
import { SpanLang } from '@base/components/form';
import { Canvas, Portal } from '@base/components';
import classNames from 'classnames';
import { useModalSizeAtomValue } from '../../recoil/atoms';

type ModalContainerProps = {
  isOpen: boolean;
  isView?: boolean;
  onClose: () => void;
  viewComponent?: React.ReactNode;
  writeComponent?: React.ReactNode;
  title: string;
  isFooter?: boolean;
  modalSize?: string;
};

const ModalContainer = (props: ModalContainerProps) => {
  let modalSize = useModalSizeAtomValue();
  if (props?.modalSize) {
    modalSize = props.modalSize;
  }
  const {
    isOpen = false,
    onClose,
    isView = false,
    viewComponent,
    writeComponent,
    title,
    isFooter = true,
  } = props;

  return (
    <Portal>
      <Canvas
        isVisible={isOpen}
        onCloseSideBar={onClose}
        className={classNames('page-sidebar-container-wrap', modalSize)}
        customStyles={{ zIndex: '1052' }}
        backdropStyles={{ zIndex: '1051' }}
      >
        <Canvas.Header title={<SpanLang keyLang={title} />} onClose={onClose} />
        <Canvas.Body>{isView ? viewComponent : writeComponent}</Canvas.Body>
        {isFooter && <Canvas.Footer onClose={onClose} />}
      </Canvas>
    </Portal>
  );
};

export default React.memo(ModalContainer);
