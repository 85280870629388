import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isFunction, isString, isUndefined } from 'lodash';
import { convertDateTimeServerToClient } from '@base/utils/helpers';

export interface ItemBodyProps {
  data: any;
  isSplitMode?: boolean;
  columns: {
    name: string;
    title: string;
  }[];
  columnRender?: any;
}

const defaultColumnRender = (col: string, data: any): string | null => {
  const val = data?.[col];
  if (isUndefined(val) || isEmpty(val) || !val) {
    return '-';
  }
  if (['createdAt', 'updatedAt'].indexOf(col) !== -1) {
    return convertDateTimeServerToClient({ date: val, isTime: true, humanize: true });
  }
  if (['createdBy', 'updatedBy'].indexOf(col) !== -1) {
    return val?.name || '-';
  }
  // return json to know error for fix.
  return isString(val) ? val : JSON.stringify(val);
};

const ItemBody = (props: ItemBodyProps) => {
  const {
    data,
    isSplitMode = false,
    columns = [],
    columnRender,
  } = props;
  //lang
  const { t } = useTranslation();

  //render
  return (
    <div className='d-flex'>
      {isSplitMode ? (
        <ul className="list-unstyled mg-b-0">
          {columns.length > 0 && columns.map((col: any, i: number) => {
            const ColumnComponent =
              isFunction(columnRender) && columnRender(col.name)
                ? columnRender(col.name)(col.name, data)
                : defaultColumnRender(col.name, data);
            return (
              <Fragment key={i}>
                {ColumnComponent}
              </Fragment>
            );
          })}
        </ul>
      ) : (
        <div className="grid-list-item-detail-body">
          {columns.length > 0 && columns.map((col: any, i: number) => {
            const ColumnComponent =
              isFunction(columnRender) && columnRender(col.name)
                ? columnRender(col.name)(col.name, data)
                : defaultColumnRender(col.name, data);
            return (
              <div className="grid-list-item-col" key={i}>
                <span className="grid-list-item-col-title">{t(col.title) + ':'}</span>
                <span className="grid-list-item-col-content">{ColumnComponent}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ItemBody;
