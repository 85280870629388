import React, { FormEvent, useCallback } from 'react';
import { Select } from '@base/components/form';
import DatePicker from '@base/components/date-picker/default';
import { RepeatState } from '.';
import { LabelValue } from '@base/types/types/app';

interface RepeatProps {
  value: RepeatState;
  onChange: (val: RepeatState) => void;
}

export const typeOptions = [
  {
    value: 'REPEAT_UNIT_HOURY',
    label: 'Hourly',
  },
  {
    value: 'REPEAT_UNIT_DAILY',
    label: 'Daily',
  },
  {
    value: 'REPEAT_UNIT_WEEKLY',
    label: 'Weekly',
  },
  {
    value: 'REPEAT_UNIT_MONTHLY',
    label: 'Monthly',
  },
  {
    value: 'REPEAT_UNIT_YEARLY',
    label: 'Yearly',
  },
];

const Repeat = (props: RepeatProps) => {
  const { value, onChange } = props;
  // console.log('repate value', value);

  //change flag
  const handleRepeatChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      onChange({ ...value, useRepeat: e.currentTarget.checked });
    },
    [value],
  );

  //Frequency
  const handleFrequencyChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      onChange({ ...value, repeatFrq: Number(e.currentTarget.value) });
    },
    [value],
  );

  //type
  const handleTypeChange = useCallback(
    (option: LabelValue) => {
      onChange({ ...value, repeatType: option.value as string });
    },
    [value],
  );

  //weekday change
  const handleWeekdaysChange = useCallback(
    (e: FormEvent<HTMLInputElement>, day: number) => {
      let newWeekdays = value.repeatDays || 'nnnnnnn';
      if (e.currentTarget.checked) {
        //add
        newWeekdays = newWeekdays.substring(0, day) + 'y' + newWeekdays.substring(day + 1);
      } else {
        //remove
        newWeekdays = newWeekdays.substring(0, day) + 'n' + newWeekdays.substring(day + 1);
      }
      onChange({ ...value, repeatDays: newWeekdays });
    },
    [value],
  );

  //end date option
  const handleEndDateChange = useCallback(
    (val: boolean) => {
      const newData = { ...value };
      newData.repeatUseEnd = val;
      if (!val) {
        //no end date
        newData.repeatEnd = '';
      }
      onChange(newData);
    },
    [value],
  );

  //end by
  const handleEndByChange = useCallback(
    (val: string) => {
      onChange({ ...value, repeatEnd: val });
    },
    [value],
  );

  //render
  return (
    <div className="pos-relative">
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id="repeatTaskSwitch"
          checked={value.useRepeat}
          onChange={handleRepeatChange}
        />
        <label className="custom-control-label" htmlFor="repeatTaskSwitch">
          Repeat
        </label>
      </div>
      {/* content */}
      {value.useRepeat && (
        <div className="mg-t-10 form-row">
          <div className="form-group col-6">
            <label className="form-item-title">Type</label>
            <div className="wd-100p mg-b-10">
              <Select
                outSide={true}
                options={typeOptions}
                value={typeOptions[typeOptions.findIndex((el) => el.value === value.repeatType)]}
                onChange={handleTypeChange}
              />
            </div>

            {/* for weekly */}
            {value.repeatType === 'REPEAT_UNIT_WEEKLY' && (
              <div className="d-flex justify-content-start">
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="weekDayCheck0"
                    checked={value.repeatDays.charAt(0) === 'y'}
                    onChange={(e) => handleWeekdaysChange(e, 0)}
                  />
                  <label className="custom-control-label" htmlFor="weekDayCheck0">
                    Sun
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="weekDayCheck1"
                    checked={value.repeatDays.charAt(1) === 'y'}
                    onChange={(e) => handleWeekdaysChange(e, 1)}
                  />
                  <label className="custom-control-label" htmlFor="weekDayCheck1">
                    Mon
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="weekDayCheck2"
                    checked={value.repeatDays.charAt(2) === 'y'}
                    onChange={(e) => handleWeekdaysChange(e, 2)}
                  />
                  <label className="custom-control-label" htmlFor="weekDayCheck2">
                    Tue
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="weekDayCheck3"
                    checked={value.repeatDays.charAt(3) === 'y'}
                    onChange={(e) => handleWeekdaysChange(e, 3)}
                  />
                  <label className="custom-control-label" htmlFor="weekDayCheck3">
                    Wed
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="weekDayCheck4"
                    checked={value.repeatDays.charAt(4) === 'y'}
                    onChange={(e) => handleWeekdaysChange(e, 4)}
                  />
                  <label className="custom-control-label" htmlFor="weekDayCheck4">
                    Thu
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="weekDayCheck5"
                    checked={value.repeatDays.charAt(5) === 'y'}
                    onChange={(e) => handleWeekdaysChange(e, 5)}
                  />
                  <label className="custom-control-label" htmlFor="weekDayCheck5">
                    Fri
                  </label>
                </div>
                <div className="custom-control custom-checkbox d-inline-block mg-r-10">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="weekDayCheck6"
                    checked={value.repeatDays.charAt(6) === 'y'}
                    onChange={(e) => handleWeekdaysChange(e, 6)}
                  />
                  <label className="custom-control-label" htmlFor="weekDayCheck6">
                    Sat
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="form-group col-6">
            <label className="form-item-title">Every</label>
            <div className="input-group">
              <input
                type="number"
                className="form-control"
                min="0"
                value={value.repeatFrq}
                onChange={handleFrequencyChange}
              />
              <div className="input-group-append">
                <span className="input-group-text lh-1">time(s)</span>
              </div>
            </div>
          </div>
          <div className="form-group col-12">
            <label className="form-item-title">End Date</label>
            <div className="d-flex justify-content-start align-items-center">
              <div className="custom-control custom-radio mg-r-10">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="endDateRadio"
                  id="endDateRadio1"
                  checked={!value.repeatUseEnd}
                  onChange={() => handleEndDateChange(false)}
                />
                <label className="custom-control-label mg-r-10" htmlFor="endDateRadio1">
                  No end date
                </label>
              </div>
              <div className="custom-control custom-radio mg-r-10">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="endDateRadio"
                  id="endDateRadio2"
                  checked={value.repeatUseEnd}
                  onChange={() => handleEndDateChange(true)}
                />
                <label className="custom-control-label mg-r-10" htmlFor="endDateRadio2">
                  End by
                </label>
              </div>
              {value.repeatUseEnd && (
                <div className="wd-200">
                  <DatePicker
                    selected={value.repeatEnd ? new Date(value.repeatEnd) : null}
                    onChange={(date: Date) => {
                      handleEndByChange(date.toISOString());
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Repeat;
