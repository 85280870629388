import React from 'react';
import classNames from 'classnames';
import Button from '@base/components/form/button';

const defaultProps = {
  tag: 'div',
};

const Footer = (props: any) => {
  const {
    className,
    tag: Tag,
    closeBtn,
    onClose,
    closeLabel,
    applyBtn,
    onApply,
    applyLabel,
    applyLoading,
    saveBtn,
    onSave,
    saveLabel,
    saveLoading,
    children,
    componentRight = null,
    saveColor = 'primary',
    disabled = false,
  } = props;

  //button DOM
  let applyButton;
  let closeButton;
  let saveButton;

  if (!closeBtn && onClose) {
    closeButton = (
      <Button color="light" outline onClick={onClose}>
        {closeLabel || 'Cancel'}
      </Button>
    );
  }

  if (!saveBtn && onSave) {
    saveButton = (
      <Button
        color={saveColor}
        className="mg-l-5"
        onClick={onSave}
        loading={saveLoading}
        disabled={disabled}
      >
        {saveLabel || 'Save'}
      </Button>
    );
  }

  if (!applyBtn && onApply) {
    applyButton = (
      <Button color="secondary" className="mg-l-5" onClick={onApply} loading={applyLoading}>
        {applyLabel || 'Apply'}
      </Button>
    );
  }

  return (
    <Tag className={classNames(className, 'ht-60 pd-20 bd-t')}>
      {children}
      <div className="d-flex justify-content-between">
        <div>{applyBtn || applyButton}</div>
        <div>
          {closeBtn || closeButton}
          {saveBtn || saveButton}
          {componentRight}
        </div>
      </div>
    </Tag>
  );
};

Footer.defaultProps = defaultProps;

export default Footer;
