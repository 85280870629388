import { useEffect, useState } from 'react';
import useWindowSize from '@base/hooks/useWindowSize';
import { IDevice } from '@base/types/interfaces/app';
import { deviceAtom } from '@base/recoil/atoms/app';
import { useRecoilState, useRecoilValue } from 'recoil';
import { extractUrlParams } from '@site-page/utils/Params';
import { useUserSetting } from '@base/services';
import { UserSetting } from '@base/types/interfaces/setting';
import { generateUUID } from '@base/utils/helpers';
import _ from 'lodash';

const useDevice = (): IDevice => {
  const [device, setDevice] = useRecoilState<IDevice>(deviceAtom);

  const siteParams = extractUrlParams();
  const getThemeConfig = (token: string) => {
    let response = null;
    if (token && token.length > 0) {
      const isFalse: boolean = false;
      const fakeResponse: UserSetting = {
        value: JSON.stringify(device),
        id: generateUUID(),
        key: 'general',
        menu: 'common',
      };
      return { fakeResponse, isFalse };
    } else {
      // get user setting from api
      // response = useUserSetting('common', 'general');
      const { data, isLoading } = {data:{value: ''}, isLoading:false};
      return { data, isLoading };
    }
  };
  const { data, isLoading } = getThemeConfig(siteParams?.tk || '');

  const size = useWindowSize();
  useEffect(() => {
    function handleResize() {
      const resolution = size.width ?? 0;
      const isMobile = resolution >= 320 && resolution <= 480;
      const isTablet = resolution >= 768 && resolution <= 1024;
      const isDesktop = !isMobile && !isTablet;
      const newDevice = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop';
      if (device.device != newDevice) {
        setDevice({
          ...device,
          device: newDevice,
          isDesktop: isDesktop,
          isTablet: isTablet,
          isMobile: isMobile,
        });
      }
    }
    handleResize();
  }, [size, device]);

  useEffect(() => {
    if (!isLoading && data?.value) {
      const userConfig = data.value ? JSON.parse(data.value) : null;
      if (!_.isEqual(device, userConfig)) {
        setDevice({
          ...userConfig,
          device: device.device,
        });
      }
    }
  }, [data, isLoading]);

  return device;
};

export default useDevice;
