import React from 'react';
//import { useTranslation } from 'react-i18next';
import TextView from '@base/containers/view-field/text/view';

const View = (props: any) => {
  //const { t } = useTranslation();
  const { value } = props;

  return <TextView value={value ? value.join(', ') : ''} />;
};

export default View;
