import { isUndefined } from 'lodash';

export const buildViewSchema = ({
  sections,
  configFields = {},
  ignore = [],
  hiddenSchemas = [],
}: any) => {
  let schemas: string[] = [];
  if (sections) {
    schemas.push('id');
  }
  if (sections && !isUndefined(hiddenSchemas) && hiddenSchemas.length > 0) {
    schemas.push(...hiddenSchemas);
  }
  sections?.forEach((section: { children: any[] }) => {
    const childrenData = section?.children || [];
    childrenData.forEach((item: { keyName: string; permissionType: string }) => {
      const { schema } = configFields?.[item?.keyName] || {};
      if (
        ignore.indexOf(item?.keyName) == -1 &&
        !(item?.permissionType && item?.permissionType == 'custom') // TODO enabled custom field
      ) {
        schemas.push(schema || schema == '' ? schema : item?.keyName);
      }
    });
  });

  return schemas.join('\n');
};

export const buildListSchema = ({ fields, configFields = {}, ignore = [] }: any) => {
  let schemas: string[] = ignore && ignore.includes('id') ? [] : ['id'];
  fields &&
    fields.forEach((item: { keyName: string; permissionType: string }) => {
      const { schema } = configFields?.[item?.keyName] || {};
      if (ignore.indexOf(item?.keyName) == -1) {
        if (item?.permissionType && item?.permissionType == 'custom') {
          //schemas.push(item?.keyName);
        } else {
          schemas.push(schema || schema == '' ? schema : item?.keyName);
        }
      }
    });
  return schemas.join('\n');
};
