import React, { useCallback } from 'react';
import { SpanLang } from '@base/components/form';
import { RECURRENCES, RECURRENCE_TYPE, RECURRENCE_TYPE_LABEL, MONTHS } from './configs';
import { getStrToReDays, getWeekOfMonth, daysInMonth } from './utils';
import _ from 'lodash';
import { LabelValue } from '@base/types/types/app';
import { Repeat } from '.';

interface RepeatTimeViewProps {
  value: Repeat;
}

const RepeatTimeView = (props: RepeatTimeViewProps) => {
  const { value: scheduleValue } = props;
  /** ======================================== RENDER ============================================= */

  //recurrency type
  const renderRecurrenceType = useCallback(() => {
    const _item = RECURRENCES.find((v) => v.value == scheduleValue.type);
    return (
      <div className="d-flex">
        <label className="tx-capitalize">
          <SpanLang keyLang={_item?.label} />
        </label>
      </div>
    );
  }, [scheduleValue]);

  //start, end time
  const renderRecurrenceTime = useCallback(() => {
    return (
      <div className="form-group">
        <SpanLang keyLang={'Start Time'} className="form-item-title mg-r-10" />
        {scheduleValue.startTime}
        <SpanLang keyLang={'End Time'} className="form-item-title mg-r-10" />
        {scheduleValue.endTime}
      </div>
    );
  }, [scheduleValue]);

  //every number
  const renderRecurrenceEvery = useCallback(() => {
    return (
      <div className="wd-150-f mg-r-10">
        {scheduleValue.everyNr}
        <span className="mg-l-5">{RECURRENCE_TYPE_LABEL[scheduleValue.type]}</span>
      </div>
    );
  }, [scheduleValue]);

  //render weekdays option
  const renderWeekdayOptions = useCallback(() => {
    const DAY_OPTIONS: LabelValue[] = getStrToReDays(scheduleValue.weekdays);

    return (
      <div className="d-flex flex-wrap">
        {DAY_OPTIONS?.map((_option: any, _index: number) => {
          return (
            _option.extra && (
              <div key={_index} className="tx-capitalize mg-r-10">
                <SpanLang keyLang={_option.label} />
              </div>
            )
          );
        })}
      </div>
    );
  }, [scheduleValue]);

  //render monthly
  const renderMonthlyRecurrence = useCallback(() => {
    const dayOptions: LabelValue[] = daysInMonth(new Date().getMonth() + 1);
    const weekOptions: LabelValue[] = getWeekOfMonth(new Date());
    const monthlyWeek = weekOptions.find((_ele: any) => _ele.value === scheduleValue.monthlyWeek);
    const monthlyDay = dayOptions.find((_ele: any) => _ele.value === scheduleValue.monthlyDay);

    return (
      <div className="d-flex align-items-center mg-t-10">
        {scheduleValue.monthlyOption === 'week' && (
          <>
            <div className="tx-nowrap">the week</div>
            <div className="mg-x-10">{monthlyWeek?.label}</div>
            {renderWeekdayOptions()}
          </>
        )}
        {scheduleValue.monthlyOption === 'day' && (
          <>
            <div className="tx-nowrap">the day</div>
            <div className="mg-x-10">{monthlyDay?.label}</div>
          </>
        )}
        {scheduleValue.monthlyOption === 'last' && (
          <div className="tx-nowrap">the last day</div>
        )}
      </div>
    );
  }, [scheduleValue]);

  //render yearly
  const renderYearlyRecurrence = useCallback(() => {
    const dayOptions: LabelValue[] = daysInMonth(new Date().getMonth() + 1);
    const weekOptions: LabelValue[] = getWeekOfMonth(new Date());
    const yearlyWeekMonth = MONTHS.find(
      (_ele: any) => _ele.value === scheduleValue.yearlyWeekMonth,
    );
    const yearlyWeek = weekOptions.find((_ele: any) => _ele.value === scheduleValue.yearlyWeek);
    const yearlyDayMonth = MONTHS.find((_ele: any) => _ele.value === scheduleValue.yearlyDayMonth);
    const yearlyDay = dayOptions.find((_ele: any) => _ele.value === scheduleValue.yearlyDay);

    return (
      <div className="d-flex flex-column mg-t-10">
        {scheduleValue.yearlyOption === 'week' && (
          <>
            <div className="d-flex align-items-center">
              <div className="tx-nowrap">the week</div>
              <div className="mg-x-10">{yearlyWeekMonth?.label}</div>
              <div className="mg-r-10">{yearlyWeek?.label}</div>
            </div>
            <div className="mg-l-20">{renderWeekdayOptions()}</div>
          </>
        )}
        {scheduleValue.yearlyOption === 'day' && (
          <div className="d-flex align-items-center">
            <div className="tx-nowrap">the day</div>
            <div className="mg-x-10">{yearlyDayMonth?.label}</div>
            <div className="mg-r-10">{yearlyDay?.label}</div>
          </div>
        )}
      </div>
    );
  }, [scheduleValue]);

  //render
  return (
    <div className="pos-relative">
      {!scheduleValue.use && <label>Not use</label>}
      {scheduleValue.use && (
        <div className="mg-x-5">
          <div>
            <label className="form-item-title d-block">Recurrence</label>
            {renderRecurrenceType()}
          </div>
          {scheduleValue.type === RECURRENCE_TYPE.hourly && renderRecurrenceTime()}
          <div>
            <label className="form-item-title d-block">Days</label>
            <div className="d-flex flex-column justify-items-center">
              <div className="d-flex align-items-center">
                <span className="mg-r-10">Every</span>
                {scheduleValue.type !== RECURRENCE_TYPE.hourly &&
                  scheduleValue.type !== RECURRENCE_TYPE.daily &&
                  renderRecurrenceEvery()}
                {scheduleValue.type !== RECURRENCE_TYPE.monthly &&
                  scheduleValue.type !== RECURRENCE_TYPE.yearly &&
                  renderWeekdayOptions()}
              </div>
              {scheduleValue.type === RECURRENCE_TYPE.monthly && renderMonthlyRecurrence()}
              {scheduleValue.type === RECURRENCE_TYPE.yearly && renderYearlyRecurrence()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RepeatTimeView;
