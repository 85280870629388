import React from 'react';

const html = () => {
  return (
    <svg id="file-type-html" xmlns="http://www.w3.org/2000/svg" width="42px" height="51px" viewBox="0 0 42 51">
      <g>
        <path fill="#6FBF9B" d="M42,49c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h32l8,8V49z" />
        <polygon fill="#5BAD88" points="42.003,14.828 34.615,7.44 42,8 	" />
        <g>
          <path fill="#BAE6D1" d="M42,8h-6c-1.104,0-2-0.896-2-2V0L42,8z" />
        </g>
      </g>
      <g>
        <circle fill="#6FBF9B" cx="30" cy="36" r="11.5" />
        <circle fill="#6EBE9A" cx="29.71" cy="35.774" r="11.5" />
        <circle fill="#6DBD99" cx="29.419" cy="35.549" r="11.5" />
        <circle fill="#6CBC98" cx="29.129" cy="35.322" r="11.5" />
        <circle fill="#6CBB97" cx="28.839" cy="35.097" r="11.5" />
        <circle fill="#6BBA96" cx="28.549" cy="34.871" r="11.5" />
        <circle fill="#6AB995" cx="28.258" cy="34.645" r="11.5" />
        <circle fill="#69B894" cx="27.968" cy="34.42" r="11.5" />
        <circle fill="#68B793" cx="27.677" cy="34.193" r="11.5" />
        <circle fill="#67B692" cx="27.387" cy="33.968" r="11.5" />
        <circle fill="#66B591" cx="27.097" cy="33.742" r="11.5" />
        <circle fill="#65B490" cx="26.807" cy="33.516" r="11.5" />
        <circle fill="#65B38F" cx="26.516" cy="33.291" r="11.5" />
        <circle fill="#64B28E" cx="26.226" cy="33.064" r="11.5" />
        <circle fill="#63B18D" cx="25.936" cy="32.839" r="11.5" />
        <circle fill="#62B08C" cx="25.645" cy="32.613" r="11.5" />
        <circle fill="#61B08C" cx="25.355" cy="32.387" r="11.5" />
        <circle fill="#60AF8B" cx="25.064" cy="32.161" r="11.5" />
        <circle fill="#5FAE8A" cx="24.774" cy="31.936" r="11.5" />
        <circle fill="#5EAD89" cx="24.484" cy="31.709" r="11.5" />
        <circle fill="#5EAC88" cx="24.193" cy="31.484" r="11.5" />
        <circle fill="#5DAB87" cx="23.903" cy="31.258" r="11.5" />
        <circle fill="#5CAA86" cx="23.613" cy="31.032" r="11.5" />
        <circle fill="#5BA985" cx="23.323" cy="30.807" r="11.5" />
        <circle fill="#5AA884" cx="23.032" cy="30.58" r="11.5" />
        <circle fill="#59A783" cx="22.742" cy="30.355" r="11.5" />
        <circle fill="#58A682" cx="22.451" cy="30.129" r="11.5" />
        <circle fill="#57A581" cx="22.161" cy="29.903" r="11.5" />
        <circle fill="#57A480" cx="21.871" cy="29.678" r="11.5" />
        <circle fill="#56A37F" cx="21.581" cy="29.451" r="11.5" />
        <circle fill="#55A27E" cx="21.29" cy="29.226" r="11.5" />
        <circle fill="#54A17D" cx="21" cy="29" r="11.5" />
      </g>
      <g>
        <circle fill="#4F9776" cx="21.5" cy="29.5" r="11.5" />
      </g>
      <g>
        <path
          fill="#6FBF9B"
          d="M20.5,39C14.71,39,10,34.29,10,28.5S14.71,18,20.5,18C26.289,18,31,22.71,31,28.5S26.289,39,20.5,39z"
        />
        <path
          fill="#FFFFFF"
          d="M20.5,19c5.238,0,9.5,4.262,9.5,9.5S25.738,38,20.5,38S11,33.738,11,28.5S15.262,19,20.5,19L20.5,19z
                    M20.5,17C14.148,17,9,22.148,9,28.5S14.148,40,20.5,40S32,34.852,32,28.5S26.852,17,20.5,17L20.5,17z"
        />
      </g>
      <g opacity="0.6">
        <path
          fill="#FFFFFF"
          d="M20.5,19c2.98,0,5.5,4.351,5.5,9.5S23.48,38,20.5,38c-2.981,0-5.5-4.351-5.5-9.5S17.519,19,20.5,19L20.5,19
                    z M20.5,18c-3.59,0-6.5,4.701-6.5,10.5S16.91,39,20.5,39c3.59,0,6.5-4.701,6.5-10.5S24.09,18,20.5,18L20.5,18z"
        />
      </g>
      <g opacity="0.6">
        <defs>
          <circle id="SVGID_1_" opacity="0.6" cx="20.5" cy="28.5" r="11.5" />
        </defs>
        <g clipPath="url(#SVGID_2_)">
          <g>
            <g>
              <path
                fill="#FFFFFF"
                d="M20.5,14c5.149,0,9.5,2.519,9.5,5.5S25.649,25,20.5,25S11,22.481,11,19.5S15.351,14,20.5,14L20.5,14z
                                M20.5,13C14.701,13,10,15.91,10,19.5S14.701,26,20.5,26S31,23.09,31,19.5S26.299,13,20.5,13L20.5,13z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill="#FFFFFF"
                d="M20.5,33c5.149,0,9.5,2.519,9.5,5.5S25.649,44,20.5,44S11,41.481,11,38.5S15.351,33,20.5,33L20.5,33z
                                M20.5,32C14.701,32,10,34.91,10,38.5S14.701,45,20.5,45S31,42.09,31,38.5S26.299,32,20.5,32L20.5,32z"
              />
            </g>
          </g>
        </g>
      </g>
      <rect x="20" y="18" opacity="0.6" fill="#FFFFFF" width="1" height="22" />
      <rect x="9.5" y="28.5" opacity="0.6" fill="#FFFFFF" width="22" height="1" />
      <g>
        <path
          fill="#FFFFFF"
          d="M13.214,10.783V8.814h-1.989v1.969h-1.079V5.881h1.079V7.82h1.989V5.881h1.079v4.902H13.214z"
        />
        <path fill="#FFFFFF" d="M18.387,6.875v3.908h-1.079V6.875h-1.492V5.881h4.063v0.994H18.387z" />
        <path
          fill="#FFFFFF"
          d="M25.233,10.783V7.582l-1.38,2.095h-0.027L22.46,7.604v3.18h-1.058V5.881h1.162l1.289,2.072l1.289-2.072
                    h1.162v4.902H25.233z"
        />
        <path fill="#FFFFFF" d="M28.179,10.783V5.881h1.079v3.922h2.443v0.98H28.179z" />
      </g>
    </svg>
  );
};

export default html;
