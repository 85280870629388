// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".crm-han-group-button .btn-custom-left{border-top-right-radius:0px !important;border-bottom-right-radius:0px !important}.crm-han-group-button .btn-custom-right{border-top-left-radius:0px !important;border-bottom-left-radius:0px !important}.crm-han-group-button .btn-custom{border-radius:0px !important}", "",{"version":3,"sources":["webpack://./src/base/components/han-group-button/styles.scss"],"names":[],"mappings":"AACE,uCACE,sCAAA,CACA,yCAAA,CAEF,wCACE,qCAAA,CACA,wCAAA,CAGF,kCACE,4BAAA","sourcesContent":[".crm-han-group-button {\n  .btn-custom-left {\n    border-top-right-radius: 0px !important;\n    border-bottom-right-radius: 0px !important;\n  }\n  .btn-custom-right {\n    border-top-left-radius: 0px !important;\n    border-bottom-left-radius: 0px !important;\n  }\n\n  .btn-custom {\n    border-radius: 0px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crm-han-group-button": "crm-han-group-button",
	"btn-custom-left": "btn-custom-left",
	"btn-custom-right": "btn-custom-right",
	"btn-custom": "btn-custom"
};
export default ___CSS_LOADER_EXPORT___;
