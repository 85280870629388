import React from 'react';

const doc = () => {
  return (
    <svg id="file-type-doc" xmlns="http://www.w3.org/2000/svg" width="42px" height="51px" viewBox="0 0 42 51">
      <path fill="#5FB0F2" d="M42,49c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h32l8,8V49z" />
      <path fill="#4C9DE0" d="M42,14.83l-7.38-7.39L42,8V14.83z" />
      <path fill="#9FD3FF" d="M42,8h-6c-1.104,0-2-0.896-2-2V0L42,8z" />
      <path
        fill="#5FB0F2"
        d="M42,44c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V44z
                M42,31c0,0.553-0.447,1-1,1H17c-0.552,0-1-0.447-1-1v-1c0-0.553,0.448-1,1-1h24c0.553,0,1,0.447,1,1V31z M42,37
                c0-0.553-0.447-1-1-1H17c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V37z"
      />
      <path
        fill="#5EAFF1"
        d="M41.742,43.742c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V43.742z M41.742,30.742c0,0.553-0.447,1-1,1h-24c-0.552,0-1-0.447-1-1v-1c0-0.553,0.448-1,1-1h24c0.553,0,1,0.447,1,1V30.742z
                M41.742,36.742c0-0.553-0.447-1-1-1h-24c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V36.742z"
      />
      <path
        fill="#5EAEF0"
        d="M41.482,43.482c0-0.552-0.447-1-1-1h-8c-0.552,0-1,0.448-1,1v1c0,0.553,0.448,1,1,1h8c0.553,0,1-0.447,1-1
                V43.482z M41.482,30.482c0,0.553-0.447,1-1,1h-24c-0.552,0-1-0.447-1-1v-1c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V30.482z
                M41.482,36.482c0-0.552-0.447-1-1-1h-24c-0.552,0-1,0.448-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V36.482z"
      />
      <path
        fill="#5DADEF"
        d="M41.227,43.227c0-0.553-0.448-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.552,0.447,1,1,1h8c0.552,0,1-0.448,1-1
                V43.227z M41.227,30.227c0,0.552-0.448,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.553,0.448-1,1-1h24c0.552,0,1,0.447,1,1V30.227z
                M41.227,36.227c0-0.553-0.448-1-1-1h-24c-0.552,0-1,0.447-1,1v1c0,0.552,0.448,1,1,1h24c0.552,0,1-0.448,1-1V36.227z"
      />
      <path
        fill="#5CACEE"
        d="M40.968,42.968c0-0.552-0.447-1-1-1h-8c-0.552,0-1,0.448-1,1v1c0,0.553,0.448,1,1,1h8c0.553,0,1-0.447,1-1
                V42.968z M40.968,29.968c0,0.553-0.447,1-1,1h-24c-0.552,0-1-0.447-1-1v-1c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V29.968z
                M40.968,35.968c0-0.552-0.447-1-1-1h-24c-0.552,0-1,0.448-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V35.968z"
      />
      <path
        fill="#5BABED"
        d="M40.71,42.71c0-0.552-0.448-1-1-1h-8c-0.552,0-1,0.448-1,1v1c0,0.552,0.448,1,1,1h8c0.552,0,1-0.448,1-1
                V42.71z M40.71,29.71c0,0.552-0.448,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.552,0,1,0.448,1,1V29.71z
                M40.71,35.71c0-0.552-0.448-1-1-1h-24c-0.552,0-1,0.448-1,1v1c0,0.552,0.448,1,1,1h24c0.552,0,1-0.448,1-1V35.71z"
      />
      <path
        fill="#5BABEC"
        d="M40.451,42.451c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V42.451z M40.451,29.451c0,0.553-0.447,1-1,1h-24c-0.552,0-1-0.447-1-1v-1c0-0.553,0.448-1,1-1h24c0.553,0,1,0.447,1,1V29.451z
                M40.451,35.451c0-0.553-0.447-1-1-1h-24c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V35.451z"
      />
      <path
        fill="#5AAAEB"
        d="M40.193,42.193c0-0.553-0.448-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.552,0.447,1,1,1h8c0.552,0,1-0.448,1-1
                V42.193z M40.193,29.193c0,0.552-0.448,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.553,0.448-1,1-1h24c0.552,0,1,0.447,1,1V29.193z
                M40.193,35.193c0-0.553-0.448-1-1-1h-24c-0.552,0-1,0.447-1,1v1c0,0.552,0.448,1,1,1h24c0.552,0,1-0.448,1-1V35.193z"
      />
      <path
        fill="#59A9EA"
        d="M39.936,41.936c0-0.552-0.447-1-1-1h-8c-0.552,0-1,0.448-1,1v1c0,0.553,0.448,1,1,1h8c0.553,0,1-0.447,1-1
                V41.936z M39.936,28.936c0,0.553-0.447,1-1,1h-24c-0.552,0-1-0.447-1-1v-1c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V28.936z
                M39.936,34.936c0-0.552-0.447-1-1-1h-24c-0.552,0-1,0.448-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V34.936z"
      />
      <path
        fill="#58A8E9"
        d="M39.678,41.678c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V41.678z M39.678,28.678c0,0.553-0.447,1-1,1h-24c-0.553,0-1-0.447-1-1v-1c0-0.552,0.447-1,1-1h24c0.553,0,1,0.448,1,1V28.678z
                M39.678,34.678c0-0.552-0.447-1-1-1h-24c-0.553,0-1,0.448-1,1v1c0,0.553,0.447,1,1,1h24c0.553,0,1-0.447,1-1V34.678z"
      />
      <path
        fill="#58A7E8"
        d="M39.419,41.419c0-0.552-0.448-1-1-1h-8c-0.552,0-1,0.448-1,1v1c0,0.552,0.448,1,1,1h8c0.552,0,1-0.448,1-1
                V41.419z M39.419,28.419c0,0.552-0.448,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.552,0,1,0.448,1,1V28.419z
                M39.419,34.419c0-0.552-0.448-1-1-1h-24c-0.552,0-1,0.448-1,1v1c0,0.552,0.448,1,1,1h24c0.552,0,1-0.448,1-1V34.419z"
      />
      <path
        fill="#57A6E7"
        d="M39.161,41.161c0-0.552-0.448-1-1-1h-8c-0.552,0-1,0.448-1,1v1c0,0.552,0.448,1,1,1h8c0.552,0,1-0.448,1-1
                V41.161z M39.161,28.161c0,0.552-0.448,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.552,0,1,0.448,1,1V28.161z
                M39.161,34.161c0-0.552-0.448-1-1-1h-24c-0.552,0-1,0.448-1,1v1c0,0.552,0.448,1,1,1h24c0.552,0,1-0.448,1-1V34.161z"
      />
      <path
        fill="#56A5E6"
        d="M38.902,40.902c0-0.552-0.447-1-1-1h-8c-0.552,0-1,0.448-1,1v1c0,0.553,0.448,1,1,1h8c0.553,0,1-0.447,1-1
                V40.902z M38.902,27.902c0,0.553-0.447,1-1,1h-24c-0.552,0-1-0.447-1-1v-1c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V27.902z
                M38.902,33.902c0-0.552-0.447-1-1-1h-24c-0.552,0-1,0.448-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V33.902z"
      />
      <path
        fill="#55A4E5"
        d="M38.646,40.646c0-0.553-0.448-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.552,0.447,1,1,1h8c0.552,0,1-0.448,1-1
                V40.646z M38.646,27.646c0,0.552-0.448,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.553,0.448-1,1-1h24c0.552,0,1,0.448,1,1V27.646z
                M38.646,33.646c0-0.553-0.448-1-1-1h-24c-0.552,0-1,0.447-1,1v1c0,0.552,0.448,1,1,1h24c0.552,0,1-0.448,1-1V33.646z"
      />
      <path
        fill="#55A3E4"
        d="M38.387,40.387c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V40.387z M38.387,27.387c0,0.553-0.447,1-1,1h-24c-0.553,0-1-0.447-1-1v-1c0-0.552,0.447-1,1-1h24c0.553,0,1,0.448,1,1V27.387z
                M38.387,33.387c0-0.552-0.447-1-1-1h-24c-0.553,0-1,0.448-1,1v1c0,0.553,0.447,1,1,1h24c0.553,0,1-0.447,1-1V33.387z"
      />
      <path
        fill="#54A2E3"
        d="M38.129,40.129c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V40.129z M38.129,27.129c0,0.553-0.447,1-1,1h-24c-0.552,0-1-0.447-1-1v-1c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V27.129z
                M38.129,33.129c0-0.553-0.447-1-1-1h-24c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V33.129z"
      />
      <path
        fill="#53A2E3"
        d="M37.871,39.871c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V39.871z M37.871,26.871c0,0.553-0.447,1-1,1h-24c-0.552,0-1-0.447-1-1v-1c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V26.871z
                M37.871,32.871c0-0.553-0.447-1-1-1h-24c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V32.871z"
      />
      <path
        fill="#52A1E2"
        d="M37.613,39.613c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1
                V39.613z M37.613,26.613c0,0.552-0.447,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V26.613z
                M37.613,32.613c0-0.553-0.447-1-1-1h-24c-0.552,0-1,0.447-1,1v1c0,0.552,0.448,1,1,1h24c0.553,0,1-0.448,1-1V32.613z"
      />
      <path
        fill="#52A0E1"
        d="M37.354,39.354c0-0.552-0.447-1-1-1h-8c-0.552,0-1,0.448-1,1v1c0,0.553,0.448,1,1,1h8c0.553,0,1-0.447,1-1
                V39.354z M37.354,26.354c0,0.553-0.447,1-1,1h-24c-0.552,0-1-0.447-1-1v-1c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V26.354z
                M37.354,32.354c0-0.552-0.447-1-1-1h-24c-0.552,0-1,0.448-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V32.354z"
      />
      <path
        fill="#519FE0"
        d="M37.098,39.098c0-0.553-0.448-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.552,0.447,1,1,1h8c0.552,0,1-0.448,1-1
                V39.098z M37.098,26.098c0,0.552-0.448,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.552,0,1,0.448,1,1V26.098z
                M37.098,32.098c0-0.553-0.448-1-1-1h-24c-0.552,0-1,0.447-1,1v1c0,0.552,0.448,1,1,1h24c0.552,0,1-0.448,1-1V32.098z"
      />
      <path
        fill="#509EDF"
        d="M36.839,38.839c0-0.552-0.448-1-1-1h-8c-0.552,0-1,0.448-1,1v1c0,0.552,0.448,1,1,1h8c0.552,0,1-0.448,1-1
                V38.839z M36.839,25.839c0,0.552-0.448,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.552,0,1,0.448,1,1V25.839z
                M36.839,31.839c0-0.552-0.448-1-1-1h-24c-0.552,0-1,0.448-1,1v1c0,0.552,0.448,1,1,1h24c0.552,0,1-0.448,1-1V31.839z"
      />
      <path
        fill="#4F9DDE"
        d="M36.581,38.581c0-0.552-0.448-1-1-1h-8c-0.552,0-1,0.448-1,1v1c0,0.552,0.448,1,1,1h8c0.552,0,1-0.448,1-1
                V38.581z M36.581,25.581c0,0.552-0.448,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.552,0,1,0.448,1,1V25.581z
                M36.581,31.581c0-0.552-0.448-1-1-1h-24c-0.552,0-1,0.448-1,1v1c0,0.552,0.448,1,1,1h24c0.552,0,1-0.448,1-1V31.581z"
      />
      <path
        fill="#4F9CDD"
        d="M36.322,38.322c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1
                V38.322z M36.322,25.323c0,0.552-0.447,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.553,0.448-1,1-1h24c0.553,0,1,0.447,1,1V25.323z
                M36.322,31.322c0-0.553-0.447-1-1-1h-24c-0.552,0-1,0.447-1,1v1c0,0.552,0.448,1,1,1h24c0.553,0,1-0.448,1-1V31.322z"
      />
      <path
        fill="#4E9BDC"
        d="M36.064,38.064c0-0.553-0.448-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.552,0.447,1,1,1h8c0.552,0,1-0.448,1-1
                V38.064z M36.064,25.064c0,0.552-0.448,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.552,0,1,0.448,1,1V25.064z
                M36.064,31.064c0-0.553-0.448-1-1-1h-24c-0.552,0-1,0.447-1,1v1c0,0.552,0.448,1,1,1h24c0.552,0,1-0.448,1-1V31.064z"
      />
      <path
        fill="#4D9ADB"
        d="M35.807,37.807c0-0.552-0.447-1-1-1h-8c-0.552,0-1,0.448-1,1v1c0,0.553,0.448,1,1,1h8c0.553,0,1-0.447,1-1
                V37.807z M35.807,24.807c0,0.552-0.447,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V24.807z
                M35.807,30.807c0-0.552-0.447-1-1-1h-24c-0.552,0-1,0.448-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V30.807z"
      />
      <path
        fill="#4C99DA"
        d="M35.549,37.549c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V37.549z M35.549,24.548c0,0.552-0.447,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V24.548z
                M35.549,30.549c0-0.553-0.447-1-1-1h-24c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V30.549z"
      />
      <path
        fill="#4C99D9"
        d="M35.29,37.29c0-0.552-0.448-1-1-1h-8c-0.552,0-1,0.448-1,1v1c0,0.552,0.448,1,1,1h8c0.552,0,1-0.448,1-1
                V37.29z M35.29,24.291c0,0.552-0.448,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.552,0,1,0.448,1,1V24.291z
                M35.29,30.29c0-0.552-0.448-1-1-1h-24c-0.552,0-1,0.448-1,1v1c0,0.552,0.448,1,1,1h24c0.552,0,1-0.448,1-1V30.29z"
      />
      <path
        fill="#4B98D8"
        d="M35.032,37.032c0-0.553-0.448-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.552,0.447,1,1,1h8c0.552,0,1-0.448,1-1
                V37.032z M35.032,24.032c0,0.552-0.448,1-1,1h-24c-0.553,0-1-0.448-1-1v-1c0-0.553,0.447-1,1-1h24c0.552,0,1,0.447,1,1V24.032z
                M35.032,30.032c0-0.553-0.448-1-1-1h-24c-0.553,0-1,0.447-1,1v1c0,0.552,0.447,1,1,1h24c0.552,0,1-0.448,1-1V30.032z"
      />
      <path
        fill="#4A97D7"
        d="M34.773,36.773c0-0.552-0.447-1-1-1h-8c-0.552,0-1,0.448-1,1v1c0,0.553,0.448,1,1,1h8c0.553,0,1-0.447,1-1
                V36.773z M34.773,23.774c0,0.552-0.447,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V23.774z
                M34.773,29.773c0-0.552-0.447-1-1-1h-24c-0.552,0-1,0.448-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V29.773z"
      />
      <path
        fill="#4996D6"
        d="M34.518,36.518c0-0.553-0.448-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.552,0.447,1,1,1h8c0.552,0,1-0.448,1-1
                V36.518z M34.518,23.516c0,0.552-0.448,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.552,0,1,0.448,1,1V23.516z
                M34.518,29.518c0-0.553-0.448-1-1-1h-24c-0.552,0-1,0.447-1,1v1c0,0.552,0.448,1,1,1h24c0.552,0,1-0.448,1-1V29.518z"
      />
      <path
        fill="#4995D5"
        d="M34.258,36.258c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V36.258z M34.258,23.258c0,0.552-0.447,1-1,1h-24c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V23.258z
                M34.258,29.258c0-0.553-0.447-1-1-1h-24c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V29.258z"
      />
      <path
        fill="#4894D4"
        d="M34,36c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V36z
                M34,23c0,0.552-0.447,1-1,1H9c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V23z M34,29c0-0.553-0.447-1-1-1
                H9c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V29z"
      />
      <path
        fill="#4691CF"
        d="M35,24c0,0.552-0.447,1-1,1H10c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V24z"
      />
      <path
        fill="#FFFFFF"
        d="M34,23c0,0.552-0.447,1-1,1H9c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h24c0.553,0,1,0.448,1,1V23z"
      />
      <path
        fill="#4691CF"
        d="M35,30c0-0.553-0.447-1-1-1H10c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V30z"
      />
      <path
        fill="#FFFFFF"
        d="M34,29c0-0.553-0.447-1-1-1H9c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1h24c0.553,0,1-0.447,1-1V29z"
      />
      <path
        fill="#4691CF"
        d="M35,37c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V37z"
      />
      <path
        fill="#FFFFFF"
        d="M34,36c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V36z
                M12.059,5.818h-1.912v4.903h1.912c1.541,0,2.605-1.072,2.605-2.451V8.255C14.664,6.875,13.599,5.818,12.059,5.818z M13.536,8.283
                c0,0.869-0.595,1.464-1.478,1.464h-0.833V6.792h0.833c0.882,0,1.478,0.609,1.478,1.478V8.283z M18.702,10.805
                c-1.513,0-2.599-1.127-2.599-2.521V8.27c0-1.394,1.1-2.536,2.612-2.536c1.513,0,2.599,1.128,2.599,2.521V8.27
                C21.314,9.663,20.215,10.805,18.702,10.805z M20.187,8.27c0-0.841-0.617-1.541-1.485-1.541c-0.869,0-1.471,0.687-1.471,1.527V8.27
                c0,0.84,0.616,1.541,1.484,1.541c0.869,0,1.471-0.686,1.471-1.527L20.187,8.27L20.187,8.27z M25.268,10.805
                c-1.442,0-2.515-1.114-2.515-2.521V8.27c0-1.394,1.052-2.536,2.558-2.536c0.924,0,1.478,0.308,1.933,0.756l-0.687,0.792
                c-0.379-0.343-0.764-0.553-1.254-0.553c-0.826,0-1.422,0.687-1.422,1.527V8.27c0,0.84,0.581,1.541,1.422,1.541
                c0.561,0,0.903-0.224,1.289-0.574l0.686,0.693C26.773,10.469,26.213,10.805,25.268,10.805z"
      />
    </svg>
  );
};

export default doc;
