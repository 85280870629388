import React from 'react';
import classnames from 'classnames';
import styled from '@emotion/styled';
import { Website } from '@customer/customer/components';

const Container = styled.div``;

const Edit: React.FC = (props: any) => {
  const { value = [], onChange = () => { }, errors = {}, onSave = () => { } } = props;

  return (
    <Container className={classnames('crm-website-view')}>
      <div
        className={classnames({
          'is-required': errors?.isRequired,
        })}
      >
        <Website {...props} onChange={(data: any) => onChange(data)} />
      </div>
      {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
    </Container>
  );
};

export default Edit;
