import { Select } from '@base/components/form';
import { OptionValue } from '@base/types/interfaces/common';
import classNames from 'classnames';
import { isUndefined } from 'lodash';
import React from 'react';

interface SelectboxEditProps {
  value: OptionValue;
  onChange: (params: any) => void;
  options: OptionValue[];
  fieldValue?: string;
  fieldLabel?: string;
  errors?: any;
  className?: string;
  isSearchable?: boolean;
  isMultiple?: boolean;
  isDisabled?: boolean;
}
const SelectboxEdit = (props: SelectboxEditProps) => {
  const {
    value,
    onChange,
    options,
    fieldValue = 'keyName',
    fieldLabel = 'languageKey',
    errors = { isRequired: false },
    className,
    isSearchable = true,
    isDisabled = false,
    isMultiple = true,
  } = props;

  return (
    <div className={classNames('pos-relative', className)}>
      <div
        className={classNames({
          'is-required': errors?.isRequired,
        })}
      >
        <Select
          isSearchable={isSearchable}
          isMultiple={isMultiple}
          isDisabled={isDisabled}
          outSide={true}
          options={options}
          value={value}
          onChange={(data: any) => onChange(data)}
          getOptionValue={(c: any) => {
            return !isUndefined(c[fieldValue]) ? c[fieldValue] : '';
          }}
          getOptionLabel={(c: any) => {
            return !isUndefined(c[fieldLabel]) ? c[fieldLabel] : '';
          }}
        />
      </div>
      {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
    </div>
  );
};

export default SelectboxEdit;
