import React from 'react';
import { CheckCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import TextView from '@base/containers/view-field/text/view';

const View: React.FC<any> = (props: any) => {
  const { t } = useTranslation();
  const { value } = props;

  return (
    <div>
      {value ?
        value?.map((item: any, index: number) => {
          const optionId = item?.label?.label ?? '';
          const url = item?.protocol + item?.website;
          return (
            <div key={index} className="d-flex">
              <div className="d-flex align-items-center">
                {optionId && (
                  <span className="badge badge-dark mg-r-5 pd-b-5">
                    {optionId == '1' ? item.labelValue : t(item.label.languageKey)}
                  </span>
                )}
                <a href={url} target="_blank" className="text-truncate">
                  {url}
                </a>
              </div>
              {Boolean(item?.primary) && <CheckCircle size={16} className="tx-info mg-l-5" />}
            </div>
          );
        })
        :
        <TextView value={''} />
      }
    </div>
  );
};

export default View;
