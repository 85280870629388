import { DatasPromise } from '@base/utils/axios/graphql';
import dayjs from 'dayjs';
// import { InfiniteData } from 'react-query';
import { InfiniteData } from '@tanstack/react-query'; //v4
import { Timeline } from '@base/types/interfaces/timeline';
import { Note } from '@base/types/interfaces/note';
import { Activity } from '@activity/types/interfaces';

export function makeSection<T extends Note | Timeline | Activity>(
  data: InfiniteData<DatasPromise<T[]>> | undefined,
) {
  const sections: { [key: string]: T[] } = {};
  data?.pages.forEach((page) => {
    page.data?.forEach((item) => {
      let monthDate: string = dayjs(item.createdAt).format('YYYY-MM-DD');
      if (Array.isArray(sections[monthDate])) {
        sections[monthDate].push(item);
      } else {
        sections[monthDate] = [item];
      }
    });
  });
  return sections;
}
