import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TreeDropdown } from '@base/components/form';
import { GET_USER_GROUP } from '@base/services/graphql/user';
import { BaseResponse } from '@base/types/interfaces/response';
import { graphQLApi } from '@base/utils/axios/graphql';

/**
 * @param {*} props
 */
const UserGroupSelect: React.FC<any> = (props: any) => {
  const { value, single = false, onChange, onAdd, onClose } = props;

  //lang
  const { t } = useTranslation();
  //state
  //const [defaultValue, setDefaultValue] = useState([]);

  //set default when value changes
  // useEffect(() => {
  //   setDefaultValue(value);
  // }, [value]);

  //get user groups
  const getGroup = async ({ idFolder = 0, keyword, node }: any) => {
    //check cache
    if (idFolder === 0) {
      const params = {
        filter: {
          sort: { field: 'createdAt', orderBy: 'DESC' },
        },
      };
      const data = await graphQLApi<BaseResponse<any>>('setting_groups', GET_USER_GROUP, params);
      if (data) {
        return new Promise((resolve) => {
          resolve(data.results || []);
        });
      } else {
        return new Promise((resolve) => {
          resolve([]);
        });
      }
    } else {
      //get children of node
      return new Promise((resolve) => {
        resolve(node.children || []);
      });
    }
  };

  //bind data nodes
  const formatDataNode = (_node: any) => {
    const hasChildren = _node.has_children && _node.has_children === true;
    const isExpand = _node.expanded && _node.expanded === true;
    const rs = {
      isExpand,
      hasChildren,
      title: _node.title, //title: node.language_key,
      id: _node.id,
      isFolder: _node.type && _node?.type === 'group',
    };
    return rs;
  };

  //main render
  return (
    <TreeDropdown
      placeholder="Click to select a group..."
      //tree
      single={single}
      fetchTree={getGroup}
      formatTreeNode={formatDataNode}
      //selected items
      value={value}
      onChange={onChange}
      addLabel="Add User Group"
      onAdd={onAdd}
      onClose={onClose}
    />
  );
};

export default UserGroupSelect;
