import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import SingleImage from './upload-single-image';
import MultiImage from './upload-multi-image';

interface UploaderImageImageProps {
  isSingle?: boolean;
  value?: any;
  onChange?: any;
  type?: string;
  preview?: boolean;
  className?: string;
}

/**
 *
 * @param {*} param0
 * @returns
 */
const UploaderImage: React.FC<UploaderImageImageProps> = (props: UploaderImageImageProps) => {
  const {
    isSingle = true,
    value = [],
    onChange = null,
    type = '',
    preview = true,
    className = '',
  } = props;

  const accept = type === 'img' ? 'image/*, image/svg+xml' : '';

  const upload = isSingle ? (
    <SingleImage file={value} setFile={onChange} accept={accept} preview={preview} />
  ) : (
    <MultiImage files={value} setFiles={onChange} accept={accept} preview={preview} />
  );

  return (
    <div className={classnames('pos-relative', className, { 'pd-t-10': value?.length === 0 })}>
      {upload}
    </div>
  );
};

export default UploaderImage;
