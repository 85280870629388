import React, { useRef, useState } from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { LogoRanking } from '@base/components';

const View: React.FC = (props: any) => {
  const { value = {} } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);
  // const target = useRef(null);

  const renderPopover = () => {
    return (
      <Popover>
        <ul className="list-unstyled mg-y-10">
          <li className="d-flex align-items-center justify-content-between pd-x-10">
            Monthly Payment: <span className="mg-l-10">20</span>
          </li>
          <li className="d-flex align-items-center justify-content-between pd-x-10 mg-t-5">
            Length of Business Relationship: <span className="mg-l-10">15</span>
          </li>
          <li className="d-flex align-items-center justify-content-between pd-x-10 mg-t-5">
            Total Payments (Past): <span className="mg-l-10">15</span>
          </li>
        </ul>
      </Popover>
    );
  };

  return (
    <div className="d-flex align-items-center">
      <LogoRanking value={value} />
      {value?.label || 'No Ranking'}
      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderPopover}>
        <button
          type="button"
          id="rankingScore"
          className="mg-l-auto btn btn-link pd-0"
          // ref={target}
        >
          (Score: 50)
        </button>
      </OverlayTrigger>
    </div>
  );
};
export default View;
