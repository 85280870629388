import React from 'react';
import MiModal from '@base/components/mi-modal';

interface IModalContainerProps {
  title?: string;
  isOpen: boolean;
  isLoading?: boolean;
  children?: any;
  onNo: () => void;
  onYes?: any;
  saveLabel?: string;
}

const ModalContainer: React.FC<IModalContainerProps> = (props: IModalContainerProps) => {
  const { title, isOpen, isLoading, children, onNo, onYes, saveLabel = 'Select' } = props;

  return (
    <MiModal title={title} size="md" isOpen={isOpen} onClose={onNo}>
      <MiModal.Body>{children}</MiModal.Body>
      <MiModal.Footer onClose={onNo} onSave={onYes} saveLabel={saveLabel} saveLoading={isLoading} />
    </MiModal>
  );
};

export default ModalContainer;
