import React from 'react';
import classNames from 'classnames';

import Header from './header';
import Body from './body';
import Content from './content';
import Footer from './footer';

import './styles.scss';

/**
 * author: trungtm
 * @param {*} param0
 */
const PanelTab: React.FC<any> = (props: any) => {
  const { children, className } = props;

  return <div className={classNames('panel han-panel ms-hover', className)}>{children}</div>;
};

export default Object.assign(PanelTab, {
  Header,
  Body,
  Content,
  Footer,
});
