import React from 'react';
import TextView from '@base/containers/view-field/text/view';
import { convertDateTimeServerToClient } from '@base/utils/helpers/general.utils';

interface DateTimeViewProps {
  value: string;
  showTimeInput?: boolean;
}

const View = (props: DateTimeViewProps) => {
  const { value, showTimeInput = true } = props;
  const shortDate = value?.length > 0 ? value.slice(0, 10) : '';

  return (
    <TextView
      value={
        shortDate !== '' && shortDate !== '0001-01-01' && shortDate !== '1970-01-01'
          ? convertDateTimeServerToClient({ date: value, isTime: showTimeInput, humanize: true })
          : ''
      }
    />
  );
};

export default View;
