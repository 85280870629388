import React, { useCallback, FormEvent } from 'react';
import { SpanLang, Select, TimeRangePicker } from '@base/components/form';
import { RECURRENCES, RECURRENCE_TYPE, RECURRENCE_TYPE_LABEL, MONTHS } from './configs';
import { getStrToReDays, getReDaysToStr, getWeekOfMonth, daysInMonth } from './utils';
import _ from 'lodash';
import { LabelValue } from '@base/types/types/app';
import { Repeat } from '.';

interface RepeatTimeEditProps {
  value: Repeat;
  onChange: (val: Repeat) => void;
}

const RepeatTimeEdit = (props: RepeatTimeEditProps) => {
  const { value: scheduleValue, onChange } = props;

  //value change
  const handleValueChange = useCallback(
    (keyAttribute: keyof Repeat | 'time', newValue: any) => {
      const newScheduleValue = _.clone(scheduleValue) as any;
      if (keyAttribute === 'time') {
        newScheduleValue.startTime = newValue?.startTime || '';
        newScheduleValue.endTime = newValue?.endTime || '';
      } else {
        newScheduleValue[keyAttribute] = newValue;
      }
      //callback
      onChange && onChange(newScheduleValue);
    },
    [scheduleValue],
  );

  const handleRepeatChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      onChange && onChange({ ...scheduleValue, use: e.currentTarget.checked });
    },
    [scheduleValue],
  );

  /** ======================================== RENDER ============================================= */

  //recurrency type
  const renderRecurrenceType = useCallback(() => {
    return (
      <div className="d-flex flex-wrap">
        {RECURRENCES.map((_item, _index) => (
          <div key={_index} className="custom-control custom-radio d-inline-block mg-r-10 ">
            <input
              readOnly={true}
              type="radio"
              id={`recurrenceRadio${_item.value}`}
              name="customRadio"
              className="custom-control-input"
              checked={scheduleValue.type === _item.value}
              onClick={() => handleValueChange('type', _item.value)}
            />
            <label
              className="custom-control-label tx-capitalize cursor-pointer"
              htmlFor={`recurrenceRadio${_item.value}`}
            >
              <SpanLang keyLang={_item.label} />
            </label>
          </div>
        ))}
      </div>
    );
  }, [scheduleValue]);

  //start, end time
  const renderRecurrenceTime = useCallback(() => {
    return (
      <div className="form-group">
        <TimeRangePicker
          time={true}
          step={5}
          customLabelStart={<SpanLang keyLang={'Start Time'} className="form-item-title mg-r-10" />}
          customLabelEnd={<SpanLang keyLang={'End Time'} className="form-item-title mg-r-10" />}
          value={{ startTime: scheduleValue.startTime, endTime: scheduleValue.endTime }}
          onChange={(newTime: any) => handleValueChange('time', newTime)}
        />
      </div>
    );
  }, [scheduleValue]);

  //every number
  const renderRecurrenceEvery = useCallback(() => {
    return (
      <div className="input-group wd-150-f mg-r-10">
        <input
          min={1}
          type="number"
          className="form-control ht-38"
          value={scheduleValue.everyNr}
          onChange={(e) => handleValueChange('everyNr', e.target.value)}
        />
        <div className="input-group-append">
          <span className="input-group-text">{RECURRENCE_TYPE_LABEL[scheduleValue.type]}</span>
        </div>
      </div>
    );
  }, [scheduleValue]);

  //render weekdays option
  const renderWeekdayOptions = useCallback(() => {
    const DAY_OPTIONS: LabelValue[] = getStrToReDays(scheduleValue.weekdays);

    return (
      <div className="d-flex flex-wrap">
        {DAY_OPTIONS?.map((_option: any, _index: number) => (
          <div
            key={_index}
            className="custom-control custom-checkbox d-inline-block mg-r-10 cursor-pointer"
          >
            <input
              type="checkbox"
              id={`recurrenceDayCheck${_option.value}`}
              className="custom-control-input"
              checked={_option.extra}
              onChange={(e) =>
                handleValueChange(
                  'weekdays',
                  getReDaysToStr(
                    DAY_OPTIONS.map((x: LabelValue) => ({
                      ...x,
                      extra: x.value === _option.value ? !x.extra : x.extra,
                    })),
                  ),
                )
              }
            />
            <label
              className="custom-control-label tx-capitalize cursor-pointer"
              htmlFor={`recurrenceDayCheck${_option.value}`}
            >
              <SpanLang keyLang={_option.label} />
            </label>
          </div>
        ))}
      </div>
    );
  }, [scheduleValue]);

  //render monthly
  const renderMonthlyRecurrence = useCallback(() => {
    const dayOptions: LabelValue[] = daysInMonth(new Date().getMonth() + 1);
    const weekOptions: LabelValue[] = getWeekOfMonth(new Date());

    return (
      <>
        <div className="d-flex flex-wrap align-items-center mg-t-10">
          <div className="custom-control custom-radio d-inline-block">
            <input
              type="radio"
              id="recurrenceDayRadio1"
              name="recurrenceDayRadio"
              className="custom-control-input"
              checked={scheduleValue.monthlyOption === 'week'}
              onChange={(e) => handleValueChange('monthlyOption', 'week')}
            />
            <label className="custom-control-label tx-nowrap" htmlFor="recurrenceDayRadio1">
              the week
            </label>
          </div>
          <Select
            outSide={true}
            className="wd-100-f mg-x-10"
            options={weekOptions}
            value={weekOptions.find((_ele: any) => _ele.value === scheduleValue.monthlyWeek)}
            onChange={(newOption: any) => handleValueChange('monthlyWeek', newOption.value)}
          />
          {renderWeekdayOptions()}
        </div>
        <div className="d-flex flex-wrap align-items-center mg-t-10">
          <div className="custom-control custom-radio d-inline-block">
            <input
              type="radio"
              id="recurrenceDayRadio2"
              name="recurrenceDayRadio"
              className="custom-control-input"
              checked={scheduleValue.monthlyOption === 'day'}
              onChange={(e) => handleValueChange('monthlyOption', 'day')}
            />
            <label className="custom-control-label tx-nowrap" htmlFor="recurrenceDayRadio2">
              the day
            </label>
          </div>
          <Select
            outSide
            className="wd-100-f mg-x-10"
            options={dayOptions}
            value={dayOptions.find((_ele: any) => _ele.value === scheduleValue.monthlyDay)}
            onChange={(newOption: any) => handleValueChange('monthlyDay', newOption.value)}
          />
        </div>
        <div className="d-flex flex-wrap align-items-center mg-t-10">
          <div className="custom-control custom-radio d-inline-block">
            <input
              type="radio"
              className="custom-control-input"
              id="recurrenceDayRadio3"
              name="recurrenceDayRadio"
              checked={scheduleValue.monthlyOption === 'last'}
              onChange={(e) => handleValueChange('monthlyOption', 'last')}
            />
            <label className="custom-control-label" htmlFor="recurrenceDayRadio3">
              the last day
            </label>
          </div>
        </div>
      </>
    );
  }, [scheduleValue]);

  //render yearly
  const renderYearlyRecurrence = useCallback(() => {
    const dayOptions: LabelValue[] = daysInMonth(new Date().getMonth() + 1);
    const weekOptions: LabelValue[] = getWeekOfMonth(new Date());

    return (
      <>
        <div className="d-flex flex-wrap align-items-center mg-t-10">
          <div className="custom-control custom-radio d-inline-block">
            <input
              type="radio"
              id="recurrenceYearRadio1"
              name="recurrenceYearRadio"
              className="custom-control-input"
              checked={scheduleValue.yearlyOption === 'week'}
              onChange={(e) => handleValueChange('yearlyOption', 'week')}
            />
            <label className="custom-control-label tx-nowrap" htmlFor="recurrenceYearRadio1">
              the week
            </label>
          </div>
          <Select
            outSide
            className="wd-100-f mg-x-5"
            options={MONTHS}
            value={MONTHS.find((_ele: any) => _ele.value === scheduleValue.yearlyWeekMonth)}
            onChange={(newOption: any) => handleValueChange('yearlyWeekMonth', newOption.value)}
          />
          <Select
            outSide
            className="wd-80-f mg-x-5"
            options={weekOptions}
            value={weekOptions.find((_ele: any) => _ele.value === scheduleValue.yearlyWeek)}
            onChange={(newOption: any) => handleValueChange('yearlyWeek', newOption.value)}
          />
          {renderWeekdayOptions()}
        </div>
        <div className="d-flex flex-wrap align-items-center mg-t-10">
          <div className="custom-control custom-radio d-inline-block">
            <input
              type="radio"
              className="custom-control-input"
              id="recurrenceYearRadio2"
              name="recurrenceYearRadio"
              checked={scheduleValue.yearlyOption === 'day'}
              onChange={(e) => handleValueChange('yearlyOption', 'day')}
            />
            <label className="custom-control-label tx-nowrap" htmlFor="recurrenceYearRadio2">
              the day
            </label>
          </div>
          <Select
            outSide
            className="wd-100-f mg-x-5"
            options={MONTHS}
            value={MONTHS.find((_ele: any) => _ele.value === scheduleValue.yearlyDayMonth)}
            onChange={(newOption: any) => handleValueChange('yearlyDayMonth', newOption.value)}
          />
          <Select
            outSide
            className="wd-80-f mg-x-5"
            options={dayOptions}
            value={dayOptions.find((_ele: any) => _ele.value === scheduleValue.yearlyDay)}
            onChange={(newOption: any) => handleValueChange('yearlyDay', newOption.value)}
          />
        </div>
      </>
    );
  }, [scheduleValue]);

  //render
  return (
    <div className="pos-relative">
      <div className="form-group">
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="repeatTaskSwitch"
            checked={scheduleValue.use}
            onChange={handleRepeatChange}
          />
          <label className="custom-control-label" htmlFor="repeatTaskSwitch">
            Use Repeat
          </label>
        </div>
      </div>
      {scheduleValue.use && (
        <div className="mg-x-5">
          <div className="form-group">
            <label className="form-item-title d-block">Recurrence</label>
            {renderRecurrenceType()}
          </div>
          {scheduleValue.type === RECURRENCE_TYPE.hourly && renderRecurrenceTime()}
          <div className="form-group">
            <label className="form-item-title d-block">Days</label>
            <div className="d-flex flex-column justify-items-center">
              <div className="d-flex flex-wrap align-items-center">
                <span className="mg-r-10">Every</span>
                {scheduleValue.type !== RECURRENCE_TYPE.hourly &&
                  scheduleValue.type !== RECURRENCE_TYPE.daily &&
                  renderRecurrenceEvery()}
                {scheduleValue.type !== RECURRENCE_TYPE.monthly &&
                  scheduleValue.type !== RECURRENCE_TYPE.yearly &&
                  renderWeekdayOptions()}
              </div>
              {scheduleValue.type === RECURRENCE_TYPE.monthly && renderMonthlyRecurrence()}
              {scheduleValue.type === RECURRENCE_TYPE.yearly && renderYearlyRecurrence()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RepeatTimeEdit;
