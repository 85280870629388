import React, { useMemo } from 'react';
import { Button } from '@base/components/form';
import './styles.scss';
import classNames from 'classnames';

interface Props {
  className?: string;
  onSave?: any;
  onClose?: any;
  onDelete?: any;
  onPrimary?: any;
  isValid?: boolean;
  isSaving?: Boolean;
  isDeleting?: Boolean;
  isUpdating?: Boolean;
}

const HanGroupButton: React.FC<Props> = (props) => {
  const {
    className = '',
    onSave,
    onClose,
    onDelete,
    onPrimary,
    isValid,
    isSaving,
    isDeleting,
    isUpdating,
  } = props;

  const disabled = useMemo(
    () => Boolean(isValid || isSaving || isDeleting || isUpdating),
    [isSaving, isDeleting, isUpdating, isValid],
  );

  return (
    <div className={classNames('d-flex crm-han-group-button', className)}>
      {onSave && (
        <div>
          <Button
            className="btn-custom-left ht-30 wd-50"
            size="sm"
            color="success"
            icon="Check"
            iconSize={18}
            onClick={onSave}
            loading={isSaving}
            disabled={disabled}
          />
        </div>
      )}
      {onPrimary && (
        <div>
          <Button
            className="btn-custom ht-30 wd-50"
            size="sm"
            color="info"
            icon="CheckCircle"
            iconSize={18}
            onClick={() => onPrimary()}
            loading={isUpdating}
            disabled={disabled}
          />
        </div>
      )}
      {onDelete && (
        <div>
          <Button
            className="btn-custom ht-30 wd-50"
            size="sm"
            color="danger"
            icon="Trash2"
            iconSize={18}
            onClick={() => onDelete()}
            loading={isDeleting}
            disabled={disabled}
          />
        </div>
      )}
      {onClose && (
        <div>
          <Button
            className="btn-custom-right ht-30 wd-50"
            size="sm"
            color="light"
            icon="X"
            iconSize={18}
            onClick={() => onClose()}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};

export default HanGroupButton;
