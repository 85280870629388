import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Calendar } from "react-feather";
import DateRange from '@base/components/date-picker/range';
import dayjs from 'dayjs';

const DateRangePicKer: React.FC<any> = (props) => {
  const { className, value, onChange, disabled = false, willConvertLocalTime = true, isUtc = false } = props;
  //state
  const [showDropdown, setShowDropdown] = useState(false);
  const defaultRange = { startDate: new Date(), endDate: new Date() };
  const [curRange, setCurRange] = useState<any>(defaultRange);
  const dropdownRef = useRef<any>(null);

  //init data
  useEffect(() => {
    if (value) {
      setCurRange(value);
    } else {
      setCurRange(defaultRange);
    }
  }, [value]);

  //outside click dropdown
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef && !dropdownRef?.current?.contains(event?.target)) {
        setShowDropdown(false);
      }
    };

    window?.addEventListener('mousedown', handleClickOutside);
    return () => {
      window?.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const convertToString = (range: any) => {
    let startDate = dayjs(range?.startDate);
    let endDate = dayjs(range?.endDate);

    if(willConvertLocalTime){
      startDate = startDate.local();
      endDate = endDate.local();
    }

    if(isUtc){
      startDate = startDate.utc();
      endDate = endDate.utc();
    }

    const startDateStr = startDate.format('YYYY-MM-DD');
    const endDateStr = endDate.format('YYYY-MM-DD');

    return startDateStr + ' ~ ' + endDateStr;
  }

  return (
    <div className={classNames("input-group input-date-group dropdown", className)} ref={dropdownRef}>
      <input
        type="text"
        className="form-control ht-40"
        onFocus={() => setShowDropdown(!showDropdown)}
        value={convertToString(curRange)}
        onChange={(e) => null}
        disabled={disabled}
      />
      <div className="input-group-append">
        <span className="input-group-text bg-white tx-gray-300" style={{ borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}>
          <button
            type="button"
            className="btn btn-sm bd-0 pd-0"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <Calendar />
          </button>
        </span>
      </div>
      <div className={classNames('dropdown-menu', { show: showDropdown })}>
        <div className='dropdown-item bg-white'>
          <DateRange
            range={curRange}
            onApply={(newRange: any) => {
              setCurRange(newRange);
              onChange && onChange(newRange);
              setShowDropdown(!showDropdown)
            }}
            onCancel={(oldRange: any) => setShowDropdown(!showDropdown)}
          />
        </div>
      </div>
    </div>
  );
}

export default DateRangePicKer;
