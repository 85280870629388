import React, { useCallback, useMemo } from 'react';
import { Input, Select, SpanLang } from '@base/components/form';
import { DurationValue } from '@base/types/interfaces/common';
import {
  DurationOptions,
  parseDurationValueToSecond,
  parseDurationValueToString,
} from '@base/utils/helpers/date-utils';
import { LabelValue } from '@base/types/types/app';

interface EditProps {
  options?: LabelValue[];
  value: DurationValue;
  onChange: (params: DurationValue) => void;
}

const Edit = (props: EditProps) => {
  const { value = { duration: 1, durationUnit: 'UNIT_DAY' }, onChange, options } = props;
  const termsOptions = options ?? DurationOptions;

  const durationUnit = useMemo(() => {
    return termsOptions.find((duration) => duration.value == value.durationUnit)!!;
  }, [value.durationUnit]);

  const duration = useMemo(() => parseDurationValueToString(value, false), [value]);

  const onHandleChangeInput = useCallback(
    (duration: number) => {
      const newUnit = value.durationUnit;
      const newValue = {
        durationUnit: newUnit,
        duration: parseDurationValueToSecond({
          duration: duration,
          durationUnit: newUnit,
        }),
      };
      onChange && onChange(newValue);
    },
    [value, onChange],
  );

  const onHandleChangeSelect = useCallback(
    (durationUnit: any) => {
      let newValue = {
        durationUnit: durationUnit.value,
        duration: parseDurationValueToSecond({
          duration: Number(duration),
          durationUnit: durationUnit.value,
        }),
      };
      onChange && onChange(newValue);
    },
    [duration, onChange],
  );

  return (
    <div className="d-flex align-items-center">
      <Input
        type={'number'}
        className="mg-r-5 wd-80"
        value={duration}
        onChange={onHandleChangeInput}
      />
      <div className="input-group wd-100">
        <Select
          outSide={true}
          isSearchable={false}
          getOptionLabel={(option: LabelValue) => <SpanLang keyLang={option.label} />}
          options={termsOptions}
          value={durationUnit}
          onChange={onHandleChangeSelect}
        />
      </div>
    </div>
  );
};

export default Edit;
