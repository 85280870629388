import Select from '@base/components/form/select';
import _, { isObject, isUndefined } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface LookupProps {
  value: any;
  onChange: (params: any) => void;
  fieldValue?: string;
  fieldLabel?: string;
  isSearch?: boolean;
  isMultiple?: boolean;
  fetchList: (params: any) => void;
  useDefault?: boolean;
  extraParams?: Object;
  schema?: string;
  defaultOptions?: any[];
}
const Lookup = (props: LookupProps) => {
  const {
    value,
    onChange = (params: any) => {},
    fieldValue = 'value',
    fieldLabel = 'label',
    isSearch = true,
    isMultiple = false,
    fetchList = (params: any, schema?: string, options?: any) => {},
    extraParams = {},
    schema,
    defaultOptions,
  } = props;
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState('');
  const [params, setParams] = useState<any>({
    keyword: keyword,
    ...extraParams,
  });
  const queryOptions = defaultOptions && {
    enabled: false,
  };
  const useParams = !isUndefined(extraParams) && isObject(extraParams);
  const { data, isLoading: queryLoading }: any = useParams
    ? fetchList(params, schema, queryOptions)
    : fetchList(keyword);
  const items = data?.results ?? data?.data;
  const isLoading = defaultOptions ? false : queryLoading;
  const lookupOptions = useMemo(() => {
    if (defaultOptions) {
      return defaultOptions;
    }
    if (items) {
      return items.map((v: any) => ({
        [fieldLabel]: t(v[fieldLabel]),
        [fieldValue]: v[fieldValue],
      }));
    }
    return [];
  }, [items, defaultOptions]);

  const placeholder = 'Select...';
  const onInputChange = (newValue: string, event: any) => {
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      // for template
      if (useParams) {
        setParams({
          keyword: newValue,
          ...extraParams,
        });
      } else {
        // for normal
        setKeyword(newValue);
      }
    }
  };

  const handleValueChange = (data: any) => {
    onChange && onChange(data);
  };

  const defaultValue = useMemo(() => {
    if (!_.isEmpty(value)) {
      if (_.isArray(value)) {
        return value.map((v) => ({
          [fieldLabel]: t(v[fieldLabel]),
          [fieldValue]: v[fieldValue],
        }));
      } else {
        return {
          [fieldLabel]: t(value[fieldLabel]),
          [fieldValue]: value[fieldValue],
        };
      }
    }
    return null;
  }, [value]);

  //render
  return (
    <Select
      onChange={handleValueChange}
      isMulti={isMultiple}
      isSearchable={isSearch}
      isLoading={isLoading}
      placeholder={<div className="react-select-placeholder">{placeholder}</div>}
      options={lookupOptions}
      getOptionLabel={(c: any) => {
        return !isUndefined(c[fieldLabel]) ? c[fieldLabel] : '';
      }}
      getOptionValue={(c: any) => {
        return !isUndefined(c[fieldValue]) ? c[fieldValue] : '';
      }}
      inputValue={keyword}
      // defaultValue={defaultValue}
      value={defaultValue}
      onInputChange={onInputChange}
    />
  );
};

export default Lookup;
