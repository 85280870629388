import React, { useEffect, useState, useCallback } from 'react';
import { components } from 'react-select';
import { Plus, Users } from 'react-feather';
import _ from 'lodash';
import { Button } from '@base/components/form';
import { default as Select } from '@base/components/form/select/async';

const CustomIndicatorStyles = (base: any, state: any) => ({
  ...base,
  cursor: 'pointer',
  color: state.isFocused ? 'blue' : 'black',
});

const DropdownIndicator = (props: { selectProps?: any; getStyles?: any; innerProps?: any }) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
      <div
        style={{ padding: '0px 5px' }}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <a
          className={'tx-gray-500'}
          //href="javascript:void(0)"
          onClick={(e) => {
            props?.selectProps?.onClickIndicator && props?.selectProps?.onClickIndicator(e);
          }}
        >
          {props?.selectProps?.iconIndicator || <Users size={14} />}
        </a>
      </div>
    </div>
  );
};

const LoadingIndicator = (props: any) => {
  return (
    <div className="spinner-border spinner-border-sm mg-r-10" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
};

//add a custom option - add
const CustomOption = ({ children, ...props }: any) => {
  //state
  const [isShowAdd, setIsShowAdd] = useState(false);
  //show add canvas or modal
  const handleShowAdd = () => {
    props.selectProps?.onAdd();
    setIsShowAdd(true);
  };

  //render
  return (
    <>
      {!isShowAdd && (
        <components.Menu {...props}>
          <div>
            {!props.isLoading && (
              <div className="d-flex justify-content-center align-items-center bd-b pd-y-5">
                <Button className="link-03" color="link" onClick={handleShowAdd}>
                  <Plus /> {props.selectProps?.addLabel}
                </Button>
              </div>
            )}
            <div>{children}</div>
          </div>
        </components.Menu>
      )}
    </>
  );
};

//add a custom option - add
const CustomEmptyOption = ({ children, ...props }: any) => {
  //render
  return (
    <>
      {!props.selectProps?.showList && (
        <components.Menu {...props}>
          <div>{children}</div>
        </components.Menu>
      )}
    </>
  );
};

const AsyncSelect = ({
  loadOptions,
  components = {},
  styles = {},
  outSide = true,
  ...props
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  //init default options
  useEffect(() => {
    setDefaultOptions(props.defaultOptions);
  }, [props.defaultOptions]);

  //onFocus event
  const onFocus = () => {
    if (!defaultOptions?.length) {
      setIsLoading(true);

      loadOptions &&
        loadOptions('').then((data: React.SetStateAction<never[]>) => {
          setDefaultOptions(data);
          setIsLoading(false);
        });
    }
  };

  let customComponents = components;

  if (props?.iconIndicator) {
    customComponents.DropdownIndicator = DropdownIndicator;
  }

  if (!customComponents.LoadingIndicator) {
    customComponents.LoadingIndicator = LoadingIndicator;
  }

  //custom option
  if (props?.onAdd && props?.addLabel.length > 0) {
    customComponents.Menu = CustomOption;
  }

  //hide options menu
  if (props?.showList) {
    customComponents.Menu = CustomEmptyOption;
  }

  let stylesComponents = styles;

  if (props?.iconIndicator) {
    stylesComponents.clearIndicator = CustomIndicatorStyles;
  }

  if (outSide) {
    props.menuPortalTarget = document.body;
    stylesComponents.menuPortal = (base: any) => ({
      ...base,
      zIndex: 9999,
    });
  }

  stylesComponents.multiValueLabel = (styles: any, { data }: any) => ({
    ...styles,
    color: 'var(--text-color-tag)',
    fontSize: '0.875rem',
    padding: '1px',
  });

  stylesComponents.multiValueRemove = (styles: any, { data }: any) => ({
    ...styles,
    color: 'var(--text-color-tag)',
    opacity: '0.5',
    '> svg': {
      width: '16px',
      height: '16px',
    },
    '&:hover': {
      color: 'var(--text-color-tag)',
      opacity: '1',
    },
  });

  stylesComponents.multiValue = (styles: any, { data }: any) => {
    return {
      ...styles,
      backgroundColor: 'var(--background-tag)',
      padding: '2px 4px',
      borderRadius: '3px',
    };
  };

  //debounce function
  const waitLoadOptions = async (
    keyword: React.SetStateAction<string>,
    cb: (arg0: any) => void,
  ) => {
    setInputValue(keyword);
    cb(await loadOptions(keyword));
  };

  const debouncedLoadOptions = useCallback(_.debounce(waitLoadOptions, 500), [inputValue]);

  //render
  return (
    <Select
      cacheOptions
      styles={stylesComponents}
      components={customComponents}
      loadOptions={debouncedLoadOptions}
      onFocus={onFocus}
      defaultOptions={defaultOptions}
      isLoading={isLoading}
      {...props}
    />
  );
};

export default AsyncSelect;
