import React, { Fragment, useState, useRef, useEffect } from 'react';
import Select, { components } from 'react-select';
import _ from 'lodash';
import { colors } from './colors';
import { Plus } from 'react-feather';

//add a custom option - add
const CustomOption = ({ children, ...props }: any) => {
  //state
  const [isShowAdd, setIsShowAdd] = useState(false);
  //show add canvas or modal
  const handleShowAdd = () => {
    props.selectProps?.onAdd();
    setIsShowAdd(true);
  };
  //render
  return (
    <>
      {!isShowAdd && (
        <components.Menu {...props}>
          <div>
            {!props.isLoading &&
              props.selectProps?.onAdd &&
              props.selectProps?.addLabel.length > 0 && (
                <div className="d-flex justify-content-center align-items-center bd-b pd-y-5">
                  <button type="button" className="btn btn-link link-03" onClick={handleShowAdd}>
                    <Plus /> {props.selectProps?.addLabel}
                  </button>
                </div>
              )}
            <div>{children}</div>
          </div>
        </components.Menu>
      )}
    </>
  );
};

/**
 * trungtm: add custom option
 * @param {*} param0
 * @returns
 */
const SelectHook = ({
  options,
  width = '100%',
  styles = {},
  ctrlStyles = {},
  outSide,
  ...props
}: any) => {
  const [selected, setSelected] = useState(props.defaultValue || props.selected);
  const el: any = useRef(null);

  const onChange = (obj: any) => {
    setSelected(obj);
    props.onChange && props.onChange(obj);
    if (el.current && $(el.current).parents('form').length) {
      setTimeout(() => {
        // $(el.current).parents('form').valid();
      }, 300);
    }
  };

  useEffect(() => {
    // if (typeof selected === 'object' && selected === props.selected) return;
    if (!props.selected) return;
    let value;

    if (typeof props.selected !== 'object') {
      value = options.find((o: any) => o.value === props.selected);
    } else if (_.isArray(props.selected)) {
      value = [];

      options.forEach((opt: any) => {
        if (
          props.selected.indexOf(opt.value) !== -1 ||
          props.selected.indexOf(opt.value + '') !== -1
        ) {
          value.push(opt);
        }
      });

      //// console.log('value moi ne', value)
    }

    setSelected(value);
  }, [props.selected, options]);

  const initOptions = () => {
    if (!options)
      return [
        {
          value: '',
          label: '',
        },
      ];
    if (options && _.isObject(options[0])) {
      return options;
    }

    return options.map((opt: any) => {
      return {
        value: opt,
        label: opt,
      };
    });
  };

  options = initOptions();

  styles = {
    container: (provided: any) => ({ ...provided, width: width }),
    ...(styles || {}),
    //trungtm
    control: (provided: any, state: any) => ({
      ...provided,
      border: '1px solid var(--input-border-color)',
      //transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      '&:hover': {
        // Overwrittes the different states of border
        color: 'var(--text-color-2)',
        backgroundColor: 'var(--input-focus-color)',
        borderColor: 'var(--input-border-color)',
        outline: '0',
        boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
      },
      ...ctrlStyles,
    }),
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      color: 'var(--text-color-tag)',
      fontSize: '0.875rem',
      padding: '1px',
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      color: 'var(--text-color-tag)',
      opacity: '0.5',
      '> svg': {
        width: '16px',
        height: '16px',
      },
      '&:hover': {
        color: 'var(--text-color-tag)',
        opacity: '1',
      },
    }),
    multiValue: (styles: any, { data }: any) => ({
      ...styles,
      backgroundColor: 'var(--background-tag)',
      padding: '2px 4px',
      borderRadius: '3px',
    }),
  };

  if (outSide) {
    props.menuPortalTarget = document.body;
    styles.menuPortal = (base: any) => ({ ...base, zIndex: 9999 });
  }
  if (!props.defaultValue) props.defaultValue = [];

  //render
  return (
    <Fragment>
      {/*{*/}
      {/*    (_.isArray(selected) && selected.length ? selected : [selected === null ? {} : selected]).map(obj => (*/}
      {/*        <input type={`hidden`} name={props.name} value={obj?.value || ''}/>*/}
      {/*    ))*/}
      {/*}*/}
      <Select
        components={{ Menu: CustomOption }} //trung add 2021-05-28
        options={options}
        value={selected}
        {...props}
        styles={{ ...styles, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
        // menuIsOpen={true}
        theme={(theme) => ({
          ...theme,
          colors,
        })}
        onChange={onChange}
      />

      {props.required ? (
        <input ref={el} type="text" className="input-hide" value={selected} required />
      ) : null}
    </Fragment>
  );
};

export default SelectHook;
