import React from 'react';
import './style.scss';

const LineFullRow: React.FC = () => {
  return (
    <div className="placeholder-paragraph placeholder-full aligned-centered">
      <div className="line"></div>
    </div>
  );
};

export default LineFullRow;
