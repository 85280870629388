import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

interface IListContainer {
  isLoading?: boolean;
  isSplitMode?: boolean;
  isDesktop?: boolean;
  className?: string;
  children?: ReactNode;
}

const ListContainer: FC<IListContainer> = (props) => {
  const { isLoading, isSplitMode, isDesktop, className, children } = props;
  return (
    // <div className={classNames('h-100', { 'Pane vertical xs pane': isSplitMode })}>
    <div className={classNames('h-100', className)}>
      <div className="pos-relative h-100 w-100">
        {isLoading && (
          <div className="wrap-hanloading">
            <div className="han-loading" />
          </div>
        )}
        <div className="d-flex h-100">
          <div className="w-100 mn-ht-300">
            <div
              className={classNames('w-100 h-100', {
                //desktop: isDesktop,
                //'mn-ht-800': isDesktop,
                // is_SplitMode: isSplitMode,
              })}
            >
              <div className={classNames('list-wrap', { 'split-mode': isSplitMode })}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListContainer;
