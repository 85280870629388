import React, { useCallback, useState } from 'react';
import { HelpCircle, LogOut, Settings, Bell, Search, X, Plus, Image } from 'react-feather';
import { FormIcon } from '@base/components/form/icon';
import PreferenceMenu from './preference-setting/preference-menu';
import { useOAuthService } from '@sign-in/services/service';
import imageNoPic from '@base/assets/img/no_avatar.png';

/**
 * Main Header
 * @param {*} param0
 * @returns
 */
const HeaderTop = () => {
  //state
  const [showSetting, setShowSetting] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const oauthService = useOAuthService();

  const onLogOut = useCallback(() => {
    // id.habin.io logout
    // need to set cors in api server
    // $.post(`${OAUTH_SERVER}/sign-out?continue=${window.location.href}`);
    // postApi(
    //   `${OAUTH_SERVER}/sign-out?continue=${window.location.href}`,
    //   {},
    //   {
    //     headers: {
    //       'Content-Type': 'application/x-www-form-urlencoded',
    //     },
    //   });
    oauthService.logout();
  }, []);

  // console.log('...HEADER.TOP...');
  return (
    <>
      <div className="content-header navbar-header">
        {/* left side */}
        <nav className="nav navbar-left">
          <div className="navbar-brand">
            <a className="df-logo">Customer Name</a>
          </div>
          <button
            type="button"
            className="btn btn-primary btn-icon rounded-circle btn-add shadow-sm mg-r-20 han-tooltip han-tooltip--bottom"
            data-han-tooltip="New"
          >
            <Plus />
          </button>
          <a className="nav-link mg-r-15 han-tooltip han-tooltip--bottom" data-han-tooltip="Recent">
            <FormIcon icon="recent" iconType="icon" />
          </a>
          <a
            className="search-link link-02 han-tooltip han-tooltip--bottom"
            data-han-tooltip="Search"
            onClick={() => setShowSearch((showSearch) => !showSearch)}
          >
            <Search />
          </a>
        </nav>
        {/* right side */}
        <nav className="nav navbar-right">
          <div className="dropdown dropdown-notification">
            <a
              className="nav-link dropdown-link new-indicator han-tooltip han-tooltip--bottom"
              data-han-tooltip="Notification"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <Bell />
              <span>2</span>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <div className="dropdown-header">Notifications</div>
              <a className="dropdown-item">
                <div className="media">
                  <div className="avatar avatar-sm avatar-online">
                    <Image />
                  </div>
                  <div className="media-body mg-l-15">
                    <p>
                      Congratulate <strong>Socrates Itumay</strong> for work anniversaries
                    </p>
                    <span>Mar 15 12:32pm</span>
                  </div>
                </div>
              </a>
              <a className="dropdown-item">
                <div className="media">
                  <div className="avatar avatar-sm avatar-online">
                    <Image />
                  </div>
                  <div className="media-body mg-l-15">
                    <p>
                      Congratulate <strong>Socrates Itumay</strong> for work anniversaries
                    </p>
                    <span>Mar 15 12:32pm</span>
                  </div>
                </div>
              </a>
              <div className="dropdown-footer">
                <a>View all Notifications</a>
              </div>
            </div>
          </div>
          <a
            className="nav-link han-tooltip han-tooltip--bottom"
            data-han-tooltip="Setting"
            onClick={() => setShowSetting(true)}
          >
            <Settings />
          </a>
          <a className="nav-link han-tooltip han-tooltip--bottom" data-han-tooltip="Help">
            <HelpCircle />
          </a>
          <div
            className="avatar avatar-sm mg-l-15 han-tooltip han-tooltip--bottom"
            data-han-tooltip="User"
          >
            <img src={imageNoPic} className="rounded-circle" alt="" />
          </div>
          <a className="nav-link han-tooltip han-tooltip--bottom" data-han-tooltip="Logout">
            <LogOut onClick={onLogOut} />
          </a>
        </nav>

        {/* navigation search */}
        <div className={`navbar-search mg-r-60 ${showSearch ? 'visible' : ''}`}>
          <div className="navbar-search-header">
            <input
              type="search"
              className="form-control"
              placeholder="Type and hit enter to search..."
            />
            <button className="btn">
              <Search />
            </button>
            <a
              id="navbarSearchClose"
              className="link-03 mg-l-5 mg-lg-l-10"
              onClick={() => setShowSearch(false)}
            >
              <X />
            </a>
          </div>
          <div className="navbar-search-body">
            <label className="tx-10 tx-medium tx-uppercase tx-spacing-1 tx-color-03 mg-b-10 d-flex align-items-center">
              Recent Searches
            </label>
            <ul className="list-unstyled">
              <li>
                <a>modern dashboard</a>
              </li>
              <li>
                <a>calendar app</a>
              </li>
              <li>
                <a>modal examples</a>
              </li>
              <li>
                <a>avatar</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* setting right canvas */}
      {showSetting && <PreferenceMenu isShow={showSetting} onClose={() => setShowSetting(false)} />}

      {/* backdrop */}
      <div className={`backdrop ${showSearch ? 'show' : ''}`}></div>
    </>
  );
};

export default HeaderTop;
