import RadioGroup from '@base/components/form/radio-group';
import classNames from 'classnames';
import React from 'react';

interface RadioEditProps {
  value: any; //OptionValue;
  onChange: (params: any) => {};
  errors: any;
  isHorizontal: boolean;
  options: any[]; //OptionValue[];
  fieldValue?: string;
  fieldLabel?: string;
  componentProps?: any;
}

const RadioEdit = (props: RadioEditProps) => {
  const {
    value,
    onChange,
    errors,
    isHorizontal = true,
    options,
    fieldValue = 'keyName',
    fieldLabel = 'languageKey',
    componentProps,
  } = props;

  return (
    <div className={classNames('crm-common-radio-button')}>
      <div
        className={classNames({
          'is-required': errors?.isRequired,
        })}
      >
        <RadioGroup
          isVertical={!isHorizontal}
          options={options}
          fieldValue={fieldValue}
          fieldLabel={fieldLabel}
          value={value}
          onChange={(data) => onChange(data)}
          {...componentProps}
        />
      </div>
      {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
    </div>
  );
};

export default RadioEdit;
