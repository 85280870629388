import React, { useEffect, useState } from 'react';
import TaskSequenceForm from '@activity/containers/sequence-form';

const Edit: React.FC = (props: any) => {
  const { value } = props;

  return <TaskSequenceForm className="pd-10" mode={'r'} value={value} />;
};

export default Edit;
