import React, { Fragment, FC, useEffect, useState, ReactNode, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import {
  getAvailabelCountriesApi,
  getAvailabelCurrenciesApi,
  getAvailabelLanguagesApi,
  useGetAvailabelCountriesApi,
  useGetAvailabelCurrenciesApi,
  useGetAvailabelLanguagesApi,
  useListPageSettings,
  useUserSetting,
  useUserSettings,
} from '@base/services';
import {
  countriesAtom,
  currenciesAtom,
  languagesAtom,
  phonesAtom,
  defaultPhoneAtom,
  pinSubMenuSettingsAtom,
  deviceAtom,
  listFavoriteAtom,
} from '@base/recoil/atoms';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLanguageByMenu } from '@base/services/i18n';
import LanguageTranslation from '@base/containers/language-translation';

import MenuAside from './menu.aside';
import HeaderTop from './header.top';

interface RootLayoutProps {
  children?: ReactNode;
}

const RootLayout: FC<RootLayoutProps> = ({ children }) => {
  // const { children } = props;

  const setCountries = useSetRecoilState(countriesAtom);
  const setCurrencies = useSetRecoilState(currenciesAtom);
  const setLanguages = useSetRecoilState(languagesAtom);
  const setPhones = useSetRecoilState(phonesAtom);
  const setDefaultPhone = useSetRecoilState(defaultPhoneAtom);
  const setPinSubMenuSettings = useSetRecoilState(pinSubMenuSettingsAtom);

  const [deviceSettings, setDeviceSettings] = useRecoilState(deviceAtom);
  const [favoriteList, setFavoriteList] = useRecoilState(listFavoriteAtom);

  // load menu language
  useLanguageByMenu(['common', 'pagelayout', 'generalsetting']);
  // const { data: countryReps, isLoading: isLoadingCountry } = useGetAvailabelCountriesApi();
  // const { data: currencyReps, isLoading: isLoadingCur } = useGetAvailabelCurrenciesApi();
  // const { data: langReps, isLoading: isLoadingLang } = useGetAvailabelLanguagesApi();

  // // user common setting
  // // listFavorite, pin menu, theme, ...
  // const { data: commonSettings, isLoading: isLoadingCommonSettings } = useUserSettings('common');

  // /** ========================      INIT SETTINGS DATA FOR APP ================================= */
  // useEffect(() => {
  //   // countries, phones;
  //   if (countryReps && !isLoadingCountry) {
  //     let _countries: any = [];
  //     let phones: any = [];
  //     countryReps.results?.map((country) => {
  //       _countries.push({
  //         ...country,
  //         value: country?.isoCode2,
  //         label: country?.country,
  //       });
  //       phones.push({
  //         value: country?.phoneCode,
  //         label: country?.phoneCode,
  //         isDefault: country?.isDefault,
  //       });
  //     });
  //     setCountries(_countries);
  //     let defaultPhone = phones?.find((item: any) => item?.isDefault);
  //     setDefaultPhone(defaultPhone);
  //     setPhones(phones);
  //   }
  // }, [countryReps]);
  // useEffect(() => {
  //   // currency
  //   if (currencyReps && !isLoadingCur) {
  //     let _currencies: any = [];
  //     currencyReps.results?.map((currency: any) => {
  //       _currencies.push({
  //         ...currency,
  //         value: currency?.code,
  //         label: currency?.code,
  //       });
  //     });
  //     setCurrencies(_currencies);
  //   }
  // }, [currencyReps]);
  // useEffect(() => {
  //   // currency
  //   if (langReps && !isLoadingLang) {
  //     let _languages: any = [];
  //     langReps.results?.map((language) => {
  //       _languages.push({
  //         ...language,
  //         value: language?.code,
  //         label: language?.title,
  //       });
  //     });
  //     setLanguages(_languages);
  //   }
  // }, [langReps]);

  // // common settings
  // useEffect(() => {
  //   if (commonSettings && !isLoadingCommonSettings) {
  //     commonSettings?.results?.map((config: any) => {
  //       if (config?.key === 'menu_favorites') {
  //         setFavoriteList(JSON.parse(config?.value) ?? []);
  //       } else if (config?.key === 'pin_submenu') {
  //         setPinSubMenuSettings(JSON.parse(config?.value) ?? null);
  //       } else if (config?.key === 'general') {
  //         // setDeviceSettings(JSON.parse(config?.value) ?? null);
  //       }
  //     });
  //   }
  // }, [commonSettings, isLoadingCommonSettings]);

  //state
  const [isExpand, setIsExpand] = useState(false);

  //translate context - disable on PRODUCTION - use user config
  //// console.log('deviceSettings', deviceSettings);
  const enableTrans = false; //deviceSettings?.enableTrans || false;
  let TranslationContext =
    enableTrans && process.env.APP_ENV !== 'production' ? LanguageTranslation : Fragment;

  return (
    <TranslationContext>
      <div className="h-100">
        <div className="content" style={{ paddingLeft: isExpand ? '200px' : '60px' }}>
          {/* header */}
          <HeaderTop />
          {/* content */}
          <div className="content-body">
            {children}
            {/* An <Outlet> renders whatever child route is currently active,
                    so you can think about this <Outlet> as a placeholder for
                    the child routes we defined above. */}
            <Outlet />
          </div>
          <MenuAside isExpand={isExpand} setIsExpand={setIsExpand} />
        </div>
      </div>
    </TranslationContext>
  );
};

export default RootLayout;
