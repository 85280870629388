import { useState } from 'react';
import { Clock, Edit2, Trash, Check, X } from 'react-feather';

import Avatar from '@base/components/avatar';
import { Button } from '@base/components/form';
import { Note, NoteForm } from '@base/types/interfaces/note';
import { useTranslation } from 'react-i18next';
import useMutationPost from '@base/hooks/useMutationPost';
import { NOTE_UPDATE_ITEM, NOTE_DELETE_ITEM } from '@base/services/graphql/note';
import { useQueryClient } from '@tanstack/react-query'; //v4
import TuiEditor from '@base/components/form/tui-editor';
import { convertDateTimeServerToClient } from '@base/utils/helpers';
import React from 'react';

interface ItemProps {
  data: Note;
  source: string;
  sourceId: string;
}

function NoteItem({ data, source, sourceId }: ItemProps) {
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editNote, setEditNote] = useState(data.content);

  const queryClient = useQueryClient();

  const updateMutation: any = useMutationPost<Note>(NOTE_UPDATE_ITEM, 'builtin_updateNote', {
    onMutate: () => {
      setIsSaving(true);
    },
    onSuccess: () => {
      // setEditNote('');
      setIsSaving(false);
      setIsEdit(false);
      // setTimeout(() => {
      //   queryClient.invalidateQueries(['builtin_notes']);
      // }, 500);
    },
  });

  const deleteMutation: any = useMutationPost<Note>(NOTE_DELETE_ITEM, 'builtin_deleteNote', {
    onMutate: () => {
      setIsSaving(true);
    },
    onSuccess: () => {
      // setEditNote('');
      setIsSaving(false);
      setTimeout(() => {
        queryClient.invalidateQueries(['builtin_notes']);
      }, 500);
    },
  });

  //update note
  const onUpdateNote = () => {
    if (editNote) {
      const note: NoteForm = {
        id: data.id,
        source: {
          menu: source,
          id: sourceId,
        },
        content: editNote,
      };
      updateMutation.mutate({ note: note });
    }
  };

  // delete
  const onDeleteNote = () => {
    deleteMutation.mutate({ id: data.id });
  };

  return (
    <div className="timeline-item">
      <div className="timeline-icon">
        <Avatar url={data?.createdBy?.photo} alt={data?.createdBy?.name} width={35} height={35} />
      </div>
      <div className="timeline-body">
        <div className="card">
          <div className="d-flex flex-wrap align-items-center pd-x-15 pd-y-5 bd-b">
            <span>
              {data.updatedBy?.name}
              <span className="mg-l-10 tx-color-03">
                <>
                  <Clock size="16" className="mg-r-5" />
                  {convertDateTimeServerToClient({ date: data.createdAt, isTime: true })}
                </>
              </span>
            </span>
            <div className="mg-l-auto">
              <button
                type="button"
                className="btn btn-link btn-icon"
                data-han-tooltip="Edit"
                onClick={() => setIsEdit(true)}
              >
                <Edit2 />
                <span className="sr-only">수정</span>
              </button>
              <button
                type="button"
                className="btn btn-link btn-icon"
                data-han-tooltip="Delete"
                onClick={() => {
                  onDeleteNote();
                }}
              >
                {isSaving ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <Trash className="tx-danger" />
                )}
                <span className="sr-only">삭제</span>
              </button>
            </div>
          </div>
          <div className="pd-15">
            {isEdit ? (
              <div className="input-area">
                <TuiEditor
                  value={editNote}
                  onChange={(newValue: string) => {
                    setEditNote(newValue);
                  }}
                  placeholder={'Enter a note'}
                  editorProps={{ height: '200px' }}
                />
                <span className="btn-group btn-actions-group mg-t-5">
                  <Button
                    className="btn btn-sm btn-success"
                    name="Save"
                    disabled={editNote == ''}
                    onClick={onUpdateNote}
                    loading={isSaving}
                  >
                    <Check />
                  </Button>
                  <Button
                    className="btn btn-sm btn-light"
                    name="Cancel"
                    onClick={() => setIsEdit(false)}
                  >
                    <X />
                  </Button>
                </span>
              </div>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: editNote }} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(NoteItem);
