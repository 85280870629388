import _ from 'lodash';

export function validateEmail(email: string) {
  let re = /\S+@\S+\.\S+/;

  return re.test(email);
}

export function isNumeric(value: string) {
  return /^-?\d+$/.test(value);
}

export const getTextLength = function (str: string) {
  var len = 0;
  for (var i = 0; i < str.length; i++) {
    if (_.escape(str.charAt(i)).length == 6) {
      len++;
    }
    len++;
  }
  return len;
};
