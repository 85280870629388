import React from 'react';

const png = () => {
  return (
    <svg id="file-type-png" xmlns="http://www.w3.org/2000/svg" width="42px" height="51px" viewBox="0 0 42 51">
      <g>
        <path fill="#56B5D1" d="M42,49c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h32l8,8V49z" />
        <polygon fill="#44A2BF" points="42.003,14.828 34.615,7.44 42,8 	" />
        <g>
          <path fill="#8FCADE" d="M42,8h-6c-1.104,0-2-0.896-2-2V0L42,8z" />
        </g>
      </g>
      <g>
        <path
          fill="#56B5D1"
          d="M42,46c0,0.553-0.447,1-1,1H19c-0.552,0-1-0.447-1-1V30c0-0.553,0.448-1,1-1h22c0.553,0,1,0.447,1,1V46z"
        />
        <path
          fill="#55B4D0"
          d="M41.765,45.735c0,0.552-0.447,1-1,1h-22c-0.552,0-1-0.448-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V45.735z"
        />
        <path
          fill="#55B3CF"
          d="M41.529,45.471c0,0.553-0.447,1-1,1h-22c-0.552,0-1-0.447-1-1v-16c0-0.553,0.448-1,1-1h22
                c0.553,0,1,0.447,1,1V45.471z"
        />
        <path
          fill="#54B2CE"
          d="M41.294,45.206c0,0.552-0.447,1-1,1h-22c-0.552,0-1-0.448-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V45.206z"
        />
        <path
          fill="#53B1CD"
          d="M41.059,44.941c0,0.553-0.447,1-1,1h-22c-0.552,0-1-0.447-1-1v-16c0-0.553,0.448-1,1-1h22
                c0.553,0,1,0.447,1,1V44.941z"
        />
        <path
          fill="#53B0CC"
          d="M40.823,44.676c0,0.553-0.447,1-1,1h-22c-0.552,0-1-0.447-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V44.676z"
        />
        <path
          fill="#52AFCB"
          d="M40.588,44.412c0,0.552-0.447,1-1,1h-22c-0.552,0-1-0.448-1-1v-16c0-0.553,0.448-1,1-1h22
                c0.553,0,1,0.447,1,1V44.412z"
        />
        <path
          fill="#51AECA"
          d="M40.353,44.146c0,0.553-0.447,1-1,1h-22c-0.552,0-1-0.447-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V44.146z"
        />
        <path
          fill="#51ADC9"
          d="M40.117,43.883c0,0.552-0.447,1-1,1h-22c-0.552,0-1-0.448-1-1v-16c0-0.553,0.448-1,1-1h22
                c0.553,0,1,0.447,1,1V43.883z"
        />
        <path
          fill="#50ADC8"
          d="M39.883,43.617c0,0.553-0.447,1-1,1h-22c-0.552,0-1-0.447-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V43.617z"
        />
        <path
          fill="#4FACC7"
          d="M39.647,43.354c0,0.552-0.447,1-1,1h-22c-0.552,0-1-0.448-1-1v-16c0-0.553,0.448-1,1-1h22
                c0.553,0,1,0.447,1,1V43.354z"
        />
        <path
          fill="#4FABC6"
          d="M39.412,43.088c0,0.553-0.447,1-1,1h-22c-0.552,0-1-0.447-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V43.088z"
        />
        <path
          fill="#4EAAC5"
          d="M39.177,42.824c0,0.552-0.447,1-1,1h-22c-0.552,0-1-0.448-1-1v-16c0-0.553,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V42.824z"
        />
        <path
          fill="#4DA9C4"
          d="M38.941,42.559c0,0.553-0.447,1-1,1h-22c-0.552,0-1-0.447-1-1v-16c0-0.553,0.448-1,1-1h22
                c0.553,0,1,0.447,1,1V42.559z"
        />
        <path
          fill="#4DA8C3"
          d="M38.706,42.294c0,0.552-0.447,1-1,1h-22c-0.552,0-1-0.448-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V42.294z"
        />
        <path
          fill="#4CA7C2"
          d="M38.471,42.029c0,0.553-0.447,1-1,1h-22c-0.552,0-1-0.447-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V42.029z"
        />
        <path
          fill="#4BA6C1"
          d="M38.235,41.765c0,0.552-0.447,1-1,1h-22c-0.552,0-1-0.448-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V41.765z"
        />
        <path
          fill="#4BA5C1"
          d="M38,41.5c0,0.553-0.447,1-1,1H15c-0.552,0-1-0.447-1-1v-16c0-0.552,0.448-1,1-1h22c0.553,0,1,0.448,1,1
                V41.5z"
        />
        <path
          fill="#4AA4C0"
          d="M37.765,41.235c0,0.552-0.447,1-1,1h-22c-0.552,0-1-0.448-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V41.235z"
        />
        <path
          fill="#49A3BF"
          d="M37.529,40.971c0,0.553-0.447,1-1,1h-22c-0.552,0-1-0.447-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V40.971z"
        />
        <path
          fill="#48A2BE"
          d="M37.294,40.706c0,0.552-0.447,1-1,1h-22c-0.552,0-1-0.448-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V40.706z"
        />
        <path
          fill="#48A1BD"
          d="M37.059,40.441c0,0.553-0.447,1-1,1h-22c-0.552,0-1-0.447-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V40.441z"
        />
        <path
          fill="#47A0BC"
          d="M36.823,40.176c0,0.553-0.447,1-1,1h-22c-0.552,0-1-0.447-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V40.176z"
        />
        <path
          fill="#469FBB"
          d="M36.588,39.912c0,0.552-0.447,1-1,1h-22c-0.552,0-1-0.448-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V39.912z"
        />
        <path
          fill="#469EBA"
          d="M36.353,39.646c0,0.553-0.447,1-1,1h-22c-0.552,0-1-0.447-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V39.646z"
        />
        <path
          fill="#459DB9"
          d="M36.117,39.383c0,0.552-0.447,1-1,1h-22c-0.552,0-1-0.448-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V39.383z"
        />
        <path
          fill="#449DB8"
          d="M35.883,39.117c0,0.553-0.447,1-1,1h-22c-0.552,0-1-0.447-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V39.117z"
        />
        <path
          fill="#449CB7"
          d="M35.647,38.854c0,0.552-0.447,1-1,1h-22c-0.552,0-1-0.448-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V38.854z"
        />
        <path
          fill="#439BB6"
          d="M35.412,38.588c0,0.553-0.447,1-1,1h-22c-0.552,0-1-0.447-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V38.588z"
        />
        <path
          fill="#429AB5"
          d="M35.177,38.324c0,0.552-0.447,1-1,1h-22c-0.552,0-1-0.448-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V38.324z"
        />
        <path
          fill="#4299B4"
          d="M34.941,38.059c0,0.553-0.447,1-1,1h-22c-0.552,0-1-0.447-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V38.059z"
        />
        <path
          fill="#4198B3"
          d="M34.706,37.794c0,0.552-0.447,1-1,1h-22c-0.552,0-1-0.448-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V37.794z"
        />
        <path
          fill="#4097B2"
          d="M34.471,37.529c0,0.553-0.447,1-1,1h-22c-0.552,0-1-0.447-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V37.529z"
        />
        <path
          fill="#4096B1"
          d="M34.235,37.265c0,0.552-0.447,1-1,1h-22c-0.552,0-1-0.448-1-1v-16c0-0.552,0.448-1,1-1h22
                c0.553,0,1,0.448,1,1V37.265z"
        />
        <path
          fill="#3F95B0"
          d="M34,37c0,0.553-0.447,1-1,1H11c-0.552,0-1-0.447-1-1V21c0-0.552,0.448-1,1-1h22c0.553,0,1,0.448,1,1V37z"
        />
      </g>
      <path
        fill="#3B8AA3"
        d="M34,38c0,0.553-0.447,1-1,1H11c-0.552,0-1-0.447-1-1V21c0-0.552,0.448-1,1-1h22c0.553,0,1,0.448,1,1V38z"
      />
      <rect x="11" y="21" fill="#56B5D1" width="20" height="13" />
      <g>
        <g>
          <rect x="11" y="21" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="15" y="21" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="19" y="21" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="23" y="21" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="27" y="21" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
        </g>
        <g>
          <rect x="11" y="25" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="15" y="25" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="19" y="25" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="23" y="25" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="27" y="25" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
        </g>
        <g>
          <rect x="11" y="28.999" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="15" y="28.999" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="19" y="28.999" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="23" y="28.999" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="27" y="28.999" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
        </g>
        <g>
          <rect x="11" y="32.998" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="15" y="32.998" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="19" y="32.998" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="23" y="32.998" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="27" y="32.998" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
        </g>
        <g>
          <rect x="13" y="23" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="17" y="23" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="21" y="23" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="25" y="23" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="29" y="23" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
        </g>
        <g>
          <rect x="13" y="27" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="17" y="27" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="21" y="27" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="25" y="27" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="29" y="27" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
        </g>
        <g>
          <rect x="13" y="31" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="17" y="31" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="21" y="31" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="25" y="31" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
          <rect x="29" y="31" opacity="0.5" fill="#FFFFFF" width="2" height="2" />
        </g>
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M32,19H10c-0.552,0-1,0.448-1,1v17.23c0,0.553,0.448,1,1,1h22c0.553,0,1-0.447,1-1V20
                C33,19.448,32.553,19,32,19z M31,21v12.576c-1.355-0.316-4.121-0.525-6.119-0.576l-0.254-3.107c0.255,0.117,0.533,0.19,0.832,0.19
                c1.104,0,2.001-0.89,2.001-1.986c0-0.685-0.349-1.286-0.879-1.644c-0.255-1.355-1.377-2.376-2.726-2.376
                c-1.135,0-2.105,0.725-2.538,1.761c-0.847,0.332-1.444,1.129-1.444,2.063c0,1.23,1.035,2.229,2.313,2.229
                c0.359,0,0.695-0.085,0.998-0.225c0.005,0.001,0.01,0.002,0.015,0.002L22.947,33c-3.522,0.079-9.291,0.552-11.947,1.215V21H31z"
        />
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M14.137,9.281h-0.819v1.471h-1.079V5.849h2.003c1.17,0,1.877,0.693,1.877,1.695v0.014
                C16.119,8.693,15.237,9.281,14.137,9.281z M15.027,7.565c0-0.483-0.336-0.743-0.875-0.743h-0.833v1.499h0.854
                c0.539,0,0.854-0.322,0.854-0.743V7.565z"
        />
        <path
          fill="#FFFFFF"
          d="M21.055,10.752L18.68,7.635v3.117h-1.064V5.849h0.995l2.296,3.019V5.849h1.065v4.903H21.055z"
        />
        <path
          fill="#FFFFFF"
          d="M26.205,10.836c-1.527,0-2.578-1.071-2.578-2.521V8.301c0-1.394,1.086-2.536,2.57-2.536
                c0.883,0,1.416,0.238,1.927,0.672l-0.679,0.819C27.066,6.942,26.73,6.76,26.163,6.76c-0.784,0-1.407,0.693-1.407,1.527v0.014
                c0,0.896,0.615,1.555,1.484,1.555c0.393,0,0.742-0.098,1.016-0.294V8.861H26.17V7.929h2.129v2.129
                C27.795,10.486,27.102,10.836,26.205,10.836z"
        />
      </g>
    </svg>
  );
};

export default png;
