import { useEffect } from 'react';
import {
  s3CreateUploadApi,
  s3UploadPartApi,
  s3CompleteUploadApi,
  s3GetPresignedUrlPartsApi,
  s3GetDownloadObjectPresignedUrlApi,
  s3DownloadObjectApi,
  s3DeleteObjectApi,
  s3AbortUploadApi,
  storageCreateUploadApi,
  storageCreateDownloadApi,
  storageCreateChunkUploadApi,
  storageCompleteUploadApi,
  storageChunkUploadPartApi,
} from '@base/utils/axios/graphql';
import { IResponseError } from '@base/utils/axios/helper';
import { isString } from 'lodash';
//import { useMutation, UseMutationResult } from 'react-query';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { toast } from 'react-toastify';

/**============================ S3 STORAGE =============================== */

export function useUploadMutation<T>(
  options?: any,
  progressCallBack?: any,
): UseMutationResult<T, unknown, any, unknown> {
  const FILE_CHUNK_SIZE = 5 * 1024 * 1024; //minimum size: 5MB

  const mutation = useMutation(async (file: any) => {
    //// console.log('file', file);
    let uploadParts: any[] = [];
    let curChunkIndex = 0;
    const fileSize = file.size;
    const maxChunks = Math.ceil(fileSize / FILE_CHUNK_SIZE) - 1;

    //get each binary part
    function readCurrentChunkAsync(curChunk: number, file: any) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        const startPos = curChunk * FILE_CHUNK_SIZE;
        const endPos = startPos + FILE_CHUNK_SIZE;
        const blob = file.slice(startPos, endPos);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }

    //// console.log('maxChunks', maxChunks);
    while (curChunkIndex <= maxChunks) {
      const chunkBuffer = await readCurrentChunkAsync(curChunkIndex, file);
      uploadParts.push({ data: chunkBuffer });
      curChunkIndex++;
    }
    //// console.log('uploadParts', uploadParts);

    //1. create upload id
    const createParams = { filename: file.name, mediaType: file.type };
    const createResp: any = await s3CreateUploadApi<T>(createParams);
    const uploadId = createResp.id;
    //// console.log('create Resp', createResp);

    //2. get presigned url for parts
    const uploadPartParams = {
      uploadId,
      partNumbers: uploadParts.map((_ele: any, index: number) => index + 1),
    };
    const uploadPartResp: any = await s3GetPresignedUrlPartsApi<T>(uploadPartParams);
    //// console.log('upload parts partResp', uploadPartResp);
    const uploadUrls: any[] = Object.values(uploadPartResp?.uploadUrls) || [];
    if (uploadUrls.length > 0) {
      uploadParts = uploadParts.map((_part: any, index: number) => {
        _part.url = uploadUrls[index].url;
        return _part;
      });
    }
    //// console.log('uploadParts after get url', uploadParts);

    //3. upload parts
    const promises: any = [];
    uploadParts.map((_part: any, index: any) => {
      promises.push(
        s3UploadPartApi<T>(
          _part.url,
          _part.data,
          (pEvent: ProgressEvent) =>
            progressCallBack && progressCallBack(pEvent, uploadParts.length, index, uploadId),
        ),
      );
    });
    const uploadPartResps = await Promise.all(promises);
    //// console.log('uploaded parts res', uploadPartResps);
    uploadParts = uploadParts.map((_part: any, index: any) => {
      _part.etag = uploadPartResps[index].etag;
      return _part;
    });
    //// console.log('uploadParts after upload part', uploadParts);

    //4. complete upload
    const completeParams = {
      id: uploadId,
      parts: uploadParts.map((_ele: any, index: number) => ({
        number: index + 1,
        etag: _ele.etag,
      })),
    };
    const completeReps = await s3CompleteUploadApi<T>(completeParams);
    //// console.log('completeReps', completeReps);
    return completeReps;
  }, options);

  useEffect(() => {
    if (isString(mutation.error)) {
      const err = JSON.parse(mutation.error) as IResponseError;
      if (['no_authentication', 'server_error'].includes(err.message)) {
        // Ls.remove('token');
        // setAuth(null);
        // // console.log('logout');
      }
    }
  }, [mutation.error]);

  return mutation;
}

export function useAbortUploadMutation<T>(
  options?: any,
): UseMutationResult<T, unknown, string, unknown> {
  const mutation = useMutation(async (uploadId: string) => {
    const params = {
      id: uploadId,
    };
    //get blob
    const abortResp = await s3AbortUploadApi<T>(params);

    return abortResp;
  }, options);

  useEffect(() => {
    if (isString(mutation.error)) {
      const err = JSON.parse(mutation.error) as IResponseError;
      if (['no_authentication', 'server_error'].includes(err.message)) {
        // Ls.remove('token');
        // setAuth(null);
        // // console.log('logout');
      }
    }
  }, [mutation.error]);

  return mutation;
}

export function useDownloadObjectMutation<T>(
  options?: any,
  progressCallBack?: any,
): UseMutationResult<T, unknown, any, unknown> {
  const mutation = useMutation(async (object: any) => {
    let url: string = object?.url;
    if (!url) {
      //get presigned url
      const getParams = {
        id: object.id,
      };
      const presignedResp: any = await s3GetDownloadObjectPresignedUrlApi<T>(getParams);
      url = presignedResp.downloadUrl.url;
    }

    //get blob
    const downloadResp = await s3DownloadObjectApi<T>(url, progressCallBack);

    return downloadResp;
  }, options);

  useEffect(() => {
    if (isString(mutation.error)) {
      const err = JSON.parse(mutation.error) as IResponseError;
      if (['no_authentication', 'server_error'].includes(err.message)) {
        // Ls.remove('token');
        // setAuth(null);
        // // console.log('logout');
      }
    }
  }, [mutation.error]);

  return mutation;
}

export function useDeleteObjectMutation<T>(
  options?: any,
): UseMutationResult<T, unknown, any, unknown> {
  const mutation = useMutation(async (object: any) => {
    const params = {
      id: object.id,
    };
    //get blob
    const deleteResp = await s3DeleteObjectApi<T>(params);

    return deleteResp;
  }, options);

  useEffect(() => {
    if (isString(mutation.error)) {
      const err = JSON.parse(mutation.error) as IResponseError;
      if (['no_authentication', 'server_error'].includes(err.message)) {
        // Ls.remove('token');
        // setAuth(null);
        // // console.log('logout');
      }
    }
  }, [mutation.error]);

  return mutation;
}

/**============================ BLOCK STORAGE =============================== */

export function useStorageUploadMutation<T>(
  options?: any,
): UseMutationResult<T, unknown, string, unknown> {
  const mutation = useMutation(async (formData: any) => {
    //get blob
    const uploadResp = await storageCreateUploadApi<T>(formData);

    return uploadResp;
  }, options);

  useEffect(() => {
    if (isString(mutation.error)) {
      const err = JSON.parse(mutation.error) as IResponseError;
      if (['no_authentication', 'server_error'].includes(err.message)) {
        // Ls.remove('token');
        // setAuth(null);
        // // console.log('logout');
      }
    }
  }, [mutation.error]);

  return mutation;
}

export function useStorageDownloadMutation<T>(
  options?: any,
  progressCallBack?: any,
): UseMutationResult<T, unknown, any, unknown> {
  const mutation = useMutation(async (params: any) => {
    //get blob
    const downloadResp = await storageCreateDownloadApi<T>(params, progressCallBack);

    return downloadResp;
  }, options);

  useEffect(() => {
    if (isString(mutation.error)) {
      const err = JSON.parse(mutation.error) as IResponseError;
      if (['no_authentication', 'server_error'].includes(err.message)) {
        // Ls.remove('token');
        // setAuth(null);
        // // console.log('logout');
      }
    }
  }, [mutation.error]);

  return mutation;
}

export function useStorageChunkUploadMutation<T>(
  options?: any,
  progressCallBack?: any,
): UseMutationResult<T, unknown, any, unknown> {
  const FILE_CHUNK_SIZE = 5 * 1024 * 1024; //minimum size: 5MB

  const mutation = useMutation(async (params: any) => {
    // console.log('useStorageChunkUploadMutation start ', params.file.name, params.file.size);
    const { module, file } = params;
    let uploadParts: any[] = [];
    let curChunkIndex = 0;
    const fileSize = file.size;
    const maxChunks = Math.ceil(fileSize / FILE_CHUNK_SIZE) - 1;

    //get each binary part
    function readCurrentChunkAsync(curChunk: number, file: any) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        const startPos = curChunk * FILE_CHUNK_SIZE;
        const endPos = startPos + FILE_CHUNK_SIZE;
        const blob = file.slice(startPos, endPos);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    }

    // console.log('maxChunks', maxChunks);
    while (curChunkIndex <= maxChunks) {
      const chunkBuffer: any = await readCurrentChunkAsync(curChunkIndex, file);
      const formData = new FormData();
      const blob = new Blob([chunkBuffer], { type: file.type });
      const fileName = file.name;
      const uploadFile = new File([blob], fileName);
      formData.append('file', uploadFile);
      formData.append('module', module);
      uploadParts.push({ data: formData });
      curChunkIndex++;
    }
    // console.log('uploadParts', uploadParts);

    //1. create upload id
    const createData = new FormData();
    createData.append('module', module);
    createData.append('filename', file.name);
    createData.append('mediaType', file.type);
    createData.append('fileSize', file.size);
    const createResp: any = await storageCreateChunkUploadApi<T>(createData);
    if (createResp?.error && createResp.error != '') {
      toast.error(createResp.error);
      return createResp;
    }
    const uploadId = createResp.data;

    //2. upload parts
    const promises: any = [];
    uploadParts.map((_part: any, index: any) => {
      const nFormData = _part.data;
      nFormData.append('uploadId', uploadId);
      nFormData.append('module', module);
      // module
      promises.push(
        storageChunkUploadPartApi<T>(nFormData, (pEvent: ProgressEvent) => {
          const nPEvent = {
            loaded: pEvent.loaded,
            total: file.size,
          };
          progressCallBack && progressCallBack(nPEvent, uploadParts.length, index, uploadId);
        }),
      );
    });
    const uploadPartResps = await Promise.all(promises);
    uploadParts = uploadParts.map((_part: any, index: any) => {
      _part.isCompleted = uploadPartResps[index].isCompleted;
      return _part;
    });

    //4. complete upload
    const completeParams = new FormData();
    completeParams.append('uploadId', uploadId);
    completeParams.append('module', module);

    const completeReps = await storageCompleteUploadApi<T>(completeParams);
    // console.log('completeReps', file.name, completeReps);
    return completeReps;
  }, options);

  useEffect(() => {
    if (isString(mutation.error)) {
      const err = JSON.parse(mutation.error) as IResponseError;
      if (['no_authentication', 'server_error'].includes(err.message)) {
      }
    }
  }, [mutation.error]);

  return mutation;
}
