import React, { useEffect, useState } from 'react';
import PageView from './page-view';
import BreakView from './break-view';
import classNames from 'classnames';
import { isFunction } from 'lodash';

interface IPaginationProps {
  pageCount?: any;
  pageRangeDisplayed?: any;
  marginPagesDisplayed?: any;
  previousLabel?: React.ReactNode;
  previousAriaLabel?: string;
  prevRel?: string;
  nextLabel?: React.ReactNode;
  nextAriaLabel?: string;
  nextRel?: string;
  breakLabel?: React.ReactNode | string;
  hrefBuilder?: any;
  onPageChange?: any;
  onPageActive?: any;
  initialPage?: number;
  forcePage?: number;
  disableInitialCallback?: boolean;
  containerClassName?: string;
  pageClassName?: string;
  pageLinkClassName?: string;
  pageLabelBuilder?: any;
  activeClassName?: string;
  activeLinkClassName?: string;
  previousClassName?: string;
  nextClassName?: string;
  previousLinkClassName?: string;
  nextLinkClassName?: string;
  disabledClassName?: any;
  breakClassName?: string;
  breakLinkClassName?: string;
  extraAriaContext?: string;
  ariaLabelBuilder?: any;
  eventListener?: any;
  showPagingNumber?: boolean;
  moveFirstLabel?: React.ReactNode | null | string;
  moveLastLabel?: React.ReactNode | null | string;
  showFirstLast?: boolean;
  previousDisabled?: boolean;
  nextDisabled?: boolean;
}

const defaultProps = {
  pageCount: 10,
  pageRangeDisplayed: 2,
  marginPagesDisplayed: 3,
  activeClassName: 'selected',
  previousLabel: 'Previous',
  previousClassName: 'previous',
  previousAriaLabel: 'Previous page',
  prevRel: 'prev',
  nextLabel: 'Next',
  nextClassName: 'next',
  nextAriaLabel: 'Next page',
  nextRel: 'next',
  breakLabel: '...',
  disabledClassName: 'disabled',
  disableInitialCallback: false,
  pageLabelBuilder: (page: number | string) => page,
  eventListener: 'onClick',
  showPagingNumber: true,
  showFirstLast: false,
  moveFirstLabel: 'First',
  moveLastLabel: 'Last',
};

const Pagination: React.FC<IPaginationProps> = (props) => {
  const {
    initialPage,
    forcePage,
    disableInitialCallback,
    extraAriaContext,
    pageCount,
    eventListener,
    pageRangeDisplayed,
    onPageChange,
    onPageActive,
    disabledClassName,
    containerClassName,
    previousLabel,
    previousClassName,
    previousLinkClassName,
    previousAriaLabel,
    prevRel,
    nextLabel,
    nextClassName,
    nextLinkClassName,
    nextAriaLabel,
    nextRel,
    showPagingNumber,
    showFirstLast,
    moveFirstLabel,
    moveLastLabel,
    previousDisabled,
    nextDisabled,
  } = props;

  const [selected, setSelected] = useState(initialPage ?? forcePage ?? 0);

  useEffect(() => {
    // Call the callback with the initialPage item:
    if (typeof initialPage !== 'undefined' && !disableInitialCallback) {
      callCallback(initialPage);
    }

    if (extraAriaContext) {
      console.warn(
        'DEPRECATED (react-paginate): The extraAriaContext prop is deprecated. You should now use the ariaLabelBuilder instead.',
      );
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (typeof forcePage !== 'undefined' && !isNaN(forcePage)) {
      setSelected(forcePage);
    }

    return () => {};
  }, [forcePage]);

  const handlePreviousPage = (evt: any) => {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
    if (selected > 0) {
      handlePageSelected(selected - 1, evt);
    }
  };

  const handleNextPage = (evt: any) => {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
    if (selected < pageCount - 1) {
      handlePageSelected(selected + 1, evt);
    }
  };

  const handlePageSelected = (_selected: any, evt: any) => {
    // evt?.preventDefault ? evt?.preventDefault() : (evt.returnValue = false);
    evt?.preventDefault ? evt?.preventDefault() : false;

    if (_selected === selected) {
      callActiveCallback(_selected);
      return;
    }

    setSelected(_selected);

    // Call the callback with the new selected item:
    callCallback(_selected);
  };

  const getEventListener = (handlerFunction: any) => {
    return {
      [eventListener]: handlerFunction,
    };
  };

  const getForwardJump = () => {
    const forwardJump = selected + pageRangeDisplayed;
    return forwardJump >= pageCount ? pageCount - 1 : forwardJump;
  };

  const getBackwardJump = () => {
    const backwardJump = selected - pageRangeDisplayed;
    return backwardJump < 0 ? 0 : backwardJump;
  };

  const handleBreakClick = (index: any, evt: any) => {
    // evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
    evt.preventDefault ? evt.preventDefault() : false;

    handlePageSelected(selected < index ? getForwardJump() : getBackwardJump(), evt);
  };

  const handleBlurInputPaging = (e: any) => {
    let selected = parseInt(e.target.value, 10) - 1;
    if (selected < 0) {
      selected = 0;
    } else if (selected > pageCount - 1) {
      selected = pageCount - 1;
    }
    handlePageSelected(selected, e);
  };

  const hrefBuilder = (pageIndex: number) => {
    const { hrefBuilder } = props;
    if (hrefBuilder && pageIndex !== selected && pageIndex >= 0 && pageIndex < pageCount) {
      return hrefBuilder(pageIndex + 1);
    }
  };

  const ariaLabelBuilder = (pageIndex: number) => {
    // const selected = pageIndex === this.state.selected;

    const isSelected = pageIndex === selected;
    const { ariaLabelBuilder } = props;

    if (ariaLabelBuilder && pageIndex >= 0 && pageIndex < pageCount) {
      let label = ariaLabelBuilder(pageIndex + 1, isSelected);
      // DEPRECATED: The extraAriaContext prop was used to add additional context
      // to the aria-label. Users should now use the ariaLabelBuilder instead.
      if (extraAriaContext && !isSelected) {
        label = label + ' ' + extraAriaContext;
      }
      return label;
    }
  };

  const callCallback = (selectedItem: number) => {
    isFunction(onPageChange) && onPageChange({ selected: selectedItem });
  };

  const callActiveCallback = (selectedItem: number) => {
    if (typeof onPageActive !== 'undefined' && typeof onPageActive === 'function') {
      onPageActive({ selected: selectedItem });
    }
  };

  const getPageElement = (index: number, extendPageClass = {}, label: any = null) => {
    const {
      pageClassName,
      pageLinkClassName,
      activeClassName,
      activeLinkClassName,
      extraAriaContext,
      pageLabelBuilder,
    } = props;

    return (
      <PageView
        key={index}
        pageSelectedHandler={(e: any) => handlePageSelected(index, e)}
        selected={selected === index}
        pageClassName={classNames(pageClassName, extendPageClass)}
        pageLinkClassName={pageLinkClassName}
        activeClassName={activeClassName}
        activeLinkClassName={activeLinkClassName}
        extraAriaContext={extraAriaContext}
        href={hrefBuilder(index)}
        ariaLabel={ariaLabelBuilder(index)}
        page={index + 1}
        pageLabelBuilder={pageLabelBuilder}
        getEventListener={getEventListener}
        label={label}
      />
    );
  };

  const pagination = () => {
    const items = [];
    const {
      pageRangeDisplayed,
      pageCount,
      marginPagesDisplayed,
      breakLabel,
      breakClassName,
      breakLinkClassName,
    } = props;

    if (pageCount <= pageRangeDisplayed) {
      for (let index = 0; index < pageCount; index++) {
        items.push(getPageElement(index));
      }
    } else {
      let leftSide = pageRangeDisplayed / 2;
      let rightSide = pageRangeDisplayed - leftSide;

      // If the selected page index is on the default right side of the pagination,
      // we consider that the new right side is made up of it (= only one break element).
      // If the selected page index is on the default left side of the pagination,
      // we consider that the new left side is made up of it (= only one break element).
      if (selected > pageCount - pageRangeDisplayed / 2) {
        rightSide = pageCount - selected;
        leftSide = pageRangeDisplayed - rightSide;
      } else if (selected < pageRangeDisplayed / 2) {
        leftSide = selected;
        rightSide = pageRangeDisplayed - leftSide;
      }

      let index;
      let page;
      let breakView;
      let createPageView = (index: number) => getPageElement(index);

      for (index = 0; index < pageCount; index++) {
        page = index + 1;

        // If the page index is lower than the margin defined,
        // the page has to be displayed on the left side of
        // the pagination.
        if (page <= marginPagesDisplayed) {
          items.push(createPageView(index));
          continue;
        }

        // If the page index is greater than the page count
        // minus the margin defined, the page has to be
        // displayed on the right side of the pagination.
        if (page > pageCount - marginPagesDisplayed) {
          items.push(createPageView(index));
          continue;
        }

        // If the page index is near the selected page index
        // and inside the defined range (pageRangeDisplayed)
        // we have to display it (it will create the center
        // part of the pagination).
        if (index >= selected - leftSide && index <= selected + rightSide) {
          items.push(createPageView(index));
          continue;
        }

        // If the page index doesn't meet any of the conditions above,
        // we check if the last item of the current "items" array
        // is a break element. If not, we add a break element, else,
        // we do nothing (because we don't want to display the page).
        if (breakLabel && items[items.length - 1] !== breakView) {
          breakView = (
            <BreakView
              key={index}
              breakLabel={breakLabel}
              breakClassName={breakClassName}
              breakLinkClassName={breakLinkClassName}
              breakHandler={handleBreakClick(null, index)}
              getEventListener={getEventListener}
            />
          );
          items.push(breakView);
        }
      }
    }

    return items;
  };

  const previousClasses = classNames(previousClassName, {
    [disabledClassName]: selected === 0,
  });
  const moveFirstClasses = classNames(previousClassName, {
    [disabledClassName]: pageCount <= 1 || selected === 0,
  });
  const nextClasses = classNames(nextClassName, {
    [disabledClassName]: selected === pageCount - 1,
  });
  const moveLastClasses = classNames(nextClassName, {
    [disabledClassName]: selected === pageCount - 1,
  });

  const previousAriaDisabled = previousDisabled || selected === 0 ? 'true' : 'false';
  const nextAriaDisabled = nextDisabled || selected === pageCount - 1 ? 'true' : 'false';

  return (
    <ul className={containerClassName}>
      {showFirstLast && getPageElement(0, moveFirstClasses, moveFirstLabel)}
      <li className={previousClasses}>
        <a
          className={previousLinkClassName}
          href={hrefBuilder(selected - 1)}
          tabIndex={0}
          role="button"
          onKeyPress={handlePreviousPage}
          aria-disabled={previousAriaDisabled}
          aria-label={previousAriaLabel}
          rel={prevRel}
          {...getEventListener(handlePreviousPage)}
        >
          {previousLabel}
        </a>
      </li>

      {showPagingNumber && pagination()}

      <li className={nextClasses}>
        <a
          className={nextLinkClassName}
          href={hrefBuilder(selected + 1)}
          tabIndex={0}
          role="button"
          onKeyPress={handleNextPage}
          aria-disabled={nextAriaDisabled}
          aria-label={nextAriaLabel}
          rel={nextRel}
          {...getEventListener(handleNextPage)}
        >
          {nextLabel}
        </a>
      </li>
      {showFirstLast && getPageElement(pageCount - 1, moveLastClasses, moveLastLabel)}

      <li
        key={`input-${selected + 1}`}
        onBlur={handleBlurInputPaging}
        className="input-paging d-flex justify-content-between align-items-center mg-l-10"
      >
        <input
          type="number"
          min={1}
          max={pageCount}
          className="form-control flex-1"
          defaultValue={selected + 1}
        />
        <span className="flex-1 pd-l-5"> / {pageCount}</span>
      </li>
    </ul>
  );
};

Pagination.defaultProps = defaultProps;

export default Pagination;
