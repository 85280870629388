import { COLORS } from '@base/config/constant';
import React, { useState } from 'react';

interface IColorHeader {
  value: string;
}

interface IHeaderColorProps {
  value: string;
  onChange: (color: string) => void;
}

const HeaderColor: React.FC<IHeaderColorProps> = (props: IHeaderColorProps) => {
  const { value, onChange } = props;

  const handleChangeHeaderColor = (nVal: IColorHeader) => {
    onChange && onChange(nVal?.value);
  };

  return (
    <>
      <div className="dropdown">
        <button
          type="button"
          data-han-tooltip="Change color"
          className={`btn btn-change-header-bg shadow-sm han-tooltip--right bg-${value}`}
          data-toggle="dropdown"
        >
          <span className="sr-only">배경색 변경</span>
        </button>
        <ul className="dropdown-menu header-bg-color-list">
          {COLORS.map((item: any, index: number) => (
            <li key={index}>
              <button
                className={`btn wd-25 ht-25 pd-0 bd rounded-circle bg-${item.value}`}
                onClick={(e) => {
                  handleChangeHeaderColor(item);
                }}
              >
                &nbsp;
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default HeaderColor;
