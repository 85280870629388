import React, { lazy } from 'react';
import CommonViewField from '@base/containers/view-field/common';
import { FieldUserPermission } from '@base/types/interfaces/pagelayout';

const View = lazy(() => import('./view'));
const Edit = lazy(() => import('./edit'));

interface TitleProps {
  value: string | undefined;
  onSave?: (params: any) => void; //NOT USE
  keyName: string;
  menuSourceId: string;
  menuSource: string;
  userPermission: FieldUserPermission;
  link?: string;
  onClickLink?: any;
  viewConfig?: any;
}

const Title = (props: TitleProps) => {
  const {
    onSave,
    value,
    menuSourceId,
    menuSource,
    keyName,
    viewConfig,
    link,
    onClickLink,
    ...remainProps
  } = props;

  return (
    <CommonViewField
      keyName={keyName}
      menuSourceId={menuSourceId}
      menuSource={menuSource}
      onSave={onSave}
      componentView={View}
      componentEdit={Edit}
      value={value || '[No title]'}
      link={link}
      onClickLink={onClickLink}
      isHorizontal={link ? false : true}
      config={viewConfig ? viewConfig[keyName] : {}}
      {...remainProps}
    />
  );
};

export default Title;
