import React, { useCallback } from 'react';
import Input from '@base/components/form/input';
import Select from '@base/components/form/select';
import SpanLang from '@base/components/form/span-lang';
import { DurationValue } from '@base/types/interfaces/common';
import {
  DurationOptions,
  parseDurationValueToSecond,
  parseDurationValueToString,
} from '@base/utils/helpers/date-utils';
import { LabelValue } from '@base/types/types/app';

interface DurationRangeProps {
  options?: LabelValue[];
  value: { [index: string]: DurationValue };
  onChange: (params: any) => void;
}

const DurationRange = (props: DurationRangeProps) => {
  const { value, onChange, options } = props;
  const termsOptions = options ?? DurationOptions;

  const onHandleChangeInput = useCallback(
    (from: string, duration: number) => {
      const newValue = {
        durationUnit: value?.[from]?.durationUnit ?? 'UNIT_DAY',
        duration: parseDurationValueToSecond({
          duration: duration,
          durationUnit: value?.[from]?.durationUnit ?? 'UNIT_DAY',
        }),
      };

      onChange({ ...value, ...{ [from]: newValue } });
    },
    [value],
  );

  const onHandleChangeSelect = useCallback(
    (from: string, unit: LabelValue) => {
      if (unit.value == value?.[from]?.durationUnit) {
        return;
      }
      const duration = +parseDurationValueToString(value?.[from], false);
      const newValue = {
        durationUnit: unit.value,
        duration: parseDurationValueToSecond({
          duration: duration * 1,
          durationUnit: unit.value as string,
        }),
      };
      onChange({ ...value, ...{ [from]: newValue } });
    },
    [value],
  );

  const fromDuration = +parseDurationValueToString(value?.['from'], false);
  // console.log(fromDuration);
  const fromDurationUnit = termsOptions.find(
    (_item) => _item.value === value?.['from']?.durationUnit,
  );
  const toDuration = +parseDurationValueToString(value?.['to'], false);
  const toDurationUnit = termsOptions.find((_item) => _item.value === value?.['to']?.durationUnit);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center justify-content-center">
        <Input
          type={'number'}
          className="mg-r-5 wd-80"
          value={fromDuration}
          onChange={(val: number) => onHandleChangeInput('from', val)}
        />
        <div className="input-group dropdown wd-100">
          <div className="input-group-append wd-100p">
            <Select
              outSide={true}
              isSearchable={false}
              getOptionLabel={(option: any) => <SpanLang keyLang={option.label} />}
              options={termsOptions}
              value={fromDurationUnit}
              onChange={(val: LabelValue) => onHandleChangeSelect('from', val)}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center"> ~ </div>
      <div className="d-flex align-items-center justify-content-center">
        <Input
          type={'number'}
          className="mg-r-5 wd-80"
          value={toDuration}
          onChange={(val: number) => onHandleChangeInput('to', val)}
        />
        <div className="input-group dropdown wd-100">
          <div className="input-group-append wd-100p">
            <Select
              outSide={true}
              isSearchable={false}
              getOptionLabel={(option: any) => <SpanLang keyLang={option.label} />}
              options={termsOptions}
              value={toDurationUnit}
              onChange={(val: LabelValue) => onHandleChangeSelect('to', val)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DurationRange;
