import React from 'react';
import { CheckCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import TextView from '@base/containers/view-field/text/view';
import { LABEL_VALUE_CUSTOM } from '@customer/customer/config/constants';

const View = (props: any) => {
  const { t } = useTranslation();
  const { value } = props;

  return (
    <div>
      {(value && value.length > 0) ?
        value?.map((item: any, index: number) => {
          const optionId = item?.label?.label ?? '';
          let phoneString = item?.mobileNumber ?? '';

          return (
            <div key={index} className="d-flex align-items-center">
              {optionId && (
                <span className="badge badge-dark mg-r-5 pd-b-5">
                  {optionId === LABEL_VALUE_CUSTOM ? item?.labelValue : t(item?.label?.languageKey)}
                </span>
              )}
              <a
                href="#"
                className="text-nowrap"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open(`tel:${phoneString}`);
                }}
              >
                {phoneString}
              </a>
              {Boolean(item?.primary) && <CheckCircle size={16} className="tx-info mg-l-5" />}
            </div>
          );
        })
        :
        <TextView value={''} />
      }
    </div>
  );
};

export default View;
