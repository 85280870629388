import React, { lazy } from 'react';
import CommonViewField from '@base/containers/view-field/common';
import { OptionValue } from '@base/types/interfaces/common';
const View = lazy(() => import('./view'));
const Edit = lazy(() => import('./edit'));

interface IRadioProps {
  value: OptionValue;
  onSave: (params: any) => void;
  keyName: string;
  menuSourceId: string;
  menuSource: string;
}
const Radio: React.FC<IRadioProps> = (props: IRadioProps) => {
  const { onSave, value, menuSourceId, menuSource, keyName, ...remainProps } = props;

  return (
    <CommonViewField
      keyName={keyName}
      menuSourceId={menuSourceId}
      menuSource={menuSource}
      onSave={onSave}
      componentView={View}
      componentEdit={Edit}
      value={value}
      {...remainProps}
    />
  );
};

export default Radio;
