import React from 'react';

import Portal from '@base/components/portal';
import MiModal from '@base/components/mi-modal';
import Canvas from '@base/components/canvas';
import classNames from 'classnames';

// eslint-disable-next-line react/display-name
const withMiModal = (WrappedComponent: any) => (props: any) => {
  if (props?.showCanvas) {
    return (
      <Portal>
        <Canvas
          isVisible={props?.isOpen}
          onCloseSideBar={props?.onClose}
          className={classNames('wd-100p', props?.className)}
          customStyles={{ zIndex: '1052' }}
          backdropStyles={{ zIndex: '1051' }}
        >
          <Canvas.Header title={props?.title || 'Write New'} />
          <Canvas.Body className="h-100 pd-0-f">
            <WrappedComponent {...props} />
          </Canvas.Body>
        </Canvas>
      </Portal>
    );
  }
  return (
    <MiModal title={'Write New'} size="lg" {...props}>
      <MiModal.Body className="pd-0-f">
        <WrappedComponent {...props} />
      </MiModal.Body>
    </MiModal>
  );
};

export default withMiModal;
