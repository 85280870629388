// import { useQueries } from 'react-query';
import { useQueries } from '@tanstack/react-query'; //v4
import { FilterInput } from '@base/types/interfaces/common';
import {
  GET_SELECTION_FIELD_ITEMS_BY_SINGLE_KEY,
  GET_SELECTION_GROUPS_BY_MULTI_KEY,
  GET_AVAILABLE_COUNTRIES,
  GET_AVAILABLE_CURRENCIES,
  GET_AVAILABLE_LANGUAGES,
  GET_GENERAL_SETTINGS_COMMON,
  GET_REGION_STATES,
  GET_REGION_CITIES,
  GET_LANGUAGE_BY_MENU,
  GET_TEMPLATE_DETAIL_BY_MENU,
  GET_ALL_TEMPLATES_BY_MENU,
  GET_ALL_EMAIL_TEMPLATES_BY_MENU,
  GET_THEME_CONFIG,
  GET_PIN_SUBMENU_CONFIG,
  GET_LIST_PAGE_CONFIG,
  GET_LIST_FAVORITE_LIST,
  GET_PUBLIC_THEME_CONFIG,
  GET_USER_SETTINGS,
  GET_USER_SETTING,
} from '@base/services/graphql/setting';
import {
  IAvailableCountry,
  IMenuLanguageResponse,
  IMenuSetting,
  IMenuSettingResponse,
  ISettingSelectionPayload,
  ITemplate,
  ITemplateDetail,
  UserSetting,
} from '@base/types/interfaces/setting';
import usePost from '@base/hooks/usePost';
import { graphQLApi } from '@base/utils/axios/graphql';
import { BaseResponse } from '@base/types/interfaces/response';
import { isUndefined } from 'lodash';
import usePublicPost from '@base/hooks/usePublicPost';

export const useGetSelectionFieldItemsBySingleKeyApi = (keyName: string) => {
  const params = {
    keyName: keyName,
  };
  const res = usePost<ISettingSelectionPayload>(
    ['setting_selectionFieldItems'],
    GET_SELECTION_FIELD_ITEMS_BY_SINGLE_KEY,
    params,
  );
  return res;
};

export const useGetSelectionGroupsByMultiKeyApi = (
  // filter: FilterInput,
  keyName: string[],
  format: string | 'tree',
) => {
  let filter: FilterInput = {
    filters: {
      keyRoots: keyName,
    },
  };

  const params = {
    filter: filter,
    format: format,
  };

  const res = usePost<ISettingSelectionPayload>(
    ['setting_selectionGroups'],
    GET_SELECTION_GROUPS_BY_MULTI_KEY,
    params,
  );
  return res;
};

export const useGetAvailabelCountriesApi = () => {
  const res = usePost<ISettingSelectionPayload>(['usedCountries'], GET_AVAILABLE_COUNTRIES, {});
  return res;
};

export const getAvailabelCountriesApi = async () => {
  return await graphQLApi<ISettingSelectionPayload>('usedCountries', GET_AVAILABLE_COUNTRIES, {});
};

export const useGetAvailabelCurrenciesApi = () => {
  return usePost<ISettingSelectionPayload>(['usedCurrencies'], GET_AVAILABLE_CURRENCIES, {});
};

export const getAvailabelCurrenciesApi = async () => {
  return await graphQLApi<ISettingSelectionPayload>('usedCurrencies', GET_AVAILABLE_CURRENCIES, {});
};

export const useGetAvailabelLanguagesApi = () => {
  return usePost<ISettingSelectionPayload>(['usedLanguages'], GET_AVAILABLE_LANGUAGES, {});
};

export const getAvailabelLanguagesApi = async () => {
  return await graphQLApi<ISettingSelectionPayload>('usedLanguages', GET_AVAILABLE_LANGUAGES, {});
};

export const getRegionStatesApi = async (countryCode: string) => {
  let filter: FilterInput = {
    filters: {
      countryCode: countryCode,
    },
    paging: {
      page: 1,
      size: 500,
    },
  };

  const params = {
    filter: filter,
  };

  const res = await graphQLApi<ISettingSelectionPayload>('regions', GET_REGION_STATES, params);

  return res;
};

export const getRegionCitiesApi = async (stateCode: string) => {
  let filter: FilterInput = {
    filters: {
      subdivision: stateCode,
    },
    paging: {
      page: 1,
      size: 500,
    },
  };

  const params = {
    filter: filter,
  };

  const res = await graphQLApi<ISettingSelectionPayload>('regions', GET_REGION_CITIES, params);

  return res;
};

export const getGeneralSettingApi = () => {
  const results = useQueries({
    queries: [
      {
        queryKey: ['usedCountries'],
        queryFn: async () =>
          await graphQLApi<ISettingSelectionPayload>('usedCountries', GET_AVAILABLE_COUNTRIES, {}),
      },
      {
        queryKey: ['usedCurrencies'] as any[],
        queryFn: async () =>
          await graphQLApi<ISettingSelectionPayload>(
            'usedCurrencies',
            GET_AVAILABLE_CURRENCIES,
            {},
          ),
      },
      {
        queryKey: ['usedLanguages'] as any[],
        queryFn: async () =>
          await graphQLApi<ISettingSelectionPayload>('usedLanguages', GET_AVAILABLE_LANGUAGES, {}),
      },
    ],
  });
  // const results = useQueries([
  //   {
  //     queryKey: ['usedCountries'],
  //     queryFn: async () =>
  //       await graphQLApi<ISettingSelectionPayload>('usedCountries', GET_AVAILABLE_COUNTRIES, {}),
  //   },
  // {
  //   queryKey: ['usedCurrencies'] as any[],
  //   queryFn: async () =>
  //     await graphQLApi<ISettingSelectionPayload>('usedCurrencies', GET_AVAILABLE_CURRENCIES, {}),
  // },
  // {
  //   queryKey: ['usedLanguages'] as any[],
  //   queryFn: async () =>
  //     await graphQLApi<ISettingSelectionPayload>('usedLanguages', GET_AVAILABLE_LANGUAGES, {}),
  // },
  // ]);
  return results;
};

export const getLanguageByMenuAPI = (menus: string[], lang: string) => {
  let params = {
    menus: menus,
    lang: lang,
  };
  let key = 'setting_menusLanguages';
  const res = graphQLApi<IMenuLanguageResponse>(key, GET_LANGUAGE_BY_MENU, params);
  return res;
};

export const useMenuTemplates = ({ templateGroup, keyword = '', templates, options }: any) => {
  //// console.log('TemplateGroup', templateGroup);
  const aQueries: string[] = ['group=' + templateGroup, 'name:' + keyword];
  let filter: FilterInput = {
    query: aQueries.join(' '),
  };
  let queryKey = ['setting_menuTemplates', templateGroup, keyword];
  let params = {
    filter,
  };
  if (templates && templates.length) {
    options = {
      ...options,
      initialData: {
        results: templates,
      },
    };
  }
  const response = usePost<BaseResponse<ITemplate>>(queryKey, GET_ALL_TEMPLATES_BY_MENU, params, {
    ...options,
  });
  return response;
};
export const useEmailTemplates = ({ templateGroup, keyword = '', templates }: any) => {
  let filter: FilterInput = {
    keyword: keyword,
    filters: {
      templateGroup: templateGroup,
    },
  };
  let queryKey = ['setting_emailTemplates', templateGroup, keyword];
  let params = {
    filter,
  };
  let options = {};
  if (templates && templates.length) {
    options = {
      initialData: {
        results: templates,
      },
    };
  }
  const response = usePost<BaseResponse<ITemplate>>(
    queryKey,
    GET_ALL_EMAIL_TEMPLATES_BY_MENU,
    params,
    options,
  );
  return response;
};

export const useMenuTemplate = (templateId: string, templateGroup: string) => {
  let queryKey = ['setting_menuTemplate', templateId];
  let params = {
    templateId,
    // templateGroup,
  };
  const response = usePost<ITemplateDetail>(queryKey, GET_TEMPLATE_DETAIL_BY_MENU, params, {
    enabled: templateId != '',
  });
  return response;
};

export const useThemeConfig = () => {
  let queryKey = ['setting_menuSetting', 'common', 'theme'];
  const response = usePost<IMenuSetting>(queryKey, GET_THEME_CONFIG, {});
  return response;
};

export const usePublicThemeConfig = (token: string) => {
  let queryKey = ['tracking_menuSetting', 'common', 'theme'];
  const response = usePublicPost<IMenuSetting>(queryKey, GET_PUBLIC_THEME_CONFIG, { token });
  return response;
};

export const usePinSubMenuSettings = () => {
  let queryKey = ['setting_menuSetting', 'common', 'pin_submenu'];
  const response = usePost<IMenuSetting>(queryKey, GET_PIN_SUBMENU_CONFIG, {});
  return response;
};
export const useListPageSettings = () => {
  let queryKey = ['setting_menuSetting', 'common', 'list_page_settings'];
  const response = usePost<IMenuSetting>(queryKey, GET_LIST_PAGE_CONFIG, {});
  return response;
};

export const useListFavorite = () => {
  let queryKey = ['setting_userSetting', 'common', 'menu_favorite'];
  const response = usePost<UserSetting>(queryKey, GET_LIST_FAVORITE_LIST, {});
  return response;
};

export const useUserSettings = (menu?: string, key?: string, options?: any) => {
  const params: any = {};
  if (menu && menu != '') {
    params.menu = menu;
  }
  if (key && key != '') {
    params.key = key;
  }

  const response = usePost<BaseResponse<UserSetting>>(
    ['setting_userSettings', menu, key],
    GET_USER_SETTINGS,
    params,
    options,
  );
  return response;
};

export const useUserSetting = (menu: string, key: string, options?: any) => {
  let queryKey = ['setting_userSetting', menu, key];
  const response = usePost<UserSetting>(
    queryKey,
    GET_USER_SETTING,
    { menu: menu, key: key },
    options,
  );
  return response;
};
