import React, { useState, useEffect } from 'react';
import { NavLink as Link } from 'react-router-dom';
//import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ChevronDown, ChevronRight } from 'react-feather';
import { SidebarMenuLoading } from '@base/components/placeholder';
import { SpanLang } from '@base/components';
import { FormIcon } from '@base/components/form/icon';
//import Ls from "HanServices/Ls";

import './styles.scss';

interface ISidebarMenuProps {
  loading: boolean;
  match: any;
  activeMenu: string;
  menus: any[];
}

const SidebarMenu: React.FC<ISidebarMenuProps> = (props: ISidebarMenuProps) => {
  const {
    loading,
    match, //url params
    activeMenu, // eg: general-setting, layout
    //langKeyMenu, //pagelayout, ....
    menus, // = []
  } = props;

  //user lang
  //const userLang = Ls.get("lang") || "en";
  //const hasLang = i18next.hasResourceBundle(userLang, 'translation');

  //state
  const [sidebarMenus, setSidebarMenus] = useState<any>([]);
  //get config menu
  useEffect(() => {
    if (sidebarMenus.length === 0 || (match && match.menu != '')) {
      initSidebarMenu();
    }
  }, [menus, match]);

  //init active menu
  const setActiveMenu = (key: string, mIdx: number, cIdx: number, level: number) => {
    if (match?.params.menu) {
      //there is menu
      //level 1: key is menu keyName
      if (level === 1) {
        //set default by menu
        return key == match.params.menu ? true : false;
      }

      //level 2: key is menu id
      if (level === 2) {
        //set default by id
        return key == match.params.id ? true : false;
      }
    } else {
      //undefined
      //level 1: key is menu keyName
      if (level === 1) {
        //set default by menu
        return mIdx === 0 ? true : false;
      }

      //level 2: key is menu id
      if (level === 2) {
        //set default by id
        return mIdx === 0 && cIdx === 0 ? true : false;
      }
    }
  };

  //init menu items
  const initSidebarMenu = () => {
    let newMenus: any[] = []; //[...sidebarMenus];
    menus.map((menu: any, mIdx: number) => {
      //1 main menu
      let newMenu: any = {
        id: menu.id,
        name: <SpanLang keyLang={menu.languageKey} />,
        key: menu.keyName,
        icon: menu.icon,
        active: setActiveMenu(menu.keyName, mIdx, -1, 1),
        expand: setActiveMenu(menu.keyName, mIdx, -1, 1),
        layouts: [],
      };
      //2 sub menu
      if (menu.layouts && menu.layouts.length > 0) {
        menu.layouts.map((child: any, cIdx: number) => {
          let newChild: any = {
            id: child.id,
            name: <SpanLang keyLang={child.languageKey} />,
            key: child.keyName,
            active: setActiveMenu(child.id, mIdx, cIdx, 2),
            layouts: [],
          };
          //TODO: 3 nav
          if (child.layouts && child.layouts.length > 0) {
            child.layouts.map((nav: any, nIdx: number) => {
              let newNav: any = {
                id: nav.id,
                name: <SpanLang keyLang={nav.languageKey} />,
                key: nav.keyName,
                active: mIdx === 0 && cIdx === 0 && nIdx === 0 ? true : false, //setActiveMenu(child.id, mIdx, cIdx, 3),
                //layouts: []
              };
              newChild.layouts.push(newNav);
            });
          }
          newMenu.layouts.push(newChild);
        });
      }
      //add
      newMenus.push(newMenu);
    });

    //set state
    setSidebarMenus(newMenus);
  };

  //expand menu
  const handleExpand = (index: number) => {
    const newMenus = [...sidebarMenus];
    //set all to false, except current
    newMenus.map((item, iIdx) => {
      if (iIdx !== index) item.expand = false;
    });
    //set index
    newMenus[index].expand = !newMenus[index].expand;

    setSidebarMenus(newMenus);
  };

  //menu
  const handleActiveMenu = (menuId: string, level: number) => {
    const newMenus = [...sidebarMenus];
    //set active menu
    switch (level) {
      case 1:
        // eslint-disable-next-line no-case-declarations
        let activeMenuHasChild = true;
        newMenus.map((menu) => {
          if (menu.id === menuId) {
            //active menu
            menu.active = true;
            if (!menu.layouts || menu.layouts.length === 0) {
              activeMenuHasChild = false;
            }
          } else {
            //other menus
            menu.active = false;
          }
        });
        //set all layouts to false if activeMenuHasChild is false
        if (!activeMenuHasChild) {
          newMenus.map((menu) => {
            if (menu.id !== menuId) {
              //in-active menu
              menu.layouts &&
                menu.layouts.map((child: any) => {
                  child.active = false;
                });
            }
          });
        }
        break;
      case 2:
        newMenus.map((menu) => {
          menu.layouts &&
            menu.layouts.map((child: any) => {
              if (child.id === menuId) {
                child.active = true;
                //check in chilren is there active item
                let activeExist = false;
                child.layouts &&
                  child.layouts.map((navItem: any) => {
                    if (navItem.active) activeExist = true;
                  });
                if (!activeExist) {
                  //set default
                  if (child.layouts && child.layouts.length > 0) {
                    child.layouts[0].active = true;
                  }
                }
                //rollback to parent
                handleActiveMenu(menu.id, 1);
              } else {
                child.active = false;
                //set its' layouts to false
                child.layouts &&
                  child.layouts.map((navItem: any) => {
                    navItem.active = false;
                  });
              }
            });
        });
        break;
      case 3: //TODO
        newMenus.map((menu) => {
          menu.layouts &&
            menu.layouts.map((child: any) => {
              child.layouts &&
                child.layouts.map((navItem: any) => {
                  if (navItem.id === menuId) {
                    navItem.active = true;
                    //rollback to parent
                    handleActiveMenu(child.id, 2);
                  } else {
                    navItem.active = false;
                  }
                });
            });
        });
        break;
    }

    //set data
    setSidebarMenus(newMenus);
  };

  //// console.log('sidebarMenus', sidebarMenus);

  //main render
  return (
    <div className="page-menu-wrap">
      <div className="page-menu-nav-sidebar ht-100v">
        {loading && <SidebarMenuLoading rows={2} />}
        {!loading && (
          <ul className="page-menu-sidebar-nav">
            {sidebarMenus.map((menu: any, index: number) => {
              return (
                <li key={index} className="page-menu-nav-item show">
                  {menu?.layouts?.length > 0 ? (
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      className={classNames('page-menu-nav-link with-sub', { active: menu.active })}
                    >
                      <div className="d-flex w-100 align-items-center justify-content-between">
                        <div>
                          <FormIcon icon={menu.icon?.icon} iconType={menu.icon?.iconType} />{' '}
                          {menu.name}
                        </div>
                        <div onClick={() => handleExpand(index)}>
                          {menu.expand ? (
                            <ChevronDown className="mg-r-0-f tx-gray-300" />
                          ) : (
                            <ChevronRight className="mg-r-0-f tx-gray-300" />
                          )}
                        </div>
                      </div>
                    </a>
                  ) : (
                    <Link
                      to={`/${activeMenu}/${menu.key}`}
                      className={classNames('page-menu-nav-link with-sub', { active: menu.active })}
                      onClick={() => handleActiveMenu(menu.id, 1)}
                    >
                      <div className="d-flex w-100 align-items-center justify-content-between">
                        <div>
                          <FormIcon icon={menu.icon?.icon} iconType={menu.icon?.iconType} />{' '}
                          {menu.name}
                        </div>
                      </div>
                    </Link>
                  )}
                  {/* child 1 */}
                  {menu.layouts && menu.layouts.length > 0 && (
                    <ul className={classNames('nav', { 'd-none': !menu.expand })}>
                      {menu.layouts &&
                        menu.layouts.map((child: any, index: number) => {
                          return (
                            <li key={index} className="show">
                              <Link
                                to={`/${activeMenu}/${menu.key}/${child.id}`}
                                className={classNames('with-sub', { active: child.active })}
                                onClick={() => handleActiveMenu(child.id, 2)}
                              >
                                {child.name}
                              </Link>
                              {/* sub-child 2 */}
                              {child.layouts?.length > 0 && (
                                <nav>
                                  {child.layouts.map((navItem: any, index: number) => {
                                    return (
                                      <Link
                                        key={index}
                                        to={`/${activeMenu}/${menu.key}/${child.id}/${navItem.id}`}
                                        className={classNames({ active: navItem.active })}
                                        onClick={() => handleActiveMenu(navItem.id, 3)}
                                      >
                                        {navItem.name}
                                      </Link>
                                    );
                                  })}
                                </nav>
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SidebarMenu;
