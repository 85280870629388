import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import BaseGridItem from './base-grid-item';
import Loading from '../loading';
import NoData from '../no-data';

interface ReactGridProps {
  className?: string;
  //category: string;
  primaryKey?: string;
  titleKey?: string;
  titleUrlKey?: string;
  gridData: any[];
  isLoading: boolean;
  isSplitMode?: boolean;
  columns: any[];
  hideColumns: string[];
  defaultGridColumns?: number;
  device: string;
  columnRenderRemap?: {
    [key: string]: any;
  };
  gridNoData?: React.ReactNode;
  gridLoading?: React.ReactNode;
  gridItemRender?: any;
  checkedIds?: string[];
  onCheckedRow?: (ids: string[]) => void;
  photoKey?: string;
}

interface GridRowProps {
  isSplitMode: boolean;
  children: React.ReactNode;
}

//Grid row
const GridRow = ({ children, isSplitMode }: GridRowProps) => {
  return isSplitMode ? <>{children}</> : <div className="row row-xs">{children}</div>;
};

//create grid rows based on gridColumnsCount config
const makeRows = (items: any[], n = 2): any[] => {
  if (!items) {
    return [];
  }
  let result = [];
  for (let i = 0; i < items.length; i += n) {
    result.push(items.slice(i, i + n));
  }
  return result;
};

const ReactGrid = (props: ReactGridProps) => {
  const {
    className = '',
    //category,
    primaryKey = 'id', //item key --> for selected id
    titleKey = 'name',
    titleUrlKey = 'url',
    defaultGridColumns = 2,
    gridData = [], //items
    isLoading,
    isSplitMode = false,
    device,
    columns, //fields []
    columnRenderRemap, //render: data + component
    hideColumns, //not show
    gridNoData, //component
    gridItemRender, //custom grid item
    gridLoading, //component
    checkedIds = [],
    onCheckedRow,
    photoKey,
  } = props;
  //console.log('selectedIds', selectedIds);
  //state
  const [rowIds, setRowIds] = useState<string[]>([]); //selected ids

  //callback
  // useEffect(() => {
  //   onCheckedRow && onCheckedRow(rowIds);
  // }, [rowIds]);

  //checked all?
  useEffect(() => {
    setRowIds(checkedIds);
  }, [checkedIds]);

  //set selected rows
  const handleCheckRow = (id: string) => {
    const newRowsId = [...rowIds];
    const fIdx = newRowsId.findIndex((rowId) => rowId === id);
    if (fIdx > -1) {
      newRowsId.splice(fIdx, 1);
    } else {
      newRowsId.push(id);
    }
    setRowIds(newRowsId);
    //callback
    onCheckedRow && onCheckedRow(newRowsId);
  };

  //get grid show columns
  const gridColumnsCount =
    device === 'mobile' || device === 'tablet' || isSplitMode ? 1 : defaultGridColumns;

  //show grid rows: 1, 2,...
  const rows = makeRows(gridData, gridColumnsCount);

  //get render column
  const columnRender = (col: string) => {
    return columnRenderRemap?.[col];
  };

  //get custom or standard grid item
  const GridItem = gridItemRender ? gridItemRender : BaseGridItem;
  const GridLoading = gridLoading ? gridLoading : <Loading />;
  const GridNoData = gridNoData ? gridNoData : <NoData />;

  //render rows
  const RowsRender = useMemo(() => {
    return (
      <>
        {rows.map((_items: any[], rIdx: number) => {
          return (
            <GridRow key={rIdx} isSplitMode={isSplitMode}>
              {_items.map((_item: any, iIdx: number) => {
                const headerTitle = _item[titleKey] || '';
                const gridItemProps = {
                  //category: category || '',
                  primaryKey: primaryKey || 'id',
                  titleKey,
                  title: headerTitle,
                  titleUrl: _item?.[titleUrlKey] || '',
                  isSplitMode: isSplitMode || false,
                  data: _item,
                  columns,
                  hideColumns,
                  isChecked: rowIds.includes(_item.id),
                  columnRender, //DOM render
                  onCheckedRow: handleCheckRow,
                  photoKey,
                };
                return <GridItem key={iIdx} {...gridItemProps} />;
              })}
            </GridRow>
          );
        })}
      </>
    );
  }, [gridData, gridColumnsCount, isSplitMode, rowIds]);

  //main render
  return (
    <div className={classNames('grid-container', className, { 'one-item': gridColumnsCount == 1 })}>
      {isLoading && GridLoading}
      {!isLoading && gridData.length === 0 && GridNoData}
      {!isLoading && rows?.length > 0 && RowsRender}
    </div>
  );
};

export default ReactGrid;
