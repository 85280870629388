import React from 'react';

const pdf = () => {
  return (
    <svg
      id="file-type-pdf"
      xmlns="http://www.w3.org/2000/svg"
      width="42px"
      height="51px"
      viewBox="0 0 42 51"
    >
      <g>
        <path
          fill="#F26A4C"
          d="M42,49c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h32l8,8V49z"
        />
        <polygon fill="#E0583A" points="42.003,14.828 34.615,7.44 42,8 	" />
        <g>
          <path fill="#FFA18B" d="M42,8h-6c-1.104,0-2-0.896-2-2V0L42,8z" />
        </g>
      </g>
      <g>
        <path
          fill="#F26A4C"
          d="M42,32c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V32z
                M42,39c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V39z M42,46c0-0.553-0.447-1-1-1
                h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V46z M28,40c0,0.552-0.447,1-1,1H17c-0.553,0-1-0.448-1-1v-8
                c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V40z M28,46c0-0.553-0.447-1-1-1H17c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10
                c0.553,0,1-0.447,1-1V46z"
        />
        <path
          fill="#F1694B"
          d="M41.742,31.678c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V31.678z M41.742,38.678c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V38.678z
                M41.742,45.678c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V45.678z M27.742,39.678
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V39.678z M27.742,45.678
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V45.678z"
        />
        <path
          fill="#F0694B"
          d="M41.483,31.354c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V31.354z M41.483,38.354c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V38.354z
                M41.483,45.354c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V45.354z M27.483,39.354
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V39.354z M27.483,45.354
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V45.354z"
        />
        <path
          fill="#EF684A"
          d="M41.226,31.032c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V31.032z M41.226,38.032c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V38.032z
                M41.226,45.032c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V45.032z M27.226,39.032
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V39.032z M27.226,45.032
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V45.032z"
        />
        <path
          fill="#EE6749"
          d="M40.968,30.71c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V30.71z M40.968,37.71c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V37.71z
                M40.968,44.71c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V44.71z M26.968,38.71
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V38.71z M26.968,44.71
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V44.71z"
        />
        <path
          fill="#ED6649"
          d="M40.71,30.387c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V30.387z M40.71,37.387c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V37.387z
                M40.71,44.387c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V44.387z M26.71,38.387
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V38.387z M26.71,44.387
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V44.387z"
        />
        <path
          fill="#EC6648"
          d="M40.451,30.064c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V30.064z M40.451,37.064c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V37.064z
                M40.451,44.064c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V44.064z M26.451,38.064
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V38.064z M26.451,44.064
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V44.064z"
        />
        <path
          fill="#EB6547"
          d="M40.193,29.742c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V29.742z M40.193,36.742c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V36.742z
                M40.193,43.742c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V43.742z M26.193,37.742
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V37.742z M26.193,43.742
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V43.742z"
        />
        <path
          fill="#EA6447"
          d="M39.936,29.419c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V29.419z M39.936,36.419c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V36.419z
                M39.936,43.419c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V43.419z M25.936,37.419
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V37.419z M25.936,43.419
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V43.419z"
        />
        <path
          fill="#E96346"
          d="M39.678,29.097c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V29.097z M39.678,36.097c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V36.097z
                M39.678,43.097c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V43.097z M25.678,37.097
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V37.097z M25.678,43.097
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V43.097z"
        />
        <path
          fill="#E86345"
          d="M39.419,28.774c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V28.774z M39.419,35.774c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V35.774z
                M39.419,42.774c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V42.774z M25.419,36.774
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V36.774z M25.419,42.774
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V42.774z"
        />
        <path
          fill="#E76245"
          d="M39.161,28.451c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V28.451z M39.161,35.451c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V35.451z
                M39.161,42.451c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V42.451z M25.161,36.451
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V36.451z M25.161,42.451
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V42.451z"
        />
        <path
          fill="#E66144"
          d="M38.903,28.129c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V28.129z M38.903,35.129c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V35.129z
                M38.903,42.129c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V42.129z M24.903,36.129
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V36.129z M24.903,42.129
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V42.129z"
        />
        <path
          fill="#E56043"
          d="M38.646,27.807c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V27.807z M38.646,34.807c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V34.807z
                M38.646,41.807c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V41.807z M24.646,35.807
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V35.807z M24.646,41.807
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V41.807z"
        />
        <path
          fill="#E46043"
          d="M38.387,27.483c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V27.483z M38.387,34.483c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V34.483z
                M38.387,41.483c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V41.483z M24.387,35.483
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V35.483z M24.387,41.483
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V41.483z"
        />
        <path
          fill="#E35F42"
          d="M38.129,27.161c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V27.161z M38.129,34.161c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V34.161z
                M38.129,41.161c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V41.161z M24.129,35.161
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V35.161z M24.129,41.161
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V41.161z"
        />
        <path
          fill="#E35E41"
          d="M37.871,26.839c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V26.839z M37.871,33.839c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V33.839z
                M37.871,40.839c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V40.839z M23.871,34.839
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.448,1,1V34.839z M23.871,40.839
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V40.839z"
        />
        <path
          fill="#E25D40"
          d="M37.613,26.517c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1
                V26.517z M37.613,33.517c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V33.517z
                M37.613,40.517c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V40.517z M23.613,34.517
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8.001c0-0.552,0.447-1,1-1h10c0.553,0,1,0.448,1,1V34.517z M23.613,40.517
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V40.517z"
        />
        <path
          fill="#E15D40"
          d="M37.354,26.193c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V26.193z M37.354,33.193c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V33.193z
                M37.354,40.193c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V40.193z M23.354,34.193
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V34.193z M23.354,40.193
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V40.193z"
        />
        <path
          fill="#E05C3F"
          d="M37.097,25.871c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V25.871z M37.097,32.871c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V32.871z
                M37.097,39.871c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V39.871z M23.097,33.871
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.552,0.447-1,1-1h10c0.553,0,1,0.448,1,1V33.871z M23.097,39.871
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V39.871z"
        />
        <path
          fill="#DF5B3E"
          d="M36.839,25.548c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1
                V25.548z M36.839,32.549c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V32.549z
                M36.839,39.549c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V39.549z M22.839,33.549
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8.001c0-0.552,0.447-1,1-1h10c0.553,0,1,0.448,1,1V33.549z M22.839,39.549
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V39.549z"
        />
        <path
          fill="#DE5A3E"
          d="M36.581,25.226c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V25.226z M36.581,32.226c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V32.226z
                M36.581,39.226c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V39.226z M22.581,33.226
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.553,0.447-1,1-1h10c0.553,0,1,0.447,1,1V33.226z M22.581,39.226
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V39.226z"
        />
        <path
          fill="#DD5A3D"
          d="M36.322,24.903c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V24.903z M36.322,31.903c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V31.903z
                M36.322,38.903c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V38.903z M22.322,32.903
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.552,0.447-1,1-1h10c0.552,0,1,0.448,1,1V32.903z M22.322,38.903
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.552,0,1-0.447,1-1V38.903z"
        />
        <path
          fill="#DC593C"
          d="M36.064,24.581c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1
                V24.581z M36.064,31.581c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V31.581z
                M36.064,38.581c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V38.581z M22.064,32.581
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1V24.58c0-0.552,0.447-1,1-1h10c0.553,0,1,0.448,1,1V32.581z M22.064,38.581
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V38.581z"
        />
        <path
          fill="#DB583C"
          d="M35.807,24.258c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.552,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                V24.258z M35.807,31.258c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V31.258z
                M35.807,38.258c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V38.258z M21.807,32.258
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.552,0.447-1,1-1h10c0.553,0,1,0.448,1,1V32.258z M21.807,38.258
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V38.258z"
        />
        <path
          fill="#DA573B"
          d="M35.549,23.936c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1
                V23.936z M35.549,30.936c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V30.936z
                M35.549,37.936c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V37.936z M21.549,31.936
                c0,0.552-0.448,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.552,0.447-1,1-1h10c0.553,0,1,0.448,1,1V31.936z M21.549,37.936
                c0-0.553-0.448-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V37.936z"
        />
        <path
          fill="#D9573A"
          d="M35.29,23.613c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1
                V23.613z M35.29,30.613c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V30.613z
                M35.29,37.613c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V37.613z M21.291,31.613
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8.001c0-0.552,0.447-1,1-1h10c0.553,0,1,0.448,1,1V31.613z M21.291,37.613
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V37.613z"
        />
        <path
          fill="#D8563A"
          d="M35.032,23.291c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1
                V23.291z M35.032,30.29c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V30.29z
                M35.032,37.29c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V37.29z M21.032,31.29
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.552,0.447-1,1-1h10c0.553,0,1,0.448,1,1V31.29z M21.032,37.29
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V37.29z"
        />
        <path
          fill="#D75539"
          d="M34.774,22.968c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1
                V22.968z M34.774,29.968c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V29.968z
                M34.774,36.968c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V36.968z M20.774,30.968
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.552,0.447-1,1-1h10c0.553,0,1,0.448,1,1V30.968z M20.774,36.968
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V36.968z"
        />
        <path
          fill="#D65438"
          d="M34.517,22.645c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1
                V22.645z M34.517,29.646c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V29.646z
                M34.517,36.646c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V36.646z M20.516,30.646
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8.001c0-0.552,0.447-1,1-1h10c0.553,0,1,0.448,1,1V30.646z M20.516,36.646
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V36.646z"
        />
        <path
          fill="#D55438"
          d="M34.258,22.323c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1
                V22.323z M34.258,29.322c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V29.322z
                M34.258,36.322c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V36.322z M20.258,30.322
                c0,0.552-0.447,1-1,1h-10c-0.553,0-1-0.448-1-1v-8c0-0.552,0.447-1,1-1h10c0.553,0,1,0.448,1,1V30.322z M20.258,36.322
                c0-0.553-0.447-1-1-1h-10c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10c0.553,0,1-0.447,1-1V36.322z"
        />
        <path
          fill="#D45337"
          d="M34,22c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1V22z
                M34,29c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V29z M34,36c0-0.553-0.447-1-1-1
                h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V36z M20,30c0,0.552-0.447,1-1,1H9c-0.553,0-1-0.448-1-1v-8
                c0-0.552,0.447-1,1-1h10c0.553,0,1,0.448,1,1V30z M20,36c0-0.553-0.447-1-1-1H9c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10
                c0.553,0,1-0.447,1-1V36z"
        />
      </g>
      <path
        fill="#C74E34"
        d="M21,31c0,0.553-0.447,1-1,1H10c-0.553,0-1-0.447-1-1v-8c0-0.552,0.447-1,1-1h10c0.553,0,1,0.448,1,1V31z
            M35,23c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1V23z M35,30c0-0.552-0.447-1-1-1
            h-8c-0.553,0-1,0.448-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V30z M35,37c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1
            c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V37z M21,37c0-0.552-0.447-1-1-1H10c-0.553,0-1,0.448-1,1v1c0,0.553,0.447,1,1,1h10
            c0.553,0,1-0.447,1-1V37z"
      />
      <path
        fill="#FFFFFF"
        d="M20,30c0,0.552-0.447,1-1,1H9c-0.553,0-1-0.448-1-1v-8c0-0.552,0.447-1,1-1h10c0.553,0,1,0.448,1,1V30z
            M34,22c0-0.552-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1v1c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1V22z M34,29c0-0.553-0.447-1-1-1
            h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V29z M34,36c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1
            c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V36z M20,36c0-0.553-0.447-1-1-1H9c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h10
            c0.553,0,1-0.447,1-1V36z"
      />
      <path
        fill="#F26A4C"
        d="M15.174,31l-0.42-2.107c0.255,0.117,0.533,0.19,0.832,0.19c1.104,0,2.001-0.89,2.001-1.986
            c0-0.685-0.349-1.287-0.879-1.644c-0.255-1.355-1.377-2.376-2.726-2.376c-1.135,0-2.105,0.725-2.538,1.761
            C10.598,25.169,10,25.966,10,26.9c0,1.23,1.035,2.229,2.313,2.229c0.359,0,0.695-0.085,0.998-0.225
            c0.005,0.001,0.01,0.002,0.015,0.002L12.908,31H15.174z"
      />
      <path
        opacity="0.6"
        fill="#FFFFFF"
        d="M15.174,31l-0.42-2.107c0.255,0.117,0.533,0.19,0.832,0.19c1.104,0,2.001-0.89,2.001-1.986
            c0-0.685-0.349-1.287-0.879-1.644c-0.255-1.355-1.377-2.376-2.726-2.376c-1.135,0-2.105,0.725-2.538,1.761
            C10.598,25.169,10,25.966,10,26.9c0,1.23,1.035,2.229,2.313,2.229c0.359,0,0.695-0.085,0.998-0.225
            c0.005,0.001,0.01,0.002,0.015,0.002L12.908,31H15.174z"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M14.281,9.25h-0.82v1.471h-1.078V5.818h2.003c1.17,0,1.877,0.693,1.877,1.695v0.014
                C16.263,8.662,15.381,9.25,14.281,9.25z M15.17,7.534c0-0.483-0.336-0.743-0.875-0.743h-0.834v1.499h0.855
                c0.539,0,0.854-0.322,0.854-0.743V7.534z"
        />
        <path
          fill="#FFFFFF"
          d="M19.671,10.721h-1.912V5.818h1.912c1.54,0,2.605,1.058,2.605,2.438V8.27
                C22.276,9.649,21.211,10.721,19.671,10.721z M21.148,8.27c0-0.869-0.596-1.478-1.478-1.478h-0.833v2.956h0.833
                c0.882,0,1.478-0.595,1.478-1.464V8.27z"
        />
        <path
          fill="#FFFFFF"
          d="M25.012,6.799v1.043h2.339v0.98h-2.339v1.898h-1.079V5.818h3.733v0.981H25.012z"
        />
      </g>
    </svg>
  );
};

export default pdf;
