import React from 'react';
import { Button, FormIcon } from '@base/components/form';
import { keys } from 'lodash';
import { IconType } from '@base/types/types/app';
import { ChevronDown } from 'react-feather';

export interface IToolbarActions {
  actions: {
    [key: string]: {
      label: string;
      iconType?: IconType | any;
      icon?: string;
    };
  };
  onSelect: (actionKey: string) => void;
  color?: string | null;
}

export const ToolbarActions: React.FC<IToolbarActions> = (props) => {
  const { actions, onSelect, color } = props;

  return (
    <div className="dropdown">
      <Button
        className="btn btn-icon han-tooltip--bottom"
        data-han-tooltip="More"
        icon={'MoreHorizontal'}
        color={color ?? null}
        isSpanTitle={false}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        id="listToolbarActionMore"
      >
        <ChevronDown />
      </Button>
      <div className="dropdown-menu" aria-labelledby="listToolbarActionMore">
        {keys(actions).map((k: string, i) => {
          const action = actions[k];
          return (
            <button key={i} className="dropdown-item" onClick={() => onSelect && onSelect(k)}>
              <FormIcon
                //attrs={{ className: 'mg-r-5' }}
                iconType={action?.iconType ?? 'feather'}
                icon={action?.icon}
                size={16}
              />
              <span className="mg-l-5">{action.label}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

ToolbarActions.defaultProps = {
  color: 'white',
};
