import React from 'react';
import { SpanLang } from '@base/components/form';

interface TreeNodeTitleProps {
  data?: any;
}

const TreeNodeTitle: React.FC<TreeNodeTitleProps> = ({ data }: TreeNodeTitleProps) => {
  return <SpanLang keyLang={data?.title} />;
};

export default TreeNodeTitle;
