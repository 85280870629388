export const PREFERENCE_MENUS = [
  {
    id: 1,
    title: 'Administrator Settings',
    icon: '',
    isOpen: true,
    children: [
      // {
      //   title: 'Manage Organization',
      //   link: '/settings/organization',
      //   icon: 'Users',
      //   iconType: 'feather',
      // },
      {
        title: 'General',
        link: '/settings/general-setting',
        icon: 'Settings',
      },
      {
        title: 'Preferences for each Menu',
        link: '/settings/preferences',
        icon: 'Package',
        iconType: 'feather',
      },
      {
        title: 'Templates',
        link: '/settings/template',
        icon: 'CreditCard',
        iconType: 'feather',
      },
      {
        title: 'Page Layout',
        link: '/settings/page-layout',
        icon: 'Layers',
        iconType: 'feather',
      },
      {
        title: 'Site',
        link: '/settings/site',
        icon: 'Settings',
        iconType: 'feather',
      },
      {
        title: 'Online Digital Content',
        link: '/settings/online-digital-content',
        icon: 'Settings',
        iconType: 'feather',
      },
      {
        title: 'Bill & License',
        link: '/settings/billing-license',
        icon: 'DollarSign',
        iconType: 'feather',
      },
    ],
  },
  {
    id: 2,
    title: 'Rules/Tools/Process',
    icon: '',
    isOpen: false,
    children: [
      {
        title: 'Assignment Rule',
        link: '/settings/assignment-rule',
        icon: 'Settings',
        iconType: 'feather',
      },
      // {
      //   title: 'Rating Rule',
      //   link: '/settings/rating-rule',
      //   icon: 'Settings',
      //   iconType: 'feather',
      // },
      // {
      //   title: 'Lead Scoring Rule',
      //   link: '/settings/lead-scoring-rule',
      //   icon: 'Settings',
      //   iconType: 'feather',
      // },
      {
        title: 'Business Process',
        link: '/settings/process',
        icon: 'Cpu',
        iconType: 'feather',
      },
      {
        title: 'Automation Rule',
        link: '/settings/process/automation',
        icon: 'Settings',
        iconType: 'feather',
      },
      // {
      //   title: 'Journey Process',
      //   link: '/settings/journey-process',
      //   icon: 'Settings',
      //   iconType: 'feather',
      // },
      {
        title: 'Steps/Trigger/Attribute',
        link: '/settings/process/setting',
        icon: 'Settings',
        iconType: 'feather',
      },
    ],
  },
  // {
  //   id: 3,
  //   title: 'Marketing/Sales',
  //   icon: '',
  //   isOpen: false,
  //   children: [
  //     {
  //       title: 'Configuration',
  //       link: '/settings/configure',
  //       icon: 'Settings',
  //       iconType: 'feather',
  //     },
  //     // {
  //     //   title: 'Lead(Target Account) Process',
  //     //   link: '/settings/lead-process',
  //     //   icon: 'Settings',
  //     //   iconType: 'feather',
  //     // },
  //     // {
  //     //   title: 'Campaign Process',
  //     //   link: '/settings/campain-process',
  //     //   icon: 'Settings',
  //     //   iconType: 'feather',
  //     // },
  //     {
  //       title: 'Sales Team',
  //       link: '/settings/sales-team',
  //       icon: 'Settings',
  //       iconType: 'feather',
  //     },
  //     {
  //       title: 'Sales Commission',
  //       link: '/settings/sales-commission',
  //       icon: 'Settings',
  //       iconType: 'feather',
  //     },
  //     {
  //       title: 'Goal Metric',
  //       link: '/settings/goal-metric',
  //       icon: 'Settings',
  //       iconType: 'feather',
  //     },
  //     {
  //       title: 'Profile',
  //       link: '/settings/profile',
  //       icon: 'Settings',
  //       iconType: 'feather',
  //     },
  //     {
  //       title: 'Key Performace Indicator',
  //       link: '/settings/key-performance-indicator',
  //       icon: 'Settings',
  //       iconType: 'feather',
  //     },
  //     {
  //       title: 'Membership Sign up',
  //       link: '/settings/membership',
  //       icon: 'Settings',
  //       iconType: 'feather',
  //     },
  //   ],
  // },
];
