import { isObject, uniqueId } from 'lodash';
import React from 'react';
import Checkbox from '../checkbox';

interface ICheckboxGroupProps {
  value: any[];
  options: any[];
  isVertical?: boolean;
  fieldValue?: string;
  fieldLabel?: string;
  name?: string;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  onChange: (params: any) => void;
  onClick?: () => void;
}
const CheckboxGroup: React.FC<ICheckboxGroupProps> = (props: ICheckboxGroupProps) => {
  const {
    value = [],
    options = [],
    isVertical = true,
    fieldValue = 'value',
    fieldLabel = 'label',
    name = uniqueId('check-box-'),
    disabled = false,
    readOnly = false,
    className = 'mg-r-10 wd-100p',
    onChange = (params: any) => {},
    onClick = () => {},
  } = props;
  if (options.length === 0) return <> No Options</>;

  return (
    <div className="wd-100p">
      <div className={`${isVertical ? 'han-choice-vertical' : 'd-flex'}`}>
        {options.map((item: any, index) => (
          <Checkbox
            key={index}
            value={item[fieldValue]}
            className={className}
            id={`${name}-${index}`}
            tag={'label'}
            label={item[fieldLabel]}
            disabled={disabled}
            readOnly={readOnly}
            onClick={() => onClick()}
            checked={
              value.findIndex((_item: any) => {
                if (isObject(_item)) {
                  return _item[fieldValue as keyof typeof _item] == item[fieldValue];
                } else {
                  return _item == item[fieldValue];
                }
              }) !== -1
            }
            onChange={(e: any) => {
              if (readOnly === false && onChange && typeof onChange === 'function') {
                let valueNew = [];
                if (e.target.checked) {
                  valueNew = value.concat(item);
                } else {
                  valueNew = value.filter(
                    (itemChosed) => itemChosed[fieldValue] != item[fieldValue],
                  );
                }
                onChange(valueNew);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckboxGroup;
