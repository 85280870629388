import React from 'react';
import TextView from '@base/containers/view-field/text/view';
import { isString, isUndefined } from 'lodash';

interface LookupViewProps {
  value: any;
  fieldValue: string;
  fieldLabel: string;
}
const LookupView = (props: LookupViewProps) => {
  const { value, fieldLabel = 'keyName', fieldValue = 'languageKey' } = props;
  // get field attrs
  const getValueView = (): string => {
    return isString(value)
      ? value
      : value && !isUndefined(value[fieldLabel])
      ? value[fieldLabel]
      : '';
  };
  return <TextView value={isString(value) ? value : getValueView()} />;
};

export default LookupView;
