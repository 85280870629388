import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { ChevronDown } from 'react-feather';
import { SelectWrapper } from '@base/components/form/select-dropdown';
import Portal from '@base/components/portal';

import useDidUpdateEffect from '@base/hooks/useDidUpdateEffect';
import { generateUUID } from '@base/utils/helpers/general.utils';
import dayjs from 'dayjs';
import { DatePicker } from '@base/components/date-picker';

const ALL_OPTION_LABEL = 'All';
const range = ['This Year', 'This Quarter', 'This Month', 'This Week', 'Today'];

const SelectDropdownWithDates = ({
  className,
  name,
  tag = 'button',
  placeholder = 'Select',
  down = true,
  options = [],
  selected,
  onChange = () => null,
  icon = null,
  separate = false,
  width,
  prefix = null,
  buttonStyles = {},
  hideLabel = false,
  itemClassName = '',
  children = null,
  isMulti = false,
  outSide = false,
}: any) => {
  // state
  const [selectedRange, setSelectedRange] = useState<any>({}); //range from select date option
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<any>({}); //selected field option
  const [selectedDateLabel, setSelectedDateLabel] = useState<any>(ALL_OPTION_LABEL); //date label
  const [customRange, setCustomRange] = useState({ start: new Date(), end: new Date() }); //range from input
  const [dropdownStyle, setDropdownStyle] = useState({});
  const containerRef = useRef<any>(null);
  const id = useRef(generateUUID());

  // // console.log('selectedRange', selectedRange);
  // // console.log('selectedValue', selectedValue);
  // // console.log('selectedDateLabel', selectedDateLabel);

  // init value
  useEffect(() => {
    if (selected && !_.isEmpty(selected)) {
      if (JSON.stringify(selected) !== JSON.stringify(selectedRange)) {
        // init date range
        setSelectedRange(selected);
        // init option
        let foundOption: any = null;
        Object.keys(selected).map((_key: any) => {
          foundOption = options.find((_option: any) => _option.value === _key);
          setSelectedValue(foundOption);
        });
        // init range label
        if (foundOption) {
          const rangeLabel = getRangeLabel(
            selected[foundOption.value][0],
            selected[foundOption.value][1],
          );
          setSelectedDateLabel(rangeLabel);
        }
      }
    } else {
      setSelectedRange({});
      setSelectedValue({});
      setSelectedDateLabel(ALL_OPTION_LABEL);
    }
  }, [selected]);

  useDidUpdateEffect(() => {
    if (showDropdown) {
      $(document)
        .off('click.date-dropdown')
        .on('click.date-dropdown', (e) => {
          if (!$(e.target).parents('.dropdown-menu').length) {
            // setShowDropdown(false);
          }
        });

      let $container = $(containerRef.current),
        $btn: any = $container.find('> .btn:first');

      setDropdownStyle({
        zIndex: 999999,
        left: $btn.offset().left,
        top: $btn.offset().top + $container.outerHeight(),
      });
    } else {
      $(document).off('click.date-dropdown');
    }
  }, [showDropdown]);

  const SelectedLabel: any = useMemo<any>(() => {
    return selectedDateLabel ? (
      <span>
        {selectedValue.label} <strong>{selectedDateLabel}</strong>
      </span>
    ) : (
      placeholder
    );
  }, [selectedValue, selectedDateLabel]);

  //reset selected range
  useEffect(() => {
    const newRange: any = {};
    Object.keys(selectedRange).map((_key: string) => {
      newRange[selectedValue.value] = selectedRange[_key];
    });
    setSelectedRange(newRange);
  }, [selectedValue]);

  const onSelect = (label: any, range: any) => {
    setSelectedDateLabel(label);
    setSelectedRange(range);
  };

  //custom date change
  const handleCustomRangeChange = (rangeName: string, keyName: string, keyValue: Date) => {
    const newCustomRange: any = { ...customRange };
    newCustomRange[keyName] = keyValue;
    setCustomRange(newCustomRange);
    //set date range
    const label =
      newCustomRange.start.toISOString().slice(0, 10) +
      ' ~ ' +
      newCustomRange.end.toISOString().slice(0, 10);
    setSelectedDateLabel(label);
    setSelectedRange({ [rangeName]: [newCustomRange.start, newCustomRange.end] });
  };

  const onSubmit = () => {
    setShowDropdown(false);
    onChange && onChange(selectedRange);
  };

  const reset = () => {
    setSelectedValue({});
    // onSelect(ALL_OPTION_LABEL, { date: [0, 1] }); //all dates
    onSelect(ALL_OPTION_LABEL, {}); // all dates => none selected
  };

  const getRange = (index: number) => {
    let start, end;
    switch (index) {
      case 0:
        start = dayjs().startOf('year');
        end = dayjs().endOf('year');
        break;
      case 1:
        start = dayjs().startOf('quarter');
        end = dayjs().endOf('quarter');
        break;
      case 2:
        start = dayjs().startOf('month');
        end = dayjs().endOf('month');
        break;
      case 3:
        start = dayjs().startOf('week');
        end = dayjs().endOf('week');
        break;
      case 4:
        start = dayjs().startOf('day');
        end = dayjs().endOf('day');
        break;
    }

    return [start, end];
  };

  const getRangeLabel = (start: any, end: any) => {
    const startY = dayjs().startOf('year');
    const endY = dayjs().endOf('year');
    const startQ = dayjs().startOf('quarter');
    const endQ = dayjs().endOf('quarter');
    const startM = dayjs().startOf('month');
    const endM = dayjs().endOf('month');
    const startW = dayjs().startOf('week');
    const endW = dayjs().endOf('week');
    const startD = dayjs().startOf('day');
    const endD = dayjs().endOf('day');
    if (startY.diff(dayjs(start)) == 0 && endY.diff(dayjs(end)) == 0) {
      return range[0];
    } else if (startQ.diff(dayjs(start)) == 0 && endQ.diff(dayjs(end)) == 0) {
      return range[1];
    } else if (startM.diff(dayjs(start)) == 0 && endM.diff(dayjs(end)) == 0) {
      return range[2];
    } else if (startW.diff(dayjs(start)) == 0 && endW.diff(dayjs(end)) == 0) {
      return range[3];
    } else if (startD.diff(dayjs(start)) == 0 && endD.diff(dayjs(end)) == 0) {
      return range[4];
    } else {
      //custom
      return start.slice(0, 10) + ' ~ ' + end.slice(0, 10);
    }
  };

  const renderDefinedRange = (name: string) => (
    <div id="dateBy1" className="collapse show">
      {range.map((label, index) => (
        <button
          key={index}
          onClick={() => onSelect(label, { [name]: getRange(index) })}
          type="button"
          className={classNames('dropdown-item', { active: selectedDateLabel === label })}
        >
          {label}
        </button>
      ))}
      <button
        //onClick={() => setSelectedDateLabel('')}
        type="button"
        className="dropdown-item btn d-flex align-items-center"
        data-toggle="collapse"
        data-target="#dateBy1-custom"
      >
        <span className="mg-r-5">Custom</span>
        <ChevronDown className="mg-l-auto" />
      </button>
      <div id="dateBy1-custom" className="collapse show input-group pd-x-25 pd-y-5">
        <DatePicker
          selected={customRange.start}
          onChange={(data: Date) => handleCustomRangeChange(name, 'start', data)}
        />
        ~
        <DatePicker
          selected={customRange.end}
          onChange={(data: Date) => handleCustomRangeChange(name, 'end', data)}
        />
      </div>
    </div>
  );

  return (
    <SelectWrapper
      ref={containerRef}
      id={id.current}
      className={classNames('dropdown list-filter-item', className, { 'to-body': outSide })}
      separate={separate}
      width={width}
    >
      {prefix}
      <button
        type="button"
        className="btn btn-filter pd-l-0"
        style={buttonStyles}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {icon}
        {!hideLabel && SelectedLabel}
        {down && <ChevronDown className="mg-l-5" />}
      </button>

      <Portal id={document.body}>
        <div
          className={classNames('dropdown-menu wd-300', { show: showDropdown })}
          style={dropdownStyle}
        >
          <button className="dropdown-item" type="button" onClick={reset}>
            All
          </button>
          <div className="dropdown-divider" />
          {options.map((opt: any) => (
            <div key={opt.value} className="pd-x-10 pd-y-5">
              <div
                //type="button"
                data-toggle="collapse"
                className={classNames(
                  'd-flex wd-100p align-items-center tx-12 tx-semibold cursor-pointer',
                  {
                    collapsed: selectedValue.value !== opt.value,
                  },
                )}
                onClick={() => setSelectedValue(opt)}
              >
                <div className="mg-r-5 w-100">
                  <div className="d-flex flex-column">
                    <div>{opt.label}</div>
                    {selectedValue.value === opt.value && (
                      <div className="mg-l-5 tx-info">{selectedDateLabel}</div>
                    )}
                  </div>
                </div>
                <ChevronDown className="mg-l-auto wd-20" />
              </div>
              {selectedValue.value === opt.value && renderDefinedRange(opt.value)}
            </div>
          ))}
          <div className="dropdown-divider" />
          <div className="text-center">
            <button
              type="button"
              onClick={() => setShowDropdown(false)}
              className="btn btn-secondary btn-xs mr-2"
            >
              Cancel
            </button>
            <button type="button" onClick={onSubmit} className="btn btn-primary btn-xs">
              Apply
            </button>
          </div>
        </div>
      </Portal>
    </SelectWrapper>
  );
};

export default SelectDropdownWithDates;
