import { css, Theme } from '@emotion/react';

const GlobalStyle = (theme: Theme) => css`
  html {
    box-sizing: border-box;
    * {
      box-sizing: inherit;
    }
  }
  body {
    background-color: ${theme.bgColor.body};
  }
`;

export default GlobalStyle;
