import { BaseResponse } from '@base/types/interfaces/response';
import { graphQLApi, s3DownloadObjectApi } from '@base/utils/axios/graphql';
import {
  SETTING_CTA_CONTENT_TYPES,
  SETTING_CTA_TYPES,
} from '@settings/online-digital-content/cta/config/constants';
import { SETTING_CTA_LIST } from '@settings/online-digital-content/cta/services/graphql';
import { url } from 'inspector';

export default (editor: any, config: any) => {
  const domc = editor.DomComponents;
  const defaultType = domc.getType('default');
  const defaultModel = defaultType.model;
  const defaultView = defaultType.view;

  const selectStyle = {
    display: 'flex',
    'flex-wrap': 'wrap',
    '-webkit-box-pack': 'justify',
    'align-items': 'center',
    'background-color': '#fff',
    'border-radius': '4px',
    'border-style': 'solid',
    'border-width': '1px',
    cursor: 'default',
    'justify-content': 'space-between',
    'min-height': '38px',
    outline: '0!important',
    position: 'relative',
    transition: 'all 100ms',
    'box-sizing': 'border-box',
    border: '1px solid #b4bdce',
    padding: '0 10px',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    background: `url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat`,
    'background-position': 'calc(100% - 0.75rem) center !important',
    'padding-right': '2rem !important',
  };

  // INPUT
  // domc.addType('input', {
  //   isComponent: (el: any) => el.tagName == 'INPUT',
  //   model: {
  //     defaults: {
  //       tagName: 'input',
  //       //draggable: 'form, form *',
  //       droppable: false,
  //       highlightable: false,
  //       attributes: {
  //         type: 'text',
  //       },
  //       traits: [
  //         //nameTrait,
  //         //placeholderTrait,
  //         {
  //           type: 'select',
  //           name: 'type',
  //           options: [
  //             { value: 'text' },
  //             { value: 'email' },
  //             { value: 'password' },
  //             { value: 'number' },
  //           ],
  //         },
  //         {
  //           type: 'select',
  //           name: 'autocomplete',
  //           options: [{ value: 'off' }, { value: 'on' }],
  //         },
  //         //requiredTrait,
  //       ],
  //     },
  //   },
  // });

  // OPTION
  domc.addType('option', {
    isComponent: (el: any) => el.tagName == 'OPTION',

    model: {
      defaults: {
        tagName: 'option',
        layerable: false,
        droppable: false,
        draggable: false,
        highlightable: false,
      },
    },
  });

  const createOption = (value: string, content: string) => ({
    type: 'option',
    components: content,
    attributes: { value },
  });

  domc.addType('personalize', {
    //extend: 'input',
    isComponent: (el: any) => el.tagName == 'SELECT',
    model: {
      defaults: {
        tagName: 'select',
        droppable: ':not(form)',
        draggable: ':not(form)',
        components: [
          createOption('customerName', 'Customer Name'),
          createOption('ticketId', 'Ticket ID'),
          createOption('assignedRep', 'Assigned Rep'),
        ],
        style: selectStyle,
        traits: [
          {
            type: 'text',
            name: 'name',
            label: 'Name',
            changeProp: 1,
          },
          // {
          //   name: 'options',
          //   type: 'select-options',
          //   label: 'Options',
          //   changeProp: 1,
          // },
          // {
          //   type: 'checkbox',
          //   name: 'required',
          //   label: 'Required',
          //   changeProp: 1,
          // },
        ],
      },
    },
    view: {
      events: {
        mousedown: (ev: any) => {
          if (!editor.Commands.isActive('preview')) {
            ev.preventDefault();
          }
        },
      },
    },
  });
};
