import React from 'react';
import CommonViewField from '../common';

import View from './view';
import Edit from './edit';

const UnLimitDateTimeViewField: React.FC = (props: any) => {
  return (
    <CommonViewField {...props} componentView={View} componentEdit={Edit} value={props?.value} />
  );
};

export default UnLimitDateTimeViewField;
