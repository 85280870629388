import validator from 'validator';

import {
  PRODUCT_ITEM_TYPE_PREPAID,
  PRODUCT_PREPAID_TYPE_AMOUNT,
  PRODUCT_PREPAID_TYPE_PER_ITEM,
} from '@product/main/config/constants';

import {
  KEY_ITEM_TYPE,
  KEY_ITEM_CATEGORY,
  KEY_USE_PREPAID,
  KEY_ITEM_PREPAID_INFO,
  KEY_ITEM_BASIC_INFO,
  KEY_ITEM_NAME,
  KEY_ITEM_PRICES,
  KEY_ITEM_BATCH_INFO,
  KEY_ITEM_INVENTORIES,
  KEY_ITEM_LOCATIONS,
  KEY_PREPAID_ITEM,
  KEY_ITEM_CODE,
  KEY_ITEM_PREPAID_AMOUNT,
  KEY_PREPAID_TYPE,
  KEY_ITEM_MATCH_ITEM,
  KEY_ITEM_PREPAID_PER_ITEM,
} from '@product/item/config/key-names';

const isRequired = (value: string): boolean => {
  try {
    if (Array.isArray(value)) {
      return value.length > 0;
    } else if (typeof value == 'object') {
      return Object?.keys(value)?.length > 0;
    } else {
      return Boolean(value);
    }
  } catch (error) {
    return false;
  }
};

//custom validation
export default {
  required: (value: string) => isRequired(value) || 'language_key_required',
  maxLength: (value: string, length = 0) =>
    String(value).length <= length || `language_key_max_length_${length}`,
  min: (value: string, min = 0) => parseFloat(value) >= min || `language_key_min_${min}`,
  email: (value: string) => validator.isEmail(value) || 'Email invalid',
  emails: (value: any[]) => {
    if (Array.isArray(value)) {
      const result = value.some((_item) => !validator.isEmail(_item.email));
      if (result) {
        return 'Email invalid';
      }
      return true;
    }
    return 'Email invalid';
  },
  website: (value: string) => validator.isURL(value) || 'language_key_incorrect_url',
  protocol: (value: string) => isRequired(value) || 'protocol_require',
  ip: (value: string) => validator.isIP(value) || 'ip_incorrect',
  isIntMaxLength: (value: string | number, maxValue = 10) =>
    validator.isInt(String(value), { min: 1, max: maxValue }) || `Number max length is ${maxValue}`,
  isInt: (value: string | number) =>
    validator.isInt(String(value)) || 'Please input number integer',
  //for product item validate
  attribute: (value: any) => {
    //// console.log('validate product attribute', value);
    if (value.applyItemAttribute) {
      if (value.attributes.length === 0) {
        return false;
      }
    }
    return true;
  },
  item: (value: any) => {
    //// console.log('validate item data', value);
    if (value[KEY_ITEM_TYPE] !== PRODUCT_ITEM_TYPE_PREPAID) {
      if (!value[KEY_ITEM_BASIC_INFO] || value[KEY_ITEM_BASIC_INFO].length === 0) return true; //skip check for item write
    }
    //start check
    let isValid = true;
    if (value[KEY_ITEM_TYPE] !== PRODUCT_ITEM_TYPE_PREPAID) {
      //CURRENT VERSION: there are 2 steps
      //basic data must be valid
      value[KEY_ITEM_BASIC_INFO].map((_item: any) => {
        //required item name
        if (_item[KEY_ITEM_NAME].length === 0) {
          isValid = false;
        }
        //required prices
        _item[KEY_ITEM_PRICES].map((_price: any) => {
          if (_price.base_price !== undefined && parseInt(_price.base_price.value || 0) === 0) {
            isValid = false;
          }
          if (_price.best_price !== undefined && parseInt(_price.best_price.value || 0) === 0) {
            isValid = false;
          }
          if (_price.cost_price !== undefined && parseInt(_price.cost_price.value || 0) === 0) {
            isValid = false;
          }
          if (
            _price.purchase_price !== undefined &&
            parseInt(_price.purchase_price.value || 0) === 0
          ) {
            isValid = false;
          }
        });
      });

      //PREVIOUS VERSION: there are 5 steps
      // if(value[KEY_ITEM_CATEGORY] === PRODUCT_ITEM_CATEGORY_NON_INVENTORY) {
      //     if(value[KEY_USE_PREPAID] === 'y') {
      //         //prepaid data must be valid
      //         // if(value[KEY_ITEM_PREPAID_INFO] === undefined) {
      //         //     isValid = false;
      //         // } else if(value[KEY_ITEM_PREPAID_INFO].length === 0) {
      //         //     isValid = false;
      //         // } else {
      //         //     value[KEY_ITEM_PREPAID_INFO].map(_item => {
      //         //         _item[KEY_PREPAID_ITEMS].map(_pre => {
      //         //             if(_pre[KEY_PREPAID_ITEM_NAME].length === 0) {
      //         //                 isValid = false;
      //         //             }
      //         //         })
      //         //     })
      //         // }
      //     } else {
      //         //basic data must be valid
      //         value[KEY_ITEM_BASIC_INFO].map(_item => {
      //             //required item name
      //             if(_item[KEY_ITEM_NAME].length === 0) {
      //                 isValid = false;
      //             }
      //             //required prices
      //             _item[KEY_ITEM_PRICES].map(_price => {
      //                 if(_price.base_price !== undefined && parseInt(_price.base_price.value || 0) === 0) {
      //                     isValid = false;
      //                 }
      //                 if(_price.best_price !== undefined && parseInt(_price.best_price.value || 0) === 0) {
      //                     isValid = false;
      //                 }
      //                 if(_price.cost_price !== undefined && parseInt(_price.cost_price.value || 0) === 0) {
      //                     isValid = false;
      //                 }
      //                 if(_price.purchase_price !== undefined && parseInt(_price.purchase_price.value || 0) === 0) {
      //                     isValid = false;
      //                 }
      //             })
      //         })
      //     }
      // }
      // if(value[KEY_ITEM_CATEGORY] === PRODUCT_ITEM_CATEGORY_INVENTORY) {
      //     if(value[KEY_USE_PREPAID] === 'y') {
      //         //HIDDEN: prepaid data must be valid
      //         // if(value[KEY_ITEM_PREPAID_INFO] === undefined) {
      //         //     isValid = false;
      //         // } else if(value[KEY_ITEM_PREPAID_INFO].length === 0) {
      //         //     isValid = false;
      //         // } else {
      //         //     //name required
      //         //     value[KEY_ITEM_PREPAID_INFO].map(_item => {
      //         //         _item[KEY_PREPAID_ITEMS].map(_pre => {
      //         //             if(_pre[KEY_PREPAID_ITEM_NAME].length === 0) {
      //         //                 isValid = false;
      //         //             }
      //         //         })
      //         //     })
      //         // }
      //     } else {
      //         //HIDDEN: inventory-batch data must be valid
      //         // if(value[KEY_ITEM_BATCH_INFO] === undefined) {
      //         //     isValid = false;
      //         // } else if(value[KEY_ITEM_BATCH_INFO].length === 0) {
      //         //     isValid = false;
      //         // } else {
      //         //     value[KEY_ITEM_BATCH_INFO].map(_item => {
      //         //         _item[KEY_ITEM_INVENTORIES].map(_inv => {
      //         //             if(_inv[KEY_ITEM_LOCATIONS].length === 0) {
      //         //                 isValid = false;
      //         //             }
      //         //         })
      //         //     })
      //         // }
      //     }
      // }
    } else {
      //value.itemType === PRODUCT_ITEM_TYPE_PREPAID
      //prepaid item data must be valid
      if (value[KEY_PREPAID_ITEM] === undefined) {
        isValid = false;
      } else if (value[KEY_PREPAID_ITEM].length === 0) {
        isValid = false;
      } else {
        value[KEY_PREPAID_ITEM].map((_item: any) => {
          //name required
          if (_item[KEY_ITEM_NAME].length === 0) {
            isValid = false;
          }
          //code required
          if (_item[KEY_ITEM_CODE].length === 0) {
            isValid = false;
          }
          if (value[KEY_PREPAID_TYPE] === PRODUCT_PREPAID_TYPE_AMOUNT) {
            //amount required
            _item[KEY_ITEM_PREPAID_AMOUNT].map((_ele: any) => {
              if (parseInt(_ele.value || 0) === 0) {
                isValid = false;
              }
            });
          }
          if (value[KEY_PREPAID_TYPE] === PRODUCT_PREPAID_TYPE_PER_ITEM) {
            if (_item[KEY_ITEM_MATCH_ITEM] === null) {
              isValid = false;
            }
            if (parseInt(_item[KEY_ITEM_PREPAID_PER_ITEM] || 0) === 0) {
              isValid = false;
            }
          }
        });
      }
    }
    return isValid;
  },
  ctaLink: (value: any) => isRequired(value?.link) || 'language_key_required',
};
