import React, { useCallback, useRef, useState } from 'react';
import ActivityFilter from './filter';
import ActivityItem from './item';
import useInfinitePosts from '@base/hooks/useInfinitePosts';
import { Activity } from '@activity/types/interfaces';
import { ACTIVITY_VIEW_PAGE_SIZE } from '@base/config/constant';
import Scrollbars from 'react-custom-scrollbars-2';
import { makeSection } from '@base/utils/helpers/makeSection';
import { Section, StickyHeader } from './styles';
import { Button } from '@base/components/form';
import Loading from '@base/components/loading';
import { buildListSchema } from '@base/utils/helpers/schema';
import { getListQuery } from '@activity/services/mywork';
import { default as configFields } from '@activity/config/view-field';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { MENU_SOURCE } from '@base/config/menus';
//import { useQueryClient } from 'react-query';
import { useQueryClient } from '@tanstack/react-query'; //v4

interface ActivityProps {
  menuSource: string;
  menuSourceId: string;
}

export interface IFilter {
  createdAt: {
    from: Date;
    to: Date;
  };
  assignedTo: string;
  category: string[];
  keyword?: string;
}

const CustomerViewActivity = (props: ActivityProps) => {
  const { menuSource, menuSourceId } = props;
  // console.log('timeline activities', menuSource, menuSourceId);
  const [isAutoScroll, setAutoScroll] = useState(false);
  const [filter, setFilter] = useState<IFilter | null>(null);
  const queryClient = useQueryClient();

  const { data: listLayoutData, isLoading: isLayoutLoading } = usePageLayoutByMenu(
    'activity_activity',
    'list',
  );
  // console.log('listlayoutdata', listLayoutData);

  let fields = [];
  if (listLayoutData && listLayoutData?.data) {
    fields = listLayoutData?.data;
  }

  // console.log('fields', fields);

  const listQuerySchema = buildListSchema({ fields, configFields });
  const listFullQuery = getListQuery(listQuerySchema);

  let queries: string[] = [];
  if (filter != null) {
    if (filter.createdAt) {
      queries.push(['createdAt', '>=', '"' + filter.createdAt.from.toISOString() + '"'].join(''));
      queries.push(['createdAt', '<=', '"' + filter.createdAt.to.toISOString() + '"'].join(''));
    }
    if (filter.assignedTo != '') {
      queries.push(['assignedTo', '=', filter.assignedTo].join(''));
    }

    if (filter.category.length > 0) {
      queries.push(['category', '=', filter.category.join(',')].join(''));
    }

    if (filter.keyword != '') {
      queries.push(['subject', '=', filter.keyword].join(''));
    }
  }

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError } =
    useInfinitePosts<Activity[]>(['activity_activities', 'tab'], listFullQuery, {
      source: {
        menu: MENU_SOURCE[menuSource],
        id: menuSourceId,
      },
      filter: {
        sort: {
          field: 'createdAt',
          orderBy: 'DESC',
        },
        paging: {
          size: ACTIVITY_VIEW_PAGE_SIZE,
        },
        query: queries.length ? '(' + queries.join(' ') + ')' : null,
      },
    });

  const scrollbarRef = useRef<Scrollbars>(null);
  const onScroll = useCallback(
    (values: any) => {
      if (isAutoScroll && values.top === 1 && hasNextPage) {
        // console.log('auto');
        fetchNextPage();
        scrollbarRef.current?.scrollToBottom();
      }
    },
    [isAutoScroll, fetchNextPage, scrollbarRef, hasNextPage],
  );

  const getMore = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
      scrollbarRef.current?.scrollToBottom();
    }
  }, [fetchNextPage, scrollbarRef, hasNextPage]);

  const onChangeAutoScroll = useCallback(() => {
    setAutoScroll((auto) => !auto);
  }, []);

  const onChangeFilter = useCallback((f: IFilter | null) => {
    queryClient.removeQueries(['activity_activities', 'tab']);
    setFilter(f);
  }, []);

  const sections = makeSection(data);

  return (
    <div className="pd-20">
      <div className="new-timeline-group">
        <ActivityFilter onChangeFilter={onChangeFilter} />
        {!isError && (
          <Scrollbars
            ref={scrollbarRef}
            autoHeight
            autoHeightMin={400}
            autoHeightMax={document.body.scrollHeight - 400}
            autoHide
            onScrollFrame={onScroll}
          >
            {Object.entries(sections).map(([date, activities]) => {
              return (
                <Section key={date}>
                  <StickyHeader>
                    <button>{date}</button>
                  </StickyHeader>
                  {activities.map((activity) => (
                    <ActivityItem key={activity.id} data={activity} />
                  ))}
                </Section>
              );
            })}
          </Scrollbars>
        )}

        {(isLoading || isFetchingNextPage) && <Loading />}

        {!isAutoScroll && hasNextPage && (
          <Button
            block={true}
            color={'light'}
            className="bd-0 tx-uppercase tx-10 tx-spacing-1 tx-medium mg-t-10"
            name="Load More"
            onClick={() => getMore()}
          />
        )}
      </div>
    </div>
  );
};

export default CustomerViewActivity;
