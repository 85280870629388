import { LabelValue } from '@base/types/types/app';
import _ from 'lodash';

export const defaultReDay = 'nnnnnnn';
export const lastDay = 'l';
export const months: LabelValue[] = [
  {
    value: 1,
    label: 'January',
  },
  {
    value: 2,
    label: 'February',
  },
  {
    value: 3,
    label: 'March',
  },
  {
    value: 4,
    label: 'April',
  },
  {
    value: 5,
    label: 'May',
  },
  {
    value: 6,
    label: 'June',
  },
  {
    value: 7,
    label: 'July',
  },
  {
    value: 8,
    label: 'August',
  },
  {
    value: 9,
    label: 'September',
  },
  {
    value: 10,
    label: 'October',
  },
  {
    value: 11,
    label: 'November',
  },
  {
    value: 12,
    label: 'December',
  },
];
export const MONTHS: LabelValue[] = [
  {
    value: 1,
    label: 'January',
  },
  {
    value: 2,
    label: 'February',
  },
  {
    value: 3,
    label: 'March',
  },
  {
    value: 4,
    label: 'April',
  },
  {
    value: 5,
    label: 'May',
  },
  {
    value: 6,
    label: 'June',
  },
  {
    value: 7,
    label: 'July',
  },
  {
    value: 8,
    label: 'August',
  },
  {
    value: 9,
    label: 'September',
  },
  {
    value: 10,
    label: 'October',
  },
  {
    value: 11,
    label: 'November',
  },
  {
    value: 12,
    label: 'December',
  },
];

// export const every_type = {
//   hourly: 'h',
//   daily: 'd',
//   weekly: 'w',
//   monthly: 'm',
//   yearly: 'y',
// };

export const RECURRENCE_TYPE: { [index: string]: string } = {
  hourly: 'TYPE_HOURLY',
  daily: 'TYPE_DAILY',
  weekly: 'TYPE_WEEKLY',
  monthly: 'TYPE_MONTHLY',
  yearly: 'TYPE_YEARLY',
};

export const RECURRENCE_EVERY_TYPE: { [index: string]: string } = {
  day: 'EVERY_DAYS',
  week: 'EVERY_WEEKS',
  last: 'EVERY_LAST_DAY_OF_MONTH',
};

export const RECURRENCE_EVERY_TYPE_REVERSE: { [index: string]: string } = _.invert(RECURRENCE_EVERY_TYPE);

export const RECURRENCE_TYPE_LABEL: { [index: string]: string } = {
  [RECURRENCE_TYPE.monthly]: 'month(s)',
  [RECURRENCE_TYPE.yearly]: 'year(s)',
  [RECURRENCE_TYPE.weekly]: 'week(s)',
};

export const langRecurrence: { [index: string]: string } = {
  [RECURRENCE_TYPE.hourly]: 'hourly',
  [RECURRENCE_TYPE.daily]: 'daily',
  [RECURRENCE_TYPE.weekly]: 'weekly',
  [RECURRENCE_TYPE.monthly]: 'monthly',
  [RECURRENCE_TYPE.yearly]: 'yearly',
  mon: 'mon',
  tue: 'tue',
  wed: 'wed',
  thu: 'thu',
  fri: 'fri',
  sat: 'sat',
  sun: 'sun',
};

export const days: LabelValue[] = [
  {
    value: 1,
    label: langRecurrence.mon,
  },
  {
    value: 2,
    label: langRecurrence.tue,
  },
  {
    value: 3,
    label: langRecurrence.wed,
  },
  {
    value: 4,
    label: langRecurrence.thu,
  },
  {
    value: 5,
    label: langRecurrence.fri,
  },
  {
    value: 6,
    label: langRecurrence.sat,
  },
  {
    value: 0,
    label: langRecurrence.sun,
  },
];

export const RECURRENCES: LabelValue[] = [
  {
    value: RECURRENCE_TYPE.hourly,
    label: langRecurrence[RECURRENCE_TYPE.hourly],
  },
  {
    value: RECURRENCE_TYPE.daily,
    label: langRecurrence[RECURRENCE_TYPE.daily],
  },
  {
    value: RECURRENCE_TYPE.weekly,
    label: langRecurrence[RECURRENCE_TYPE.weekly],
  },
  {
    value: RECURRENCE_TYPE.monthly,
    label: langRecurrence[RECURRENCE_TYPE.monthly],
  },
  {
    value: RECURRENCE_TYPE.yearly,
    label: langRecurrence[RECURRENCE_TYPE.yearly],
  },
];
