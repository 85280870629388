import React, { memo, useEffect, useState } from 'react';
import Canvas from '@base/components/canvas';
import Portal from '@base/components/portal';
import CodeGeneratorSetting from './code-generator-setting';

interface CodeGeneratorProps {
  show: boolean;
  onHide: any;
  saveCb: any;
  onSettingChange: any;
  menu: string;
  defaultProps: any;
}

const CodeGeneratorCanvas: React.FC<CodeGeneratorProps> = (props: CodeGeneratorProps) => {
  const { show, onHide, saveCb, onSettingChange, menu, defaultProps } = props;

  //state
  const [showErr, setShowErr] = useState(false);
  const [errorData, setErrorData] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  //const [isInitLoading, setIsInitLoading] = useState(false);
  const [autoGenerate, setAutoGenerate] = useState(defaultProps?.autoGenerate);
  const [nextNumber, setNextNumber] = useState(defaultProps?.nextNumber || '');
  const [prefixString, setPrefixString] = useState(defaultProps?.prefixString || '');

  //get current settings
  useEffect(() => {
    // setIsInitLoading(true);
    // request(GET_CODE_NEXT_ID, { menu }).then((res) => {
    //     const { rows } = res?.getNextId;
    //     if (rows) {
    //         setAutoGenerate(rows.setting?.auto_generate);
    //         setPrefixString(rows.setting?.prefix_string);
    //         setNextNumber(rows.setting?.next_number);
    //     }
    //     setIsInitLoading(false);
    // });
  }, []);

  //check is number input
  const isNumeric = (value: string) => {
    return /^-?\d+$/.test(value);
  };

  //save success
  const handleSuccess = () => {
    // noty({
    //   title: 'Success',
    //   content: 'Code generator setting changed successfully.',
    //   type: 'success',
    // })();
    onHide();
    saveCb && saveCb();
  };

  //NEW: save setting
  const handleSave = async () => {
    //validate
    if (autoGenerate && !isNumeric(nextNumber)) {
      setErrorData('Please enter a valid next number');
      return setShowErr(true);
    }
    if (isLoading) return;
    if (showErr) setShowErr(false);

    //save setting
    // setIsLoading(true);
    // const newParams = {
    //   id: defaultProps?.id,
    //   value: JSON.stringify({
    //     autoGenerate,
    //     nextNumber,
    //     prefixString,
    //   }),
    // };
    // const res = await GraphQLv2(GO_SET_MENU_SETTING, { input: newParams });
    // //response
    // if (res && res.id) {
    //   handleSuccess();
    //   onSettingChange &&
    //     onSettingChange({
    //       autoGenerate,
    //       prefixString,
    //       nextNumber,
    //     });
    // } else {
    //   noty({
    //     title: 'Error',
    //     content: 'There is an error during saving!',
    //     type: 'danger',
    //   })();
    // }
    // setIsLoading(false);
  };

  //save buttons
  const renderSaveButton = () => {
    return (
      <div className="btn-group dropup mg-l-5">
        <button
          type="button"
          className="btn btn-primary mg-r-5"
          onClick={handleSave}
          disabled={isLoading || !defaultProps?.id}
        >
          Save
          {isLoading && (
            <span
              className="spinner-border spinner-border-sm mg-l-5"
              role="status"
              aria-hidden="true"
            />
          )}
        </button>
      </div>
    );
  };

  //main render
  return (
    <Portal>
      <Canvas
        isVisible={show}
        onCloseSideBar={onHide}
        className={'wd-550'}
        customStyles={{ zIndex: '1052' }}
        backdropStyles={{ zIndex: '1051' }}
      >
        <Canvas.Header title={'Code Generator Setting'} />
        <Canvas.Body>
          <div className={'ht-100p wd-100p d-flex flex-column'}>
            <CodeGeneratorSetting
              //isInitLoading={isInitLoading}
              autoGenerate={autoGenerate}
              setAutoGenerate={setAutoGenerate}
              nextNumber={nextNumber}
              setNextNumber={setNextNumber}
              prefixString={prefixString}
              setPrefixString={setPrefixString}
            />
            {showErr && <p className="tx-danger pd-t-10">* {errorData}</p>}
          </div>
        </Canvas.Body>
        <Canvas.Footer closeLabel="Close" onClose={onHide} saveBtn={renderSaveButton()} />
      </Canvas>
    </Portal>
  );
};

export default CodeGeneratorCanvas;
