import React from 'react';
import classNames from 'classnames';
import { useTable } from 'react-table';
import { NoData } from '@base/components';
interface ITableProps {
  columns: any;
  data: any;
  className?: string;
  wrapperClassName?: string;
  getCellProps?: any;
  isLoading?: boolean;
}
// Create a default prop getter
const defaultPropGetter = () => ({});

function useInstance(instance: any) {
  const { allColumns } = instance;
  let rowSpanHeaders: any[] = [];
  allColumns.forEach((column: any, i: any) => {
    const { id, enableRowSpan } = column;
    if (enableRowSpan !== undefined) {
      rowSpanHeaders = [...rowSpanHeaders, { id, topCellValue: null, topCellIndex: 0 }];
    }
  });
  Object.assign(instance, { rowSpanHeaders });
}

const thStyle = {};

const tdStyle = {
  verticalAlign: 'middle',
};

const BasicTable: React.FC<ITableProps> = (props: ITableProps) => {
  const {
    columns,
    data,
    className = '',
    wrapperClassName = 'bg-white bd rounded',
    getCellProps = defaultPropGetter,
    isLoading = false,
  } = props;
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, rowSpanHeaders } =
    useTable(
      {
        columns,
        data,
      },
      (hooks) => {
        hooks.useInstance.push(useInstance);
      },
    );

  // Render the UI for your table
  return (
    <div className={classNames('table-responsive', wrapperClassName)}>
      <table
        {...getTableProps()}
        className={classNames('table table-bordered dataTable mg-b-0 bd-0', className)}
      >
        <thead>
          {headerGroups.map((headerGroup: any, index: number) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, index: number) => (
                <th key={index} {...column.getHeaderProps()} style={thStyle}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading && (
            <tr>
              <td colSpan={columns?.length}>
                <div className="wrap-hanloading">
                  <div className="han-loading" />
                </div>
              </td>
            </tr>
          )}
          {!isLoading && rows?.length <= 0 && (
            <tr>
              <td colSpan={columns?.length}>
                <NoData />
              </td>
            </tr>
          )}
          {!isLoading &&
            rows.map((row: any, i: any) => {
              prepareRow(row);
              for (let j = 0; j < row.allCells.length; j++) {
                let cell: any = row.allCells[j];
                let rowSpanHeader = rowSpanHeaders.find((x: any) => x.id === cell.column.id);
                if (rowSpanHeader !== undefined) {
                  if (
                    rowSpanHeader.topCellValue === null ||
                    rowSpanHeader.topCellValue !== cell.value
                  ) {
                    cell.isRowSpanned = false;
                    rowSpanHeader.topCellValue = cell.value;
                    rowSpanHeader.topCellIndex = i;
                    cell.rowSpan = 1;
                  } else {
                    let cell2: any = rows[rowSpanHeader.topCellIndex].allCells[j];
                    cell2.rowSpan++;
                    cell.isRowSpanned = true;
                  }
                }
              }
              return null;
            })}
          {!isLoading &&
            rows.map((row: any, index: number) => {
              return (
                <tr key={index} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    if (cell.isRowSpanned) return null;
                    else
                      return (
                        <td
                          style={tdStyle}
                          rowSpan={cell.rowSpan}
                          {...cell.getCellProps()}
                          width={cell?.column?.width}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
    // <table {...getTableProps()} className={classNames('table dataTable mg-b-0', className)}>
    //   <thead>
    //     {headerGroups.map((headerGroup: any, index: any) => (
    //       <tr {...headerGroup.getHeaderGroupProps()} key={index}>
    //         {headerGroup.headers.map((column: any, cIdx: any) => (
    //           <th {...column.getHeaderProps()} key={cIdx}>
    //             {column.render('Header')}
    //           </th>
    //         ))}
    //       </tr>
    //     ))}
    //   </thead>
    //   <tbody {...getTableBodyProps()}>
    //     {isLoading && (
    //       <tr>
    //         <td colSpan={columns?.length}>
    //           <div className="d-flex justify-content-center">loading...</div>
    //         </td>
    //       </tr>
    //     )}
    //     {!isLoading &&
    //       rows.map((row, i) => {
    //         prepareRow(row);
    //         return (
    //           <tr {...row.getRowProps()} key={i}>
    //             {row.cells.map((cell: any, index: any) => {
    //               return (
    //                 <td
    //                   key={index}
    //                   {...cell.getCellProps([
    //                     getCellProps({
    //                       row,
    //                       cell,
    //                       rowIndex: i,
    //                       cellIndex: index,
    //                     }),
    //                   ])}
    //                 >
    //                   {cell.render('Cell')}
    //                 </td>
    //               );
    //             })}
    //           </tr>
    //         );
    //       })}
    //   </tbody>
    // </table>
  );
};

export default BasicTable;
