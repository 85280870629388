import { Button, Input } from '@base/components/form';
import HanGroupButton from '@base/components/han-group-button';
import classNames from 'classnames';
import React, { useState } from 'react';

interface ITextEditableProps {
  value: string;
  onClose: () => void;
  onSave: (params: any) => void;
  isSaving: boolean;
  setIsSaving: (isSaving: boolean) => void;
}
const TextEditable: React.FC<ITextEditableProps> = (props: ITextEditableProps) => {
  const { value, onClose, onSave, isSaving = false, setIsSaving } = props;
  const [inputValue, setInputValue] = useState(value);
  // const [isSaving, setIsSaving] = useState(false);
  // const [isValid, setIsValid] = useState(true);
  return (
    <>
      <div className="d-flex component-edit wd-100p">
        <div className="col pd-r-10 pd-l-0 mg-0">
          <input
            className="form-control editable-input"
            value={inputValue}
            autoFocus
            onChange={(e: any) => {
              setInputValue(e.target.value);
            }}
          />
        </div>
        {/* <div className="col-auto d-flex justify-content-end pd-0 mg-0">
          <Button
            color="success"
            className="mg-r-5"
            icon="Check"
            size="xs"
            isSpanTitle={false}
            onClick={() => onSave && onSave(inputValue)}
            loading={isSaving}
          />
          <Button
            color="light"
            icon="X"
            size="xs"
            isSpanTitle={false}
            onClick={() => {
              onClose && onClose();
            }}
          />
        </div> */}
        <HanGroupButton
          className="mg-l-5"
          onClose={() => onClose && onClose()}
          onSave={() => {
            onSave && onSave(inputValue);
          }}
          isValid={inputValue == ''}
          isSaving={isSaving}
        />
      </div>
    </>
  );
};

export default TextEditable;
