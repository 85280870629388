import React, { useEffect, useState } from 'react';
import grapesjs from 'grapesjs';
// import gjsForms from 'grapesjs-plugin-forms';
// import gjsBlocksBootstrap4 from 'grapesjs-blocks-bootstrap4';

import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs/dist/grapes.min.js';

// import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css';
// import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js';

// import 'grapesjs-plugin-forms/dist/grapesjs-plugin-forms.min.js'; //TODO: will be removed

import './assets/scss/main.scss';
import './assets/scss/tooltip.scss';

import './storage/indexeddb';
import './plugins';

//sample
// Lets say, for instance, you start with your already defined HTML template and you'd like to
// import it on fly for the user
import LandingPage from './assets/sample';

interface GrapesTSProps {
  isFullScreen?: boolean;
  value?: any; //{html: '', css: ''}
  height?: string;
  width?: string;
  storageId?: string; //indexedb name
  templateType?: 'full' | 'basic' | 'simple' | 'medium'; //'advance'
  children?: any;
  onChange?: any;
}

const GrapesTS: React.FC<GrapesTSProps> = (props: GrapesTSProps) => {
  const {
    templateType = 'full',
    isFullScreen = false,
    value,
    height,
    width,
    storageId,
    children,
    onChange,
  } = props;
  const [editor, setEditor] = useState<any>(null);
  //// console.log('grapes value', value);

  //set width of blocks for screen size
  function setBlockWidth() {
    if (isFullScreen) {
      const classElements: any = document.getElementsByClassName('gjs-block');
      //// console.log('classElements', classElements[0]);
      for (let i = 0; i < classElements.length; i++) {
        classElements[i].style.width = '27%';
      }
    } else {
      const classElements: any = document.getElementsByClassName('gjs-block');
      //// console.log('classElements', classElements[0]);
      for (let i = 0; i < classElements.length; i++) {
        classElements[i].style.width = '43%';
      }
    }
  }

  useEffect(() => {
    //init
    if (!editor) {
      const gEditor = grapesjs.init({
        container: `#gts-${storageId}`, //'#gts',
        showOffsets: 1,
        noticeOnUnload: 0,

        // Get the content for the canvas directly from the element
        // As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
        fromElement: false,

        //init template - LandingPage: TEST DATA --> using value.html and value.css
        components: value?.html || '', //LandingPage.html,
        style: value?.css || '', //LandingPage.css,

        // Size of the editor
        height: height ? height : '100%',
        width: width ? width : 'auto',
        // Disable the storage manager for the moment
        storageManager: {
          id: storageId ? storageId : 'gts-store',
          type: 'indexeddb',
          //autosave: false,
          //autoload: false,
        },
        styleManager: {
          sectors: [],
        },
        // Avoid any default panel
        panels: { defaults: [] },
        // plugins: [
        //   (editor: any) =>
        //     gjsBlocksBootstrap4(editor, {
        //       /* options */
        //     }),
        // ],
        plugins: ['gts-form-webpage', 'grapests-indexeddb'], //'gts-form-webpage', 'gjs-blocks-basic', 'gjs-preset-webpage', 'grapesjs-plugin-forms'
        pluginsOpts: {
          'gts-form-webpage': {
            //isFullScreen: isFullScreen
            templateType: templateType,
          },
        },
        //import third js/css
        // canvas: {
        //   scripts: [
        //     'https://uicdn.toast.com/tui.code-snippet/v1.5.2/tui-code-snippet.min.js',
        //     'https://uicdn.toast.com/tui.time-picker/latest/tui-time-picker.min.js',
        //     'https://uicdn.toast.com/tui.date-picker/latest/tui-date-picker.min.js',
        //     'https://uicdn.toast.com/tui-calendar/latest/tui-calendar.js',
        //   ],
        //   styles: [
        //     'https://uicdn.toast.com/tui-calendar/latest/tui-calendar.css',
        //     'https://uicdn.toast.com/tui.date-picker/latest/tui-date-picker.css',
        //     'https://uicdn.toast.com/tui.time-picker/latest/tui-time-picker.css',
        //   ],
        // },
        //more
        //...options,
      });
      //set custom style
      gEditor.StyleManager.addProperty('extra', 'filter');
      gEditor.StyleManager.addProperty('extra', { extend: 'filter', property: 'backdrop-filter' });

      //local state
      setEditor(gEditor);
    }

    //clean up
    return () => {
      if (editor) {
        editor.destroy();
      }
    };
  }, []);

  //track full screen
  useEffect(() => {
    //// console.log('isFullScreen', isFullScreen);
    if (editor) {
      setBlockWidth();
    }
  }, [isFullScreen]);

  //init - track changes
  if (editor) {
    editor.on('update', () => {
      //// console.log('getHtml', editor.getHtml());
      //// console.log('getCss', editor.getCss());
      const newHtml = editor.getHtml();
      const newCss = editor.getCss();
      const newBody = editor.Canvas.getBody();
      //// console.log('newBody', newBody);
      //callback
      onChange && onChange({ html: newHtml, css: newCss, body: newBody });
    });

    editor.on('load', () => {
      setBlockWidth();
    });
  }

  //render
  return <div id={`gts-${storageId}`}>{children}</div>;
};

export default GrapesTS;
