import React, { useState, useEffect, useRef, Fragment } from 'react';
import { ChevronDown, Search, Plus } from 'react-feather';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import CoreTree from '../tree/core/tree';
import TagName from '@base/components/form/tag-name';
import useDidUpdateEffect from '@base/utils/helpers/use-did-update-effect';

//import useScrollbar from "Crm/utils/useScrollbar";

import './styles.scss';

interface TreeDropdownProps {
  placeholder?: string;
  value: any; //[]: multiple, {}: single
  onChange: any;
  single?: boolean; //true / false
  isSearchable?: boolean;
  isLoading?: boolean;
  fetchTree: any;
  formatTreeNode: any;
  addLabel?: any;
  onAdd?: any;
  onClose?: any;
}

const TreeDropdown: React.FC<TreeDropdownProps> = (props: TreeDropdownProps) => {
  const {
    placeholder = 'Select...',
    value, //[]: multiple, {}: single
    onChange,
    single = false, //true / false
    isSearchable = false,
    isLoading,
    fetchTree,
    formatTreeNode,
    addLabel,
    onAdd,
    onClose,
  } = props;

  //state
  const [show, setShow] = useState(false); //show dropdown
  const [items, setItems] = useState<any>([]);
  const [shortLength, setShortLength] = useState(0);
  const [shortDisplay, setShortDisplay] = useState(false);
  //refs
  const dropdownRef = useRef<any>(null); //useScrollbar();
  const valueRef = useRef<HTMLInputElement>(null);
  const treeRef = useRef<any>();
  const treeDDRef = useRef<any>(null);
  const treeItemsRef = useRef<any>(null);
  //lang
  const { t } = useTranslation();
  useDidUpdateEffect(() => {
    if (!show) {
      onClose && onClose(items);
    }
  }, [show]);

  //set default items
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(items)) {
        setItems(value);
      }
    } else {
      setItems([]);
    }
  }, [value]);

  //outside click dropdown
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef && !dropdownRef?.current?.contains(event?.target)) {
        setShow(false);
      }
    };
    window?.addEventListener('mousedown', handleClickOutside);
    return () => {
      window?.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  //short display
  useEffect(() => {
    // // console.log('treeDDRef width', treeDDRef.current ? treeDDRef.current.offsetWidth : 0);
    // // console.log('treeItemsRef width', treeItemsRef.current ? treeItemsRef.current.offsetWidth : 0);

    if (treeDDRef.current) {
      const $el = $(treeDDRef.current);
      const treeDDWidth = treeDDRef.current ? treeDDRef.current.offsetWidth : 0;
      const treeItemsWidth = treeItemsRef.current ? treeItemsRef.current.offsetWidth : 0;

      let shortIndex = -1;
      $el
        .find('.o-hidden')
        .find('.crm-tag-name')
        .each(function (this: HTMLElement, index: number) {
          let offset = $(this).position().left + ($(this).width() || 0);
          if (shortIndex === -1 && offset > treeDDWidth) {
            shortIndex = index - 1;
          }
        });

      setShortDisplay(shortIndex > -1);
      setShortLength(shortIndex);

      // if(treeDDWidth - treeItemsWidth <= 160) {
      //     setShortDisplay(true);
      //     setShortLength(items.length - 1);
      // } else if(shortDisplay > 0 && shortDisplay < (items.length - 1)) {
      //     setShortDisplay(true);
      // } else {
      //     setShortDisplay(false);
      //     setShortLength(0);
      // }
    }
  }, [items]);

  //set values changes
  const handleSelectChange = (data: any, node: any) => {
    //parent node is checked
    if (node?.checked === 1 && node?.children && node?.children?.length > 0) {
      //only get parent node
      const newItems: any = [...items];
      newItems.push(node);
      setItems(newItems);
      //callback
      onChange && onChange(newItems);
    } else {
      //current child node is checked
      //find out parent node is included in data, then remove all child node
      const idx = data.findIndex((el: any) => el.id === node.parent_id);
      if (idx > -1) {
        //exist parent node (mean all children are checked)
        //only select parent node, remove all child nodes
        const newData: any = [...data];
        newData[idx].children.map((child: any) => {
          const cIdx = newData.findIndex((el: any) => el.id === child.id);
          newData.splice(cIdx, 1);
        });
        //check for other nodes in selected items, only keep parent node if all child are checked
        items.map((item: any) => {
          if (item.children && item.children.length > 0) {
            //this is parent node
            const pIdx = newData.findIndex((el: any) => el.id === item.id);
            if (pIdx > -1) {
              //exist in return list
              //remove all it's chilrent
              item.children.map((iChild: any) => {
                const icIdx = newData.findIndex((el: any) => el.id === iChild.id);
                if (icIdx > -1) {
                  newData.splice(icIdx, 1);
                }
              });
            }
          }
        });
        //set new selected items
        setItems(newData);
        //callback
        onChange && onChange(newData);
      } else {
        //keep current seleted items, add new selected nodes
        const newData: any = [...data];
        items.map((item: any) => {
          if (item.children && item.children.length > 0) {
            //this is parent node
            const pIdx = newData.findIndex((el: any) => el.id === item.id);
            if (pIdx > -1) {
              //exist in return list
              //remove all it's chilrent
              item.children.map((iChild: any) => {
                const icIdx = newData.findIndex((el: any) => el.id === iChild.id);
                if (icIdx > -1) {
                  newData.splice(icIdx, 1);
                }
              });
            }
          }
        });
        setItems(newData);
        //callback
        onChange && onChange(newData);
      }
    }

    if (single) {
      setShow(false);
    }
  };

  //remove item
  const handleRemove = (item: any) => {
    let newItems = [...items];
    const idx = newItems.findIndex((el: any) => el.id === item.id);
    if (idx > -1) {
      newItems.splice(idx, 1);
      setItems(newItems);
      //update tree
      treeRef.current?.onUpdateCheckBox(newItems);
      //callback
      onChange && onChange(newItems);
    }
  };

  //handle when single = true
  const handleTreeNodeClick = (node: any) => {
    if (single) {
      setItems([node]);
      setShow(false);
      //callback
      onChange && onChange([node]);
    }
  };

  //render
  return (
    <div className="pos-relative w-100">
      {/* selected items */}
      <div
        id="tree-container"
        ref={treeDDRef}
        className={classNames('dropdown tree-dd-container', { 'ht-40': items?.length == 0 })}
      >
        <div id="tree-items" className="d-flex w-100">
          {!single && (
            <div ref={treeItemsRef} className="d-flex">
              {/* display items */}
              {items.map(
                (item: any, index: number) =>
                  (!shortDisplay || (shortDisplay && index < shortLength)) && (
                    <TagName
                      key={index}
                      name={item?.title || item?.name}
                      onDelete={() => handleRemove(item)}
                    />
                  ),
              )}
              {/* hidden items */}
              {shortDisplay && (
                <div className="tree-dd-tag">
                  <span className="tx-normal" style={{ whiteSpace: 'nowrap' }}>
                    +{items.length - shortLength}...
                  </span>
                </div>
              )}

              <div className="o-hidden d-flex h-100">
                {items.map((item: any, index: number) => (
                  <TagName
                    key={index}
                    name={item?.title || item?.name}
                    onDelete={() => handleRemove(item)}
                  />
                ))}
              </div>
            </div>
          )}
          {/* input+dropdown */}
          <div className={classNames('w-100', { show: show })}>
            <input
              //id="ddmInput"
              type="text"
              ref={valueRef}
              className="form-control w-100"
              style={{
                maxWidth: items?.length === 0 || single ? 'calc(100% - 40px)' : '150px',
                boxShadow: 'unset',
                border: '0px',
              }}
              autoComplete="off"
              // placeholder={items?.length === 0 ? placeholder : ''}
              placeholder={placeholder}
              onClick={() => setShow(true)}
              defaultValue={
                single && items?.length > 0 && items[0]
                  ? items[0]?.title || (t(items[0]?.languageKey) as string)
                  : ''
              }
              readOnly={!isSearchable}
            />
            <div
              className={classNames('dropdown-menu bd-0 overflow-y-auto w-100', { show: show })}
              x-placement="top-start"
              style={{
                maxHeight: '250px',
                position: 'absolute',
                top: '38px',
                left: '0px',
                transition: 'all 0.3s',
                boxShadow: '0 6px 12px rgba(0,0,0,.175)',
              }}
              ref={dropdownRef}
            >
              {onAdd && addLabel.length > 0 && (
                <div className="d-flex justify-content-center align-items-center bd-b">
                  <button type="button" className="btn btn-link link-03" onClick={onAdd}>
                    <Plus /> {addLabel}
                  </button>
                </div>
              )}
              {show && (
                <CoreTree
                  ref={treeRef}
                  hasCheckBox={!single}
                  parsedNode={formatTreeNode}
                  source={fetchTree}
                  value={items}
                  onChecked={handleSelectChange}
                  onClickTitle={handleTreeNodeClick}
                />
              )}
            </div>
          </div>
        </div>
        <div className="pos-absolute" style={{ top: '10px', right: '10px' }}>
          <div className="d-flex align-items-center">
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm tx-gray-300 mg-r-10"
                role="status"
                aria-hidden="true"
              />
            )}
            <div
              className="d-flex align-items-center pd-r-5 pd-l-10 bd-l bd-gray-300"
              style={{ height: '16px' }}
            >
              {show ? (
                <Search className="tx-gray-300" style={{ width: '16px', height: '16px' }} />
              ) : (
                <ChevronDown
                  onClick={() => setShow(true)}
                  className="tx-gray-300"
                  style={{ width: '16px', height: '16px' }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreeDropdown;
