import React from 'react';
import TextView from '@base/containers/view-field/text/view';
import { useTranslation } from 'react-i18next';
// import { SpanLang } from '@base/components';
// import { OptionValue } from '@base/types/interfaces/common';

// interface IRadioViewProps {
//   value: OptionValue;
// }

const RadioView: React.FC<any> = (props) => {
  const { value } = props;
  const { t } = useTranslation();

  return <TextView value={value?.languageKey ? t(value.languageKey) : value} />;
};

export default RadioView;
