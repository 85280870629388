import { graphQLApi } from '@base/utils/axios/graphql';
import { gql } from 'graphql-request';
import usePost from '@base/hooks/usePost';
import { keyStringify } from '@base/utils/helpers';

interface Query {
  menu: string;
  schemas: string;
  params: any;
  enabled?: boolean;
  menuKey?: string[];
}

export const getQuery = (props: Pick<Query, 'menu' | 'schemas'>) => {
  const { menu, schemas } = props;
  return gql`
    query q($id: String!) {
      ${menu}(id: $id) {
        ${schemas}
      }
    }
  `;
};

export const getView = async <T>(props: Exclude<Query, 'enabled'>) => {
  const { menu, schemas, params } = props;
  const query = getQuery({ menu, schemas });
  const res = await graphQLApi<T>(menu, query, params);
  return res;
};

export const useGetView = <T>(props: Query) => {
  const { menu, schemas = '', params = {}, enabled = true } = props;
  const query = getQuery({ menu, schemas });
  const menuKey = props.menuKey ?? [menu, keyStringify(params, '')];

  const response = usePost<T>(menuKey, query, params, {
    enabled: schemas != '' && params?.id?.length > 0 && enabled,
    cacheTime: 0, // Don't cache
  });
  return response;
};
