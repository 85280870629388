import React, { useEffect, useState } from 'react';
import { DatePicker } from '@base/components/date-picker';

interface DateSelectorProps {
  value: any;
  onChange(date: any): void;
}

const DateSelector: React.FC<DateSelectorProps> = (props: DateSelectorProps) => {
  const { value = new Date().toISOString(), onChange, ...rest } = props;

  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (value && value != '') {
      setSelected(value);
    }
  }, [value]);

  // value change
  const handleDateChange = (date: Date) => {
    const dateStr = date.toISOString();
    setSelected(dateStr);
    onChange && onChange(dateStr);
  };

  return (
    <DatePicker
      selected={selected != '' ? new Date(selected) : new Date()}
      onChange={handleDateChange}
      {...rest}
    />
  );
};

export default DateSelector;
