import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
//import { withRouter } from 'react-router-dom';
import styled from '@emotion/styled';
import $ from 'jquery';

const NavItem = styled.div`
  .nav-link {
    &.active,
    &:hover {
      cursor: pointer;
      background-color: #c7c7c726;
    }

    border-radius: 3px;
    padding: 5px;

    &[aria-expanded='true'] {
      & + .arrow-toggle {
        transform: rotate(90deg);
      }
    }
  }

  .arrow-toggle {
    position: absolute;
    right: 8px;
    top: 8px;
    display: none;
  }

  .panel-collapse {
    ul {
      opacity: 1 !important;
      margin-left: 20px;
      border-left: 1px solid #f2f2f2;
      padding-left: 20px;
    }
  }
`;

interface MenuItemProps {
  active: boolean;
  withSub: boolean;
  show: boolean;
  children: any;
}

const MenuItem = (props: MenuItemProps) => {
  const { active, withSub, show, children } = props;
  const [isActive, setActive] = useState(active);
  const el = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!active) {
      if ($(el.current as any).find('.nav-link.active').length) {
        setActive(true);
      } else {
        setActive(false);
      }
    }
  }, [active]);

  return (
    <NavItem
      ref={el}
      className={classNames('nav-item', { active: isActive, 'with-sub': withSub, show })}
    >
      {children}
    </NavItem>
  );
};

export default MenuItem;
