import React from 'react';

interface LoadingProps {
  top?: number;
}

const Loading = (props: LoadingProps) => {
  const { top = 0 } = props;
  return (
    <div className="wrap-hanloading" style={{ top: `${top || 0}px` }}>
      <div className="han-loading" />
    </div>
  );
};

export default Loading;
