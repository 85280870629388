import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

interface Props {
  value: any;
  className?: string;
}

const Container = styled.div``;

const View: React.FC<Props> = (props: Props) => {
  const { className, value } = props;

  return (
    <Container className="crm-view-detail">
      <div className={classnames(className, {})}>
        <input type="color" value={value} disabled />
      </div>
    </Container>
  );
};

View.defaultProps = {
  className: '',
};

export default View;
