import React, { useEffect, useState } from 'react';
import { Select } from '@base/components/form';

type SelectBox = {
  label: string;
  value: string;
};

type StaticSelectProps = {
  className: string;
  value: string;
  options: SelectBox[];
  onChange: (val: string) => void;
};

const StaticSelect: React.FC<StaticSelectProps> = (props: StaticSelectProps) => {
  const { className, value, options, onChange } = props;

  //state
  const [selectedValue, setSelectedValue] = useState(value);

  const handleValueChange = (newValue: string) => {
    setSelectedValue(newValue);
    //callback
    onChange && onChange(newValue);
  };

  //render
  return (
    <Select
      className={className}
      outSide={true}
      options={options}
      selected={selectedValue}
      defaultValue={selectedValue}
      onChange={handleValueChange}
    />
  );
};

export default StaticSelect;
