import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

import dayjs from 'dayjs';
import { icons } from '@base/assets/icons/svg-icons';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { ACTIVITY_MENU_KEYS } from '@activity/config/constants';

export interface ISchedule {
  id: string;
  start: Date;
  end: Date;
  title: string;
  editable: boolean;
  extendedProps: {
    category: string;
    source: {
      id: string;
      menu: string;
    };
  };
}

const CalendarHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0.625rem 0;
`;

type CalendarProps = {
  schedules: ISchedule[];
  children?: React.ReactNode;
  currentDate: string;
  setCurrentDate: (date: string) => void;
};

enum CalendarViewType {
  'MONTH' = 'dayGridMonth',
  'WEEK' = 'dayGridWeek',
  'DAY' = 'timeGridDay',
}

function Calendar(props: CalendarProps) {
  // console.log('Base Calendar', props);
  const calRef = useRef(null);
  const navigate = useNavigate();

  const { schedules, currentDate, setCurrentDate } = props;
  const [viewType, setViewType] = useState(CalendarViewType.MONTH);
  const [dateTitle, setDateTitle] = useState('');

  let firstDay = currentDate;
  let lastDay = currentDate;
  const tmpDate = new Date(currentDate);
  if (viewType == CalendarViewType.WEEK) {
    firstDay = dayjs(new Date(tmpDate.setDate(tmpDate.getDate() - tmpDate.getDay()))).format(
      'YYYY-MM-DD',
    );
    lastDay = dayjs(new Date(tmpDate.setDate(tmpDate.getDate() - tmpDate.getDay() + 6))).format(
      'YYYY-MM-DD',
    );
  } else if (viewType == CalendarViewType.MONTH) {
    firstDay = dayjs(new Date(tmpDate.getFullYear(), tmpDate.getMonth(), 1)).format('YYYY-MM-DD');
    lastDay = dayjs(new Date(tmpDate.getFullYear(), tmpDate.getMonth() + 1, 0)).format(
      'YYYY-MM-DD',
    );
  }

  const goToday = useCallback(() => {
    const calendar = calRef.current as any;
    calendar.getApi().today();
    setCurrentDate(dayjs(new Date()).format('YYYY-MM-DD'));
  }, [calRef]);

  const getTitle = useCallback(() => {
    // console.log('render date', viewType, firstDay, lastDay);
    if (viewType == CalendarViewType.MONTH) {
      return dayjs(firstDay).format('YYYY-MM');
    } else if (viewType == CalendarViewType.WEEK) {
      return firstDay + ' ~ ' + lastDay;
    } else {
      return firstDay;
    }
  }, [firstDay, lastDay, viewType]);

  useEffect(() => {
    setDateTitle(getTitle());
  }, [firstDay, lastDay, viewType]);

  const onClickSchedule = useCallback(
    (info: any) => {
      // console.log(info.event.extendedProps);
      const { id, extendedProps: raw } = info.event;
      if (raw.category == 'CATEGORY_PROCESS') {
        if (raw.source.menu == 'MENU_QUOTE') {
          navigate(`/sales/quote/all/${raw.source.id}`);
        }
      } else {
        const type = ACTIVITY_MENU_KEYS[raw.category];
        navigate(`/activity/mywork/${type}_${id}`);
      }
    },
    [calRef],
  );

  const changeView = useCallback(
    (view: CalendarViewType) => {
      const calendar = calRef.current as any;
      calendar.getApi().changeView(view);
      setViewType(view);
    },
    [calRef],
  );

  const previousView = useCallback(() => {
    const calendar = calRef.current as any;
    const api = calendar.getApi();
    api.prev();
    const renderedDate = api.getDate();
    setCurrentDate(dayjs(renderedDate).format('YYYY-MM-DD'));
  }, [calRef]);

  const nextView = useCallback(() => {
    const calendar = calRef.current as any;
    const api = calendar.getApi();
    api.next();
    const renderedDate = api.getDate();
    setCurrentDate(dayjs(renderedDate).format('YYYY-MM-DD'));
  }, [calRef]);

  const CalendarMemo = useMemo(() => {
    return (
      <FullCalendar
        ref={calRef}
        initialView="dayGridMonth"
        plugins={[dayGridPlugin, timeGridPlugin]}
        themeSystem={'bootstrap'}
        headerToolbar={false}
        events={schedules}
        eventClick={onClickSchedule}
      />
    );
  }, [schedules]);

  return (
    <>
      <CalendarHeader>
        <h2 className="mg-b-0 mg-r-10 tx-20">{dateTitle}</h2>
        <button
          className="btn btn-sm btn-white btn-icon mg-l-5"
          data-han-tooltip="previous"
          onClick={() => previousView()}
        >
          {icons['calendar_arrow_previous']}
        </button>
        <button
          className="btn btn-sm btn-white btn-icon mg-l-5"
          data-han-tooltip="next"
          onClick={() => nextView()}
        >
          {icons['calendar_arrow_next']}
        </button>
        <button className="btn btn-sm btn-white mg-l-5" onClick={() => goToday()}>
          Today
        </button>
        <div className="btn-group mg-l-auto">
          <button
            className={classNames('btn btn-sm btn-white', {
              active: viewType == CalendarViewType.MONTH,
            })}
            onClick={() => changeView(CalendarViewType.MONTH)}
          >
            Month
          </button>
          <button
            className={classNames('btn btn-sm btn-white', {
              active: viewType == CalendarViewType.WEEK,
            })}
            onClick={() => changeView(CalendarViewType.WEEK)}
          >
            Week
          </button>
          <button
            className={classNames('btn btn-sm btn-white', {
              active: viewType == CalendarViewType.DAY,
            })}
            onClick={() => changeView(CalendarViewType.DAY)}
          >
            Day
          </button>
        </div>
      </CalendarHeader>
      {CalendarMemo}
    </>
  );
}

export default React.memo(Calendar);
