import React, { useEffect, useState } from 'react';
import { Download, Trash, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { IAttachment, IAttachmentsResponse } from '@base/types/interfaces/attachment';
import { confirmAlert } from '@base/components/confirm-alert';
import { Image } from '@base/components/form';
import {
  convertDateTimeServerToClient,
  getFileIcon,
  humanFileSize,
} from '@base/utils/helpers/general.utils';
//import { FormIcon } from '@base/components/form';
import { SvgIcons } from '@base/assets/icons/svg-icons';
import { SpanLang } from '@base/components';
import {
  useDeleteObjectMutation,
  useDownloadObjectMutation,
} from '@base/hooks/useFileUploadMutation';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import useMutationPost from '@base/hooks/useMutationPost';
import { ATTACHMENT_DELETE_ITEM } from '@base/services/graphql/attachment';
//import { MENU_SOURCE } from '@base/config/menus';
//import { useQueryClient } from 'react-query';
import { useQueryClient } from '@tanstack/react-query'; //v4

//convert string to blob
// const decodeBase64ToBlob = (byteCharacters: string, contentType?: string) => {
//   const byteNumbers = new Array(byteCharacters.length);
//   for (let i = 0; i < byteCharacters.length; i++) {
//     byteNumbers[i] = byteCharacters.charCodeAt(i);
//   }
//   const byteArray = new Uint8Array(byteNumbers);
//   const blob = new Blob([byteArray]); //{ type: contentType }
//   return blob;
// }

//decode base64
// const decodeFileBase64 = (base64String: string) => {
//   return decodeURIComponent(
//     atob(base64String)
//       .split("")
//       .map((c: string) => {
//         return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
//       })
//       .join("")
//   );
// }

interface AttachmentCard {
  listType: string;
  menuSource: string;
  menuSourceId: string;
  item: IAttachment;
  onRemoveItem: (id: string) => void;
}

/**
 *
 * @param {*} props
 * @returns
 */
const AttachmentCard = (props: AttachmentCard) => {
  const { listType = 'grid', menuSource, menuSourceId, item, onRemoveItem } = props;

  //recoil
  //const menuSource = MENU_SOURCE[menuSource];
  const queryClient = useQueryClient();
  let queryKeys = ['builtin_attachments', menuSource, menuSourceId];

  //lang
  const { t } = useTranslation();
  const [downloadItem, setDownloadItem] = useState<any>(null);
  const [deleteItem, setDeleteItem] = useState<any>(null);

  //download mutation
  const mDownload: any = useDownloadObjectMutation<BaseMutationResponse>({
    onSuccess: (data: any, variables: any, context: any) => {
      //toast.success('Uploaded successfully!');
    },
    onError: (error: any, variables: any, context: any) => {
      //// console.log('mutation error', error);
      toast.error('Downloaded failed: ' + JSON.parse(error).message);
    },
  });

  //delete mutation
  const mDeleteUpload: any = useDeleteObjectMutation<BaseMutationResponse>({
    onSuccess: (data: any, variables: any, context: any) => {
      //toast.success('Uploaded successfully!');
    },
    onError: (error: any, variables: any, context: any) => {
      //// console.log('mutation error', error);
      toast.error('Deleted upload failed: ' + JSON.parse(error).message);
    },
  });

  //delete in DB
  const mDeleteAttachment: any = useMutationPost<BaseMutationResponse>(
    ATTACHMENT_DELETE_ITEM,
    'builtin_deleteAttachment',
    {
      onSuccess: (res: any) => {
        //toast.success('Attachment deleted!');
      },
      onError: (error: any, variables: any, context: any) => {
        //// console.log('mutation error', error);
        toast.error('Deleted attachment failed: ' + JSON.parse(error).message);
      },
    },
  );

  //download success
  useEffect(() => {
    //// console.log('<<< download completed useEffect >>>', mDownload);
    //reference: https://base64.guru/converter/encode/image
    if (mDownload.isSuccess) {
      let link = window.document.createElement('a');
      link.href = mDownload.data;
      link.download = downloadItem.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      //reset
      setDownloadItem(null);
    }
  }, [mDownload.isSuccess]);

  //delete upload success
  useEffect(() => {
    //// console.log('<<< deleted upload useEffect >>>', mDeleteUpload);
    if (mDeleteUpload.isSuccess) {
      //delete in DB
      const params = {
        source: {
          menu: menuSource,
          id: menuSourceId,
        },
        id: deleteItem.id,
      };
      mDeleteAttachment.mutate(params);
    }
  }, [mDeleteUpload.isSuccess]);

  //delete DB success
  useEffect(() => {
    //// console.log('<<< deleted DB useEffect >>>', mDeleteAttachment);
    if (mDeleteAttachment.isSuccess) {
      //delete in query data - cache
      let response = queryClient.getQueryData<IAttachmentsResponse>(queryKeys);
      if (response?.results) {
        response.results.forEach((item: IAttachment, index: number) => {
          if (item.id === deleteItem.id) {
            response?.results.splice(index, 1);
          }
        });
      }
      queryClient.setQueryData(queryKeys, response);

      //delete in state
      onRemoveItem && onRemoveItem(deleteItem.id);

      //reset
      setDeleteItem(null);
    }
  }, [mDeleteAttachment.isSuccess]);

  //download 1 file
  const handleDownloadFile = async (item: any) => {
    if (item) {
      setDownloadItem(item);
      const params = {
        id: item.objectId,
        url: item.objectUrl,
      };
      mDownload.mutate(params);
    }
  };

  //delete a file
  const handleDeleteFile = (item: any) => {
    setDeleteItem(item);
    //delete upload
    const params = { id: item.objectId };
    mDeleteUpload.mutate(params);
    // confirmAlert({
    //   title: t('crm_new_common_delete'),
    //   message: t('crm_new_common_delete_msg'),
    //   buttons: [
    //     {
    //       label: 'No',
    //       className: 'btn-secondary',
    //     },
    //     {
    //       label: 'Yes',
    //       className: 'btn-primary',
    //       onClick: () => {
    //         setDeleteItem(item);
    //         //delete upload
    //         const params = { id: item.objectId };
    //         mDeleteUpload.mutate(params);
    //       },
    //     },
    //   ],
    // });
  };

  //render icon or image
  const renderAttachmentIcon = () => {
    const [, fileExt] = item.name.split('.');
    let icon = 'txt';
    if (
      fileExt.toLowerCase() === 'jpg' ||
      fileExt.toLowerCase() === 'jpeg' ||
      fileExt.toLowerCase() === 'png' ||
      fileExt.toLowerCase() === 'gif'
    ) {
      if (item?.url) {
        return (
          <Image
            key={item.id}
            url={item?.url}
            imgClass={'h-100'}
            className="h-100 w-100 tx-center"
          />
        );
      } else {
        icon = getFileIcon(item.name);
      }
    } else {
      icon = getFileIcon(item.name);
    }
    return <SvgIcons type={icon} />;
  };

  //render grid view
  const renderGridView = () => {
    return (
      <div className="card card-file">
        <div className="card-file-thumb">{renderAttachmentIcon()}</div>
        <div className="card-body">
          <a role="button" className="link-02">
            {item.name}
          </a>
          <span className="card-file-size">{humanFileSize(item.size)}</span>
        </div>
        <div className="card-footer">
          <span className="card-footer-date">
            {convertDateTimeServerToClient({ date: item.createdAt?.toString() })}
          </span>
          <button
            type="button"
            className="btn btn-link link-03 btn-icon mg-l-auto"
            data-han-tooltip="Download"
            onClick={() => handleDownloadFile(item)}
          >
            {!mDownload.isLoading && <Download />}
            {mDownload.isLoading && (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            )}
            <span className="sr-only">
              <SpanLang keyLang="common_btn_download" />
            </span>
          </button>
          <button
            type="button"
            className="btn btn-link link-03 btn-icon"
            data-han-tooltip="Delete"
            onClick={() => handleDeleteFile(item)}
          >
            {!mDeleteUpload.isLoading && !mDeleteAttachment.isLoading && <Trash />}
            {(mDeleteUpload.isLoading || mDeleteAttachment.isLoading) && (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            )}
            <span className="sr-only">
              <SpanLang keyLang="crm_new_common_delete" />
            </span>
          </button>
        </div>
      </div>
    );
  };

  //render list view
  const renderListView = () => {
    return (
      <div className="d-flex align-items-center mg-b-3 pd-x-10 pd-y-5 bg-light rounded">
        <div
          className="media align-items-center flex-grow-1"
          style={{ maxWidth: 'calc(100% - 72px' }}
        >
          <span className="file-type-icon">{renderAttachmentIcon()}</span>
          <div className="media-body mg-l-10 text-truncate">
            {item.name}
            <small className="d-block text-muted text-truncate">
              <>
                ({humanFileSize(item.size || 1)}){' '}
                {convertDateTimeServerToClient({ date: item.createdAt?.toString() })}
              </>
            </small>
          </div>
        </div>
        <div className="flex-shrink-0">
          <button
            type="button"
            className="btn btn-link btn-icon han-tooltip--top"
            data-han-tooltip="Download"
            onClick={() => handleDownloadFile(item)}
          >
            {!mDownload.isLoading && <Download />}
            {mDownload.isLoading && (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            )}
            <span className="sr-only">
              <SpanLang keyLang="common_btn_download" />
            </span>
          </button>
          <button
            type="button"
            className="btn btn-link btn-icon han-tooltip--top"
            data-han-tooltip="Delete"
            onClick={() => handleDeleteFile(item)}
          >
            {!mDeleteUpload.isLoading && !mDeleteAttachment.isLoading && (
              <X className="tx-danger" />
            )}
            {(mDeleteUpload.isLoading || mDeleteAttachment.isLoading) && (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            )}
            <span className="sr-only">
              <SpanLang keyLang="common_btn_download" />
            </span>
          </button>
        </div>
      </div>
    );
  };

  //render item
  return (
    <>
      {listType === 'grid' && renderGridView()}
      {listType === 'list' && renderListView()}
    </>
  );
};

export default AttachmentCard;
