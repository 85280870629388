import { MENU_PRODUCT_PRODUCT } from './menus';

/**
 * Customer menu
 */
export const CUSTOMER_MENUS = [
  {
    value: 'all',
    label: 'Customers',
    path: '/customer/all',
  },
  {
    value: 'account',
    label: 'Account',
    path: '/customer/account',
  },
  {
    value: 'contact',
    label: 'Contact',
    path: '/customer/contact',
  },
  // {
  //   value: 'marketing',
  //   label: 'Marketing List',
  //   path: '/customer/marketing',
  // },
  // {
  //   value: 'persona',
  //   label: 'Buyer Persona',
  //   path: '/customer/persona',
  // },
  // {
  //   value: 'ideal',
  //   label: 'Ideal Customer Profile',
  //   path: '/customer/ideal',
  // },
  // {
  //   value: 'block',
  //   label: 'BlockList',
  //   path: '/customer/block',
  // },
];

/**
 * Activity menu
 */
export const ACTIVITY_MENU = [
  {
    value: 'comparison',
    label: 'Comparison',
    path: '/activity/comparison',
  },
  {
    value: 'mywork',
    label: 'My Works',
    path: '/activity/mywork',
  },
  {
    value: 'activity',
    label: 'Activity',
    path: '/activity/activity',
  },
];

/**
 * Activity menu
 */
export const ORDER_MENU = [
  {
    value: 'slist',
    label: 'Sale Order',
    path: '/sale-order/slist',
  },
  {
    value: 'point',
    label: 'Dashboard',
    path: '/selling/point',
  },
  {
    value: 'selling',
    label: 'Selling',
    path: '/selling/selling',
  },
];

/**
 * Invoice menu
 */
export const INVOICE_MENUS = [
  {
    value: 'title-0',
    label: 'Dashboard',
    path: '/invoice/dashboard',
  },
  {
    value: 'dashboard',
    label: 'Dashboard',
    path: '/invoice/dashboard',
  },
  {
    value: 'title-1',
    label: 'Invoices',
    path: '/invoice/invoice',
  },
  {
    value: 'invoice',
    label: 'Invoices',
    path: '/invoice/invoice',
  },
  {
    value: 'unpaid_invoice',
    label: 'Unpaid Invoices',
    path: '/invoice/unpaid',
  },
  {
    value: 'credit_note',
    label: 'Credit Note',
    path: '/invoice/credit-note',
  },
  {
    value: 'debit_note',
    label: 'Debit Note',
    path: '/invoice/debit-note',
  },
  {
    value: 'payment_received',
    label: 'Payment Received',
    path: '/invoice/payment-received',
  },
  {
    value: 'sales_commission',
    label: 'Sales Commission Expense',
    path: '/invoice/sales-commission',
  },
  {
    value: 'title-2',
    label: 'Purchase Bill',
    path: '/invoice/purchase-bill',
  },
  {
    value: 'purchase_bill',
    label: 'Purchase Bill',
    path: '/invoice/purchase-bill',
  },
  {
    value: 'credit_vendor',
    label: 'Credit Vendor',
    path: '/invoice/credit-vendor',
  },
  {
    value: 'debit_vendor',
    label: 'Debit Vendor',
    path: '/invoice/debit-vendor',
  },
  {
    value: 'payment_made',
    label: 'Payment Made',
    path: '/invoice/payment-made',
  },
];

/**
 * Desk menu
 */
export const DESK_MENUS = [
  {
    value: 'ticket',
    label: 'Ticket',
    path: '/desk/ticket',
  },
  {
    value: 'knowledge',
    label: 'Knowledge Base',
    path: '/desk/knowledge',
  },
];

/**
 * Inventory menu
 */
export const INVENTORY_MENUS = [
  {
    value: 'warehouse',
    label: 'Warehouse',
    path: '/inventory/warehouse',
  },
  {
    value: 'item',
    label: 'Inventory Items',
    path: '/inventory/item',
  },
  {
    value: 'adjustment',
    label: 'Adjustment',
    path: '/inventory/adjustment',
  },
  {
    value: 'transfer',
    label: 'Transfer Order',
    path: '/inventory/transfer',
  },
  {
    value: 'receipt',
    label: 'Receipt',
    path: '/inventory/receipt',
  },
  {
    value: 'shipment',
    label: 'Shipment',
    path: '/inventory/shipment',
  },
];

export const PURCHASE_MENUS = [
  {
    value: 'pr',
    label: 'Purchase Request',
    path: '/purchase/pr',
  },
  {
    value: 'rfq',
    label: 'Request For Quote',
    path: '/purchase/rfq',
  },
  {
    value: 'rft',
    label: 'Request For Tender',
    path: '/purchase/rft',
  },
  {
    value: 'po',
    label: 'Purchase Order',
    path: '/purchase/po',
  },
  {
    value: 'pa',
    label: 'Purchase Agreement',
    path: '/purchase/pa',
  },
  {
    value: 'pb',
    label: 'Purchase Bill',
    path: '/purchase/pb',
  },
];

/**
 * Inventory menu
 */
export const SALES_MENUS = [
  {
    value: 'dashboard',
    label: 'Dashboard',
    path: '/sales/dashboard',
  },
  {
    value: 'allquote',
    label: 'All Quotes',
    path: '/sales/quote/all',
  },
  {
    value: 'myquote',
    label: 'My Quotes',
    path: '/sales/quote/my',
  },
  {
    value: 'opportunity',
    label: 'Opportunity',
    path: '/sales/opportunity',
  },
];

export const SETTING_ONLINE_DIGITAL_MENUS = [
  {
    value: 'cta',
    label: 'Call To Action',
    path: '/settings/online-digital-content/cta',
  },
  {
    value: 'landing-page',
    label: 'Landing Page',
    path: '/settings/online-digital-content/landing-page',
  },
  {
    value: 'survey',
    label: 'Survey',
    path: '/settings/online-digital-content/survey',
  },
  {
    value: 'ticket-form',
    label: 'Form',
    path: '/settings/online-digital-content/ticket-form',
  },
];

export const SETTING_TEMPLATE_MENUS = [
  {
    value: 'knowledgebase',
    label: 'Knowledge Base',
    path: '/settings/template/knowledgebase',
  },
  {
    value: 'email',
    label: 'Email',
    path: '/settings/template/email',
  },
  {
    value: 'sms',
    label: 'SMS',
    path: '/settings/template/sms',
  },
  {
    value: 'task',
    label: 'Task',
    path: '/settings/template/task',
  },
  {
    value: 'call',
    label: 'Call',
    path: '/settings/template/call',
  },
];

/**
 * Product menu
 */
export const PRODUCT_MENUS = [
  {
    value: 'product',
    label: 'Products',
    path: '/product/product',
  },
  {
    value: 'group',
    label: 'Product-Tree',
    path: '/product/group',
  },
  {
    value: 'item',
    label: 'Items',
    path: '/product/item',
  },
  // {
  //   value: 'component',
  //   label: 'Components',
  //   path: '/product/component',
  // },
  {
    value: 'unit',
    label: 'Units',
    path: '/product/unit',
  },
  // {
  //   value: 'price-list',
  //   label: 'Price List',
  //   path: '/product/price-list',
  // },
  // {
  //   value: 'discount',
  //   label: 'Discount',
  //   path: '/product/discount',
  // },
  // {
  //   value: 'base-cost', // Request by Mr.Kim
  //   label: 'Base Cost',
  //   path: '/product/base-cost',
  // },
];

export const ORDER_MENUS = [
  {
    value: 'dashboard',
    label: 'Dashboard',
    path: '/order/dashboard',
  },
  {
    value: 'process',
    label: 'Order Process',
    path: '/order/process',
  },
  {
    value: 'sales',
    label: 'Sales Order',
    path: '/order/sales',
  },
  {
    value: 'return',
    label: 'Sales Return',
    path: '/order/return',
  },
  {
    value: 'replacement',
    label: 'Replacement Order',
    path: '/order/replacement',
  },
  {
    value: 'point',
    label: 'Point Of Sale',
    path: '/order/point',
  },
  {
    value: 'report',
    label: 'Sales Report',
    path: '/order/report',
  },
];

export const SITE_MENUS = [
  {
    value: 'desk',
    label: 'Desk',
    path: '/setting/site/desk',
  },
];

export const SETTING_ASSIGNMENT_RULE = [
  {
    value: 'assignment-rule',
    label: 'Assignment Rule',
    path: '/settings/assignment-rule/list',
  },
  /*{
    value: 'run-report',
    label: 'Run Report',
    path: '/settings/assignment-rule/run-report',
  },*/
];

export const SETTING_SITE_TEMPLATE_MENUS = [
  {
    value: 'desk',
    label: 'Desk',
    path: '/settings/site/desk',
  },
];
