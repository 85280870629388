import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-feather';
import { Avatar } from '@base/components';
import { isFunction } from 'lodash';
import { defaultColumnRender } from '@base/components/list/list-grid/list-grid-item';
import { SpanLang } from '@base/components/form';

interface IListGridItemBodyProps {
  date?: string;
  custPhoto?: string;
  custName?: string;
  repPhoto?: string;
  repName?: string;
  columns?: any[];
  ignoreColumns?: any[];
  columnRender?: any;
  itemData?: any;
  isSplitMode?: boolean;
  children?: React.ReactNode;
}

const ListGridItemBody: FC<IListGridItemBodyProps> = (props) => {
  const {
    isSplitMode,
    date,
    custName,
    custPhoto,
    repPhoto,
    repName,
    columns,
    ignoreColumns,
    columnRender,
    itemData,
    children,
  } = props;
  const { t } = useTranslation();

  //render basic body for split
  const renderBasicSplit = () => {
    if (children) return children;

    return (
      <ul className="list-unstyled mg-b-0">
        {date && (
          <li className="d-flex align-items-center mg-t-5">
            <span className="split-list-icon">
              <Calendar />
            </span>
            {date}
          </li>
        )}
        <li className="d-flex align-items-center mg-t-5">
          <Avatar url={custPhoto} alt={custName} width={26} height={26} />
          <span className="mg-l-5">{custName}</span>
        </li>
        {
          <li className="d-flex align-items-center mg-t-5">
            <Avatar url={repPhoto} alt={repName || 'Not assigned'} width={26} height={26} />
            <span className="mg-l-5">{repName || '(Not assigned)'}</span>
          </li>
        }
      </ul>
    );
  };

  //render basic body for full
  const renderBasicFull = () => {
    if (children) return children;

    return (
      <div className="grid-list-item-detail-body">
        {columns &&
          columns.map((col: any, i: number) => {
            if (ignoreColumns && ignoreColumns.includes(col?.name)) {
              return null;
            }

            const columnComponent =
              isFunction(columnRender) && columnRender(col?.name)
                ? columnRender(col.name)(col?.name, itemData)
                : defaultColumnRender(col?.name, itemData);

            return (
              <div className="grid-list-item-col" key={i}>
                <span className="grid-list-item-col-title">
                  <SpanLang keyLang={col?.title} isString />:
                </span>
                <span className="grid-list-item-col-content">{columnComponent}</span>
              </div>
            );
          })}
      </div>
    );
  };

  return <>{isSplitMode ? renderBasicSplit() : renderBasicFull()}</>;
};

export default ListGridItemBody;
