import React, { ReactNode } from 'react';
import { FormIcon, SpanLang } from '@base/components/form';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const Tab = ({ icon = 'User', iconType = 'feather', title = '', description = '', active = false, onChange }: any) => {
  //const { t } = useTranslation();

  return (
    <li className="nav-item ht-50 bd rounded bg-gray-100 cursor-pointer" style={{ flex: 1 }} onClick={onChange}>
      <div className="ht-100p wd-100p d-flex ">
        <div
          className={classNames('wd-50 ht-100p d-flex align-items-center justify-content-center', {
            'bg-primary': active,
            'bg-gray-200': !active,
          })}
          style={{ borderRadius: '5px 0px 0px 5px' }}
        >
          <FormIcon icon={icon} iconType={iconType} size={24} color="white" className='tx-white' />
        </div>
        <div className="pd-l-10 container-text-more">
          <div className="tx-bold tx-16 text-more-flex">
            <SpanLang keyLang={title} />
          </div>
          <div className="text-more-flex">
            <SpanLang keyLang={description} />
          </div>
        </div>
      </div>
    </li>
  );
};

export default Tab;
