import {
  GRAPHQL_HOST,
  GRAPHQL_HOSTID,
  STORAGE_SERVER_PASSWORD,
  STORAGE_SERVER_USERNAME,
} from '@base/config/graphql';
import Storages from '@base/utils/storages/ls';
import { IToken } from '@sign-in/types/auth';
import axios from 'axios';
import { merge } from 'lodash';

export interface IResponseError {
  message: string;
}

export function axiosHelper() {
  const Ls = new Storages();
  const token = JSON.parse(Ls.get('token') as string) as IToken;

  return {
    headerHandler,
    publicHeaderHandler,
    uploadHeaderHandler,
    variablesHandler,
    errorHandler,
  };

  function headerHandler() {
    return {
      authorization: token?.accessToken ? `Bearer ${token.accessToken}` : '',
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Credentials': true,
      Accept: 'application/json',
      // 'User-Agent': 'Axios GraphQL',
      'Accept-Language': 'ko',
      'Session-Id': 'PaKp8HecxB8Mwr03uiasM',
    };
  }

  function publicHeaderHandler() {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'ko',
    };
  }

  function uploadHeaderHandler(isUpload: boolean) {
    var encodedToken = window.btoa(STORAGE_SERVER_USERNAME + ':' + STORAGE_SERVER_PASSWORD);
    let baseHeaders = {
      Authorization: `Basic ${encodedToken}`,
      Accept: '*/*',
    };
    if (isUpload) {
      return {
        ...baseHeaders,
        'Content-Type': 'application/octet-stream', //'application/octet-stream', 'multipart/form-data'
      };
    } else {
      return {
        ...baseHeaders,
        'Content-Type': 'application/json',
      };
    }
  }

  function variablesHandler(variables: any) {
    let tenantHost = Ls.get('tenant_host') as string;
    if (tenantHost === '' || tenantHost === null) {
      tenantHost = GRAPHQL_HOST;
    }
    // tenantHost: save in localstorage or http host
    return merge(variables, { tenantHost: tenantHost, tenantId: GRAPHQL_HOSTID });
  }

  function errorHandler(error: any) {
    if (axios.isAxiosError(error)) {
      if ([401, 403].includes(error.response?.status as number) && token?.accessToken) {
        throw JSON.stringify({ message: 'no_authentication' });
      } else {
        throw JSON.stringify(error);
      }
    } else {
      let returnMessage = null;
      try {
        const response = JSON.parse(error) as IResponseError[];
        response.forEach(({ message }) => {
          if (
            (message.match(/not a tenant member|not a member|re-authenticate|tenant not found/i) ||
              message.match(/code 401/)) &&
            token?.accessToken
          ) {
            returnMessage = { message: 'no_authentication' };
            return;
          } else if (message.match(/internal server error/i)) {
            returnMessage = { message: 'server_error' };
            return;
          }
        });
        throw returnMessage ? JSON.stringify(returnMessage) : error;
      } catch (exception: any) {
        throw error;
      }
    }
  }
}
