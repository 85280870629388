import React, { useState, useEffect, useRef, VFC } from 'react';
import { MoreHorizontal, X } from 'react-feather';
import styled from '@emotion/styled';
import useWindowSize from '@base/hooks/useWindowSize';
import $ from 'jquery';
import Portal from '@base/components/portal';
import classNames from 'classnames';

const Container = styled.div`
  z-index: 1000;
`;

const DropdownMore = styled.div`
  .dropdown-item {
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

interface IQuickAction {
  resetSelected: any;
  checked: any;
  buttons: any[];
  visible: any;
}

const ListQuickAction: VFC<IQuickAction> = ({
  resetSelected,
  checked,
  buttons,
  visible,
}: IQuickAction) => {
  const windowSize = useWindowSize();
  const menuRef = useRef<any>(null);
  const [limit, setLimit] = useState<number>(buttons.length);

  const prependMoreActions = () => {
    if (!visible) return;

    const $menu = $(menuRef.current);

    const $firstButton = $menu.find('[data-main-btn]:first');

    if ($firstButton === undefined || !$firstButton || !$firstButton.offset()) {
      return null; // button is hidden
    }

    const $firstButtonTop = $firstButton ? $firstButton?.offset()?.top : null;

    const $lastButton = $menu
      .find('[data-main-btn]')
      .filter(function (index) {
        const $el = $(this);
        return $el ? $el?.offset()?.top === $firstButtonTop : false;
      })
      .last();

    if ($lastButton.length) {
      setLimit($lastButton.data('main-btn'));
    }
  };

  useEffect(() => {
    prependMoreActions();
  }, [windowSize]);

  useEffect(() => {
    prependMoreActions();
  }, [buttons]);

  return (
    <Portal>
      <Container
        className={classNames('fixed-action-buttons clearfix z-index-150', { active: visible })}
        role="alert"
      >
        <button
          onClick={resetSelected}
          type="button"
          className="float-left btn btn-link btn-icon btn-close"
        >
          <X />
          <span className="sr-only">Close</span>
        </button>

        <span>
          <span className="selected-count">
            <span className="badge">{checked.length}</span>
          </span>
        </span>

        {buttons.slice(0, limit).map((button, index) => (
          <button
            key={index}
            onClick={() => button.callback(checked)}
            data-main-btn="1"
            className="btn btn-link"
          >
            {button.icon}
            {button.title}
          </button>
        ))}

        {limit < buttons.length && (
          <DropdownMore className="dropdown">
            <button type="button" className="btn btn-link btn-more" data-toggle="dropdown">
              <MoreHorizontal />
              More
            </button>
            <div className="dropdown-menu dropdown-icon">
              {buttons.slice(limit, buttons.length).map((button, i) => (
                <a
                  key={i}
                  onClick={() => button.callback(checked)}
                  className="dropdown-item"
                >
                  {button.icon}
                  {button.title}
                </a>
              ))}
            </div>
          </DropdownMore>
        )}

        <div ref={menuRef} style={{ padding: '10px 160px 10px 10px' }} className="o-hidden">
          {buttons.map((button, index) => (
            <button type="button" data-main-btn={index + 1} className="btn btn-link" key={index}>
              {button.icon}
              {button.title}
            </button>
          ))}
        </div>
      </Container>
    </Portal>
  );
};

export default ListQuickAction;
