import React, { useRef } from 'react';
import cn from 'classnames';
import { ChevronRight, ChevronLeft, Trash2 } from 'react-feather';
import DropArea from '@base/components/form/upload/drop-area';

import '../styles.scss';

interface UploadMultiImageProps {
  as?: any;
  className?: any;
  files: string[];
  setFiles: any;
  accept: any;
  preview: boolean;
}

const UploadMultiImage: React.FC<UploadMultiImageProps> = (props: UploadMultiImageProps) => {
  const { as: As, className, files = [], setFiles, accept = '', preview = true } = props;
  const imgRef = useRef<HTMLDivElement>(null);

  const handleNextClick = () => {
    if (imgRef?.current) {
      const position = imgRef?.current?.getBoundingClientRect();
      imgRef.current.scrollTo(position?.right - position?.x + imgRef?.current?.scrollLeft, 0);
    }
  };

  const handlePrevClick = () => {
    if (imgRef?.current) {
      const position = imgRef?.current?.getBoundingClientRect();
      imgRef.current.scrollTo(imgRef?.current?.scrollLeft - position?.right + position?.x, 0);
    }
  };

  return (
    <As className={cn('prod_upload_image', className)}>
      {preview && files?.length > 0 && (
        <button className="btn prod_upload_image_left_btn" onClick={handlePrevClick}>
          <ChevronLeft />
        </button>
      )}

      <div className="prod_upload_image_list  w-100" ref={imgRef}>
        {preview &&
          files?.map((file: any, index: number) => (
            <div key={index} className={'prod_upload_image_item'}>
              <div
                className="remove-cover"
                onClick={() => {
                  setFiles(files.filter((file: any, idx: number) => index !== idx));
                }}
              >
                <Trash2 />
              </div>
              <img className={'img-preview'} src={window.URL.createObjectURL(file)}></img>
            </div>
          ))}
        <DropArea
          // className={cn('btn prod_upload_dropzone', {
          //   next_upload: files?.length > 0,
          // })}
          viewListFiles={!preview}
          onDrop={(files: any) => setFiles && setFiles(files)}
          files={files}
          accept={accept}
        />
      </div>
      {preview && files?.length > 0 && (
        <button className="btn prod_upload_image_right_btn" onClick={handleNextClick}>
          <ChevronRight />
        </button>
      )}
    </As>
  );
};

UploadMultiImage.defaultProps = {
  as: 'div',
  preview: true,
};

export default UploadMultiImage;
