import juice from 'juice';
import { cmdExport } from './../constants';

export default (editor: any, config: any) => {
  const pfx = editor.getConfig('stylePrefix');
  const modal = editor.Modal;
  const codeViewer = editor.CodeManager.getViewer('CodeMirror').clone();
  const container = document.createElement('div');
  const juiceOpts = config.juiceOpts || {};
  let cmdm = editor.Commands;
  let viewerEditor = codeViewer.editor;

  // Init code viewer
  codeViewer.set({
    ...{
      codeName: 'htmlmixed',
      theme: 'hopscotch', //codeViewerTheme: 'material',
      readOnly: 0,
    },
    ...config.importViewerOptions,
  });

  // Set the command which could be used outside
  cmdm.add(cmdExport, {
    run(editor: any, sender: any, opts: any) {
      const tmpl = editor.getHtml() + `<style>${editor.getCss()}</style>`;
      return juice(tmpl, opts);
    },
  });

  return {
    run(editor: any, sender: any) {
      // Init code viewer if not yet instantiated
      if (!viewerEditor) {
        let txtarea = document.createElement('textarea');

        if (config.modalExportLabel) {
          let labelEl = document.createElement('div');
          labelEl.className = pfx + 'export-label';
          labelEl.innerHTML = config.modalExportLabel;
          container.appendChild(labelEl);
        }
        container.appendChild(txtarea);
        codeViewer.init(txtarea);
        viewerEditor = codeViewer.editor;
        viewerEditor.setOption('lineWrapping', 1);
      }

      modal.setTitle(config.modalExportTitle);
      modal.setContent(container);
      const tmpl = editor.getHtml() + `<style>${editor.getCss()}</style>`;
      codeViewer.setContent(config.inlineCss ? juice(tmpl, juiceOpts) : tmpl);
      modal
        .open()
        .getModel()
        .once('change:open', () => editor.stopCommand(cmdExport)); //this.id
      viewerEditor.refresh();
      sender && sender.set && sender.set('active', 0);
    },

    stop() {
      modal.close();
    },
  };
};
