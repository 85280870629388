import React, { useEffect, useState } from 'react';
import CommonViewField from '@base/containers/view-field/common';

import View from './view';
import Edit from './edit';

const DataSourceViewField: React.FC = (props: any) => {
  const { value } = props;
  //state
  const [valueState, setValueState] = useState(value);

  //value change
  useEffect(() => {
    setValueState(value);
  }, [value]);

  //save success
  const onSave = (formData: any) => {
    setValueState(formData[props.keyName]);
  };

  return (
    <CommonViewField
      {...props}
      value={valueState}
      onSave={onSave}
      componentView={View}
      componentEdit={Edit}
    />
  );
};

export default DataSourceViewField;
