import React from 'react';
import { RepeatState } from '.';
import { typeOptions } from './edit';

type ViewProps = {
  value: RepeatState;
};

const View: React.FC<ViewProps> = (props: ViewProps) => {
  const { value } = props;
  return (
    <>
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          checked={value.useRepeat}
          disabled={true}
        />
        <label className="custom-control-label">
          {value.useRepeat && (
            <div className="d-flex justify-content-between">
              <div className="mg-r-15">
                {typeOptions.find((opt) => opt.value == value.repeatType)?.label}
              </div>
            </div>
          )}
        </label>
      </div>
    </>
  );
};

export default View;
