import { Switch } from "@base/components/form";
import { useEffect, useState } from "react";
import UserAutoComplete from "../user-auto-complete";

interface IAssignRepCheckProps {
  checkLabel?: string;
  value?: any;
  onChange?: (value: any) => void;
}

const AssignRepCheck: React.FC<IAssignRepCheckProps> = (props) => {
  const {
    checkLabel = 'My work listing for assigned user/group',
    value,
    onChange
  } = props;
  //state
  const defaultValue = {
    users: [],
    useWork: false
  };
  const [curValue, setCurValue] = useState<any>(defaultValue);

  //init current value
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(value)) {
        setCurValue(value);
      }
    } else {
      setCurValue(defaultValue);
    }
  }, [value]);

  //value change
  const handleValueChange = (keyName: string, keyValue: any) => {
    const newValue = { ...curValue };
    newValue[keyName] = keyValue;
    setCurValue(newValue);
    //callback
    onChange && onChange(newValue);
  }

  return (
    <div>
      <UserAutoComplete
        showAvatar={true}
        value={curValue.users}
        onChange={(newUsers: any) => handleValueChange('users', newUsers)}
      />
      <Switch
        className="mg-t-5"
        title={checkLabel}
        value={curValue.useWork}
        onChange={(e: any) => handleValueChange('useWork', e.target.checked)}
      />
    </div>
  );
}

export default AssignRepCheck;