import React from 'react';
import classNames from 'classnames';

//import '../style.scss';

/**
 * author: trungtm
 * @param {*} param0
 */
const Body: React.FC<any> = (props: any) => {
  const { children, className } = props;

  //render
  return (
    <div className={classNames('panel-body', className)}>
      <div className="tab-content">{children}</div>
    </div>
  );
};

export default Body;
