import React, { Suspense, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import NoteAndError from '@base/components/hook-form/error-note';
import Field from '@base/components/hook-form/field';
import { FooterContainer } from '@base/containers/aside-modal';

type AsideWriteProps = {
  onSubmit: (formData: any, isNew: boolean) => void;
  defaultValues: any;
  fields: any[];
  isSaving: boolean;
  isReset: boolean;
};

const AsideWrite = (props: AsideWriteProps) => {
  const { onSubmit, defaultValues, fields, isSaving, isReset } = props;

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  useEffect(() => {
    if (isReset) {
      reset(); //back to default values
    }
  }, [isReset]);

  const onFormSubmit = () => {
    handleSubmit((data) => onSubmit(data, false))();
  };

  const onFormNewSubmit = () => {
    handleSubmit((data) => onSubmit(data, true))();
  };

  const formHeight = 'calc(100vh - 175px)';

  return (
    <div className="pd-5">
      <div className="bd-b pd-b-10">
        <NoteAndError errors={errors} />
      </div>
      <div
        className="pos-relative scroll-box"
        style={{ height: formHeight, overflowY: 'auto', overflowX: 'hidden' }}
      >
        <form className="form">
          <div className="pd-15 form-row">
            {fields.map((_item, _index) => {
              return (
                <Suspense key={_item.keyName}>
                  <Field
                    item={{
                      ..._item,
                      componentProps: _item?.componentProps,
                      columns: _item.columns,
                      hideTitle: _item.hideTitle, //hide title for a specific key name
                    }}
                    control={control}
                    errors={errors}
                  />
                </Suspense>
              );
            })}
          </div>
        </form>
      </div>
      <FooterContainer
        isSaving={isSaving}
        isReset={isReset}
        isValid={isValid}
        onSubmit={onFormSubmit}
        onNewSubmit={onFormNewSubmit}
      />
    </div>
  );
};

export default AsideWrite;
