import React from 'react';
import classNames from 'classnames';
// import HanTablePlaceHolder from './HanTablePlaceHolder';
// import HanTreeHolder from './HanTreeHolder';
// import HanAvgPlaceHolder from './HanAvgPlaceHolder';
// import HanLineHolder from './HanLineHolder';
import LineFullRow from './line-full-row';
import SidebarMenuLoading from './sidebar-menu-loading';

interface PlaceHolderProps {
  paragraph?: boolean;
  position?: string;
  className?: string;
  rows: number;
}

const PlaceHolder: React.FC<PlaceHolderProps> = (props: PlaceHolderProps) => {
  const { paragraph, position, className, rows } = props;

  const classes = classNames(
    position ? `aligned-${position}` : 'aligned-left',
    paragraph ? 'placeholder-paragraph' : 'placeholder-media',
    className,
  );

  let lines = [];
  for (let i = 0; i < rows; i++) {
    lines.push(<div className="line" key={i}></div>);
  }
  return <div className={classes}>{lines}</div>;
};

PlaceHolder.defaultProps = {
  position: 'left',
  paragraph: true,
  rows: 1,
};

export { PlaceHolder, LineFullRow, SidebarMenuLoading };
