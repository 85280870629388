import React, { useEffect, useState } from 'react';
import TextView from '@base/containers/view-field/text/view';
//import { isString } from 'lodash';

const View: React.FC = (props: any) => {
  const { value } = props;
  //state
  const [text, setText] = useState('');

  useEffect(() => {
    if (value) {
      setText(value);
    } else {
      setText('');
    }
  }, [value]);

  return <TextView value={text} />;
};

export default View;
