import React, { FC, VFC } from 'react';
import { Check } from 'react-feather';
import { convertDateTimeServerToClient } from '@base/utils/helpers/general.utils';
import { isEmpty, isFunction, isString, isUndefined } from 'lodash';

import ListGridItemPhoto from '@base/components/list/list-grid/list-grid-item/list-grid-item-photo';
import ListGridItemHeader from '@base/components/list/list-grid/list-grid-item/list-grid-item-header';
import ListGridItemBody from '@base/components/list/list-grid/list-grid-item/list-grid-item-body';

export const defaultColumnRender = (col: string, data: any): string | null => {
  const val = data?.[col];

  if (isUndefined(val) || isEmpty(val) || !val) {
    return '-';
  }

  if (['createdAt', 'updatedAt'].indexOf(col) !== -1) {
    return convertDateTimeServerToClient({ date: val, isTime: true, humanize: true });
  }

  if (['createdBy', 'updatedBy'].indexOf(col) !== -1) {
    return val?.name || '-';
  }

  // return json to know error for fix.
  return isString(val) ? val : JSON.stringify(val);
};

export interface IListGridItemBase {
  primaryKey: string | number;
  data: any;
  isSplitMode: boolean;
  onCheckedRow: (checkedValue: string | number, e?: any) => void;
  selectedIds: string[];
  photoRender?:
    | {
        src: string;
        alt: string;
      }
    | (() => React.ReactNode);
  children?: any;
  listItemHeaderComponent?: React.ReactNode;
}

const ListGridItemBase: FC<IListGridItemBase> = ({
  primaryKey, // main key of record data. Example: id
  data,
  isSplitMode,
  onCheckedRow,
  selectedIds,
  photoRender,
  children,
  listItemHeaderComponent,
}: IListGridItemBase) => {
  const isSelected = selectedIds.indexOf(data?.[primaryKey]) !== -1;

  const ListGridItemPre: FC<any> = ({ children }) => {
    return isSplitMode ? children : <div className="col-lg-6 d-flex">{children}</div>;
  };

  const PhotoRendered = (props: { className?: string; isSplitMode?: boolean }): any => {
    if (photoRender === undefined) {
      return null;
    } else {
      if (isFunction(photoRender)) {
        return photoRender;
      } else {
        return <ListGridItemPhoto {...photoRender} {...props} />;
      }
    }
  };

  return (
    <ListGridItemPre key={data?.[primaryKey]}>
      <div
        className="grid-list-item"
        // role="button"
        // onClick={(e) => onCheckedRow(data?.[primaryKey], e)}
      >
        {isSplitMode ? (
          photoRender ? (
            <div
              onClick={(e) => onCheckedRow(data?.[primaryKey], e)}
              className={`avatar mg-r-10 ${isSelected ? 'selected-item' : ''}`}
            >
              {isSelected ? <Check size={20} /> : <PhotoRendered isSplitMode={isSplitMode} />}
            </div>
          ) : (
            <div className="custom-control custom-checkbox pd-t-5">
              <input
                type="checkbox"
                className="custom-control-input"
                id={`gridCheck_${data?.[primaryKey]}`}
                onChange={(e) => onCheckedRow(data?.[primaryKey], e)}
                // defaultChecked={isSelected}
                checked={isSelected}
              />
              <label className="custom-control-label" htmlFor={`gridCheck_${data?.[primaryKey]}`} />
            </div>
          )
        ) : (
          <>
            <div className="grid-list-item-sel pd-t-8">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`gridCheck_${data?.[primaryKey]}`}
                  onChange={(e) => onCheckedRow(data?.[primaryKey], e)}
                  // defaultChecked={isSelected}
                  checked={isSelected}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`gridCheck_${data?.[primaryKey]}`}
                />
              </div>
            </div>
            <PhotoRendered className="grid-list-item-pic" />
          </>
        )}
        <div className="grid-list-item-detail">
          {listItemHeaderComponent}
          {children}
        </div>
      </div>
    </ListGridItemPre>
  );
};

ListGridItemBase.defaultProps = {
  primaryKey: 'id',
};

export default React.memo(ListGridItemBase);

export { ListGridItemHeader, ListGridItemBody };
