import React, { lazy } from 'react';
import CommonViewField from '@base/containers/view-field/common';
import { useRecoilValue } from 'recoil';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import {
  getFieldLayoutDataByKeyName,
  parseLookupAttrToConfig,
} from '@base/utils/helpers/page-layout-utils';
const View = lazy(() => import('./view'));
const Edit = lazy(() => import('./edit'));
interface LookupProps {
  value: any;
  onSave: (params: any) => void;
  keyName: string;
  menuSourceId: string;
  menuSource: string;
}
const Lookup = (props: LookupProps) => {
  const { onSave, value, menuSourceId, menuSource, keyName, ...remainProps } = props;
  const viewData = useRecoilValue(viewDataByMenuAtom(menuSource));
  // get field attr
  const field = getFieldLayoutDataByKeyName(viewData, keyName);
  let lookupProps = null;
  if (field) {
    lookupProps = parseLookupAttrToConfig(field);
    if (lookupProps) {
      lookupProps.fetchList = field.config?.fetchList;
    }
  }
  let nRemainProps = {
    ...remainProps,
    lookupProps,
  };
  return (
    <CommonViewField
      keyName={keyName}
      menuSourceId={menuSourceId}
      menuSource={menuSource}
      {...nRemainProps}
      onSave={onSave}
      componentView={View}
      componentEdit={Edit}
      value={value}
    />
  );
};

export default Lookup;
