import React from 'react';
import classNames from 'classnames';

/**
 *
 * @param {*} props
 */
interface CodeGeneratorSettingProps {
  //isInitLoading: boolean;
  autoGenerate: boolean;
  setAutoGenerate: any;
  nextNumber: string;
  setNextNumber: any;
  prefixString: string;
  setPrefixString: any;
}

const CodeGeneratorSetting: React.FC<CodeGeneratorSettingProps> = (
  props: CodeGeneratorSettingProps,
) => {
  const {
    //isInitLoading,
    autoGenerate,
    setAutoGenerate,
    nextNumber,
    setNextNumber,
    prefixString,
    setPrefixString,
  } = props;

  //next number changes
  const handleNextNumberChange = (e: any) => {
    const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
    let { value } = e.target; //e.target.value.replace(/\,/g, '');
    //just accept Number
    if (value === '' || floatRegExp.test(value)) {
      setNextNumber(value);
    }
  };

  //render content
  return (
    <div className={classNames('pos-relative pd-10')}>
      <div className="d-flex">
        {autoGenerate && (
          <span>
            Your code are set on auto-generate mode to save your time. Are you sure about changing
            this setting?
          </span>
        )}
        {!autoGenerate &&
          'You have selected manual code with order numbering. Do you want us to auto-generate it for you ?'}
      </div>
      {/* option 1 */}
      <div className="d-flex pd-t-10">
        <div className="custom-control custom-radio">
          <input
            type="radio"
            id="autoRadio"
            name="codeRadio"
            className="custom-control-input"
            checked={autoGenerate}
            onChange={() => setAutoGenerate(true)}
          />
          <label className="custom-control-label" htmlFor="autoRadio">
            Continue auto-generating a code with order numbering.
          </label>
          {/* {isInitLoading && (
            <span
              className="spinner-border spinner-border-sm mg-l-5 tx-gray-300"
              role="status"
              aria-hidden="true"
            />
          )} */}
        </div>
        {/* input */}
        {autoGenerate && (
          <div className="d-flex mg-l-5">
            <div className="d-flex flex-column mg-r-5">
              <div className="tx-gray-300">Prefix</div>
              <div className="">
                <input
                  //disabled={isInitLoading}
                  type="text"
                  className="form-control"
                  value={prefixString}
                  onChange={(e) => setPrefixString(e?.target?.value)}
                />
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="tx-gray-300">Next number</div>
              <div className="">
                <input
                  //disabled={isInitLoading}
                  type="text"
                  className="form-control"
                  value={nextNumber}
                  onChange={handleNextNumberChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* options 2 */}
      <div className="d-flex pd-t-10">
        <div className="custom-control custom-radio">
          <input
            type="radio"
            id="manualRadio"
            name="codeRadio"
            className="custom-control-input"
            checked={!autoGenerate}
            onChange={() => setAutoGenerate(false)}
          />
          <label className="custom-control-label" htmlFor="manualRadio">
            I will add them manually each time.
          </label>
        </div>
      </div>
    </div>
  );
};

export default CodeGeneratorSetting;
