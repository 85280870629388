import React from 'react';
import { LineFullRow } from '@base/components/placeholder';

const TreeLoading: React.FC = () => {
  return (
    <div className={'pd-l-20 pd-r-20 rh-tree-loading'}>
      {[...Array(15)].map((_e, _index) => (
        <LineFullRow key={_index} />
      ))}
    </div>
  );
};

export default TreeLoading;
