import grapesjs from 'grapesjs';

import loadBlocks from './blocks';
import loadComponents from './components';

export default grapesjs.plugins.add('gts-blocks-shorten-url', (editor: any, opts = {}) => {
  const config = {
    blocks: [
      'click-action',
      'survey-url',
    ],
    category: 'Simple URL Shortener',
    labelClickAction: 'Click to Action',
    labelSurvey: 'Survey',
    //other options
    ...opts,
  };

  //Add blocks
  loadBlocks(editor, config);
  //add components
  loadComponents(editor, config);
});