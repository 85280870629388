import React, { useMemo, useState } from 'react';
import { PRIORITIES } from '@base/config/constant';
import { Select, SpanLang } from '@base/components/form';
import { OptionValue } from '@base/types/interfaces/common';

interface IEditProps {
  value: OptionValue;
  onChange: (params: any) => {};
}

const Edit = (props: IEditProps) => {
  const { value, onChange } = props;

  const getOptionLabel = (opt: OptionValue) => {
    return <SpanLang keyLang={opt.languageKey} />;
  };
  const getOptionValue = (opt: OptionValue) => {
    return opt.keyName;
  };

  return (
    <Select
      defaultValue={value}
      options={PRIORITIES}
      onChange={onChange}
      getOptionLabel={(opt: OptionValue) => getOptionLabel(opt)}
      getOptionValue={(opt: OptionValue) => getOptionValue(opt)}
    />
  );
};

export default Edit;
