import React, { useEffect } from 'react';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { X, Minus } from 'react-feather';
import Portal from '../portal';
import $ from 'jquery';
import 'jqueryui';
// import 'jquery-ui';

declare global {
  interface Window {
    $modalMinimizedArea: any;
  }
}
const RightSideButton = styled.button`
   {
    top: 45px;
    position: absolute;
    left: 0;
    background-color: #fff;
    border-right: 0;

    svg {
      padding: 2px;
      width: 24px;
      height: 24px;
    }
  }
`;

const Modal = styled.div`
  .modal-footer {
    position: sticky;
    bottom: 0;
    background: var(--background-box);
  }

  & .shown {
    > button {
      left: -52px;
      transition-delay: 0s;
      z-index: 1;
    }
  }
`;

interface ModalProps {
  className?: string;
  classDialogSize?: string;
  onModalInit?: any;
  onHide: (params: any) => void;
  children?: any;
  scrollable?: boolean;
  ef?: string;
  title: any;
  hideHeader?: boolean;
  centered?: boolean;
  isStatic?: boolean;
  hasMinimize?: boolean;
  canClose?: boolean;
}

/**
 * Modal HTML with Bootstrap JS
 * -- Please backup it or create new Modal with new name
 */
const ModalHTML: React.FC<ModalProps> = (props: ModalProps) => {
  const {
    className,
    children,
    scrollable,
    classDialogSize,
    title,
    hideHeader,
    centered = true,
    hasMinimize,
    canClose,
    onHide,
  } = props;

  let $body = $('body');
  let _minimized = false;
  let _shown = false;
  let $modal: any;

  useEffect(() => {
    return () => {
      $modal?.modal('hide');
    };
  });

  const onHideModel = () => {
    $body.removeClass('react-modal-show');
    if (!_minimized) {
      onHide(false);
    }
  };

  const onShowModal = ($modal: any) => {
    const $another = $('body').find('.modal').not($modal[0]).not('.behind');
    if ($another.length) {
      $another.addClass('behind');
    }
  };

  const isRight = () => {
    const { className } = props;
    return className && className.indexOf('right') !== -1;
  };

  const showModal = (modal: any) => {
    const { onModalInit, isStatic } = props;
    if (_shown) return;
    $modal = $(modal);

    _shown = true;
    onShowModal($modal);
    $modal
      .modal({
        backdrop: isStatic ? 'static' : true,
      })
      .on('hide.bs.modal', () => {
        $body.find('.behind').last().removeClass('behind');
      })
      .on('hidden.bs.modal', onHideModel.bind(this))
      .on('shown.bs.modal', () => {
        //// console.log($modal);
        if (!isRight()) {
          $modal.draggable({ handle: '.modal-header' });
        }
        $modal.addClass('shown');
        $modal.find('.close-modal').on('click', () => {
          $modal.modal('hide');
        });
      });

    $('body').addClass('react-modal-show');

    if (typeof onModalInit === 'function') {
      onModalInit($modal);
    } else {
      onModalInit.current = $modal;
    }
  };

  const onModalInit = (el: any) => {
    showModal(el);
  };

  const _effect = () => {
    const { ef } = props;
    return `effect-${ef}`;
  };

  const onMinimize = () => {
    _minimized = true;
    $modal.modal('hide');
    let miniButton: any = $(`
        <button class="btn btn-outline-light btn-lg">
            <i class="fal fa-window"></i> ${props.title}
        </button>
    `).on('click', () => {
      miniButton.remove();
      $modal.modal('show');
      _minimized = false;
      onShowModal($modal);
    });

    miniButton.appendTo(window.$modalMinimizedArea);
  };

  const render = () => {
    return (
      // <Portal
      //   id={document.body}
      // >
      <Modal
        className={classNames('modal fade', _effect(), className)}
        ref={onModalInit.bind(this)}
        role="dialog"
      >
        <div
          className={classNames(
            'modal-dialog',
            { 'modal-dialog-scrollable': scrollable, 'modal-dialog-centered': centered },
            classDialogSize,
          )}
        >
          {/*{*/}
          {/*     this.isRight() && (*/}
          {/*        <RightSideButton onClick={() => this.$modal.modal('hide')} className="btn btn-lg btn-light"><X /></RightSideButton>*/}
          {/*    )*/}
          {/*}*/}
          <div className="modal-content">
            {!hideHeader && (
              <div style={{ cursor: 'move' }} className="modal-header">
                <h6 className="modal-title">{title}</h6>
                {canClose && (
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <X width={20} height={20} />
                    <span className="sr-only">닫기</span>
                  </button>
                )}
                {hasMinimize && (
                  <RightSideButton
                    type="button"
                    onClick={onMinimize.bind(this)}
                    className="btn btn-link"
                  >
                    <Minus />
                  </RightSideButton>
                )}
              </div>
            )}
            {children}
          </div>
        </div>
      </Modal>
      // </Portal>
    );
  };

  return render();
};

ModalHTML.defaultProps = {
  onModalInit: () => null,
  scrollable: true,
  classDialogSize: '',
  ef: 'scale',
  isStatic: true,
  hasMinimize: false,
  canClose: true,
};

export default ModalHTML;
