import React, { ReactNode, useEffect, useRef } from 'react';
import $ from 'jquery';
import 'jqueryui';

interface ISortable {
  children: React.ReactNode | React.ReactNode[];
  forcePlaceholderSize: boolean;
  handle: string;
  onChange: (order: any) => void;
  placeholder: string;
  animation: number;
}

const Sortable: React.FC<ISortable> = (props: ISortable) => {
  const { onChange } = props;

  const thisRef = useRef<any>(null);

  useEffect(() => {
    const { forcePlaceholderSize, handle, placeholder, animation = 150 } = props;

    const node: any = thisRef.current;
    const nodeSortable = $(node);

    nodeSortable.sortable({
      handle: handle ?? '',
      cursor: 'move',
      stop: () => {
        const order = $(node).sortable('toArray');
        $(order).children(':first').sortable('cancel');
        // console.log('ORDER', order);
        onChange(order);
      },
    });

    return () => {
      try {
        const node: any = thisRef.current;
        $(node).sortable('destroy');
      } catch (e) {}
    };
  });

  return (
    <div ref={thisRef} className="wd-100p">
      {props.children}
    </div>
  );
};

export default React.memo(Sortable);
