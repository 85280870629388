import React from 'react';
import { Button } from '@base/components/form';
import classnames from 'classnames';
import _uniqueId from 'lodash/uniqueId';

export const TypeTab = {
  header: 'header',
  basic: 'basic',
  line: 'line',
  list: 'list',
};

export const Tab = ({ className = '', value = '', id = '', children }: any) => {
  return value == id && <div className={className}>{children}</div>;
};

const Tabs = ({
  Tag = 'ul',
  tab,
  onChange = () => {},
  tabs = [],
  className = 'bd-b-0',
  typeTab = TypeTab.basic,
  itemClass = '',
  labelClass = '',
  renderTab = null,
  keyValue = 'value',
  keyLabel = 'label',
  style = {},
}: any) => {
  const renderItem = (item: any, index: any) => {
    if (renderTab) {
      return renderTab(item, index);
    }
    switch (typeTab) {
      case TypeTab.header:
        return (
          <Button
            onClick={() => onChange(item)}
            className={classnames('nav-link', {
              active: tab?.[keyValue] == item?.[keyValue],
            })}
            color=""
            dataToggle="tab"
            disabled={item?.disabled}
            key={index != undefined ? index : _uniqueId('tabs-')}
          >
            {item?.[keyLabel]}
          </Button>
        );

      case TypeTab.list:
        return (
          <li
            className={classnames('nav-item cursor-pointer', itemClass)}
            onClick={() => onChange(item)}
            key={index != undefined ? index : _uniqueId('tabs-')}
          >
            <button
              type="button"
              className={classnames(
                'btn nav-link',
                {
                  active: tab?.[keyValue] == item?.[keyValue],
                },
                labelClass,
              )}
            >
              {item?.[keyLabel]}
            </button>
          </li>
        );

      default:
        return (
          <li
            className={classnames('nav-item cursor-pointer', itemClass)}
            onClick={() => onChange(item)}
            key={index != undefined ? index : _uniqueId('tabs-')}
          >
            <a
              className={classnames(
                'nav-link',
                {
                  active: tab?.[keyValue] == item?.[keyValue],
                },
                labelClass,
              )}
            >
              {item?.[keyLabel]}
            </a>
          </li>
        );
    }
  };

  return (
    <Tag
      className={classnames(
        'nav',
        className,
        {
          ['nav-line']: typeTab == TypeTab.line || typeTab == TypeTab.header,
          ['nav-tabs']: typeTab == TypeTab.basic || typeTab == TypeTab.list,
        },
        {
          ['list-tabs']: typeTab == TypeTab.list,
        },
      )}
      style={style}
    >
      {tabs.map((item: any, index: any) => renderItem(item, index))}
    </Tag>
  );
};

export default Tabs;
