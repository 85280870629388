import React from 'react';
import classNames from 'classnames';

const defaultProps = {
  tag: 'div',
};

const Body: React.FC<any> = (props: any) => {
  const { className, tag: Tag, children, style = {} } = props;

  return (
    <Tag
      className={classNames(className, 'pd-10')} // fix for Main > Setting > General
      style={{ ...style }}
    >
      {children}
    </Tag>
  );
};

Body.defaultProps = defaultProps;

export default Body;
