import React, { memo, useEffect, useState } from 'react';
import ListToolbar from '@base/components/list/list-toolbar';
import { IToolbarActions } from '@base/components/list/list-toolbar/list-toolbar-action-more';
import { IToolbarListTypeButton } from '@base/components/list/list-toolbar/list-toolbar-list-type-button';
import { useRecoilState } from 'recoil';
import { listFavoriteAtom, listPageSettingsAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_LIST_PAGE_CONFIG, UPDATE_USER_SETTING } from '@base/services/graphql/setting';
import { isUndefined } from 'lodash';
import { ListType } from '@base/types/enums';
import { toast } from 'react-toastify';

interface IListToolbarContainerProps {
  isSplitMode?: boolean;
  setIsSplitMode?: (isSplitMode: boolean) => void;
  categoryOptions?: any;
  categorySelected?: string;
  onCategoryChange?: (category: string) => void;
  addOptionType?: string; // category , tab
  addOptions?: any;
  onAdd?: (category?: any) => void;
  canAdd?: boolean;
  onRefresh?: () => void;
  onEmpty?: () => void;
  listTypeProps?: IToolbarListTypeButton; // type grid | list | kanban | calendar
  actionsProps?: IToolbarActions; // more buttons
  tooltipMessage?: any;
  useNewFilter?: boolean;
  hideHeaderTitle?: boolean;
  menu?: string;
  moreAction?: any;
}
const ListToolbarContainer: React.FC<IListToolbarContainerProps> = (
  props: IListToolbarContainerProps,
) => {
  const { isSplitMode, setIsSplitMode, listTypeProps, menu = '', ...remainProps } = props;
  const { onChangeListType, listTypeStr } = listTypeProps ?? {};

  // recoil
  const [favoriteList, setFavoriteList] = useRecoilState(listFavoriteAtom);

  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_USER_SETTING,
    'setting_updateUserSetting',
    {},
  );

  const onNewChangeListType = (listType: ListType) => {
    // callBack
    onChangeListType && onChangeListType(listType);

    if (listType === ListType.SPLIT) {
      // just use for old
      setIsSplitMode && setIsSplitMode(true);
    }
  };

  const newListTypeProps: IToolbarListTypeButton | undefined = isUndefined(listTypeProps)
    ? undefined
    : {
      ...listTypeProps,
      onChangeListType: onNewChangeListType,
      listTypeStr: listTypeStr ?? ListType.LIST,
    };

  const handleSetIsSplitMode = (nVal: boolean) => {
    // change list type
    onNewChangeListType(nVal ? ListType.SPLIT : ListType.LIST);

    // just use for old
    setIsSplitMode && setIsSplitMode(nVal);
  };

  const onChangeWishList = (title: string, selected: any) => {
    const addItem: any = {
      menuKey: selected,
      menuTitle: title,
      menuPath: `/${menu}/${selected}`,
    };
    const newFavorites: any[] = favoriteList.filter((_item: any) => {
      if (_item.menuKey != addItem.menuKey) {
        return _item;
      }
    });
    if (newFavorites?.length === favoriteList?.length) {
      newFavorites.push(addItem);
    }

    setFavoriteList(newFavorites);
    const params = {
      menu: 'common',
      key: 'menu_favorites',
      value: JSON.stringify(newFavorites),
    };
    mUpdate.mutate({ userSetting: params });

    // let newVal = favoriteList;
    // let submitValue = [];
    // const addVal = {
    //   menuKey: menu + '_' + selected,
    //   menuTitle: title,
    //   menuPath: '/' + menu + '/' + selected,
    // };

    // const newValue = newVal.filter((_item: any) => {
    //   if (_item.menuKey != addVal.menuKey) {
    //     return _item;
    //   }
    // });

    // if (newValue.length == newVal.length) {
    //   submitValue = [...newValue, addVal];
    // } else {
    //   submitValue = [...newValue];
    // }

    // const postParam = {
    //   menu: 'common',
    //   key: 'menu_favorite',
    //   value: JSON.stringify(submitValue),
    // };

    // if (newVal.length > submitValue.length) {
    //   toast.error('Removed site from favorite list');
    // } else {
    //   toast.success('Added site to favorite list');
    // }
    // setFavoriteList(submitValue);
    // mUpdate.mutate({ menuSetting: postParam });
  };

  return (
    <>
      <ListToolbar
        menu={menu}
        setIsSplitMode={handleSetIsSplitMode}
        listTypeProps={newListTypeProps}
        isSplitMode={isSplitMode}
        favoriteList={favoriteList}
        onChangeWishList={(title: string, selected: any) => onChangeWishList(title, selected)}
        {...remainProps}
      />
    </>
  );
};

export default ListToolbarContainer;
