import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Storages from '@base/utils/storages/ls';
import { getLanguageByMenuAPI } from '@base/services/setting-service';
import usePost from '@base/hooks/usePost';
import { GET_LANGUAGE_BY_MENU, GET_PUBLIC_LANGUAGE_BY_MENU } from './graphql/setting';
import { IMenuLanguageResponse } from '@base/types/interfaces/setting';
import usePublicPost from '@base/hooks/usePublicPost';

/******* Defined *****/
const menuLoaded: string[] = [];
const langLoaded: string[] = [];
const Ls = new Storages();

/******* Function *****/
export const getCurrentLang = () => {
  return Ls.get('language-system') ? (Ls.get('language-system') as string) : 'en';
};
export const loadCommonLanguage = () => {
  const currentLang = getCurrentLang();
  i18n
    .use(detector)
    .use(initReactI18next)
    .init(
      {
        lng: currentLang,
        fallbackLng: currentLang,
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      },
      (err: any, t: any) => {
        if (err) {
          // console.log('ERROR Load language', err);
        }

        //get common lang
        //TODO: will be removed
        getLanguageByMenu(['common', 'pagelayout', 'generalsetting'], null);
      },
    );
};

export const getLanguageByMenu = async (menus: string[], cb: any) => {
  //// console.log('Load Languages menus', menus);
  const currentLang = getCurrentLang();
  menus.map((_menu) => {
    if (menuLoaded.indexOf(_menu) !== -1 && langLoaded.indexOf(currentLang) !== -1) {
      cb && cb();
      return;
    }
  });

  menus.map((_menu) => {
    if (menuLoaded.indexOf(_menu) === -1) {
      menuLoaded.push(_menu);
    }
  });
  if (langLoaded.indexOf(currentLang) === -1) {
    langLoaded.push(currentLang);
  }
  // useQuery
  const res = await getLanguageByMenuAPI(menus, currentLang);
  if (res && res.results) {
    const rows = res.results || {};
    i18n.addResourceBundle(currentLang, 'translation', rows, true, true);
    //refresh lang
    await i18n.changeLanguage(currentLang);

    // window.jQuery(window).trigger('translation');
  }
};
export const useLanguageByMenu = async (menus: string[], cb?: any) => {
  const currentLang = getCurrentLang();
  //// console.log('useLanguageByMenu', menus, currentLang);
  menus.map((_menu) => {
    if (menuLoaded.indexOf(_menu) !== -1 && langLoaded.indexOf(currentLang) !== -1) {
      cb && cb();
      return;
    }
  });

  menus.map((_menu) => {
    if (menuLoaded.indexOf(_menu) === -1) {
      menuLoaded.push(_menu);
    }
  });
  if (langLoaded.indexOf(currentLang) === -1) {
    langLoaded.push(currentLang);
  }
  let queryKeys: string[] = ['setting_menusLanguages', ...menus, currentLang];
  let variables = {
    menus: menus,
    lang: currentLang,
  };
  const { data } = await usePost<IMenuLanguageResponse>(queryKeys, GET_LANGUAGE_BY_MENU, variables);
  if (data && data.results) {
    //// console.log('############');
    //// console.log(data);
    const rows = data.results || {};
    i18n.addResourceBundle(currentLang, 'translation', rows, true, true);
    //refresh lang
    //// console.log('language', i18n.language, 'current', currentLang);
    await i18n.changeLanguage(currentLang);
  }
};

export const updateLanguageI18n = (langKey: string, langValue: any) => {
  const userLang = getCurrentLang();
  let newLangJson: any = {};
  newLangJson[langKey] = langValue[userLang];
  i18n.addResources(userLang, 'translation', newLangJson);
};

//for public site
export const usePublicLanguageByMenu = async (menus: string[], token: string) => {
  const currentLang = getCurrentLang();
  //// console.log('useLanguageByMenu', menus, currentLang);
  menus.map((_menu) => {
    if (menuLoaded.indexOf(_menu) !== -1 && langLoaded.indexOf(currentLang) !== -1) {
      return;
    }
  });
  menus.map((_menu) => {
    if (menuLoaded.indexOf(_menu) === -1) {
      menuLoaded.push(_menu);
    }
  });
  if (langLoaded.indexOf(currentLang) === -1) {
    langLoaded.push(currentLang);
  }
  let queryKeys: string[] = ['tracking_menusLanguages', ...menus, currentLang];
  let variables = {
    menus: menus,
    lang: currentLang,
    token,
  };
  const { data } = await usePublicPost<IMenuLanguageResponse>(
    queryKeys,
    GET_PUBLIC_LANGUAGE_BY_MENU,
    variables,
    { enabled: menus.length > 0 && token !== '' },
  );
  if (data && data.results) {
    //// console.log('############');
    //// console.log(data);
    const rows = data.results || {};
    i18n.addResourceBundle(currentLang, 'translation', rows, true, true);
    //refresh lang
    //// console.log('language', i18n.language, 'current', currentLang);
    await i18n.changeLanguage(currentLang);
  }
};
