import React, { FC } from 'react';
import classNames from 'classnames';
import ListGridItem from '@base/components/list/list-grid/list-grid-item';

interface IListGridProp {
  category?: string;
  primaryKey?: string;
  titleKey?: string;
  titleLink?: string;
  onClickTitleLink?: any;
  menuSource?: string;
  listData: any[];
  isFetching: boolean;
  fetching: React.ReactNode;
  nodata?: React.ReactNode;
  isSplitMode?: boolean;
  selectedIds: string[];
  columns: any[];
  hideColumns: string[];
  gridColumnsCount?: number;
  device: string;
  columnRenderRemap?: {
    [key: string]: any;
  };
  onCheckedRow: (checkedValue: string | number) => void;
  listGridItemRender?: FC<any>;
  columnsOnSplitMode?: {
    [key: string]: any;
  };
}

const ListGrid: FC<IListGridProp> = (props) => {
  const {
    category,
    primaryKey, //item key --> for selected id
    titleKey,
    titleLink,
    onClickTitleLink,
    menuSource,
    listData, //items
    isFetching,
    fetching, //component
    nodata, //component
    isSplitMode,
    selectedIds,
    device,
    columns, //data
    columnRenderRemap, //render: data + component
    hideColumns, //not show
    onCheckedRow,
    listGridItemRender, //custom listgrid item
    columnsOnSplitMode,
  }: IListGridProp = props;

  //get grid show columns
  const gridColumnsCount =
    device === 'mobile' || device === 'tablet' || isSplitMode ? 1 : props.gridColumnsCount;

  //get showing columns, skip hidden
  const getShowingColumns = () => {
    return columns.reduce((obj, column) => {
      if (hideColumns.indexOf(column.name) === -1) {
        obj.push(column);
      }
      return obj;
    }, []);
  };

  //create grid rows based on gridColumnsCount config
  const makeRows = (items: any[], n = 2): any[] => {
    if (!items) {
      return [];
    }
    let result = [];
    for (let i = 0; i < items.length; i += n) {
      result.push(items.slice(i, i + n));
    }
    return result;
  };
  //show grid rows: 1, 2,...
  const rows = makeRows(listData, gridColumnsCount);

  //get render column
  const columnRender = (col: string) => {
    return columnRenderRemap?.[col];
  };

  //get custom or standard grid item
  const GridItem = listGridItemRender ? listGridItemRender : ListGridItem;

  //Grid row
  const ListGridRow: FC<any> = ({ children }: any) => {
    return isSplitMode ? children : <div className="row row-xs">{children}</div>;
  };

  //main render
  return (
    <div className={classNames('grid-container', { 'one-item': gridColumnsCount == 1 })}>
      {isFetching && fetching}
      {!isFetching &&
        rows?.length > 0 &&
        rows.map((_items: any[], i: number) => {
          return (
            <ListGridRow key={i}>
              {_items.map((_item: any, i2: number) => {
                const gridItemProps = {
                  category: category,
                  primaryKey: primaryKey ?? 'id',
                  titleKey: titleKey,
                  titleLink: titleLink,
                  onClickTitleLink: onClickTitleLink,
                  menuSource: menuSource,
                  isSplitMode: isSplitMode || false,
                  data: _item,
                  selectedIds,
                  onCheckedRow,
                  columns: getShowingColumns(), //data
                  columnRender, //data + components
                  hideColumns,
                  columnsOnSplitMode,
                };
                return <GridItem key={i2} {...gridItemProps} onCheckedRow={onCheckedRow} />;
              })}
            </ListGridRow>
          );
        })}
      {!isFetching && !listData?.length && nodata}
    </div>
  );
};

ListGrid.defaultProps = {
  primaryKey: 'id',
  titleKey: 'name',
  gridColumnsCount: 2,
};

export default ListGrid;
