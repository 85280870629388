import React from 'react';
import TextView from '@base/containers/view-field/text/view';
import { useTranslation } from 'react-i18next';

const View: React.FC<any> = (props) => {
  const { value } = props;
  //// console.log('selectboxView', value, props);
  const { t } = useTranslation();

  return <TextView value={value?.languageKey ? t(value.languageKey) : value || ''} />;
};

export default View;
