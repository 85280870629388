import React from 'react';
import classnames from 'classnames';
import { DatePicker } from '@base/components/date-picker';

interface IProps {
  value: any;
  onChange: (params: any) => void;
  showTimeInput?: boolean;
  errors: any;
  dateFormat?: string;
}

const DateEdit = (props: IProps) => {
  const { value, onChange, errors, showTimeInput = false, dateFormat = 'yyyy/MM/dd' } = props;
  return (
    <div className="wd-100p">
      <div
        className={classnames({
          'is-required': errors?.isRequired,
        })}
      >
        <DatePicker
          selected={value && value != '0001-01-01T00:00:00Z' ? new Date(value) : null}
          onChange={(data: Date) => {
            // console.log('datepicker', data);
            onChange && onChange(data.toISOString());
          }}
          showTimeInput={showTimeInput}
          dateFormat={dateFormat}
        />
      </div>
      {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
    </div>
  );
};

export default DateEdit;
