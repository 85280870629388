import React, { useEffect, useRef, useState, VFC } from 'react';
import { Calendar as CalendarIcon } from 'react-feather';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import Portal from '@base/components/portal';
//import { usePopper } from 'react-popper';
import { clickOutSide, formatDateDisplay } from '@base/utils/helpers';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import dayjs from 'dayjs';

const CalendarWrapper: any = styled.div`
  ${(props: any) =>
    !props.nonStyle &&
    css`
      background-color: var(--background-box);
      border: 1px solid var(--input-border-color);
      border-radius: 5px;
      input {
        background-color: var(--background-box) !important;
        border: none;
      }

      .input-group-prepend {
        border-right: 2px solid var(--border-color-2);
      }
    `}
`;

interface IDateRangeSelection {
  onChange?: (selectionRange: { endDate: null; startDate: null } | null) => void;
  isInline?: boolean;
  readOnly?: boolean;
  inputRender?: (args: any) => void;
}

const DateRangeSelection: VFC<IDateRangeSelection> = (props: IDateRangeSelection) => {
  const { onChange, isInline, readOnly, inputRender = null } = props;

  const key = 'conversionDate';

  const [dialogShown, showDialog] = useState(false);

  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: dayjs().toDate(),
    endDate: dayjs().add(1, 'weeks').toDate(),
    key,
  });

  useEffect(() => {
    if (dialogShown) {
      const offOutside = clickOutSide('customDateRangeSelect', () => {
        showDialog(false);
      });

      if (offOutside) offOutside();
    }
  }, [dialogShown]);

  const _formatDate = () => {
    const userFormat = 'YYYY/DD/MM';
    const { startDate, endDate } = selectionRange;

    if (!startDate || !endDate) return '';

    return `${formatDateDisplay(startDate, userFormat)} - ${formatDateDisplay(
      endDate,
      userFormat,
    )}`;
  };

  const handleSelect = (date: any) => {
    setSelectionRange(date);
  };

  const defaultStaticRanges = createStaticRanges([
    {
      label: 'Customize Selection',
      range: () => selectionRange,
      isSelected: (range) => {
        return range.startDate == null && range.endDate == null;
      },
    },
  ]);

  const [buttonElement, setButtonElement] = useState<any | undefined>(null);

  const [popperElement, setPopperElement] = useState<any | undefined>(null);

  //const { styles, attributes } = usePopper(buttonElement, popperElement);

  return (
    <CalendarWrapper nonStyle={!!inputRender}>
      {dialogShown && (
        <Portal id={document.body}>
          <div
            id="customDateRangeSelect"
            className="dropdown-menu popper show"
            ref={setPopperElement}
          //style={styles.popper}
          //{...attributes.popper}
          >
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={(range) => handleSelect(range[key])}
              staticRanges={defaultStaticRanges}
            />

            <hr />
            <div className="text-right pd-r-10 pd-l-10 pd-b-10">
              <button
                className="btn btn-secondary mg-r-20"
                type="button"
                onClick={() => {
                  showDialog(false);
                  setSelectionRange({
                    ...selectionRange,
                    startDate: undefined,
                    endDate: undefined,
                  });
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                type="button"
                onClick={(e) => {
                  onChange && onChange(selectionRange);
                  showDialog(false);
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </Portal>
      )}
      {!inputRender ? (
        <span className={classNames('input-group', { 'wd-50p d-inline-flex': isInline })}>
          <span className="input-group-prepend ht-38">
            <span className="input-group-text">
              <CalendarIcon />
            </span>
          </span>
          <input
            onFocus={() => showDialog(true)}
            ref={setButtonElement}
            // readOnly={!!readOnly}
            type="datetime"
            value={_formatDate()}
            onChange={() => { }}
            // defaultValue={_formatDate()}
            className="form-control ht-38"
          />
        </span>
      ) : (
        inputRender({
          onClick: () => showDialog(true),
          value: _formatDate(),
          onRef: setButtonElement,
        })
      )}
      {selectionRange.startDate && (
        <input
          type={'hidden'}
          name={`${name}[start]`}
          value={dayjs(selectionRange.startDate).format('YYYY-MM-DD')}
        />
      )}
      {selectionRange.endDate && (
        <input
          type={'hidden'}
          name={`${name}[end]`}
          value={dayjs(selectionRange.endDate).format('YYYY-MM-DD')}
        />
      )}
    </CalendarWrapper>
  );
};

export default DateRangeSelection;
