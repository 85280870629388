import React from 'react';
import classNames from 'classnames';
import { nanoid } from '@base/utils/helpers/general.utils';
import SpanLang from '@base/components/form/span-lang';

const Checkbox = ({
  onChange,
  id = nanoid(),
  name = nanoid(),
  className,
  inputClassName,
  label,
  labelClassName,
  spanClassName,
  disabled,
  checked,
}: any) => {
  return (
    <div className={classNames('custom-control custom-checkbox', className)}>
      <input
        type="checkbox"
        disabled={disabled}
        className={classNames('custom-control-input', inputClassName)}
        onChange={onChange}
        id={id}
        checked={checked}
        name={name}
      />
      <label className={classNames('custom-control-label', labelClassName)} htmlFor={id}>
        {label && <SpanLang keyLang={label} className={spanClassName} />}
      </label>
    </div>
  );
};

export default Checkbox;
