import { ChevronDown, Plus, Star, RefreshCw, HelpCircle, Trash } from 'react-feather';
import React, { useEffect, useState } from 'react';
import Button from '@base/components/form/button';
import {
  IToolbarListTypeButton,
  ToolbarListTypeButton,
} from '@base/components/list/list-toolbar/list-toolbar-list-type-button';
import {
  IToolbarActions,
  ToolbarActions,
} from '@base/components/list/list-toolbar/list-toolbar-action-more';
import { keys } from 'lodash';
import { useTranslation } from 'react-i18next';
import ListFilter from '@base/containers/list/filter';

interface IListToolbar {
  isSplitMode?: boolean;
  setIsSplitMode?: (isSplitMode: boolean) => void;
  categoryOptions?: any;
  categorySelected?: string;
  onCategoryChange?: (category: string) => void;
  addOptionType?: string; // category , tab
  addOptions?: any;
  onAdd?: (category?: any) => void;
  canAdd?: boolean;
  onRefresh?: () => void;
  onEmpty?: () => void;
  listTypeProps?: IToolbarListTypeButton; // type grid | list | kanban | calendar
  actionsProps?: IToolbarActions; // more buttons
  useNewFilter?: boolean;
  hideHeaderTitle?: boolean;
  menu?: string;
  favoriteList?: any | [];
  onChangeWishList?: (title: string, selected: any) => any;
  moreAction?: any;
}

const ListToolbar: React.FC<IListToolbar> = (props) => {
  const {
    isSplitMode,
    setIsSplitMode,
    categoryOptions = {},
    categorySelected = 'account', //all, account, contact, ...
    onCategoryChange,
    onRefresh,
    onEmpty,
    addOptionType = 'category',
    addOptions = {},
    onAdd,
    canAdd = true,
    listTypeProps,
    actionsProps,
    useNewFilter = false,
    hideHeaderTitle = false,
    menu = '',
    favoriteList = [],
    onChangeWishList,
    moreAction,
  } = props;

  const { t } = useTranslation();

  let headerTitle = '-- None --';
  if (typeof categoryOptions[categorySelected] === 'object') {
    headerTitle = categoryOptions[categorySelected].title;
  } else {
    headerTitle = categoryOptions[categorySelected];
  }

  return (
    <div className="list-header">
      {/* Category */}
      {!hideHeaderTitle ? (
        <div className="d-flex align-items-center mg-r-10 mg-b-5">
          <button
            type="button"
            className={
              favoriteList?.findIndex((v: any) => v.menuKey == categorySelected) >= 0
                ? 'btn btn-xs btn-icon btn-favorite active'
                : 'btn btn-xs btn-icon btn-favorite'
            }
            aria-label="즐겨찾기 추가"
            onClick={() => {
              onChangeWishList && onChangeWishList(headerTitle, categorySelected);
            }}
          >
            <Star size="18" />
          </button>
          <h1 className="list-header-title" style={{ maxWidth: isSplitMode ? '120px' : '100%' }}>
            {headerTitle}
          </h1>
          {onCategoryChange && categoryOptions && (
            <ListFilter
              listFilter={categoryOptions}
              value={categorySelected}
              onChange={onCategoryChange}
              useNewFilter={useNewFilter}
              menu={menu}
            />
          )}
        </div>
      ) : (
        ''
      )}

      <div className="d-flex align-items-center mg-l-auto mg-b-5">
        {/* Adding buttons */}
        {canAdd && onAdd && (
          <div className="btn-group dropdown">
            <button type="button" className="btn btn-primary pd-x-10" onClick={() => onAdd()}>
              <Plus /> {!isSplitMode ? 'New' : ''}
            </button>
            {addOptions && keys(addOptions).length > 0 && (
              <>
                <button
                  type="button"
                  className="btn btn-primary dropdown-toggle-split pd-x-5"
                  data-toggle="dropdown"
                >
                  <ChevronDown />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  {addOptionType === 'category' && (
                    <>
                      {keys(addOptions).map(
                        (key, i) =>
                          key !== categorySelected && (
                            <button
                              key={i}
                              type="button"
                              className="dropdown-item"
                              onClick={() => onAdd(key)}
                            >
                              <span className="mg-r-5">{addOptions[key].icon ?? ''}</span>
                              {addOptions[key].name}
                            </button>
                          ),
                      )}
                    </>
                  )}
                  {addOptionType === 'tab' && (
                    <>
                      {keys(addOptions).map((key, i) => (
                        <button
                          key={i}
                          type="button"
                          className="dropdown-item"
                          onClick={() => onAdd(key)}
                        >
                          {addOptions[key].icon ? addOptions[key].icon : ''} {addOptions[key].label}
                        </button>
                      ))}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        )}

        {!isSplitMode && (
          <div className="button-group">
            {/* actions more dropdown */}
            {actionsProps && <ToolbarActions {...actionsProps} />}

            {/* list type grid */}
            {listTypeProps && <ToolbarListTypeButton {...listTypeProps} />}

            {onRefresh && (
              <button
                type="button"
                className="btn btn-white btn-icon"
                data-han-tooltip="Reload"
                onClick={onRefresh}
              >
                <RefreshCw />
                <span className="sr-only">새로고침</span>
              </button>
            )}
            {onEmpty && (
              <button
                type="button"
                className="btn btn-white btn-icon"
                data-han-tooltip="Empty All"
                onClick={onEmpty}
              >
                <Trash className='tx-danger' />
              </button>
            )}
          </div>
        )}

        {isSplitMode && setIsSplitMode && (
          <Button
            icon={'CreditCard'}
            color="white"
            isSpanTitle={false}
            onClick={() => {
              setIsSplitMode(!isSplitMode);
            }}
            data-han-tooltip={'Normal View'}
            aria-label="split view"
            className="btn btn-white btn-icon mg-l-5"
          />
        )}

        {/* More icon */}
        {moreAction && moreAction()}
      </div>
    </div>
  );
};

export default ListToolbar;
