import React from 'react';
import classNames from 'classnames';
import { Check, Circle, CheckCircle } from 'react-feather';
import { NoData } from '@base/components';

interface ProgressProps {
  className?: string;
  steps: any[];
}

const Progress: React.FC<ProgressProps> = (props) => {
  const { className, steps } = props;

  let currentStep: any = {};
  if (steps.length > 0) {
    currentStep = { ...steps[0], index: 0 };
    const fIdx = steps.findIndex((_step: any) => _step?.name?.length > 0);
    if (fIdx > -1) {
      currentStep = { ...steps[fIdx], index: fIdx };
    }
  }

  return (
    <div className={classNames("w-100 bg-white pd-10 bd-b ht-140", className)}>
      <ol className="list-unstyled pd-15 bg-light rounded process-wrap text-nowrap">
        {steps.length === 0 && <NoData />}
        {steps.length > 0 &&
          steps.map((_step, _index) => (
            <li
              key={_index}
              className={classNames('process-item', {
                current: currentStep.index == _index,
                done: _index < currentStep.index,
              })}
            >
              <button type="button" className="btn process-item-btn">
                {currentStep.index == _index ? (
                  <CheckCircle />
                ) : _index < currentStep.index ? (
                  <Check />
                ) : (
                  <Circle />
                )}
              </button>
              <div className="process-item-text mg-t-10">{_step.label}</div>
              {currentStep.index == _index && _step?.name && (
                <div className="mg-t-5">
                  <span className="tx-color-03 mg-r-5">Status</span>
                  <span>{_step?.name}</span>
                </div>
              )}
            </li>
          ))}
      </ol>
    </div>
  );
};

export default Progress;
