import * as menuKeys from './menus';
import { SequenceWriteField } from '@activity/config/write-field/sequence';
import { ChecklistWriteField } from '@activity/config/write-field/checklist';
import {
  BusinessProcessWriteField,
  ClosedWriteField,
  StepWriteField,
  ActionWriteField,
  CriteriaWriteField,
  TriggerWriteField,
  AutomationWriteField,
  InstantWriteField,
} from '@settings/process/config/write-field';
import StageWriteField from '@settings/process/config/write-field/stage';
import {
  KBWriteField,
  EmailWriteField,
  TaskWriteField,
  CallWriteField,
  DMWriteField,
  SmsWriteField,
  MeetingWriteField,
} from '@settings/template/config/write-field';
// import RoleWriteField from '@settings/organization/config/write-field/role';
// import SalesCommissionWriteField from '@settings/sales-commission/config/write-field';
import ARWriteField from '@settings/assignment-rule/config/write-field';
import ARWriteRuleField from '@settings/assignment-rule/config/write-rule-field/index';
// import SalesTeamWriteField from '@settings/sales-team/config/write-field';
// import LeadARWriteField from '@settings/lead-scoring-rule/config/write-field';

export const ModuleConfig: any = {
  [menuKeys.MENU_ACTIVITY_TASK_SEQUENCE]: SequenceWriteField,
  [menuKeys.MENU_ACTIVITY_TASK_CHECKLIST]: ChecklistWriteField,
  [menuKeys.MENU_PROCESS_BUSINESS]: BusinessProcessWriteField,
  [menuKeys.MENU_PROCESS_STEP]: StepWriteField,
  [menuKeys.MENU_PROCESS_STAGE]: StageWriteField,
  [menuKeys.MENU_PROCESS_CLOSED]: ClosedWriteField,
  [menuKeys.MENU_PROCESS_SETTING_ACTION]: ActionWriteField,
  [menuKeys.MENU_PROCESS_SETTING_CRITERIA]: CriteriaWriteField,
  [menuKeys.MENU_PROCESS_SETTING_TRIGGER]: TriggerWriteField,
  [menuKeys.MENU_PROCESS_AUTOMATION]: AutomationWriteField,
  [menuKeys.MENU_PROCESS_AUTOMATION_INSTANT]: InstantWriteField,
  [menuKeys.MENU_TEMPLATE_KNOWLEDGEBASE]: KBWriteField,
  [menuKeys.MENU_TEMPLATE_EMAIL]: EmailWriteField,
  [menuKeys.MENU_TEMPLATE_SMS]: SmsWriteField,
  [menuKeys.MENU_TEMPLATE_CALL]: CallWriteField,
  [menuKeys.MENU_TEMPLATE_TASK]: TaskWriteField,
  [menuKeys.MENU_TEMPLATE_MEETING]: MeetingWriteField,
  [menuKeys.MENU_TEMPLATE_DM]: DMWriteField,
  // [menuKeys.MENU_SETTING_ORGANIZATION_ROLE]: RoleWriteField,
  // [menuKeys.MENU_SETTING_SALES_COMMISSION]: SalesCommissionWriteField,
  [menuKeys.MENU_SETTING_ASSIGNMENT_RULE]: ARWriteField,
  [menuKeys.MENU_SETTING_ASSIGNMENT_RULE_ENTRY]: ARWriteRuleField,
  // [menuKeys.MENU_SETTING_SALES_TEAM]: SalesTeamWriteField,
  // [menuKeys.MENU_SETTING_LEAD_SCORING_RULE]: LeadARWriteField,
};

export const getConfigForMenu = (menu = '') => {
  return {
    ...(ModuleConfig?.[menu] || {}),
  };
};
