import classNames from 'classnames';
import React from 'react';

const SpanLoading: React.FC<any> = (props) => {
  const { className } = props;
  return (
    <span
      className={classNames("spinner-border spinner-border-sm tx-primary mg-l-5", className)}
      role="status"
      aria-hidden="true"
    />
  );
};

export default SpanLoading;
