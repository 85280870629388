import React, { lazy } from 'react';
import CommonViewField from '@base/containers/view-field/common';

const View = lazy(() => import('./view'));
const Edit = lazy(() => import('./edit'));

export interface Repeat {
  use: boolean;
  type: string;
  startTime: string;
  endTime: string;
  everyNr: number;
  weekdays: string;
  //monthly
  monthlyOption: string; //or 'day', 'last'
  monthlyWeek: number;
  monthlyDay: number;
  //yearly
  yearlyOption: string; //or 'day'
  yearlyWeekMonth: number;
  yearlyDayMonth: number;
  yearlyWeek: number;
  yearlyDay: number;
}

interface RepeatTimeProps {
  value: Repeat;
  onSave: (params: any) => void;
  keyName: string;
  menuSourceId: string;
  menuSource: string;
}

const RepeatTime = (props: RepeatTimeProps) => {
  const { onSave, value, menuSourceId, menuSource, keyName, ...remainProps } = props;
  return (
    <CommonViewField
      keyName={keyName}
      menuSourceId={menuSourceId}
      menuSource={menuSource}
      {...remainProps}
      onSave={onSave}
      componentView={View}
      componentEdit={Edit}
      value={value}
    />
  );
};

export default RepeatTime;
