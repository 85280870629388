import React from 'react';
import { useTranslation } from 'react-i18next';
import { isFunction } from 'lodash';
import ListGridItemBase, {
  defaultColumnRender,
  ListGridItemHeader,
  ListGridItemBody,
} from '@base/components/list/list-grid/list-grid-item';
import { generateUUID } from '@base/utils/helpers';

export interface IListGridItem {
  menuSource?: string;
  primaryKey: string | number;
  titleKey: string;
  titleLink?: string;
  onClickTitleLink?: any;
  data: any;
  isSplitMode: boolean;
  onCheckedRow: (checkedValue: string | number) => void;
  selectedIds: string[];
  columns: {
    name: string;
    title: string;
  }[];
  hideColumns?: string[];
  columnsOnSplitMode?: {
    [key: string]: any;
  };
  columnRender?: (col: string) => any;
  photoRender?: any;
}

const ListGridItem: React.FC<IListGridItem> = (props: IListGridItem) => {
  const {
    menuSource,
    primaryKey,
    titleKey,
    titleLink,
    onClickTitleLink = null,
    data,
    isSplitMode,
    onCheckedRow,
    selectedIds,
    columns,
    columnRender,
    hideColumns = [],
    columnsOnSplitMode,
    photoRender,
  } = props;
  const { t, i18n } = useTranslation();

  return (
    <ListGridItemBase
      primaryKey={primaryKey}
      data={data}
      isSplitMode={isSplitMode}
      onCheckedRow={onCheckedRow}
      selectedIds={selectedIds}
      photoRender={photoRender && photoRender(data)}
    >
      <ListGridItemHeader
        id={data?.[primaryKey] ?? generateUUID()}
        title={
          typeof data?.[titleKey] === 'object' ? data?.[titleKey]?.name : data?.[titleKey] ?? ''
        }
        titleLink={titleLink ? `${titleLink}/${data?.[primaryKey] ?? ''}` : ''}
        onClickTitleLink={onClickTitleLink}
        isSplitMode={isSplitMode}
        keyName={titleKey}
        menuSource={menuSource}
      />
      <ListGridItemBody isSplitMode={isSplitMode}>
        {isSplitMode ? (
          <ul className="list-unstyled mg-b-0">
            {columns &&
              columns.map((col: any, i: number) => {
                if (
                  hideColumns?.indexOf(col?.keyName) >= 0 ||
                  col?.keyName === primaryKey ||
                  col?.keyName === titleKey
                ) {
                  return null;
                } else {
                  if (columnsOnSplitMode?.[col?.keyName]) {
                    const columnComponent =
                      isFunction(columnRender) && columnRender(col?.keyName)
                        ? columnRender(col.keyName)(col.keyName, data)
                        : defaultColumnRender(col.keyName, data);
                    return (
                      <li key={col?.keyName} className="d-flex flex-wrap mg-t-5">
                        <div className="d-flex align-items-center">
                          {columnsOnSplitMode?.[col?.keyName]?.title && (
                            <span className="d-flex align-items-center mg-r-5">
                              {t(col?.languageKey) as string}
                              {/* TODO show title from columnsOnSplitMode or column layout */}
                            </span>
                          )}
                          {columnComponent}
                        </div>
                      </li>
                    );
                  } else {
                    return null;
                  }
                }
              })}
          </ul>
        ) : (
          <div className="grid-list-item-detail-body">
            {columns &&
              columns.map((col: any, i: number) => {
                if (
                  hideColumns?.indexOf(col?.keyName) >= 0 ||
                  col?.keyName === primaryKey ||
                  col?.keyName === titleKey
                ) {
                  return null;
                } else {
                  const columnComponent =
                    isFunction(columnRender) && columnRender(col.keyName)
                      ? columnRender(col.keyName)(col.keyName, data)
                      : defaultColumnRender(col.keyName, data);
                  return (
                    <div className="grid-list-item-col" key={i}>
                      <span className="grid-list-item-col-title">
                        {t(col?.languageKey) as string}
                      </span>
                      <span className="grid-list-item-col-content">{columnComponent}</span>
                    </div>
                  );
                }
              })}
          </div>
        )}
      </ListGridItemBody>
    </ListGridItemBase>
  );
};

ListGridItem.defaultProps = {
  primaryKey: 'id',
  titleKey: 'name',
};

export default ListGridItem;
