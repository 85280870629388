import React from 'react';
import classnames from 'classnames';
import styled from '@emotion/styled';
import { Email } from '@customer/customer/components';

const Container = styled.div``;

const Edit: React.FC = (props: any) => {
  const { value = [], onChange = () => { }, componentProps, errors = {} } = props;

  return (
    <Container className={classnames('crm-email-view')}>
      <div
        className={classnames({
          'is-required': errors?.isRequired,
        })}
      >
        <Email {...componentProps} value={value} onChange={onChange} />
      </div>
      {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
    </Container>
  );
};

export default Edit;
