import {
  Settings,
  Users,
  BarChart2,
  CheckSquare,
  Database,
  Search,
  Lock,
  CreditCard,
  Sidebar,
  Layout,
  PhoneCall,
  PhoneMissed,
  PhoneIncoming,
  ShoppingBag,
} from 'react-feather';
import { mainIcons } from '.';

const MenuIcons = {
  'co-manage': (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      {' '}
      <g id="Group_6" data-name="Group 6" transform="translate(-556.656 -264)">
        {' '}
        <g id="Group_3" data-name="Group 3" transform="translate(616.189 264)">
          {' '}
          <rect
            id="Rectangle_1"
            data-name="Rectangle 1"
            width="5.286"
            height="4.076"
            rx="0.548"
            transform="translate(-58.553 3.243)"
            fill="white"
          />{' '}
          <rect
            id="Rectangle_2"
            data-name="Rectangle 2"
            width="5.286"
            height="4.076"
            rx="0.553"
            transform="translate(-58.553 8.685)"
            fill="white"
          />{' '}
          <path
            id="Path_10"
            data-name="Path 10"
            d="M-47.848,19.215a2.306,2.306,0,0,1-2.3,2.3h-3.187a2.306,2.306,0,0,1-2.3-2.3v-6.7a2.3,2.3,0,0,1,1.78-2.241v-.7a2.992,2.992,0,0,0-2.467,2.941v6.7a2.993,2.993,0,0,0,2.99,2.99h3.187a2.994,2.994,0,0,0,2.99-2.99V14.744h-.687Z"
            fill="white"
          />{' '}
          <rect
            id="Rectangle_4"
            data-name="Rectangle 4"
            width="0.687"
            height="17.857"
            transform="translate(-43.443 1.795)"
            fill="white"
          />{' '}
          <rect
            id="Rectangle_5"
            data-name="Rectangle 5"
            width="0.687"
            height="17.857"
            transform="translate(-52.291 1.795)"
            fill="white"
          />{' '}
          <rect
            id="Rectangle_6"
            data-name="Rectangle 6"
            width="5.286"
            height="4.076"
            rx="0.546"
            transform="translate(-41.779 3.243)"
            fill="white"
          />{' '}
          <rect
            id="Rectangle_7"
            data-name="Rectangle 7"
            width="5.286"
            height="4.076"
            rx="0.547"
            transform="translate(-50.166 3.243)"
            fill="white"
          />{' '}
          <rect
            id="Rectangle_8"
            data-name="Rectangle 8"
            width="5.286"
            height="4.076"
            rx="0.552"
            transform="translate(-41.779 8.685)"
            fill="white"
          />{' '}
          <rect
            id="Rectangle_9"
            data-name="Rectangle 9"
            width="5.286"
            height="4.076"
            rx="0.545"
            transform="translate(-41.779 14.128)"
            fill="white"
          />{' '}
          <path
            id="Path_11"
            data-name="Path 11"
            d="M-47.161,13.41v1.1l2.754,1-3.116-7.167-3.116,7.167,2.792-1.016V13.41Z"
            fill="white"
          />{' '}
        </g>{' '}
        <rect
          id="Rectangle_11"
          data-name="Rectangle 11"
          width="24"
          height="24"
          transform="translate(556.656 264)"
          fill="none"
        />{' '}
      </g>{' '}
    </svg>
  ),
  jira: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9.953,5.112l-5.944,5.944l0.71,1.704 M4.008,11.056l-1.622-1.62c-0.596-0.598-0.596-1.565,0-2.162l3.783-3.783c0.597-0.597,1.565-0.597,2.162,0l1.621,1.622 M9.657,18.375l2.3,2.23c0.533,0.547,1.386,0.617,1.998,0.162l6.559-3.33c0.68-0.5,0.823-1.457,0.323-2.137c-0.049-0.066-0.104-0.129-0.078-0.104l-5.844-5.08v0.002l-5.347,1.595c-0.807-0.808-0.783-2.14,0.052-2.975l5.071-3.756l5.356,5.357 M14.691,4.983l1.513-1.513c0.558-0.557,1.446-0.572,1.983-0.034l3.408,3.409c0.538,0.538,0.521,1.425-0.033,1.982l-1.516,1.512 M8.904,20.37c-0.72,0.839-1.982,0.937-2.821,0.216l-2.437-3.033c-0.838-0.721-0.935-1.982-0.215-2.82l0.977-1.14c0.719-0.838,1.981-0.936,2.82-0.215l1.974,3.604c0.654,1.129,0.534,2.289,0.217,2.82L8.904,20.37z" />
    </svg>
  ),
  customer: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9.953,5.112l-5.944,5.944l0.71,1.704 M4.008,11.056l-1.622-1.62c-0.596-0.598-0.596-1.565,0-2.162l3.783-3.783c0.597-0.597,1.565-0.597,2.162,0l1.621,1.622 M9.657,18.375l2.3,2.23c0.533,0.547,1.386,0.617,1.998,0.162l6.559-3.33c0.68-0.5,0.823-1.457,0.323-2.137c-0.049-0.066-0.104-0.129-0.078-0.104l-5.844-5.08v0.002l-5.347,1.595c-0.807-0.808-0.783-2.14,0.052-2.975l5.071-3.756l5.356,5.357 M14.691,4.983l1.513-1.513c0.558-0.557,1.446-0.572,1.983-0.034l3.408,3.409c0.538,0.538,0.521,1.425-0.033,1.982l-1.516,1.512 M8.904,20.37c-0.72,0.839-1.982,0.937-2.821,0.216l-2.437-3.033c-0.838-0.721-0.935-1.982-0.215-2.82l0.977-1.14c0.719-0.838,1.981-0.936,2.82-0.215l1.974,3.604c0.654,1.129,0.534,2.289,0.217,2.82L8.904,20.37z" />
    </svg>
  ),
  statistics: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9,17H4c-1.104,0-2-0.896-2-2V5c0-1.104,0.896-2,2-2h16c1.104,0,2,0.896,2,2v6.021 M16.44,12.016c2.459,0,4.453,1.999,4.453,4.463c0,2.467-1.994,4.467-4.453,4.467s-4.452-2-4.452-4.467C11.988,14.015,13.981,12.016,16.44,12.016z M22,22.052l-2.407-2.407 M6,9.042V13 M9,6.042V13 M12,7.042v3.979 M15.056,9V8.042 M18.042,10V9.042 M1.986,21h9.083" />
    </svg>
  ),
  opportunity: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22.033,17H19.87C19.303,17,19,16.537,19,15.969V8c0-0.569,0.303-1.03,0.87-1.03l2.163,0.002V17z M19,8.5C17.75,6.021,15.396,5,14.568,5H9.542c-0.828,0-1.5,0.672-1.5,1.5l0,0c0,0.828,0.672,1.5,1.5,1.5H13c0,0,1,2,3,2 M6,7L4.505,6.98c-0.828,0-1.5,0.672-1.5,1.5l0,0c0,0.828,0.703,1.5,1.531,1.5L9,10 M4.344,9.98H3.5c-0.828,0-1.5,0.672-1.5,1.5l0,0c0,0.828,0.672,1.5,1.5,1.5L9,13 M4.505,12.98c-0.828,0-1.5,0.672-1.5,1.5l0,0c0,0.828,0.672,1.5,1.5,1.5L9,16 M9,16H6.5C5.672,16,5,16.672,5,17.5l0,0C5,18.328,5.672,19,6.5,19h5.993c0,0,3.512-0.02,6.512-3.02 M11,16V9" />
    </svg>
  ),
  activity: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M22,19h-2.163c-0.567,0-0.87-0.463-0.87-1.031V10c0-0.569,0.303-1.03,0.87-1.03L22,8.972V19z M18.967,10.5
	C17.717,8.021,15.363,7,14.535,7H9.509c-0.828,0-1.5,0.672-1.5,1.5l0,0c0,0.828,0.672,1.5,1.5,1.5h3.458c0,0,1,2,3,2 M4.311,11.98
	H3.467c-0.828,0-1.5,0.672-1.5,1.5l0,0c0,0.828,0.672,1.5,1.5,1.5l1.5,0.004 M4.472,14.98c-0.828,0-1.5,0.672-1.5,1.5l0,0
	c0,0.828,0.672,1.5,1.5,1.5L5.966,18 M6.467,18c-0.828,0-1.5,0.672-1.5,1.5l0,0c0,0.828,0.672,1.5,1.5,1.5h5.993
	c0,0,3.513-0.02,6.513-3.02 M12.208,6.187L11.325,4.19c-0.447-1.01-1.629-1.466-2.639-1.019L4.117,5.196
	C3.107,5.643,2.65,6.825,3.098,7.834L7.57,17.932c0.447,1.01,1.629,1.465,2.639,1.018l4.568-2.023
	c1.011-0.447,1.466-1.629,1.019-2.639l-1.153-2.607"
      />
    </svg>
  ),
  product: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M16.5,9.4l-9-5.19 M21,16V8c0-0.714-0.382-1.373-1-1.73l-7-4c-0.619-0.357-1.381-0.357-2,0l-7,4
	C3.382,6.627,3,7.286,3,8v8c0,0.714,0.382,1.373,1,1.73l7,4c0.619,0.356,1.381,0.356,2,0l7-4C20.618,17.373,21,16.714,21,16z
	 M3.27,6.96L12,12.01l8.73-5.05 M12,22.08V12 M16.5,9.4v3.605"
      />
    </svg>
  ),
  marketing: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m5.152 9.885 3.855 6.676-2.225 1.283a3.854 3.854 0 1 1-3.854-6.675l2.224-1.284zm3.855 6.676 6.848-.988L9.431 4.448 5.152 9.885l3.855 6.676zm.894-13.869a1.285 1.285 0 0 0-.47 1.755l6.424 11.125a1.285 1.285 0 0 0 2.225-1.285L11.656 3.164a1.285 1.285 0 0 0-1.755-.472zM6.782 17.844l2.569 4.451a1.284 1.284 0 0 0 2.224-1.285l-2.569-4.449-2.224 1.283zm8.384-13.74 2.695-3.04-2.695 3.04zM23 9.965l-3.98.814L23 9.965zm-2.568-4.45-3.338 1.926 3.338-1.926z" />
    </svg>
  ),
  helpdesk: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M4.38,7.172C5.337,3.905,8.058,2,12,2c3.975,0,6.707,1.936,7.645,5.253 M10.643,19.869l-1.374-0.223
	c-2.408-0.674-4.09-2.293-4.836-4.646 M6.001,8H3.64C2.735,8,2.001,8.734,2.001,9.639v2.721c0,0.906,0.734,1.64,1.639,1.64h0.722
	c0.905,0,1.639-0.734,1.639-1.64V8z M18.001,12.36c0,0.906,0.733,1.64,1.64,1.64h0.723c0.904,0,1.639-0.734,1.639-1.64V9.639
	C22.001,8.734,21.268,8,20.361,8h-2.36V12.36z M13.501,20.5c0-0.829-0.672-1.5-1.5-1.5l0,0c-0.828,0-1.5,0.671-1.5,1.5l0,0
	c0,0.829,0.671,1.5,1.5,1.5l0,0C12.829,22,13.501,21.329,13.501,20.5L13.501,20.5z"
      />
    </svg>
  ),
  feed: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9,21H4c-1.104,0-2-0.896-2-2V6c0-1.104,0.896-2,2-2h2 M14,4h2c1.104,0,2,0.896,2,2v1 M7,2h6c0.553,0,1,0.448,1,1v2c0,0.552-0.447,1-1,1H7C6.448,6,6,5.552,6,5V3C6,2.448,6.448,2,7,2z M17,9h-3.707C12.579,9,12,9.58,12,10.294v10.412C12,21.42,12.579,22,13.293,22h7.413C21.42,22,22,21.42,22,20.706V14L17,9z M17,9.47V14h4.529" />
    </svg>
  ),
  dashboard: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M11.117,15.722L8.47,10.72 M12,15.428c-0.974,0-1.764,0.787-1.764,1.762c0,0.977,0.79,1.767,1.764,1.767
	c0.974,0,1.766-0.79,1.766-1.767C13.766,16.215,12.974,15.428,12,15.428z M20.662,9.25l-2.04,1.177 M12,6.603V4.25 M5.377,10.427
	L3.34,9.25 M18.113,17.778l2.547,1.472c0.852-1.472,1.34-3.176,1.34-5c0-5.522-4.477-10-10-10c-5.523,0-10,4.478-10,10
	c0,1.824,0.488,3.528,1.342,5l2.545-1.472"
      ></path>
    </svg>
  ),
  sales: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5,18H3.858c0,0-0.837-0.248-1.432-1.096C1.947,16.221,2.025,15,2.025,15H5 M15,5c0,0-0.011-1.01,0.333-1.689C15.802,2.387,16.98,2,16.98,2L17,2.008L5.837,2.027c0,0-0.837,0.249-1.431,1.097C3.927,3.807,4.005,4.64,4.005,4.64H4V12 M11.957,5H7 M8,8H7.042 M18.098,5.187C18.634,4.903,19,4.34,19,3.691C19,2.757,18.244,2,17.31,2 M15.232,11v8.184 M13,17.695h3.161c0.72,0,1.303-0.583,1.303-1.302c0-0.72-0.583-1.302-1.303-1.302h-1.859c-0.72,0-1.302-0.583-1.302-1.303c0-0.719,0.582-1.301,1.302-1.301h2.79 M15,8c-3.866,0-7,3.134-7,7s3.134,7,7,7s7-3.134,7-7S18.866,8,15,8z" />
    </svg>
  ),
  quotes: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M17,1H4C2.896,1,2,1.896,2,3v18c0,1.104,0.896,2,2,2h16c1.104,0,2-0.896,2-2V7L17,1z M17,1v6h5 M12,7v12 M15.486,9.512h-5.229c-0.962,0-1.743,0.78-1.743,1.743s0.781,1.743,1.743,1.743h3.486c0.962,0,1.743,0.781,1.743,1.743 c0,0.963-0.781,1.743-1.743,1.743H8.514" />
    </svg>
  ),
  customization: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M6.083,10H4.689c-1.451,0-2.626,1.273-2.626,2.724v1.313 M7.5,2C8.88,2,10,3.119,10,4.5C10,5.88,8.88,7,7.5,7C6.119,7,5,5.88,5,4.5C5,3.119,6.119,2,7.5,2z M14.625,12.614c1.11,0,2.011,0.9,2.011,2.011s-0.9,2.011-2.011,2.011s-2.011-0.9-2.011-2.011S13.515,12.614,14.625,12.614z M19.587,16.636c-0.184,0.415-0.096,0.898,0.221,1.222l0.04,0.04c0.524,0.522,0.524,1.371,0.001,1.896c0,0,0,0-0.001,0c-0.523,0.525-1.372,0.525-1.896,0.001c0,0,0,0-0.001-0.001l-0.04-0.04c-0.323-0.317-0.807-0.403-1.221-0.222c-0.404,0.174-0.669,0.572-0.67,1.014v0.113c0,0.74-0.6,1.341-1.341,1.341c-0.74,0-1.341-0.601-1.341-1.341v-0.061c-0.01-0.454-0.298-0.855-0.724-1.012c-0.415-0.184-0.898-0.096-1.221,0.221l-0.041,0.04c-0.523,0.524-1.372,0.525-1.896,0.001c0,0,0,0-0.001-0.001c-0.524-0.523-0.524-1.372-0.001-1.896c0,0,0.001,0,0.001-0.001l0.041-0.04c0.316-0.323,0.404-0.807,0.221-1.221c-0.174-0.404-0.571-0.669-1.012-0.67H8.591c-0.741,0-1.341-0.6-1.341-1.341c0-0.74,0.6-1.341,1.341-1.341h0.06c0.455-0.011,0.856-0.298,1.013-0.724c0.183-0.414,0.095-0.897-0.222-1.221l-0.04-0.04C8.878,10.83,8.878,9.981,9.401,9.457c0,0,0,0,0.001-0.001c0.523-0.524,1.372-0.524,1.896-0.001c0,0,0,0.001,0.001,0.001l0.04,0.041c0.323,0.316,0.807,0.404,1.221,0.221h0.054c0.405-0.174,0.669-0.571,0.67-1.012V8.591c0-0.741,0.601-1.341,1.341-1.341s1.341,0.6,1.341,1.341v0.06c0.001,0.441,0.265,0.839,0.67,1.013c0.415,0.183,0.898,0.095,1.222-0.222l0.04-0.04c0.522-0.524,1.371-0.524,1.896-0.001c0,0,0,0,0,0.001c0.525,0.523,0.525,1.372,0.001,1.896c0,0,0,0.001-0.001,0.001l-0.04,0.041c-0.317,0.323-0.403,0.806-0.222,1.221v0.054c0.174,0.405,0.572,0.668,1.013,0.67h0.114c0.74,0,1.341,0.601,1.341,1.341s-0.601,1.341-1.341,1.341h-0.061C20.157,15.967,19.761,16.23,19.587,16.636z" />
    </svg>
  ),
  layout: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-layout"
    >
      <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
      <line x1="3" y1="9" x2="21" y2="9"></line>
      <line x1="9" y1="21" x2="9" y2="9"></line>
    </svg>
  ),
  n_customer: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20,21v-2c0-2.209-1.791-5-4-5l-4,4.016L8,14c-2.209,0-4,2.791-4,5v2" />
      <circle cx="12" cy="7" r="4" />
    </svg>
  ),
  n_activity: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22,19h-2.163c-0.567,0-0.87-0.463-0.87-1.031V10c0-0.569,0.303-1.03,0.87-1.03L22,8.972V19z M18.967,10.5C17.717,8.021,15.363,7,14.535,7H9.509c-0.828,0-1.5,0.672-1.5,1.5l0,0c0,0.828,0.672,1.5,1.5,1.5h3.458c0,0,1,2,3,2 M4.311,11.98H3.467c-0.828,0-1.5,0.672-1.5,1.5l0,0c0,0.828,0.672,1.5,1.5,1.5l1.5,0.005 M4.472,14.98c-0.828,0-1.5,0.672-1.5,1.5l0,0c0,0.828,0.672,1.5,1.5,1.5L5.966,18 M6.467,18c-0.828,0-1.5,0.672-1.5,1.5l0,0c0,0.828,0.672,1.5,1.5,1.5h5.993c0,0,3.512-0.02,6.512-3.02 M12.208,6.187L11.325,4.19c-0.447-1.01-1.629-1.466-2.639-1.019L4.117,5.196C3.107,5.643,2.65,6.825,3.098,7.834l4.472,10.097c0.447,1.01,1.629,1.465,2.639,1.018l4.569-2.023c1.01-0.447,1.465-1.629,1.018-2.639l-1.154-2.607 M6.636,8.421l2.82,6.368 M12.209,13.57l-1.41-3.184" />
    </svg>
  ),
  paid: (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <circle cx="9" cy="21" r="1"></circle>
      <circle cx="20" cy="21" r="1"></circle>
      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
    </svg>
  ),
  selling: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3.979,1H1.021 M3.979,1l3.625,14 M7.604,15h12.375 M23,5.001L20,15 M9,19c-1.104,0-2,0.896-2,2s0.896,2,2,2 s2-0.896,2-2S10.104,19,9,19z M18,19c-1.104,0-2,0.896-2,2s0.896,2,2,2s2-0.896,2-2S19.104,19,18,19z M14,1v11.021 M16.5,3.004 h-3.749C11.783,3.004,11,3.789,11,4.758c0,0.967,0.783,1.753,1.751,1.753h2.499c0.966,0,1.75,0.784,1.75,1.753 s-0.784,1.754-1.75,1.754H11 M23,5h-2" />
    </svg>
  ),
  crmcall: <PhoneCall />,
  crm_call: <PhoneCall />,
  loyalty: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M10.423,10.32l-3.527,0.525l2.552,2.564l-0.602,3.612L12,15.313l3.154,1.709l-0.604-3.612l2.553-2.564 l-3.528-0.525L12,7.031L10.423,10.32z M1,16c1.214-0.912,2-2.364,2-4c0-1.616-0.766-3.052-1.955-3.966L1,8.004V4 c0-1.104,0.896-2,2-2h18c1.104,0,2,0.896,2,2v4.035l-0.002-0.002c-1.189,0.914-1.957,2.351-1.957,3.968 c0,1.617,0.768,3.055,1.958,3.969L23,15.966V20c0,1.104-0.896,2-2,2H3c-1.104,0-2-0.896-2-2v-4.002L1,16z" />
    </svg>
  ),
  order: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        className="st0"
        d="M17,1H4C2.896,1,2,1.896,2,3v18c0,1.104,0.896,2,2,2h16c1.104,0,2-0.896,2-2V7L17,1z M17,1v6h5 M8.108,7H7.05 M8.108,7l1.296,7.031 M9.404,14.031h6.321 M15.73,14.032l1.373-4.063 M14.5,17.021c-0.275,0-0.5,0.225-0.5,0.5 c0,0.274,0.225,0.5,0.5,0.5s0.5-0.226,0.5-0.5C15,17.246,14.775,17.021,14.5,17.021z M10.5,17.021c-0.275,0-0.5,0.225-0.5,0.5 c0,0.274,0.225,0.5,0.5,0.5s0.5-0.226,0.5-0.5C11,17.246,10.775,17.021,10.5,17.021z M17.104,9.969H12"
      />
    </svg>
  ),
  submenu: {
    activities: {
      todo: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          {' '}
          <path d="M16,4h2c1.104,0,2,0.896,2,2v14c0,1.104-0.896,2-2,2H6c-1.104,0-2-0.896-2-2V6c0-1.104,0.896-2,2-2h2 M9,2h6 c0.553,0,1,0.448,1,1v2c0,0.552-0.447,1-1,1H9C8.448,6,8,5.552,8,5V3C8,2.448,8.448,2,9,2z M16.996,10l-6.919,6.994l-3.054-3.055" />{' '}
        </svg>
      ),
    },
  },
  einvoice: (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
      <title>iconEinvoice</title>
      <rect x="0.94" y="1.23" width="24" height="24" rx="4.05" fill="#62b746" />
      <path
        d="M18.24,5.77H7.65a.55.55,0,0,0-.55.54V20.16a.55.55,0,0,0,.55.54H18.24a.54.54,0,0,0,.54-.54V6.31A.54.54,0,0,0,18.24,5.77Zm-.47,13.86H14.52V17.74a.25.25,0,0,0-.25-.24H11.61a.24.24,0,0,0-.24.24v1.89H8.12V6.83h9.65Z"
        fill="#fff"
      />
      <rect x="9.24" y="16.43" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="9.24" y="14.3" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="9.24" y="12.17" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="9.24" y="10.03" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="9.24" y="7.9" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="15.58" y="16.43" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="15.58" y="14.3" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="15.58" y="12.17" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="15.58" y="10.03" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="15.58" y="7.9" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="13.45" y="14.3" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="13.45" y="12.17" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="13.45" y="10.03" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="13.45" y="7.9" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="11.32" y="14.3" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="11.32" y="12.17" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="11.32" y="10.03" width="1.07" height="1.07" rx="0.25" fill="#fff" />
      <rect x="11.32" y="7.9" width="1.07" height="1.07" rx="0.25" fill="#fff" />
    </svg>
  ), // TODO: use approriate icon
  folder: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-folder"
    >
      <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
    </svg>
  ),
  scrum_project: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.261"
      height="25.823"
      viewBox="0 0 27.261 25.823"
    >
      {' '}
      <g id="Group_108" data-name="Group 108" transform="translate(-3.201 -4.126)">
        {' '}
        <line
          id="Line_64"
          data-name="Line 64"
          x2="24.978"
          transform="translate(4.201 25.013)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />{' '}
        <path
          id="Path_35"
          data-name="Path 35"
          d="M25.94,21.492l3.522,3.522L25.94,28.535"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />{' '}
        <path
          id="Path_36"
          data-name="Path 36"
          d="M8.448,22.573,7.825,21.9A10,10,0,1,1,18.378,24.6"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />{' '}
      </g>{' '}
    </svg>
  ),
  kaban_project: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      {' '}
      <g id="Group_109" data-name="Group 109" transform="translate(-5.402 -41.29)">
        {' '}
        <rect
          id="Rectangle_92"
          data-name="Rectangle 92"
          width="20"
          height="20"
          rx="2.18"
          transform="translate(6.402 42.289)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        ></rect>{' '}
        <line
          id="Line_65"
          data-name="Line 65"
          x1="19.1"
          transform="translate(7 48)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        ></line>{' '}
        <line
          id="Line_66"
          data-name="Line 66"
          y2="20"
          transform="translate(19.5 42.289)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        ></line>{' '}
        <line
          id="Line_67"
          data-name="Line 67"
          y2="20"
          transform="translate(13 42.289)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        ></line>{' '}
      </g>{' '}
    </svg>
  ),
  settings: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-settings"
    >
      <circle cx="12" cy="12" r="3"></circle>
      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
    </svg>
  ),
  pie_chart: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-pie-chart"
    >
      <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
      <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
    </svg>
  ),
  chevron_right: (
    <svg
      style={{ fill: 'transparent' }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-right"
    >
      <polyline points="9 18 15 12 9 6"></polyline>
    </svg>
  ),
  co_report: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      {' '}
      <g id="Group_5" data-name="Group 5" transform="translate(-527 -264)">
        {' '}
        <g id="Group_4" data-name="Group 4" transform="translate(616.189 264)">
          {' '}
          <path
            id="Path_12"
            data-name="Path 12"
            d="M-80.646,5.136c.141.131.268.242.388.362,1.848,1.846,3.7,3.689,5.537,5.547.236.238.38.226.621.021,1.148-.976,2.311-1.935,3.47-2.9.093-.077.195-.142.34-.247.232.8.45,1.548.664,2.294q.879,3.065,1.755,6.13c.086.3.168.6.257.894.059.195,0,.286-.21.276-.069,0-.138,0-.207,0H-84.922c-.528,0-.528,0-.528-.529,0-1.785-.006-3.57.008-5.354a.993.993,0,0,1,.179-.565c1.451-1.888,2.917-3.765,4.379-5.645C-80.814,5.333-80.739,5.248-80.646,5.136Z"
            fill="#566476"
          />{' '}
          <path
            id="Path_13"
            data-name="Path 13"
            d="M-88.219,3.738c.392,0,.726.011,1.059,0,.194-.009.281.049.266.248-.007.1,0,.207,0,.311V18.387c0,.526,0,.526.514.526h20.22c0,.408.007.794-.008,1.18,0,.057-.1.134-.173.158a.858.858,0,0,1-.274.011q-10.571,0-21.141,0c-.462,0-.462,0-.462-.472V3.738Z"
            fill="#566476"
          />{' '}
        </g>{' '}
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="24"
          height="24"
          transform="translate(527 264)"
          fill="none"
        />{' '}
      </g>{' '}
    </svg>
  ),
  co_worklist: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      {' '}
      <g id="Group_7" data-name="Group 7" transform="translate(-585.361 -264)">
        {' '}
        <g id="Group_2" data-name="Group 2" transform="translate(616.189 264)">
          {' '}
          <path
            id="Path_5"
            data-name="Path 5"
            d="M-29.876,19.515h8.533l6.725-6.928c-1.758-.528-2.59-.8-2.59-.8V10.337C-15.357,8.5-14.2.97-19.637.97c-4.374,0-4.374,6.93-2.009,9.367v1.447a19.663,19.663,0,0,0-8.23,4.571Z"
            fill="#566476"
          />{' '}
          <path id="Path_6" data-name="Path 6" d="M-12.81,19.515h3.84V15.679Z" fill="#566476" />{' '}
          <path
            id="Path_7"
            data-name="Path 7"
            d="M-19.634,19.287l-.727,3.743,4-.578Z"
            fill="#566476"
          />{' '}
          <path
            id="Path_8"
            data-name="Path 8"
            d="M-17.695,18.947l-.563-.545,4.735-4.868.564.545Zm4.507-6.353-6.056,6.226,3.331,3.222,6.056-6.226Z"
            fill="#566476"
          />{' '}
          <path
            id="Path_9"
            data-name="Path 9"
            d="M-7.872,13.157l-2.709-2.621c-.168-.161-.506-.084-.756.172l-1,1.017,0-.005-.525.54,3.331,3.222.525-.539-.011-.011,1-1.016c.25-.257.317-.6.149-.759"
            fill="#566476"
          />{' '}
        </g>{' '}
        <rect
          id="Rectangle_12"
          data-name="Rectangle 12"
          width="24"
          height="24"
          transform="translate(585.361 264)"
          fill="none"
        />{' '}
      </g>{' '}
    </svg>
  ),
  co_admin: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      {' '}
      <g id="Group_8" data-name="Group 8" transform="translate(-616.189 -264)">
        {' '}
        <g id="Group_1" data-name="Group 1" transform="translate(616.189 264)">
          {' '}
          <path
            id="Path_2"
            data-name="Path 2"
            d="M5.76,16.987c-.088.056-.184.109-.27.173-.355.266-.711.531-1.06.805a.381.381,0,0,1-.562-.015c-.469-.469-.93-.948-1.395-1.422a.487.487,0,0,1,0-.749,8.339,8.339,0,0,0,.7-.908.788.788,0,0,0,.062-.489c-.089-.638-.531-.837-1.111-.868-.269-.015-.536-.071-.8-.109a.393.393,0,0,1-.392-.44c.009-.642.006-1.283,0-1.924,0-.26.09-.41.374-.446.452-.056.9-.141,1.348-.219a.865.865,0,0,0,.483-1.259q-.349-.454-.718-.893a.473.473,0,0,1,.007-.719c.461-.46.923-.92,1.392-1.374a.464.464,0,0,1,.693-.007c.2.167.427.3.606.484A.916.916,0,0,0,6.38,6.79c.167-.1.263-.169.287-.368.056-.464.134-.925.21-1.386a.451.451,0,0,1,.515-.414c.6.009,1.209,0,1.813,0a.46.46,0,0,1,.526.479c.046.452.126.9.209,1.35a.929.929,0,0,0,1.131.445c.35-.252.693-.514,1.033-.779a.468.468,0,0,1,.715.038c.418.435.848.858,1.27,1.29a.507.507,0,0,1,.042.8c-.243.26-.433.568-.664.841a.479.479,0,0,0-.076.634.37.37,0,0,1,.056.136c.084.486.422.6.854.619a4.669,4.669,0,0,1,1.02.156.54.54,0,0,1,.34.355c.034.689.035,1.381,0,2.071a.517.517,0,0,1-.326.356,10.316,10.316,0,0,1-1.345.226.606.606,0,0,0-.489.361.9.9,0,0,0,.2,1.233c.185.2.34.422.513.631a.387.387,0,0,1,.017.565c-.477.5-.96.987-1.451,1.468a.462.462,0,0,1-.717,0c-.188-.176-.427-.3-.606-.484a.865.865,0,0,0-1.192-.2c-.186.11-.31.185-.335.418-.049.453-.139.9-.206,1.351a.436.436,0,0,1-.5.426q-.924-.015-1.849,0a.435.435,0,0,1-.5-.422c-.055-.389-.139-.776-.174-1.167-.031-.331-.141-.554-.492-.63A2.555,2.555,0,0,1,5.76,16.987ZM8.329,9.044A2.944,2.944,0,0,0,5.323,11.99,2.983,2.983,0,0,0,8.238,15a3.028,3.028,0,0,0,3.056-2.954A2.972,2.972,0,0,0,8.329,9.044Z"
            fill="#566476"
          />{' '}
          <path
            id="Path_3"
            data-name="Path 3"
            d="M15.708,9.351c.17-.42.316-.785.466-1.148a.691.691,0,0,0-.56-.992c-.353-.052-.7-.121-1.055-.172a.311.311,0,0,1-.306-.372c.013-.37.018-.74,0-1.109a.358.358,0,0,1,.356-.419c.341-.054.681-.111,1.023-.15a.7.7,0,0,0,.568-.936c-.14-.342-.272-.686-.41-1.029a.328.328,0,0,1,.186-.478,7.956,7.956,0,0,0,.894-.518.4.4,0,0,1,.626.116c.207.275.427.541.641.811a.687.687,0,0,0,1.027.024c.256-.3.5-.605.75-.914a.307.307,0,0,1,.45-.083c.351.205.717.387,1.051.616a.442.442,0,0,1,.118.39c-.111.377-.276.737-.411,1.106a.7.7,0,0,0,.587.9c.342.037.682.1,1.023.149a.339.339,0,0,1,.341.39c-.012.382-.008.764,0,1.146a.3.3,0,0,1-.285.354c-.364.057-.726.13-1.091.182a.691.691,0,0,0-.549.973c.136.329.247.669.389,1,.1.237.015.364-.19.474a9.457,9.457,0,0,0-.927.536c-.245.169-.4.1-.558-.106-.219-.281-.45-.555-.674-.833a.73.73,0,0,0-1.069.013c-.225.292-.461.577-.686.869a.308.308,0,0,1-.472.091c-.333-.21-.683-.392-1.016-.6A1.217,1.217,0,0,1,15.708,9.351Zm2.921-1.756a1.515,1.515,0,0,0,1.545-1.466,1.579,1.579,0,0,0-1.48-1.551,1.606,1.606,0,0,0-1.553,1.487A1.532,1.532,0,0,0,18.629,7.595Z"
            fill="#566476"
          />{' '}
          <path
            id="Path_4"
            data-name="Path 4"
            d="M18.675,20.744a4.637,4.637,0,0,0-.609.443c-.239.247-.426.544-.669.787a.444.444,0,0,1-.4.089,7.18,7.18,0,0,1-1.088-.622.488.488,0,0,1-.106-.434,10.1,10.1,0,0,1,.4-1.031.718.718,0,0,0-.545-.934c-.342-.045-.682-.1-1.024-.143-.257-.033-.4-.141-.383-.43.02-.357.015-.716,0-1.073a.352.352,0,0,1,.36-.413c.354-.044.707-.1,1.059-.153a.708.708,0,0,0,.519-.944c-.144-.34-.279-.684-.41-1.029a.325.325,0,0,1,.172-.459c.337-.177.667-.372.993-.57a.3.3,0,0,1,.45.072c.226.277.461.545.685.824a.717.717,0,0,0,1.162-.005c.21-.273.449-.523.66-.8a.332.332,0,0,1,.507-.076c.327.2.674.363.987.579a.433.433,0,0,1,.134.38c-.109.377-.266.74-.411,1.106a.71.71,0,0,0,.522.916c.365.054.73.109,1.1.159a.34.34,0,0,1,.334.4c-.01.37-.012.741,0,1.11a.339.339,0,0,1-.334.4c-.328.048-.655.107-.985.142a.7.7,0,0,0-.607,1,9.56,9.56,0,0,1,.394,1.032.436.436,0,0,1-.142.378,7.773,7.773,0,0,1-1.051.614.445.445,0,0,1-.406-.071c-.253-.25-.451-.554-.7-.812A4.526,4.526,0,0,0,18.675,20.744Zm-.01-1.313a1.5,1.5,0,0,0,1.507-1.493,1.515,1.515,0,1,0-1.507,1.493Z"
            fill="#566476"
          />{' '}
        </g>{' '}
        <rect
          id="Rectangle_13"
          data-name="Rectangle 13"
          width="24"
          height="24"
          transform="translate(616.189 264)"
          fill="none"
        />{' '}
      </g>{' '}
    </svg>
  ),
  co_dashboard: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      {' '}
      <g id="Group_9" data-name="Group 9" transform="translate(-649.083 -264)">
        {' '}
        <path
          id="Path_1"
          data-name="Path 1"
          d="M44.815,20.657c-2.775,0-5.549-.01-8.323.009A1.2,1.2,0,0,1,35.318,20a10.634,10.634,0,0,1-1.493-6.46,10.772,10.772,0,0,1,5.546-8.746,10.2,10.2,0,0,1,5.572-1.449,10.791,10.791,0,0,1,9.288,5.324,10.714,10.714,0,0,1,1.577,6.515,11.052,11.052,0,0,1-1.624,5.01,1,1,0,0,1-.967.469Q49.016,20.651,44.815,20.657Zm0-1.524a3.506,3.506,0,0,0,.583-.071,2.382,2.382,0,0,0,1.006-4.11.434.434,0,0,1-.147-.507c.383-1.391.763-2.785,1.1-4.187a1.441,1.441,0,0,0-.1-.859.669.669,0,0,0-.781-.322c-.43.071-.595.387-.694.763-.365,1.383-.74,2.764-1.088,4.151a.51.51,0,0,1-.429.432,2.355,2.355,0,0,0-1.842,2.532A2.383,2.383,0,0,0,44.811,19.133ZM39.3,10.421a1.55,1.55,0,0,0,1.588-1.553,1.6,1.6,0,1,0-3.2-.018A1.555,1.555,0,0,0,39.3,10.421Zm11.044,0a1.553,1.553,0,0,0,1.594-1.547,1.6,1.6,0,1,0-3.2-.03A1.557,1.557,0,0,0,50.34,10.421ZM44.826,8.066A1.593,1.593,0,1,0,43.212,6.49,1.568,1.568,0,0,0,44.826,8.066ZM36.941,12.78a1.591,1.591,0,0,0-1.632,1.606,1.616,1.616,0,1,0,1.632-1.606Zm15.764,0a1.593,1.593,0,1,0,1.608,1.592A1.591,1.591,0,0,0,52.705,12.78Z"
          transform="translate(616.189 264)"
          fill="#566476"
        />{' '}
        <rect
          id="Rectangle_14"
          data-name="Rectangle 14"
          width="24"
          height="24"
          transform="translate(649.083 264)"
          fill="none"
        />{' '}
      </g>{' '}
    </svg>
  ),
  co_manage_gray: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      {' '}
      <g id="Group_6" data-name="Group 6" transform="translate(-556.656 -264)">
        {' '}
        <g id="Group_3" data-name="Group 3" transform="translate(616.189 264)">
          {' '}
          <rect
            id="Rectangle_1"
            data-name="Rectangle 1"
            width="5.286"
            height="4.076"
            rx="0.548"
            transform="translate(-58.553 3.243)"
            fill="#566476"
          />{' '}
          <rect
            id="Rectangle_2"
            data-name="Rectangle 2"
            width="5.286"
            height="4.076"
            rx="0.553"
            transform="translate(-58.553 8.685)"
            fill="#566476"
          />{' '}
          <path
            id="Path_10"
            data-name="Path 10"
            d="M-47.848,19.215a2.306,2.306,0,0,1-2.3,2.3h-3.187a2.306,2.306,0,0,1-2.3-2.3v-6.7a2.3,2.3,0,0,1,1.78-2.241v-.7a2.992,2.992,0,0,0-2.467,2.941v6.7a2.993,2.993,0,0,0,2.99,2.99h3.187a2.994,2.994,0,0,0,2.99-2.99V14.744h-.687Z"
            fill="#566476"
          />{' '}
          <rect
            id="Rectangle_4"
            data-name="Rectangle 4"
            width="0.687"
            height="17.857"
            transform="translate(-43.443 1.795)"
            fill="#566476"
          />{' '}
          <rect
            id="Rectangle_5"
            data-name="Rectangle 5"
            width="0.687"
            height="17.857"
            transform="translate(-52.291 1.795)"
            fill="#566476"
          />{' '}
          <rect
            id="Rectangle_6"
            data-name="Rectangle 6"
            width="5.286"
            height="4.076"
            rx="0.546"
            transform="translate(-41.779 3.243)"
            fill="#566476"
          />{' '}
          <rect
            id="Rectangle_7"
            data-name="Rectangle 7"
            width="5.286"
            height="4.076"
            rx="0.547"
            transform="translate(-50.166 3.243)"
            fill="#566476"
          />{' '}
          <rect
            id="Rectangle_8"
            data-name="Rectangle 8"
            width="5.286"
            height="4.076"
            rx="0.552"
            transform="translate(-41.779 8.685)"
            fill="#566476"
          />{' '}
          <rect
            id="Rectangle_9"
            data-name="Rectangle 9"
            width="5.286"
            height="4.076"
            rx="0.545"
            transform="translate(-41.779 14.128)"
            fill="#566476"
          />{' '}
          <path
            id="Path_11"
            data-name="Path 11"
            d="M-47.161,13.41v1.1l2.754,1-3.116-7.167-3.116,7.167,2.792-1.016V13.41Z"
            fill="#566476"
          />{' '}
        </g>{' '}
        <rect
          id="Rectangle_11"
          data-name="Rectangle 11"
          width="24"
          height="24"
          transform="translate(556.656 264)"
          fill="none"
        />{' '}
      </g>{' '}
    </svg>
  ),
  purchase: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M7.313,11.393l6.666-10.394l9.015,6.003l-9.027,14.165l-1.654-1 M3.507,22.568l5.145-3.006l4.456-3.953
	c0.451-0.399,0.328-1.604-0.021-2.098l0,0c-0.381-0.533-1.124-0.658-1.656-0.275l-3.843,1.839L7.055,8.938l1.26-0.703 M10.871,3.722
	L5.53,5.991c-0.426,0.18-0.785,0.485-1.031,0.877L2.565,17.88l-0.552,0.369 M2.787,16.621l-1.811,1.996L3.698,23l2.075-1.406
	L2.787,16.621z M17.779,3.53l-6.348,9.707"
      />
    </svg>
  ),
  shipment: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14,7.631h4.818c0.346,0,0.668,0.17,0.863,0.456l1.775,3.197h0.521c0.588,0,1.043,0.388,1.043,1.154v3.875 c0,0.576-0.467,1.043-1.043,1.043h-1.043 M7.37,18H13V4H1v14h1.196 M18.844,15.855c-1.156,0-2.092,0.932-2.092,2.082 s0.936,2.083,2.092,2.083c1.154,0,2.092-0.933,2.092-2.083S19.998,15.855,18.844,15.855z M5,15.848 c-1.155,0-2.091,0.932-2.091,2.082S3.845,20.013,5,20.013c1.155,0,2.092-0.933,2.092-2.083S6.155,15.848,5,15.848z M13,18h3.762" />
    </svg>
  ),
  production: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M17.016,18h1 M11.5,18h1 M5.984,18h1 M23,18c0-1.657-1.343-3-3-3H4c-1.657,0-3,1.343-3,3l0,0 c0,1.657,1.343,3,3,3h16C21.657,21,23,19.657,23,18L23,18z M1.105,17.215L3,11 M22.637,16.567L20,11 M17.021,12V5.05L16,3h-4.5H7 L6.021,5.05V12h5.5H17.021z M6.332,6H17" />
    </svg>
  ),
  price_list: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5,2h13 M8.631,9H5 M7.001,16H5 M1,2h0.01 M1,9h0.01 M1,16h0.01 M15,8.984v10.031 M18,10.984h-4.5 c-0.828,0-1.5,0.672-1.5,1.5s0.672,1.5,1.5,1.5h3c0.828,0,1.5,0.672,1.5,1.5s-0.672,1.5-1.5,1.5H12 M14.999,6 c-4.417,0-7.998,3.582-7.998,8c0,4.418,3.582,8,7.998,8c4.418,0,8-3.582,8-8C22.999,9.582,19.417,6,14.999,6z" />
    </svg>
  ),
  invoice: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M17.031,20L12,23l-4.958-3L2,23V1h20v22L17.031,20z M12,17.041v-12 M8.729,14.857h4.638
	c1.055,0,1.908-0.854,1.908-1.908c0-1.055-0.854-1.908-1.908-1.908h-2.729c-1.056,0-1.91-0.854-1.91-1.908
	c0-1.054,0.854-1.909,1.91-1.909h4.088"
      />
    </svg>
  ),

  //Settings
  organization: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        className="st0"
        d="M4,16.586V12h16v4.586 M12,16.586V7 M3.7,16.586c-1.492,0-2.7,1.209-2.7,2.7c0,1.492,1.208,2.703,2.7,2.703 c1.493,0,2.702-1.211,2.702-2.703C6.402,17.795,5.193,16.586,3.7,16.586z M11.97,16.586c-1.492,0-2.701,1.209-2.701,2.7 c0,1.492,1.209,2.703,2.701,2.703c1.493,0,2.702-1.211,2.702-2.703C14.672,17.795,13.463,16.586,11.97,16.586z M20.299,16.586 c-1.492,0-2.701,1.209-2.701,2.7c0,1.492,1.209,2.703,2.701,2.703c1.491,0,2.701-1.211,2.701-2.703 C23,17.795,21.79,16.586,20.299,16.586z M16,2H8v5h8V2z"
      />
    </svg>
  ),
  general_settings: <Settings />,
  templates: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M13,2H6C4.896,2,4,2.896,4,4v16c0,1.104,0.896,2,2,2h12c1.104,0,2-0.896,2-2V9L13,2z M13,2v7h7 M7,6h2 M7,10h3 M17,18.016H7V14h10V18.016z" />
    </svg>
  ),
  manage_process: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20,10h3.001V5 M23.001,9.799l-5.104-4.795c-1.045-1.044-2.311-1.84-3.704-2.331 C9.036,0.855,3.381,3.563,1.565,8.72s0.891,10.81,6.048,12.627c5.157,1.816,10.81-0.892,12.627-6.048 M11.084,10.272 c0.954,0,1.728,0.773,1.728,1.728s-0.774,1.728-1.728,1.728S9.357,12.954,9.357,12S10.13,10.272,11.084,10.272z M15.346,13.728 c-0.158,0.355-0.082,0.771,0.189,1.048l0.035,0.034c0.449,0.449,0.451,1.179,0,1.629c0,0,0,0,0,0.001 c-0.449,0.45-1.18,0.45-1.629,0.001c0,0,0,0,0-0.001l-0.035-0.034c-0.277-0.272-0.693-0.347-1.049-0.19 c-0.348,0.149-0.574,0.49-0.576,0.87v0.098c0,0.636-0.516,1.151-1.151,1.151s-1.151-0.516-1.151-1.151v-0.052 c-0.009-0.391-0.256-0.735-0.622-0.869c-0.355-0.158-0.771-0.083-1.048,0.189l-0.035,0.034c-0.449,0.45-1.179,0.451-1.628,0.001 c0,0-0.001,0-0.001-0.001c-0.45-0.449-0.451-1.179-0.001-1.629c0,0,0,0,0.001,0l0.035-0.035c0.271-0.277,0.347-0.692,0.19-1.048 C6.72,13.426,6.379,13.199,6,13.197H5.902c-0.636,0-1.152-0.516-1.152-1.151s0.516-1.151,1.152-1.151h0.051 c0.391-0.009,0.735-0.256,0.87-0.622c0.157-0.355,0.081-0.771-0.19-1.048L6.599,9.19c-0.45-0.449-0.45-1.179,0-1.628 c0,0,0-0.001,0-0.001C7.048,7.11,7.777,7.11,8.228,7.56c0,0,0,0,0.001,0.001l0.035,0.035c0.277,0.271,0.692,0.347,1.048,0.19h0.046 c0.349-0.149,0.575-0.491,0.576-0.87V6.818c0-0.636,0.516-1.152,1.151-1.152s1.151,0.516,1.151,1.152v0.051 c0.001,0.379,0.228,0.721,0.577,0.87c0.355,0.157,0.77,0.082,1.047-0.19l0.035-0.035c0.449-0.45,1.178-0.45,1.629,0v0 c0.451,0.45,0.451,1.179,0.002,1.629c0,0,0,0-0.002,0.001L15.49,9.179c-0.273,0.277-0.348,0.692-0.191,1.048v0.046 c0.15,0.348,0.49,0.575,0.871,0.576h0.098c0.635,0,1.15,0.516,1.15,1.152s-0.516,1.151-1.15,1.151h-0.053 C15.836,13.152,15.494,13.379,15.346,13.728z" />
    </svg>
  ),
  billing: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1,19h3 M2,13h3 M3,7h2 M8,18V6c0-1.656,1.337-3,2.994-3H12v12.007L11,15C9.343,15,8,16.344,8,18s1.343,3,3,3 v0.007h12v-14H12 M14.969,17.031h3.563c0-0.828,0.672-2.5,1.5-2.5V13.5c-0.828,0-1.5-1.672-1.5-2.5h-3.563" />
    </svg>
  ),
  license: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18.986,8.042l-7-7h-7c-1.104,0-2,0.896-2,2v16c0,1.105,0.896,2,2,2h6 M11.986,1.042v7h7 M17.514,12 c1.933,0,3.5,1.568,3.5,3.501c0,1.934-1.567,3.501-3.5,3.501c-1.934,0-3.5-1.567-3.5-3.501C14.014,13.568,15.58,12,17.514,12z M15.619,18.445l-0.605,4.557l2.5-1.499l2.5,1.499l-0.605-4.561" />
    </svg>
  ),
  //temp -> thuc
  customer_employees: <Users />,
  menu_settings: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M2,9h4 M2,3h18 M2,15h2 M15,12.09c1.054,0,1.91,0.856,1.91,1.91s-0.856,1.91-1.91,1.91s-1.91-0.856-1.91-1.91S13.946,12.09,15,12.09z M19.709,15.91 c-0.173,0.392-0.09,0.851,0.21,1.157l0.038,0.038c0.498,0.496,0.498,1.303,0.001,1.801c0,0,0,0-0.001,0 c-0.496,0.498-1.302,0.498-1.8,0.001c0,0,0,0-0.001-0.001l-0.038-0.038c-0.307-0.301-0.766-0.383-1.158-0.21 c-0.385,0.165-0.635,0.542-0.637,0.961v0.108c0,0.702-0.569,1.272-1.272,1.272s-1.272-0.57-1.272-1.272v-0.057 c-0.01-0.432-0.282-0.813-0.688-0.962c-0.392-0.173-0.851-0.09-1.156,0.21l-0.039,0.038c-0.496,0.498-1.303,0.498-1.8,0.001 c0,0-0.001,0-0.001-0.001c-0.497-0.496-0.498-1.302-0.001-1.8c0,0,0,0,0.001-0.001l0.038-0.038c0.3-0.307,0.383-0.766,0.21-1.158 c-0.166-0.385-0.543-0.635-0.961-0.637H9.272C8.57,15.323,8,14.754,8,14.051s0.57-1.272,1.272-1.272H9.33 c0.431-0.01,0.812-0.282,0.961-0.688c0.174-0.392,0.09-0.851-0.21-1.156l-0.038-0.039c-0.497-0.496-0.498-1.303,0-1.8 c0,0,0-0.001,0-0.001c0.498-0.497,1.303-0.498,1.8-0.001c0,0,0.001,0,0.001,0.001l0.038,0.038c0.306,0.3,0.766,0.384,1.158,0.21 h0.05c0.385-0.166,0.636-0.543,0.638-0.961V8.272C13.728,7.57,14.298,7,15,7s1.272,0.57,1.272,1.272V8.33 c0.001,0.418,0.253,0.796,0.638,0.961c0.392,0.174,0.851,0.09,1.157-0.21l0.038-0.038c0.496-0.497,1.303-0.498,1.801,0v0 c0.498,0.498,0.498,1.303,0.001,1.8c0,0,0,0.001-0.001,0.001l-0.038,0.038c-0.301,0.307-0.383,0.766-0.21,1.158v0.05 c0.165,0.385,0.542,0.636,0.961,0.638h0.108C21.43,12.728,22,13.298,22,14s-0.57,1.272-1.272,1.272h-0.057 C20.251,15.273,19.873,15.525,19.709,15.91z" />
    </svg>
  ),
};

const LeftSideIcons = {
  'm_co-manage': (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9.953,5.112l-5.944,5.944l0.71,1.704 M4.008,11.056l-1.622-1.62c-0.596-0.598-0.596-1.565,0-2.162l3.783-3.783c0.597-0.597,1.565-0.597,2.162,0l1.621,1.622 M9.657,18.375l2.3,2.23c0.533,0.547,1.386,0.617,1.998,0.162l6.559-3.33c0.68-0.5,0.823-1.457,0.323-2.137c-0.049-0.066-0.104-0.129-0.078-0.104l-5.844-5.08v0.002l-5.347,1.595c-0.807-0.808-0.783-2.14,0.052-2.975l5.071-3.756l5.356,5.357 M14.691,4.983l1.513-1.513c0.558-0.557,1.446-0.572,1.983-0.034l3.408,3.409c0.538,0.538,0.521,1.425-0.033,1.982l-1.516,1.512 M8.904,20.37c-0.72,0.839-1.982,0.937-2.821,0.216l-2.437-3.033c-0.838-0.721-0.935-1.982-0.215-2.82l0.977-1.14c0.719-0.838,1.981-0.936,2.82-0.215l1.974,3.604c0.654,1.129,0.534,2.289,0.217,2.82L8.904,20.37z" />
    </svg>
  ),
  m_jira: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9.953,5.112l-5.944,5.944l0.71,1.704 M4.008,11.056l-1.622-1.62c-0.596-0.598-0.596-1.565,0-2.162l3.783-3.783c0.597-0.597,1.565-0.597,2.162,0l1.621,1.622 M9.657,18.375l2.3,2.23c0.533,0.547,1.386,0.617,1.998,0.162l6.559-3.33c0.68-0.5,0.823-1.457,0.323-2.137c-0.049-0.066-0.104-0.129-0.078-0.104l-5.844-5.08v0.002l-5.347,1.595c-0.807-0.808-0.783-2.14,0.052-2.975l5.071-3.756l5.356,5.357 M14.691,4.983l1.513-1.513c0.558-0.557,1.446-0.572,1.983-0.034l3.408,3.409c0.538,0.538,0.521,1.425-0.033,1.982l-1.516,1.512 M8.904,20.37c-0.72,0.839-1.982,0.937-2.821,0.216l-2.437-3.033c-0.838-0.721-0.935-1.982-0.215-2.82l0.977-1.14c0.719-0.838,1.981-0.936,2.82-0.215l1.974,3.604c0.654,1.129,0.534,2.289,0.217,2.82L8.904,20.37z" />
    </svg>
  ),
  m_customer: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20,21v-2c0-2.209-1.791-5-4-5l-4,4.016L8,14c-2.209,0-4,2.791-4,5v2" />
      <circle cx="12" cy="7" r="4" />
    </svg>
  ),
  m_customer_account: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5.163,2h7.673C14.029,2,15,2.969,15,4.163v15.675C15,21.03,14.029,22,12.836,22H5.163C3.969,22,3,21.03,3,19.838V4.163C3,2.969,3.969,2,5.163,2z M16,11h3c1.104,0,2,0.896,2,2v7c0,1.104-0.896,2-2,2h-8 M7,7H6 M12,7l-1,0 M18,14v1 M18,18v1 M7,12H6 M11,12l1,0 M7,17H6 M11,17h1" />
    </svg>
  ),
  m_lead: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M6,14.992v-0.709c0-0.782,0.634-1.275,1.417-1.275h6.166c0.782,0,1.417,0.493,1.417,1.275v0.709 M8.004,7.501C8.004,8.882,9.122,10,10.503,10c1.379,0,2.499-1.118,2.499-2.499c0-1.379-1.12-2.499-2.499-2.499C9.122,5.002,8.004,6.122,8.004,7.501z M19,4c0-1.104-0.896-2-2-2H4C2.896,2,2,2.896,2,4v13c0,1.104,0.896,2,2,2h13c1.104,0,2-0.896,2-2V4z M7,22h13c1.104,0,2-0.896,2-2V7" />
    </svg>
  ),
  m_potential: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21,12c0,4.971-4.029,9-9,9c-4.971,0-9-4.029-9-9c0-4.971,4.029-9,9-9C16.971,3,21,7.029,21,12z M12,2v3.021 M12,19v3 M2,11.986L5,12 M19,12l3-0.014 M8,17v-0.709c0-0.782,0.634-1.275,1.417-1.275h5.166c0.782,0,1.417,0.493,1.417,1.275V17 M10,10c0,1.105,0.895,2,2,2c1.104,0,2-0.895,2-2c0-1.104-0.896-2-2-2C10.895,8,10,8.896,10,10z" />
    </svg>
  ),
  m_customer_rating: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12.03,2.026l2.44,4.943l5.457,0.797l-3.948,3.847l0.932,5.431l-4.88-2.566l-4.88,2.566l0.932-5.431L4.133,7.767L9.59,6.97L12.03,2.026z M19.994,21.994c0-1.104-0.895-2-2-2H6.026c-1.104,0-2,0.896-2,2" />
    </svg>
  ),
  bell: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-bell"
    >
      <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
    </svg>
  ),
  calendar: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-calendar"
    >
      <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
      <line x1="16" y1="2" x2="16" y2="6"></line>
      <line x1="8" y1="2" x2="8" y2="6"></line>
      <line x1="3" y1="10" x2="21" y2="10"></line>
    </svg>
  ),
  m_activity_history: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4.181,5.773C6.014,3.474,8.838,2,12.006,2c5.522,0,10,4.478,10,10s-4.478,10-10,10c-5.522,0-10-4.478-10-10 M3.114,2.025v4.444h4.446 M12.006,6.969V13 M17.031,13h-5.025" />
    </svg>
  ),
  m_activity_todo: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16,4h2c1.104,0,2,0.896,2,2v14c0,1.104-0.896,2-2,2H6c-1.104,0-2-0.896-2-2V6c0-1.104,0.896-2,2-2h2 M9,2h6c0.553,0,1,0.448,1,1v2c0,0.552-0.447,1-1,1H9C8.448,6,8,5.552,8,5V3C8,2.448,8.448,2,9,2z M16.996,10l-6.919,6.994l-3.054-3.055" />
    </svg>
  ),
  m_activity_calendar: 'calendar',
  m_activity_reservation: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M10.993,19.91c-0.343,0.045-0.693,0.068-1.048,0.068c-4.407,0-7.979-3.571-7.979-7.979c0-4.407,3.572-7.979,7.979-7.979c1.945,0,3.727,0.695,5.112,1.851 M22.027,7.904l-7.755,7.754 M22.027,7.904l-4.935,14.098l-2.82-6.344l-6.343-2.82L22.027,7.904z M18.042,4.021l-3.041-2.025 M5.007,1.996L1.966,4.021" />
    </svg>
  ),
  m_activity_board: 'calendar',
  m_missed_call: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M11,3H5v6 M19,3l-7,7L5,3 M4.029,20.53l-1.605-1.604c-0.593-0.59-0.597-1.547-0.008-2.141c0.033-0.033,0.068-0.065,0.104-0.096C4.345,15.222,6.488,14.488,8.779,14c2.114-0.471,4.306-0.471,6.42,0c2.299,0.492,4.45,1.232,6.28,2.711c0.642,0.535,0.728,1.489,0.192,2.131c-0.029,0.035-0.06,0.068-0.091,0.1l-1.604,1.604c-0.532,0.543-1.384,0.607-1.99,0.15c-0.582-0.446-1.211-0.824-1.878-1.129c-0.538-0.244-0.885-0.778-0.888-1.37v-1.358c-2.102-0.577-4.319-0.577-6.42,0v1.358c-0.003,0.592-0.35,1.126-0.888,1.371c-0.666,0.304-1.296,0.682-1.877,1.127C5.421,21.158,4.558,21.087,4.029,20.53z" />
    </svg>
  ),
  m_survey: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="10" y="8" className="st0" width="4" height="13" />
      <rect x="17" y="3" className="st0" width="4" height="18" />
      <rect x="3" y="15" className="st0" width="4" height="6" />
    </svg>
  ),
  m_mobile_sync: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-refresh-ccw"
    >
      <polyline points="1 4 1 10 7 10"></polyline>
      <polyline points="23 20 23 14 17 14"></polyline>
      <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
    </svg>
  ),

  m_scheduled_to_send: 'm_activity_reservation',

  m_mail_bulk: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-mail"
    >
      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
      <polyline points="22,6 12,13 2,6"></polyline>
    </svg>
  ),

  //activity crm call
  m_crm_call: <PhoneCall />,
  m_crm_mycall: <PhoneIncoming />,
  m_crm_missedcall: <PhoneMissed />,

  // Analysis/Report
  m_statistics_report: MenuIcons.dashboard,
  m_statistics_setting: MenuIcons.dashboard,
  m_statistics_create: MenuIcons.dashboard,
  m_activity_analysis: MenuIcons.activity,
  m_customer_statistics: 'm_customer',
  m_potential_statistics: 'm_potential',
  // Customization
  ncrm_default_setting: <Settings />,
  m_customer_setting: <Users />,
  m_customer_grade_setting: <BarChart2 />,
  m_selection_options: <CheckSquare />,
  m_interwork: <Database />,
  m_search_setting: <Search />,
  access_permission: <Lock />,
  ncrm_activity: MenuIcons.activity,
  // Layout
  m_layout_main_menu: <CreditCard />,
  m_layout_side_menu: <Sidebar />,
  m_layout_template: <Layout />,
  // E-invoice
  e_invoice_template: (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>CRM</title>
      <path
        d="M18.31,4.21A1.38,1.38,0,0,1,19.72,6,8,8,0,0,1,19,7.71q-1.38,2.43-2.84,4.81A3.65,3.65,0,0,1,14.52,14a2.66,2.66,0,0,1-2.68-4.55C13.58,7.93,15.29,6.36,17,4.8A1.86,1.86,0,0,1,18.31,4.21Z"
        fill="#626262"
      />
      <path
        d="M4.22,14.84c.46.31.87.59,1.28.85a1.27,1.27,0,0,0,.37.18.52.52,0,0,0,.66-.32,10.46,10.46,0,0,1,.63-1.13,2.74,2.74,0,0,1,1.92-1.07,8,8,0,0,1,1.1-.11.32.32,0,0,1,.24.13,3.71,3.71,0,0,0,2,1.59.33.33,0,0,1,.17.18,4.24,4.24,0,0,1-.89,3.24,4.14,4.14,0,0,1-4,1.34,3.76,3.76,0,0,1-3.17-3A6.53,6.53,0,0,1,4.22,14.84Z"
        fill="#626262"
      />
    </svg>
  ),
  e_invoice_release: (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>CRM</title>
      <path
        d="M14.2,14.15v3.46c0,.47,0,.51-.51.51H8c-.14,0-.35-.07-.39-.16s0-.3.13-.41q.56-.72,1.17-1.41a.73.73,0,0,1,.44-.21q1.17,0,2.34,0c.24,0,.33-.06.33-.31,0-1.68,0-3.35,0-5,0-.22-.07-.29-.28-.28H10.38A.55.55,0,0,1,9.82,10a.54.54,0,0,1,.12-.61l2.67-3.21a.56.56,0,0,1,1,0c.88,1,1.76,2.11,2.65,3.16a.59.59,0,0,1,.15.68.6.6,0,0,1-.63.32H14.57c-.37,0-.37,0-.37.36Z"
        fill="#626262"
      />
    </svg>
  ),
  e_invoice_combine_customer: (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>CRM</title>
      <path
        d="M12,19.79c-1.2,0-2.4,0-3.6,0a3,3,0,0,1-3-2.55,7.34,7.34,0,0,1,.83-4.66A3.22,3.22,0,0,1,8,11.11c.19-.07.2-.13.11-.3A4.44,4.44,0,1,1,16.4,8.05a4.36,4.36,0,0,1-.51,2.74c-.08.14-.13.24.08.31a3.68,3.68,0,0,1,2.23,2.37,8.28,8.28,0,0,1,.46,3.38,3.06,3.06,0,0,1-3.1,2.94C14.38,19.76,13.2,19.79,12,19.79Zm0-1.1c1.23,0,2.45,0,3.68,0a1.9,1.9,0,0,0,1.88-1.65,6.45,6.45,0,0,0-.48-3.49,2.46,2.46,0,0,0-1.48-1.46.89.89,0,0,0-.91.07,4.34,4.34,0,0,1-5.46-.06.51.51,0,0,0-.38-.1,2.23,2.23,0,0,0-1.67,1.13,6.12,6.12,0,0,0-.69,3.95A1.88,1.88,0,0,0,8.38,18.7C9.58,18.66,10.78,18.69,12,18.69ZM12,12a3.37,3.37,0,1,0,0-6.73A3.37,3.37,0,0,0,12,12Z"
        fill="#626262"
      />
    </svg>
  ),
  e_invoice_management: (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>CRM</title>
      <path
        d="M18.52,2.77H5.42a.67.67,0,0,0-.67.67V20.56a.67.67,0,0,0,.67.67h13.1a.67.67,0,0,0,.67-.67V3.44A.67.67,0,0,0,18.52,2.77Zm-.58,17.14h-4V17.58a.3.3,0,0,0-.3-.3H10.33a.29.29,0,0,0-.3.3v2.33H6V4.09H17.94Z"
        fill="#626262"
      />
      <rect x="8.26" y="15.2" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="8.26" y="13.07" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="8.26" y="10.93" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="8.26" y="8.8" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="8.26" y="6.67" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="14.61" y="15.2" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="14.61" y="13.07" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="14.61" y="10.93" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="14.61" y="8.8" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="14.61" y="6.67" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="12.48" y="13.07" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="12.48" y="10.93" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="12.48" y="8.8" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="12.48" y="6.67" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="10.34" y="13.07" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="10.34" y="10.93" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="10.34" y="8.8" width="1.07" height="1.07" rx="0.25" fill="#626262" />
      <rect x="10.34" y="6.67" width="1.07" height="1.07" rx="0.25" fill="#626262" />
    </svg>
  ),
  e_invoice_mail_history: (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>CRM</title>
      <path
        d="M12,19.78c-2.06,0-4.13,0-6.19,0A1.48,1.48,0,0,1,4.2,18.23c0-2.73,0-5.45,0-8.17a.58.58,0,0,1,.25-.52c1.7-1.3,3.38-2.61,5.08-3.9.49-.38,1-.74,1.51-1.1a1.53,1.53,0,0,1,1.89,0c.75.53,1.5,1.06,2.23,1.62,1.33,1,2.63,2,4,3a1.53,1.53,0,0,1,.67,1.42c0,2.57,0,5.15,0,7.72a1.37,1.37,0,0,1-1.46,1.47Zm0-3.32a2.56,2.56,0,0,0,1.35-.39c.55-.37,1.1-.76,1.64-1.15.94-.67,1.86-1.35,2.79-2,.29-.21.3-.34.1-.63a.44.44,0,0,1-.08-.11c-.32-.42-.37-.43-.8-.11-1.25.91-2.5,1.84-3.76,2.74a1.75,1.75,0,0,1-2.5,0c-1.23-.89-2.45-1.8-3.69-2.69-.53-.39-.54-.38-.92.16-.22.3-.21.43.09.64,1.26.93,2.52,1.87,3.8,2.78A3.28,3.28,0,0,0,12,16.46Z"
        fill="#626262"
      />
    </svg>
  ),
  e_invoice_setting: (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>CRM</title>
      <path
        d="M7.3,15.74l-.2.13-.8.6a.29.29,0,0,1-.44,0L4.81,15.38a.35.35,0,0,1,0-.52c.19-.23.36-.47.55-.7a.38.38,0,0,0,.05-.4.77.77,0,0,0-.83-.63c-.22,0-.43-.06-.64-.09a.28.28,0,0,1-.27-.32V11.24a.27.27,0,0,1,.27-.31l1-.17a.66.66,0,0,0,.37-.92c-.18-.24-.37-.47-.56-.69a.38.38,0,0,1,0-.57l1.06-1a.33.33,0,0,1,.49,0c.24.19.49.37.73.57a.31.31,0,0,0,.4.05.69.69,0,0,1,.28-.11c.17,0,.21-.14.23-.3,0-.35.11-.69.16-1a.34.34,0,0,1,.38-.3H9.91a.34.34,0,0,1,.38.35c0,.33.1.66.15,1,0,.15.1.21.24.29a.65.65,0,0,0,.9-.13c.17-.15.36-.27.54-.4a.31.31,0,0,1,.47,0l1,1a.37.37,0,0,1,0,.58c-.19.21-.35.46-.53.68a.31.31,0,0,0-.05.4l0,0a.64.64,0,0,0,.75.56,5.46,5.46,0,0,1,.69.1.3.3,0,0,1,.27.33c0,.49,0,1,0,1.48a.29.29,0,0,1-.27.32c-.34,0-.68.11-1,.15-.18,0-.25.12-.34.26a.7.7,0,0,0,.16,1,2.23,2.23,0,0,1,.38.48.44.44,0,0,1,0,.39c-.34.39-.71.75-1.08,1.11a.36.36,0,0,1-.56,0c-.22-.2-.47-.37-.7-.56a.43.43,0,0,0-.4,0,.68.68,0,0,0-.55.74c0,.24-.07.48-.1.71a.33.33,0,0,1-.36.31c-.47,0-.93,0-1.4,0-.22,0-.35-.08-.38-.31S8,16.66,8,16.35a.43.43,0,0,0-.36-.45A2.6,2.6,0,0,1,7.3,15.74Zm1.93-6A2.21,2.21,0,0,0,7,12a2.24,2.24,0,0,0,2.2,2.26A2.28,2.28,0,0,0,11.47,12,2.24,2.24,0,0,0,9.23,9.76Z"
        fill="#626262"
      />
      <path
        d="M14.8,10l.35-.87a.52.52,0,0,0-.41-.73l-.8-.13A.24.24,0,0,1,13.7,8c0-.28,0-.55,0-.83a.25.25,0,0,1,.25-.3c.25,0,.49-.09.74-.12A.52.52,0,0,0,15.15,6c-.11-.25-.2-.5-.3-.75A.23.23,0,0,1,15,4.87q.38-.19.72-.42a.27.27,0,0,1,.41.07c.16.22.34.42.5.64a.53.53,0,0,0,.8,0L18,4.51a.24.24,0,0,1,.36-.07c.26.15.53.28.77.45a.36.36,0,0,1,.11.29c-.09.28-.21.55-.31.83a.52.52,0,0,0,.45.69l.77.12c.18,0,.25.12.24.3s0,.45,0,.67c0,.41,0,.42-.41.5-.18,0-.37.05-.55.08a.52.52,0,0,0-.46.8c.1.23.17.47.27.7a.22.22,0,0,1-.11.32c-.27.15-.53.32-.8.45a.32.32,0,0,1-.3-.05c-.21-.22-.39-.47-.59-.71a.56.56,0,0,0-.75,0l-.54.67c-.11.15-.21.18-.37.08s-.52-.3-.77-.46C14.89,10.14,14.85,10,14.8,10ZM17,8.66a1.13,1.13,0,0,0,1.16-1.1A1.19,1.19,0,0,0,17,6.39a1.21,1.21,0,0,0-1.17,1.12A1.16,1.16,0,0,0,17,8.66Z"
        fill="#626262"
      />
      <path
        d="M17,18.58a2.39,2.39,0,0,0-.44.31,7.22,7.22,0,0,0-.5.6.25.25,0,0,1-.38.06c-.24-.15-.48-.28-.72-.41a.25.25,0,0,1-.13-.37c.1-.25.19-.51.3-.75a.54.54,0,0,0-.43-.73L14,17.18c-.19,0-.3-.11-.28-.32s0-.53,0-.78.06-.32.27-.34.54-.08.8-.12a.54.54,0,0,0,.39-.71c-.11-.25-.2-.5-.3-.75a.26.26,0,0,1,.15-.38c.23-.12.45-.25.67-.39a.27.27,0,0,1,.43.07l.49.6a.54.54,0,0,0,.84,0l.52-.62a.25.25,0,0,1,.36-.07c.25.16.52.29.76.45a.32.32,0,0,1,.1.29,7.87,7.87,0,0,1-.3.81.54.54,0,0,0,.42.72l.8.11a.26.26,0,0,1,.24.31c0,.27,0,.55,0,.83a.26.26,0,0,1-.25.3c-.25,0-.49.08-.74.1a.54.54,0,0,0-.46.76,7.38,7.38,0,0,1,.3.78.34.34,0,0,1-.11.28,6.32,6.32,0,0,1-.79.47.38.38,0,0,1-.31-.06c-.19-.19-.34-.42-.52-.61A2.67,2.67,0,0,0,17,18.58Zm0-1a1.13,1.13,0,0,0,1.14-1.12A1.15,1.15,0,0,0,17,15.33a1.17,1.17,0,0,0-1.15,1.13A1.15,1.15,0,0,0,17,17.58Z"
        fill="#626262"
      />
    </svg>
  ),
  // Quote
  m_quotes_all: mainIcons.quotes,
  m_quotes_my: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20,9V8l-5-6H6C4.896,2,4,2.896,4,4v16c0,1.104,0.896,2,2,2h4.021 M15,2v6h5 M12,9v9.031 M12.5,17H9 M14,11 h-3.5C9.672,11,9,11.672,9,12.5S9.672,14,10.5,14h2 M23,22v-0.761c0-1.22-0.988-2.208-2.207-2.208h-4.586 c-1.219,0-2.207,0.988-2.207,2.208V22 M18.5,12c1.219,0,2.209,0.988,2.209,2.208s-0.99,2.209-2.209,2.209s-2.209-0.989-2.209-2.209 S17.281,12,18.5,12z" />
    </svg>
  ),
  // Selling
  m_dashboard: mainIcons.dashboard,
  m_selling: mainIcons.selling,
  //Loyalty
  m_loyalty: mainIcons.loyalty,
  m_loyalty_spending_rule: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5,22H3c-1.104,0-2-0.896-2-2V4c0-1.104,0.896-2,2-2h18c1.104,0,2,0.896,2,2v2.083 M14.688,12.419 c1.25,0,2.267,1.015,2.267,2.268c0,1.25-1.017,2.265-2.267,2.265c-1.251,0-2.268-1.015-2.268-2.265 C12.419,13.435,13.437,12.419,14.688,12.419z M20.28,16.952c-0.208,0.469-0.109,1.012,0.246,1.378l0.048,0.046 c0.589,0.59,0.589,1.545,0,2.135c0,0,0,0,0,0.002c-0.59,0.591-1.548,0.591-2.139,0c0,0,0,0-0.001,0l-0.044-0.045 c-0.365-0.358-0.909-0.455-1.377-0.25c-0.456,0.198-0.753,0.645-0.754,1.143v0.127c0,0.834-0.678,1.513-1.512,1.513 c-0.835,0-1.51-0.679-1.51-1.513V21.42c-0.014-0.512-0.337-0.966-0.819-1.14c-0.466-0.208-1.008-0.11-1.375,0.246l-0.044,0.047 c-0.592,0.589-1.547,0.589-2.139,0c-0.001,0-0.001,0-0.001,0c-0.588-0.59-0.591-1.548,0-2.139l0,0l0.046-0.045 c0.356-0.366,0.456-0.91,0.248-1.376C8.96,16.558,8.512,16.26,8.013,16.26H7.886c-0.834,0-1.511-0.679-1.511-1.513 c0-0.837,0.677-1.51,1.511-1.51h0.068c0.513-0.014,0.964-0.336,1.142-0.818c0.206-0.467,0.107-1.008-0.25-1.375l-0.045-0.046 c-0.59-0.589-0.591-1.547,0-2.137c0,0,0,0,0-0.003c0.59-0.59,1.545-0.59,2.136,0h0.003l0.044,0.046 c0.366,0.357,0.909,0.458,1.375,0.249h0.061c0.458-0.195,0.755-0.642,0.757-1.139v-0.13c0-0.834,0.677-1.509,1.511-1.509 c0.833,0,1.511,0.675,1.511,1.509v0.07C16.2,8.45,16.499,8.9,16.954,9.095C17.421,9.3,17.966,9.2,18.331,8.846l0.045-0.046 c0.59-0.59,1.545-0.59,2.137,0l0,0c0.593,0.59,0.593,1.548,0,2.137c0,0,0,0,0,0.003l-0.044,0.043 c-0.357,0.366-0.456,0.909-0.249,1.375v0.062c0.196,0.458,0.644,0.752,1.141,0.755h0.127c0.835,0,1.513,0.677,1.513,1.513 c0,0.833-0.678,1.51-1.513,1.51h-0.066C20.923,16.2,20.476,16.498,20.28,16.952z" />
    </svg>
  ),

  //Order
  m_order_all: mainIcons.order,
  m_order_my: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20,9V8l-5-6H6C4.896,2,4,2.896,4,4v16c0,1.104,0.896,2,2,2h4 M15,2v6h5 M7.05,8h1.058 M9.404,15.031L8.108,8 M9.404,15.031H13 M10.5,18.021c-0.275,0-0.5,0.225-0.5,0.5s0.225,0.5,0.5,0.5s0.5-0.225,0.5-0.5S10.775,18.021,10.5,18.021z M12,11 h3.104 M23,23v-0.762c0-1.219-0.988-2.207-2.207-2.207h-4.586c-1.219,0-2.207,0.988-2.207,2.207V23 M18.5,13 c1.219,0,2.209,0.988,2.209,2.208s-0.99,2.209-2.209,2.209s-2.209-0.989-2.209-2.209S17.281,13,18.5,13z" />
    </svg>
  ),
};

const MenuIcons2 = {
  'co-manage_dashboard': MenuIcons.co_dashboard,
  'co-manage_project': MenuIcons.co_manage_gray,
  'co-manage_complete-project': MenuIcons.co_manage_gray,
  'co-manage_work-list': MenuIcons.co_worklist,
  'co-manage_admin': MenuIcons.co_admin,
  'co-manage_report': MenuIcons.co_report,
  t_dashboard: MenuIcons.dashboard,
  t_customer: MenuIcons.n_customer,
  t_activity: MenuIcons.n_activity,
  t_statistics: MenuIcons.statistics,
  t_product: MenuIcons.product,
  t_layout: MenuIcons.layout,
  t_customization: MenuIcons.customization,
  t_selling: MenuIcons.selling,
  t_einvoice: MenuIcons.einvoice,
  t_loyalty: MenuIcons.loyalty,
  desk: MenuIcons.helpdesk,
  order: MenuIcons.order,

  m_customer: LeftSideIcons.m_customer,
  m_customer_account: LeftSideIcons.m_customer_account,
  m_lead: LeftSideIcons.m_lead,
  m_potential: LeftSideIcons.m_potential,

  m_activity_history: LeftSideIcons.m_activity_history,
  m_missed_call: LeftSideIcons.m_missed_call,
  m_crm_call: LeftSideIcons.m_crm_call,
  m_crm_mycall: LeftSideIcons.m_crm_mycall,
  m_crm_missedcall: LeftSideIcons.m_crm_missedcall,
  m_survey: LeftSideIcons.m_survey,
  m_mobile_sync: LeftSideIcons.m_mobile_sync,
  m_customer_card: LeftSideIcons.m_mobile_sync,
  price_list: MenuIcons.price_list,
};

const namespace = (namespaceString: string, obj: any): any => {
  let parts = namespaceString.split('.'),
    parent = obj,
    currentPart = '';

  for (let i = 0, { length } = parts; i < length; i++) {
    currentPart = parts[i];
    parent[currentPart] = parent[currentPart] || null;
    parent = parent[currentPart];

    if (parent === null) {
      break;
    }
  }

  if (typeof parent === 'string') {
    return namespace(parent, obj);
  }

  return parent;
};

const Icons = (name: string) => {
  let icon = namespace(name, { ...MenuIcons, ...LeftSideIcons, ...MenuIcons2 });

  if (icon) return icon;

  console.warn(name + ' icon does not exist');
};

export default {
  ...MenuIcons,
  ...MenuIcons2,
};

export { LeftSideIcons, Icons };
