import React from 'react';
import CommonViewField from '@base/containers/view-field/common';
import { DurationValue } from '@base/types/interfaces/common';
import View from './view';
import Edit from './edit';

type durationProps = {
  value: DurationValue;
  onSave: (params: any) => void;
  keyName: string;
  menuSourceId: string;
  menuSource: string;
};

const Duration = (props: durationProps) => {
  const { onSave, value, menuSourceId, menuSource, keyName, ...remainProps } = props;
  return (
    <CommonViewField
      keyName={keyName}
      menuSourceId={menuSourceId}
      menuSource={menuSource}
      {...remainProps}
      onSave={onSave}
      componentView={View}
      componentEdit={Edit}
      value={value}
    />
  );
};

export default Duration;
