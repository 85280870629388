import { DurationValue } from '@base/types/interfaces/common';
import { parseDurationValueToString } from '@base/utils/helpers/date-utils';

type viewProps = {
  value: DurationValue;
};
const View = (props: viewProps) => {
  const { value } = props;

  return <>{value ? parseDurationValueToString(value) : ''}</>;
};

export default View;
