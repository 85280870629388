import React from 'react';

const css = () => {
  return (
    <svg id="file-type-css" xmlns="http://www.w3.org/2000/svg" width="42px" height="51px" viewBox="0 0 42 51">
      <g>
        <path fill="#83CEE6" d="M42,49c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h32l8,8V49z" />
        <polygon fill="#58B5D4" points="42.003,14.828 34.615,7.44 42,8 	" />
        <g>
          <path fill="#ABE0F3" d="M42,8h-6c-1.104,0-2-0.896-2-2V0L42,8z" />
        </g>
      </g>
      <g>
        <path
          fill="#83CEE6"
          d="M35.527,30.026V28.11c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479-0.393,1.953,1.087,1.953H42v2.2h-0.493c-1.479,0-1.087,0.475-1.087,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C37.841,31.126,37.537,30.596,35.527,30.026z M34.525,37.942c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S33.579,37.942,34.525,37.942z M28.526,37.979c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S27.561,37.979,28.526,37.979z M18.686,32.283c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954h-0.493v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C18.989,30.596,18.686,31.126,18.686,32.283z M20.777,36.229c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C21.561,37.979,20.777,37.195,20.777,36.229z"
        />
        <path
          fill="#82CDE6"
          d="M35.391,29.867v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479-0.369,1.953,1.11,1.953h0.493v2.2h-0.493c-1.479,0-1.11,0.475-1.11,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C37.704,30.967,37.4,30.437,35.391,29.867z M34.389,37.783c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S33.442,37.783,34.389,37.783z M28.39,37.819c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S27.424,37.819,28.39,37.819z M18.55,32.124c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.313,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954h-0.493v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C18.853,30.437,18.55,30.967,18.55,32.124z M20.641,36.069c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C21.424,37.819,20.641,37.036,20.641,36.069z"
        />
        <path
          fill="#81CDE5"
          d="M35.255,29.708v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479-0.348,1.953,1.132,1.953h0.493v2.2h-0.493c-1.479,0-1.132,0.475-1.132,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C37.568,30.808,37.265,30.277,35.255,29.708z M34.253,37.624c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S33.307,37.624,34.253,37.624z M28.254,37.66c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S27.288,37.66,28.254,37.66z M18.413,31.965c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.424,0-4.951-1.82-4.951-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954h-0.493v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.951-3.756v1.916C18.716,30.277,18.413,30.808,18.413,31.965z M20.505,35.91c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C21.288,37.66,20.505,36.877,20.505,35.91z"
        />
        <path
          fill="#80CCE5"
          d="M35.118,29.549v-1.916c3.475,0,4.949,1.822,4.949,3.756c0,0.285-0.057,0.986-0.057,1.309
					c0,1.48-0.324,1.953,1.155,1.953h0.493v2.201h-0.493c-1.479,0-1.155,0.475-1.155,1.953c0,0.322,0.057,1.025,0.057,1.309
					c0,1.936-1.526,3.756-4.949,3.756v-1.916c2.01-0.568,2.313-1.1,2.313-2.256c0-0.381-0.057-1.006-0.057-1.404
					c0-1.385,0.702-2.125,1.972-2.541c-1.27-0.418-1.972-1.158-1.972-2.543c0-0.396,0.057-1.023,0.057-1.402
					C37.432,30.648,37.128,30.119,35.118,29.549z M34.116,37.465c0.947,0,1.715-0.768,1.715-1.713c0-0.947-0.768-1.715-1.715-1.715
					c-0.946,0-1.714,0.768-1.714,1.715C32.402,36.697,33.17,37.465,34.116,37.465z M28.117,37.502c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S27.151,37.502,28.117,37.502z M18.277,31.807c0,0.379,0.057,1.006,0.057,1.402
					c0,1.385-0.702,2.125-1.972,2.543c1.271,0.416,1.972,1.156,1.972,2.541c0,0.398-0.057,1.023-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.256v1.916c-3.423,0-4.95-1.82-4.95-3.756c0-0.283,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.953-2.086-1.953h-0.493V34.65h0.493c1.479,0,2.086-0.473,2.086-1.953c0-0.322-0.057-1.023-0.057-1.309
					c0-1.934,1.475-3.756,4.95-3.756v1.916C18.58,30.119,18.277,30.648,18.277,31.807z M20.368,35.752c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C21.151,37.502,20.368,36.719,20.368,35.752z"
        />
        <path
          fill="#7FCCE4"
          d="M34.981,29.391v-1.916c3.475,0,4.949,1.82,4.949,3.756c0,0.283-0.057,0.986-0.057,1.309
					c0,1.479-0.301,1.953,1.179,1.953h0.493v2.199h-0.493c-1.479,0-1.179,0.475-1.179,1.955c0,0.322,0.057,1.023,0.057,1.309
					c0,1.934-1.526,3.754-4.949,3.754v-1.914c2.01-0.57,2.313-1.102,2.313-2.258c0-0.379-0.057-1.006-0.057-1.404
					c0-1.383,0.702-2.123,1.972-2.541c-1.27-0.416-1.972-1.156-1.972-2.541c0-0.398,0.057-1.023,0.057-1.404
					C37.295,30.49,36.991,29.959,34.981,29.391z M33.979,37.307c0.947,0,1.715-0.768,1.715-1.715c0-0.945-0.768-1.713-1.715-1.713
					c-0.946,0-1.714,0.768-1.714,1.713C32.266,36.539,33.033,37.307,33.979,37.307z M27.98,37.342c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S27.015,37.342,27.98,37.342z M18.141,31.646c0,0.381,0.057,1.006,0.057,1.404
					c0,1.385-0.702,2.125-1.972,2.541c1.271,0.418,1.972,1.158,1.972,2.541c0,0.398-0.057,1.025-0.057,1.404
					c0,1.156,0.303,1.688,2.313,2.258v1.914c-3.423,0-4.95-1.82-4.95-3.754c0-0.285,0.057-0.986,0.057-1.309
					c0-1.48-0.607-1.955-2.086-1.955h-0.493v-2.199h0.493c1.479,0,2.086-0.475,2.086-1.953c0-0.322-0.057-1.025-0.057-1.309
					c0-1.936,1.475-3.756,4.95-3.756v1.916C18.444,29.959,18.141,30.49,18.141,31.646z M20.231,35.592c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C21.015,37.342,20.231,36.559,20.231,35.592z"
        />
        <path
          fill="#7ECBE4"
          d="M34.846,29.23v-1.916c3.475,0,4.949,1.822,4.949,3.756c0,0.285-0.057,0.986-0.057,1.309
					c0,1.48-0.279,1.953,1.2,1.953h0.493v2.201h-0.493c-1.479,0-1.2,0.475-1.2,1.953c0,0.322,0.057,1.025,0.057,1.309
					c0,1.936-1.526,3.756-4.949,3.756v-1.916c2.01-0.568,2.313-1.1,2.313-2.256c0-0.381-0.057-1.006-0.057-1.404
					c0-1.385,0.702-2.125,1.972-2.541c-1.27-0.418-1.972-1.158-1.972-2.543c0-0.396,0.057-1.023,0.057-1.402
					C37.159,30.33,36.855,29.801,34.846,29.23z M33.844,37.146c0.947,0,1.715-0.768,1.715-1.713c0-0.947-0.768-1.715-1.715-1.715
					c-0.946,0-1.714,0.768-1.714,1.715C32.13,36.379,32.897,37.146,33.844,37.146z M27.845,37.184c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S26.879,37.184,27.845,37.184z M18.004,31.488c0,0.379,0.057,1.006,0.057,1.402
					c0,1.385-0.702,2.125-1.972,2.543c1.271,0.416,1.972,1.156,1.972,2.541c0,0.398-0.057,1.023-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.256v1.916c-3.423,0-4.95-1.82-4.95-3.756c0-0.283,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.953-2.086-1.953h-0.493v-2.201h0.493c1.479,0,2.086-0.473,2.086-1.953c0-0.322-0.057-1.023-0.057-1.309
					c0-1.934,1.475-3.756,4.95-3.756v1.916C18.308,29.801,18.004,30.33,18.004,31.488z M20.096,35.434c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C20.879,37.184,20.096,36.4,20.096,35.434z"
        />
        <path
          fill="#7DCBE4"
          d="M34.709,29.072v-1.916c3.475,0,4.949,1.82,4.949,3.756c0,0.283-0.057,0.986-0.057,1.309
					c0,1.479-0.256,1.953,1.224,1.953h0.493v2.199h-0.493c-1.479,0-1.224,0.475-1.224,1.955c0,0.322,0.057,1.023,0.057,1.309
					c0,1.934-1.526,3.754-4.949,3.754v-1.914c2.01-0.57,2.313-1.102,2.313-2.258c0-0.379-0.057-1.006-0.057-1.404
					c0-1.383,0.702-2.123,1.972-2.541c-1.27-0.416-1.972-1.156-1.972-2.541c0-0.398,0.057-1.023,0.057-1.404
					C37.022,30.172,36.719,29.641,34.709,29.072z M33.707,36.988c0.947,0,1.715-0.768,1.715-1.715c0-0.945-0.768-1.713-1.715-1.713
					c-0.946,0-1.714,0.768-1.714,1.713C31.993,36.221,32.761,36.988,33.707,36.988z M27.708,37.023c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S26.742,37.023,27.708,37.023z M17.868,31.328c0,0.381,0.057,1.006,0.057,1.404
					c0,1.385-0.702,2.125-1.972,2.541c1.271,0.418,1.972,1.158,1.972,2.541c0,0.398-0.057,1.025-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.258v1.914c-3.423,0-4.95-1.82-4.95-3.754c0-0.285,0.057-0.986,0.057-1.309
					c0-1.48-0.607-1.955-2.086-1.955h-0.493v-2.199h0.493c1.479,0,2.086-0.475,2.086-1.953c0-0.322-0.057-1.025-0.057-1.309
					c0-1.936,1.475-3.756,4.95-3.756v1.916C18.171,29.641,17.868,30.172,17.868,31.328z M19.959,35.273c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C20.742,37.023,19.959,36.24,19.959,35.273z"
        />
        <path
          fill="#7CCAE3"
          d="M34.573,28.912v-1.916c3.475,0,4.949,1.822,4.949,3.756c0,0.285-0.057,0.986-0.057,1.309
					c0,1.48-0.234,1.953,1.245,1.953h0.493v2.201h-0.493c-1.479,0-1.245,0.475-1.245,1.953c0,0.322,0.057,1.025,0.057,1.309
					c0,1.936-1.526,3.756-4.949,3.756v-1.916c2.01-0.568,2.313-1.1,2.313-2.256c0-0.381-0.057-1.006-0.057-1.404
					c0-1.385,0.702-2.125,1.972-2.541c-1.27-0.418-1.972-1.158-1.972-2.543c0-0.396,0.057-1.023,0.057-1.402
					C36.887,30.012,36.583,29.482,34.573,28.912z M33.571,36.828c0.947,0,1.715-0.768,1.715-1.713c0-0.947-0.768-1.715-1.715-1.715
					c-0.946,0-1.714,0.768-1.714,1.715C31.857,36.061,32.625,36.828,33.571,36.828z M27.572,36.865c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S26.606,36.865,27.572,36.865z M17.731,31.17c0,0.379,0.057,1.006,0.057,1.402
					c0,1.385-0.702,2.125-1.972,2.543c1.271,0.416,1.972,1.156,1.972,2.541c0,0.398-0.057,1.023-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.256v1.916c-3.424,0-4.951-1.82-4.951-3.756c0-0.283,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.953-2.086-1.953h-0.493v-2.201h0.493c1.479,0,2.086-0.473,2.086-1.953c0-0.322-0.057-1.023-0.057-1.309
					c0-1.934,1.475-3.756,4.951-3.756v1.916C18.035,29.482,17.731,30.012,17.731,31.17z M19.822,35.115c0-0.967,0.784-1.75,1.75-1.75
					c0.967,0,1.75,0.783,1.75,1.75s-0.783,1.75-1.75,1.75C20.606,36.865,19.822,36.082,19.822,35.115z"
        />
        <path
          fill="#7BC9E3"
          d="M34.437,28.754v-1.916c3.475,0,4.949,1.82,4.949,3.756c0,0.283-0.057,0.986-0.057,1.309
					c0,1.479-0.211,1.953,1.269,1.953h0.493v2.199h-0.493c-1.479,0-1.269,0.475-1.269,1.955c0,0.322,0.057,1.023,0.057,1.309
					c0,1.934-1.526,3.754-4.949,3.754v-1.914c2.01-0.57,2.313-1.102,2.313-2.258c0-0.379-0.057-1.006-0.057-1.404
					c0-1.383,0.702-2.123,1.972-2.541c-1.27-0.416-1.972-1.156-1.972-2.541c0-0.398,0.057-1.023,0.057-1.404
					C36.75,29.854,36.446,29.322,34.437,28.754z M33.435,36.67c0.947,0,1.715-0.768,1.715-1.715c0-0.945-0.768-1.713-1.715-1.713
					c-0.946,0-1.714,0.768-1.714,1.713C31.721,35.902,32.488,36.67,33.435,36.67z M27.436,36.705c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S26.47,36.705,27.436,36.705z M17.595,31.01c0,0.381,0.057,1.006,0.057,1.404
					c0,1.385-0.702,2.125-1.972,2.541c1.271,0.418,1.972,1.158,1.972,2.541c0,0.398-0.057,1.025-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.258v1.914c-3.423,0-4.95-1.82-4.95-3.754c0-0.285,0.057-0.986,0.057-1.309
					c0-1.48-0.607-1.955-2.086-1.955h-0.493v-2.199h0.493c1.479,0,2.086-0.475,2.086-1.953c0-0.322-0.057-1.025-0.057-1.309
					c0-1.936,1.475-3.756,4.95-3.756v1.916C17.898,29.322,17.595,29.854,17.595,31.01z M19.687,34.955c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C20.47,36.705,19.687,35.922,19.687,34.955z"
        />
        <path
          fill="#7AC9E2"
          d="M34.3,28.595v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479-0.188,1.953,1.292,1.953h0.493v2.2h-0.493c-1.479,0-1.292,0.475-1.292,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C36.613,29.694,36.31,29.164,34.3,28.595z M33.298,36.511c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S32.352,36.511,33.298,36.511z M27.299,36.547c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S26.333,36.547,27.299,36.547z M17.459,30.852c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.313,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954H12.3v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C17.762,29.164,17.459,29.694,17.459,30.852z M19.55,34.797c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C20.333,36.547,19.55,35.764,19.55,34.797z"
        />
        <path
          fill="#79C8E2"
          d="M34.164,28.436V26.52c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479-0.166,1.953,1.313,1.953h0.493v2.2H40.37c-1.479,0-1.313,0.475-1.313,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755V40.84c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C36.478,29.535,36.174,29.005,34.164,28.436z M33.162,36.352c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S32.216,36.352,33.162,36.352z M27.163,36.388c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S26.197,36.388,27.163,36.388z M17.322,30.692c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.424,0-4.951-1.82-4.951-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954h-0.493v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.951-3.756v1.916C17.625,29.005,17.322,29.535,17.322,30.692z M19.414,34.638c0-0.967,0.784-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C20.197,36.388,19.414,35.604,19.414,34.638z"
        />
        <path
          fill="#78C8E2"
          d="M34.027,28.276V26.36c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479-0.143,1.953,1.337,1.953h0.493v2.2h-0.493c-1.479,0-1.337,0.475-1.337,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C36.341,29.376,36.037,28.846,34.027,28.276z M33.025,36.192c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S32.079,36.192,33.025,36.192z M27.026,36.229c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S26.061,36.229,27.026,36.229z M17.186,30.533c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954h-0.493v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C17.489,28.846,17.186,29.376,17.186,30.533z M19.277,34.479c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C20.061,36.229,19.277,35.445,19.277,34.479z"
        />
        <path
          fill="#77C7E1"
          d="M33.891,28.117v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479-0.119,1.953,1.36,1.953h0.493v2.2h-0.493c-1.479,0-1.36,0.475-1.36,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C36.204,29.217,35.9,28.687,33.891,28.117z M32.889,36.033c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S31.942,36.033,32.889,36.033z M26.89,36.069c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S25.924,36.069,26.89,36.069z M17.05,30.374c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954h-0.493v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C17.353,28.687,17.05,29.217,17.05,30.374z M19.141,34.319c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C19.924,36.069,19.141,35.286,19.141,34.319z"
        />
        <path
          fill="#76C7E1"
          d="M33.755,27.958v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479-0.098,1.953,1.382,1.953h0.493v2.2h-0.493c-1.479,0-1.382,0.475-1.382,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C36.068,29.058,35.765,28.527,33.755,27.958z M32.753,35.874c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S31.807,35.874,32.753,35.874z M26.754,35.91c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S25.788,35.91,26.754,35.91z M16.913,30.215c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954h-0.493v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C17.216,28.527,16.913,29.058,16.913,30.215z M19.004,34.16c0-0.967,0.784-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C19.788,35.91,19.004,35.127,19.004,34.16z"
        />
        <path
          fill="#75C6E0"
          d="M33.618,27.799v-1.916c3.475,0,4.949,1.822,4.949,3.755c0,0.285-0.057,0.986-0.057,1.309
					c0,1.48-0.074,1.953,1.405,1.953h0.493v2.201h-0.493c-1.479,0-1.405,0.475-1.405,1.953c0,0.322,0.057,1.025,0.057,1.309
					c0,1.936-1.526,3.756-4.949,3.756v-1.916c2.01-0.568,2.313-1.1,2.313-2.256c0-0.381-0.057-1.006-0.057-1.404
					c0-1.385,0.702-2.125,1.972-2.541c-1.27-0.418-1.972-1.158-1.972-2.543c0-0.396,0.057-1.023,0.057-1.402
					C35.932,28.898,35.628,28.369,33.618,27.799z M32.616,35.715c0.947,0,1.715-0.768,1.715-1.713c0-0.947-0.768-1.715-1.715-1.715
					c-0.946,0-1.714,0.768-1.714,1.715C30.902,34.947,31.67,35.715,32.616,35.715z M26.617,35.752c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S25.651,35.752,26.617,35.752z M16.777,30.057c0,0.379,0.057,1.006,0.057,1.402
					c0,1.385-0.702,2.125-1.972,2.543c1.271,0.416,1.972,1.156,1.972,2.541c0,0.398-0.057,1.023-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.256v1.916c-3.423,0-4.95-1.82-4.95-3.756c0-0.283,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.953-2.086-1.953h-0.493V32.9h0.493c1.479,0,2.086-0.473,2.086-1.953c0-0.322-0.057-1.023-0.057-1.309
					c0-1.934,1.475-3.755,4.95-3.755v1.916C17.08,28.369,16.777,28.898,16.777,30.057z M18.868,34.002c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C19.651,35.752,18.868,34.969,18.868,34.002z"
        />
        <path
          fill="#74C5E0"
          d="M33.481,27.641v-1.917c3.475,0,4.949,1.821,4.949,3.756c0,0.283-0.057,0.986-0.057,1.309
					c0,1.479-0.051,1.953,1.429,1.953h0.493v2.199h-0.493c-1.479,0-1.429,0.475-1.429,1.955c0,0.322,0.057,1.023,0.057,1.309
					c0,1.934-1.526,3.754-4.949,3.754v-1.914c2.01-0.57,2.313-1.102,2.313-2.258c0-0.379-0.057-1.006-0.057-1.404
					c0-1.383,0.702-2.123,1.972-2.541c-1.27-0.416-1.972-1.156-1.972-2.541c0-0.398,0.057-1.023,0.057-1.404
					C35.795,28.74,35.491,28.209,33.481,27.641z M32.479,35.557c0.947,0,1.715-0.768,1.715-1.715c0-0.945-0.768-1.713-1.715-1.713
					c-0.946,0-1.714,0.768-1.714,1.713C30.766,34.789,31.533,35.557,32.479,35.557z M26.48,35.592c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S25.515,35.592,26.48,35.592z M16.641,29.896c0,0.381,0.057,1.006,0.057,1.404
					c0,1.385-0.702,2.125-1.972,2.541c1.271,0.418,1.972,1.158,1.972,2.541c0,0.398-0.057,1.025-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.258v1.914c-3.423,0-4.95-1.82-4.95-3.754c0-0.285,0.057-0.986,0.057-1.309
					c0-1.48-0.607-1.955-2.086-1.955h-0.493v-2.199h0.493c1.479,0,2.086-0.475,2.086-1.953c0-0.322-0.057-1.025-0.057-1.309
					c0-1.936,1.475-3.756,4.95-3.756v1.917C16.944,28.209,16.641,28.74,16.641,29.896z M18.732,33.842c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C19.515,35.592,18.732,34.809,18.732,33.842z"
        />
        <path
          fill="#73C5DF"
          d="M33.346,27.48v-1.916c3.475,0,4.949,1.822,4.949,3.755c0,0.285-0.057,0.986-0.057,1.309
					c0,1.48-0.029,1.953,1.45,1.953h0.493v2.201h-0.493c-1.479,0-1.45,0.475-1.45,1.953c0,0.322,0.057,1.025,0.057,1.309
					c0,1.936-1.526,3.756-4.949,3.756v-1.916c2.01-0.568,2.313-1.1,2.313-2.256c0-0.381-0.057-1.006-0.057-1.404
					c0-1.385,0.702-2.125,1.972-2.541c-1.27-0.418-1.972-1.158-1.972-2.543c0-0.396,0.057-1.023,0.057-1.402
					C35.659,28.58,35.355,28.051,33.346,27.48z M32.344,35.396c0.947,0,1.715-0.768,1.715-1.713c0-0.947-0.768-1.715-1.715-1.715
					c-0.946,0-1.714,0.768-1.714,1.715C30.63,34.629,31.397,35.396,32.344,35.396z M26.345,35.434c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S25.379,35.434,26.345,35.434z M16.504,29.738c0,0.379,0.057,1.006,0.057,1.402
					c0,1.385-0.702,2.125-1.972,2.543c1.271,0.416,1.972,1.156,1.972,2.541c0,0.398-0.057,1.023-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.256v1.916c-3.423,0-4.95-1.82-4.95-3.756c0-0.283,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.953-2.086-1.953h-0.493v-2.201h0.493c1.479,0,2.086-0.473,2.086-1.953c0-0.322-0.057-1.023-0.057-1.309
					c0-1.934,1.475-3.755,4.95-3.755v1.916C16.808,28.051,16.504,28.58,16.504,29.738z M18.595,33.684c0-0.967,0.784-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C19.379,35.434,18.595,34.65,18.595,33.684z"
        />
        <path
          fill="#72C4DF"
          d="M33.209,27.322v-1.917c3.475,0,4.949,1.821,4.949,3.756c0,0.283-0.057,0.986-0.057,1.309
					c0,1.479-0.006,1.953,1.474,1.953h0.493v2.199h-0.493c-1.479,0-1.474,0.475-1.474,1.955c0,0.322,0.057,1.023,0.057,1.309
					c0,1.934-1.526,3.754-4.949,3.754v-1.914c2.01-0.57,2.313-1.102,2.313-2.258c0-0.379-0.057-1.006-0.057-1.404
					c0-1.383,0.702-2.123,1.972-2.541c-1.27-0.416-1.972-1.156-1.972-2.541c0-0.398,0.057-1.023,0.057-1.404
					C35.522,28.422,35.219,27.891,33.209,27.322z M32.207,35.238c0.947,0,1.715-0.768,1.715-1.715c0-0.945-0.768-1.713-1.715-1.713
					c-0.946,0-1.714,0.768-1.714,1.713C30.493,34.471,31.261,35.238,32.207,35.238z M26.208,35.273c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S25.242,35.273,26.208,35.273z M16.368,29.578c0,0.381,0.057,1.006,0.057,1.404
					c0,1.385-0.702,2.125-1.972,2.541c1.271,0.418,1.972,1.158,1.972,2.541c0,0.398-0.057,1.025-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.258v1.914c-3.423,0-4.95-1.82-4.95-3.754c0-0.285,0.057-0.986,0.057-1.309
					c0-1.48-0.607-1.955-2.086-1.955h-0.493v-2.199h0.493c1.479,0,2.086-0.475,2.086-1.953c0-0.322-0.057-1.025-0.057-1.309
					c0-1.936,1.475-3.756,4.95-3.756v1.917C16.671,27.891,16.368,28.422,16.368,29.578z M18.459,33.523c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C19.242,35.273,18.459,34.49,18.459,33.523z"
        />
        <path
          fill="#71C4DF"
          d="M33.073,27.162v-1.916c3.475,0,4.949,1.822,4.949,3.755c0,0.285-0.057,0.986-0.057,1.309
					c0,1.48,0.016,1.953,1.495,1.953h0.493v2.201h-0.493c-1.479,0-1.495,0.475-1.495,1.953c0,0.322,0.057,1.025,0.057,1.309
					c0,1.936-1.526,3.756-4.949,3.756v-1.916c2.01-0.568,2.313-1.1,2.313-2.256c0-0.381-0.057-1.006-0.057-1.404
					c0-1.385,0.702-2.125,1.972-2.541c-1.27-0.418-1.972-1.158-1.972-2.543c0-0.396,0.057-1.023,0.057-1.402
					C35.387,28.262,35.083,27.732,33.073,27.162z M32.071,35.078c0.947,0,1.715-0.768,1.715-1.713c0-0.947-0.768-1.715-1.715-1.715
					c-0.946,0-1.714,0.768-1.714,1.715C30.357,34.311,31.125,35.078,32.071,35.078z M26.072,35.115c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S25.106,35.115,26.072,35.115z M16.231,29.42c0,0.379,0.057,1.006,0.057,1.402
					c0,1.385-0.702,2.125-1.972,2.543c1.271,0.416,1.972,1.156,1.972,2.541c0,0.398-0.057,1.023-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.256v1.916c-3.423,0-4.95-1.82-4.95-3.756c0-0.283,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.953-2.086-1.953h-0.493v-2.201h0.493c1.479,0,2.086-0.473,2.086-1.953c0-0.322-0.057-1.023-0.057-1.309
					c0-1.934,1.475-3.755,4.95-3.755v1.916C16.535,27.732,16.231,28.262,16.231,29.42z M18.323,33.365c0-0.967,0.783-1.75,1.75-1.75
					c0.967,0,1.75,0.783,1.75,1.75s-0.783,1.75-1.75,1.75C19.106,35.115,18.323,34.332,18.323,33.365z"
        />
        <path
          fill="#70C3DE"
          d="M32.937,27.004v-1.917c3.475,0,4.949,1.821,4.949,3.756c0,0.283-0.057,0.986-0.057,1.309
					c0,1.479,0.039,1.953,1.519,1.953h0.493v2.199h-0.493c-1.479,0-1.519,0.475-1.519,1.955c0,0.322,0.057,1.023,0.057,1.309
					c0,1.934-1.526,3.754-4.949,3.754v-1.914c2.01-0.57,2.313-1.102,2.313-2.258c0-0.379-0.057-1.006-0.057-1.404
					c0-1.383,0.702-2.123,1.972-2.541c-1.27-0.416-1.972-1.156-1.972-2.541c0-0.398,0.057-1.023,0.057-1.404
					C35.25,28.104,34.946,27.572,32.937,27.004z M31.935,34.92c0.947,0,1.715-0.768,1.715-1.715c0-0.945-0.768-1.713-1.715-1.713
					c-0.946,0-1.714,0.768-1.714,1.713C30.221,34.152,30.988,34.92,31.935,34.92z M25.936,34.955c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S24.97,34.955,25.936,34.955z M16.095,29.26c0,0.381,0.057,1.006,0.057,1.404
					c0,1.385-0.702,2.125-1.972,2.541c1.271,0.418,1.972,1.158,1.972,2.541c0,0.398-0.057,1.025-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.258v1.914c-3.423,0-4.95-1.82-4.95-3.754c0-0.285,0.057-0.986,0.057-1.309
					c0-1.48-0.607-1.955-2.086-1.955h-0.493v-2.199h0.493c1.479,0,2.086-0.475,2.086-1.953c0-0.322-0.057-1.025-0.057-1.309
					c0-1.936,1.475-3.756,4.95-3.756v1.917C16.398,27.572,16.095,28.104,16.095,29.26z M18.186,33.205c0-0.967,0.784-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C18.97,34.955,18.186,34.172,18.186,33.205z"
        />
        <path
          fill="#6FC3DE"
          d="M32.8,26.845v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479,0.063,1.953,1.542,1.953h0.493v2.2h-0.493c-1.479,0-1.542,0.475-1.542,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C35.113,27.944,34.81,27.414,32.8,26.845z M31.798,34.761c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S30.852,34.761,31.798,34.761z M25.799,34.797c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S24.833,34.797,25.799,34.797z M15.959,29.102c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954H10.8v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C16.262,27.414,15.959,27.944,15.959,29.102z M18.05,33.047c0-0.967,0.784-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C18.833,34.797,18.05,34.014,18.05,33.047z"
        />
        <path
          fill="#6EC2DD"
          d="M32.664,26.686V24.77c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479,0.084,1.953,1.563,1.953h0.493v2.2H39.12c-1.479,0-1.563,0.475-1.563,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755V39.09c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C34.978,27.785,34.674,27.255,32.664,26.686z M31.662,34.602c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S30.716,34.602,31.662,34.602z M25.663,34.638c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S24.697,34.638,25.663,34.638z M15.822,28.942c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954h-0.493v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C16.125,27.255,15.822,27.785,15.822,28.942z M17.914,32.888c0-0.967,0.783-1.75,1.75-1.75
					c0.967,0,1.75,0.783,1.75,1.75s-0.783,1.75-1.75,1.75C18.697,34.638,17.914,33.854,17.914,32.888z"
        />
        <path
          fill="#6EC1DD"
          d="M32.527,26.526V24.61c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479,0.107,1.953,1.587,1.953H39.5v2.2h-0.493c-1.479,0-1.587,0.475-1.587,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C34.841,27.626,34.537,27.096,32.527,26.526z M31.525,34.442c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S30.579,34.442,31.525,34.442z M25.526,34.479c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S24.561,34.479,25.526,34.479z M15.686,28.783c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954h-0.493v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C15.989,27.096,15.686,27.626,15.686,28.783z M17.777,32.729c0-0.967,0.784-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C18.561,34.479,17.777,33.695,17.777,32.729z"
        />
        <path
          fill="#6DC1DD"
          d="M32.391,26.367v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479,0.131,1.953,1.61,1.953h0.493v2.2h-0.493c-1.479,0-1.61,0.475-1.61,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C34.704,27.467,34.4,26.937,32.391,26.367z M31.389,34.283c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S30.442,34.283,31.389,34.283z M25.39,34.319c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S24.424,34.319,25.39,34.319z M15.55,28.624c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954h-0.493v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C15.853,26.937,15.55,27.467,15.55,28.624z M17.641,32.569c0-0.967,0.784-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C18.424,34.319,17.641,33.536,17.641,32.569z"
        />
        <path
          fill="#6CC0DC"
          d="M32.255,26.208v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479,0.152,1.953,1.632,1.953h0.493v2.2h-0.493c-1.479,0-1.632,0.475-1.632,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C34.568,27.308,34.265,26.777,32.255,26.208z M31.253,34.124c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S30.307,34.124,31.253,34.124z M25.254,34.16c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S24.288,34.16,25.254,34.16z M15.413,28.465c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954h-0.493v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C15.716,26.777,15.413,27.308,15.413,28.465z M17.504,32.41c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.783,1.75-1.75,1.75C18.288,34.16,17.504,33.377,17.504,32.41z"
        />
        <path
          fill="#6BC0DC"
          d="M32.118,26.049v-1.916c3.475,0,4.949,1.821,4.949,3.755c0,0.285-0.057,0.986-0.057,1.309
					c0,1.48,0.176,1.953,1.655,1.953h0.493v2.201h-0.493c-1.479,0-1.655,0.475-1.655,1.953c0,0.322,0.057,1.025,0.057,1.309
					c0,1.936-1.526,3.756-4.949,3.756v-1.916c2.01-0.568,2.313-1.1,2.313-2.256c0-0.381-0.057-1.006-0.057-1.404
					c0-1.385,0.702-2.125,1.972-2.541c-1.27-0.418-1.972-1.158-1.972-2.543c0-0.396,0.057-1.023,0.057-1.402
					C34.432,27.148,34.128,26.619,32.118,26.049z M31.116,33.965c0.947,0,1.715-0.768,1.715-1.713c0-0.947-0.768-1.715-1.715-1.715
					c-0.946,0-1.714,0.768-1.714,1.715C29.402,33.197,30.17,33.965,31.116,33.965z M25.117,34.002c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S24.151,34.002,25.117,34.002z M15.277,28.307c0,0.379,0.057,1.006,0.057,1.402
					c0,1.385-0.702,2.125-1.972,2.543c1.271,0.416,1.972,1.156,1.972,2.541c0,0.398-0.057,1.023-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.256v1.916c-3.423,0-4.95-1.82-4.95-3.756c0-0.283,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.953-2.086-1.953h-0.493V31.15h0.493c1.479,0,2.086-0.473,2.086-1.953c0-0.322-0.057-1.023-0.057-1.309
					c0-1.934,1.475-3.755,4.95-3.755v1.916C15.58,26.619,15.277,27.148,15.277,28.307z M17.368,32.252c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C18.151,34.002,17.368,33.219,17.368,32.252z"
        />
        <path
          fill="#6ABFDB"
          d="M31.981,25.89v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.283-0.057,0.986-0.057,1.309
					c0,1.479,0.199,1.953,1.679,1.953h0.493v2.199h-0.493c-1.479,0-1.679,0.475-1.679,1.955c0,0.322,0.057,1.023,0.057,1.309
					c0,1.934-1.526,3.754-4.949,3.754v-1.914c2.01-0.57,2.313-1.102,2.313-2.258c0-0.379-0.057-1.006-0.057-1.404
					c0-1.383,0.702-2.123,1.972-2.541c-1.27-0.416-1.972-1.156-1.972-2.541c0-0.398,0.057-1.023,0.057-1.404
					C34.295,26.99,33.991,26.459,31.981,25.89z M30.979,33.807c0.947,0,1.715-0.768,1.715-1.715c0-0.945-0.768-1.713-1.715-1.713
					c-0.946,0-1.714,0.768-1.714,1.713C29.266,33.039,30.033,33.807,30.979,33.807z M24.98,33.842c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S24.015,33.842,24.98,33.842z M15.141,28.146c0,0.381,0.057,1.006,0.057,1.404
					c0,1.385-0.702,2.125-1.972,2.541c1.271,0.418,1.972,1.158,1.972,2.541c0,0.398-0.057,1.025-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.258v1.914c-3.423,0-4.95-1.82-4.95-3.754c0-0.285,0.057-0.986,0.057-1.309
					c0-1.48-0.607-1.955-2.086-1.955H9.981v-2.199h0.493c1.479,0,2.086-0.475,2.086-1.953c0-0.322-0.057-1.025-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C15.444,26.459,15.141,26.99,15.141,28.146z M17.231,32.092c0-0.967,0.784-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C18.015,33.842,17.231,33.059,17.231,32.092z"
        />
        <path
          fill="#69BFDB"
          d="M31.846,25.731v-1.916c3.475,0,4.949,1.821,4.949,3.755c0,0.285-0.057,0.986-0.057,1.309
					c0,1.48,0.221,1.953,1.7,1.953h0.493v2.201h-0.493c-1.479,0-1.7,0.475-1.7,1.953c0,0.322,0.057,1.025,0.057,1.309
					c0,1.936-1.526,3.756-4.949,3.756v-1.916c2.01-0.568,2.313-1.1,2.313-2.256c0-0.381-0.057-1.006-0.057-1.404
					c0-1.385,0.702-2.125,1.972-2.541c-1.27-0.418-1.972-1.158-1.972-2.543c0-0.396,0.057-1.023,0.057-1.402
					C34.159,26.83,33.855,26.301,31.846,25.731z M30.844,33.646c0.947,0,1.715-0.768,1.715-1.713c0-0.947-0.768-1.715-1.715-1.715
					c-0.946,0-1.714,0.768-1.714,1.715C29.13,32.879,29.897,33.646,30.844,33.646z M24.845,33.684c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S23.879,33.684,24.845,33.684z M15.004,27.988c0,0.379,0.057,1.006,0.057,1.402
					c0,1.385-0.702,2.125-1.972,2.543c1.271,0.416,1.972,1.156,1.972,2.541c0,0.398-0.057,1.023-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.256v1.916c-3.423,0-4.95-1.82-4.95-3.756c0-0.283,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.953-2.086-1.953H9.845v-2.201h0.493c1.479,0,2.086-0.473,2.086-1.953c0-0.322-0.057-1.023-0.057-1.309
					c0-1.934,1.475-3.755,4.95-3.755v1.916C15.308,26.301,15.004,26.83,15.004,27.988z M17.095,31.934c0-0.967,0.784-1.75,1.75-1.75
					s1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75S17.095,32.9,17.095,31.934z"
        />
        <path
          fill="#68BEDB"
          d="M31.709,25.572v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.283-0.057,0.986-0.057,1.309
					c0,1.479,0.244,1.953,1.724,1.953h0.493v2.199h-0.493c-1.479,0-1.724,0.475-1.724,1.955c0,0.322,0.057,1.023,0.057,1.309
					c0,1.934-1.526,3.754-4.949,3.754v-1.914c2.01-0.57,2.313-1.102,2.313-2.258c0-0.379-0.057-1.006-0.057-1.404
					c0-1.383,0.702-2.123,1.972-2.541c-1.27-0.416-1.972-1.156-1.972-2.541c0-0.398,0.057-1.023,0.057-1.404
					C34.022,26.672,33.719,26.141,31.709,25.572z M30.707,33.488c0.947,0,1.715-0.768,1.715-1.715c0-0.945-0.768-1.713-1.715-1.713
					c-0.946,0-1.714,0.768-1.714,1.713C28.993,32.721,29.761,33.488,30.707,33.488z M24.708,33.523c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S23.742,33.523,24.708,33.523z M14.868,27.828c0,0.381,0.057,1.006,0.057,1.404
					c0,1.385-0.702,2.125-1.972,2.541c1.271,0.418,1.972,1.158,1.972,2.541c0,0.398-0.057,1.025-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.258v1.914c-3.423,0-4.95-1.82-4.95-3.754c0-0.285,0.057-0.986,0.057-1.309
					c0-1.48-0.607-1.955-2.086-1.955H9.708v-2.199h0.493c1.479,0,2.086-0.475,2.086-1.953c0-0.322-0.057-1.025-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C15.171,26.141,14.868,26.672,14.868,27.828z M16.959,31.773c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C17.742,33.523,16.959,32.74,16.959,31.773z"
        />
        <path
          fill="#67BEDA"
          d="M31.573,25.413v-1.916c3.475,0,4.949,1.821,4.949,3.755c0,0.285-0.057,0.986-0.057,1.309
					c0,1.48,0.266,1.953,1.745,1.953h0.493v2.201h-0.493c-1.479,0-1.745,0.475-1.745,1.953c0,0.322,0.057,1.025,0.057,1.309
					c0,1.936-1.526,3.756-4.949,3.756v-1.916c2.01-0.568,2.313-1.1,2.313-2.256c0-0.381-0.057-1.006-0.057-1.404
					c0-1.385,0.702-2.125,1.972-2.541c-1.27-0.418-1.972-1.158-1.972-2.543c0-0.396,0.057-1.023,0.057-1.402
					C33.887,26.512,33.583,25.982,31.573,25.413z M30.571,33.328c0.947,0,1.715-0.768,1.715-1.713c0-0.947-0.768-1.715-1.715-1.715
					c-0.946,0-1.714,0.768-1.714,1.715C28.857,32.561,29.625,33.328,30.571,33.328z M24.572,33.365c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S23.606,33.365,24.572,33.365z M14.731,27.67c0,0.379,0.057,1.006,0.057,1.402
					c0,1.385-0.702,2.125-1.972,2.543c1.271,0.416,1.972,1.156,1.972,2.541c0,0.398-0.057,1.023-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.256v1.916c-3.423,0-4.95-1.82-4.95-3.756c0-0.283,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.953-2.086-1.953H9.572v-2.201h0.493c1.479,0,2.086-0.473,2.086-1.953c0-0.322-0.057-1.023-0.057-1.309
					c0-1.934,1.475-3.755,4.95-3.755v1.916C15.035,25.982,14.731,26.512,14.731,27.67z M16.822,31.615c0-0.967,0.784-1.75,1.75-1.75
					c0.967,0,1.75,0.783,1.75,1.75s-0.783,1.75-1.75,1.75C17.606,33.365,16.822,32.582,16.822,31.615z"
        />
        <path
          fill="#66BDDA"
          d="M31.437,25.253v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.283-0.057,0.986-0.057,1.309
					c0,1.479,0.289,1.953,1.769,1.953h0.493v2.199h-0.493c-1.479,0-1.769,0.475-1.769,1.955c0,0.322,0.057,1.023,0.057,1.309
					c0,1.934-1.526,3.754-4.949,3.754v-1.914c2.01-0.57,2.313-1.102,2.313-2.258c0-0.379-0.057-1.006-0.057-1.404
					c0-1.383,0.702-2.123,1.972-2.541c-1.27-0.416-1.972-1.156-1.972-2.541c0-0.398,0.057-1.023,0.057-1.404
					C33.75,26.354,33.446,25.823,31.437,25.253z M30.435,33.17c0.947,0,1.715-0.768,1.715-1.715c0-0.945-0.768-1.713-1.715-1.713
					c-0.946,0-1.714,0.768-1.714,1.713C28.721,32.402,29.488,33.17,30.435,33.17z M24.436,33.205c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S23.47,33.205,24.436,33.205z M14.595,27.51c0,0.381,0.057,1.006,0.057,1.404
					c0,1.385-0.702,2.125-1.972,2.541c1.271,0.418,1.972,1.158,1.972,2.541c0,0.398-0.057,1.025-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.258v1.914c-3.423,0-4.95-1.82-4.95-3.754c0-0.285,0.057-0.986,0.057-1.309
					c0-1.48-0.607-1.955-2.086-1.955H9.436v-2.199h0.493c1.479,0,2.086-0.475,2.086-1.953c0-0.322-0.057-1.025-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C14.898,25.823,14.595,26.354,14.595,27.51z M16.686,31.455c0-0.967,0.784-1.75,1.75-1.75
					s1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75S16.686,32.422,16.686,31.455z"
        />
        <path
          fill="#65BCD9"
          d="M31.3,25.095v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479,0.313,1.953,1.792,1.953h0.493v2.2h-0.493c-1.479,0-1.792,0.475-1.792,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C33.613,26.194,33.31,25.664,31.3,25.095z M30.298,33.011c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S29.352,33.011,30.298,33.011z M24.299,33.047c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S23.333,33.047,24.299,33.047z M14.459,27.352c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954H9.3v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C14.762,25.664,14.459,26.194,14.459,27.352z M16.55,31.297c0-0.967,0.784-1.75,1.75-1.75
					s1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75S16.55,32.264,16.55,31.297z"
        />
        <path
          fill="#64BCD9"
          d="M31.164,24.936V23.02c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479,0.334,1.953,1.813,1.953h0.493v2.2H37.87c-1.479,0-1.813,0.475-1.813,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755V37.34c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C33.478,26.035,33.174,25.505,31.164,24.936z M30.162,32.852c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S29.216,32.852,30.162,32.852z M24.163,32.888c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S23.197,32.888,24.163,32.888z M14.322,27.192c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954H9.163v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C14.625,25.505,14.322,26.035,14.322,27.192z M16.414,31.138c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C17.197,32.888,16.414,32.104,16.414,31.138z"
        />
        <path
          fill="#63BBD9"
          d="M31.027,24.776V22.86c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479,0.357,1.953,1.837,1.953h0.493v2.2h-0.493c-1.479,0-1.837,0.475-1.837,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C33.341,25.876,33.037,25.346,31.027,24.776z M30.025,32.692c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S29.079,32.692,30.025,32.692z M24.026,32.729c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S23.061,32.729,24.026,32.729z M14.186,27.033c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954H9.027v-2.2H9.52c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C14.489,25.346,14.186,25.876,14.186,27.033z M16.277,30.979c0-0.967,0.784-1.75,1.75-1.75
					s1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75S16.277,31.945,16.277,30.979z"
        />
        <path
          fill="#62BBD8"
          d="M30.891,24.617v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479,0.381,1.953,1.86,1.953h0.493v2.2h-0.493c-1.479,0-1.86,0.475-1.86,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C33.204,25.717,32.9,25.187,30.891,24.617z M29.889,32.533c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S28.942,32.533,29.889,32.533z M23.89,32.569c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S22.924,32.569,23.89,32.569z M14.05,26.874c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954H8.891v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C14.353,25.187,14.05,25.717,14.05,26.874z M16.141,30.819c0-0.967,0.784-1.75,1.75-1.75
					s1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75S16.141,31.786,16.141,30.819z"
        />
        <path
          fill="#61BAD8"
          d="M30.755,24.458v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479,0.402,1.953,1.882,1.953h0.493v2.2h-0.493c-1.479,0-1.882,0.475-1.882,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C33.068,25.558,32.765,25.027,30.755,24.458z M29.753,32.374c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S28.807,32.374,29.753,32.374z M23.754,32.41c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S22.788,32.41,23.754,32.41z M13.913,26.715c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954H8.754v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C14.216,25.027,13.913,25.558,13.913,26.715z M16.004,30.66c0-0.967,0.783-1.75,1.75-1.75
					c0.966,0,1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75C16.788,32.41,16.004,31.627,16.004,30.66z"
        />
        <path
          fill="#60BAD7"
          d="M30.618,24.299v-1.916c3.475,0,4.949,1.821,4.949,3.755c0,0.285-0.057,0.986-0.057,1.309
					c0,1.48,0.426,1.953,1.905,1.953h0.493v2.201h-0.493c-1.479,0-1.905,0.475-1.905,1.953c0,0.322,0.057,1.025,0.057,1.309
					c0,1.936-1.526,3.756-4.949,3.756v-1.916c2.01-0.568,2.313-1.1,2.313-2.256c0-0.381-0.057-1.006-0.057-1.404
					c0-1.385,0.702-2.125,1.972-2.541c-1.27-0.418-1.972-1.158-1.972-2.543c0-0.396,0.057-1.023,0.057-1.402
					C32.932,25.399,32.628,24.869,30.618,24.299z M29.616,32.215c0.947,0,1.715-0.768,1.715-1.713c0-0.947-0.768-1.715-1.715-1.715
					c-0.946,0-1.714,0.768-1.714,1.715C27.902,31.447,28.67,32.215,29.616,32.215z M23.617,32.252c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S22.651,32.252,23.617,32.252z M13.777,26.557c0,0.379,0.057,1.006,0.057,1.402
					c0,1.385-0.702,2.125-1.972,2.543c1.271,0.416,1.972,1.156,1.972,2.541c0,0.398-0.057,1.023-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.256v1.916c-3.423,0-4.95-1.82-4.95-3.756c0-0.283,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.953-2.086-1.953H8.618V29.4h0.493c1.479,0,2.086-0.473,2.086-1.953c0-0.322-0.057-1.023-0.057-1.309
					c0-1.934,1.475-3.755,4.95-3.755v1.916C14.08,24.869,13.777,25.399,13.777,26.557z M15.868,30.502c0-0.967,0.784-1.75,1.75-1.75
					s1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75S15.868,31.469,15.868,30.502z"
        />
        <path
          fill="#5FB9D7"
          d="M30.481,24.14v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.987-0.057,1.309
					c0,1.479,0.449,1.953,1.929,1.953h0.493v2.199h-0.493c-1.479,0-1.929,0.475-1.929,1.955c0,0.322,0.057,1.023,0.057,1.309
					c0,1.934-1.526,3.754-4.949,3.754v-1.914c2.01-0.57,2.313-1.102,2.313-2.258c0-0.379-0.057-1.006-0.057-1.404
					c0-1.383,0.702-2.123,1.972-2.541c-1.27-0.416-1.972-1.156-1.972-2.541c0-0.398,0.057-1.023,0.057-1.404
					C32.795,25.24,32.491,24.709,30.481,24.14z M29.479,32.057c0.947,0,1.715-0.768,1.715-1.715c0-0.945-0.768-1.713-1.715-1.713
					c-0.946,0-1.714,0.768-1.714,1.713C27.766,31.289,28.533,32.057,29.479,32.057z M23.48,32.092c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S22.515,32.092,23.48,32.092z M13.641,26.396c0,0.381,0.057,1.006,0.057,1.404
					c0,1.385-0.702,2.125-1.972,2.541c1.271,0.418,1.972,1.158,1.972,2.541c0,0.398-0.057,1.025-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.258v1.914c-3.423,0-4.95-1.82-4.95-3.754c0-0.285,0.057-0.986,0.057-1.309
					c0-1.48-0.607-1.955-2.086-1.955H8.481v-2.199h0.493c1.479,0,2.086-0.475,2.086-1.953c0-0.322-0.057-1.025-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C13.944,24.709,13.641,25.24,13.641,26.396z M15.731,30.342c0-0.967,0.784-1.75,1.75-1.75
					s1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75S15.731,31.309,15.731,30.342z"
        />
        <path
          fill="#5EB8D6"
          d="M30.346,23.981v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.285-0.057,0.986-0.057,1.308
					c0,1.48,0.471,1.953,1.95,1.953h0.493v2.201h-0.493c-1.479,0-1.95,0.475-1.95,1.953c0,0.322,0.057,1.025,0.057,1.309
					c0,1.936-1.526,3.756-4.949,3.756v-1.916c2.01-0.568,2.313-1.1,2.313-2.256c0-0.381-0.057-1.006-0.057-1.404
					c0-1.385,0.702-2.125,1.972-2.541c-1.27-0.418-1.972-1.158-1.972-2.543c0-0.396,0.057-1.023,0.057-1.402
					C32.659,25.081,32.355,24.55,30.346,23.981z M29.344,31.896c0.947,0,1.715-0.768,1.715-1.713c0-0.947-0.768-1.715-1.715-1.715
					c-0.946,0-1.714,0.768-1.714,1.715C27.63,31.129,28.397,31.896,29.344,31.896z M23.345,31.934c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S22.379,31.934,23.345,31.934z M13.504,26.238c0,0.379,0.057,1.006,0.057,1.402
					c0,1.385-0.702,2.125-1.972,2.543c1.271,0.416,1.972,1.156,1.972,2.541c0,0.398-0.057,1.023-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.256v1.916c-3.423,0-4.95-1.82-4.95-3.756c0-0.283,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.953-2.086-1.953H8.345v-2.201h0.493c1.479,0,2.086-0.473,2.086-1.953c0-0.322-0.057-1.023-0.057-1.308
					c0-1.935,1.475-3.756,4.95-3.756v1.916C13.808,24.55,13.504,25.081,13.504,26.238z M15.595,30.184c0-0.967,0.784-1.75,1.75-1.75
					s1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75S15.595,31.15,15.595,30.184z"
        />
        <path
          fill="#5DB8D6"
          d="M30.209,23.822v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.987-0.057,1.309
					c0,1.479,0.494,1.953,1.974,1.953h0.493v2.199h-0.493c-1.479,0-1.974,0.475-1.974,1.955c0,0.322,0.057,1.023,0.057,1.309
					c0,1.934-1.526,3.754-4.949,3.754v-1.914c2.01-0.57,2.313-1.102,2.313-2.258c0-0.379-0.057-1.006-0.057-1.404
					c0-1.383,0.702-2.123,1.972-2.541c-1.27-0.416-1.972-1.156-1.972-2.541c0-0.398,0.057-1.023,0.057-1.404
					C32.522,24.921,32.219,24.391,30.209,23.822z M29.207,31.738c0.947,0,1.715-0.768,1.715-1.715c0-0.945-0.768-1.713-1.715-1.713
					c-0.946,0-1.714,0.768-1.714,1.713C27.493,30.971,28.261,31.738,29.207,31.738z M23.208,31.773c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S22.242,31.773,23.208,31.773z M13.368,26.078c0,0.381,0.057,1.006,0.057,1.404
					c0,1.385-0.702,2.125-1.972,2.541c1.271,0.418,1.972,1.158,1.972,2.541c0,0.398-0.057,1.025-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.258v1.914c-3.423,0-4.95-1.82-4.95-3.754c0-0.285,0.057-0.986,0.057-1.309
					c0-1.48-0.607-1.955-2.086-1.955H8.208v-2.199h0.493c1.479,0,2.086-0.475,2.086-1.953c0-0.322-0.057-1.025-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C13.671,24.391,13.368,24.921,13.368,26.078z M15.458,30.023c0-0.967,0.784-1.75,1.75-1.75
					s1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75S15.458,30.99,15.458,30.023z"
        />
        <path
          fill="#5CB7D6"
          d="M30.073,23.663v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.308
					c0,1.48,0.516,1.953,1.995,1.953h0.493v2.201h-0.493c-1.479,0-1.995,0.475-1.995,1.953c0,0.322,0.057,1.025,0.057,1.309
					c0,1.936-1.526,3.756-4.949,3.756v-1.916c2.01-0.568,2.313-1.1,2.313-2.256c0-0.381-0.057-1.006-0.057-1.404
					c0-1.385,0.702-2.125,1.972-2.541c-1.27-0.418-1.972-1.158-1.972-2.543c0-0.396,0.057-1.023,0.057-1.403
					C32.387,24.762,32.083,24.232,30.073,23.663z M29.071,31.578c0.947,0,1.715-0.768,1.715-1.713c0-0.947-0.768-1.715-1.715-1.715
					c-0.946,0-1.714,0.768-1.714,1.715C27.357,30.811,28.125,31.578,29.071,31.578z M23.072,31.615c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S22.106,31.615,23.072,31.615z M13.231,25.919c0,0.379,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.543c1.271,0.416,1.972,1.156,1.972,2.541c0,0.398-0.057,1.023-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.256v1.916c-3.423,0-4.95-1.82-4.95-3.756c0-0.283,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.953-2.086-1.953H8.072v-2.201h0.493c1.479,0,2.086-0.473,2.086-1.953c0-0.322-0.057-1.024-0.057-1.308
					c0-1.935,1.475-3.756,4.95-3.756v1.916C13.535,24.232,13.231,24.762,13.231,25.919z M15.322,29.865c0-0.967,0.784-1.75,1.75-1.75
					s1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75S15.322,30.832,15.322,29.865z"
        />
        <path
          fill="#5BB7D5"
          d="M29.937,23.503v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.987-0.057,1.309
					c0,1.479,0.539,1.953,2.019,1.953h0.493v2.199h-0.493c-1.479,0-2.019,0.475-2.019,1.955c0,0.322,0.057,1.023,0.057,1.309
					c0,1.934-1.526,3.754-4.949,3.754v-1.914c2.01-0.57,2.313-1.102,2.313-2.258c0-0.379-0.057-1.006-0.057-1.404
					c0-1.383,0.702-2.123,1.972-2.541c-1.27-0.416-1.972-1.156-1.972-2.541c0-0.398,0.057-1.023,0.057-1.404
					C32.25,24.603,31.946,24.073,29.937,23.503z M28.935,31.42c0.947,0,1.715-0.768,1.715-1.715c0-0.945-0.768-1.713-1.715-1.713
					c-0.946,0-1.714,0.768-1.714,1.713C27.221,30.652,27.988,31.42,28.935,31.42z M22.936,31.455c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S21.97,31.455,22.936,31.455z M13.095,25.76c0,0.38,0.057,1.005,0.057,1.404
					c0,1.385-0.702,2.125-1.972,2.541c1.271,0.418,1.972,1.158,1.972,2.541c0,0.398-0.057,1.025-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.258v1.914c-3.423,0-4.95-1.82-4.95-3.754c0-0.285,0.057-0.986,0.057-1.309
					c0-1.48-0.607-1.955-2.086-1.955H7.936v-2.199h0.493c1.479,0,2.086-0.475,2.086-1.953c0-0.322-0.057-1.025-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C13.398,24.073,13.095,24.603,13.095,25.76z M15.186,29.705c0-0.967,0.784-1.75,1.75-1.75
					s1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75S15.186,30.672,15.186,29.705z"
        />
        <path
          fill="#5AB6D5"
          d="M29.8,23.345v-1.916c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479,0.563,1.953,2.042,1.953h0.493v2.2h-0.493c-1.479,0-2.042,0.475-2.042,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C32.113,24.444,31.81,23.914,29.8,23.345z M28.798,31.261c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S27.852,31.261,28.798,31.261z M22.799,31.297c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S21.833,31.297,22.799,31.297z M12.959,25.602c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954H7.8v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C13.262,23.914,12.959,24.444,12.959,25.602z M15.05,29.547c0-0.967,0.784-1.75,1.75-1.75
					s1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75S15.05,30.514,15.05,29.547z"
        />
        <path
          fill="#59B6D4"
          d="M29.664,23.186V21.27c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479,0.584,1.953,2.063,1.953h0.493v2.2H36.62c-1.479,0-2.063,0.475-2.063,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755V35.59c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C31.978,24.285,31.674,23.755,29.664,23.186z M28.662,31.102c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S27.716,31.102,28.662,31.102z M22.663,31.138c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S21.697,31.138,22.663,31.138z M12.822,25.442c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954H7.663v-2.2h0.493c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C13.125,23.755,12.822,24.285,12.822,25.442z M14.913,29.388c0-0.967,0.784-1.75,1.75-1.75
					s1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75S14.913,30.354,14.913,29.388z"
        />
        <path
          fill="#58B5D4"
          d="M29.527,23.026V21.11c3.475,0,4.949,1.821,4.949,3.756c0,0.284-0.057,0.986-0.057,1.309
					c0,1.479,0.607,1.953,2.087,1.953H37v2.2h-0.493c-1.479,0-2.087,0.475-2.087,1.954c0,0.322,0.057,1.024,0.057,1.309
					c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257c0-0.38-0.057-1.006-0.057-1.404
					c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542c0-0.397,0.057-1.023,0.057-1.403
					C31.841,24.126,31.537,23.596,29.527,23.026z M28.525,30.942c0.947,0,1.715-0.768,1.715-1.714s-0.768-1.714-1.715-1.714
					c-0.946,0-1.714,0.768-1.714,1.714S27.579,30.942,28.525,30.942z M22.526,30.979c0.966,0,1.75-0.783,1.75-1.75
					s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S21.561,30.979,22.526,30.979z M12.686,25.283c0,0.38,0.057,1.006,0.057,1.403
					c0,1.385-0.702,2.125-1.972,2.542c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404
					c0,1.156,0.303,1.688,2.314,2.257v1.915c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309
					c0-1.479-0.607-1.954-2.086-1.954H7.527v-2.2H8.02c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309
					c0-1.935,1.475-3.756,4.95-3.756v1.916C12.989,23.596,12.686,24.126,12.686,25.283z M14.777,29.229c0-0.967,0.784-1.75,1.75-1.75
					s1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75S14.777,30.195,14.777,29.229z"
        />
      </g>
      <path
        fill="#52A8C4"
        d="M12.686,25.283c0,0.38,0.057,1.006,0.057,1.403c0,1.385-0.702,2.125-1.972,2.542
				c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404c0,1.156,0.303,1.688,2.314,2.257v1.915
				c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309c0-1.479-0.607-1.954-2.086-1.954H7.527v-2.2H8.02
				c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309c0-1.935,1.475-3.756,4.95-3.756v1.916
				C12.989,23.596,12.686,24.126,12.686,25.283z M29.527,23.026V21.11c3.475,0,4.949,1.821,4.949,3.756
				c0,0.284-0.057,0.986-0.057,1.309c0,1.479,0.607,1.953,2.087,1.953H37v2.2h-0.493c-1.479,0-2.087,0.475-2.087,1.954
				c0,0.322,0.057,1.024,0.057,1.309c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257
				c0-0.38-0.057-1.006-0.057-1.404c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542
				c0-0.397,0.057-1.023,0.057-1.403C31.841,24.126,31.537,23.596,29.527,23.026z M14.777,29.229c0-0.967,0.784-1.75,1.75-1.75
				s1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75S14.777,30.195,14.777,29.229z M22.526,30.979c0.966,0,1.75-0.783,1.75-1.75
				s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S21.561,30.979,22.526,30.979z M28.525,30.942c0.947,0,1.715-0.768,1.715-1.714
				s-0.768-1.714-1.715-1.714c-0.946,0-1.714,0.768-1.714,1.714S27.579,30.942,28.525,30.942z"
      />
      <path
        fill="#FFFFFF"
        d="M11.686,24.283c0,0.38,0.057,1.006,0.057,1.403c0,1.385-0.702,2.125-1.972,2.542
				c1.271,0.417,1.972,1.157,1.972,2.541c0,0.398-0.057,1.024-0.057,1.404c0,1.156,0.303,1.688,2.314,2.257v1.915
				c-3.423,0-4.95-1.82-4.95-3.755c0-0.284,0.057-0.986,0.057-1.309c0-1.479-0.607-1.954-2.086-1.954H6.527v-2.2H7.02
				c1.479,0,2.086-0.474,2.086-1.953c0-0.322-0.057-1.024-0.057-1.309c0-1.935,1.475-3.756,4.95-3.756v1.916
				C11.989,22.596,11.686,23.126,11.686,24.283z M28.527,22.026V20.11c3.475,0,4.949,1.821,4.949,3.756
				c0,0.284-0.057,0.986-0.057,1.309c0,1.479,0.607,1.953,2.087,1.953H36v2.2h-0.493c-1.479,0-2.087,0.475-2.087,1.954
				c0,0.322,0.057,1.024,0.057,1.309c0,1.935-1.526,3.755-4.949,3.755v-1.915c2.01-0.569,2.313-1.101,2.313-2.257
				c0-0.38-0.057-1.006-0.057-1.404c0-1.384,0.702-2.124,1.972-2.541c-1.27-0.417-1.972-1.157-1.972-2.542
				c0-0.397,0.057-1.023,0.057-1.403C30.841,23.126,30.537,22.596,28.527,22.026z M13.777,28.229c0-0.967,0.784-1.75,1.75-1.75
				s1.75,0.783,1.75,1.75s-0.784,1.75-1.75,1.75S13.777,29.195,13.777,28.229z M21.526,29.979c0.966,0,1.75-0.783,1.75-1.75
				s-0.784-1.75-1.75-1.75s-1.75,0.783-1.75,1.75S20.561,29.979,21.526,29.979z M27.525,29.942c0.947,0,1.715-0.768,1.715-1.714
				s-0.768-1.714-1.715-1.714c-0.946,0-1.714,0.768-1.714,1.714S26.579,29.942,27.525,29.942z"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M14.886,10.867c-1.443,0-2.515-1.113-2.515-2.521V8.332c0-1.395,1.051-2.535,2.557-2.535
					c0.924,0,1.478,0.308,1.933,0.756l-0.687,0.791c-0.378-0.343-0.764-0.553-1.254-0.553c-0.826,0-1.421,0.687-1.421,1.527v0.014
					c0,0.84,0.581,1.541,1.421,1.541c0.561,0,0.904-0.225,1.289-0.574l0.686,0.693C16.392,10.531,15.831,10.867,14.886,10.867z"
        />
        <path
          fill="#FFFFFF"
          d="M20.198,10.854c-0.742,0-1.492-0.26-2.08-0.785l0.638-0.764C19.197,9.67,19.659,9.9,20.219,9.9
					c0.441,0,0.708-0.175,0.708-0.462V9.424c0-0.272-0.168-0.412-0.987-0.623c-0.988-0.252-1.625-0.525-1.625-1.498V7.288
					c0-0.89,0.714-1.478,1.716-1.478c0.714,0,1.323,0.225,1.821,0.623l-0.561,0.813c-0.434-0.301-0.861-0.482-1.274-0.482
					s-0.63,0.188-0.63,0.427v0.014c0,0.322,0.209,0.427,1.057,0.645c0.994,0.259,1.555,0.616,1.555,1.471v0.015
					C21.998,10.307,21.256,10.854,20.198,10.854z"
        />
        <path
          fill="#FFFFFF"
          d="M25.309,10.854c-0.742,0-1.492-0.26-2.08-0.785l0.637-0.764C24.307,9.67,24.77,9.9,25.33,9.9
					c0.44,0,0.707-0.175,0.707-0.462V9.424c0-0.272-0.168-0.412-0.988-0.623c-0.987-0.252-1.625-0.525-1.625-1.498V7.288
					c0-0.89,0.715-1.478,1.717-1.478c0.714,0,1.323,0.225,1.82,0.623L26.4,7.246c-0.434-0.301-0.861-0.482-1.273-0.482
					c-0.414,0-0.631,0.188-0.631,0.427v0.014c0,0.322,0.21,0.427,1.057,0.645c0.995,0.259,1.556,0.616,1.556,1.471v0.015
					C27.108,10.307,26.365,10.854,25.309,10.854z"
        />
      </g>
    </svg>
  );
};

export default css;
