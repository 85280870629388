import React from 'react';
import classNames from 'classnames';
// // import Tooltip from 'rc-tooltip';
// import 'rc-tooltip/assets/bootstrap.css';
import { Tooltip as BTooltip } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Placement } from 'react-bootstrap/esm/types';
import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';
import { nanoid } from '@base/utils/helpers';

interface ToolTipProps {
  children: any;
  placement: Placement;
  overlay: any;
  overlayClassName: string;
  trigger: OverlayTriggerType;
  visible: boolean;
  onVisibleChange: boolean;
  className: string;
  outSide: boolean;
}

const ToolTip = (props: ToolTipProps) => {
  const {
    children,
    placement,
    overlay,
    overlayClassName,
    trigger,
    visible,
    onVisibleChange,
    className,
    outSide,
  } = props;

  // const hoverOption: any = {
  //   placement,
  //   mouseenterdelay: 0,
  //   mouseleavedelay: 0.1,
  //   trigger,
  //   overlay: overlay,
  //   overlayclassname: overlayClassName,
  // };

  // const clickOption: any = {
  //   placement,
  //   mouseEnterDelay: 0,
  //   mouseLeaveDelay: 0.1,
  //   trigger,
  //   overlay: overlay,
  //   overlayClassName: overlayClassName,
  //   visible,
  //   onVisibleChange,
  // };

  const overlayStyle: any = {};

  if (outSide) overlayStyle.zIndex = 9999;
  let tooltipId = nanoid();
  return (
    <div className={classNames('cursor-pointer', className)}>
      {/* <Tooltip {...(trigger == 'click' ? clickOption : hoverOption)} overlayStyle={overlayStyle}>
        {children}
      </Tooltip> */}
      <OverlayTrigger
        key={placement}
        placement={placement}
        overlay={<BTooltip id={`tooltip-${tooltipId}`}>{overlay}</BTooltip>}
      >
        {children}
      </OverlayTrigger>
    </div>
  );
};

ToolTip.defaultProps = {
  children: null,
  placement: 'right',
  overlay: null,
  overlayClassName: '',
  trigger: ['hover', 'focus'],
  visible: false,
  onVisibleChange: null,
  className: '',
  outSide: false,
};

export default ToolTip;
