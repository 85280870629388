import { isObject, isString, isBoolean } from 'lodash';

/**
 *
 * @param checkedValueStr id string, all, notAll
 * @param selectedIds array string id
 * @param listData array data
 */
export function handleCheckbox(
  checkedValueStr: string,
  selectedIds: string[],
  listData: any[],
): string[] {
  let newSelectedIds = selectedIds;

  switch (checkedValueStr) {
    case 'all':
      if (listData && listData.length) {
        // for (const i in listData) {
        //   newSelectedIds = [...newSelectedIds, listData[i]?.id];
        // }
        const curPageSelectedIds = listData.map((item) => {
          return item.id;
        });
        newSelectedIds = curPageSelectedIds;
      }
      break;
    case 'notAll':
      newSelectedIds = [];
      break;
    default:
      if (selectedIds.indexOf(checkedValueStr) !== -1) {
        //newSelectedIds.splice(selectedIds.indexOf(checkedValueStr), 1);
        newSelectedIds = selectedIds.filter((id) => {
          return checkedValueStr != id;
        });
      } else {
        newSelectedIds = [...selectedIds, checkedValueStr];
      }
      break;
  }
  // console.log('checkedValueStr', checkedValueStr, selectedIds, newSelectedIds);
  return newSelectedIds;
}

export const getMoreActions = () => {
  return {
    actions: {
      merge: {
        label: 'Merge',
        icon: 'merge',
        iconType: 'common',
      },
      import: {
        label: 'Import',
        icon: 'UploadCloud',
      },
      export: {
        label: 'Export',
        icon: 'Download',
      },
    },
    onSelect: (key: string) => null,
  };
};

// use to build cache key.
export const keyStringify = (
  data: { [key: string]: any },
  preKey: string,
): { [key: string]: any } => {
  let newData: { [key: string]: any } = {};

  for (const i in data) {
    if (isObject(data[i])) {
      newData = { ...newData, ...keyStringify(data[i], i) };
    } else {
      newData[preKey ? `${preKey}.${i}` : i] = data[i];
    }
  }

  return newData;
};

/**
 *
 * @param queryValue object
 * format input: { queryKey: { value: string, operator: ':' | '=' } }
 * @returns query string
 */
export const generateFilterQuery = (queryValue: any): string => {
  let strQuery = '';
  //console.log('queryValue', queryValue);
  Object.keys(queryValue).map((_key: string) => {
    //multi condition or single condition
    if (queryValue[_key]?.criteria?.length > 0) {
      //multi condition
      if (queryValue[_key].criteria.length === 1) {
        //ONE: only AND
        const operator = queryValue[_key].criteria[0].operator;
        const value = queryValue[_key].criteria[0].value;
        const qryValue = Number.isInteger(value) ? value : `"${value}"`;
        strQuery += ` ${_key}${operator}${qryValue}`;
      } else {
        //MANY: check OR or AND
        strQuery += queryValue[_key].condition === 'AND' ? ' (' : ' {'; //start statement
        queryValue[_key].criteria.map((_ele: any, index: number) => {
          const operator = _ele?.operator;
          const value = _ele?.value;
          const qryValue = Number.isInteger(value) ? value : `"${value}"`;
          if (index > 0) strQuery += ' ';
          strQuery += ` ${_key}${operator}${qryValue}`;
        });
        strQuery += queryValue[_key].condition === 'AND' ? ')' : '}'; //end statement
      }
    } else {
      //single condition: AND
      const operator = queryValue[_key]?.operator || '=';
      const value = queryValue[_key]?.value || '';
      //&& value !== 'deletedAccount'
      if (value) {
        const qryValue = Number.isInteger(value) || isBoolean(value) ? value : `"${value}"`;
        strQuery += `${_key}${operator}${qryValue} `;
      }
    }
  });
  return strQuery;
};
