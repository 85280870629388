import LineLoader from './line-loader';

/* eslint-disable react/react-in-jsx-scope */
const SidebarMenuLoading = ({ rows }: any) => {
  return (
    <div className="d-flex flex-column w-100">
      {new Array(rows).fill(1).map((_ele: any, _index: number) => (
        <div key={_index} className="w-100 pd-b-20">
          <LineLoader />
          <div className="pd-l-20 pd-t-10">
            <LineLoader />
          </div>
          <div className="pd-l-20 pd-t-10">
            <LineLoader />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SidebarMenuLoading;
