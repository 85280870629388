import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

interface IListBodyProp {
  isSplitMode?: boolean;
  isScrollY?: boolean;
  split?: React.ReactNode;
  normal?: React.ReactNode;
  className?: string;
  styles?: any;
  children?: ReactNode;
}

const ListBody: FC<IListBodyProp> = ({
  isSplitMode,
  isScrollY = true,
  split,
  normal,
  children,
  className,
  styles,
}) => {
  return (
    <div
      className={classNames('list-body', className, { 'scroll-box': isScrollY })}
      style={styles ?? { maxHeight: 'calc(100vh - 175px)' }}
    >
      {isSplitMode ? <>{split}</> : <>{normal}</>}
      {children}
    </div>
  );
};

export default ListBody;
