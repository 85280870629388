import React from 'react';
import { Input } from '@base/components/form';
import classNames from 'classnames';
interface ITitleEditProps {
  value: string;
  onChange: (params: any) => {};
  errors: any;
}

const TitleEdit: React.FC<ITitleEditProps> = (props: ITitleEditProps) => {
  const { value, onChange, errors } = props;
  return (
    <div className={classNames('wd-100p')}>
      <div
        className={classNames({
          'is-required': errors?.isRequired,
        })}
      >
        <Input type="text" value={value} onChange={(data: any) => onChange(data)} />
      </div>
      {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
    </div>
  );
};

export default TitleEdit;
