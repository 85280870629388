import React from 'react';
import { default as TabsCommon } from '@base/components/tabs';
import Tab from './tab';
import _uniqueId from 'lodash/uniqueId';
import classNames from 'classnames';

const Tabs = ({ tabs = [], tab = {}, onChange = () => { }, className }: any) => {
  if (Array.isArray(tabs) && tabs.length == 1) {
    return null;
  }

  return (
    <TabsCommon
      className={classNames("bd-b-0 pd-15", className)}
      tabs={tabs}
      tab={tab}
      onChange={onChange}
      renderTab={(item: any, index?: any) => (
        <Tab
          key={index != undefined ? index : _uniqueId('tabs-')}
          {...item}
          active={item?.value === tab?.value}
          onChange={() => onChange(item)}
        />
      )}
    />
  );
};

export default Tabs;
