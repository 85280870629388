import React from 'react';

const txt = () => {
  return (
    <svg id="file-type-txt" xmlns="http://www.w3.org/2000/svg" width="42px" height="51px" viewBox="0 0 42 51">
      <g>
        <path fill="#B4CDE6" d="M42,49c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h32l8,8V49z" />
        <polygon fill="#99B9D4" points="42.003,14.828 34.615,7.44 42,8 	" />
        <g>
          <path fill="#C2D9F0" d="M42,8h-6c-1.104,0-2-0.896-2-2V0L42,8z" />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#B4CDE6"
            d="M42,43c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V43z
                    M42,30c0,0.553-0.447,1-1,1H20c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V30z M42,36
                    c0-0.553-0.447-1-1-1H20c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V36z"
          />
          <path
            fill="#B3CCE5"
            d="M41.73,42.73c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V42.73z M41.73,29.73c0,0.553-0.447,1-1,1h-21c-0.552,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V29.73z
                    M41.73,35.73c0-0.553-0.447-1-1-1h-21c-0.552,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V35.73z"
          />
          <path
            fill="#B2CCE5"
            d="M41.462,42.461c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V42.461z M41.462,29.461c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V29.461z
                    M41.462,35.461c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V35.461z"
          />
          <path
            fill="#B1CBE4"
            d="M41.192,42.192c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V42.192z M41.192,29.192c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V29.192z
                    M41.192,35.192c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V35.192z"
          />
          <path
            fill="#B0CAE4"
            d="M40.923,41.923c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V41.923z M40.923,28.923c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V28.923z
                    M40.923,34.923c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V34.923z"
          />
          <path
            fill="#AFCAE3"
            d="M40.654,41.654c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V41.654z M40.654,28.654c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V28.654z
                    M40.654,34.654c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V34.654z"
          />
          <path
            fill="#AEC9E2"
            d="M40.385,41.385c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V41.385z M40.385,28.385c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V28.385z
                    M40.385,34.385c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V34.385z"
          />
          <path
            fill="#ADC8E2"
            d="M40.115,41.115c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V41.115z M40.115,28.115c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V28.115z
                    M40.115,34.115c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V34.115z"
          />
          <path
            fill="#ACC7E1"
            d="M39.846,40.846c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V40.846z M39.846,27.846c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V27.846z
                    M39.846,33.846c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V33.846z"
          />
          <path
            fill="#ABC7E0"
            d="M39.577,40.577c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V40.577z M39.577,27.577c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V27.577z
                    M39.577,33.577c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V33.577z"
          />
          <path
            fill="#AAC6E0"
            d="M39.308,40.308c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V40.308z M39.308,27.308c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V27.308z
                    M39.308,33.308c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V33.308z"
          />
          <path
            fill="#A9C5DF"
            d="M39.038,40.039c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V40.039z M39.038,27.039c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V27.039z
                    M39.038,33.039c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V33.039z"
          />
          <path
            fill="#A8C5DF"
            d="M38.77,39.77c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V39.77z M38.77,26.77c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.448,1,1V26.77z
                    M38.77,32.77c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V32.77z"
          />
          <path
            fill="#A7C4DE"
            d="M38.5,39.5c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V39.5z M38.5,26.5c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V26.5z M38.5,32.5
                    c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V32.5z"
          />
          <path
            fill="#A7C3DD"
            d="M38.23,39.23c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V39.23z M38.23,26.23c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.552,0.447-1,1-1h21c0.553,0,1,0.447,1,1V26.23z
                    M38.23,32.23c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V32.23z"
          />
          <path
            fill="#A6C3DD"
            d="M37.962,38.961c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V38.961z M37.962,25.961c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V25.961z
                    M37.962,31.961c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V31.961z"
          />
          <path
            fill="#A5C2DC"
            d="M37.692,38.692c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V38.692z M37.692,25.692c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V25.692z
                    M37.692,31.692c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V31.692z"
          />
          <path
            fill="#A4C1DC"
            d="M37.423,38.423c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V38.423z M37.423,25.423c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V25.423z
                    M37.423,31.423c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V31.423z"
          />
          <path
            fill="#A3C1DB"
            d="M37.154,38.154c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V38.154z M37.154,25.154c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V25.154z
                    M37.154,31.154c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V31.154z"
          />
          <path
            fill="#A2C0DA"
            d="M36.885,37.885c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V37.885z M36.885,24.885c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V24.885z
                    M36.885,30.885c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V30.885z"
          />
          <path
            fill="#A1BFDA"
            d="M36.615,37.615c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V37.615z M36.615,24.615c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V24.615z
                    M36.615,30.615c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V30.615z"
          />
          <path
            fill="#A0BED9"
            d="M36.346,37.346c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V37.346z M36.346,24.346c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V24.346z
                    M36.346,30.346c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V30.346z"
          />
          <path
            fill="#9FBED8"
            d="M36.077,37.077c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V37.077z M36.077,24.077c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V24.077z
                    M36.077,30.077c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V30.077z"
          />
          <path
            fill="#9EBDD8"
            d="M35.808,36.808c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V36.808z M35.808,23.808c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V23.808z
                    M35.808,29.808c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V29.808z"
          />
          <path
            fill="#9DBCD7"
            d="M35.538,36.539c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V36.539z M35.538,23.539c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V23.539z
                    M35.538,29.539c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V29.539z"
          />
          <path
            fill="#9CBCD7"
            d="M35.27,36.27c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
                    V36.27z M35.27,23.269c0,0.553-0.447,1-1,1h-21c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V23.269z
                    M35.27,29.27c0-0.553-0.447-1-1-1h-21c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V29.27z"
          />
          <path
            fill="#9BBBD6"
            d="M35,36c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V36z
                    M35,23c0,0.553-0.447,1-1,1H13c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V23z M35,29
                    c0-0.553-0.447-1-1-1H13c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V29z"
          />
        </g>
        <path
          fill="#98B8D1"
          d="M35,23c0,0.553-0.447,1-1,1H13c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V23z
                M35,29c0-0.553-0.447-1-1-1H13c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V29z M35,36
                c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V36z"
        />
        <path
          fill="#FFFFFF"
          d="M34,22c0,0.553-0.447,1-1,1H12c-0.553,0-1-0.447-1-1v-1c0-0.553,0.447-1,1-1h21c0.553,0,1,0.447,1,1V22z
                M34,28c0-0.553-0.447-1-1-1H12c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1V28z M34,35
                c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v1c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1V35z"
        />
      </g>
      <circle fill="#FFFFFF" cx="6.141" cy="25" r="2.044" />
      <circle fill="#FFFFFF" cx="6.141" cy="12.648" r="2.044" />
      <circle fill="#FFFFFF" cx="6.141" cy="34.491" r="2.044" />
      <circle fill="#FFFFFF" cx="6.141" cy="43.759" r="2.044" />
      <g>
        <path fill="#FFFFFF" d="M15.604,6.875v3.908h-1.079V6.875h-1.491V5.881h4.062v0.994H15.604z" />
        <path
          fill="#FFFFFF"
          d="M21.68,10.783l-1.1-1.681l-1.106,1.681h-1.226l1.709-2.493l-1.639-2.409h1.26l1.03,1.59l1.036-1.59h1.227
                l-1.64,2.395l1.709,2.508H21.68z"
        />
        <path fill="#FFFFFF" d="M26.663,6.875v3.908h-1.079V6.875h-1.491V5.881h4.062v0.994H26.663z" />
      </g>
    </svg>
  );
};

export default txt;
