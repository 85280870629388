import React, { useEffect, useState } from 'react';
import Filter from '@base/components/han-header/filter';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_PIN_SUBMENU_CONFIG, UPDATE_USER_SETTING } from '@base/services/graphql/setting';
import { useRecoilState } from 'recoil';
import { pinSubMenuSettingsAtom } from '@base/recoil/atoms';
import { toast } from 'react-toastify';
interface IListFilterProps {
  listFilter: any;
  onChange: (param: any) => void;
  value: any;
  useNewFilter: boolean;
  menu: string;
}
const ListFilter: React.FC<IListFilterProps> = (props: IListFilterProps) => {
  const { listFilter, onChange, value, useNewFilter, menu } = props;

  const [pinSubMenuSettings, setPinSubMenuSettings] = useRecoilState(pinSubMenuSettingsAtom);

  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_USER_SETTING,
    'setting_updateUserSetting',
  );
  const defaultActive = pinSubMenuSettings?.[menu] ?? '';

  const [activeValue, setActiveValue] = useState(defaultActive);

  const handleActive = (nVal: string) => {
    const nSettings = {
      ...pinSubMenuSettings,
      [menu]: nVal,
    };
    const userSetting = {
      menu: 'common',
      key: 'pin_submenu',
      value: JSON.stringify(nSettings),
    };
    mUpdate.mutate(
      { userSetting },
      {
        onSuccess: (res: any) => {
          setPinSubMenuSettings(nSettings);
        },
      },
    );
  };

  useEffect(() => {
    if (pinSubMenuSettings) {
      const nActive = pinSubMenuSettings[menu] ?? '';
      setActiveValue(nActive);
    }
  }, [pinSubMenuSettings]);

  return (
    <>
      <Filter
        listFilter={listFilter}
        value={value}
        onChange={onChange}
        useNewFilter={useNewFilter}
        onActive={handleActive}
        activeValue={activeValue}
      />
    </>
  );
};

export default ListFilter;
