//import ReactDOM from 'react-dom'; //react17
import ReactDOM from 'react-dom/client'; //react18

export function convertComponentToHTML(tag: any, Component: any) {
  const $target = document.createElement(tag);
  //ReactDOM.render(Component, $target); //react17
  const root = ReactDOM.createRoot($target); //react18
  root.render(Component);
  return $target;
}
