import React, { memo, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import { ViewFields } from '@base/components/hook-form/view';
import { SpanLang } from '@base/components';
import { viewDataByMenuAtom } from '@base/recoil/atoms';

interface ISummaryProps {
  menuSource: string;
  menuSourceId?: string;
  ignoreFields?: string[];
  isCustomView?: boolean;
  onRefetch?: () => void;
}

const PagelayoutSummary: React.FC<ISummaryProps> = (props: ISummaryProps) => {
  const { menuSource, ignoreFields = [], isCustomView = false, onRefetch } = props;
  const viewData = useRecoilValue(viewDataByMenuAtom(menuSource));

  const [basicFields, setBasicField] = useState<any[]>([]);

  useEffect(() => {
    setBasicField(viewData?.layout?.data?.[0]?.children ?? []);
  }, [viewData]);

  return (
    <>
      <div
        className={classNames('view-aside-section', {
          'view-aside-section-full-scroll': !isCustomView,
        })}
      >
        <div className="view-aside-section-header">
          <span className="view-aside-section-header-title">
            <SpanLang keyLang="Summary" />
          </span>
        </div>
        <div
          className={classNames('view-aside-section-body scroll-box', {
            'view-aside-section-body-full-scroll': !isCustomView,
            // 'view-aside-section-body-full-scroll': ignoreFields.length === 0,
            // 'view-aside-section-body-part-scroll': ignoreFields.length > 0,
          })}
        >
          <div className="form-row mg-x-0 mg-t-10">
            <ViewFields
              fields={basicFields}
              ignoreFields={ignoreFields}
              menu={viewData?.menu ?? ''}
              menuSource={viewData?.menuSource ?? ''}
              menuSourceId={viewData?.menuSourceId ?? ''}
              onRefetch={onRefetch}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PagelayoutSummary;
