import { css } from '@emotion/react';
import { useCallback, useState } from 'react';
import TagItem from './item';

interface TagInputProps {
  value: string[];
  onChange?: (val: string[]) => void;
  onAdd?: (val: string) => void;
  onDelete?: (val: string) => void;
  mode?: 'view' | 'write';
}

const filterTag = (text: string) => {
  return text.replace(
    /[^0-9a-zA-Zㄱ-힣.\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf ]/g,
    ' ',
  );
};

function TagInput(props: TagInputProps) {
  const { value: tags, onChange, onAdd, onDelete, mode = 'write' } = props;
  const [inputTag, setInputTag] = useState('');

  const onChangeTag = (e: React.FormEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = e;
    setInputTag(value);
  };

  const onChangeTags = (tag: string | string[]) => {
    if (typeof tag == 'string') {
      onChange && onChange([...tags, tag]);
      onAdd && onAdd(tag);
    } else {
      onChange && onChange(tag);
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    if (['Enter', ','].indexOf(key) !== -1) {
      const addTag = filterTag(inputTag).trim();
      if (addTag === '') return;
      if (tags.indexOf(addTag) !== -1) return;
      if (addTag.length > 25) return;
      onChangeTags(addTag);
      setInputTag('');
      e.preventDefault();
    }

    if (mode == 'write') {
      if (key === 'Backspace' && !inputTag.length && tags.length) {
        e.preventDefault();
        const tagsCopy = [...tags];
        const poppedTag = tagsCopy.pop();
        onChangeTags(tagsCopy);
        poppedTag && setInputTag(poppedTag);
      }
    }
  };

  const deleteTag = useCallback(
    (tag: string) => {
      onChange && onChange(tags.filter((t) => t !== tag));
      onDelete && onDelete(tag);
    },
    [tags],
  );

  const renderWrite = () => {
    return (
      <div
        className="pd-x-10"
        css={css`
          border: 1px solid var(--input-border-color);
          border-radius: 0.25rem;
        `}
      >
        <div className="d-flex flex-wrap mg-t-5">
          {tags.map((tag, index) => (
            <TagItem key={index} tag={tag} deleteTag={deleteTag} />
          ))}
          <input
            type="text"
            className="form-control bd-0 wd-150"
            value={inputTag}
            onKeyUp={onKeyUp}
            onChange={onChangeTag}
          />
        </div>
      </div>
    );
  };

  const renderView = () => {
    return (
      <div
        className="mail-compose-body"
        css={css`
          padding: 0px !important;
        `}
      >
        <div className="d-flex flex-wrap">
          {tags.map((tag, index) => (
            <TagItem key={index} tag={tag} deleteTag={deleteTag} />
          ))}
        </div>
        <div className="pd-t-5">
          <input
            type="text"
            className="form-control tx-16"
            value={inputTag}
            onKeyUp={onKeyUp}
            onChange={onChangeTag}
          />
        </div>
      </div>
    );
  };

  return <>{mode == 'write' ? renderWrite() : renderView()}</>;
}

export default TagInput;
