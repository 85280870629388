import grapesjs from 'grapesjs';

import pluginLayout from './layout-block';
import pluginBasic from './basic-block';
import pluginForm from './form-block';
import pluginShortenUrl from './shorten-url-block';
import pluginFilter from './style-filter';
import pluginExport from './export';
import pluginImageEditor from './image-editor';

import panels from '../panels';
import commands from '../commands';
import styles from '../styles';
//import components from '../components';

export default grapesjs.plugins.add('gts-form-webpage', (editor: any, opts: {}) => {
  let config: any = opts;
  let defaults: any = {
    // Which blocks to add
    blocks: [],

    // Modal import title
    modalImportTitle: 'Import template',

    // Modal import button text
    modalImportButton: 'Import',

    // Import description inside import modal
    modalImportLabel: 'Paste all your code here below and click import',

    // Default content to setup on import model open.
    // Could also be a function with a dynamic content return (must be a string)
    // eg. modalImportContent: editor => editor.getHtml(),
    modalImportContent: '',

    modalExportTitle: 'Export template',
    modalExportLabel: 'Copy the code and use it wherever you want',

    // Code viewer (eg. CodeMirror) options
    importViewerOptions: {},

    // Confirm text before cleaning the canvas
    textClearTitle: 'Clear content',
    textCleanCanvas: 'Are you sure to clear all content? (You cannot undo for this action)',

    // Show the Style Manager on component change
    showStylesOnChange: 1,

    // Text for General sector in Style Manager
    textGeneral: 'General',

    // Text for Layout sector in Style Manager
    textLayout: 'Layout',

    // Text for Typography sector in Style Manager
    textTypography: 'Typography',

    // Text for Decorations sector in Style Manager
    textDecorations: 'Decorations',

    // Text for Extra sector in Style Manager
    textExtra: 'Extra',

    // Text for Extra sector in Style Manager
    textFlex: 'Flex',

    //panels option
    panelOpts: {
      cmdBtnDesktopLabel: 'Desktop',
      cmdBtnTabletLabel: 'Tablet',
      cmdBtnMobileLabel: 'Mobile',
      cmdBtnViewComLabel: 'View components',
      cmdBtnPreviewLabel: 'Preview',
      cmdBtnFullScreenLabel: 'Fullscreen',
      cmdBtnViewCodeLabel: 'View code',
      cmdBtnUndoLabel: 'Undo',
      cmdBtnRedoLabel: 'Redo',
      cmdBtnExportZipLabel: 'Export zip',
      cmdBtnImportLabel: 'Import template',
      cmdBtnClearLabel: 'Clear content',
    },

    // Use custom set of sectors for the Style Manager
    customStyleManager: [],

    // By setting this option to `false` will avoid loading the plugin
    blocksLayoutOpts: {},

    // By setting this option to `false` will avoid loading the plugin
    blocksBasicOpts: {
      templateType: config?.templateType
    },

    // By setting this option to `false` will avoid loading the plugin
    formsOpts: false,

    // By setting this option to `false` will avoid loading the plugin
    shortenUrlOpts: {},

    // By setting this option to `false` will avoid loading the plugin
    exportOpts: {},

    // By setting this option to `false` will avoid loading the plugin
    imgEditorOpts: {},
  };

  // Load defaults
  for (let name in defaults) {
    if (!(name in config)) config[name] = defaults[name];
  }

  const { blocksLayoutOpts, blocksBasicOpts, formsOpts, shortenUrlOpts, exportOpts, imgEditorOpts } = config;

  // Load plugins
  blocksLayoutOpts && config?.templateType !== 'basic' && config?.templateType !== 'simple' && pluginLayout(editor, blocksLayoutOpts);
  blocksBasicOpts && pluginBasic(editor, blocksBasicOpts);
  shortenUrlOpts && pluginShortenUrl(editor, shortenUrlOpts);
  // formsOpts && pluginForm(editor, formsOpts);
  pluginFilter(editor);
  exportOpts && pluginExport(editor, exportOpts);
  imgEditorOpts && pluginImageEditor(editor, imgEditorOpts);

  // Load commands
  commands(editor, config);

  // Load panels
  panels(editor, config);

  // Load styles
  styles(editor, config);
});
