import { BaseResponse } from '@base/types/interfaces/response';
import { graphQLApi, s3DownloadObjectApi } from '@base/utils/axios/graphql';
import {
  SETTING_CTA_CONTENT_TYPES,
  SETTING_CTA_TYPES,
} from '@settings/online-digital-content/cta/config/constants';
import { SETTING_CTA_LIST } from '@settings/online-digital-content/cta/services/graphql';

export default (editor: any, config: any) => {
  const domc = editor.DomComponents;
  const defaultType = domc.getType('link');
  const defaultModel = defaultType.model;
  const defaultView = defaultType.view;

  const btnStyle = {
    display: 'inline-block',
    'text-decoration': 'none',
    cursor: 'pointer',
    'font-weight': '400',
    width: '160px',
    color: 'white',
    'text-align': 'center',
    'vertical-align': 'middle',
    'user-select': 'none',
    'background-color': '#007bff', //'transparent'
    border: '1px solid transparent',
    padding: '0.2rem 0.6rem',
    'font-size': '1rem',
    'line-height': '1.5',
    'border-radius': '0.25rem',
    transition: `color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out`,
  };

  const linkStyle = {
    color: '#0168fa',
    'text-decoration': 'none',
    'background-color': 'transparent',
  };

  domc.addType('click-action', {
    model: defaultModel.extend(
      {
        defaults: {
          ...defaultModel.prototype.defaults,
          'custom-name': 'click-to-action',
          content: 'Click to Action',
          //attributes: { class: 'click-action' },
          style: btnStyle,
          // components: [
          //   {
          //     type: 'image',
          //     attributes: { src: 'https://path/image' },
          //   }
          // ],
          traits: [
            {
              type: 'select',
              name: 'ctas',
              label: 'Click to Action',
              options: [],
              changeProp: 1,
            },
            {
              type: 'text',
              name: 'name',
              label: 'Name',
              changeProp: 1,
            },
            {
              type: 'text',
              name: 'type',
              label: 'Type',
              changeProp: 1,
            },
            {
              type: 'text',
              name: 'href',
              label: 'Landing Page/Site',
              changeProp: 1,
            },
            {
              type: 'text',
              name: 'contentType',
              label: 'Content Type',
              changeProp: 1,
            },
            {
              type: 'text',
              name: 'pageTitle',
              label: 'Page Title',
              changeProp: 1,
            },
          ],
        },

        async init() {
          //fetch cta data
          const params = {
            keyword: '',
            sort: { field: 'createdAt', orderBy: 2 },
            paging: { page: 1, size: 20 },
          };
          const data = await graphQLApi<BaseResponse<any>>(
            'setting_ctas',
            SETTING_CTA_LIST,
            params,
          );
          //// console.log('cta data', data);
          //create options
          const newOptions = data?.results?.map((_ele: any) => ({
            ..._ele,
            value: _ele.id,
          }));
          //add options to cta
          let ctasTrait = this.get('traits').where({ name: 'ctas' })[0];
          ctasTrait.set('options', newOptions);
          //call api to get data
          this.on('change:ctas', this.handleCTAChange);
        },

        async handleCTAChange(el: any) {
          //el = this: this html element - cta button link
          //// console.log('all props > ', this.props());
          //// console.log('a prop value > ', this.get('ctas'));
          //// console.log('this.el > ', el.getAttributes());

          const ctasTrait = this.get('traits').where({ name: 'ctas' })[0];
          const ctaOptions = ctasTrait.get('options');
          //// console.log('ctasTrait options > ', ctasTrait.get('options'));
          const selectedOption = ctaOptions.find((_ele: any) => _ele.id === ctasTrait.get('value'));
          //// console.log('ctasTrait selectedOption > ', selectedOption);

          //set element content for traits
          //el.set({ content: selectedOption?.name || '' }); //this element content
          el.set({ name: selectedOption?.name || '' }); //element content of trait 'name'
          const selectedType = SETTING_CTA_TYPES.find(
            (_type: any) => _type.value === selectedOption?.type,
          );
          el.set({ type: selectedType?.label || '' });
          el.set({ href: selectedOption.linkUrl });
          const selectedContentType = SETTING_CTA_CONTENT_TYPES.find(
            (_type: any) => _type.value === selectedOption?.contentType,
          );
          el.set({ contentType: selectedContentType?.label || '' });
          el.set({ pageTitle: selectedOption.pageTitle });

          //change element attribute
          el.setAttributes({ href: selectedOption.linkUrl });

          //add child IMG element - TODO: checking type of cta: selectedOption.type
          switch (selectedOption?.type) {
            case 'SETTING_CTA_TYPE_TEXT': //button
              const firstEle = el.getChildAt(0); //remove image child element if any
              if (firstEle) {
                firstEle.remove();
              }
              el.setStyle({
                ...btnStyle,
                'background-color': selectedOption?.txtBgColor || '#007bff',
                color: selectedOption?.txtColor || 'white',
                'font-size': selectedOption?.txtFontSize || '1rem',
                'font-weight': selectedOption?.txtFontWeight + 'px' || '400',
                'border-radius': selectedOption?.txtRounded + 'px' || '0.25rem',
              });
              el.set({ content: selectedOption?.txtValue || '' }); //this element content - for code generated
              el.set({ value: selectedOption?.txtValue || '' }); //this element content in for editor
              break;
            case 'SETTING_CTA_TYPE_IMAGE':
              //get blob
              //const url = 'https://api.habin.io/v1/core/storage/object/rtdf94d7zfdwty5nbgyju54g5y';
              const imgSrc = await s3DownloadObjectApi<any>(selectedOption.imgUrl);
              //// console.log('imgSrc', imgSrc);
              el.setStyle({ ...btnStyle, 'background-color': 'transparent' });
              el.set({ content: '' });
              const imageEle = el.getChildAt(0); //remove if any
              if (imageEle) {
                imageEle.remove();
              }
              el.append({
                type: 'image',
                attributes: { src: imgSrc || '', alt: selectedOption?.imgAlt || '' },
                style: {
                  width: selectedOption?.imgSize?.width + 'px' || '160px',
                  height: selectedOption?.imgSize?.height + 'px' || '60px',
                },
              });
              break;
            case 'SETTING_CTA_TYPE_QRCODE':
              break;
            case 'SETTING_CTA_TYPE_URL':
              const childEle = el.getChildAt(0); //remove image child element if any
              if (childEle) {
                childEle.remove();
              }
              el.setStyle(linkStyle);
              el.set({ content: selectedOption?.name || '' }); //this element content - for code generated
              el.set({ value: selectedOption?.name || '' }); //this element content in for editor
              break;
            default:
              break;
          }
        },

        // toHTML() {
        //   const tagName = this.get('tagName');
        //   const price = this.get('defaultPrice');
        //   return `<${tagName}>${price}</{tagName}>`;
        // },
      },
      {
        isComponent(el: any) {
          // if (el.tagName == 'A' && el.hasAttribute('class="cta-button"')) {
          //   return { type: 'cta-button' };
          // }
          return false;
        },
      },
    ),
    //extendFnView: ['render'],
    view: defaultView.extend({
      // init() {
      //   this.listenTo(this.model, 'change:defaultPrice', this.render);
      // },
      onRender({ el }: any) {
        // const imgEl = document.createElement('img');
        // imgEl.setAttribute('src', 'http://bobbyhadz.com/images/blog/javascript-show-div-on-select-option/banner.webp');
        // imgEl.setAttribute('alt', 'aaa');
        // el.appendChild(imgEl);
      },
      // events: {
      //   click: function () {
      //     if (selecionado && selecionado == this.model) {
      //       selecionado = false;
      //       selecionadoB = false;
      //     } else {
      //       editor.select(this.model);
      //       selecionado = this.model;
      //       selecionadoB = 'link';
      //       let selected = editor.getSelected();
      //       let openSmBtn = editor.Panels.getButton('views', 'opcoesBloco');
      //       openSmBtn.set('active', 1);
      //     }
      //   },
      //   mouseenter: function () {
      //     if (!selecionado) editor.select(this.model);
      //   },
      //   mouseleave: function () {
      //     if (!selecionado) {
      //       editor.select(null);
      //     } else {
      //       editor.select(selecionado);
      //     }
      //   },
      // },
    }),
  });
};
