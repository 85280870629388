import { gql } from 'graphql-request';

export const GET_USER_IN_GROUP = gql`
  query q($groupId: String) {
    setting_groupUsers(groupId: $groupId) {
      results {
        id
        name
       
      }
    }
  }
`;

export const GET_USER_GROUP = gql`
  query q($filter: SearchFilter) {
    setting_groups(filter: $filter) {
      results {
        id
        name
        up
        order
      }
    }
  }
`;
