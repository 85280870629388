import { SpanLang } from '@base/components';
import React from 'react';
import { nanoid } from '@base/utils/helpers/general.utils';

interface ICheckboxViewProps {
  name?: string;
  value: boolean;
  label: string;
}
const CheckboxView: React.FC<ICheckboxViewProps> = (props: ICheckboxViewProps) => {
  const { value, label, name = nanoid() } = props;
  // console.log('checkbox', value, label);
  return (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        disabled={true}
        className="custom-control-input"
        // value={value}
        id={name}
        checked={value}
      />
      <label className="custom-control-label" htmlFor={name}>
        {label && <SpanLang keyLang={label} />}
      </label>
    </div>
  );
};

export default CheckboxView;
