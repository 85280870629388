import SelectTemplate from '@base/containers/select-template';
import { ETemplateGroup } from '@base/types/enums';
import React from 'react';

interface ISelectTemplateEditProps {
  templateGroup: ETemplateGroup;
  value: any;
  onChange: (nVal: any) => void;
}
const SelectTemplateEdit: React.FC<ISelectTemplateEditProps> = (
  props: ISelectTemplateEditProps,
) => {
  const { templateGroup, value, onChange } = props;
  return (
    <>
      <SelectTemplate templateGroup={templateGroup} value={value} onChange={onChange} />
    </>
  );
};

export default SelectTemplateEdit;
