import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Select } from '@base/components/form';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { useMenuSetting } from '@settings/general-setting/services/format-service';
import { PRIORITIES_CLASS } from '@base/config/constant';

import '@base/assets/scss/desktop/theme1/_desk.scss';

interface IPrioritySelectProps {
  className?: string;
  single?: boolean;
  isDisabled?: boolean;
  defaultOptions?: any[];
  value?: any;
  onChange?: (value: any) => void;
  placeholder?: string;
}

const PrioritySelect: React.FC<IPrioritySelectProps> = (props) => {
  const {
    className,
    single = true,
    isDisabled,
    defaultOptions = [],
    value, //={}
    onChange,
    placeholder = 'Select...',
  } = props;
  const { t } = useTranslation();
  //state
  const [options, setOptions] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>(null);

  //init rows
  useEffect(() => {
    if (single) {
      if (value) {
        if (_.isString(value)) {
          if (value !== selectedOption?.priority) {
            //find in options
            const fOption = options.find((_ele: any) => _ele.priority === value);
            if (fOption) {
              setSelectedOption(fOption);
            }
          }
        } else {
          if (value?.priority !== selectedOption?.priority) {
            setSelectedOption(value);
          }
        }
      } else {
        setSelectedOption(null);
      }
    } else {
      if (value) {
        if (Array.isArray(value) && value.length > 0) {
          //2 cases: value=string[] or object[] + selectedOption=object[]
          let isArrayString = _.isString(value[0]);
          if (isArrayString) {
            const selectedIds = selectedOption?.map((_ele: any) => _ele.priority);
            if (JSON.stringify(value) !== JSON.stringify(selectedIds)) {
              const newValue: any = [];
              value.map((_item: string) => {
                //find in options
                const fOption = options.find((_ele: any) => _ele.priority === _item);
                if (fOption) {
                  newValue.push(fOption);
                }
              });
              setSelectedOption(newValue);
            }
          } else {
            //array object
            if (JSON.stringify(value) !== JSON.stringify(selectedOption)) {
              setSelectedOption(value);
            }
          }
        } else {
          setSelectedOption([]);
        }
      } else {
        setSelectedOption(null);
      }
    }
  }, [value]); //options

  // ============= HOOK get field options ======================
  const defaultParams = {
    menu: 'desk',
    key: 'priority',
  };
  const { data, isLoading } = useMenuSetting(defaultParams);

  //init states list
  useEffect(() => {
    if (data) {
      try {
        let newOptions: any[] = JSON.parse(data.value);
        // console.log(newOptions);
        setOptions(newOptions);
      } catch (e) {
        // console.log('error parse json');
      }
    }
  }, [data]);

  useEffect(() => {
    const curOptions = options.map((_ele: any) => _ele.priority);
    if (defaultOptions?.length > 0) {
      if (JSON.stringify(curOptions) !== JSON.stringify(defaultOptions)) {
        let newOptions = options.filter(
          (_ele: any) =>
            defaultOptions.findIndex((_opt: any) => _opt.priority === _ele.priority) > -1,
        );
        setOptions(newOptions);
      }
    }
  }, [defaultOptions]);

  // ============= END get field options ======================

  //option change
  const handleValueChange = (newValue: any) => {
    setSelectedOption(newValue);
    //callback
    onChange && onChange(newValue);
  };

  //selected options
  const CustomMultiValueLabel = ({ children, ...props }: any) => {
    const { data } = props;
    return (
      <components.MultiValueLabel {...props}>
        <span className={`badge badge-priority priority-${PRIORITIES_CLASS[data.priority]}`}>
          {children}
        </span>
      </components.MultiValueLabel>
    );
  };

  //single custom option
  const CustomSingleValueLabel = ({ children, ...props }: any) => {
    const { data } = props;
    return (
      <components.SingleValue {...props}>
        <span className={`badge badge-priority priority-${PRIORITIES_CLASS[data.priority]}`}>
          {children}
        </span>
      </components.SingleValue>
    );
  };

  //select custom option
  const CustomOption = ({ children, ...props }: any) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <span className={`badge badge-priority priority-${PRIORITIES_CLASS[data.priority]}`}>
          {children}
        </span>
      </components.Option>
    );
  };

  //render
  return (
    <div className={classNames('pos-relative', className)}>
      <Select
        // outSide={true}
        isSearchable={false}
        isMulti={!single}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable
        components={{
          Option: CustomOption,
          MultiValueLabel: CustomMultiValueLabel,
          SingleValue: CustomSingleValueLabel,
        }}
        options={options}
        getOptionLabel={(opt: any) => t(opt.languageKey)}
        getOptionValue={(opt: any) => opt.priority}
        value={selectedOption}
        onChange={handleValueChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default PrioritySelect;
