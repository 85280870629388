import Avatar from '@base/components/avatar';
import React from 'react';
import { ChevronDown } from 'react-feather';

interface IListTableCellDroplist {
  values: any[];
  className?: string;
  showAvatar?: boolean;
  cellComponent?: any;
}

const ListTableCellDroplist: React.FC<IListTableCellDroplist> = (props) => {
  const { values, className, showAvatar = false, cellComponent } = props;

  if (!values?.length) {
    return <div />;
  }

  return (
    <div className="dropdown cell-droplist">
      <button
        type="button"
        className={['btn', className ?? ''].join(' ')}
        data-toggle={values?.length > 1 ? 'dropdown' : ''}
      >
        <div className="d-flex align-items-center">
          {showAvatar ? (
            <span className="d-flex align-items-center mg-r-5">
              <Avatar url={values[0]?.photo} alt={values[0]?.name} height={26} width={26} />
              {/* <span className="mg-l-5">{values[0]?.name}</span> */}
              {cellComponent ? (
                cellComponent(values[0])
              ) : (
                <span className="mg-l-5">{values[0]?.name}</span>
              )}
            </span>
          ) : (
            // <span className="mg-r-5">{values[0]?.name || '-No name-'}</span>
            <>
              {cellComponent ? (
                cellComponent(values[0])
              ) : (
                <span className="mg-l-5">{values[0]?.name}</span>
              )}
            </>
          )}
          {values.length > 1 && (
            <>
              <span className="badge badge-secondary mg-r-5 mg-l-5">+ {values.length - 1}</span>
              <ChevronDown className="cursor-pointer" />
            </>
          )}
        </div>
      </button>

      {!!values.length && values.length > 1 && (
        <div className="dropdown-menu pd-0">
          {values.map((value: any, i: number) => (
            <div key={i} className="dropdown-item-list">
              {showAvatar ? (
                <span className="d-flex align-items-center mg-r-5">
                  <Avatar url={value?.photo} alt={value?.name} height={26} width={26} />
                  {/* <span className="mg-l-5">{value?.name}</span> */}
                  {cellComponent ? (
                    cellComponent(value)
                  ) : (
                    <span className="mg-l-5">{value?.name}</span>
                  )}
                </span>
              ) : (
                // <span>{value.name || '-No name-'}</span>
                <>
                  {cellComponent ? (
                    cellComponent(value)
                  ) : (
                    <span className="mg-l-5">{value?.name}</span>
                  )}
                </>
              )}
            </div>
          ))}
          <div className="dropdown-footer">Total: {values.length}</div>
        </div>
      )}
    </div>
  );
};

export default ListTableCellDroplist;
