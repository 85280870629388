import { ITemplate } from '@base/types/interfaces/setting';
import { content } from 'html2canvas/dist/types/css/property-descriptors/content';

export interface IBaseTemplateData {
  html: string;
  id: string;
  name: string;
}
function parserKnowledgeBaseTemplate(template: ITemplate): IBaseTemplateData | null {
  let result: IBaseTemplateData = {
    id: template.id,
    name: template.name,
    html: template.html ?? '',
  };
  return result;
}

export function parserTemplateProperties(template: ITemplate, templateGroup: string): any {
  let result = null;
  switch (templateGroup) {
    case 'knowledgebase':
      result = parserKnowledgeBaseTemplate(template);
      break;
    default:
      result = template;
      break;
  }
  return result;
}
