import React, { useEffect, useState } from 'react';
import { Select } from '@base/components/form';
import { useRecoilValue } from 'recoil';
import { currenciesAtom } from '@base/recoil/atoms';
import { defaultCurrencySelector } from '@base/recoil/selectors';

/**
 * @param {*} props
 * @returns
 */
const CurrencySelect: React.FC<any> = (props: any) => {
  const { className, showSymbol = false, value, onChange, ...rest } = props;

  //state
  const [options, setOptions] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const currencies: any = useRecoilValue(currenciesAtom);
  const defaultCurrency: any = useRecoilValue(defaultCurrencySelector);

  //init selected value
  useEffect(() => {
    if (value) {
      if (value?.code !== selectedValue?.code) {
        setSelectedValue(value);
      }
    } else {
      // setSelectedValue({
      //   ...defaultCurrency,
      //   label: showSymbol ? defaultCurrency.currencySymbol : defaultCurrency.currencyName,
      //   value: defaultCurrency.code,
      // });
      setSelectedValue(null);
    }
  }, [value]);

  //init options
  useEffect(() => {
    if (currencies && currencies.length > 0) {
      let newOptions: any = [];
      currencies.map((_item: any) => {
        newOptions.push({
          ..._item,
          label: showSymbol ? _item.currencySymbol : _item.currencyName,
          value: _item.code,
        });
      });
      setOptions(newOptions);
    }
  }, [currencies]);

  const handleValueChange = (newValue: any) => {
    setSelectedValue(newValue);
    //callback
    onChange && onChange(newValue);
  };

  //render
  return (
    <Select
      isSearchable={false}
      className={className}
      options={options}
      value={selectedValue}
      onChange={handleValueChange}
      {...rest}
    />
  );
};

export default CurrencySelect;
