import classNames from "classnames";
import { ReactNode, useMemo, useState } from "react";
import { FormIcon } from "../form";

import './main.scss';

interface DomEditorProps {
  className?: string;
  children?: ReactNode;
  onPreview?: (val: boolean) => void;
}

const DEVICE_OPTIONS = [
  { label: 'Desktop', value: 'desktop', icon: 'Monitor', height: 26, width: 26 },
  { label: 'Tablet', value: 'tablet', icon: 'Tablet', height: 26, width: 23 },
  { label: 'Mobile', value: 'mobile', icon: 'Smartphone', height: 26, width: 20 },
];

const DomEditor = (props: DomEditorProps) => {
  const {
    className = '',
    children,
    onPreview
  } = props;
  //state
  const [activeDevice, setActiveDevice] = useState('desktop');
  const [activePreview, setActivePreview] = useState(false);

  const handlePreview = () => {
    setActivePreview(!activePreview);
    //callback
    onPreview && onPreview(!activePreview);
  }

  //toolbar buttons
  const Toolbar = useMemo(() => {
    return (
      <div className="d-flex">
        <div
          className="d-flex w-100 justify-content-between align-items-center"
          style={{ color: "#6c757d", backgroundColor: "#fcfdff", boxShadow: "0 0 5px rgb(0 0 0 / 20%)" }}
        >
          <div className="d-flex mg-l-5">
            {DEVICE_OPTIONS.map((_item: any, index: number) => (
              <div
                key={index}
                className={classNames("pd-3 mg-3 han-tooltip--bottom", { "dom-active": _item.value === activeDevice })}
                data-han-tooltip={_item.label}
                onClick={() => setActiveDevice(_item.value)}
              >
                <FormIcon icon={_item.icon} attrs={{ height: _item.height, width: _item.width }} />
              </div>
            ))}
          </div>
          <div className="d-flex mg-r-5">
            <div
              className={classNames("pd-4 mg-3 han-tooltip--bottom", { "dom-active": activePreview })}
              data-han-tooltip={'Preview'}
              onClick={handlePreview}
            >
              <FormIcon icon={'Eye'} attrs={{ height: 24, width: 24 }} />
            </div>
          </div>
        </div>
      </div>
    );
  }, [activeDevice, activePreview]);

  //render
  return (
    <div className={classNames("pos-relative", className)}>
      {!activePreview && Toolbar}
      <div className="dom-content-wrap" style={{ minHeight: "500px" }}>
        {activePreview &&
          <div className="pos-absolute t-5 l-5 z-index-50" style={{ color: "#6c757d" }} onClick={handlePreview}>
            <FormIcon icon={'EyeOff'} attrs={{ height: 20, width: 20 }} className="tx-gray" />
          </div>
        }
        <div className={classNames("dom-content-frame", {
          'dom-desktop-view': activeDevice === 'desktop',
          'dom-tablet-view': activeDevice === 'tablet',
          'dom-mobile-view': activeDevice === 'mobile'
        })}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default DomEditor;