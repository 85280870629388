import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Flag from 'react-world-flags';

interface ILangInputProps {
  uid: string;
  disabled: boolean;
  className?: string;
  value: any;
  onChange: (params: any) => void;
}
const LangInput: React.FC<ILangInputProps> = (props: ILangInputProps) => {
  const {
    uid, //unique
    disabled,
    className,
    value, //[{id: 'vn', value: ''}]
    onChange,
  } = props;
  const TAB_CONFIG = [
    { key: 'en' + uid, label: 'English', value: 'en', icon: 'gb', other: false },
    { key: 'ko' + uid, label: 'Korean', value: 'ko', icon: 'kr', other: false },
    { key: 'vi' + uid, label: 'Vietnamese', value: 'vi', icon: 'vn', other: false },
    { key: 'jp' + uid, label: 'Japanese', value: 'jp', icon: 'jp', other: true },
    { key: 'zh' + uid, label: 'Chinese', value: 'zh', icon: 'cn', other: true },
    { key: 'ido' + uid, label: 'Indonesian', value: 'ido', icon: 'id', other: true },
  ];

  //state
  const [isLoading, setIsLoading] = useState(false);
  const [langTabs, setLangTabs] = useState(TAB_CONFIG);
  const [activeTab, setActiveTab] = useState('en' + uid);
  const [data, setData] = useState<any>([]); //return [{id: 'vn', value: ''}, {...}]

  //get data
  useEffect(() => {
    // setIsLoading(true);
    //get lang
    // request(GET_GENERAL_SETTING_LANGUAGES, null).then((res) => {
    //   const { results } = res?.usedLanguages;
    //   if (results) {
    //     //set lang tabs
    //     const newLangTabs = [...langTabs];
    //     results.map((row, idx) => {
    //       if (row.code !== 'en' && row.code !== 'ko' && row.code !== 'vi') {
    //         const newTab = {
    //           key: row.code + uid,
    //           label: row.title,
    //           value: row.code,
    //           icon: getFlagIcon(row.code),
    //           other: true,
    //         };
    //         newLangTabs.push(newTab);
    //       }
    //     });
    //     setLangTabs(newLangTabs);
    //   }
    //   setIsLoading(false);
    // });
  }, []);

  //reset data
  useEffect(() => {
    if (value) {
      setData([...value]);
    }
  }, [value]);

  //const render icon
  const getFlagIcon = (code: string) => {
    switch (code) {
      case 'zh':
        return 'cn';
      case 'ja':
        return 'jp';
      default:
        return code;
    }
  };

  //value change
  const handleChange = (e: any, id: string) => {
    let newData = data ? [...data] : [];
    //check exist
    const idx = newData.findIndex((d) => d.id === id);
    if (idx > -1) {
      newData[idx].value = e.target.value;
    } else {
      const newItem = {
        id,
        value: e.target.value,
      };
      newData.push(newItem);
    }
    setData(newData);
    //return
    onChange && onChange(newData);
  };

  //render - basic tab
  return (
    <div className="pos-relative">
      {/* tab header */}
      <ul className="nav nav-tabs mg-t-10" id="myLangTab" role="tablist">
        {/* main tabs */}
        {langTabs.map(
          (item, index) =>
            !item.other && (
              <li className="nav-item" key={index}>
                <a
                  className={classNames('nav-link', { active: index == 0 })}
                  data-toggle="tab"
                  href={`#langtab${item.key}`}
                  role="tab"
                  aria-selected="true"
                  onClick={() => setActiveTab(item.key)}
                >
                  {item.icon && <Flag code={item.icon} className="wd-20 mg-r-3" />}
                  {item.label}
                </a>
              </li>
            ),
        )}
        {/* other tabs */}
        <li className="nav-item">
          <a
            href={
              langTabs.findIndex((e) => e.other && e.key === activeTab) > -1
                ? `#langtab${
                    langTabs[langTabs.findIndex((e) => e.other && e.key === activeTab)]?.key
                  }`
                : '#langtabothers'
            }
            className={classNames('nav-link show dropdown')}
            role="tab"
            data-toggle="tab"
          >
            <span
              className="dropdown-toggle"
              id="ddmOther"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {langTabs.findIndex((e) => e.other && e.key === activeTab) > -1
                ? langTabs[langTabs.findIndex((e) => e.other && e.key === activeTab)]?.label
                : 'Others'}
            </span>
            <div className="dropdown-menu" aria-labelledby="ddmOther">
              {!isLoading &&
                langTabs.map((item) => {
                  return (
                    item.other && (
                      <div
                        className="dropdown-item"
                        onClick={() => setActiveTab(item.key)}
                        key={item.key}
                      >
                        {item.icon && <Flag code={item.icon} className="wd-20 mg-r-3" />}
                        <span>{item.label}</span>
                      </div>
                    )
                  );
                })}
              {isLoading && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: '50px' }}
                >
                  <span
                    className="spinner-border spinner-border-sm tx-gray-300"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
          </a>
        </li>
      </ul>
      {/* tab content */}
      <div className="tab-content bd bd-gray-300 bd-t-0 pd-20">
        {langTabs.map((item) => {
          let fIdx = data.findIndex((d: any) => d.id === item.value);
          let langValue = fIdx != -1 ? data[fIdx].value : '';
          return (
            <div
              key={item.key}
              id={`langtab${item.key}`}
              className={classNames('tab-pane fade', { 'show active': activeTab === item.key })}
            >
              <input
                type="text"
                className="form-control ht-38"
                placeholder={item.label}
                disabled={disabled}
                value={langValue}
                onChange={(e) => handleChange(e, item.value)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LangInput;
