import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { X } from 'react-feather';
import { confirmAlert, SpanLang, NoData } from '@base/components';
import ProductAutoComplete from '@product/product/containers/product-auto-complete';
import Loading from '@base/components/loading';
import { ProductTextView } from '../quick-view';

interface AssignProductContainerProps {
  placement?: string;
  items: any;
  isLoading?: boolean;
  onDelete?: any;
  onAssign?: any;
}

/**
 *
 * @param {*} props
 * @returns
 */
const AssignProductContainer = (props: AssignProductContainerProps) => {
  const { placement = 'right', items, isLoading, onDelete, onAssign } = props;

  //lang
  const { t } = useTranslation();

  //state
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deletedItem, setDeletedItem] = useState<any>(null);

  //delete confirm
  const handleDelete = (item: any) => {
    setIsDeleting(true);
    setDeletedItem(item);
    onDelete(item, () => {
      setDeletedItem(null);
      setIsDeleting(false);
    });
  };

  //render items
  const renderItemInfo = (item: any) => {
    return (
      <div key={item?.id} className={classNames('d-flex align-items-center mg-b-3 pd-x-10 pd-y-3')}>
        <div className="media align-items-center flex-grow-1">
          <div className="media-body mg-l-5 text-truncate">
            {/* {item?.name ?? ''} */}
            <ProductTextView value={{ id: item?.id, name: item?.name }} />
          </div>
        </div>
        <div className="flex-shrink-0">
          <button
            type="button"
            className="btn btn-link btn-icon han-tooltip--top"
            data-han-tooltip="Delete"
            onClick={() => handleDelete(item)}
          >
            {isDeleting && deletedItem?.id === item.id ? (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            ) : (
              <X className="tx-danger" />
            )}
          </button>
        </div>
      </div>
    );
  };

  //render
  return (
    <>
      <div className="pos-relative mg-b-10">
        {(items.length == 0 || isLoading) && <NoData icon={'Package'} iconType={'feather'} />}
        {isLoading ? <Loading /> : items.map((_item: any) => _item && renderItemInfo(_item))}
      </div>
      <ProductAutoComplete showAllOption={false} single={true} onChange={onAssign} value={[]} />
    </>
  );
};

export default AssignProductContainer;
