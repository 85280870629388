import { atom, atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';
import { IDevice, MenuData } from '@base/types/interfaces/app';
import { COLORS, DESC } from '@base/config/constant';
import { ListType } from '@base/types/enums';
import { ICountry } from '@base/types/interfaces/setting';
import {
  MENU_ACTIVITY,
  MENU_CUSTOMER,
  MENU_DESK,
  MENU_DESK_TICKET,
  MENU_INVENTORY,
  MENU_MYWORK,
} from '@base/config/menus';

export const deviceAtom = atom<IDevice>({
  key: 'deviceAtom',
  default: {
    device: 'desktop',
    layout: 'layout1',
    isMobile: false,
    isTablet: false,
    isDesktop: true,
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    skin: 'light',
    headerColor: COLORS[0].value,
    language: 'en',
    enableTrans: false,
  } as IDevice,
});

export const countriesAtom = atom<ICountry[]>({
  key: 'usedCountriesAtom',
  default: [],
});

export const statesAtom = atom<any | null>({
  key: 'statesAtom',
  default: {},
});

export const citiesAtom = atom<any | null>({
  key: 'citiesAtom',
  default: {},
});

export const currenciesAtom = atom<any[]>({
  key: 'currenciesAtom',
  default: [],
});

export const languagesAtom = atom<any[]>({
  key: 'languagesAtom',
  default: [],
});

export const phonesAtom = atom<any[]>({
  key: 'phonesAtom',
  default: [],
});

export const defaultPhoneAtom = atom<any | null>({
  key: 'defaultPhoneAtom',
  default: null,
});

// export const pageDataByMenuAtom = (menu: string) =>
//   atom<MenuData>({
//     key: `page${menu}MenuDataAtom`,
//     default: {
//       isSplitMode: true,
//       listType: ListType.LIST,
//       filter: {
//         baseFilters: {},
//         headerFilters: {},
//         searchFilters: {},
//         keyword: '',
//         paging: {
//           page: 1,
//           size: 15,
//         },
//         sort: {
//           field: 'createdAt',
//           orderBy: DESC,
//         },
//       },
//     },
//   });

export const pageDataByMenuAtom = atomFamily<MenuData, string>({
  key: 'pageMenuDataAtom',
  default: (param) => ({
    isSplitMode: false,
    listType: defaultListType(param),
    filter: {
      baseFilters: {},
      headerFilters: {
        groupBy: defaultFilter(param),
      },
      searchFilters: {},
      keyword: '',
      paging: {
        page: 1,
        size: 10,
      },
      sort: {
        field: 'createdAt',
        orderBy: DESC,
      },
      query: '',
    },
    settingColumns: [],
  }),
});

function defaultListType(param: string) {
  //// console.log('defaultFilter', param);
  if (param == MENU_MYWORK) {
    return ListType.KANBAN;
  }
  // else if (param == MENU_DESK_TICKET) {
  //   return 'allTickets';
  // }
  return ListType.LIST;
}

function defaultFilter(param: string) {
  //// console.log('defaultFilter', param);
  if (param == MENU_ACTIVITY) {
    return 'all';
  } else if (param == MENU_MYWORK) {
    return 'my';
  }
  // else if (param == MENU_DESK_TICKET) {
  //   return 'allTickets';
  // }
  return 'all';
}

export const formatSettingsAtom = atom<any | null>({
  key: 'formatSettingsAtom',
  default: [],
});

export const modalSizeAtom = atom({
  key: 'modalSizeAtom',
  default: 'wd-600',
});

export const useModalSizeAtomState = () => {
  return useSetRecoilState(modalSizeAtom);
};

export const useModalSizeAtomValue = () => {
  return useRecoilValue(modalSizeAtom);
};

export const pinSubMenuSettingsAtom = atom<any | null>({
  key: 'pinSubMenuSettingsAtom',
  default: null,
});

export const listPageSettingsAtom = atom<any | null>({
  key: 'listPageSettingsAtom',
  default: null,
});

export const listFavoriteAtom = atom<any | null>({
  key: 'listFavoriteAtom',
  default: [],
});
