import React, { useState, useEffect, useRef } from 'react';
import CodeGeneratorModal from './code-generator-modal';
import CodeGeneratorCanvas from './code-generator-canvas';
import CodeGeneratorPopover from './code-generator-popover';
import { Settings } from 'react-feather';
import { Input } from '@base/components/form';
import { GET_NEXT_ID, GET_PUBLIC_NEXT_ID } from './query';
import usePost from '@base/hooks/usePost';
import usePublicPost from '@base/hooks/usePublicPost';

interface InputCodeGeneratorProps {
  value: string;
  menu: string;
  showType?: string;
  onChange: any;
  onSettingChange?: any;
  index?: number;
  isPublic?: boolean;
  token?: string;
}

interface CodeResponse {
  code: string;
  setting: any;
}

//id: for re-init code if id changes
//waste: for generating many
//value: set default value or change
const InputCodeGenerator: React.FC<InputCodeGeneratorProps> = (props: InputCodeGeneratorProps) => {
  const {
    value, //initial code
    menu,
    showType, //'popover' or 'modal' or 'canvas'
    onChange,
    onSettingChange,
    index = 0,
    isPublic = false,
    token = ''
  } = props;

  //state
  const [showSetting, setShowSetting] = useState(false);
  const [productCode, setProductCode] = useState<string>(value ?? '');
  const [isDisable, setIsDisable] = useState(value ? true : false);
  const [productCodeSetting, setProductCodeSetting] = useState(null);
  const ref = useRef(null); //for popover
  const [target, setTarget] = useState(null); //for popover

  //get next id
  const useGetNextId = (menu: string, index: number, value: string, isPublic: boolean, token: string) => {
    let response = null;
    if (isPublic) {
      response = usePublicPost<CodeResponse>(
        ['tracking_nextId', menu, index],
        GET_PUBLIC_NEXT_ID,
        { menu, token },
        { enabled: value === '' }
      );
    } else {
      response = usePost<CodeResponse>(
        ['setting_nextId', menu, index],
        GET_NEXT_ID,
        { menu },
        { enabled: value === '' }
      );
    }
    return response;
  }

  //get next code
  const { data: codeData, isLoading, refetch } = useGetNextId(menu, index, value, isPublic, token);

  //set new code
  useEffect(() => {
    if (value) {
      if (value !== productCode) {
        setProductCode(value);
      }
    } else {
      setProductCode('');
      // call mutation again
      // refetch();
    }

    return () => { };
  }, [value]);

  //get new setting when an id changes
  useEffect(() => {
    if (codeData) {
      const newSetting = codeData.setting || { autoGenerate: false };
      setProductCodeSetting(newSetting);
      if (newSetting.autoGenerate) {
        setIsDisable(true);
        setProductCode(codeData?.code);
        //get next ID
        onChange && onChange(codeData?.code);
      } else {
        setIsDisable(false);
        setProductCode('');
        onChange && onChange('');
      }
    }
  }, [codeData]);

  //refetch new code
  const refetchCode = () => {
    refetch();
  };

  //manually input value
  const handleChange = (value: string) => {
    setProductCode(value);
    //callback
    onChange && onChange(value);
  };

  //main render
  return (
    <div className="pos-relative" ref={ref}>
      <Input
        type="text"
        value={productCode}
        disabled={isDisable}
        rightIcon={<Settings className="wd-20" />}
        isLoading={isLoading && productCode === ''}
        onChange={handleChange}
        onRightIconClick={(e: any) => {
          setShowSetting(!showSetting);
          setTarget(e.target);
        }}
      />
      {/* onRightIconClick={() => !userAdditional?.enable_trans && setShowSetting(true)} */}

      {/* show modal */}
      {showSetting && showType === 'modal' && (
        <CodeGeneratorModal
          show={showSetting}
          defaultProps={productCodeSetting}
          menu={menu}
          onHide={() => setShowSetting(false)}
          saveCb={refetchCode}
          onSettingChange={onSettingChange}
        />
      )}

      {/* show canvas */}
      {showSetting && showType === 'canvas' && (
        <CodeGeneratorCanvas
          show={showSetting}
          onHide={() => setShowSetting(false)}
          menu={menu}
          defaultProps={productCodeSetting}
          saveCb={refetchCode}
          onSettingChange={onSettingChange}
        />
      )}

      {/* show popover */}
      {showSetting && showType === 'popover' && (
        <CodeGeneratorPopover
          containerRef={ref}
          target={target}
          show={showSetting}
          onHide={() => setShowSetting(false)}
          menu={menu}
          defaultProps={productCodeSetting}
          saveCb={refetchCode}
          onSettingChange={onSettingChange}
        />
      )}
    </div>
  );
};

InputCodeGenerator.defaultProps = {
  value: '', //initial code
  menu: '',
  showType: 'canvas', //'popover' or 'modal' or 'canvas'
  //onChange: () => { },
  //onSettingChange: () => { },
};

export default InputCodeGenerator;
