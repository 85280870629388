import { useEffect, useRef } from 'react';

export default function useFirstUpdate(fn: any, inputs: any, times = 1) {
  const firstUpdate = useRef(times);
  useEffect(() => {
    if (firstUpdate.current === 0) {
      fn();
      return;
    }

    firstUpdate.current--;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
}
