//import React, { memo } from "react";
import { Database } from 'react-feather';
import classNames from 'classnames';
import SpanLang from '@base/components/form/span-lang';
import './styles.scss';

interface EmptyProps {
  text?: string;
  icon?: any;
  className?: string;
}

const Empty = ({ text, icon, className, ...rest }: EmptyProps) => {
  return (
    <div className={classNames('no-data-container tx-color-04', className)} {...rest}>
      {icon}
      <p className="mg-y-8">{text}</p>
    </div>
  );
};

Empty.defaultProps = {
  text: <SpanLang keyLang={'crm_new_no_data'} />,
  icon: <Database />,
};

export default Empty;
