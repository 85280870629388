import React, { forwardRef } from 'react';
import { Calendar } from 'react-feather';
import Datepicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Placement } from '@popperjs/core';
import { ko, enUS as en, zhCN as cn, ja as jp, vi } from 'date-fns/esm/locale';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';

interface IDatePickerProps {
  placement?: Placement;
  disabled?: boolean;
  selected: Date | null;
  onChange: (date: Date) => void;
  showTimeInput?: boolean;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  startDate?: Date;
  endDate?: Date;
  dateFormat?: string;
  minDate?: Date;
}

const customHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const mToday = dayjs(date);
  const years = _.range(1990, mToday.year() + 50, 1).reverse();
  return (
    <div className="rdrMonthAndYearWrapper">
      <button
        type="button"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        className="rdrNextPrevButton rdrPprevButton"
      >
        <i></i>
      </button>
      <span className="rdrMonthAndYearPickers">
        <span className="rdrMonthPicker">
          <select
            value={months[mToday.month()]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </span>
        <span className="rdrYearPicker">
          <select
            value={mToday.year()}
            onChange={({ target: { value } }) => changeYear(Number(value))}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </span>
      </span>

      <button
        type="button"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        className="rdrNextPrevButton rdrNextButton"
      >
        <i></i>
      </button>
    </div>
  );
};

export const DatePicker = ({
  placement,
  selected,
  onChange,
  selectsEnd,
  selectsStart,
  startDate,
  endDate,
  minDate,
  disabled,
  showTimeInput,
  dateFormat,
}: IDatePickerProps) => {
  const CustomInput = forwardRef<HTMLInputElement>((props, ref) => (
    <div className="input-group input-date-group dropdown">
      <input disabled type="text" className="form-control" ref={ref} {...props} />
      <div className="input-group-prepend">
        <span className="input-group-text bg-white tx-gray-300">
          <Calendar />
        </span>
      </div>
    </div>
  ));
  CustomInput.displayName = 'ReactDatePickerInput';

  const renderDayContents = (day: number, date: Date) => {
    return (
      <span className="custom_date_picker_day">
        <span className="custom_date_picker_day_content">{day}</span>
      </span>
    );
  };

  return (
    <Datepicker
      locale={ko}
      customInput={<CustomInput />}
      className="form-control"
      popperPlacement={placement}
      renderCustomHeader={customHeader}
      useWeekdaysShort={true}
      renderDayContents={renderDayContents}
      selected={selected}
      onChange={onChange}
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      dateFormat={dateFormat || 'yyyy/MM/dd'}
      disabled={disabled}
      showTimeInput={showTimeInput}
    />
  );
};

export default DatePicker;
