import React from 'react';
import { SpanLang } from '@base/components';
import { OptionValue } from '@base/types/interfaces/common';
import styled from '@emotion/styled';
import classNames from 'classnames';

const PriorityLabel = styled.span`
  background-color: #17a2b8;
  color: #fff;

  &.urgent {
    background-color: #dc3545;
  }

  &.low {
    background-color: #8392a5;
  }

  &.high {
    background-color: #ff7907;
  }

  &.very_low {
    background-color: #dadada;
    color: #0d0d0d;
  }
`;

interface IViewProps {
  value: OptionValue;
}
const View: React.FC<IViewProps> = (props: IViewProps) => {
  // console.log('priority', props);
  const { value } = props;

  return (
    <PriorityLabel
      className={classNames(
        'd-inline-block pd-x-10 pd-t-2 pd-b-4 rounded-30 lh-1 tx-white',
        value?.keyName,
      )}
    >
      <SpanLang keyLang={value?.languageKey} />
    </PriorityLabel>
  );
};

export default View;
