import { components } from 'react-select';
import { isFunction, isBoolean, isInteger } from 'lodash';
import { convertComponentToHTML } from './utils';
import { arr2Dic } from '@base/utils/helpers/general.utils';
import TreeNodeTitle from '../components/tree-node-title';
import React from 'react';
import $ from 'jquery';

class TreeManage {
  private lsData: any;
  private idTree: string;
  private isLazy: boolean;
  private source: any;
  private component: any;
  private set: any;
  private action: any;
  private icons: any;
  private hasCb: boolean;
  private parsedNode: any;
  private classColorActive: string;
  private classColorDeactive: string;
  private setLoadedTree: any;
  private onChecked: any;
  private onClickCbNotExpanded: any;
  private onClickTitle: any;
  private onClickNode: any;
  private disabledWithRole: boolean;
  [key: string]: any;

  constructor() {
    this.lsData = {};
    this.idTree = '';
    this.isLazy = false;
    this.source = null;
    this.component = null;
    this.set = null;
    this.action = null;
    this.icons = null;
    this.hasCb = false;
    /**
     * check
     */
    this.parsedNode = null;
    this.classColorActive = 'tx-primary';
    this.classColorDeactive = 'tx-gray-500';
    /**
     *
     */
    this.setLoadedTree = null;
    this.onChecked = null;
    this.onClickCbNotExpanded = null;
    this.onClickTitle = null;
    this.onClickNode = null;
    this.disabledWithRole = false;
  }

  public initial(params: any = {}) {
    Object.keys(params).forEach((_key: any) => {
      if (typeof this[_key] != 'undefined') {
        this[_key] = params[_key];
      }
    });
    this.lsData = {};
    return this;
  }

  public onSearch(root: any, keyword: string) {
    const $tree = $(`#${root?.idTree}`);
    $tree.html('');
    root.create(root, keyword, null, true);
  }

  public onUpdateCb(root: any, _items: any) {
    const dic: any = _items.length > 0 ? arr2Dic(_items, 'id') : {};
    Object.keys(root.lsData).forEach((_key) => {
      const $el = $(`#${root.idTree}`).find('li[data-key="' + _key + '"]');
      const item = root.lsData[_key];
      //TODO: check group and leaf if same
      if (item.checked === 1 && !dic[item.id]) {
        root.lsData[_key].checked = 0;
        root._onChangeIconCb(root, $el.find('.rh-check-box')[0], -1);
        root._onChangeAllRelationCb(root, $el, -1, false, false, _key);
      }
    });
  }

  //trungtm - init checked items
  public onInitCb(root: any, _items: any) {
    const dic: any = _items.length > 0 ? arr2Dic(_items, 'id') : {};
    Object.keys(root.lsData).forEach((_key) => {
      const $el = $(`#${root.idTree}`).find('li[data-key="' + _key + '"]');
      const item = root.lsData[_key];
      //check exist in tree
      if (dic[item.id]) {
        root.lsData[_key].checked = 1;
        root._onChangeIconCb(root, $el.find('.rh-check-box')[0], 1);
        root._onChangeAllRelationCb(root, $el, 1, false, false, _key);
      }
    });
  }

  public create(self = null, search = '', node = null, isLoop = false) {
    const root = self ? self : this;
    const $tree = $(`#${root.idTree}`);
    root._loadingAllTree(root, true);
    root?.setLoadedTree && root?.setLoadedTree(false);

    root?.source &&
      root
        .source({
          idFolder: 0,
          keyWord: search,
          node,
        })
        .then((res: any) => {
          root._loadingAllTree(root, false);
          if (res.length > 0) {
            const $ulTree = $('<ul/>', {
              class: 'rh-tree',
            });
            $ulTree.append(root._loopCreateNode(root, res, '0', isLoop));
            $tree.append($ulTree);
            root?.setLoadedTree && root?.setLoadedTree(true);
          } else {
            const $nodata = convertComponentToHTML('div', root.component?.nodata || null);
            $nodata.className = 'wd-100p ht-100p d-flex justify-content-center align-items-center';
            $tree.append($nodata);
          }
        });
  }

  public _createFolder(root: any, parsedData: any, idParent: any, isLoop = false) {
    const isLoadChildren = isLoop && parsedData.isExpand === true && parsedData?.children;
    if (isLoadChildren) {
      parsedData.isExpand = true;
    }
    const $node = root._createNode(root, parsedData, idParent, 1);
    const $titleNode = root._renderTitle(root, 'folderTitle', $node, parsedData);
    const $iconTypeNode = root._renderIconNode(root, parsedData);
    if (parsedData.hasChildren) {
      $iconTypeNode.onclick = function () {
        root._onClickFolderIcon(root, this, parsedData);
      };
    }
    if (root.hasCb) {
      const dataIcon = root._renderIconCb(root, parsedData, 'leaf');
      let $iconCb = dataIcon.icon;
      if (dataIcon.disabled === false) {
        $iconCb = root._onCheckedIconCb(root, $iconCb, true);
      }
      $node.append($iconCb);
    }

    $node.append($iconTypeNode);
    $node.append($titleNode);
    if (isLoadChildren) {
      const $ulNode = $('<ul/>', {
        class: 'rh-ul-node',
      });
      $ulNode.append(root._loopCreateNode(root, parsedData.children, $node.data('key'), isLoop));
      $node.append($ulNode);
    }
    return $node;
  }

  public _createLeaf(root: any, parsedData: any, idParent: any) {
    const $node = root._createNode(root, parsedData, idParent, 0);
    const $titleNode = root._renderTitle(root, null, $node, parsedData);
    if (root.hasCb) {
      const dataIcon = root._renderIconCb(root, parsedData, 'leaf');
      let $iconCb = dataIcon.icon;
      if (dataIcon.disabled === false) {
        $iconCb = root._onCheckedIconCb(root, $iconCb, false);
      }
      $node.append($iconCb);
    }
    $node.append(root._renderIconNode(root, parsedData, 'leaf'));
    $node.append($titleNode);
    return $node;
  }

  /**
   *  checked <int>: -1= false, 1= true, 0= half
   * @param root
   * @param data
   * @returns {{checked}|*}
   * @private
   */
  public _coverParsedNode(root: any, data: any, firstData: any) {
    if (root.hasCb) {
      if (!data?.checked) data.checked = -1;
    }
    return { ...firstData, ...data };
  }

  public _renderTitle(root: any, type = 'leafTitle', $node: any, data = {}) {
    const componentTitle = root.component?.folderTitle || null;
    // if (componentTitle) componentTitle.props = { data: data };

    const cloneComponentTitle = React.cloneElement(componentTitle, { data: data });
    const $a = convertComponentToHTML('a', cloneComponentTitle);
    $a.className = 'mg-l-5 rh-title';
    $a.href = 'javascript:';
    $a.onclick = function () {
      root.onClickTitle && root?.onClickTitle(root.lsData[$node.data('key')]);
    };
    return $a;
  }

  public _renderIconCb(root: any, data: any, type = null) {
    const isDisableCB =
      root.disabledWithRole &&
      data?.has_role_id &&
      data?.has_role_id !== null &&
      data?.has_role_id !== ''
        ? true
        : false;

    const itemIcon = root._getIconCb(root, data.checked);
    let classIcon = `rh-check-box ${itemIcon.classIcon} ${isDisableCB && 'rh-check-box-disable'}`;
    const $iconCb = convertComponentToHTML('span', itemIcon.icon);
    $iconCb.className = classIcon;
    return {
      icon: $iconCb,
      disabled: isDisableCB,
    };
  }

  public _onCheckedIconCb(root: any, $iconCb: any, isNode = true) {
    $iconCb.onclick = function () {
      const $parent = $(this).parent('li');
      const dataNode = $parent.data();
      const currData = root.lsData[dataNode.key];
      const newValue = currData.checked == 1 ? -1 : 1;
      root._onChangeIconCb(root, this, newValue);

      root.lsData[dataNode.key] = {
        ...root.lsData[dataNode.key],
        checked: newValue,
      };
      root.onClickNode && root.onClickNode(root.lsData[dataNode.key]);
      root._onChangeAllRelationCb(root, $parent, newValue, isNode, dataNode.key);
    };
    return $iconCb;
  }

  public _onChangeIconCb(root: any, target: any, value: any) {
    const itemIcon = root._getIconCb(root, value);
    let classIcon = `rh-check-box ${itemIcon.classIcon}`;
    const $iconCb = convertComponentToHTML('span', itemIcon.icon);
    $(target).attr('class', classIcon).html($iconCb);
  }

  public _onChangeAllRelationCb(
    root: any,
    $li: any,
    newValue = -1,
    isNode = true,
    isClickCb = true,
    currKey = '',
  ) {
    root._onChangeLoopParent(root, $li, newValue);
    if (isNode) {
      root._onChangeLoopChildren(root, $li, newValue);
    }
    if (root.onChecked && isClickCb) {
      setTimeout(() => {
        root.onChecked(
          Object.values(root.lsData),
          $li.data('key') != '' ? root.lsData[$li.data('key')] : null,
        );
      }, 500);
    }
  }

  public _onChangeLoopParent(root: any, $li: any, newValue: any) {
    const $liParentNode = $li.parents('.rh-node');
    if ($liParentNode.length > 0) {
      $liParentNode.each(function (this: HTMLElement) {
        let core = 0;
        const keyNode = $(this).data('key');
        const childrenLi = $(this).find('.rh-ul-node').find('li');

        childrenLi.each(function () {
          const dataLi = root.lsData[$(this).data('key')];
          if (dataLi.checked === 1) {
            core++;
          }
        });
        const newValue = childrenLi.length === core ? 1 : core === 0 ? -1 : 0;
        root._onChangeIconCb(root, $(this).find('.rh-check-box')[0], newValue);
        root.lsData[keyNode] = {
          ...root.lsData[keyNode],
          checked: newValue,
        };
      });
    }
  }

  public _onChangeLoopChildren(root: any, $li: any, newValue: any, checkSub = false) {
    const $ulChildren = $li.children('.rh-ul-node');
    if ($ulChildren.length > 0 && $ulChildren.children('li').length > 0) {
      $ulChildren.children('li').each(function (this: HTMLElement) {
        const cb = $(this).find('.rh-check-box');
        const isDisabled = cb.hasClass('rh-check-box-disable');
        if (isDisabled === false) {
          const keyNode = $(this).data('key');
          root._onChangeIconCb(root, cb, newValue);
          root.lsData[keyNode] = {
            ...root.lsData[keyNode],
            checked: newValue,
          };
          if (root.lsData[keyNode].isFolder) {
            root._onChangeLoopChildren(root, $(this), newValue, true);
          }
        }
      });
    } else {
      if (root.isLazy && root.onClickCbNotExpanded && checkSub === false) {
        root._setUI(root, 'disabledAll', true);
        root._onChangeIconCb(root, $li.find('.rh-check-box'), true);
        root.onClickCbNotExpanded(root.lsData[$li.data('key')], (data: any) => {
          root._onChangeIconCb(root, $li.find('.rh-check-box'), 1);
          root._setUI(root, 'disabledAll', false);
        });
      }
    }
  }

  public _getIconCb(root: any, value: any) {
    if (isBoolean(value)) {
      return {
        classIcon: root.classColorDeactive,
        icon: root.icons.loadingFolder,
      };
    }
    if (isInteger(value)) {
      switch (value) {
        case 1:
          return {
            classIcon: root.classColorActive,
            icon: root.icons.cbTrue,
          };
        case 0:
          return {
            classIcon: root.classColorDeactive,
            icon: root.icons.cbHalf,
          };
        default:
          return {
            classIcon: root.classColorDeactive,
            icon: root.icons.cbFalse,
          };
      }
    }
  }

  public _renderIconNode(root: any, data: any, type = null) {
    let classIcon = 'rh-icon-type tx-primary';
    let $iconType = null;
    if (type) {
      $iconType = convertComponentToHTML('span', root.icons[type]);
    } else {
      if (data.hasChildren) {
        classIcon = `${classIcon} ${data.isExpand ? 'rh-expanded' : 'rh-shrink'}`;
        $iconType = convertComponentToHTML(
          'span',
          data.isExpand ? root.icons.folderExpand : root.icons.folderShrink,
        );
      } else {
        $iconType = convertComponentToHTML('span', root.icons.folder);
      }
    }
    $iconType.className = classIcon;
    return $iconType;
  }

  public _createNode(root: any, data: any, idParent: any, isFolder: any) {
    const idNode = `${root.idTree}-${idParent}-${data?.id}`;
    const $node = $('<li/>', {
      class: `rh-node ${data.isExpand ? '' : 'rh-unexpanded'}`,
      // ['data-expanded']: data.isExpanded ? '1' : '0',
      ['data-id']: data?.id,
      ['data-key']: idNode,
      ['data-has-children']: data.hasChildren ? 1 : 0,
    });
    root.lsData[idNode] = {
      ...data,
      isFolder: isFolder === 1,
    };
    return $node;
  }

  public _loopCreateNode(root: any, res: any, idParent = '0', isLoop = false) {
    const rs: any = [];
    res.forEach((_item: any) => {
      const parsedData = root._coverParsedNode(
        root,
        root.parsedNode ? root.parsedNode(_item) : _item,
        _item,
      );
      rs.push(
        root[parsedData?.isFolder ? '_createFolder' : '_createLeaf'](
          root,
          parsedData,
          idParent,
          isLoop,
        ),
      );
    });
    return rs;
  }

  public _onClickFolderIcon(root: any, $target: any, data: any) {
    const $el = $($target).parent('li');
    const attrData = $el.data();
    const keyParent = attrData.key;
    const isExpanded = root.lsData[keyParent].isExpand;
    const currChecked = root?.lsData[keyParent]?.checked || -1;
    if (root.isLazy) {
      const isLoadedChildren = $el.find('ul').length > 0;
      if (!isLoadedChildren && !isExpanded) {
        root._changeIcon(root, $target, 'loadingFolder');
        root._setUI(root, 'disabledAll', true);
        root
          .source({
            idFolder: attrData.id,
            node: root.lsData[keyParent],
          })
          .then((res: any) => {
            if (res.length > 0) {
              const $ulNode = $('<ul/>', {
                class: 'rh-ul-node',
              });
              $ulNode.append(
                root._loopCreateNode(root, res, keyParent.replace(`${root.idTree}-`, '')),
              );
              $el.append($ulNode);
            }

            root.lsData[keyParent].isExpand = true;
            root._changeLiExpanded($el, true);
            root._changeIcon(root, $target, 'folderExpand');
            root._setUI(root, 'disabledAll', false);

            if (root.hasCb) {
              if (currChecked === 1) {
                root._onChangeAllRelationCb(root, $el, 1, true, false, keyParent);
              } else {
                root._loopCheckedParent(root, $el);
              }
            }
          });
      } else {
        const newState = !isExpanded;
        root.lsData[attrData.key].isExpand = newState;
        root._changeLiExpanded($el, newState);
        root._changeIcon(root, $target, newState ? 'folderExpand' : 'folderShrink');
      }
    } else {
      /**
       * Romio TODO
       */
    }
  }

  public _loopCheckedParent(root: any, $el: any) {
    if ($el.find('.rh-node').length > 0) {
      const itemLi = root.lsData[$el.data('key')];
      const arrNode = $el.find('.rh-node');
      let score = 0;
      arrNode.each(function (this: HTMLElement) {
        const item = root.lsData[$(this).data('key')];
        if (item && item.checked === 1) {
          score += 1;
        }
      });
      const newValue = score == arrNode.length ? 1 : score == 0 ? -1 : 0;
      if (newValue != itemLi.checked) {
        root._onChangeIconCb(root, $el.find('.rh-check-box')[0], newValue);
        root.lsData[$el.data('key')].checked = newValue;
        const $parentLi = $el.parent('.rh-node');
        if ($parentLi) {
          root._loopCheckedParent(root, $parentLi);
        }
      }
    }
  }

  public _changeLiExpanded(li: any, isExpanded: any) {
    if (isExpanded) {
      $(li).removeClass('rh-unexpanded').addClass('rh-expanded');
    } else {
      $(li).removeClass('rh-expanded').addClass('rh-unexpanded');
    }
  }

  public _changeIcon(root: any, $target: any, nameIcon: any) {
    $($target).html('').append(convertComponentToHTML('span', root.icons[nameIcon]));
  }

  /**
   *
   * @param root
   * @param isLoading
   * @private loading all tree
   */
  public _loadingAllTree(root: any, isLoading: any) {
    root._setUI(root, 'disabledAll', isLoading);
    if (isLoading) {
      $(`#${root.idTree}`).append(
        convertComponentToHTML('div', root.component?.loadingTree || null),
      );
    } else {
      $(`#${root.idTree}`).html('');
    }
  }

  /**
   *
   * @param root
   * @param key
   * @param value
   * @private
   */
  public _setUI(root: any, key: any, value: any) {
    if (root.set && root.set?.key) root.set[key](value);
  }
}

export default TreeManage;
