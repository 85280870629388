import React from 'react';
import {
  Briefcase,
  Check,
  CornerUpLeft,
  CornerUpRight,
  Download,
  ExternalLink,
  Hash,
  Mail,
  Phone,
  PhoneCall,
  Printer,
  Send,
  Smartphone,
} from 'react-feather';
import { FormIcon } from '@base/components/form';
import { Activity } from '@activity/types/interfaces';
import dayjs from 'dayjs';
import RawHTML from '@base/components/raw-html';
import Avatar from '@base/components/avatar';

interface IItemProps {
  data: Activity;
}

const ActivityItem = ({ data }: IItemProps) => {
  const renderTask = (data: Activity) => {
    return (
      <>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">
            <FormIcon icon="task" iconType="icon" />
          </div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>{data.subject}</span>
                <span className="mg-l-auto tx-color-03">
                  {dayjs(data.createdAt).format('HH:mm')}
                </span>
              </div>
              <div className="new-timeline-body-content">
                <RawHTML>{data.content}</RawHTML>
              </div>
              <div className="tx-color-03">
                Duration: 3hours(2021-06-16 11:00) | Worker: John Doe
              </div>
              <div className="new-timeline-footer">
                <div className="media align-items-center">
                  <div className="avatar avatar-xs">
                    <Avatar
                      url={data?.createdBy?.photo}
                      alt={data?.createdBy?.name}
                      width={26}
                      height={26}
                    />
                  </div>
                  <div className="media-body mg-l-10">{data.createdBy?.name}</div>
                </div>
                <div className="mg-l-auto">
                  <button type="button" className="btn btn-link">
                    <Check className="mg-r-5" />
                    Mark complete
                  </button>
                  <button type="button" className="btn btn-link">
                    View detail
                    <ExternalLink className="mg-l-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderMeeting = (data: Activity) => {
    return (
      <>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">
            <Briefcase />
          </div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>{data.subject}</span>
                <span className="mg-l-auto tx-color-03">
                  {dayjs(data.createdAt).format('HH:mm')}
                </span>
              </div>
              <div className="new-timeline-body-content">
                <RawHTML>{data.content}</RawHTML>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderMail = (data: Activity) => {
    return (
      <>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">
            <Mail />
          </div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>{data.subject}</span>
                <span className="mg-l-auto tx-color-03">
                  {dayjs(data.createdAt).format('HH:mm')}
                </span>
              </div>
              <div className="new-timeline-body-content">
                <RawHTML>{data.content}</RawHTML>
              </div>
              <div className="new-timeline-footer">
                <div className="mg-l-auto">
                  <button type="button" className="btn btn-link">
                    <CornerUpLeft className="mg-r-5" />
                    Reply
                  </button>
                  <button type="button" className="btn btn-link">
                    <CornerUpRight className="mg-r-5" />
                    Foward
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderCall = (data: Activity) => {
    return (
      <>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">
            <Phone />
          </div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>{data.subject}</span>
                <span className="mg-l-auto tx-color-03">
                  {dayjs(data.createdAt).format('HH:mm')}
                </span>
              </div>
              <div className="mg-y-5">
                <audio controls preload="metadata">
                  <source src="" />
                </audio>
              </div>
              <div className="new-timeline-footer">
                <div className="media align-items-center">
                  <div className="avatar avatar-xs">
                    <Avatar
                      url={data?.createdBy?.photo}
                      alt={data?.createdBy?.name}
                      width={26}
                      height={26}
                    />
                  </div>
                  <div className="media-body mg-l-10">{data.createdBy?.name}</div>
                </div>
                <div className="mg-l-auto">
                  <button type="button" className="btn btn-link">
                    <Download className="mg-r-5" />
                    Download
                  </button>
                  <button type="button" className="btn btn-link">
                    <PhoneCall className="mg-r-5" />
                    Call
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderSms = (data: Activity) => {
    return (
      <>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">
            <Smartphone />
          </div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>{data.subject}</span>
                <span className="mg-l-auto tx-color-03">
                  {dayjs(data.createdAt).format('HH:mm')}
                </span>
              </div>
              <div className="new-timeline-body-content">
                <RawHTML>{data.content}</RawHTML>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderFax = (data: Activity) => {
    return (
      <>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">
            <Printer />
          </div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>{data.subject}</span>
                <span className="mg-l-auto tx-color-03">
                  {dayjs(data.createdAt).format('HH:mm')}
                </span>
              </div>
              <div className="new-timeline-body-content">
                <RawHTML>{data.content}</RawHTML>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderDm = (data: Activity) => {
    return (
      <>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">
            <Send />
          </div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>{data.subject}</span>
                <span className="mg-l-auto tx-color-03">
                  {dayjs(data.createdAt).format('HH:mm')}
                </span>
              </div>
              <div className="new-timeline-body-content">
                <RawHTML>{data.content}</RawHTML>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderGeneral = (data: Activity) => {
    return (
      <>
        <div className="new-timeline-item">
          <div className="new-timeline-icon">
            <Hash />
          </div>
          <div className="new-timeline-body">
            <div className="card pd-15">
              <div className="new-timeline-body-header">
                <span>{data.subject}</span>
                <span className="mg-l-auto tx-color-03">
                  {dayjs(data.createdAt).format('HH:mm')}
                </span>
              </div>
              <div className="new-timeline-footer">
                <div className="media align-items-center">
                  <div className="avatar avatar-xs">
                    <Avatar
                      url={data?.createdBy?.photo}
                      alt={data?.createdBy?.name}
                      width={26}
                      height={26}
                    />
                  </div>
                  <div className="media-body mg-l-10">{data.createdBy?.name}</div>
                </div>
                <div className="mg-l-auto">
                  <button type="button" className="btn btn-link">
                    View detail
                    <ExternalLink className="mg-l-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderItem = (data: Activity) => {
    switch (data.type) {
      case 'TYPE_TASK': {
        return renderTask(data);
      }
      case 'TYPE_MEETING': {
        return renderMeeting(data);
      }
      case 'TYPE_CALL': {
        return renderCall(data);
      }
      case 'TYPE_MAIL': {
        return renderMail(data);
      }
      case 'TYPE_SMS': {
        return renderSms(data);
      }
      case 'TYPE_FAX': {
        return renderFax(data);
      }
      case 'TYPE_DM': {
        return renderDm(data);
      }
      default: {
        return renderGeneral(data);
      }
    }
  };

  return <>{renderItem(data)}</>;
};

export default ActivityItem;
