import React, { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, Range } from 'react-date-range';
import { isDate } from 'date-fns';
import { ko, enUS as en, zhCN as cn, ja as jp, vi } from 'date-fns/esm/locale';

interface IDateRangeProps {
  range: Range;
  onApply: (val: Range) => void;
  onCancel: (val: Range) => void;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
}

const DateRange = (props: IDateRangeProps) => {
  const { range, onApply, onCancel, dateFormat, minDate, maxDate } = props;
  const rangeKey = range?.key || 'daterangepicker';
  const initialData = {
    ...range,
    startDate: isDate(range?.startDate) ? range?.startDate : undefined,
    endDate: isDate(range?.endDate) ? range?.endDate : undefined,
    key: rangeKey,
  };
  //state
  const [curRange, setCurRange] = useState<Range[]>([initialData]);

  //reset
  const convertToString = (range: Range) => {
    return range;
  };

  //render
  return (
    <>
      <DateRangePicker
        locale={ko}
        className={'pd-b-0-i'}
        onChange={(newValue: any) => {
          setCurRange([newValue[rangeKey]]);
        }}
        ranges={curRange}
        dateDisplayFormat={dateFormat || 'yyyy-MM-dd'}
        minDate={minDate}
        maxDate={maxDate}
      />
      <hr />
      <div className="text-right pd-r-10 pd-l-10 pd-b-10">
        <button
          className="btn btn-secondary mg-r-20"
          type="button"
          onClick={(e) => {
            setCurRange([initialData]);
            onCancel && onCancel(convertToString(range));
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          type="button"
          onClick={(e) => {
            onApply && onApply(convertToString(curRange[0] || {}));
          }}
        >
          Apply
        </button>
      </div>
    </>
  );
};

export default DateRange;
