import React, { memo, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import { ViewFields } from '@base/components/hook-form/view';
import { SpanLang } from '@base/components';
//import { viewDataByMenuAtom } from '@base/recoil/atoms';

interface ISummaryProps {
  bodyClassName?: any;
  bodyStyle?: any;
  ignoreFields?: string[];
  hiddenFields?: string[];
  refetchFields?: string[];
  data?: any;
  //showTop?: boolean;
  onRefetch?: () => void;
}

/**
 * trungtm
 * It is similar to component pagelayout-summary
 * but this component using data from props, not from recoil
 * @param props
 * @returns
 */
const ViewSummary: React.FC<ISummaryProps> = (props: ISummaryProps) => {
  const { bodyClassName, bodyStyle, ignoreFields = [], hiddenFields = [], refetchFields = [], data, onRefetch } = props;
  //state
  const [basicFields, setBasicField] = useState<any[]>([]);

  //init fields
  useEffect(() => {
    let newBasicFields = data?.layout?.data?.[0]?.children ?? [];
    newBasicFields = newBasicFields.filter((_field: any) => !hiddenFields.includes(_field.keyName));
    setBasicField(newBasicFields);
  }, [data]);

  return (
    <div className="view-aside-section">
      <div className="view-aside-section-header">
        <span className="view-aside-section-header-title">
          <SpanLang keyLang="Summary" />
        </span>
      </div>
      <div
        className={classNames('view-aside-section-body', bodyClassName)}
        style={bodyStyle}
      >
        <div className="form-row mg-x-0 mg-t-10">
          <ViewFields
            fields={basicFields}
            ignoreFields={ignoreFields}
            menu={data?.menu ?? ''}
            menuSource={data?.menuSource ?? ''}
            menuSourceId={data?.menuSourceId ?? ''}
            refetchFields={refetchFields}
            onRefetch={onRefetch}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewSummary;
