import * as dataTypes from '@base/config/data-types';
import * as keyNames from '@base/config/key-names';
import * as components from '@base/config/write-field/components';
import validators from '@base/utils/validation/field-validator';

/**
 * Basic field by keyname
 */
/**
 * parseValue --> convert api value to component value
 * parseParam --> convert component value to api value
 */
export const BasicConfig = {
  [keyNames.KEY_NAME_NAME]: {
    component: components.Input,
    componentProps: {
      type: 'text',
    },
    validate: {},
    defaultValue: '',
    parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: string) => value,
  },
};

/**
 * parseValue --> convert api value to component value
 * parseParam --> convert component value to api value
 */
export const BasicDataTypeConfig: any = {
  [dataTypes.BASIC_DATA_TYPE_TEXT]: {
    component: components.Input,
    componentProps: {
      type: 'text',
    },
    showFullRow: true,
    //validate: {},
    defaultValue: '',
    //parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: string) => value,
  },
  [dataTypes.BASIC_DATA_TYPE_SINGLE_CHOICE]: {
    component: components.RadioGroup,
    //validate: {},
    defaultValue: { value: 'sample', label: 'sample' },
    componentProps: {
      isVertical: true,
      options: [
        { value: 'sample', label: 'sample' },
        { value: 'sample 2', label: 'sample 2' },
      ],
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    //parseValue: (valueApi: any) => valueApi || null,
    parseParam: (value: any) => ({
      id: value?.id ?? '',
      name: value?.languageKey ?? '',
    }),
  },
  [dataTypes.BASIC_DATA_TYPE_TEXTAREA]: {
    component: components.TextArea,
    //validate: {},
    showFullRow: true,
    defaultValue: '',
    //parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: string) => value,
  },
  [dataTypes.BASIC_DATA_TYPE_MULTI_CHOICES]: {
    component: components.CheckBox,
    //validate: {},
    defaultValue: [],
    //parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: any) =>
      value?.map((_ele: any) => ({
        id: _ele.id,
        name: _ele.languageKey,
      })),
  },
  [dataTypes.BASIC_DATA_TYPE_SELECT_BOX]: {
    component: components.Select,
    componentProps: {
      single: true, //TODO - check in pagelayout data
    },
    //validate: {},
    defaultValue: null,
    //parseValue: (valueApi: any) => valueApi || null,
    parseParam: (value: any) =>
      Array.isArray(value)
        ? value?.map((_ele: any) => ({
            id: _ele.id,
            name: _ele.languageKey,
          }))
        : {
            id: value.id,
            name: value.languageKey,
          },
  },
  [dataTypes.BASIC_DATA_TYPE_DATE]: {
    component: components.DateSelector,
    //validate: {},
    defaultValue: new Date(),
    //parseValue: (valueApi: any) => valueApi || null,
    parseParam: (value: any) => new Date(value).toISOString(),
  },
  [dataTypes.BASIC_DATA_TYPE_DATE_TIME]: {
    component: components.DateSelector,
    //validate: {},
    defaultValue: new Date(),
    //parseValue: (valueApi: any) => valueApi || null,
    parseParam: (value: any) => new Date(value).toISOString(),
  },
  [dataTypes.BASIC_DATA_TYPE_NUMBER]: {
    component: components.Input,
    componentProps: {
      type: 'number',
    },
    //validate: {},
    defaultValue: '',
    //parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: string | number) => value,
  },
  [dataTypes.BASIC_DATA_TYPE_DECIMAL]: {
    component: components.Input,
    componentProps: {
      type: 'money',
    },
    //validate: {},
    defaultValue: '',
    //parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: string | number) => value,
  },
  [dataTypes.BASIC_DATA_TYPE_PERCENT]: {
    component: components.Percent,
    //validate: {},
    defaultValue: '',
    //parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: string | number) => value,
  },
  [dataTypes.BASIC_DATA_TYPE_SWITCH]: {
    component: components.Switch,
    //validate: {},
    defaultValue: false,
    //parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: boolean) => value,
  },

  [dataTypes.BASIC_DATA_TYPE_ADDRESS]: {
    component: components.Address,
    //validate: {},
    defaultValue: [],
    showFullRow: true,
    //parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: any) =>
      value
        .filter((_ele: any) => _ele.address.length > 0)
        .map((_ele: any) => ({
          label: _ele.option?.value,
          labelValue: _ele.optionDescription,
          country: _ele.country?.value,
          state: _ele.state?.value,
          city: _ele.city?.value,
          street: _ele.address,
          zipcode: _ele.zipcode,
        })),
  },
  [dataTypes.BASIC_DATA_TYPE_EMAIL]: {
    component: components.Email,
    componentProps: {
      isMultiple: true,
    },
    validate: {
      emails: validators.emails, //'emails' --> error key
    },
    defaultValue: [],
    showFullRow: true,
    //parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: any) =>
      value?.map((_ele: any) => ({
        label: _ele.label,
        labelValue: _ele.label_value,
        email: _ele.value,
      })),
  },
  [dataTypes.BASIC_DATA_TYPE_PHONE]: {
    component: components.Phone,
    componentProps: {
      isMultiple: true,
    },
    //validate: {},
    showFullRow: true,
    defaultValue: [],
    //parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: any) =>
      value
        .filter((_ele: any) => _ele.phoneNumber.length > 0)
        .map((_ele: any) => ({
          label: _ele.phoneOption.value,
          labelValue: _ele.phoneOptionDescription,
          country: _ele.phoneCode.value,
          phoneNumber: _ele.phoneNumber,
          extension: _ele.phoneOptionExtension,
        })),
  },
  [dataTypes.BASIC_DATA_TYPE_WEBSITE]: {
    component: components.Website,
    //validate: {},
    showFullRow: true,
    defaultValue: [],
    //parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: any) =>
      value.map((_ele: any) => ({
        label: _ele.label,
        labelValue: _ele.label_value,
        website: _ele.value,
        protocol: _ele.protocol,
      })),
  },
  [dataTypes.BASIC_DATA_TYPE_IP]: {
    component: components.IpAddress,
    //validate: {},
    defaultValue: [],
    //parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: any) =>
      value.map(
        (_ele: any) =>
          _ele !== '...' && {
            ip: _ele,
          },
      ),
  },
  [dataTypes.BASIC_DATA_TYPE_CURRENCY]: {
    component: components.CurrencySelect,
    componentProps: {
      showSymbol: true,
    },
    //validate: {},
    defaultValue: null,
    //parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: any) => ({
      code: value?.code,
      name: value?.currencyName, //TODO: recheck API
    }),
  },
  [dataTypes.BASIC_DATA_TYPE_LOOKUP]: {
    component: components.DataSourceSelect,
    getProps: (field: any) => {
      //// console.log('field', field);
      return {
        single: field.attrs?.data_source_type?.value === 'single' ? true : false,
        sourceKey: field.attrs?.data_source_key?.value,
        sourceValue: field.attrs?.data_source_value?.value,
        sourceType: field.attrs?.data_source_selection_type?.value || 'field',
      };
    },
    //validate: {},
    defaultValue: null,
    //parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: any) => value?.keyName || 'NONE', //TODO: always single !?
  },
};
