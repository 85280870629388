import React from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { IParam } from '.';

interface IPopoverParamsProps {
  params: IParam[];
  target: any;
  show: boolean;
}
const PopoverParams: React.FC<IPopoverParamsProps> = (props: IPopoverParamsProps) => {
  const { params, target, show } = props;
  return (
    <>
      <Overlay show={show} target={target} placement="bottom" containerPadding={0}>
        <Popover id="tui-editor-popover-param">
          <Popover.Body>
            <div className="dropdown">
              {params && params.length > 0
                ? params.map((p) => {
                    return (
                      <a className="dropdown-item" role="button" key={p.code}>
                        {p.name}
                      </a>
                    );
                  })
                : ''}
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

export default PopoverParams;
