import { graphQLApi } from '@base/utils/axios/graphql';
import { isString } from 'lodash';
import { useEffect } from 'react';
//import { useMutation, UseMutationResult } from 'react-query';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { authAtom } from '@sign-in/recoil/atoms/auth';
import { IResponseError } from '@base/utils/axios/helper';
import Storages from '@base/utils/storages/ls';

function useMutationPost<T>(
  query: string,
  key: string,
  options?: any,
): UseMutationResult<T, unknown, void, unknown> {
  const Ls = new Storages();
  const setAuth = useSetRecoilState(authAtom);

  const mutation = useMutation(async (newData) => {
    const response = await graphQLApi<T>(key, query, newData);
    return response;
  }, options);

  useEffect(() => {
    if (isString(mutation.error)) {
      const err = JSON.parse(mutation.error) as IResponseError;
      if (['no_authentication', 'server_error'].includes(err.message)) {
        // Ls.remove('token');
        // setAuth(null);
        // // console.log('logout');
      }
    }
  }, [mutation.error]);

  return mutation;
}

export default useMutationPost;
