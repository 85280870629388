import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useIntersection } from './intersection-observer';

import './styles.scss';
import Loading from '../loading';

interface ILazyLoadImageProps {
  url: string; //big image
  thumb: string; //small image
  width: number;
  height: number;
}

const LazyLoadImage: React.FC<ILazyLoadImageProps> = (props) => {
  const { url, thumb, width, height } = props;
  //state
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef<any>(null);

  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  const handleOnLoad = () => {
    setIsLoaded(true);
  };

  //// console.log('url', url);
  return (
    <div
      className="image-container rounded"
      ref={imgRef}
      style={{
        paddingBottom: `${(height / width) * 100}%`,
        width: '100%',
      }}
    >
      {isInView && (
        <>
          <img
            className={classnames('image', 'thumb', {
              ['is-loaded']: !!isLoaded,
            })}
            src={thumb}
          />
          {!isLoaded && <Loading />}
          <img
            className={classnames('image', {
              ['is-loaded']: !!isLoaded,
            })}
            src={url}
            onLoad={handleOnLoad}
          />
        </>
      )}
    </div>
  );
};

export default LazyLoadImage;
