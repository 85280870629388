import React from 'react';

type viewProps = {
  value: any[];
}

const View = (props: viewProps) => {
  const { value } = props;

  return (
    <>
      {value && value.length
        ? value.map((item, index) => {
            return (
              <div className="badge badge-dark pd-7 tx-12 mg-r-10" key={index}>
                {item.name ?? ''}
              </div>
            );
          })
        : ''}
    </>
  );
};

export default View;
