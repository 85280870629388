import React, { useState } from 'react';
import { Note, NoteForm } from '@base/types/interfaces/note';
import { InputBox } from './styles';
import useMutationPost from '@base/hooks/useMutationPost';
import { NOTE_CREATE_ITEM } from '@base/services/graphql/note';
import { useQueryClient } from '@tanstack/react-query'; //v4
import TuiEditor from '@base/components/form/tui-editor';
import { Button } from '@base/components/form';
import { MENU_SOURCE } from '@base/config/menus';

interface NoteWriteProps {
  source: string;
  sourceId: string;
}

function NoteWrite(props: NoteWriteProps) {
  const { source, sourceId } = props;
  const queryClient = useQueryClient();

  const [isSaving, setIsSaving] = useState(false);
  const [addNote, setAddNote] = useState('');

  const mutation: any = useMutationPost<Note>(NOTE_CREATE_ITEM, 'builtin_createNote', {
    onMutate: () => {
      setIsSaving(true);
    },
    onSuccess: () => {
      setAddNote('');
      setIsSaving(false);
      setTimeout(() => {
        queryClient.invalidateQueries(['builtin_notes']);
      }, 500);
    },
  });

  const addNoteSubmit = () => {
    const note: NoteForm = {
      source: {
        menu: MENU_SOURCE[source],
        id: sourceId,
      },
      content: addNote,
    };
    mutation.mutate({ note: note });
  };

  return (
    <InputBox>
      <TuiEditor
        value={addNote}
        onChange={setAddNote}
        placeholder={'Enter a note'}
        editorProps={{ height: '200px' }}
      />
      <Button
        color="primary"
        className={'float-right mg-t-5 btn-sm'}
        onClick={() => {
          addNoteSubmit();
        }}
        loading={isSaving}
      >
        Post
      </Button>
    </InputBox>
  );
}

export default NoteWrite;
