import styled from '@emotion/styled';

export const EmptySplitView = styled.div`
  flex: 1 1 0;
  height: 100%;
  position: relative;
  outline: none;
`;

export const EmptySplitViewContent = styled.div`
  svg {
    width: 120px;
    fill: #e2e2e2;
  }

  p {
    color: #888;
  }
`;
