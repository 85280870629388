import React, { useState, useEffect } from 'react';
import { MiModal } from '@base/components';
import { Search } from 'react-feather';
import { Button } from '@base/components/form';
import LanguageForm from './language-form';
import LanguageListSearch from './language-list-search';
import { UPDATE_SYSTEM_LANGUAGE, GET_SYSTEM_LANGUAGES, CREATE_SYSTEM_LANGUAGE } from './query';
import {
  BaseMutationResponse,
  BaseResponse,
  ResponsePaging,
} from '@base/types/interfaces/response';
import usePosts from '@base/hooks/usePosts';
import useMutationPost from '@base/hooks/useMutationPost';
import { toast } from 'react-toastify';

/**
 */
const LanguageModal: React.FC<any> = (props: any) => {
  const {
    showModal,
    translateWords,
    // keyTranslateWords,
    isKey,
    closeModalLanguage,
  } = props;

  //NEW constant
  const menuData = [
    { label: 'Common', value: 'common' },
    { label: 'Page Layout', value: 'pagelayout' },
    { label: 'General Setting', value: 'generalsetting' },
    { label: 'Customer', value: 'customer' },
    { label: 'Activity', value: 'activity' },
    { label: 'Product', value: 'product' },
    // { label: 'Order', value: 'order' },
    // { label: 'Purchase', value: 'purchase' },
    // { label: 'Invoice', value: 'invoice' },
    // { label: 'Marketing', value: 'marketing' },
    // { label: 'Inventory', value: 'inventory' },
    // { label: 'Sales', value: 'sales' },
    { label: 'Desk', value: 'desk' },
  ];
  const defaultLangValues = [
    { id: 'en', value: '', label: 'English(en)' },
    { id: 'ko', value: '', label: 'Korean(ko)' },
    { id: 'vi', value: '', label: 'Tiếng Việt(vi)' },
    { id: 'jp', value: '', label: 'Japan(jp)' },
    { id: 'ch', value: '', label: 'China(ch)' },
    { id: 'zh', value: '', label: 'China(zh)' },
    { id: 'ido', value: '', label: 'Indonesia(id)' }, //'id'
  ];

  //state
  const [type, setType] = useState<string>('all');
  const [menu, setMenu] = useState<any>(menuData[0]);
  const [langKey, setLangKey] = useState<string>(menuData[0].value + '_');
  const [langValues, setLangValues] = useState<any>(defaultLangValues);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  //const [isLoading, setIsLoading] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>('');
  const [dataListSearch, setDataListSearch] = useState<any>([]);
  //selected item
  const [modifiedLangValue, setModifedLangValue] = useState<any>(null); //object
  const [copiedLangValue, setCopiedLangValue] = useState<any>(null); //object
  //error
  const [langKeyError, setLangKeyError] = useState<boolean>(false);
  const [langValueError, setLangValueError] = useState<boolean>(false);

  /** hook get data */
  let params: any = {
    filter: {
      paging: {
        size: 15,
        page: 1,
      },
    },
  };
  if (keyword.includes('_')) {
    params.filter.filters = {
      langKey: keyword,
    };
  } else {
    params.filter.keyword = keyword;
  }
  const { data, isLoading, refetch } = usePosts<any[]>(
    ['systemLanguages', keyword], //query keys
    GET_SYSTEM_LANGUAGES,
    params,
    {
      keepPreviousData: true,
      enabled: keyword.length > 0,
    },
  );

  /** mutation update */
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_SYSTEM_LANGUAGE,
    'createSystemLanguage',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Added new language successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        //// console.log('mutation error', error);
        toast.success('Added new language failed: ' + JSON.parse(error).message);
      },
    },
  );

  const mutationUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_SYSTEM_LANGUAGE,
    'updateSystemLanguage',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Updated language successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        //// console.log('mutation error', error);
        toast.success('Updated language failed: ' + JSON.parse(error).message);
      },
    },
  );

  //check success
  useEffect(() => {
    if (mutationAdd.isSuccess) {
      handleReset();
    }
  }, [mutationAdd.isSuccess]);

  useEffect(() => {
    if (mutationUpdate.isSuccess) {
      handleReset();
    }
  }, [mutationUpdate.isSuccess]);

  //set search results
  useEffect(() => {
    ///// console.log('data', data);
    if (data?.data) {
      setDataListSearch(data.data);
      if (data?.data.length === 0) {
        initLangValues(keyword);
      } else {
        resetNewLang();
      }
    } else {
      setDataListSearch([]);
      initLangValues(keyword);
    }
  }, [data]);

  //set keywords
  useEffect(() => {
    let initKeyword = '';
    if (translateWords.split('.').length > 1) {
      //this is lang key
      initKeyword = translateWords.split('.')[1];
    } else {
      //search test
      initKeyword = translateWords;
    }
    //set search text
    setKeyword(initKeyword);
    resetNewLang();

    //loadList(initKeyword);
  }, [translateWords]);

  //init data
  useEffect(() => {
    //
    if (modifiedLangValue) {
      setCopiedLangValue(null);
      //set lang values
      const newLangValues = [...langValues];
      newLangValues.map((item) => (item.value = modifiedLangValue[item.id]));
      setLangValues(newLangValues);
      //set lang key
      setLangKey(modifiedLangValue.langKey);
      //set type
      setType(modifiedLangValue.system || 'all');
      //set menu
      setMenu(menuData[menuData.findIndex((el) => el.value === modifiedLangValue.menu)]);
    }
  }, [modifiedLangValue]);

  //init data
  useEffect(() => {
    //
    if (copiedLangValue) {
      setModifedLangValue(null);
      //set lang values
      const newLangValues = [...langValues];
      newLangValues.map((item) => (item.value = copiedLangValue[item.id]));
      setLangValues(newLangValues);
      //reset lang key
      setLangKey('ncrm_' + menu.value + '_');
      //set type
      setType(copiedLangValue.system || 'all');
      //set menu
      setMenu(menuData[menuData.findIndex((el) => el.value === copiedLangValue.menu)]);
    }
  }, [copiedLangValue]);

  //init lang values
  const initLangValues = (searchText: string) => {
    //init lang text
    const newLangValues = [...langValues];
    newLangValues.map((item) => (item.value = searchText));
    setLangValues(newLangValues);
  };

  //init lang values
  const resetLangValues = () => {
    //init lang text
    const newLangValues = [...langValues];
    newLangValues.map((item) => (item.value = ''));
    setLangValues(newLangValues);
  };

  //reset to create new lang key
  const resetNewLang = () => {
    setModifedLangValue(null);
    setCopiedLangValue(null);
    setType('all');
    setLangKey('ncrm_' + menu.value + '_');
    resetLangValues();
  };

  //key press enter
  const handkeKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      refetch();
    }
  };

  //validate data before save
  const validData = () => {
    //validate lang key
    let tmpLangKeyError = false;
    if (langKey === 'ncrm_' + menu.value + '_') {
      tmpLangKeyError = true;
      setLangKeyError(true);
    } else {
      setLangKeyError(false);
    }

    //validate lang values
    let tmpLangValueError = true;
    //there is an item having value, set to false
    langValues.map((item: any) => {
      if (item.value && item.value.length > 0) {
        tmpLangValueError = false;
      }
    });
    setLangValueError(tmpLangValueError);

    return !tmpLangKeyError && !tmpLangValueError;
  };

  //save translate
  const handleSave = async () => {
    if (!validData()) return;

    let langData: any = {};
    langValues.map((item: any) => (langData[item.id] = item.value));

    //call graphQL
    const newParams: any = {
      menu: menu.value,
      langKey: langKey,
      ...langData,
    };
    let res = null;

    if (modifiedLangValue) {
      newParams.id = modifiedLangValue.id;
      mutationUpdate.mutate({ systemLanguage: newParams });
    } else {
      mutationAdd.mutate({ systemLanguage: newParams });
    }
  };

  //reset
  const handleReset = () => {
    //reset input
    setModifedLangValue(null);
    setCopiedLangValue(null);
    setLangKey('ncrm_' + menu.value + '_');
    resetLangValues();
    //load list
    refetch();
  };

  //main render
  return (
    <MiModal title={'Translate Search'} size="xl" isOpen={showModal} onClose={closeModalLanguage}>
      <div className="pos-relative d-flex justify-content-between align-items-center pd-10">
        <div className="pos-relative d-flex flex-grow-1 justify-content-end align-items-center">
          <input
            className="form-control"
            placeholder={'Please input keyword and Enter'}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyPress={handkeKeyPress}
          />
          <div className="pos-absolute t-8 r-10">
            <Search className="wd-20 tx-gray-300" />
          </div>
        </div>
        <div className="mg-l-5">
          <span>{'(Search lang key by include "_" in keyword)'}</span>
        </div>
      </div>
      <MiModal.Body>
        <div className="scroll-box" style={{ height: 'calc(100vh - 250px)' }}>
          <LanguageListSearch
            keyword={keyword}
            dataListSearch={dataListSearch || []}
            isLoading={isLoading}
            setModifedLangValue={setModifedLangValue}
            setCopiedLangValue={setCopiedLangValue}
          />
          <LanguageForm
            closeModal={closeModalLanguage}
            type={type}
            setType={setType}
            configMenu={menuData}
            menu={menu}
            setMenu={setMenu}
            langKey={langKey}
            setLangKey={setLangKey}
            langValues={langValues}
            setLangValues={setLangValues}
            isSaving={isSaving}
            setIsSaving={setIsSaving}
            modifiedLangValue={modifiedLangValue}
            resetNewLang={resetNewLang}
            //error
            langKeyError={langKeyError}
            langValueError={langValueError}
          />
        </div>
      </MiModal.Body>
      <MiModal.Footer>
        <div className="d-flex justify-content-end">
          <Button color="secondary" className="btn btn-secondary" onClick={closeModalLanguage}>
            Close
          </Button>
          <Button
            color="primary"
            className="btn btn-primary mg-l-5"
            loading={mutationAdd.isLoading || mutationUpdate.isLoading}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </MiModal.Footer>
    </MiModal>
  );
};

export default LanguageModal;
