import React, { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { UserSelect } from '@base/containers';
import { Button } from '@base/components/form';
import { Overlay, Popover } from 'react-bootstrap';

/**
 *
 * @param {*} param0
 * @returns
 */
const UserPopover: React.FC<any> = (props: any) => {
  const {
    isOpen = false,
    onClose = null,
    onApply = null,
    placement = 'right',
    single = false,
    data, //initial data
    containerRef,
    target,
  } = props;

  //state
  const [idPop] = useState(uniqueId('user-popover-'));
  const [users, setUsers] = useState([]);

  //set initial data
  useEffect(() => {
    setUsers(data);
  }, [data]);

  //reset data and close popover
  const handleClose = () => {
    //setUsers([]);
    onClose && onClose();
  };

  let $target: any = $(target?.current);
  let $body: any = $(document.body);
  // // console.log('$target', $target?.offset());
  // // console.log('$body outerHeight', $body.outerHeight());
  // // console.log('$outerWidth', $target?.outerWidth());
  let posLeft = $target?.offset()?.left;
  let posTop = $target?.offset()?.top;
  if (placement === 'left') {
    posLeft = posLeft - $target?.outerWidth() * 1.5;
  }
  if (posTop > $body.outerHeight() / 2) {
    posTop = posTop - $body.outerHeight() / 2;
    if (posTop > 250) {
      posTop = 250;
    }
  } else {
    posTop = posTop - 100;
  }

  return (
    <Overlay
      show={isOpen}
      target={target?.current}
      placement={placement}
      container={containerRef}
      containerPadding={0}
    >
      <Popover id={idPop}>
        <div
        // className="pos-absolute bg-gray-100 rounded"
        // style={{ top: `${posTop}px`, left: `${posLeft}px` }}
        >
          <Popover.Body>
            <UserSelect
              single={single}
              value={users} //selected items
              onChange={(items: any) => setUsers(items)}
            />
          </Popover.Body>
          <div className={'ht-60 d-flex justify-content-end align-items-center pd-r-10'}>
            <Button
              name={'Close'}
              className="mg-r-5"
              color={'secondary'}
              outline
              onClick={handleClose}
            />
            <Button
              name={'Apply'}
              color={'primary'}
              outline
              onClick={() => onApply && onApply(users)}
            />
          </div>
        </div>
      </Popover>
    </Overlay>
  );
};

export default UserPopover;
