import React from 'react';
import classNames from 'classnames';
import { X } from 'react-feather';
import Button from '@base/components/form/button';

const defaultProps = {
  title: '',
  tag: 'span',
  wrapTag: 'div',
  //closeAriaLabel: 'Close',
  //charCode: 215,
};

const Header = (props: any) => {
  let closeButton;
  const {
    className,
    title,
    children,
    tag: Tag,
    wrapTag: WrapTag,
    closeBtn,
    closeLabel,
    onClose,
    ...attributes
  } = props;

  if (!closeBtn && onClose) {
    closeButton = (
      <Button
        type="button"
        onClick={onClose}
        className={'close'}
        aria-label={closeLabel || 'Close'}
      >
        <span aria-hidden="true">
          <X />
        </span>
      </Button>
    );
  }

  return (
    <WrapTag {...attributes} className={classNames(className, 'off-canvas-header')}>
      <Tag className="tx-16 tx-bold">{title || children}</Tag>
      {closeBtn || closeButton}
    </WrapTag>
  );
};

Header.defaultProps = defaultProps;

export default Header;
