import React, { VFC } from 'react';
import { Sortable } from '@base/components';
import SectionItem from '@base/components/view/section-item';

interface IDesktopLayout1ViewRightProps {
  rightConfig: any;
  styles?: any;
}

const DesktopLayout1ViewRight: React.FC<IDesktopLayout1ViewRightProps> = (
  props: IDesktopLayout1ViewRightProps,
) => {
  const { styles, rightConfig } = props;

  //render
  return (
    <div
      className="scroll-box view-aside-inner"
      style={styles ? styles : { height: 'calc(100vh - 110px)' }}
    >
      <Sortable
        key="account-right"
        handle=".view-aside-section-header"
        onChange={(orders: any) => {
          // TODO save to user config
        }}
        forcePlaceholderSize={false}
        placeholder=""
        animation={150}
      >
        {rightConfig.map((item: any) => {
          return (
            <SectionItem
              key={item.key}
              id={item.key}
              title={item.title}
              sortable={item.sortable}
              show={item.show}
            >
              {item.component}
            </SectionItem>
          );
        })}
      </Sortable>
    </div>
  );
};

export default DesktopLayout1ViewRight;
