import styled from '@emotion/styled';

export const HoverEditContainer = styled.div`
  margin-left: -6px;
  width: 100%;
  .component-edit {
    min-height: 37px;
    padding: 5px;
    border-radius: 10px;
    border: 1px dashed var(--border-color-2);
  }
  .is-lock {
    background-color: var(--background-box-second) !important;
    border-radius: 4px !important;
  }
  .component-view-lock {
    border-radius: 5px;
    // background-color: var(--background-box-second);
    .icon-edit {
      line-height: 0px;
      display: none;
    }
    &:hover {
      border: 1px dashed transparent !important;
      .icon-edit {
        display: none !important;
      }
    }
  }
`;

export const ViewContainer = styled.div`
  min-height: 30px;
  padding: 5px;
  display: flex;
  align-items: center;
  border: 1px dashed transparent;
  position: relative;

  .img-fit-cover {
    // max-height: 50px;
    object-fit: contain;
    width: auto;
  }

  .icon-edit {
    line-height: 0px;
    display: none;
    position: absolute;
    transition: all 0.3s;
  }
  &:hover {
    border: 1px dashed var(--border-color-2);
    border-radius: 5px;
    .icon-edit {
      display: initial;
      right: 10px;
    }
  }
`;
