import React, { useState } from 'react';
import { SpanLang } from '@base/components';
import { UserSelect } from '@base/containers';
import MiModal from '@base/components/mi-modal';

const AssignRepModal: React.FC<any> = (props) => {
  const {
    show = false,
    users = [],
    title = <SpanLang keyLang={'crm_new_generalsetting_assign_user'} />,
    single = false,
    isAssigning = false,
    onAssign,
    onClose,
  } = props;
  //state
  const [selectedUsers, setSelectedUsers] = useState(users);

  //select user
  const handleUserChange = (items: any) => {
    setSelectedUsers(items);
  };

  //start save
  const handleSave = () => {
    onAssign && onAssign(selectedUsers);
    //onClose();
  };

  return (
    <MiModal title={title} size="md" isOpen={show} onClose={onClose}>
      <MiModal.Body>
        <div className="pd-y-15">
          <UserSelect value={selectedUsers} onChange={handleUserChange} single={single} />
        </div>
      </MiModal.Body>
      <MiModal.Footer onClose={onClose} onSave={handleSave} saveLabel={'Assign'} saveLoading={isAssigning} />
    </MiModal>
  );
};

export default AssignRepModal;
