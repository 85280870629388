import { BLOCK_STORAGE_SERVER, GRAPHQL_LOCAL_SERVER, GRAPHQL_SERVER, STORAGE_UPLOAD_SERVER } from '@base/config/graphql';
import axios from 'axios';

import { axiosHelper } from './helper';

// axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  function (config) {
    console.log('just before request successfull');
    return config;
  },
  function (err) {
    console.log('just before request failrue');
    return Promise.reject(err);
  },
);

axios.interceptors.response.use(
  function (response) {
    console.log('just before response successfull');
    return response;
  },
  function (err) {
    console.log('just before response failrue');
    return Promise.reject(err);
  },
);

interface Paging {
  totalPage: number;
  totalItems: number;
  currentPage: number;
  itemPerPage: number;
  nextPage?: number | null;
  previousPage?: number | null;
}

interface DatasPayload<T> {
  data: {
    [key: string]: {
      results: T;
      paging: Paging;
    };
  };
  errors: {
    message: string;
  }[];
}

export interface DataIndex<T> {
  [key: string]: T;
}
export interface DataPayload<T> {
  data: {
    [key: string]: T;
  };
  errors: {
    message: string;
  }[];
}

export interface DatasPromise<T> {
  data: T;
  paging: Paging;
}

export type PostQueryKey = string[];

function GetGraphQLServer(): string {
  let serverAPI = GRAPHQL_SERVER;
  if (document.location.origin.indexOf('localhost') != -1) {
    serverAPI = GRAPHQL_LOCAL_SERVER;
  }
  //// console.log('server api', serverAPI);
  return serverAPI;
}

export async function graphQLApi<T>(key: string, query: string, variables?: any): Promise<T> {
  const helper = axiosHelper();
  try {
    const response = await axios.post<DataPayload<T>>(
      GetGraphQLServer(),
      {
        query,
        variables: helper.variablesHandler(variables),
      },
      {
        headers: helper.headerHandler(),
      },
    );

    if (response.data.errors) {
      throw JSON.stringify(response.data.errors);
    }
    return response.data.data[key];
  } catch (error: any) {
    throw helper.errorHandler(error);
  }
}

export async function graphQLGetsApi<T>(
  key: string,
  query: string,
  variables?: any,
): Promise<DatasPromise<T>> {
  const helper = axiosHelper();
  try {
    const response = await axios.post<DatasPayload<T>>(
      GetGraphQLServer(),
      {
        query,
        variables: helper.variablesHandler(variables),
      },
      {
        headers: helper.headerHandler(),
      },
    );

    if (response.data.errors) {
      throw JSON.stringify(response.data.errors);
    }

    const { results: data, paging } = response.data.data[key];
    const isNextPage =
      (paging?.currentPage || 1) * (paging?.itemPerPage || 10) < (paging?.totalItems || 0);
    const isPreviousPage = (paging?.currentPage || 1) <= 1;

    return {
      data,
      paging: {
        ...paging,
        nextPage: isNextPage ? (paging?.currentPage || 1) + 1 : null,
        previousPage: isPreviousPage ? (paging?.currentPage || 1) - 1 : null,
      },
    };
  } catch (error: any) {
    throw helper.errorHandler(error);
  }
}

/** ============================== S3 upload api ================================ */

export async function s3CreateUploadApi<T>(variables: any): Promise<T> {
  const helper = axiosHelper();
  try {
    const response = await axios.post<any>(
      `${STORAGE_UPLOAD_SERVER}/CreateUpload`,
      variables, //{ filename: 'test1', mediaType: 'pdf' },
      { headers: helper.uploadHeaderHandler(false) },
    );
    if (response.data.errors) {
      throw JSON.stringify(response.data.errors);
    }
    return response.data;
  } catch (error: any) {
    throw helper.errorHandler(error);
  }
}

export async function s3GetPresignedUrlPartsApi<T>(variables: any): Promise<T> {
  const helper = axiosHelper();
  try {
    const response = await axios.post<any>(
      `${STORAGE_UPLOAD_SERVER}/UploadParts`,
      variables, //{ uploadId: 'JCIbEOTASw6eo0CAleQAxg==', partNumber: 1 },
      { headers: helper.uploadHeaderHandler(false) },
    );
    if (response.data.errors) {
      throw JSON.stringify(response.data.errors);
    }
    return response.data;
  } catch (error: any) {
    throw helper.errorHandler(error);
  }
}

export async function s3UploadPartApi<T>(
  url: string,
  data: any,
  progressCallBack: any,
): Promise<any> {
  const helper = axiosHelper();
  try {
    const response = await axios.put<any>(url, data, {
      headers: helper.uploadHeaderHandler(true),
      onUploadProgress: (pEvent: ProgressEvent) => {
        progressCallBack && progressCallBack(pEvent);
      },
    });
    if (response.data.errors) {
      throw JSON.stringify(response.data.errors);
    }
    return response.headers;
  } catch (error: any) {
    throw helper.errorHandler(error);
  }
}

export async function s3CompleteUploadApi<T>(variables: any): Promise<T> {
  const helper = axiosHelper();
  try {
    const response = await axios.post<any>(
      `${STORAGE_UPLOAD_SERVER}/CompleteUpload`,
      variables, //{ uploadId: 'JCIbEOTASw6eo0CAleQAxg==', parts: [{uploadId: 'JCIbEOTASw6eo0CAleQAxg==', number: 1, etag: ''}] },
      { headers: helper.uploadHeaderHandler(false) },
    );
    if (response.data.errors) {
      throw JSON.stringify(response.data.errors);
    }
    return response.data;
  } catch (error: any) {
    throw helper.errorHandler(error);
  }
}

export async function s3GetDownloadObjectPresignedUrlApi<T>(variables: any): Promise<T> {
  const helper = axiosHelper();
  try {
    const response = await axios.post<any>(
      `${STORAGE_UPLOAD_SERVER}/DownloadObject`,
      variables, //{ id: 'JCIbEOTASw6eo0CAleQAxg==' },
      { headers: helper.uploadHeaderHandler(false) },
    );
    if (response.data.errors) {
      throw JSON.stringify(response.data.errors);
    }
    return response.data;
  } catch (error: any) {
    throw helper.errorHandler(error);
  }
}

export async function s3DownloadObjectApi<T>(url: string, progressCallBack?: any): Promise<any> {
  const helper = axiosHelper();
  try {
    const response = await axios.get<any>(url, {
      responseType: 'stream', //'blob'
      headers: helper.uploadHeaderHandler(false),
      onDownloadProgress: (pEvent: ProgressEvent) => {
        progressCallBack && progressCallBack(pEvent);
      },
    });
    if (response.data.errors) {
      throw JSON.stringify(response.data.errors);
    }
    return response.data;
  } catch (error: any) {
    throw helper.errorHandler(error);
  }
}

export async function s3DeleteObjectApi<T>(variables: any): Promise<T> {
  const helper = axiosHelper();
  try {
    const response = await axios.post<any>(
      `${STORAGE_UPLOAD_SERVER}/DeleteObject`,
      variables, //{ id: 'JCIbEOTASw6eo0CAleQAxg==' },
      { headers: helper.uploadHeaderHandler(false) },
    );
    if (response.data.errors) {
      throw JSON.stringify(response.data.errors);
    }
    return response.data;
  } catch (error: any) {
    throw helper.errorHandler(error);
  }
}

export async function s3AbortUploadApi<T>(variables: any): Promise<T> {
  const helper = axiosHelper();
  try {
    const response = await axios.post<any>(
      `${STORAGE_UPLOAD_SERVER}/DeleteUpload`,
      variables, //{ id: 'JCIbEOTASw6eo0CAleQAxg==' },
      { headers: helper.uploadHeaderHandler(false) },
    );
    if (response.data.errors) {
      throw JSON.stringify(response.data.errors);
    }
    return response.data;
  } catch (error: any) {
    throw helper.errorHandler(error);
  }
}

/** ============================== blockstorage upload/download ================================ */
export async function storageCompleteUploadApi<T>(variables: any): Promise<T> {
  const helper = axiosHelper();
  try {
    const response = await axios.post<any>(
      `${BLOCK_STORAGE_SERVER}/complete-upload`,
      variables, //{ uploadId: 'JCIbEOTASw6eo0CAleQAxg==', parts: [{uploadId: 'JCIbEOTASw6eo0CAleQAxg==', number: 1, etag: ''}] },
      { headers: helper.uploadHeaderHandler(false) },
    );
    if (response.data.errors) {
      throw JSON.stringify(response.data.errors);
    }
    return response.data;
  } catch (error: any) {
    throw helper.errorHandler(error);
  }
}
export async function storageCreateUploadApi<T>(variables: any): Promise<T> {
  const helper = axiosHelper();
  try {
    const response = await axios.post<any>(
      `${BLOCK_STORAGE_SERVER}/upload`,
      variables, //{ files: [FILE], module: 'XYZ' },
      { headers: helper.uploadHeaderHandler(true) },
    );
    if (response.data.errors) {
      throw JSON.stringify(response.data.errors);
    }
    return response.data;
  } catch (error: any) {
    // console.log('error axios', error);
    throw helper.errorHandler(error);
  }
}

export async function storageCreateChunkUploadApi<T>(variables: any): Promise<T> {
  const helper = axiosHelper();
  try {
    const response = await axios.post<any>(
      `${BLOCK_STORAGE_SERVER}/create-chunk-upload`,
      variables, //{ files: [FILE], module: 'XYZ' },
      { headers: helper.uploadHeaderHandler(true) },
    );
    if (response.data.errors) {
      throw JSON.stringify(response.data.errors);
    }
    return response.data;
  } catch (error: any) {
    console.log('error axios', error);
    throw helper.errorHandler(error);
  }
}

export async function storageCreateDownloadApi<T>(
  variables: any,
  progressCallBack?: any,
): Promise<T> {
  const helper = axiosHelper();
  try {
    const response = await axios.get<any>(`${BLOCK_STORAGE_SERVER}/download`, {
      responseType: 'stream', //'blob'
      headers: helper.uploadHeaderHandler(false),
      params: variables,
      onDownloadProgress: (pEvent: ProgressEvent) => {
        progressCallBack && progressCallBack(pEvent);
      },
    });
    if (response.data.errors) {
      throw JSON.stringify(response.data.errors);
    }
    return response.data;
  } catch (error: any) {
    throw helper.errorHandler(error);
  }
}

export async function storageChunkUploadPartApi<T>(data: any, progressCallBack: any): Promise<any> {
  const helper = axiosHelper();
  try {
    const response = await axios.put<any>(`${BLOCK_STORAGE_SERVER}/chunk-upload`, data, {
      headers: helper.uploadHeaderHandler(true),
      onUploadProgress: (pEvent: ProgressEvent) => {
        progressCallBack && progressCallBack(pEvent);
      },
    });
    if (response.data.error) {
      throw JSON.stringify(response.data.error);
    }
    return response.headers;
  } catch (error: any) {
    throw helper.errorHandler(error);
  }
}
/** ===================================== GRAPH FOR PUBLIC ================================== */

export async function graphQLPublicApi<T>(key: string, query: string, variables?: any): Promise<T> {
  const helper = axiosHelper();
  try {
    const response = await axios.post<DataPayload<T>>(
      GetGraphQLServer(),
      {
        query,
        variables,
      },
      {
        headers: helper.publicHeaderHandler(), //TODO: add token
      },
    );

    if (response.data.errors) {
      throw JSON.stringify(response.data.errors);
    }
    return response.data.data[key];
  } catch (error: any) {
    throw helper.errorHandler(error);
  }
}

export async function graphQLGetsPublicApi<T>(
  key: string,
  query: string,
  variables?: any,
): Promise<DatasPromise<T>> {
  const helper = axiosHelper();
  try {
    const response = await axios.post<DatasPayload<T>>(
      GetGraphQLServer(),
      {
        query,
        variables,
      },
      {
        headers: helper.publicHeaderHandler(), //TODO: add token
      },
    );

    if (response.data.errors) {
      throw JSON.stringify(response.data.errors);
    }

    const { results: data, paging } = response.data.data[key];
    const isNextPage =
      (paging?.currentPage || 1) * (paging?.itemPerPage || 10) < (paging?.totalItems || 0);
    const isPreviousPage = (paging?.currentPage || 1) <= 1;

    return {
      data,
      paging: {
        ...paging,
        nextPage: isNextPage ? (paging?.currentPage || 1) + 1 : null,
        previousPage: isPreviousPage ? (paging?.currentPage || 1) - 1 : null,
      },
    };
  } catch (error: any) {
    throw helper.errorHandler(error);
  }
}
