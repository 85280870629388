import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { getTextLength } from '@base/utils/helpers/validate';

const TextArea = (props: any) => {
  const {
    name,
    placeholder = '',
    rows = 4,
    className = '',
    value,
    checkLength = false,
    validateLength = 300,
    onChange = () => {},
    ...attrs
  } = props;

  const [contentLength, setContentLength] = useState(0);
  const onChangeTextarea = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    // console.log(newValue);
    const len = getTextLength(newValue);
    if (len > validateLength) return;
    onChange && onChange(newValue);
  }, []);

  useEffect(() => {
    if (value) {
      setContentLength(getTextLength(value));
    }
  }, [value]);

  //render
  return (
    <>
      <textarea
        className={`form-control ${className}`}
        name={name}
        value={value}
        onChange={onChangeTextarea}
        placeholder={placeholder}
        rows={rows}
        {...attrs}
      />
      {checkLength && (
        <div className="d-flex justify-content-end mg-t-5 mg-r-5">
          {contentLength} byte / {validateLength} Bytes
        </div>
      )}
    </>
  );
};

export default TextArea;
