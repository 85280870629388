import React from 'react';
import classnames from 'classnames';
import { DatePicker } from '@base/components/date-picker';

interface IProps {
  value: any;
  onChange: (params: any) => void;
  errors: any;
}

const Edit = (props: IProps) => {
  const { value, onChange, errors } = props;
  const shortDate = value?.length > 0 ? value.slice(0, 10) : '';

  return (
    <div className='wd-100p'>
      <div
        className={classnames({
          'is-required': errors?.isRequired,
        })}
      >
        <DatePicker
          selected={(shortDate !== '' && shortDate !== '0001-01-01') ? new Date(value) : null}
          onChange={(data: Date) => {
            onChange && onChange(data.toISOString());
          }}
        />
      </div>
      {errors?.isRequired && <div className="tx-danger tx-12">{errors?.isRequired}</div>}
    </div>
  );
};

export default Edit;
