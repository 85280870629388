import React from 'react';

const jpg = () => {
  return (
    <svg id="file-type-jpg" xmlns="http://www.w3.org/2000/svg" width="42px" height="51px" viewBox="0 0 42 51">
      <g>
        <path fill="#6BB061" d="M42,49c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h32l8,8V49z" />
        <polygon fill="#589E4E" points="42.003,14.828 34.615,7.44 42,8 	" />
        <g>
          <path fill="#AED7A8" d="M42,8h-6c-1.104,0-2-0.896-2-2V0L42,8z" />
        </g>
      </g>
      <g>
        <path
          fill="#6BB061"
          d="M42,50c0,0.553-0.447,1-1,1H19c-0.553,0-1-0.447-1-1V34c0-0.553,0.447-1,1-1h22c0.553,0,1,0.447,1,1V50z"
        />
        <path
          fill="#6AAF60"
          d="M41.719,49.594c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V49.594z"
        />
        <path
          fill="#69AE5F"
          d="M41.438,49.188c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V49.188z"
        />
        <path
          fill="#69AD5F"
          d="M41.156,48.781c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V48.781z"
        />
        <path
          fill="#68AC5E"
          d="M40.875,48.375c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V48.375z"
        />
        <path
          fill="#67AB5D"
          d="M40.594,47.969c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V47.969z"
        />
        <path
          fill="#66AA5C"
          d="M40.313,47.563c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V47.563z"
        />
        <path
          fill="#65A95C"
          d="M40.031,47.156c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V47.156z"
        />
        <path
          fill="#65A85B"
          d="M39.75,46.75c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V46.75z"
        />
        <path
          fill="#64A75A"
          d="M39.469,46.344c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V46.344z"
        />
        <path
          fill="#63A659"
          d="M39.188,45.938c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V45.938z"
        />
        <path
          fill="#62A558"
          d="M38.906,45.531c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V45.531z"
        />
        <path
          fill="#61A458"
          d="M38.625,45.125c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V45.125z"
        />
        <path
          fill="#60A357"
          d="M38.344,44.719c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V44.719z"
        />
        <path
          fill="#60A256"
          d="M38.063,44.313c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V44.313z"
        />
        <path
          fill="#5FA155"
          d="M37.781,43.906c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V43.906z"
        />
        <path
          fill="#5EA054"
          d="M37.5,43.5c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22c0.553,0,1,0.447,1,1
                    V43.5z"
        />
        <path
          fill="#5DA054"
          d="M37.219,43.094c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V43.094z"
        />
        <path
          fill="#5C9F53"
          d="M36.938,42.688c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.553,0.447-1,1-1h22
                    c0.553,0,1,0.447,1,1V42.688z"
        />
        <path
          fill="#5C9E52"
          d="M36.656,42.281c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.552,0.447-1,1-1h22
                    c0.553,0,1,0.448,1,1V42.281z"
        />
        <path
          fill="#5B9D51"
          d="M36.375,41.875c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.552,0.447-1,1-1h22
                    c0.553,0,1,0.448,1,1V41.875z"
        />
        <path
          fill="#5A9C51"
          d="M36.094,41.469c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.552,0.447-1,1-1h22
                    c0.553,0,1,0.448,1,1V41.469z"
        />
        <path
          fill="#599B50"
          d="M35.813,41.063c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.552,0.447-1,1-1h22
                    c0.553,0,1,0.448,1,1V41.063z"
        />
        <path
          fill="#589A4F"
          d="M35.531,40.656c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.552,0.447-1,1-1h22
                    c0.553,0,1,0.448,1,1V40.656z"
        />
        <path
          fill="#58994E"
          d="M35.25,40.25c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.552,0.447-1,1-1h22
                    c0.553,0,1,0.448,1,1V40.25z"
        />
        <path
          fill="#57984D"
          d="M34.969,39.844c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.552,0.447-1,1-1h22
                    c0.553,0,1,0.448,1,1V39.844z"
        />
        <path
          fill="#56974D"
          d="M34.688,39.438c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.552,0.447-1,1-1h22
                    c0.553,0,1,0.448,1,1V39.438z"
        />
        <path
          fill="#55964C"
          d="M34.406,39.031c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.552,0.447-1,1-1h22
                    c0.553,0,1,0.448,1,1V39.031z"
        />
        <path
          fill="#54954B"
          d="M34.125,38.625c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.552,0.447-1,1-1h22
                    c0.553,0,1,0.448,1,1V38.625z"
        />
        <path
          fill="#53944A"
          d="M33.844,38.219c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.552,0.447-1,1-1h22
                    c0.553,0,1,0.448,1,1V38.219z"
        />
        <path
          fill="#53934A"
          d="M33.563,37.813c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.552,0.447-1,1-1h22
                    c0.553,0,1,0.448,1,1V37.813z"
        />
        <path
          fill="#529249"
          d="M33.281,37.406c0,0.553-0.447,1-1,1h-22c-0.553,0-1-0.447-1-1v-16c0-0.552,0.447-1,1-1h22
                    c0.553,0,1,0.448,1,1V37.406z"
        />
        <path
          fill="#519148"
          d="M33,37c0,0.553-0.447,1-1,1H10c-0.553,0-1-0.447-1-1V21c0-0.552,0.447-1,1-1h22c0.553,0,1,0.448,1,1V37z"
        />
      </g>
      <g>
        <path
          fill="#4A8541"
          d="M34,39.23c0,0.553-0.447,1-1,1H11c-0.553,0-1-0.447-1-1V22c0-0.552,0.447-1,1-1h22c0.553,0,1,0.448,1,1
                    V39.23z"
        />
        <rect x="11" y="22" fill="#6BB061" width="20" height="14.23" />
        <g>
          <path
            fill="#FFFFFF"
            d="M32,20H10c-0.553,0-1,0.448-1,1v17.23c0,0.553,0.447,1,1,1h22c0.553,0,1-0.447,1-1V21
                        C33,20.448,32.553,20,32,20z M31,22v12.576c-1.355-0.316-4.121-0.525-6.119-0.576l-0.254-3.107c0.255,0.117,0.533,0.19,0.832,0.19
                        c1.104,0,2.001-0.89,2.001-1.986c0-0.685-0.349-1.286-0.879-1.644c-0.255-1.355-1.377-2.376-2.726-2.376
                        c-1.135,0-2.105,0.725-2.538,1.761c-0.847,0.331-1.444,1.129-1.444,2.063c0,1.23,1.035,2.229,2.313,2.229
                        c0.359,0,0.695-0.085,0.998-0.225c0.005,0.001,0.01,0.002,0.015,0.002L22.947,34c-3.522,0.079-9.291,0.552-11.947,1.215V22H31z"
          />
          <circle fill="#FFFFFF" cx="15.206" cy="25.881" r="1.698" />
        </g>
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M13.701,10.791c-0.764,0-1.268-0.336-1.604-0.735l0.68-0.756c0.279,0.308,0.553,0.49,0.896,0.49
                    c0.406,0,0.658-0.245,0.658-0.813V5.818h1.106v3.208C15.438,10.216,14.738,10.791,13.701,10.791z"
        />
        <path
          fill="#FFFFFF"
          d="M19.134,9.25h-0.819v1.471h-1.078V5.818h2.002c1.17,0,1.877,0.693,1.877,1.695v0.014
                    C21.115,8.662,20.233,9.25,19.134,9.25z M20.023,7.534c0-0.483-0.336-0.743-0.875-0.743h-0.834v1.499h0.854
                    c0.539,0,0.854-0.322,0.854-0.743V7.534z"
        />
        <path
          fill="#FFFFFF"
          d="M24.972,10.805c-1.526,0-2.577-1.071-2.577-2.521V8.27c0-1.394,1.085-2.536,2.57-2.536
                    c0.883,0,1.414,0.238,1.926,0.672l-0.68,0.819c-0.378-0.315-0.714-0.497-1.281-0.497c-0.785,0-1.408,0.693-1.408,1.527V8.27
                    c0,0.896,0.617,1.555,1.484,1.555c0.393,0,0.743-0.098,1.016-0.294V8.83h-1.085V7.898h2.129v2.129
                    C26.562,10.455,25.868,10.805,24.972,10.805z"
        />
      </g>
    </svg>
  );
};

export default jpg;
