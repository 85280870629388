import { ITab } from '@base/types/interfaces/app';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';

interface ISettingPageProps {
  tabs: ITab[];
}
const SettingPage: React.FC<ISettingPageProps> = (props: ISettingPageProps) => {
  const { tabs = [], ...remainProps } = props;
  const defaultActiveTab = tabs?.find((item) => item.default);
  const [activeTab, setActiveTab] = useState<ITab | undefined>(defaultActiveTab);
  // console.log('Tabs', tabs);
  return (
    <>
      <ul className="nav nav-line mg-b-15-f mg-x-10">
        {tabs &&
          tabs.map((item, index) => (
            <li className="nav-item" key={index}>
              <NavLink
                to={item.path}
                className={classNames('btn nav-link', {
                  active: item.path == activeTab?.path,
                })}
                onClick={(e) => {
                  setActiveTab(item);
                }}
              >
                {item.label}
              </NavLink>
            </li>
          ))}
      </ul>
      {/* render tab component */}
      <div className="page-container-wrap">
        <div className="page-container-content">
          <Routes>
            {tabs.length
              ? tabs.map((tab: ITab) => {
                  if ('show' in tab && !tab.show) {
                    return;
                  }
                  const TabComponent = tab.tabComponent;
                  const tabComponentProps = tab?.tabComponentProps;
                  return (
                    <React.Fragment key={tab.path}>
                      {tab.default ? <Route index element={<Navigate to={tab.path} />} /> : ''}
                      {
                        <Route
                          path={tab.path}
                          element={<TabComponent {...remainProps} {...tabComponentProps} />}
                        />
                      }
                    </React.Fragment>
                  );
                })
              : ''}
          </Routes>
        </div>
      </div>
    </>
  );
};

export default SettingPage;
