import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import TextView from '@base/containers/view-field/text/view';

interface IProductSelectViewProps {
  value: any;
  menuSourceId: string;
  useDeleteProduct: () => void;
}
const ProductSelectView: React.FC<IProductSelectViewProps> = (props: IProductSelectViewProps) => {
  const { value, useDeleteProduct, menuSourceId } = props;
  const [products, setProducts] = useState<any[]>([]);

  const callbackDeleteTag = (productId: any) => {
    let nProducts = products?.filter((product: any) => product.id != productId);
    setProducts(nProducts);
  };
  // console.log('useDeleteProduct', useDeleteProduct);
  const mDeleteProduct: any = useDeleteProduct ? useDeleteProduct() : null;
  const handleDeleteProduct = (id: string, tagId: string) => {
    if (mDeleteProduct) {
      mDeleteProduct.mutate(
        { id, tagId },
        {
          onSuccess: (res: any) => {
            callbackDeleteTag(tagId);
          },
        },
      );
    }
  };
  useEffect(() => {
    if (value) {
      setProducts(value);
    }
  }, [value]);
  return (
    <>
      {products && products.length ? (
        products.map((item, index) => {
          return (
            <div className="tag-item" key={index}>
              {item.name ?? ''}
              <button
                type="button"
                className="btn-tag-remove"
                onClick={(ev: any) => {
                  // console.log('AAAAAA');
                  handleDeleteProduct(menuSourceId, item.id);
                  ev?.preventDefault && ev.preventDefault();
                  ev?.stopPropagation && ev.stopPropagation();
                }}
              >
                <X />
                <span className="sr-only">삭제</span>
              </button>
            </div>
          );
        })
      ) : (
        <TextView value="" />
      )}
    </>
  );
};

export default ProductSelectView;
