import React, { useEffect, useRef, useState } from 'react';
import TextView from '@base/containers/view-field/text/view';
import { isArray } from 'lodash';
import { ModalHTML, Portal } from '@base/components';
import withLoading from '@base/hooks/hocs/withLoading';
import { IdName } from '@base/types/interfaces/common';

export interface QuickViewComponentProps {
  id: string;
  setLoading?: (is: boolean) => void;
  [x: string]: any;
}

interface TextAndPreviewModalProps {
  value: IdName[] | IdName;
  [x: string]: any;
}

export function withTextAndPreviewModal(QuickViewComponent: any, modalProps: any = {}) {
  return (props: TextAndPreviewModalProps) => {
    const { value, ...restProps } = props;

    const [previewedData, setPreviewedData] = useState<any | null>(null);

    const handleOnClick = (data: any, e: MouseEvent) => {
      if (!!e) {
        e.stopPropagation();
        e.preventDefault();
      }
      setPreviewedData(data);
    };

    const handleOnClickModalWrapper = (e: any) => {
      if (e.target === e.currentTarget) {
        e.stopPropagation();
        e.preventDefault();
        return;
      }
      const jCurrentTarget = e?.currentTarget ? $(e.currentTarget) : null;
      jCurrentTarget && jCurrentTarget.trigger('click');
    };

    const TextLink = ({ name, onClick }: any) => {
      return (
        <a href="#" onClick={onClick}>
          <TextView value={name} />
        </a>
      );
    };

    const QuickViewComponentWithLoading = withLoading(QuickViewComponent);

    const modalRef = useRef<any>(null);
    useEffect(() => {
      return () => {
        if (!!modalRef.current) {
          const jModal: any = $(modalRef.current);
          jModal?.modal('hide');
        }
      };
    });

    return (
      <>
        {!!value &&
          (isArray(value) ? (
            value.map((v: any, i: number) => {
              return (
                <TextLink
                  name={v?.name ?? ''}
                  key={i}
                  onClick={(e: MouseEvent) => {
                    handleOnClick(v, e);
                  }}
                />
              );
            })
          ) : (
            <TextLink
              name={value?.name ?? ''}
              onClick={(e: MouseEvent) => {
                handleOnClick(value, e);
              }}
            />
          ))}
        <div onClick={handleOnClickModalWrapper}>
          {previewedData !== null && (
            <Portal>
              <ModalHTML
                onModalInit={modalRef}
                onHide={() => setPreviewedData(null)}
                title={previewedData?.name ?? ''}
                className={'modal-preview'}
                centered={true}
                {...modalProps}
              >
                <QuickViewComponentWithLoading id={previewedData?.id} {...restProps} />
              </ModalHTML>
            </Portal>
          )}
        </div>
      </>
    );
  };
}

export default withTextAndPreviewModal;
