import React, { useEffect } from "react";
import { MENU_DESK } from "@base/config/menus";
import { Link } from "react-router-dom";
import withTextAndPreviewModal, { QuickViewComponentProps } from "@base/hooks/hocs/withTextAndPreviewModal";
import { useTicketView } from "@desk/ticket/services/ticket-service";

export const TicketQuickView = (props: QuickViewComponentProps) => {
  const { id, setLoading } = props;

  const { data, isLoading } = useTicketView(id);

  useEffect(() => {
    setLoading && setLoading(isLoading);
  }, [isLoading]);

  const url = `/${MENU_DESK}/ticket/${id}`;

  //render
  return (
    <div className="pos-relative pd-20">
      <div className='scroll-box' style={{ maxHeight: "calc(100vh - 210px)", overflowX: 'hidden' }}>
        <div className="form-group row">
          <div className="col-sm-8 d-flex align-items-center">
            <Link to={url} className="tx-18 font-weight-bold">{data?.subject || ''}</Link>
          </div>
        </div>
        <div
          className="wd-100p toastui-editor-contents"
          dangerouslySetInnerHTML={{ __html: data?.content || '' }}
        />
      </div>
    </div>
  );
};

export default withTextAndPreviewModal(TicketQuickView, { title: 'Ticket Information' });