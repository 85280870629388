import React, { useEffect, useRef, useState } from 'react';
import { ITemplate } from '@base/types/interfaces/setting';
import TuiEditor from '@base/components/form/tui-editor';
import { IBaseTemplateData } from '@base/utils/helpers/templates-utils';
import SelectTemplate from '@base/containers/select-template';
import { ETemplateGroup } from '@base/types/enums';

interface IEditorEditProps {
  value: any;
  onChange: (params: any) => {};
  menuSource: string;
  templateGroup: ETemplateGroup;
}
const EditorTemplateEdit: React.FC<IEditorEditProps> = (props: IEditorEditProps) => {
  const { value, onChange, menuSource, templateGroup } = props;
  const defaultTplValue =
    value && value.tpl
      ? {
          id: value.tpl.id,
          name: value.tpl.name,
        }
      : {
          id: '',
          name: 'No Selected',
        };
  const [templateSelected, setTemplateSelected] = useState<ITemplate>(defaultTplValue);
  const initContentValue = value && value.content ? value.content : '';
  const [content, setContent] = useState(initContentValue);
  const handleSelectTemplateChange = (tpl: IBaseTemplateData) => {
    let sTpl: ITemplate = {
      id: tpl.id,
      name: tpl.name,
    };
    setTemplateSelected(sTpl);
    // do setconent
    if (tpl.html) {
      const htmlJson = JSON.parse(tpl.html ?? '{}');
      const cssStr = `<style>${htmlJson?.css ?? ''}</style>`;

      const htmlString = htmlJson?.html ?? '';
      const nContent = htmlString + cssStr;
      // console.log('nContent', nContent);
      setContent(nContent);
    }
    let newValue = {
      tpl: {
        id: tpl.id,
        name: tpl.name,
      },
      html: tpl.html ?? '',
    };
    onChange && onChange(newValue);
  };
  return (
    <>
      <div className={'mg-b-10'}>
        <SelectTemplate
          useSelectBox={true}
          // menuSource={menuSource}
          templateGroup={templateGroup}
          options={[]}
          value={templateSelected}
          onChange={(item) => handleSelectTemplateChange(item)}
        />
      </div>
      {/* <TextArea value={value} onChange={(data: any) => onChange(data)} /> */}
      <TuiEditor
        value={content}
        onChange={(newContent: string) => {
          let newValue = value
            ? {
                ...value,
                content: newContent,
              }
            : {
                tpl: null,
                content: newContent,
              };
          onChange && onChange(newValue);
        }}
      />
    </>
  );
};

export default EditorTemplateEdit;
