import Lookup from '@base/containers/lookup';
import React from 'react';

interface LookupEditProps {
  value: any;
  onChange: (params: any) => void;
  fieldValue: string;
  fieldLabel: string;
  isSearch: boolean;
  isMultiple: boolean;
  fetchList: (params: any) => void;
  extraParams?: Object;
}
const LookupEdit = (props: LookupEditProps) => {
  //render
  return <Lookup {...props} />;
};

export default LookupEdit;
