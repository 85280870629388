import React from 'react';
import { Link } from 'react-router-dom';
import { isFunction } from 'lodash';

interface ITitleViewProps {
  menuSourceId?: string;
  value: any;
  link?: string;
  onClickLink?: any;
}
const TitleView: React.FC<ITitleViewProps> = (props: ITitleViewProps) => {
  const { menuSourceId, value, link, onClickLink } = props;

  return (
    <>
      {link ? (
        <Link
          onClick={(e: any) => {
            if (onClickLink && typeof onClickLink != 'undefined' && isFunction(onClickLink)) {
              // console.log('...onClickLink...', onClickLink);
              onClickLink && onClickLink(menuSourceId);
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          to={link}
          className="grid-list-item-title link-02"
        >
          <span className="tx-bold">{value}</span>
        </Link>
      ) : (
        <h1
          onClick={() => {
            onClickLink && onClickLink(value);
          }}
          className="view-title"
        >
          {value}
        </h1>
      )}
    </>
  );
};

export default TitleView;
