import React, { useEffect } from 'react';
import classNames from 'classnames';
import './react-confirm-alert.scss';
import ModalHTML from '../html-modal';
import { createRoot } from 'react-dom/client';
import Portal from '../portal';

interface ConfirmAlertProps {
  title: string;
  message: string;
  buttons: any;
  childrenElement?: any;
  customUI?: (params: any) => {};
  closeOnClickOutside?: boolean;
  closeOnEscape?: boolean;
  willUnmount?: () => void;
  afterClose?: () => void;
  onClickOutside?: () => void;
  onKeypressEscape?: (params: any) => void;
  overlayClassName?: string;
}
/**
 * author: trungtm
 * reference: react-confirm-alert
 */
const ReactConfirmAlert: React.FC<ConfirmAlertProps> = (props: ConfirmAlertProps) => {
  let $modal: any;
  // let overlay: any;

  useEffect(() => {
    document.addEventListener('keydown', keyboardClose, false);
    return () => {
      document.removeEventListener('keydown', keyboardClose, false);
      props.willUnmount && props.willUnmount();
    };
  });
  const keyboardClose = (event: any) => {
    const { closeOnEscape, onKeypressEscape } = props;
    const isKeyCodeEscape = event.keyCode === 27;

    if (closeOnEscape && isKeyCodeEscape) {
      onKeypressEscape && onKeypressEscape(event);
      close();
    }
  };
  const handleClickButton = (button: any) => {
    if (button.onClick) button.onClick();
    close();
  };

  // const handleClickOverlay = (e: any) => {
  //   const { closeOnClickOutside, onClickOutside } = props;
  //   const isClickOutside = e.target === overlay;

  //   if (closeOnClickOutside && isClickOutside) {
  //     onClickOutside();
  //     close();
  //   }
  // };

  const close = () => {
    $modal.modal('hide');
  };

  const onClose = (input: any) => {
    const { afterClose } = props;

    removeBodyClass();
    removeElementReconfirm();
    removeSVGBlurReconfirm(afterClose);
  };

  const renderCustomUI = () => {
    const { title, message, buttons, customUI } = props;
    const dataCustomUI = {
      title,
      message,
      buttons,
      onClose: close,
    };

    return customUI && customUI(dataCustomUI);
  };

  const render = () => {
    const { title, message, buttons, customUI } = props;
    //// console.log('confirm-alert -render');
    return (
      <Portal id={document.body}>
        <ModalHTML
          onModalInit={(el: any) => ($modal = el)}
          title={title}
          onHide={onClose.bind(this)}
          isStatic={true}
          className="confirm-alert-modal"
        >
          {typeof customUI != 'undefined' ? (
            renderCustomUI()
          ) : (
            <>
              <div className="modal-body">
                <p className="mg-b-0 tx-center tx-20">{message}</p>
              </div>
              <div className="modal-footer">
                <div className="react-confirm-alert-button-group">
                  {buttons.map((button: any, i: number) => (
                    <button
                      key={i}
                      onClick={() => handleClickButton(button)}
                      className={classNames('btn', button.className)}
                    >
                      {button.label}
                    </button>
                  ))}
                </div>
              </div>
            </>
          )}
        </ModalHTML>
      </Portal>
    );
  };

  return render();
};

ReactConfirmAlert.defaultProps = {
  buttons: [
    {
      label: 'Cancel',
      onClick: () => null,
      className: null,
    },
    {
      label: 'Confirm',
      onClick: () => null,
      className: null,
    },
  ],
  childrenElement: () => null,
  closeOnClickOutside: true,
  closeOnEscape: true,
  willUnmount: () => null,
  afterClose: () => null,
  onClickOutside: () => null,
  onKeypressEscape: () => null,
};
export default ReactConfirmAlert;

let rootConfirmAlert: any = null;
function removeSVGBlurReconfirm(afterClose: any) {
  const svg = document.getElementById('react-confirm-alert-firm-svg');

  if (svg) {
    svg?.parentNode?.removeChild(svg);
  }
  document.body.children[0].classList.remove('react-confirm-alert-blur');
  afterClose();
}

function createElementReconfirm(properties: any) {
  let divTarget = document.getElementById('react-confirm-alert');

  if (divTarget) {
    // Rerender - the mounted ReactConfirmAlert}
    if (!rootConfirmAlert) {
      rootConfirmAlert = createRoot(divTarget);
    }
    rootConfirmAlert.render(<ReactConfirmAlert {...properties} />);
  } else {
    // Mount the ReactConfirmAlert component
    document.body.children[0].classList.add('react-confirm-alert-blur');
    divTarget = document.createElement('div');
    divTarget.id = 'react-confirm-alert';
    document.body.appendChild(divTarget);
    if (!rootConfirmAlert) {
      rootConfirmAlert = createRoot(divTarget);
    }
    rootConfirmAlert.render(<ReactConfirmAlert {...properties} />);
  }
}

function removeElementReconfirm() {
  const target = document.getElementById('react-confirm-alert');

  if (target) {
    // unmountComponentAtNode(target);
    // // console.log('target', target);
    // const rootEl = hydrateRoot(target);
    // rootEl.unmount();
    // if (rootConfirmAlert?.unmount) {
    //   rootConfirmAlert.unmount();
    // }

    target?.parentNode?.removeChild(target);
  }
}

function addBodyClass() {
  document.body.classList.add('react-confirm-alert-body-element');
}

function removeBodyClass() {
  document.body.classList.remove('react-confirm-alert-body-element');
}

export function confirmAlert(properties: any) {
  addBodyClass();
  // createSVGBlurReconfirm()
  createElementReconfirm(properties);
}
