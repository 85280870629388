import React from 'react';
import SelectDropdown from '@base/components/form/select-dropdown';
import SelectDropdownWithDates from '@base/components/form/select-dropdown-dates';
import SelectDropdownFilters from '@base/components/form/select-dropdown-filters';

interface ListHeaderFilterNormalMode {
  groupByOptions?: any[];
  filterByOptions?: any[];
  dateByOptions?: any[];
  filterData: { [key: string]: any };
  onFilterDataChanged: (value: any, type: string) => void;
  sort?: React.ReactNode;
}

const ListHeaderFilterNormalMode = (props: ListHeaderFilterNormalMode) => {
  const { groupByOptions, filterByOptions, dateByOptions, filterData, onFilterDataChanged, sort } =
    props;

  return (
    <>
      {/* Group by */}
      {groupByOptions && groupByOptions?.length > 0 && (
        <SelectDropdown
          options={groupByOptions}
          selected={filterData?.groupBy ?? ''}
          placeholder="Group By"
          onChange={(data: any) => onFilterDataChanged(data.value, 'group_by')}
        />
      )}

      {/* Select date */}
      {dateByOptions && dateByOptions?.length > 0 && (
        <SelectDropdownWithDates
          placeholder="All"
          options={dateByOptions}
          onChange={(data: any) => {
            onFilterDataChanged(data, 'date');
          }}
        />
      )}

      {/* Filters */}
      {filterByOptions && filterByOptions?.length > 0 && (
        <SelectDropdownFilters
          placeholder="Filters"
          options={filterByOptions}
          onChange={(data: any) => onFilterDataChanged(data, 'filter')}
          // selected={{ active: true }} // default filters
        />
      )}

      {/* Sorts */}
      {!!sort && sort}
    </>
  );
};

export default ListHeaderFilterNormalMode;
