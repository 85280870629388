import React from 'react';
//display raw html
interface IRawHTMLProps {
  children?: string;
  nl2br?: boolean;
  tag?: string;
}

const RawHTML = (props: IRawHTMLProps) => {
  let { children, tag = 'div', nl2br = true, ...rest } = props;

  return React.createElement(tag, {
    dangerouslySetInnerHTML: {
      __html: nl2br ? children && children.replace(/\n/g, '<br />') : children,
    },
    ...rest,
  });
};

export default RawHTML;
