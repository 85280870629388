import React from 'react';
import TextView from '@base/containers/view-field/text/view';
import { convertDateTimeServerToClient } from '@base/utils/helpers/general.utils';

interface DateViewProps {
  value: string;
  showTimeInput?: boolean;
}

const DateView = (props: DateViewProps) => {
  const { value, showTimeInput = false } = props;

  return (
    <TextView
      value={convertDateTimeServerToClient({ date: value, isTime: showTimeInput, humanize: true })}
    />
  );
};

export default DateView;
