import React, { useState, useMemo, useEffect, useCallback, ChangeEvent } from 'react';
import { SpanLang } from '@base/components/form';
import { isArray, uniqueId, isObject } from 'lodash';

interface RadioGroupProps {
  value: any;
  options: any[];
  name?: string;
  fieldValue?: string;
  fieldLabel?: string;
  isVertical?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (params: any) => void;
}

const RadioGroup = (props: RadioGroupProps) => {
  const {
    value = null, // {[fieldValue]: value, [fieldLabel]: label}
    options = [],
    name = uniqueId('radio-button-'),
    fieldValue = 'value',
    fieldLabel = 'label',
    isVertical = false,
    disabled = false,
    readOnly = false,
    onChange = null,
  } = props;

  const [curValue, setCurValue] = useState(_getValue(value));

  function _getValue(_value: object) {
    if (_value) {
      if (isArray(_value)) return _value[0];
      return _value;
    }
    return options.length > 0 ? options[0][fieldValue]?.toString() : '';
  }

  useEffect(() => {
    const dataValue = Array.isArray(value) ? value[0] : value;
    if (isObject(dataValue)) {
      setCurValue(_getValue(dataValue));
      // return dataValue;
    } else {
      const findData = options.find((x: any) => x[fieldValue] === dataValue);
      setCurValue(_getValue(findData ? findData : null));
    }
  }, [value, options]);

  const onClick = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.currentTarget.value;
      if (newValue.toString() !== curValue?.[fieldValue]?.toString()) {
        onChange && onChange(options.find((item: any) => item[fieldValue] == newValue));
      }
    },
    [curValue],
  );

  return (
    <div className="wd-100p">
      <div className={`${isVertical ? 'han-choice-vertical pd-b-10' : 'd-flex pd-b-10'}`}>
        {options.map((item: any, index: number) => {
          return (
            <div key={index} className="custom-control custom-radio mg-r-10">
              <input
                type="radio"
                className="custom-control-input"
                value={item[fieldValue]}
                id={`${name}-${index}`}
                name={name}
                checked={curValue?.[fieldValue]?.toString() === item[fieldValue]?.toString()}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  return readOnly ? null : onClick(e);
                }}
                readOnly
                disabled={disabled || item?.disabled}
              />
              <SpanLang
                tag={'label'}
                keyLang={item[fieldLabel]}
                forID={`${name}-${index}`}
                className="custom-control-label"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RadioGroup;
