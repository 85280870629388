import React, { useEffect, useState } from 'react';
import GrapesTS from '@base/components/grapes-ts';

const Edit: React.FC = (props: any) => {
  const { value, onChange } = props;

  return (
    <GrapesTS
      isFullScreen={false}
      height={'calc(100vh - 290px)'}
      storageId={'grapes-ts-view-gts'}
      value={value}
      onChange={onChange}
    />
  );
};

export default Edit;
