import React, { useEffect, useMemo, useState } from 'react';
import { nanoid } from '@base/utils/helpers/general.utils';

const Switch: React.FC<any> = (props: any) => {
  const {
    name = nanoid(),
    title,
    label,
    className = '',
    noLabel,
    onChange,
    value,
    disabled = false,
    defaultChecked = false,
    ...letProps
  } = props;

  const [checked, setChecked] = useState(value ?? defaultChecked);

  const hanlderOnChange = (e: any) => {
    onChange && onChange(e);
  };

  useEffect(() => {
    if (typeof value != 'undefined' && value != checked) {
      setChecked(value);
    }
    return () => {};
  }, [value]);

  const renderSwitch = () => {
    return (
      <div className={'custom-control custom-switch ' + className}>
        <input
          type="checkbox"
          className="custom-control-input"
          id={name}
          name={name}
          //{...letProps}
          // defaultChecked={checked}
          checked={checked}
          onChange={hanlderOnChange}
          disabled={disabled}
        />
        <label className={'custom-control-label'} htmlFor={name}>
          {title}
        </label>
      </div>
    );
  };

  return noLabel ? (
    <>{renderSwitch()}</>
  ) : (
    <div className={'form-group-switch tx-nowrap ' + className}>
      {label && (
        <label className="mg-r-5" htmlFor={name}>
          {label}
        </label>
      )}
      {renderSwitch()}
    </div>
  );
};

export default Switch;
