import React, { useEffect, useMemo, useRef, useState } from 'react';
import _, { isFunction } from 'lodash';
import classNames from 'classnames';
import { ChevronDown, ChevronRight } from 'react-feather';
import useDidUpdateEffect from '@base/hooks/useDidUpdateEffect';
import { SelectWrapper } from '@base/components/form/select-dropdown';
import Portal from '@base/components/portal';
import { generateUUID } from '@base/utils/helpers/general.utils';

import $ from 'jquery';
import '@base/utils/helpers/jquery';

const SelectDropdownFilters = ({
  className,
  placeholder = 'Select',
  down = true,
  options = [],
  selected,
  onChange = () => null,
  icon = null,
  separate = false,
  width,
  prefix = null,
  buttonStyles = {},
  hideLabel = false,
  outSide = false,
}: any) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedOptionLabel, setSelectedOptionLabel] = useState<string | null>(null); //label of selected options
  const [dropdownStyle, setDropdownStyle] = useState<any>({});
  const [opened, setOpened] = useState<any>({}); //selected option
  const [filter, setFilter] = useState<any>({});
  const containerRef = useRef<any>(null);
  const formRef = useRef<any>(null);
  const id = useRef<any>(generateUUID());

  //// console.log('opened', opened);
  //// console.log('filter', filter);
  //// console.log('selectedOptionLabel', selectedOptionLabel);

  const [filterOptions, setFilterOptions] = useState<any>({});

  //init values
  useEffect(() => {
    if (selected && !_.isEmpty(selected)) {
      if (JSON.stringify(selected) !== JSON.stringify(filter)) {
        setFilter(selected);
        const openingOptions: any = {};
        Object.keys(selected).map((_key: string) => {
          const foundOpt = options.find((_ele: any) => _ele.value === _key);
          openingOptions[_key] = foundOpt;
        });
        setOpened(openingOptions);
      }
    } else {
      setFilter({});
      setOpened({});
    }
  }, [selected]);

  useDidUpdateEffect(() => {
    if (showDropdown) {
      $(document)
        .off('click.date-dropdown')
        .on('click.date-dropdown', (e: any) => {
          if (
            !$(e.target).parents('.dropdown-menu').length &&
            !$(e.target).parents('.dropdown').length
          ) {
            // setShowDropdown(false);
          }
        });

      let $container: any = $(containerRef.current),
        $btn = $container.find('> .btn:first');

      setDropdownStyle({
        // zIndex: 999999,
        left: $btn.offset().left,
        top: $btn.offset().top + $container.outerHeight(),
      });
    } else {
      $(document).off('click.date-dropdown');
    }
  }, [showDropdown]);

  const SelectedLabel = useMemo<any>(() => {
    //TODO: opened labels
    return selectedOptionLabel ? (
      <span>
        <strong>{selectedOptionLabel}</strong>
      </span>
    ) : (
      placeholder
    );
  }, [selectedOptionLabel, opened]);

  const onSubmit = (isReset: boolean) => {
    // just filters field opening
    let newFilter: any = {};
    if (isReset) {
      // newFilter = { all: true };
      newFilter = {}; // all => none filters
    } else {
      Object.keys(filter).map((key) => {
        const value = filter[key];
        if (opened[key]) {
          newFilter[key] = value;
        }
      });
    }

    // console.log('newFilter', newFilter);

    setShowDropdown(false);
    onChange && onChange(newFilter);
  };

  const onReset = () => {
    // reset data
    // formRef.current.reset();
    setFilter({});
    setOpened({});
    onSubmit(true);
  };

  const onOpenFilter = (opt: any) => {
    if (!opened[opt.value]) {
      opened[opt.value] = opt;
    } else {
      delete opened[opt.value];
    }
    setOpened({ ...opened });
  };

  const renderDefinedComponent = (opt: any) => {
    const RenderComponent = opt?.component ?? null;
    return (
      <div className="filter-component collapse show">
        {RenderComponent ? (
          <React.Suspense fallback={<></>}>
            <RenderComponent
              {...(opt?.componentProps ?? {})}
              onChange={(value: any) => {
                setFilter({
                  ...filter,
                  [opt.value]: isFunction(opt?.getValue) ? opt?.getValue(value) : value,
                });

                setFilterOptions({
                  ...filterOptions,
                  [opt.value]: value,
                });
              }}
              // TODO: can't set value for IDName object
              value={
                isFunction(opt?.setValue)
                  ? opt?.setValue(filter[opt.value])
                  : filterOptions[opt.value]
              }
            />
          </React.Suspense>
        ) : (
          <input
            autoFocus
            className="form-control"
            name={opt.value}
            value={filter[opt.value] || ''}
            onChange={(e) => {
              setFilter({
                ...filter,
                [opt?.value]: e?.target?.value ?? '',
              });
            }}
            type="text"
          />
        )}
      </div>
    );
  };

  return (
    <SelectWrapper
      ref={containerRef}
      id={id.current}
      className={classNames('dropdown list-filter-item', className, { 'to-body': outSide })}
      separate={separate}
      width={width}
    >
      {prefix}
      <button
        type="button"
        className="btn btn-filter pd-l-0"
        style={buttonStyles}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {icon}
        {!hideLabel && SelectedLabel}
        {Object.keys(opened).length > 0 ? ` (${Object.keys(opened).length})` : null}
        {down && <ChevronDown className="mg-l-5" />}
      </button>

      <Portal id={document.body}>
        <div
          className={classNames('dropdown-menu wd-300', { show: showDropdown })}
          style={dropdownStyle}
        >
          <form ref={formRef}>
            <button className="dropdown-item" type="button" onClick={onReset}>
              All
            </button>
            <div className="dropdown-divider" />
            {options.map((opt: any) => {
              return (
                <div key={opt.value}>
                  <button
                    type="button"
                    data-toggle="collapse"
                    className={classNames(
                      'btn d-flex wd-100p align-items-center tx-12 tx-semibold',
                      {
                        collapsed: !opened[opt.value],
                      },
                    )}
                    onClick={() => onOpenFilter(opt)}
                  >
                    <span className="mg-r-5">{opt.label}</span>
                    {!opened[opt.value] ? (
                      <ChevronDown className="mg-l-auto" />
                    ) : (
                      <ChevronRight className="mg-l-auto" />
                    )}
                  </button>
                  {opened[opt.value] && renderDefinedComponent(opt)}
                </div>
              );
            })}
            <div className="dropdown-divider" />
            <div className="text-center">
              <button
                type="button"
                onClick={() => setShowDropdown(false)}
                className="btn btn-secondary btn-xs mr-2"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => onSubmit(false)}
                className="btn btn-primary btn-xs"
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </Portal>
    </SelectWrapper>
  );
};

export default SelectDropdownFilters;
