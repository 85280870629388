import React from 'react';
import '@toast-ui/editor/dist/toastui-editor.css';
interface IEditorViewProps {
  value: any;
}
const EditorView = (props: IEditorViewProps) => {
  const { value } = props;
  return (
    <div className="card card-body overflow-x-auto">
      <div
        className="wd-100p toastui-editor-contents"
        dangerouslySetInnerHTML={{ __html: value }}
      />
    </div>
  );
};

export default EditorView;
