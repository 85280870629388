import React, { useEffect, useState } from 'react';
import TextView from '@base/containers/view-field/text/view';

import { languagesAtom } from '@base/recoil/atoms';
import { useRecoilValue } from 'recoil';

const View: React.FC = (props: any) => {
  const { value } = props;

  const [valueLanguage, setValueLanguage] = useState<any>(null);

  const avaiLanguages: any = useRecoilValue(languagesAtom);

  useEffect(() => {
    if (avaiLanguages && avaiLanguages.length > 0) {
      avaiLanguages.map((_item: any) => {
        if (_item?.code == value) {
          setValueLanguage(_item);
        }
      });
    }
  }, [avaiLanguages]);

  return <TextView value={valueLanguage?.title ?? ''} />;
};

export default View;
