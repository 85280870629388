import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { getCurrentLang } from '@base/services/i18n';
import styled from '@emotion/styled';

//import HanSpanLine from 'Crm/components/HanPlaceHolder/HanSpanLine';
const SpanLine = styled.div`
  height: 12px;
  background: linear-gradient(
    to right,
    var(--gray-200) 8%,
    var(--gray-300) 18%,
    var(--gray-200) 33%
  );
  background-size: 500px 100px;
  animation-name: shimmer;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
`;
interface SpanLangProps {
  keyLang: string;
  className?: string;
  tag?: any;
  showKeyId?: boolean;
  key?: string;
  isHtml?: boolean;
  isString?: boolean;
  forID?: string;
  dataPos?: string;
  defaultText?: string;
  children?: any;
}

const SpanLang = (props: SpanLangProps): any => {
  const {
    keyLang,
    className,
    tag: Tag,
    showKeyId,
    key,
    isHtml,
    isString,
    forID,
    defaultText,
    dataPos,
    children,
    ...rest
  } = props;
  //user lang
  const { t } = useTranslation();
  const userLang = getCurrentLang();
  const hasLang = i18next.hasResourceBundle && i18next.hasResourceBundle(userLang, 'translation');

  const parse = () => {
    return defaultText && !i18next.exists(keyLang) ? defaultText : t(keyLang);
  };

  const attr: any = {
    className,
    key,
    //for: forID,
    htmlFor: forID,
  };

  if (showKeyId === true) attr['data-lang-id'] = keyLang;
  if (dataPos !== '') attr['data-pos'] = dataPos;
  if (isString) return parse();
  if (isHtml) {
    return <Tag {...attr} dangerouslySetInnerHTML={{ __html: parse() }} />;
  }
  return (
    <Tag {...attr} {...rest}>
      {children}
      {!hasLang && (
        <div className="wd-100">
          <SpanLine />
        </div>
      )}
      {hasLang && parse()}
    </Tag>
  );
};

SpanLang.defaultProps = {
  keyLang: '',
  // className: 'pd-l-5',
  className: '',
  tag: 'span',
  showKeyId: true,
  key: '',
  isHtml: false,
  isString: false,
  forID: '',
  dataPos: '',
  defaultText: '',
  children: null,
};

export default SpanLang;
