import React, { useMemo } from 'react';
// import { Check } from 'react-feather';
// import { convertDateTimeServerToClient } from '@base/utils/helpers/general.utils';
// import { isEmpty, isFunction, isString, isUndefined } from 'lodash';
import styled from '@emotion/styled';
//import ListGridItemPhoto from '@base/components/list/list-grid/list-grid-item/list-grid-item-photo';
import { Avatar } from '@base/components';
import { Image } from 'react-feather';

const FirstLetterSpan = styled.span`
  text-transform: capitalize;
`;

const ListGridItemPhotoContainer: React.FC<any> = ({ children, empty }) =>
  empty ? children : <span className="avatar-initial rounded">{children}</span>;

const ItemPhoto = (props: any) => {
  const { className = '', isSplitMode = false, url = '', alt = '' } = props;

  const getFirstCharacter = (label: string): string => {
    return label?.charAt?.(0) || '';
  };

  const PhotoComponent = useMemo(() => {
    return (
      <div className={className}>
        {url ? (
          <Avatar
            key={url}
            url={url}
            alt={''}
            height={isSplitMode ? 38 : 100}
            width={isSplitMode ? 38 : 100}
            isCircle={false}
            className={'rounded'}
          />
        ) : (
          <ListGridItemPhotoContainer empty={className === 'grid-list-item-pic'}>
            {alt ? <FirstLetterSpan>{getFirstCharacter(alt)}</FirstLetterSpan> : <Image />}
          </ListGridItemPhotoContainer>
        )}
      </div>
    );
  }, [url, isSplitMode]);

  return <>{PhotoComponent}</>;
};

export default ItemPhoto;