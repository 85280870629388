import React from 'react';
import classNames from 'classnames';

/**
 * author: trungtm
 * @param {*} param0
 */
const Content: React.FC<any> = (props: any) => {
  const { children, tabId, className, active } = props;

  //render
  return (
    <div id={tabId} className={classNames('tab-pane', className, { active: active })}>
      {children}
    </div>
  );
};

export default Content;
