import styled from '@emotion/styled';
import React, { FC, VFC } from 'react';
import { Layers } from 'react-feather';

const NothingWrap = styled.div`
  padding: 24px;
  text-align: center;
`;

const ProgressWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  ${(props: any) => props.theme?.progress && props.theme.progress.style};
`;

export const GridNothing = () => {
  return <NothingWrap>There are no records to display</NothingWrap>;
};

export const TableNothing = () => {
  return (
    <div className="text-center p-4">
      <Layers size={40} color="#ccc" />
      <p className="mt-2 mb-2 text-secondary">There are no records to display.</p>
    </div>
  );
};

const ListLoading: FC<any> = ({ children }) => {
  return (
    <ProgressWrapper>
      {children ?? (
        <div
          className="d-flex justify-content-center flex-column align-items-center"
          style={{ minHeight: '200px' }}
        >
          <div className="spinner-border text-secondary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p className="mt-2 mb-2 text-secondary">Loading data...</p>
        </div>
      )}
    </ProgressWrapper>
  );
};

export default ListLoading;
