import React from 'react';
import { ReminderState } from '.';
import { timeOptions, typeOptions } from './edit';

interface ViewProps {
  value: ReminderState;
}

const View = (props: ViewProps) => {
  const { value } = props;
  return (
    <>
      {value?.use ? (
        <div className="d-flex align-items-center">
          <div className="mg-r-15">
            {typeOptions.find((opt) => opt.value == value.notify)?.label}
          </div>
          <div>{timeOptions.find((opt) => opt.value == value.end)?.label}</div>
        </div>
      ) : (
        <label>Not Use</label>
      )}
    </>
  );
};

export default View;
