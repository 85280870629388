import React from 'react';
import { useNavigate } from 'react-router-dom';

interface noFoundProps {}

const NotFound = (props: noFoundProps) => {
  const navigate = useNavigate();

  return (
    <div className="container ht-100p tx-center">
      <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
        <div className="wd-70p wd-sm-250 wd-lg-300">
          <img src="/errors/404-error.gif" className="img-fluid" alt="Error 404" />
        </div>
        <h1 className="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5">Page Not Found</h1>
        <h5 className="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20">
          Oopps. The page you were looking for doesn't exist.
        </h5>
        <p className="tx-color-03 mg-b-30">
          You may have mistyped the address or the page may have moved.
        </p>
        <div className="d-flex mg-b-40">
          <button
            className="btn btn-brand-02 bd-0 mg-l-5 pd-sm-x-25"
            onClick={() => {
              navigate('/');
            }}
          >
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
