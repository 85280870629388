import { Select } from '@base/components/form';
import React from 'react';

const LanguageSetting: React.FC<any> = (props: any) => {
  const { value, onChange } = props;

  const langOptions = [
    {
      value: 'en',
      label: 'English',
      isFixed: true,
    },
    {
      value: 'ko',
      label: 'Korean',
      isFixed: true,
    },
    {
      value: 'vi',
      label: 'Vietnamese',
      isFixed: true,
    },
  ];

  const handleChangeLang = (newLanguage: any) => {
    onChange && onChange(newLanguage?.value);
  };

  return (
    <Select
      options={langOptions}
      value={langOptions?.find((v: any) => v.value === value) ?? null}
      onChange={handleChangeLang}
    />
  );
};

export default LanguageSetting;
