import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { getRange, isValidIPItemValue } from './helper';
import './style.scss';

const defaultProps = {
  className: 'form-control',
  value: '...',
  isError: () => false,
  onChange: new Function(),
  name: 'ip',
};

const IPInput = (props: any) => {
  const { className, isError, value, onChange } = props;

  const [values, setValues] = useState<any[]>([]);
  const [ip, setIp] = useState('');

  const refs = useRef<any>(Array.from({ length: value.length }, () => React.createRef()));

  const REGEX_IP = /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/gm;

  //init value
  useEffect(() => {
    if (value) {
      const newValues = value.split('.');
      setValues(newValues);
      //ip
      const _ip = newValues.map((val: number) => (isNaN(val) ? '' : val)).join('.');
      setIp(_ip);
    }
  }, [value]);

  // useEffect(() => {
  //   const _ip = value.map((val: number) => (isNaN(val) ? '' : val)).join('.');
  //   setIp(_ip);
  // }, [value]);

  const handleOnChange = (newValues: any[]) => {
    const _ip = newValues.map((val: number) => (isNaN(val) ? '' : val)).join('.');
    onChange && onChange(_ip);
  };

  const handleChange = (e: any, i: number): void => {
    let val = parseInt(e.target.value);

    if (isNaN(e.target.value)) {
      return e.preventDefault();
    }

    if (e.target.value !== '' && !isValidIPItemValue(val)) {
      val = 255;
    }

    let newValues = [...values];

    newValues[i] = val;
    setValues(newValues);

    if (!isNaN(val) && String(val).length === 3 && i < 3) {
      refs.current[i + 1].focus();
    }
    //callback
    handleOnChange(newValues);
  };

  // const handleKeyDown = (e: any, i: any) => {
  //   /* 37 = ←, 39 = →, 8 = backspace */
  //   let domId = i;

  //   if ((e.keyCode === 37 || e.keyCode === 8) && getRange(e.target).end === 0 && i > 0) {
  //     domId = i - 1;
  //   }
  //   if (e.keyCode === 39 && getRange(e.target).end === e.target.value.length && i < 3) {
  //     domId = i + 1;
  //   }
  //   refs.current[domId].focus();
  // };

  // const handlePaste = (e: any, i: any) => {
  //   if (!e.clipboardData || !e.clipboardData.getData) {
  //     return;
  //   }

  //   const pasteData = e.clipboardData.getData('text/plain');

  //   if (!pasteData) {
  //     return;
  //   }

  //   const _value = pasteData.split('.').map((v: string) => parseInt(v));

  //   if (_value.length !== 4 - i) {
  //     return;
  //   }

  //   if (!_value.every(isValidIPItemValue)) {
  //     return;
  //   }

  //   let newValue = [...value];

  //   _value.forEach((val: any, j: any) => {
  //     newValue[i + j] = val;
  //   });

  //   setValues(newValue);

  //   return e.preventDefault();
  // };

  const finalValue = () => {
    if (ip.match(REGEX_IP)) return ip;
    else return '';
  };

  return (
    <div className={classNames(className, 'react-ip-input ht-38', isError(ip) ? 'has-error' : '')}>
      {values?.map((val: number, i: number) => {
        return (
          <div className="react-ip-input__item" key={i}>
            <input
              ref={(el) => (refs.current[i] = el)}
              type="text"
              value={isNaN(val) ? '' : val}
              onChange={(e) => handleChange(e, i)}
            />
            {i !== 3 ? <i>.</i> : false}
          </div>
        );
      })}
      <input type={'hidden'} name={props.name} value={finalValue()} />
    </div>
  );
};

IPInput.defaultProps = defaultProps;

export default IPInput;
