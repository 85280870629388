/* eslint-disable react/react-in-jsx-scope */
const LineLoader = (props: any) => {
  const { rows = 1 } = props;

  function renderRow() {
    return (
      <div className="line-place-holder">
        <div className="placeholder-paragraph">
          <div className="line"></div>
        </div>
      </div>
    );
  }

  const rs = [];
  for (let i = 0; i < rows; i++) rs.push(renderRow());

  return <>{rs}</>;
};

export default LineLoader;
