import React, { useEffect, useState } from 'react';

const Percent: React.FC<any> = (props: any) => {
  const { value, onChange, className = '' } = props;

  const [curValue, setCurValue] = useState(value);

  useEffect(() => {
    if (value !== curValue) {
      setCurValue(value);
    }
  }, [value]);

  return (
    <div className={`input-group ${className}`}>
      <input
        type="number"
        className="form-control ht-38 tx-right"
        value={curValue}
        onChange={(e: any) => {
          let newValue = e.target.value;
          setCurValue(newValue);
          //callback
          onChange && onChange(newValue);
        }}
      />
      <div className="input-group-append">
        <span className="input-group-text ht-38">%</span>
      </div>
    </div>
  );
};

export default Percent;
