//import React, { Suspense } from 'react';
import Loading from '@base/components/loading';
import { useWriteForm } from '../useWriteForm';

const withWriteForm = (WrappedComponent: any) => (props: any) => {
  const attrs = useWriteForm({
    menu: props.menuApi,
    //id: props.id || ''
  });

  //// console.log('withWriteForm attrs', attrs, props.menuApi);
  //// console.log('withWriteForm props', props);

  return (
    <>
      {
        attrs.loading ? (
          <div style={{ minHeight: '420px' }}>
            <Loading />
          </div>
        ) : (
          // <Suspense fallback={<Loading />}>
          <WrappedComponent {...attrs} {...props} />
        )
        // </Suspense>
      }
    </>
  );
};

export default withWriteForm;
