import React from 'react';
import classNames from 'classnames';

import { FormIcon } from '@base/components/form/icon';
import { mapToCssModules } from '@base/utils/helpers/general.utils';
import { X } from 'react-feather';

const Button = (props: any) => {
  const {
    active,
    'aria-label': ariaLabel,
    block,
    className,
    close,
    cssModule,
    color,
    outline,
    size,
    children,
    innerRef,
    type,
    id,
    loading,
    icon,
    isIconsEnd,
    onClickIcon,
    isSpanTitle,
    onClickSpanTitle,
    classSpanIcon,
    iconType,
    classSpan,
    form,
    iconSize,
    iconClass,
    tooltip,
    notPaddingIcon,
    ...attributes
  } = props;
  let { name } = props;
  let { tag: Tag } = props;

  if (form !== '') {
    attributes.form = form;
  }

  if (tooltip) {
    attributes['data-han-tooltip'] = tooltip;
  }

  if (close && typeof name === 'undefined') {
    name = (
      <span aria-hidden>
        <X />
      </span>
    );
  }
  const btnOutlineColor = color ? `btn${outline ? '-outline' : ''}-${color}` : '';

  const classes = mapToCssModules(
    classNames(
      className,
      { close },
      close || 'btn',
      close || btnOutlineColor,
      size ? `btn-${size}` : false,
      block ? 'btn-block' : false,
      type === 'circle' ? `btn-circle-${size}` : false,
      {
        active,
        disabled: loading ? true : props.disabled,
      },
      tooltip ? 'han-tooltip' : '',
    ),
    cssModule,
  );

  if (attributes.href && Tag === 'button') {
    Tag = 'a';
  }

  const defaultAriaLabel = close ? 'Close' : null;

  const onClick = (e: any) => {
    if (props.disabled) {
      e.preventDefault();
      return;
    }

    if (props.onClick) {
      props.onClick(e);
    }
  };

  const getPaddingIcon = () => {
    let { children, icon, notPaddingIcon } = props;
    return children ? (icon ? (notPaddingIcon ? '' : 'mg-r-5') : 'mg-r-10') : '';
  };

  return (
    <Tag
      id={id ?? null}
      type={Tag === 'button' && attributes.onClick ? 'button' : undefined}
      {...attributes}
      className={classes}
      ref={innerRef}
      onClick={onClick}
      aria-label={ariaLabel || defaultAriaLabel}
      disabled={loading}
    >
      {!isIconsEnd ? (
        loading ? (
          <span
            className={classNames('spinner-border spinner-border-sm', getPaddingIcon())}
            role="status"
            aria-hidden="true"
          />
        ) : (
          icon !== '' &&
          (classSpanIcon ? (
            <span onClick={onClickIcon} className={`${classSpanIcon} ${getPaddingIcon()}`}>
              <FormIcon icon={icon} iconType={iconType} className={iconClass} size={iconSize} />
            </span>
          ) : (
            <FormIcon icon={icon} iconType={iconType} className={iconClass} size={iconSize} />
          ))
        )
      ) : null}

      {!isSpanTitle ? (
        name
      ) : (
        <span className={classSpan || ''} onClick={onClickSpanTitle}>
          {name}
        </span>
      )}

      {isIconsEnd ? (
        loading ? (
          <span
            className="spinner-border spinner-border-sm mg-r-10"
            role="status"
            aria-hidden="true"
          />
        ) : (
          icon != '' && (
            <span onClick={onClickIcon} className={`${name ? 'mg-l-5' : ''}`}>
              <FormIcon icon={icon} iconType={iconType} className={iconClass} size={iconSize} />
            </span>
          )
        )
      ) : null}
      {children}
    </Tag>
  );
};

const defaultProps = {
  form: '',
  color: 'secondary',
  tag: 'button',
  type: '',
  icon: '',
  loading: false,
  isIconsEnd: false,
  notPaddingIcon: false,
  iconType: 'feather',
  iconSize: 14,
  iconClass: '',
  tooltip: null,
};

Button.defaultProps = defaultProps;

export default Button;
