import React, { useState, useEffect, useReducer } from 'react';
import classNames from 'classnames';
import './style.scss';
import {
  X,
  ArrowDownRight,
  ArrowUpLeft,
  ArrowLeft,
  ArrowRight,
  Minimize,
  Maximize,
} from 'react-feather';
import Body from '@base/components/mi-modal/body';
import Footer from '@base/components/mi-modal/footer';
import { MiContext, typeState, initState, contextState } from './context';
import { isFunction } from 'lodash';

/**
 *
 * @param {*} props
 * @returns
 */
const MiModal = (props: any) => {
  const {
    title,
    isOpen = true,
    size, //'sm', ''md, 'lg', 'xl'
    onClose,
    children,
    fullScreen = false,
    setFullScreen = null,
    ...remainProps
  } = props;

  const allowControls = {
    ...{
      shrink: true,
      minimize: true,
      fullscreen: true
    },
    ...(remainProps?.allowControls ?? {})
  }

  const [hmState, setHMState] = useReducer(contextState, {
    ...initState,
    isFullScreen: fullScreen,
  });

  useEffect(() => {
    if (isOpen) {
      setHMState({
        type: typeState.UPDATE_STATE,
        key: 'isShow',
        value: true,
      });
    }
  }, []);

  const handleClose = () => {
    if (isFunction(onClose)) {
      return onClose();
    } else {
      setHMState({
        type: typeState.UPDATE_STATE,
        key: 'isShow',
        value: false,
      });
    }
  };

  //main render
  return (
    <MiContext.Provider
      value={{
        hmState,
      }}
    >
      <div
        className={classNames('mail-compose', {
          show: hmState?.isShow,
          shrink: hmState?.isShrink,
          minimize: hmState?.isMinimize,
          fullscreen: hmState?.isFullScreen,
        })}
      >
        <div
          className={classNames('mail-compose-dialog', {
            'mail-compose-sm': !hmState?.isFullScreen && size === 'sm',
            'mail-compose-md': !hmState?.isFullScreen && size === 'md',
            'mail-compose-lg': !hmState?.isFullScreen && size === 'lg',
            'mail-compose-xl': !hmState?.isFullScreen && size === 'xl',
          })}
        >
          {/* header */}
          <div className="mail-compose-header">
            <h6 className="mail-compose-title tx-white">{title}</h6>
            <nav className="nav">
              {
                allowControls?.minimize && (
                  <a
                    href="#"
                    className="nav-link nav-link-minimize d-none d-lg-block pd-r-0"
                    onClick={() => {
                      setHMState({
                        type: typeState.UPDATE_ONE_STATE,
                        key: 'isMinimize',
                        value: !hmState?.isMinimize,
                      });
                    }}
                  >
                    {!hmState?.isMinimize && (
                      <ArrowDownRight
                        style={{
                          width: '18px',
                          height: '18px',
                        }}
                      />
                    )}
                    {hmState?.isMinimize && (
                      <ArrowUpLeft
                        style={{
                          width: '18px',
                          height: '18px',
                        }}
                      />
                    )}
                  </a>
                )
              }
              {
                allowControls?.shrink && (
                  <a
                    href="#"
                    className="nav-link nav-link-shrink d-none d-lg-block pd-r-0"
                    onClick={() => {
                      setHMState({
                        type: typeState.UPDATE_ONE_STATE,
                        key: 'isShrink',
                        value: !hmState?.isShrink,
                      });
                    }}
                  >
                    {hmState?.isShrink ? (
                      <ArrowLeft style={{ width: '18px', height: '18px' }} />
                    ) : (
                      <ArrowRight style={{ width: '18px', height: '18px' }} />
                    )}
                  </a>
                )
              }
              {
                allowControls?.fullscreen && (
                  <a
                    href="#"
                    className="nav-link nav-link-fullscreen d-none d-lg-block pd-r-0"
                    onClick={() => {
                      setHMState({
                        type: typeState.UPDATE_ONE_STATE,
                        key: 'isFullScreen',
                        value: !hmState?.isFullScreen,
                      });
                      setFullScreen && setFullScreen(!hmState?.isFullScreen);
                    }}
                  >
                    {hmState?.isFullScreen && (
                      <Minimize
                        style={{
                          width: '18px',
                          height: '18px',
                        }}
                      />
                    )}
                    {!hmState?.isFullScreen && (
                      <Maximize
                        style={{
                          width: '18px',
                          height: '18px',
                        }}
                      />
                    )}
                  </a>
                )
              }
              <a href="#" className="nav-link nav-link-close" onClick={handleClose}>
                <X
                  style={{
                    width: '18px',
                    height: '18px',
                  }}
                />
              </a>
            </nav>
          </div>

          {/* body + footer */}
          {children}
        </div>
      </div>
    </MiContext.Provider>
  );
};

export default Object.assign(MiModal, {
  Body,
  Footer,
});
