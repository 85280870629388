// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".no-data-container{display:flex;align-items:center;justify-content:center;height:130px;flex-direction:column}.no-data-container>svg{min-width:40px;min-height:40px}", "",{"version":3,"sources":["webpack://./src/base/components/empty/styles.scss"],"names":[],"mappings":"AAAA,mBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,qBAAA,CAEA,uBACI,cAAA,CACA,eAAA","sourcesContent":[".no-data-container{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 130px;\n    flex-direction: column;\n\n    > svg {\n        min-width: 40px;\n        min-height: 40px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no-data-container": "no-data-container"
};
export default ___CSS_LOADER_EXPORT___;
