import React from 'react';

const MiContext = React.createContext({ hmState: {} });

const typeState = {
  UPDATE_STATE: 'UPDATE_STATE',
  UPDATE_ONE_STATE: 'UPDATE_ONE_STATE',
};

const initState = {
  isShow: false,
  isShrink: false,
  isMinimize: false,
  isFullScreen: false,
};

/**
 * @param {any} state
 * @param {*} action
 */
function contextState(state: any, action: { type: any; key: string; value: any }) {
  switch (action.type) {
    case typeState.UPDATE_STATE:
      return {
        ...state,
        [action.key]: action.value,
      };
    case typeState.UPDATE_ONE_STATE:
      return {
        ...state,
        isShrink: action.key === 'isShrink' ? action.value : false,
        isMinimize: action.key === 'isMinimize' ? action.value : false,
        isFullScreen: action.key === 'isFullScreen' ? action.value : false,
      };
    default:
      return state;
  }
}

export { MiContext, typeState, initState, contextState };
