import { SearchFilter } from '@base/types/interfaces/app';
import { ISearchFields } from '@base/types/interfaces/common';

export function ParseFiltersToQuery(
  filter: SearchFilter | undefined,
  dateByOptions: any[],
  searchFields: any[],
): string {
  let queries: string[] = [];
  Object.keys(filter?.headerFilters).map((key) => {
    const value = filter?.headerFilters[key];
    if (value === '') return false;
    const isDateTime = dateByOptions?.findIndex((v) => v.value === key) > -1;
    if (isDateTime) {
      queries.push(`${key}>="${value?.[0]}"`);
      queries.push(`${key}<="${value?.[1]}"`);
    } else {
      queries.push(`${key}=${value}`);
    }
  });

  // search query
  if (filter?.keyword && filter?.keyword != '') {
    let orQueries: string[] = [];
    searchFields?.length &&
      searchFields?.map((field: any) => {
        orQueries.push(`${field?.name}:"${filter?.keyword}"`);
      });
    const orQuery = orQueries?.length > 1 ? `{${orQueries.join(' ')}}` : `${orQueries.join(' ')}`;
    queries.push(orQuery);
  }

  let queryString = '';
  if (queries?.length) {
    queryString = `${queries.join(' ')}`;
  }
  // console.log('queryString', queryString);
  return queryString;
}

export function ParseSearchFieldsToQuery(searchFields: ISearchFields[]): string {
  let queries: string[] = [];
  searchFields?.length &&
    searchFields?.map((field: any) => {
      if (field?.value != '') {
        if (field?.type == 'string') {
          queries.push(`${field?.key}="${field?.value}"`);
        } else if (field?.type == 'text') {
          queries.push(`${field?.key}:"${field?.value}"`);
        }
      }
    });

  let queryString = '';
  if (queries?.length) {
    queryString = `${queries.join(' ')}`;
  }

  return queryString;
}
