import React, { useState } from 'react';
import { Avatar, NoData, SpanLang } from '@base/components';
import MiModal from '@base/components/mi-modal';
import classNames from 'classnames';

const UnAssignRepModal: React.FC<any> = (props) => {
  const {
    show = false,
    users = [],
    title = <SpanLang keyLang={'crm_new_generalsetting_assign_user'} />,
    isUnAssigning = false,
    onUnAssign,
    onClose,
  } = props;
  //state
  const [selectedUsers, setSelectedUsers] = useState<any>([]);

  //select user
  const handleCheck = (user: any) => {
    const newUsers = [...selectedUsers];
    const fIdx = newUsers.findIndex((_ele: any) => _ele.id === user.id);
    if (fIdx === -1) {
      newUsers.push(user);
      setSelectedUsers(newUsers);
    }
  };

  //start save
  const handleSave = () => {
    onUnAssign && onUnAssign(selectedUsers);
    //onClose();
  };

  return (
    <MiModal title={title} size="md" isOpen={show} onClose={onClose}>
      <MiModal.Body>
        <div className="modal-body">
          <ul className="list-group">
            {users.length === 0 && <NoData label='No reps available.' />}
            {users.length > 0 && users.map((user: any, index: number) => (
              <li key={index}
                className={classNames('list-group-item d-flex align-items-center list-group-item-action', { 'active': 0 })}>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`remove-user-${user.id}`}
                    onChange={() => handleCheck(user)}
                  />
                  <label className="custom-control-label" htmlFor={`remove-user-${user.id}`} />
                </div>
                <Avatar
                  url={user?.photo}
                  alt={user.name}
                  width={32}
                  height={32}
                />
                <span className='mg-l-5'>{user.name}</span>
              </li>
            ))
            }
          </ul>
        </div>
      </MiModal.Body>
      <MiModal.Footer onClose={onClose} onSave={handleSave} saveLabel={'Unassign'} saveLoading={isUnAssigning} />
    </MiModal>
  );
};

export default UnAssignRepModal;
