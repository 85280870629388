import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CoreTree from '@base/components/form/tree/core/tree';

/**
 * trungtm
 * @param {*} props
 */
const TreeListView: React.FC<any> = (props: any) => {
  const {
    value, //[]: multiple, {}: single
    onChange,
    single = false, //true / false
    fetchTree,
    formatTreeNode,
  } = props;

  //state
  const [items, setItems] = useState<any>([]);
  //refs
  const treeRef = useRef();
  //lang
  const { t } = useTranslation();

  //set default items
  useEffect(() => {
    if (value) {
      setItems(value);
    } else {
      setItems([]);
    }
  }, [value]);

  //set values changes
  const handleSelectChange = (data: any, node: any) => {
    //parent node is checked
    if (node.checked == 1 && node.children && node.children.length > 0) {
      //only get parent node
      const newItems: any = [...items];
      newItems.push(node);
      setItems(newItems);
      //callback
      onChange && onChange(newItems);
    } else {
      //current child node is checked
      //find out parent node is included in data, then remove all child node
      const idx = data.findIndex((el: any) => el.id === node.parent_id);
      if (idx > -1) {
        //exist parent node (mean all children are checked)
        //only select parent node, remove all child nodes
        const newData = [...data];
        newData[idx].children.map((child: any) => {
          const cIdx = newData.findIndex((el) => el.id === child.id);
          newData.splice(cIdx, 1);
        });
        //check for other nodes in selected items, only keep parent node if all child are checked
        items.map((item: any) => {
          if (item.children && item.children.length > 0) {
            //this is parent node
            const pIdx = newData.findIndex((el) => el.id === item.id);
            if (pIdx > -1) {
              //exist in return list
              //remove all it's chilrent
              item.children.map((iChild: any) => {
                const icIdx = newData.findIndex((el) => el.id === iChild.id);
                if (icIdx > -1) {
                  newData.splice(icIdx, 1);
                }
              });
            }
          }
        });
        //set new selected items
        setItems(newData);
        //callback
        onChange && onChange(newData);
      } else {
        //keep current seleted items, add new selected nodes
        const newData = [...data];
        items.map((item: any) => {
          if (item.children && item.children.length > 0) {
            //this is parent node
            const pIdx = newData.findIndex((el) => el.id === item.id);
            if (pIdx > -1) {
              //exist in return list
              //remove all it's chilrent
              item.children.map((iChild: any) => {
                const icIdx = newData.findIndex((el) => el.id === iChild.id);
                if (icIdx > -1) {
                  newData.splice(icIdx, 1);
                }
              });
            }
          }
        });
        setItems(newData);
        //callback
        onChange && onChange(newData);
      }
    }
  };

  //handle when single = true
  const handleTreeNodeClick = (node: any) => {
    if (single) {
      setItems([node]);
      //callback
      onChange && onChange([node]);
    }
  };

  //render
  return (
    <div className="pos-relative w-100">
      {/* selected items */}
      <div id="tree-container">
        <CoreTree
          ref={treeRef}
          hasCheckBox={single ? false : true}
          parsedNode={formatTreeNode}
          source={fetchTree}
          value={items}
          onChecked={handleSelectChange}
          onClickTitle={handleTreeNodeClick}
        />
      </div>
    </div>
  );
};

export default TreeListView;
