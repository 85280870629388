import { FormIcon } from '@base/components/form';
import classNames from 'classnames';
import React from 'react';
import { ChevronRight, ChevronDown } from 'react-feather';

interface ISItemProps {
  title: any;
  id: string;
  children: any;
  sortable?: boolean;
  show?: boolean;
}
const SectionItem = (props: ISItemProps) => {
  const { title, children, id, sortable, show } = props;
  return (
    <div className="view-aside-section">
      {sortable && (
        <div className="view-aside-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            <FormIcon icon="move" iconType="icon" />
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">{title}</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto pd-0"
            data-toggle="collapse"
            data-target={`#section-item-${id}`}
          >
            {show ? <ChevronDown /> : <ChevronRight />}
          </button>
        </div>
      )}
      <div
        id={`section-item-${id}`}
        className={classNames('view-aside-section-body collapse', (show || !sortable) ? 'show' : '')}
      >
        {children}
      </div>
    </div>
  );
};

SectionItem.defaultProps = {
  sortable: true,
  show: true,
};

export default SectionItem;
