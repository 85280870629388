import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { isFunction } from 'lodash';

import ItemPhoto from './photo';
import ItemBody from './body';
import ItemHeader from './header';

export interface BaseGridItemProps {
  //category: string;
  primaryKey: string | number;
  titleKey?: string;
  title?: string; //header title
  titleUrl?: string;
  data: any;
  isSplitMode: boolean;
  columns: {
    name: string;
    title: string;
  }[];
  columnRender?: any;
  hideColumns?: string[];
  isChecked?: boolean;
  onCheckedRow?: (val: string) => void;
  photoKey?: string;
}

const BaseGridItem = (props: BaseGridItemProps) => {
  const {
    //category,
    primaryKey = 'id',
    titleKey = 'name',
    title = '',
    titleUrl = '',
    data,
    isSplitMode,
    columns,
    columnRender,
    hideColumns = [],
    isChecked = false,
    onCheckedRow,
    photoKey = '',
  } = props;
  //lang
  //const { t, i18n } = useTranslation();
  //state
  const [itemChecked, setItemChecked] = useState(false);

  //console.log('grid item', data);
  useEffect(() => {
    if (isChecked !== itemChecked) {
      setItemChecked(isChecked);
    }
  }, [isChecked]);

  //get showing columns, skip hidden
  const getViewingColumns = useCallback(() => {
    return columns.reduce((obj: any, column: any) => {
      if (
        hideColumns.indexOf(column.name) === -1 &&
        titleKey != column?.name &&
        photoKey != column?.name
      ) {
        obj.push(column);
      }
      return obj;
    }, []);
  }, [columns, hideColumns]);

  //get header title
  const headerCol: any = columns.find((_col: any) => _col.name === titleKey);

  return (
    <div className={classNames('d-flex', { 'col-lg-6': !isSplitMode })}>
      <div className="grid-list-item">
        <div className="grid-list-item-sel">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`gridCheck_${data?.[primaryKey]}`}
              onChange={(e) => {
                setItemChecked(e.target.checked);
                //callback
                onCheckedRow && onCheckedRow(data?.[primaryKey]);
              }}
              checked={itemChecked}
            />
            <label className="custom-control-label" htmlFor={`gridCheck_${data?.[primaryKey]}`} />
          </div>
        </div>
        {!isSplitMode && photoKey != '' && (
          <ItemPhoto
            url={data?.[photoKey] || ''}
            alt={data?.[titleKey] || ''}
            className={'grid-list-item-pic'}
            isSplitMode={isSplitMode}
          />
        )}
        <div className="grid-list-item-detail">
          <ItemHeader
            title={title}
            url={titleUrl ? titleUrl : data?.[primaryKey]}
            headerRender={
              isFunction(columnRender) && columnRender(headerCol?.[titleKey])
                ? columnRender(headerCol?.[titleKey])(headerCol?.[titleKey], data)
                : null
            }
          />
          <ItemBody
            data={data}
            isSplitMode={isSplitMode}
            columns={getViewingColumns()}
            columnRender={columnRender}
          />
        </div>
      </div>
    </div>
  );
};

export default BaseGridItem;
