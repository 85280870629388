import React, { memo } from 'react';
import { SpanLang } from '@base/components';
import classNames from 'classnames';
import TextViewField from '@base/containers/view-field/text';

interface Props {
  menu?: string;
  menuSource?: string;
  menuSourceId?: string;
  fields: any[];
  metadata?: any;
  ignoreFields?: string[];
  refetchFields?: string[];
  column?: number;
  onRefetch?: () => void;
}

const Fields = (props: Props) => {
  const {
    menu,
    menuSource,
    menuSourceId,
    metadata,
    fields,
    ignoreFields,
    refetchFields = [],
    column = 1,
    onRefetch,
  } = props;
  return (
    <>
      {fields?.map((field: any, index: number) => {
        const _config = field?.config ?? null;
        const userPermission = _config?.viewProps?.userPermission ?? field?.userPermission;
        const Component = _config?.component ?? null;
        let { data } = field;
        if (
          (ignoreFields && ignoreFields?.indexOf(field?.keyName) > -1) ||
          !userPermission?.isShow
        ) {
          return null;
        }

        let newColumn = column;
        if (_config?.hasOwnProperty('showFullRow')) {
          if (_config.showFullRow) {
            newColumn = 1;
          } else {
            newColumn = 2;
            if (_config.showFullRow === 0) {
              newColumn = 0;
            }
          }
        }

        let showTitle = true;
        if (
          _config?.hasOwnProperty('notShowTitle') ||
          _config?.hideTitle ||
          _config?.viewProps?.notShowTitle
        ) {
          showTitle = false;
        }

        return (
          <div
            key={field.keyName + index.toString()}
            className={classNames('form-group', {
              'col-6': newColumn == 2,
              'col-12': newColumn == 1,
            })}
          >
            {showTitle && (
              <label className="form-item-title">
                <SpanLang className="pd-l-0" keyLang={field?.languageKey} />
              </label>
            )}

            {Component ? (
              <Component
                key={field.keyName}
                value={data}
                userPermission={userPermission}
                keyName={field.keyName}
                dataType={field?.dataType}
                {..._config?.componentProps}
                {..._config?.viewProps}
                config={_config}
                menu={menu}
                metadata={field?.metadata ?? metadata}
                menuSource={field?.menuSource ?? menuSource}
                menuSourceId={field?.menuSourceId ?? menuSourceId}
                // onRefetch={refetchFields.includes(field.keyName) ? onRefetch : null}
                onRefetch={onRefetch} //Auto refetch view data when update field
              />
            ) : (
              <TextViewField
                key={field.keyName}
                value={data && data != '' ? data : ''}
                userPermission={userPermission}
                keyName={field.keyName}
                dataType={field?.dataType}
                {..._config?.componentProps}
                config={_config}
                menu={menu}
                metadata={field?.metadata ?? metadata}
                menuSource={field?.menuSource ?? menuSource}
                menuSourceId={field?.menuSourceId ?? menuSourceId}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default Fields;
