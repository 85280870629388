import React from 'react';
import { X } from 'react-feather';

interface TagItemProps {
  tag: string;
  deleteTag: (tag: string) => void;
}

function TagItem({ tag, deleteTag }: TagItemProps) {
  return (
      <div className="tag-item mg-r-5">
        <span className="mg-x-5">{tag}</span> 
        <button
          type="button"
          className="btn btn-sm btn-link btn-icon"
          onClick={() => deleteTag(tag)}
        >
          <X className="tx-danger" />
        </button>
      </div>
  );
}

export default React.memo(TagItem);
