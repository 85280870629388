import React, { useEffect, useRef } from 'react';

interface IClickOutsideProps {
  children: any;
  className?: string;
  onClickOutsite?: () => void;
  onClickInsite: () => void;
}
const ClickOutside: React.FC<IClickOutsideProps> = (props: IClickOutsideProps) => {
  const {
    children = null,
    className = '',
    onClickOutsite = () => {},
    onClickInsite = () => {},
  } = props;
  const wrapperRef = useRef<any>(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // console.log('onClickOutsite');
        onClickOutsite();
      } else {
        // console.log('onClickInsite');
        onClickInsite();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className={className} ref={wrapperRef}>
      {children}
    </div>
  );
};

export default ClickOutside;
