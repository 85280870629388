import React, { useState, useEffect } from 'react';
import CommonViewField from '../common';

import View from './view';
import Edit from './edit';

const TextAreaViewField: React.FC<any> = (props: any) => {
  const [value, setValue] = useState(props?.value ?? '');

  //init data
  useEffect(() => {
    if (value !== props?.value) {
      setValue(props?.value);
    }
  }, [props?.value]);

  const onSave = (formData: any) => {
    setValue(formData[props.keyName]);
  };

  return (
    <CommonViewField
      {...props}
      onSave={onSave}
      componentView={View}
      componentEdit={Edit}
      value={value}
    />
  );
};

export default TextAreaViewField;
