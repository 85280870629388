import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Menu, Search, Settings } from 'react-feather';
import classNames from 'classnames';
// import Portal from '@base/components/portal';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import { FormIcon, SpanLang } from '@base/components/form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { t } from 'i18next';

const Container = styled.div`
  z-index: 999999;
  min-width: 340px;
  padding: 0;
  top: 100%;

  .column-settings-title {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid $border-color;
    font-weight: $font-weight-semibold;
  }

  .list-group {
    max-height: 300px;
    overflow-y: auto;

    .list-group-item {
      display: flex;
      justify-content: space-between;
      border-width: 0 0 1px;

      .handler {
        padding: 0;
      }
    }
  }

  .column-settings-actions {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.25rem;
    border-top: 1px solid $border-color;
  }
`;

interface IColumnSettingProps {
  className?: string;
  menuSource?: string;
  columLimit?: number;
  columns: any[];
  hideColumns?: any[];
  fixedColumns?: any[];
  onChange?: any;
}

const ListTableColumnSetting: React.FC<IColumnSettingProps> = (props) => {
  const {
    className,
    menuSource,
    columLimit = 10,
    columns = [],
    hideColumns = [],
    fixedColumns = [],
    onChange,
  } = props;

  //state
  const [items, setItems] = useState<any[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const containerRef = useRef<any>(null);

  //init state
  useEffect(() => {
    if (columns?.length > 0) {
      const colIds = columns?.map((_col: any) => _col?.keyName);
      const itemIds = items?.map((_item: any) => _item?.keyName);
      if (JSON.stringify(colIds) !== JSON.stringify(itemIds)) {
        setItems(columns);
        //set selected ids
        const newSelectedIds: string[] = [];
        columns.map((_ele: any) => {
          if (_ele?.isViewing) {
            newSelectedIds.push(_ele?.keyName);
          }
        });
        setSelectedIds(newSelectedIds);
      }
    } else {
      setItems([]);
      setSelectedIds([]);
    }
  }, [columns]);

  //handle check change
  const handleCheckChange = (id: string) => {
    if (id) {
      const newSelectedIds = [...selectedIds];
      if (!newSelectedIds.includes(id)) {
        if (newSelectedIds.length >= columLimit) {
          toast.warning('The viewed columns maximum overpass ' + columLimit);
          return false;
        } else {
          newSelectedIds.push(id);
        }
      } else {
        newSelectedIds.splice(newSelectedIds.indexOf(id), 1);
      }
      setSelectedIds(newSelectedIds);
    }
  };

  //apply new settings
  const handleApply = () => {
    const newViewColumns = items?.map((item: any) => ({
      ...item,
      isViewing: selectedIds.includes(item.keyName) ? true : false,
      attributes: [], //remove attributes value
    }));

    // save to menu setting
    // if (menu && menu != '') {
    //   const menuListPage: any = listPageSettings?.[menu] ?? {
    //     listType: ListType.LIST,
    //     itemPerPage: 10,
    //     columnSettings: null,
    //   };
    //   const nListPageSettings = {
    //     ...listPageSettings,
    //     [menu]: {
    //       ...menuListPage,
    //       columnSettings: newViewColumns,
    //     },
    //   };

    //   const postParam = {
    //     menu: 'common',
    //     key: 'list_page_settings',
    //     value: JSON.stringify(nListPageSettings),
    //   };
    //   mChangeSetting.mutate({ menuSetting: postParam });
    //   setListPageSettings(nListPageSettings);
    // }

    // callback
    onChange && onChange(newViewColumns);
    setShowDropdown(false);
  };

  //drag end, update new position
  const handleDragEnd = (result: any) => {
    //// console.log('handleDragEnd =>', result);
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (fixedColumns) {
      const target = items?.[destination?.index] ?? {};
      if (target?.keyName && fixedColumns.includes(target.keyName)) {
        return;
      }
    }

    //re-order the items
    const newItems = [...items];
    //const dragItem = newItems[source.index];
    const [removedItem] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, removedItem);
    setItems(newItems);
  };

  /* =============================== RENDER ===================================== */
  //no items
  const renderNoData = () => {
    return (
      <ul className="list-group">
        <li className="list-group-item d-flex justify-content-between align-items-center">
          There are not columns to show.
        </li>
      </ul>
    );
  };

  //apply button
  const renderFooter = () => {
    return (
      <div className="column-settings-actions">
        Max : <strong className="mg-x-5 tx-primary">{selectedIds.length || 0}</strong>/{columLimit}
        <button
          type="button"
          className="btn btn-light mg-l-auto"
          onClick={() => setShowDropdown(false)}
        >
          Close
        </button>
        <button type="button" className="btn btn-primary mg-l-5" onClick={handleApply}>
          Apply
        </button>
      </div>
    );
  };

  //search text
  const renderSearch = () => {
    return (
      <div className="input-group wd-350 bd-y">
        <input type="search" className="form-control form-control-lg bd-0 rounded-0" />
        <div className="input-group-append">
          <span className="input-group-text tx-gray-400 bd-0">
            <Search />
          </span>
        </div>
      </div>
    );
  };

  //render items
  const renderDropDrapItems = () => {
    return (
      <Droppable droppableId={`${menuSource}-columns`}>
        {(provided, snapshot) => (
          <ul
            ref={provided.innerRef}
            className="list-group scroll-box"
            style={{
              // padding: 5,
              // margin: 4,
              // borderRadius: 4,
              backgroundColor: snapshot.isDraggingOver
                ? 'var(--background-hover-color)'
                : 'var(--background-container)',
            }}
          >
            {items.length == 0
              ? renderNoData()
              : items.map((_item: any, _index: number) => {
                  const isFixedColumn = fixedColumns?.includes(_item?.keyName);
                  return (
                    <Draggable
                      key={_item?.keyName}
                      draggableId={_item?.keyName}
                      index={_index}
                      isDragDisabled={isFixedColumn}
                      //isDragDisabled={!_item?.permission?.canMovePosition}
                    >
                      {(provided, snapshot) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={provided.draggableProps.style}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          <div className="custom-control custom-checkbox">
                            <input
                              onChange={() => handleCheckChange(_item?.keyName)}
                              type="checkbox"
                              className={classNames('custom-control-input', {
                                'is-disabled': _item?.isDefault,
                              })}
                              name={`columns[${_item?.keyName}]`}
                              checked={selectedIds.includes(_item?.keyName)}
                              data-id={_item?.keyName}
                              id={`col-${_item?.keyName}`}
                              disabled={isFixedColumn}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`col-${_item?.keyName}`}
                            >
                              <span>{t(_item?.languageKey) ?? _item?.title}</span>
                              {/* {_item.isDefault ? (
                                <span className="badge badge-primary ml-2">Default</span>
                              ) : null} */}
                            </label>
                          </div>
                          {!isFixedColumn && (
                            <button
                              type="button"
                              className="btn btn-link link-02 btn-icon handler"
                              aria-label="change the order"
                            >
                              <Menu />
                            </button>
                          )}
                        </li>
                      )}
                    </Draggable>
                  );
                })}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    );
  };

  //render drop context area
  const DrapDropArea = useMemo(() => {
    return <DragDropContext onDragEnd={handleDragEnd}>{renderDropDrapItems()}</DragDropContext>;
  }, [items, selectedIds]);

  //dropdown items
  const renderDropdownItems = () => {
    return (
      // <Portal id={`${menuSource}TableColumnSetting`}>
      <Container
        className={classNames('dropdown-menu dropdown-menu-right', { show: showDropdown })}
        ref={containerRef}
      >
        <div className="column-settings">
          <div className="d-flex justify-content-between">
            <div className="column-settings-title">Column Settings</div>
            <button type="button" className="btn btn-link btn-icon mg-r-10" data-han-tooltip="Sort">
              <FormIcon icon="sort" iconType="icon" />
            </button>
          </div>
          {renderSearch()}
          {DrapDropArea}
          {renderFooter()}
        </div>
      </Container>
      // </Portal>
    );
  };

  //render
  return (
    <div className={classNames('dropdown', className)} id={`${menuSource}TableColumnSetting`}>
      <button
        type="button"
        className="btn btn-white btn-icon mg-l-5"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <FormIcon icon="column_settings" iconType="icon" />
      </button>
      {renderDropdownItems()}
    </div>
  );
};

export default ListTableColumnSetting;
