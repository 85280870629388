import React, { useState } from 'react';
import { TreeListSelect } from '@base/components/form';
import { GET_USER_IN_GROUP, GET_USER_GROUP } from '@base/services/graphql/user';
import { BaseResponse, ResponsePaging } from '@base/types/interfaces/response';
import { graphQLApi } from '@base/utils/axios/graphql';
import { User } from '@base/types/interfaces/user';

/**
 *
 * @param {*} props
 */
const UserSelect: React.FC<any> = (props: any) => {
  const { single, value, onChange } = props;

  //state
  const [userData, setUserData] = useState<ResponsePaging<User>>({
    results: [],
    paging: { totalItems: 0, totalPage: 0, currentPage: 1 },
  }); //list data
  const [isListLoading, setIsListLoading] = useState(false);

  //get user groups
  const getGroup = async ({ idFolder = 0, keyword, node }: any) => {
    //check cache
    if (idFolder === 0) {
      const params = {
        filter: {
          sort: { field: 'createdAt', orderBy: 'DESC' },
        },
      };
      const data = await graphQLApi<BaseResponse<any>>('setting_groups', GET_USER_GROUP, params);
      if (data) {
        return new Promise((resolve) => {
          resolve(data.results || []);
        });
      } else {
        return new Promise((resolve) => {
          resolve([]);
        });
      }
    } else {
      //get children of node
      return new Promise((resolve) => {
        resolve(node.children || []);
      });
    }
  };

  //bind data nodes
  const formatDataNode = (_node: any) => {
    const hasChildren = _node.children && _node.children.length > 0;
    const isExpand = _node.expanded && _node.expanded === true;
    const rs = {
      isExpand,
      hasChildren,
      title: _node.name,
      id: _node.id,
      isFolder: true, //_node.hasOwnProperty('type') && _node?.type === 'group'
    };
    return rs;
  };

  //get list by keyword, groupIDs
  const getList = async ({ page, keyword, groupIDs, group }: any) => {
    const params = {
      groupId: groupIDs && groupIDs.length > 0 ? groupIDs[0] : '',
    };
    const data = await graphQLApi<ResponsePaging<User>>(
      'setting_groupUsers',
      GET_USER_IN_GROUP,
      params,
    );
    setUserData(data);
    setIsListLoading(false);
  };

  //main render
  return (
    <TreeListSelect
      //tree
      fetchTree={getGroup}
      formatTreeNode={formatDataNode}
      //list
      fetchList={getList}
      listData={{
        items: userData.results || [],
        attrs: {
          total: userData?.paging?.totalItems || 0,
          maxpage: userData?.paging?.totalPage || 1,
          page: userData?.paging?.currentPage || 1,
        },
        keyword: '', //TODO: save cache
        group: null, //TODO: save cache
      }}
      isListLoading={isListLoading}
      //selected items
      single={single}
      value={value || []}
      onChange={onChange}
    />
  );
};

export default UserSelect;
