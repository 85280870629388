import React, { useEffect, FC } from 'react';
// import { useSetRecoilState } from 'recoil';
// import { deviceAtom } from '@base/recoil/atoms/app';
// import useDevice from '@base/hooks/useDevice';

const BaseContainer: FC = () => {
  // const setDeviceState = useSetRecoilState(deviceAtom);
  // const device = useDevice();

  // useEffect(() => {
  //   setDeviceState(device);
  // }, [device]);

  return null;
};

export default BaseContainer;
