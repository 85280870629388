import loadNavbar from './navbar';
import loadTabs from './tabs';
import loadCustomCode from './custom-code';
import loadTooltip from './tooltip';
import loadHeading from './heading';
import loadShapeDivider from './shape-divider';
import loadTable from './table';
// import loadCalendar from './calendar';
//import loadCtaButton from './cta-button';
import loadPersonalize from './personalize';
import loadForm from './form';
import loadNImage from './n-image';

export default (editor: any, opt: any) => {
  loadNavbar(editor, opt);
  loadTabs(editor, opt);
  loadCustomCode(editor, opt);
  loadTooltip(editor, opt);
  loadHeading(editor, opt);
  loadShapeDivider(editor, opt);
  loadTable(editor, opt);
  //loadCalendar(editor, opt);
  //loadCtaButton(editor, opt);
  loadPersonalize(editor, opt);
  loadForm(editor, opt);
  loadNImage(editor, opt);
};
