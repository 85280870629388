import React, { forwardRef } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import Image from '../image';
import './styles.scss';

// eslint-disable-next-line react/display-name
const PhotoGallery = forwardRef<HTMLDivElement>((props: any, ref) => {
  // eslint-disable-next-line react/prop-types
  const { items = [], ...rest } = props;
  return (
    <div className="crm-image-gallery">
      <ImageGallery
        ref={ref}
        thumbnailPosition={'right'}
        showFullscreenButton={false}
        showPlayButton={false}
        items={items}
        renderItem={(item: any) => (
          <Image key={item.original} className={'original'} url={item.original} />
        )}
        renderThumbInner={(item: any) => (
          <Image key={item.original} className={'thumbnail'} url={item.thumbnail} />
        )}
        {...rest}
      />
    </div>
  );
});

export default PhotoGallery;
