import React, { useEffect, useState } from 'react';
import TaskSequenceForm from '@activity/containers/sequence-form';

const Edit: React.FC = (props: any) => {
  const { value, onChange } = props;

  return <TaskSequenceForm className="pd-10" mode={'w'} value={value} onChange={onChange} />;
};

export default Edit;
