import React from 'react';
import classNames from 'classnames';
import Button from '@base/components/form/button';

/**
 *
 * @param {*} props
 * @returns
 */
const Footer = (props: any) => {
  const {
    className,
    closeBtn,
    onClose,
    closeLabel,
    saveBtn,
    onSave,
    saveLabel,
    saveLoading,
    children,
  } = props;

  //button DOM
  let closeButton;
  let saveButton;

  if (!closeBtn && onClose) {
    closeButton = (
      <Button color="light" outline onClick={onClose}>
        {closeLabel || 'Cancel'}
      </Button>
    );
  }

  if (!saveBtn && onSave) {
    saveButton = (
      <Button color="primary" className="mg-l-5" onClick={onSave} loading={saveLoading}>
        {saveLabel || 'Save'}
      </Button>
    );
  }

  //main render
  return (
    <div className={classNames('compose-footer', className)}>
      {children}
      <div className="d-flex align-items-center justify-content-end">
        <div className="tx-13 mg-t-15 mg-sm-t-0">
          {closeBtn || closeButton}
          {saveBtn || saveButton}
        </div>
      </div>
    </div>
  );
};

export default Footer;
