import React from 'react';

const zip = () => {
  return (
    <svg id="file-type-zip" xmlns="http://www.w3.org/2000/svg" width="42px" height="51px" viewBox="0 0 42 51">
      <g>
        <path fill="#F2BD4C" d="M42,49c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h32l8,8V49z" />
        <polygon fill="#E0AB3A" points="42.003,14.828 34.615,7.44 42,8 	" />
        <g>
          <path fill="#FFDA8B" d="M42,8h-6c-1.104,0-2-0.896-2-2V0L42,8z" />
        </g>
      </g>
      <g>
        <polygon fill="#F2BD4C" points="31,37 22,34 22,45.5 31,48.5 40,45.5 40,34 	" />
        <polygon
          fill="#F1BC4B"
          points="30.706,36.691 21.706,33.691 21.706,45.191 30.706,48.191 39.706,45.191 39.706,33.691 	"
        />
        <polygon
          fill="#F1BB4A"
          points="30.412,36.382 21.412,33.383 21.412,44.883 30.412,47.883 39.412,44.883 39.412,33.383 	"
        />
        <polygon
          fill="#F0BB49"
          points="30.117,36.073 21.117,33.074 21.117,44.574 30.117,47.574 39.117,44.574 39.117,33.074 	"
        />
        <polygon
          fill="#EFBA48"
          points="29.823,35.764 20.823,32.765 20.823,44.266 29.823,47.265 38.823,44.266 38.823,32.765 	"
        />
        <polygon
          fill="#EEB947"
          points="29.529,35.455 20.529,32.456 20.529,43.957 29.529,46.956 38.529,43.957 38.529,32.456 	"
        />
        <polygon
          fill="#EEB846"
          points="29.235,35.146 20.235,32.146 20.235,43.647 29.235,46.646 38.235,43.647 38.235,32.146 	"
        />
        <polygon
          fill="#EDB745"
          points="28.941,34.838 19.941,31.838 19.941,43.339 28.941,46.338 37.941,43.339 37.941,31.838 	"
        />
        <polygon
          fill="#ECB644"
          points="28.647,34.529 19.647,31.529 19.647,43.029 28.647,46.029 37.647,43.029 37.647,31.529 	"
        />
        <polygon
          fill="#EBB643"
          points="28.353,34.221 19.353,31.221 19.353,42.721 28.353,45.721 37.353,42.721 37.353,31.221 	"
        />
        <polygon
          fill="#EBB542"
          points="28.059,33.911 19.059,30.912 19.059,42.412 28.059,45.412 37.059,42.412 37.059,30.912 	"
        />
        <polygon
          fill="#EAB441"
          points="27.765,33.603 18.765,30.604 18.765,42.104 27.765,45.104 36.765,42.104 36.765,30.604 	"
        />
        <polygon
          fill="#E9B340"
          points="27.471,33.293 18.471,30.294 18.471,41.795 27.471,44.794 36.471,41.795 36.471,30.294 	"
        />
        <polygon
          fill="#E8B23F"
          points="27.177,32.984 18.176,29.985 18.176,41.486 27.177,44.485 36.177,41.486 36.177,29.985 	"
        />
        <polygon
          fill="#E8B13E"
          points="26.883,32.676 17.882,29.676 17.882,41.177 26.883,44.176 35.883,41.177 35.883,29.676 	"
        />
        <polygon
          fill="#E7B13D"
          points="26.588,32.367 17.588,29.367 17.588,40.868 26.588,43.867 35.588,40.868 35.588,29.367 	"
        />
        <polygon
          fill="#E6B03C"
          points="26.294,32.059 17.294,29.059 17.294,40.559 26.294,43.559 35.294,40.559 35.294,29.059 	"
        />
        <polygon fill="#E6AF3B" points="26,31.75 17,28.75 17,40.25 26,43.25 35,40.25 35,28.75 	" />
        <polygon
          fill="#E5AE3B"
          points="25.706,31.441 16.706,28.441 16.706,39.941 25.706,42.941 34.706,39.941 34.706,28.441 	"
        />
        <polygon
          fill="#E4AD3A"
          points="25.412,31.132 16.412,28.133 16.412,39.633 25.412,42.633 34.412,39.633 34.412,28.133 	"
        />
        <polygon
          fill="#E3AD39"
          points="25.117,30.823 16.118,27.824 16.118,39.324 25.117,42.324 34.117,39.324 34.117,27.824 	"
        />
        <polygon
          fill="#E3AC38"
          points="24.823,30.514 15.824,27.515 15.824,39.016 24.823,42.015 33.823,39.016 33.823,27.515 	"
        />
        <polygon
          fill="#E2AB37"
          points="24.529,30.205 15.529,27.206 15.529,38.707 24.529,41.706 33.529,38.707 33.529,27.206 	"
        />
        <polygon
          fill="#E1AA36"
          points="24.235,29.896 15.235,26.896 15.235,38.397 24.235,41.396 33.235,38.397 33.235,26.896 	"
        />
        <polygon
          fill="#E0A935"
          points="23.941,29.588 14.941,26.588 14.941,38.089 23.941,41.088 32.941,38.089 32.941,26.588 	"
        />
        <polygon
          fill="#E0A834"
          points="23.647,29.279 14.647,26.279 14.647,37.779 23.647,40.779 32.647,37.779 32.647,26.279 	"
        />
        <polygon
          fill="#DFA833"
          points="23.353,28.971 14.353,25.971 14.353,37.471 23.353,40.471 32.353,37.471 32.353,25.971 	"
        />
        <polygon
          fill="#DEA732"
          points="23.059,28.661 14.059,25.662 14.059,37.162 23.059,40.162 32.059,37.162 32.059,25.662 	"
        />
        <polygon
          fill="#DDA631"
          points="22.765,28.353 13.765,25.354 13.765,36.854 22.765,39.854 31.765,36.854 31.765,25.354 	"
        />
        <polygon
          fill="#DDA530"
          points="22.471,28.043 13.471,25.044 13.471,36.545 22.471,39.544 31.471,36.545 31.471,25.044 	"
        />
        <polygon
          fill="#DCA42F"
          points="22.177,27.734 13.176,24.735 13.176,36.236 22.177,39.235 31.177,36.236 31.177,24.735 	"
        />
        <polygon
          fill="#DBA32E"
          points="21.883,27.426 12.882,24.426 12.882,35.927 21.883,38.926 30.883,35.927 30.883,24.426 	"
        />
        <polygon
          fill="#DAA32D"
          points="21.588,27.117 12.588,24.118 12.588,35.618 21.588,38.617 30.588,35.618 30.588,24.118 	"
        />
        <polygon
          fill="#DAA22C"
          points="21.294,26.809 12.294,23.809 12.294,35.309 21.294,38.309 30.294,35.309 30.294,23.809 	"
        />
        <polygon fill="#D9A12B" points="21,26.5 12,23.5 12,35 21,38 30,35 30,23.5 	" />
      </g>
      <g>
        <polygon opacity="0.5" fill="#FFFFFF" points="21,38 12,35 12,23.5 21,26.5 	" />
        <polygon fill="#FFFFFF" points="21,38 30,35 30,23.5 21,26.5 	" />
        <polygon fill="#FFFFFF" points="18,29.499 9,26.5 12,23.5 21,26.5 	" />
        <polygon fill="#F2BD4C" points="24,29.499 33,26.5 30,23.5 21,26.5 	" />
        <polygon opacity="0.5" fill="#FFFFFF" points="24,29.499 33,26.5 30,23.5 21,26.5 	" />
        <polygon fill="#FFFFFF" points="18,19 9,22 12,23.5 21,20.5 	" />
        <polygon fill="#FFFFFF" points="24,19 33,22 30,23.5 21,20.5 	" />
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M14.236,10.752V9.933l2.695-3.138H14.32V5.849h3.998v0.82l-2.695,3.138h2.695v0.945H14.236z"
        />
        <path fill="#FFFFFF" d="M20.088,10.752V5.849h1.078v4.903H20.088z" />
        <path
          fill="#FFFFFF"
          d="M24.988,9.281H24.17v1.471h-1.079V5.849h2.003c1.17,0,1.877,0.693,1.877,1.695v0.014
                    C26.971,8.693,26.088,9.281,24.988,9.281z M25.879,7.565c0-0.483-0.337-0.743-0.876-0.743H24.17v1.499h0.854
                    c0.539,0,0.855-0.322,0.855-0.743V7.565z"
        />
      </g>
    </svg>
  );
};

export default zip;
