import React from 'react';
import { EmptySplitView, EmptySplitViewContent } from './styles';

const EmptySplitViewFC: React.FC = () => {
  return (
    <EmptySplitView>
      <EmptySplitViewContent className="d-flex align-items-center justify-content-center flex-column h-100">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M22,3H5A2,2 0 0,0 3,5V9H5V5H22V19H5V15H3V19A2,2 0 0,0 5,21H22A2,2 0 0,0 24,19V5A2,2 0 0,0 22,3M7,15V13H0V11H7V9L11,12L7,15M20,13H13V11H20V13M20,9H13V7H20V9M17,17H13V15H17V17Z" />
        </svg>
        <h5>Select an item to view</h5>
        <p>Nothing is Selected</p>
      </EmptySplitViewContent>
    </EmptySplitView>
  );
};

export default EmptySplitViewFC;
