import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { isFunction, isObject, uniqueId } from 'lodash';
import { Empty } from '@base/components';
import TreeManage from './tree-manage';
import TreeLoading from '../components/tree-loading';
import TreeNodeTitle from '../components/tree-node-title';
import {
  leafIcon,
  folderNormal,
  folderPlus,
  folderMinus,
  loader,
  checkBoxTrue,
  checkBoxFalse,
  checkBoxHalf,
} from '../components/tree-icons';
import { arr2Dic } from '@base/utils/helpers/general.utils';
import './styles.scss';

interface CoreTreeProps {
  value?: any[];
  source: any;
  id?: string;
  configs?: any;
  conditions?: any;
  icons?: any;
  hasCheckBox?: boolean;
  parsedNode?: any;
  onChecked?: any;
  disabledWithRole?: boolean;
  onClickCbNotExpanded?: any;
  onClickTitle?: any;
  conditionChecked?: any;
  onClickNode?: any;
}

const CoreTree = (props: CoreTreeProps, ref: any) => {
  const {
    value = [],
    source = null,
    id = '',
    configs = {},
    conditions = {},
    icons = {},
    hasCheckBox = false,
    parsedNode = null,
    onChecked = null,
    disabledWithRole = false,
    onClickCbNotExpanded = null,
    onClickTitle = null,
    conditionChecked = null,
    onClickNode = null,
  } = props;

  const [currValue, setCurrValue] = useState<any>({});
  const [idTree] = useState(uniqueId('ncrm-tree-'));
  const [isDisabledAll, setIsDisabledAll] = useState(false);
  const [treeManage, setTreeManage] = useState<TreeManage | null>(null);
  const [itemChecked, setItemChecked] = useState<any>(null);
  const [isLoadedTree, setLoadedTree] = useState(false);
  const isLazy = isFunction(source);

  const {
    loadingTree = <TreeLoading />,
    nodata = <Empty />,
    folderTitle = <TreeNodeTitle />,
    leafTitle = <TreeNodeTitle />,
  } = configs;

  const {
    leaf = leafIcon,
    folder = folderNormal,
    folderExpand = folderMinus,
    folderShrink = folderPlus,
    loadingFolder = loader,
  } = icons;

  useEffect(() => {
    setTreeManage(
      new TreeManage().initial({
        idTree,
        isLazy,
        source,
        hasCb: hasCheckBox,
        disabledWithRole: disabledWithRole,
        set: { disabledAll: (_value: any) => setIsDisabledAll(_value) },
        component: {
          loadingTree,
          nodata,
          folderTitle,
          leafTitle,
        },
        icons: {
          leaf,
          folder,
          folderExpand,
          folderShrink,
          loadingFolder,
          cbTrue: checkBoxTrue,
          cbFalse: checkBoxFalse,
          cbHalf: checkBoxHalf,
        },
        parsedNode: (_node: any) => {
          if (parsedNode) return parsedNode(_node);
          return _node;
        },
        onChecked: (_item: any, currNode: any) => {
          setItemChecked({
            items: _item,
            currNode,
          });
        },
        setLoadedTree: (_item: any) => setLoadedTree(_item),
        onClickNode,
        onClickCbNotExpanded,
        onClickTitle,
      }),
    );
    return () => setTreeManage(null);
  }, []);

  useEffect(() => {
    // value?.length > 0 && setCurrValue(arr2Dic(value, 'id'));
    if (value && value?.length > 0 && isObject(value?.[0])) {
      setCurrValue(arr2Dic(value, 'id'));
    }
  }, [value]);

  useEffect(() => {
    // value.length > 0 && treeManage && treeManage.onInitCb(treeManage, value);
    if (isLoadedTree && value && value?.length > 0 && isObject(value?.[0])) {
      treeManage && treeManage.onInitCb(treeManage, value);
    }
  }, [isLoadedTree]);

  useEffect(() => {
    if (isLoadedTree) {
      if (itemChecked.items.length > 0) {
        let temp = { ...currValue };
        const willRemove: any = [];
        const arrAdd: any = [];
        itemChecked.items
          .filter((x: any) => (conditionChecked ? conditionChecked(x) : true))
          .forEach((_item: any) => {
            if (_item.checked === 1) {
              arrAdd.push(_item);
            } else {
              willRemove.push(_item?.id);
            }
          });
        if (willRemove.length > 0) {
          willRemove.forEach((_key: any) => {
            if (temp[_key]) delete temp[_key];
          });
        }
        if (arrAdd.length > 0) {
          const dicAdd = arr2Dic(arrAdd, 'id');
          temp = { ...temp, ...dicAdd };
        }
        onChecked && onChecked(Object.values(temp), itemChecked?.currNode || null);
      }
    }
  }, [itemChecked]);

  useEffect(() => {
    treeManage && treeManage.create();
  }, [treeManage]);

  useImperativeHandle(ref, () => ({
    onSearch: (key: any) => {
      treeManage && treeManage.onSearch(treeManage, key);
    },
    onUpdateCheckBox: (_items: any) => {
      treeManage && treeManage.onUpdateCb(treeManage, _items);
    },
    // onInitCb: (_items) => {
    //     treeManage && treeManage.onInitCb(treeManage, _items);
    // },
  }));

  return (
    <div className={'pos-relative ht-100p wd-100p'}>
      <div className={'wd-100p ht-100p'}>
        <div id={idTree} className={'wd-100p'}></div>
      </div>
      <LayerDisable show={isDisabledAll} />
    </div>
  );
};

export default forwardRef(CoreTree);

const LayerDisable = ({ show = false }: any) => {
  return (
    <div
      className={'pos-absolute bg-white'}
      style={{
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        opacity: 0.2,
        display: show ? 'block' : 'none',
      }}
    ></div>
  );
};
