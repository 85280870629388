export default (editor: any, config: any) => {
  const domc = editor.DomComponents;
  const textType = domc.getType('text');
  const textModel = textType.model;
  const textView = textType.view;

  domc.addType('heading', {
    model: textModel.extend(
      {
        defaults: {
          ...textModel.prototype.defaults,
          'custom-name': 'Heading',
          tagName: config.defaultHTagName,
          traits: [
            {
              type: 'select',
              options: [
                { value: 'h1', name: config.labelHN1 },
                { value: 'h2', name: config.labelHN2 },
                { value: 'h3', name: config.labelHN3 },
                { value: 'h4', name: config.labelHN4 },
                { value: 'h5', name: config.labelHN5 },
                { value: 'h6', name: config.labelHN6 },
              ],
              label: config.labelHTrait,
              name: 'tagName',
              changeProp: 1,
            },
          ],
        },
      },
      {
        isComponent(el: any) {
          if (el && el.tagName && ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(el.tagName)) {
            return { type: 'heading' };
          }
        },
      },
    ),
    view: textView,
  });
};
