import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Mail, Plus, X } from 'react-feather';

import { confirmAlert, SpanLang, NoData, Avatar } from '@base/components';
import CustomerAutoComplete from '@customer/customer/containers/customer-auto-complete';
import Loading from '@base/components/loading';
import { MENU_CUSTOMER } from '@base/config/menus';
import WriteCustomer from '@customer/customer/pages/write';
import CompetitorWriteForm from '@customer/customer/containers/competitor-write-form';
import { FormIcon } from '@base/components/form';
import SendMailModal from '../send-mail-modal';
import SendSmsModal from '../send-sms-modal';
import { CustomerTextView } from '@base/containers/quick-view';

interface IAssignCustomerContainerProps {
  placement?: string;
  items: any;
  menuSource?: string;
  menuSourceId?: string;
  menuSourceName?: string;
  menuTab?: string; //= customer type
  menuType?: string; //= customer category
  isLoading: boolean;
  isShowAdd?: boolean;
  addLabel?: string;
  onDelete?: any;
  onAssign?: any;
  onCallback?: any;
  readOnly?: boolean;
  buttonsOnItems?: React.ReactNode[];
  sendMail?: boolean;
  sendSms?: boolean;
}

/**
 *
 * @param {*} props
 * @returns
 */
const AssignCustomerContainer = (props: IAssignCustomerContainerProps) => {
  const {
    placement,
    menuType, //category
    menuSource,
    menuSourceId,
    menuSourceName,
    menuTab,
    items,
    isLoading = false,
    isShowAdd = false,
    addLabel = '',
    onDelete,
    onAssign,
    onCallback, //refresh list
    readOnly = false,
    buttonsOnItems,
    sendMail = false,
    sendSms = false,
  } = props;

  //lang
  const { t } = useTranslation();
  //state
  const [isDeleting, setIsDeleting] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showView, setShowView] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ name: '' });
  const [deletedItem, setDeletedItem] = useState<any>(null);
  //const [exceptItems, setExceptItems] = useState<any>([]);
  const [fullScreen, setFullScreen] = useState(false);

  const [showSendMail, setShowSendMail] = useState<boolean>(false);
  const [showSendSms, setShowSendSms] = useState<boolean>(false);

  //contact view
  const handleView = (item: any) => {
    setSelectedItem(item);
    setShowView(true);
  };

  //delete confirm
  const handleDelete = (item: any) => {
    setIsDeleting(true);
    setDeletedItem(item);
    onDelete(item, () => {
      setDeletedItem(null);
      setIsDeleting(false);
    });
  };

  //detail
  const renderItemInfo = (item: any) => {
    return (
      <div
        className="d-flex align-items-center mg-b-3 pd-x-10 pd-y-3 justify-content-between"
        key={item.id}
      >
        <div
          className="media align-items-center flex-grow-1"
          // style={{ maxWidth: 'calc(100% - 108px)' }}
        >
          <div className="avatar avatar-xs">
            <Avatar url={item.photo} alt={item.name} width={26} height={26} />
          </div>
          <div className="media-body mg-l-5">
            <CustomerTextView value={{ id: item?.id, name: item?.name }} />
          </div>
        </div>
        <div className="flex-shrink-0">
          {!!buttonsOnItems?.length &&
            buttonsOnItems.map((passedComp, i) => (
              <React.Fragment key={i}>{passedComp}</React.Fragment>
            ))}
          {sendMail && (
            <button
              type="button"
              className="btn btn-link btn-icon mg-l-auto han-tooltip--top"
              data-han-tooltip="Send Email"
              onClick={() => setShowSendMail(true)}
            >
              <Mail className="tx-teal" />
              <span className="sr-only">이메일 보내기</span>
            </button>
          )}
          {sendSms && (
            <button
              type="button"
              className="btn btn-link btn-icon tx-orange op-8 han-tooltip--top"
              data-han-tooltip="Send SMS"
              onClick={() => setShowSendSms(true)}
            >
              <FormIcon icon="sms" iconType="icon" />
              <span className="sr-only">SMS 보내기</span>
            </button>
          )}

          {!readOnly && (
            <button
              type="button"
              className="btn btn-link btn-icon han-tooltip--top"
              data-han-tooltip="Delete"
              onClick={() => handleDelete(item)}
            >
              {isDeleting && deletedItem?.id === item.id ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <X className="tx-danger" />
              )}
            </button>
          )}
        </div>
      </div>
    );
  };

  let placeholder = 'Type or click to select a customer...';
  if (menuType === 'account') {
    placeholder = 'Type or click to select a account...';
  }
  if (menuType === 'contact') {
    placeholder = 'Type or click to select a contact...';
  }
  //render
  return (
    <>
      <div className="pos-relative mg-b-10">
        {(items.length == 0 || isLoading) && <NoData icon={'Users'} iconType={'feather'} />}
        {!isLoading && items.map((_item: any) => renderItemInfo(_item))}
        {!isLoading && (menuSource === MENU_CUSTOMER || isShowAdd) && (
          <button
            type="button"
            className="btn tx-primary add-mode-btn"
            onClick={() => setShowAdd(true)}
          >
            <Plus /> {addLabel || 'Add new contact'}
          </button>
        )}
        {isLoading && <Loading />}
        {menuSource !== MENU_CUSTOMER && !isShowAdd && !readOnly && (
          <CustomerAutoComplete
            placement={placement}
            single={true}
            showAvatar={true}
            visible={false}
            placeholder={placeholder}
            category={menuType || ''}
            type={menuTab}
            //exceptItems={exceptItems}
            value={[]}
            onChange={onAssign}
            //addLabel=''
            //onAdd={menuSource === MENU_CUSTOMER ? () => setShowAdd(true) : null}
          />
        )}
      </div>
      {showAdd && menuTab !== 'competitor' && (
        <WriteCustomer
          title={<SpanLang keyLang={'New Contact'} />}
          size="lg"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={showAdd}
          account={
            menuSource === MENU_CUSTOMER
              ? {
                  id: menuSourceId, //account id
                  name: menuSourceName, //account name
                  value: menuSourceId,
                  label: menuSourceName,
                }
              : null
          }
          menuApi={`${MENU_CUSTOMER}_${menuType}`} //MENU_CUSTOMER_CONTACT
          onClose={() => setShowAdd(false)}
          onReload={onCallback}
        />
      )}
      {showAdd && menuTab === 'competitor' && (
        <CompetitorWriteForm
          title={<SpanLang keyLang={'Create Competior'} />}
          size="lg"
          showCanvas={true}
          isOpen={showAdd}
          onClose={() => setShowAdd(false)}
          //onReload={refetch}
        />
      )}

      {/* Show Send Mail Modal*/}
      {showSendMail && (
        <SendMailModal isOpen={showSendMail} handleClose={() => setShowSendMail(false)} />
      )}

      {/* Show Send SMS Modal */}
      {showSendSms && (
        <SendSmsModal isOpen={showSendSms} handleClose={() => setShowSendSms(false)} />
      )}
    </>
  );
};

AssignCustomerContainer.defaultProps = {
  placement: 'right',
  menuSource: '',
  menuSourceId: '',
  menuSourceName: '',
  menuTab: '', //= customer type
  menuType: '', //= customer category
  items: [],
  isLoading: false,
  onDelete: () => null,
  onAssign: () => null,
};

export default AssignCustomerContainer;
