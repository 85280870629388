import React, { useEffect, useState } from 'react';
import TimePicker from './time-picker';
import { getMinMax } from './utils';
import { isEqual } from 'lodash';

const TimeRangePicker = (props: any) => {
  const {
    value = null,
    onChange = null,
    customLabelStart = null,
    customLabelEnd = null,
    popperPlacement = 'top-end',
    step = 15,
  } = props;

  //state
  const [crrValue, setCrrValue] = useState<any>(null);
  const [minMaxStart, setMinMaxStart] = useState<any>({
    minTime: null,
    maxTime: value?.endTime || null,
  });
  const [minMaxEnd, setMinMaxEnd] = useState<any>({
    minTime: value?.startTime || null,
    maxTime: null,
  });

  //init
  useEffect(() => {
    function initValue() {
      if (crrValue === null) {
        setCrrValue({
          startTime: '00:00',
          endTime: '23:59',
        });
      }
    }
    if (value) {
      if (!isEqual(value, crrValue) && value.startTime !== '' && value.endTime !== '') {
        setCrrValue({
          startTime: value.startTime,
          endTime: value.endTime,
        });
      }
      if (value.startTime === '' && value.endTime === '') {
        initValue();
      }
    } else {
      initValue();
    }
  }, [value]);

  //value change
  const handleChangeTime = (_value: any, _type: string) => {
    const newData = {
      ...crrValue,
      [_type]: `${_value.getHours()}:${_value.getMinutes()}`,
    };
    handleMinMax(newData);
    setCrrValue(newData);
    //callback
    onChange && onChange(newData);
  };

  //set min, max
  const handleMinMax = (_data: any) => {
    const { startTime = '', endTime = '' } = _data;
    const rs: any = getMinMax(startTime, endTime);
    setMinMaxStart(rs?.minMaxStart);
    setMinMaxEnd(rs?.minMaxEnd);
  };

  return (
    <div className="d-flex flex-wrap align-items-center justify-content-start">
      <div className="d-flex align-items-center justify-content-center mg-b-5">
        {customLabelStart ? (
          customLabelStart
        ) : (
          <label className="form-item-title mg-r-10">Start</label>
        )}
        <div className="wd-150-f">
          <TimePicker
            popperPlacement={popperPlacement}
            key={'time-picker-start'}
            onChange={(data: any) => handleChangeTime(data, 'startTime')}
            {...minMaxStart}
            step={step}
            value={crrValue?.startTime || ''}
          />
        </div>
      </div>
      <div className="pd-x-10">~</div>
      <div className="d-flex align-items-center justify-content-center mg-b-5">
        {customLabelEnd ? customLabelEnd : <label className="form-item-title mg-r-10">End</label>}
        <div className="wd-150-f">
          <TimePicker
            {...minMaxEnd}
            popperPlacement={popperPlacement}
            key={'time-picker-end'}
            onChange={(data: any) => handleChangeTime(data, 'endTime')}
            step={step}
            value={crrValue?.endTime || ''}
          />
        </div>
      </div>
    </div>
  );
};

export default TimeRangePicker;
