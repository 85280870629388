import React, { useState, useEffect, useMemo } from 'react';
import { ChevronDown } from 'react-feather';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const SelectWrapper: any = styled.div`
  position: relative;

  ${(props: any) =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${(props: any) =>
    props.separate &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        ${props.separate === 'left'
          ? css`
              left: 0;
            `
          : css`
              right: 0;
            `}
        width: 1px;
        height: 16px;
        margin-top: -8px;
        background-color: var(--border-color);
      }
    `}
  
  .dropdown-menu {
    max-height: 650px;
    overflow: auto;

    .dropdown-item {
      cursor: pointer;
    }
  }
`;

const defaultDropdownItem = {
  className: 'dropdown-item',
  tag: 'div',
  children: (props: any): string => props.label,
};

const SelectDropdown = ({
  className,
  name,
  tag = 'button',
  placeholder = 'Select',
  down = true,
  options = [],
  selected = 0,
  defaultValue = 0,
  onChange = () => null,
  icon = null,
  separate = false,
  width,
  prefix = null,
  buttonStyles = {},
  hideLabel = false,
  itemClassName = '',
  children = null,
  isMulti = false,
  outSide = false,
}: any) => {
  const [selectedItem, setSelected] = useState(
    typeof selected !== 'object' && isMulti ? [] : selected,
  );

  const renderCheckbox = (item: any) => (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className="custom-control-input"
        checked={selectedItem.indexOf(item.value) !== -1}
        id={`dropdown-${item.value}`}
      />
      <label className="custom-control-label" htmlFor={`dropdown-${item.value}`}>
        {item.label}
      </label>
    </div>
  );

  const selectedLabel = useMemo(() => {
    if (isMulti) {
      return `${placeholder}${selectedItem.length > 0 ? ` (${selectedItem.length})` : ''}`;
    }

    let selected = options.find((opt: any) => opt.value === selectedItem);

    return selected ? selected.label : placeholder;
  }, [selectedItem]);

  useEffect(() => {
    if (selected !== selectedItem && typeof selectedItem !== 'object') {
      setSelected(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (defaultValue && !selected) {
      setSelected(defaultValue);
    }
  }, []);

  const onSelect = (item: any, evt: any) => {
    evt && evt.preventDefault();
    if (!isMulti) {
      setSelected(item.value);
      onChange(item);
    } else {
      if (selectedItem.indexOf(item.value) === -1) {
        selectedItem.push(item.value);
      } else {
        selectedItem.splice(selectedItem.indexOf(item.value), 1);
      }

      setSelected([...selectedItem]);
      onChange(selectedItem);
    }
  };

  const onSelectAll = () => {
    const selected =
      selectedItem.length !== options.length ? options.map((opt: any) => opt.value) : [];

    setSelected(selected);
    onChange(selected);
  };

  const isSelected = (item: any) => {
    return item.value === selectedItem;
  };

  const renderDropdownItem = (item: any, index: number) => {
    if (item.divider) {
      return <div key={index} className="dropdown-divider" />;
    }

    item = { ...defaultDropdownItem, ...item };
    const Component = item.tag;

    return (
      <Component
        {...item}
        key={item.value}
        onClick={(e: any) => onSelect(item, e)}
        className={classNames(item.className, itemClassName, {
          active: !isMulti && isSelected(item),
          disabled: item.disabled,
        })}
      >
        {typeof children === 'function'
          ? children(item)
          : isMulti
          ? renderCheckbox(item)
          : item.children(item)}
      </Component>
    );
  };

  return (
    <SelectWrapper
      className={classNames('dropdown list-filter-items', className, { 'to-body': outSide })}
      separate={separate}
      width={width}
    >
      {prefix}
      <button
        type="button"
        className="btn btn-filter pd-l-0"
        data-toggle="dropdown"
        style={buttonStyles}
      >
        {icon}
        {!hideLabel && selectedLabel}
        {down && <ChevronDown className="mg-l-5" />}
      </button>
      <div className="dropdown-menu">
        {isMulti && (
          <>
            <button type="button" onClick={onSelectAll} className="dropdown-item">
              Show all
            </button>
            <div className="dropdown-divider" />
          </>
        )}
        {options.map((item: any, index: number) => renderDropdownItem(item, index))}
      </div>
      <input
        type="hidden"
        name={name}
        value={typeof selectedItem === 'object' ? JSON.stringify(selectedItem) : selectedItem}
      />
    </SelectWrapper>
  );
};

export default SelectDropdown;
